import { Component, OnInit, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ExperimentDataRecordNotification } from '../../api/audit/models';
import { AuditHistory } from '../services/audit-history.interface';
import { UserService } from '../../../app/api/services/user.service';
import { User } from '../../../app/api/models';
import { DataRecordService } from '../services/data-record.service';
import { dateComparator, filterComparator, parseFormattedStringDateToInstant } from '../../shared/date-time-helpers';

@Component({
  selector: 'app-audit-history',
  templateUrl: './audit-history.component.html',
  styleUrls: ['./audit-history.component.scss']
})
export class AuditHistoryComponent implements OnInit {
  private readonly cellStyleClass = "grid-cell-align";
  columnDefs!: ColumnDefinition[];
  private readonly keyNameOf = <T>(name: Extract<keyof T, string>): string => name;
  message!: string;
  dataSource: AuditHistory[] = [];
  usersList!: User[];
  isValid = false;
  dataRecords!: ExperimentDataRecordNotification[];
  private readonly excelSheetName = 'demo-sheet';
  private readonly excelFileName = 'History';
  @ViewChild('auditGrid') grid!: BptGridComponent;
  bptGridMode!: BptGridMode;  
  dataloaded = false;

  auditGridOptions = {
    allowRowAdd: false,
    includeRowNumberColumn: false,
    enableExport: true,
    useJsJodaTypes: true,
    debounceVerticalScrollbar: true
  }
  /**
   * @param dynamicDialogConfig To get the configurations
   */
  constructor(private readonly dynamicDialogConfig: DynamicDialogConfig, private readonly userService: UserService,
    private readonly dataRecordService: DataRecordService) {       
  }

  ngOnInit() {    
    this.message = $localize`:@@loadingHistory:Loading History...`;
    this.dataRecords = this.dynamicDialogConfig.data as ExperimentDataRecordNotification[];
    this.setDialogData(this.dataRecords);
    this.bptGridMode = BptGridMode.dataView;
  }

  resultsGridReady() {
    this.grid.gridApi.setDomLayout();
     setTimeout(() => { this.isValid = false; }, 1500);    
  }

  /**
   * Sets the dialog with required data
   * @param _records Selected context can be  Experiment/Activities/Module/Form/Table
   */
  private async setDialogData(records: ExperimentDataRecordNotification[]) {
    this.isValid = true;
    await this.dataRecordService.getAuditHistoryAsync(records).then((results) => {
      this.dataSource = results;     
      this.dataloaded = true;
    });    
    this.columnDefs = [
      {
        field: this.keyNameOf<AuditHistory>('Time'),
        label: $localize`:@@Date&Time:Date & Time`,
        width: 'auto',
        sortable: true,
        sort: "desc",
        filterType: 'date',
        cellClass: this.cellStyleClass,
        filter: true,
        comparator: this.dateCompare,
        filterParams: {
          debounceMs: 500,
          comparator: filterComparator,
        }
      },
      {
        field: this.keyNameOf<AuditHistory>('Context'),
        label: $localize`:@@Context:Context`,
        width: 'auto',
        cellClass: this.cellStyleClass,
      },
      {
        field: this.keyNameOf<AuditHistory>('RecordType'),
        label: $localize`:@@RecordType:Record Type`,
        width: 'auto',
        sortable: true,
        cellClass: this.cellStyleClass,
      },
      {
        field: this.keyNameOf<AuditHistory>('ContextType'),
        label: $localize`:@@ContextType:Context Type`,
        width: 'auto',
        hidden: true,
        cellClass: this.cellStyleClass
      },
      {
        field: this.keyNameOf<AuditHistory>('Name'),
        label: $localize`:@@Name:Name`,
        width: 'auto',
        hidden: true,
        cellClass: this.cellStyleClass
      },
      {
        field: this.keyNameOf<AuditHistory>('Description'),
        label: $localize`:@@Description:Description`,
        width: 'auto',
        cellClass: `${this.cellStyleClass} preWhiteSpace`
      },
      {
        field: this.keyNameOf<AuditHistory>('RecordVersion'),
        label: $localize`:@@RecordVersion:Record Version`,
        width: 'auto',
        hidden: true,
        cellClass: this.cellStyleClass
      },
      {
        field: this.keyNameOf<AuditHistory>('PerformedBy'),
        label: $localize`:@@PerformedBy:Performed By`,
        width: 'auto',
        cellClass: this.cellStyleClass,
      }
    ];
  }  

  exportDataToExcel() {
    const allColumns = this.grid.gridColumnApi.getAllColumns();
    const params = {
      columnKeys: !allColumns ? [] : allColumns,
      fileName: this.excelFileName,
      sheetName: this.excelSheetName,
    };
    this.grid.gridApi.exportDataAsExcel(params);
  }

  dateCompare(cellValue1:string, cellValue2:string){
    if (cellValue1 === null && cellValue2 === null) return 0;
    if (cellValue1 === null) return 1;
    if (cellValue2 === null) return -1;
    const dateA = parseFormattedStringDateToInstant(cellValue1).toString();
    const dateB = parseFormattedStringDateToInstant(cellValue2).toString();
    return dateComparator(dateA,dateB);
  }  
}