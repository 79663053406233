import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { Subscription } from 'rxjs';
import { PreparationItem } from '../../../preparation/models/preparation-presentation.model';
import { ClientStateService } from '../../../services/client-state.service';
import { ExperimentService } from '../../services/experiment.service';
import { ActivatedRoute } from '@angular/router';
import { elnDecodeSpecialChars } from '../../../shared/url-path-serializer';
import { ExperimentPreparation } from '../../../api/models';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { BarcodeScannerHelper } from '../../../services/barcode-scanner-helper';
import { ClientFacingNoteRequest, FlagConfigRequest } from '../../../preparation/models/comments.model';
import { FlagRendererService } from '../../services/flag-renderer.service';
import { PreparationEventService } from '../../../preparation/services/preparation-event.service';


@Component({
  selector: 'app-lab-items-preparation',
  templateUrl: './lab-items-preparation.component.html',
  styleUrls: ['./lab-items-preparation.component.scss']
})
export class LabItemsPreparationComponent extends BaseComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  labItemPreparations: PreparationItem[] = [];
  currentActivityId = "";
  showGrid = false;
  constructor(
    clientStateService: ClientStateService,
    private readonly experimentService: ExperimentService,
    private readonly route: ActivatedRoute,
    private readonly elementRef: ElementRef,
    public readonly preparationEventService: PreparationEventService,
    private readonly flagRendererService: FlagRendererService,
    private readonly barcodeScannerHelper:BarcodeScannerHelper
  ) 
 {
  super(clientStateService,route)
  this.subscriptions.push(
    this.flagRendererService.ClientFacingNoteAdded.subscribe({
      next: (note) => {
        this.elementRef.nativeElement.dispatchEvent(note);
      }
    })
  );
}

  ngOnInit() {
    this.loadDataSource();
    this.watchForActivityTitleDeterminationThroughRoute(this.route);
    this.subscriptions.push(
      this.barcodeScannerHelper.labItemPreparationAddedPublisher.subscribe(() => {
        // condition check is not need because the validation messages are handled in barcode-scanner service
          this.showGrid=false;
          this.loadDataSource();
      })
    );
    this.watchClientFacingNoteEvents();
  }

  public watchForActivityTitleDeterminationThroughRoute(route: ActivatedRoute) {
    this.subscriptions.push(route.params.subscribe((params) => {
      const currentActivityTitle = elnDecodeSpecialChars(params['itemTitle']);
      const activity = this.experimentService.currentExperiment?.activities.find(
        (activity) => activity.itemTitle === currentActivityTitle
      );
      if (activity) {
        this.currentActivityId = activity.activityId;
        this.loadDataSource();
      }
    }));
  }

  loadDataSource() {
    const activityLabItem = this.experimentService.currentExperiment?.activityLabItems
    .find(labItem=>labItem.nodeId === this.returnCurrentActivityId());
    if(activityLabItem) {
    const collectionOfPreparations: PreparationItem[] = [];
      activityLabItem.preparations.forEach((labItemPrep:ExperimentPreparation)=> {
        collectionOfPreparations.push(labItemPrep);
      })

      this.labItemPreparations = [...collectionOfPreparations]
    }
    else {
      this.labItemPreparations = [];
    }
    this.showGrid=true;
  }
  
  getFlagRendererConfig = (flagConfigRequest: FlagConfigRequest) => {
    return this.flagRendererService.getFlagRendererConfig(
      flagConfigRequest.flag,
      this.flagRendererService.getPathBasedOnFlag(
        flagConfigRequest.flag, 
        flagConfigRequest.fieldName, 
        flagConfigRequest.id, 
        flagConfigRequest.field, 
        flagConfigRequest.commentContext
      ),
      flagConfigRequest.commentContext,
      flagConfigRequest.rowId
    );
  }

  showClientFacingNotes(request: ClientFacingNoteRequest) {
    this.flagRendererService.showClientFacingNotes(request.paths, request.contextType, request.nodeId);
  }

  watchClientFacingNoteEvents() {
    this.subscriptions.push(
      this.experimentService.clientFacingNoteEvents.subscribe({
        next: () => {
          this.preparationEventService.refreshGrid.next();
        }
      })
    );
  }

  public returnCurrentActivityId() {
    return this.experimentService.currentActivity?.activityId;
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }
}
