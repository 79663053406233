import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

declare const Blazor: any;

@Injectable({
  providedIn: 'root'
})
export class BlazorStartService {
  constructor() {
    console.debug('New instance of BlazorStartService');
  }

  public blazorLoadState: BlazorLoadState = BlazorLoadState.uninitialized;
  public blazorLoadingStatusNotifications: BehaviorSubject<BlazorLoadState> = new BehaviorSubject(this.blazorLoadState);
  
  private readonly blazorLoadingPromise?: Promise<IPreloadScriptResult>;

  public loadBlazor(): Promise<IPreloadScriptResult> {
    return this.blazorLoadingPromise ?? this.loadBlazorInternal();
  }

  private loadBlazorInternal(): Promise<IPreloadScriptResult>{
    if (this.blazorLoadState !== BlazorLoadState.uninitialized && this.blazorLoadingPromise) return this.blazorLoadingPromise;
    
    this.blazorLoadStateChanged(BlazorLoadState.starting);
    return new Promise<IPreloadScriptResult>(resolve => {
      const blazorStartOption = {
        loadBootResource: function (type: any, name: any, defaultUri: any, _integrity: any) {
          if (type === 'dotnetjs') {
            return `${environment.elnWebUrl}/_framework/${name}`;
          }
          return `${environment.elnWebUrl}/${defaultUri}`;
        }
      };
      Blazor.start(blazorStartOption).then(() => {
        this.blazorLoadStateChanged(BlazorLoadState.started);
        resolve({blazorLoadState: this.blazorLoadState});
      }).catch((error: any) => {
        if(error.message === 'Blazor has already started.') {
          console.warn('Blazor re-initialization attempted, ignoring this error.');
          console.trace();
          resolve({blazorLoadState: this.blazorLoadState});
        } else {
          console.error('Blazor initialization failed', error);
          this.blazorLoadStateChanged(BlazorLoadState.failed);
          resolve({blazorLoadState: this.blazorLoadState});
        }
        
      });
    });
  }

  private blazorLoadStateChanged(newState: BlazorLoadState): void {
    console.debug(`BlazorStartService: BlazorLoadStateChanged from '${this.blazorLoadState}' to '${newState}'`);
    this.blazorLoadState = newState;
    this.blazorLoadingStatusNotifications.next(this.blazorLoadState);
  }
}

export enum BlazorLoadState {
  uninitialized = "uninitialized",
  starting = "starting",
  started = "started",
  stopped = "stopped",
  failed = "failed"
};

export interface IPreloadScriptResult {
  blazorLoadState: BlazorLoadState
}