import { LocalDate } from '@js-joda/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ColumnDefinition, ISeverityIndicatorConfig } from 'bpt-ui-library/bpt-grid';
import { BptControlSeverityIndicator,NA } from 'bpt-ui-library/shared';
import { Observable } from 'rxjs';
import { ActivityInputType, ColumnType } from '../../../../api/models';
import {
  LabItemsFeatureManager,
  LabItemWisePermissions
} from '../../shared/lab-items-feature-manager';
import { BptElnGridColumnLinkoutComponent } from '../../shared/bpt-eln-grid-column-linkout/bpt-eln-grid-column-linkout.component';
import { LabItemsService } from '../../lab-items.service';
import { formatLocalDate } from '../../../../shared/date-time-helpers';

export class LabItemsMaterialTableOptions {  
  public static readonly ColumnDefinition: { [fieldName: string]: { displayName: string } } = {
    code: { displayName: 'Material Aliquot ID' },
    name: { displayName: 'Name' },
    materialStatus: { displayName: 'Status' },
    notebookReference: { displayName: 'Notebook Reference' },
    materialType: { displayName: 'Material Type' },
    brand: { displayName: 'Brand' },
    grade: { displayName: 'Grade' },
    purity: { displayName: 'Purity' },
    concentration: { displayName: 'Concentration' },
    lotNumber: { displayName: 'Lot #' },
    catalogNumber: { displayName: 'Catalog #' },
    inUseExpirationDate: { displayName: 'In Use Expiration' },
    expirationDate: { displayName: 'Expiration Date' },
    AdditionalInformation: { displayName: 'Additional Information' }
  };

  private static readonly cellStyleClass = 'grid-cell-align';
  private static readonly maxIsoDateForRefresh = '9999-12-31';

  public static GetColumnDefinitions(
    latestLabItemsPermissions?: Observable<LabItemWisePermissions>,
    flagProvider?: () => any,
    severityIndicatorConfig?: () => ISeverityIndicatorConfig
  ): ColumnDefinition[] {
    const itemType = ActivityInputType.Material;
    return [
      {
        label: $localize`:@@preparationRowId:Row ID`,
        field: 'code',
        columnType: ColumnType.RowId,
        hidden: true,
        editable: false,
        showInColumnChooser: false
      },
      {
        label: $localize`:@@Index:Index`,
        field: 'rowIndex',
        columnType: ColumnType.Index,
        hidden: true,
        showInColumnChooser: false,
        editable: false
      },
      {
        label: $localize`:@@MaterialAliquotIdGridHeader:Material Aliquot ID`,
        field: 'code',
        width: 'auto',
        minWidth: 300,
        disableHiding: true,
        lockVisible: true,
        showHeader: false,
        sortable: true,
        editable: false,
        cellRenderer: BptElnGridColumnLinkoutComponent,
        cellRendererParams: {
          suppressCount: true,
          flagConfigProvider: flagProvider!()
        },
        showInColumnChooser: false
      },
      {
        label: $localize`:@@name:Name`,
        field: 'name',
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        columnType: ColumnType.String,
        cellRendererParams: {
          suppressCount: true
        },
        editable: false,
        width: 'auto',
        showInColumnChooser: false,
        sortable: true,
        allowAutoResize: true
      },
      {
        field: 'materialStatus',
        label: $localize`:@@status:Status`,
        width: 'auto',
        editable: false,
        allowAutoResize: true,
        sortable: true,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: true,
        columnType: ColumnType.String,
        severityIndicatorConfig: () => {
          return {
            getIndicator: (params: ICellRendererParams) => {
              return this.severityIndicator(params);
            }
          };
        }
      },
      {
        field: 'notebookReference',
        label: $localize`:@@notebookReferenceGridHeader:Notebook Reference`,
        width: 180,
        minWidth: 180,
        editable: false,
        allowAutoResize: true,
        sortable: true,
        columnType: ColumnType.String,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: false
      },
      {
        field: 'materialType',
        label: $localize`:@@MaterialType:Material Type`,
        width: 'flex',
        minWidth: 180,
        allowAutoResize: true,
        editable: false,
        sortable: true,
        columnType: ColumnType.String,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: false
      },
      {
        field: 'brand',
        label: $localize`:@@brand:Brand`,
        width: 90,
        minWidth: 90,
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true
      },
      {
        field: 'grade',
        label: $localize`:@@grade:Grade`,
        width: 90,
        minWidth: 90,
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true
      },
      {
        field: 'purity',
        label: $localize`:@@purity:Purity`,
        width: 90,
        minWidth: 90,
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true
      },
      {
        field: 'concentration',
        label: $localize`:@@concentration:Concentration`,
        width: 140,
        minWidth: 140,
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true
      },
      {
        field: 'lotNumber',
        label: $localize`:@@materialLotGridHeader:Lot #`,
        width: 80,
        minWidth: 80,
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true
      },
      {
        field: 'catalogNumber',
        label: $localize`:@@materialCatalogGridHeader:Catalog #`,
        width: 110,
        minWidth: 110,
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true
      },
      {
        field: 'inUseExpirationDate',
        label: $localize`:@@InUseExpiration:In Use Expiration`,
        width: 200,
        minWidth: 200,
        sortable: true,
        editable: false,
        hidden: false,
        showInColumnChooser: true,
        columnType: ColumnType.String,
        severityIndicatorConfig: () => {
          return {
            getIndicator: (params: ICellRendererParams) => {
              let response!: BptControlSeverityIndicator;
              if (params.value !== NA && params.value !== null) {
                return this.severityIndicator(params)
              } else {
                return response;
              }
            }
          };
        }
      },
      {
        field: 'expirationDate',
        label: $localize`:@@materialExpirationGridHeader:Expiration Date`,
        width: 180,
        minWidth: 180,
        sortable: true,
        editable: false,
        hidden: false,
        showInColumnChooser: true,
        columnType: ColumnType.String,
        valueGetter: (params: any) => {
          if(!params.data.expirationDate  || params.data.expirationDate === LabItemsMaterialTableOptions.maxIsoDateForRefresh ) return NA;
          return formatLocalDate(params.data.expirationDate);
        },
        severityIndicatorConfig: () => {
          return {
            getIndicator: (params: ICellRendererParams) => {
              let response!: BptControlSeverityIndicator;
              if (params.value !== NA && params.value !== null) {
                return this.severityIndicator(params);
              } else {
                return response;
              }
            }
          };
        }
      },
      {
        field: 'AdditionalInformation',
        label: $localize`:@@additionalInfo:Additional Information`,
        width: 'auto',
        sortable: true,
        allowMultiLine: true,
        editable: function (params) {
          let permissions: LabItemWisePermissions = {};
          latestLabItemsPermissions?.subscribe({
            next: (latestPermissions) => {
              permissions = latestPermissions;
            }
          });
          return (
            params.data.code &&
            permissions[itemType][
              LabItemsFeatureManager.FeatureNamesByItemType[itemType].cellChangeFeatureName
            ]
          );
        },
        columnType: 'string',
        hidden: true,
        allowAutoResize: true,
        showInColumnChooser: true,
        severityIndicatorConfig: severityIndicatorConfig
      }
    ];
  }

  static severityIndicator(params: ICellRendererParams): BptControlSeverityIndicator {
    let response!: BptControlSeverityIndicator;
    const isMaterialExpired: boolean = (params.data.inUseExpirationDate !== NA &&
      params.data.inUseExpirationDate !== null &&
      params.data.inUseExpirationDate < LocalDate.now().toString()) || (params.data.expirationDate !== NA &&
        params.data.expirationDate !== null &&
        params.data.expirationDate < LocalDate.now().toString());

    if (params.data.materialStatus !== 'In Use' && params.data.materialStatus !== 'Released' && isMaterialExpired) {
      return LabItemsService.getExclamationIndicator(
        $localize`:@@MaterialAliquotIsInvalidAndExpired:The scanned material is expired and has invalid status`
      );
    } else if (params.data.materialStatus !== 'In Use' && params.data.materialStatus !== 'Released') {
      return LabItemsService.getExclamationIndicator($localize`:@@InvalidStatus:Invalid Status`);
    } else if (isMaterialExpired) {
      return LabItemsService.getExclamationIndicator($localize`:@@MaterialAliquotExpired:Material Aliquot has expired.`);
    } else {
      return response;
    }
  }
}
