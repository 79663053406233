import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  BptGridCellValueChangedEvent,
  BptGridComponent,
  BptGridMode,
  BptGridValuesPastedEvent,
  BptGridRowActionClickEvent,
  BptGridRowActionConfiguration,
  BptRowActionElement,
  ColumnDefinition,
  GridContextMenuItem,
  IFlagConfig,
  ISeverityIndicatorConfig
} from 'bpt-ui-library/bpt-grid';
import { LabItemRefreshedContext, LabItemsService } from '../../lab-items.service';
import { MaterialAliquot as LabItemsMaterial } from '../../../../api/models/LabItemsELN/Bookshelf/Api/LabItems/material-aliquot';
import { LabItemsMaterialTableOptions } from './lab-items-material-table-options';
import { BaseComponent } from '../../../../base/base.component';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';
import { filter } from 'rxjs/operators';
import { ActivityInputType, ClientFacingNoteContextType } from '../../../../api/models';
import { BptElnGridRowActionsComponent } from '../../shared/bpt-eln-grid-row-actions.component';
import { GridActionsPositionManager } from '../../shared/grid-actions-position-manager';
import { LabItemsRemovedMaterialComponent } from '../lab-items-removed-material/lab-items-removed-material.component';
import { DialogService } from 'primeng/dynamicdialog';
import { LabItemWisePermissions } from '../../shared/lab-items-feature-manager';
import { FlagRendererService } from '../../../../experiment/services/flag-renderer.service';
import { CommentContextType } from '../../../../api/internal-comment/models';
import { Experiment } from 'model/experiment.interface';
import { ClientFacingNoteModel } from '../../../comments/client-facing-note/client-facing-note.model';
import { LabItemsClientFacingNoteContext } from '../../../comments/client-facing-note/client-facing-note-event.model';
import { ICellRendererParams } from 'ag-grid-community';
import { ExperimentService } from '../../../services/experiment.service';
import { ExperimentWarningService } from '../../../services/experiment-warning.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-lab-items-material',
  templateUrl: './lab-items-material.component.html',
  styleUrls: ['./lab-items-material.component.scss']
})
export class LabItemsMaterialComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() titleOfTable = $localize`:@@LabItemsMaterialsTableTitle:Materials`;
  @Input() labItemsMaterialDataSource: LabItemsMaterial[] = [];
  @ViewChild('labItemsMaterialGrid') labItemsMaterialGrid!: BptGridComponent;

  public gridActionsPositionManager = new GridActionsPositionManager(
    'app-lab-items-material',
    BptElnGridRowActionsComponent.RowActionsWidth
  );

  labItemsMaterialId = 'labItemsMaterial';
  columnDefinitions: ColumnDefinition[] = [];
  gridMode = BptGridMode.dataEntry;
  public containsRemovedRows = false;
  wasEmpty = false;
  showGrid = true;
  public styleRootVariables: string = '';
  experiment!: Experiment;
  reloadGrid = true;
  gridActions!: BptGridRowActionConfiguration;

  constructor(
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly labItemsService: LabItemsService,
    private readonly router: Router,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly dialogService: DialogService,
    private readonly flagRendererService: FlagRendererService,
    private readonly experimentService: ExperimentService,
    private readonly experimentWarningService: ExperimentWarningService,
  ) {
    super(clientStateService, activatedRoute);
  }

  ngOnInit(): void {   
    this.addGridActions();  
    this.columnDefinitions = LabItemsMaterialTableOptions.GetColumnDefinitions(
      this.labItemsService.latestLabItemsPermissions,
      this.augmentColumnsWithCornerFlagProviderForCells.bind(this),
      this.severityIndicatorConfig
    );
         
    this.refreshDataSource();
    this.watchMaterialRefreshNotification();
    this.watchMaterialAddedNotification();
    this.watchClientFacingNoteNotification();
    this.watchInternalCommentsNotification();
    this.watchMaterialUpdatedNotification();
    this.watchRouterPathChanges();
    this.watchCollaborativeRefreshedMaterialNotification();
    this.watchWorkFlowStateChanges();
    this.labItemsService.watchForActivityTitleDeterminationThroughRoute(this.activatedRoute);
    this.flagRendererService.ClientFacingNoteAdded.subscribe({
      next: (note) => {
        this.elementRef.nativeElement.dispatchEvent(note);
      }
    });
  }

  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);

    this.gridActions = {
      actions: actionsSubject
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.labItemsService.columnDeleteActionId,
        enabled: this.evaluatePermissions()[ActivityInputType.Material]['eln-remove-lab-item'],
        styleClass: 'far fa-trash-alt',
        click:  this.rowDeleteActionClick.bind(this),
        tooltip: $localize`:@@RemoveItem:Remove item`
      },
      {
        id: this.labItemsService.columnRefreshActionId,
        enabled: this.evaluatePermissions()[ActivityInputType.Material]['eln-refresh-lab-item'],
        styleClass: 'fas fa-sync-alt',
        click: this.rowRefreshActionClick.bind(this),
        tooltip: $localize`:@@RefreshItem:Refresh item`
      }
    ];
  }

  private rowDeleteActionClick(e: BptGridRowActionClickEvent) {
    this.labItemsService.removeHandler[ActivityInputType.Material](e.params.data);
  }

  private rowRefreshActionClick(e: BptGridRowActionClickEvent) {
    this.labItemsService.refreshHandler[ActivityInputType.Material](e.params.data);
  }
  
  onFirstDataRendered(e: any) {
    this.labItemsService.loadCellLocks(this.labItemsMaterialGrid.gridOptions, this.renderer);
  }

 public severityIndicatorConfig = () => {
    return {
      getIndicator: this.getSeverityIndicator //Called by the ag grid cell renderer
    } as ISeverityIndicatorConfig;
  };

  public readonly getSeverityIndicator = (params: ICellRendererParams) => {
    return this.labItemsService.getSeverityIndicatorDefinition(
      this.labItemsMaterialDataSource,
      params
    );
  };
  private watchInternalCommentsNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.internalCommentsRefresh.subscribe({
        next: (_internalComment) => {
          this.labItemsMaterialGrid?.gridApi?.refreshCells({ force: true });
        }
      })
    );
    this.experiment = this.labItemsService.experimentService.currentExperiment as Experiment;
  }

  private watchClientFacingNoteNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.experimentService.clientFacingNoteEvents.subscribe({
        next: (note) => {
          this.onClientFacingNoteEvent(note);
        }
      })
    );
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }

  onGridReady() {
    this.augmentColumnsWithCornerFlagProviderForCells();
  }

  loadAuditHistoryDialog() {
    this.labItemsService.loadAuditHistoryDialog(ActivityInputType.Material);
  }

  onClientFacingNoteEvent(note: ClientFacingNoteModel): void {
    const context = note.context as LabItemsClientFacingNoteContext;
    if (
      note.nodeId !== this.labItemsService.experimentService.currentActivityId ||
      note.contextType !== ClientFacingNoteContextType.LabItems ||
      context.labItemType !== ActivityInputType.Material
    )
      return;
    this.refreshCell(context.rowId, context.columnField, true);
  }

  refreshCell(rowId: string, columnField: string, force: boolean): void {
    const rowNode = this.labItemsMaterialGrid.gridApi.getRowNode(rowId);
    if (!rowNode) return;

    const column = this.labItemsMaterialGrid.gridColumnApi.getColumn(columnField);
    if (!column) return;

    this.labItemsMaterialGrid.gridApi.refreshCells({
      force,
      rowNodes: [rowNode],
      columns: [column]
    });
  }
  private augmentColumnsWithCornerFlagProviderForCells(): any {
    const flagConfigProvider = (
      flag: 'top-right' | 'bottom-right',
      rowId: string,
      field: string
    ): IFlagConfig => {
      this.flagRendererService.ClientFacingNoteAdded.subscribe({
        next: (note) => {
          this.elementRef.nativeElement.dispatchEvent(note);
        }
      });
      const fieldName = LabItemsMaterialTableOptions.ColumnDefinition[field].displayName;
      return this.flagRendererService.getFlagRendererConfig(
        flag,
        this.flagRendererService.getPathBasedOnFlag(flag, fieldName, rowId, field, CommentContextType.LabItems, ActivityInputType.Material),
        CommentContextType.TableCell,
        rowId
      );
    };
    this.columnDefinitions.forEach((c: ColumnDefinition) => {
      c.flagConfigProvider = flagConfigProvider;
    });
    return flagConfigProvider;
  }

  private refreshDataSource(): void {
    const materials = this.labItemsService.getLabItemsMaterials();
    materials.forEach((material) => {
      this.labItemsService.unpackModifiableDataValues<LabItemsMaterial>(
        material,
        material.tableData,
        this.columnDefinitions,
        ActivityInputType.Material
      );
    });
    this.labItemsMaterialDataSource = [...materials];
    this.labItemsMaterialGrid?.gridApi?.setRowData(this.labItemsMaterialDataSource);
    this.containsRemovedRows = this.labItemsService.getLabItemsRemovedMaterials().length > 0;
    this.labItemsMaterialGrid?.gridColumnApi?.autoSizeAllColumns();
  }

  private watchMaterialRefreshNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.materialShouldRefresh.subscribe({
        next: () => this.refreshDataSource()
      })
    );
  }

  pasteValues(e: BptGridValuesPastedEvent) {
    if (this.experimentWarningService.isUserAllowedToEdit) {
      if (e.cellChangedEvents) {
        e.cellChangedEvents.forEach(element => {
          this.cellValueChanged(element);
        });
      }
    }
  }

  private watchMaterialAddedNotification(): void {
    this.activeSubscriptions.push(
      this.labItemsService.MaterialAdded.subscribe({
        next: this.addNewMaterialIntoGrid.bind(this)
      })
    );
  }

  private watchMaterialUpdatedNotification(): void {
    this.activeSubscriptions.push(
      this.labItemsService.MaterialUpdated.subscribe({
        next: this.updateMaterialIntoGrid.bind(this)
      })
    );
  }

  getContextMenu(): GridContextMenuItem[] {
    return [
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'paste',
      'separator',
      {
        label: $localize`:@@clientFacingNoteContextMenuOption:Client-facing Notes`,
        action: () => {
          const cell = this.labItemsMaterialGrid.gridApi.getFocusedCell();
          if (cell) {
            const row = this.labItemsMaterialGrid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const colDef = cell.column.getColDef();
            const field = colDef.field as string; // template validation prevents undefined
            if (row && row.id && colDef?.field) {
              this.flagRendererService.showClientFacingNotes(
                [
                  row.id,
                  field,
                  this.labItemsService.experimentService.currentActivityId,
                  ActivityInputType.Material
                ],
                ClientFacingNoteContextType.LabItems,
                this.labItemsService.experimentService.currentActivityId
              );
            }
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />',
        // TODO : This is to be removed as part of Product Backlog Item 3239397: TECH : Revert the code for Restrict users from editing the experiment changes
      },
      {
        label: $localize`:@@commentsHeader:Internal Comments`,
        action: () => {
          const cell =
            this.labItemsMaterialGrid && this.labItemsMaterialGrid.gridApi.getFocusedCell();
          if (cell) {
            const row =
              this.labItemsMaterialGrid &&
              this.labItemsMaterialGrid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const field = cell.column.getColDef().field as string;
            const fieldName = LabItemsMaterialTableOptions.ColumnDefinition[field].displayName;
            const currentRowIndex =  this.labItemsMaterialGrid.gridApi.getDisplayedRowAtIndex(cell.rowIndex)?.rowIndex;
            if (row && currentRowIndex != null)
              this.flagRendererService.showInternalComments(
                [
                  this.labItemsService.experimentService.currentActivityId,
                  fieldName,
                  row.id as string,
                  field,
                  row?.rowIndex != null && row.rowIndex >= 0 ? (row.rowIndex + 1).toString() : '',
                  ActivityInputType.Material
                ],
                CommentContextType.TableCell,
              );
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />'
      }
    ];
  }
  watchCollaborativeRefreshedMaterialNotification() {
    this.labItemsService.MaterialRefreshedByOtherUser.subscribe({
      next: this.applyCellChange.bind(this)
    });
    this.labItemsService.cellChangedNotificationByItemType[ActivityInputType.Material].subscribe({
      next: this.applyCellChange.bind(this)
    });
  }

  private watchWorkFlowStateChanges() {
    this.experimentService.experimentWorkFlowState.subscribe({
      next: (state) => {
        this.reloadGrid = false;
        setTimeout(() => {
          this.reloadGrid = true;
        }, 200);
      }
    });    
  }

  private addNewMaterialIntoGrid(_material: LabItemsMaterial): void {
    _material.activityInputType = ActivityInputType.Material;
    this.labItemsMaterialGrid?.gridColumnApi.autoSizeAllColumns();
  }

  private updateMaterialIntoGrid(material: LabItemsMaterial): void {
    this.labItemsMaterialGrid?.gridApi.applyTransaction({
      update: [material]
    });
  }

  private evaluatePermissions(): LabItemWisePermissions {
    const features = this.clientStateService.getFeatureFlags(this.clientState);
    this.labItemsService.featuresByClientState = features;
    return this.labItemsService.evaluateUserPermissionsOnLabItems();
  }

  private watchRouterPathChanges() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((_route: { url: string }) => {
        if (_route.url.toLowerCase().includes('/labitems')) {
          this.refreshDataSource();
        }
      });
  }

  cellValueChanged(e: BptGridCellValueChangedEvent) {
    this.labItemsService.labItemsCellValueChanged(
      ActivityInputType.Material,
      e,
      this.labItemsMaterialGrid as BptGridComponent
    );
    this.refreshDataSource();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    if (this.labItemsMaterialGrid) {
      this.gridActionsPositionManager.updatePosition(
        this.labItemsMaterialGrid.gridApi,
        this.labItemsMaterialGrid.gridColumnApi
      );
    }
  }

  loadRemovedRowsDialog() {
    this.dialogService.open(LabItemsRemovedMaterialComponent, {
      width: '80%',
      autoZIndex: true,
      closable: true,
      closeOnEscape: true,
      header: $localize`:@@labitemsRemovedMaterialAliquots:Removed material aliquots`,
      styleClass: 'eln-removed-material-dialog'
    });
  }

  applyCellChange(labItemRefreshedContext: LabItemRefreshedContext): void {
    const rowNode = this.labItemsMaterialGrid?.gridApi.getRowNode(labItemRefreshedContext.rowId);
    if (rowNode) {
      LabItemsService.UpdateCellValueInGrid(labItemRefreshedContext, rowNode);
      if (labItemRefreshedContext.skipFlash) {
        return;
      }
      this.labItemsMaterialGrid?.gridApi.flashCells({
        rowNodes: [rowNode],
        columns: labItemRefreshedContext.changedColumns
      });
    }
  }
}
