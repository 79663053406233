/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddCrossReferenceCommand } from '../models/add-cross-reference-command';
import { ApplyReferenceTemplateCommand } from '../models/apply-reference-template-command';
import { ChangeCrossReferenceCommand } from '../models/change-cross-reference-command';
import { CrossReferenceAddedResponse } from '../models/cross-reference-added-response';
import { CrossReferenceChangedResponse } from '../models/cross-reference-changed-response';
import { CrossReferenceRemovedResponse } from '../models/cross-reference-removed-response';
import { CrossReferenceRestoredResponse } from '../models/cross-reference-restored-response';
import { ReferenceTemplateAppliedResponse } from '../models/reference-template-applied-response';
import { RemoveCrossReferenceCommand } from '../models/remove-cross-reference-command';
import { RestoreCrossReferenceCommand } from '../models/restore-cross-reference-command';

@Injectable({
  providedIn: 'root',
})
export class ActivityReferenceEventsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation activityReferencesCrossReferencesPost
   */
  static readonly ActivityReferencesCrossReferencesPostPath = '/activity-references/cross-references';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferencesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferencesPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddCrossReferenceCommand
  }): Observable<StrictHttpResponse<CrossReferenceAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityReferenceEventsService.ActivityReferencesCrossReferencesPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CrossReferenceAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferencesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferencesPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddCrossReferenceCommand
  }): Observable<CrossReferenceAddedResponse> {

    return this.activityReferencesCrossReferencesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CrossReferenceAddedResponse>) => r.body as CrossReferenceAddedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferencesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferencesPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddCrossReferenceCommand
  }): Observable<StrictHttpResponse<CrossReferenceAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityReferenceEventsService.ActivityReferencesCrossReferencesPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CrossReferenceAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferencesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferencesPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddCrossReferenceCommand
  }): Observable<CrossReferenceAddedResponse> {

    return this.activityReferencesCrossReferencesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CrossReferenceAddedResponse>) => r.body as CrossReferenceAddedResponse)
    );
  }

  /**
   * Path part for operation activityReferencesCrossReferenceChangePost
   */
  static readonly ActivityReferencesCrossReferenceChangePostPath = '/activity-references/cross-reference/change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferenceChangePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceChangePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeCrossReferenceCommand
  }): Observable<StrictHttpResponse<CrossReferenceChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityReferenceEventsService.ActivityReferencesCrossReferenceChangePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CrossReferenceChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferenceChangePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceChangePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeCrossReferenceCommand
  }): Observable<CrossReferenceChangedResponse> {

    return this.activityReferencesCrossReferenceChangePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CrossReferenceChangedResponse>) => r.body as CrossReferenceChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferenceChangePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceChangePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeCrossReferenceCommand
  }): Observable<StrictHttpResponse<CrossReferenceChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityReferenceEventsService.ActivityReferencesCrossReferenceChangePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CrossReferenceChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferenceChangePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceChangePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeCrossReferenceCommand
  }): Observable<CrossReferenceChangedResponse> {

    return this.activityReferencesCrossReferenceChangePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CrossReferenceChangedResponse>) => r.body as CrossReferenceChangedResponse)
    );
  }

  /**
   * Path part for operation activityReferencesApplyReferenceTemplatePost
   */
  static readonly ActivityReferencesApplyReferenceTemplatePostPath = '/activity-references/apply-reference-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesApplyReferenceTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesApplyReferenceTemplatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ApplyReferenceTemplateCommand
  }): Observable<StrictHttpResponse<ReferenceTemplateAppliedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityReferenceEventsService.ActivityReferencesApplyReferenceTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReferenceTemplateAppliedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityReferencesApplyReferenceTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesApplyReferenceTemplatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ApplyReferenceTemplateCommand
  }): Observable<ReferenceTemplateAppliedResponse> {

    return this.activityReferencesApplyReferenceTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReferenceTemplateAppliedResponse>) => r.body as ReferenceTemplateAppliedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesApplyReferenceTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesApplyReferenceTemplatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ApplyReferenceTemplateCommand
  }): Observable<StrictHttpResponse<ReferenceTemplateAppliedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityReferenceEventsService.ActivityReferencesApplyReferenceTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReferenceTemplateAppliedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityReferencesApplyReferenceTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesApplyReferenceTemplatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ApplyReferenceTemplateCommand
  }): Observable<ReferenceTemplateAppliedResponse> {

    return this.activityReferencesApplyReferenceTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReferenceTemplateAppliedResponse>) => r.body as ReferenceTemplateAppliedResponse)
    );
  }

  /**
   * Path part for operation activityReferencesCrossReferenceRemovePost
   */
  static readonly ActivityReferencesCrossReferenceRemovePostPath = '/activity-references/cross-reference/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferenceRemovePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRemovePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveCrossReferenceCommand
  }): Observable<StrictHttpResponse<CrossReferenceRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityReferenceEventsService.ActivityReferencesCrossReferenceRemovePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CrossReferenceRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferenceRemovePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRemovePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveCrossReferenceCommand
  }): Observable<CrossReferenceRemovedResponse> {

    return this.activityReferencesCrossReferenceRemovePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CrossReferenceRemovedResponse>) => r.body as CrossReferenceRemovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferenceRemovePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRemovePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveCrossReferenceCommand
  }): Observable<StrictHttpResponse<CrossReferenceRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityReferenceEventsService.ActivityReferencesCrossReferenceRemovePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CrossReferenceRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferenceRemovePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRemovePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveCrossReferenceCommand
  }): Observable<CrossReferenceRemovedResponse> {

    return this.activityReferencesCrossReferenceRemovePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CrossReferenceRemovedResponse>) => r.body as CrossReferenceRemovedResponse)
    );
  }

  /**
   * Path part for operation activityReferencesCrossReferenceRestorePost
   */
  static readonly ActivityReferencesCrossReferenceRestorePostPath = '/activity-references/cross-reference/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferenceRestorePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRestorePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreCrossReferenceCommand
  }): Observable<StrictHttpResponse<CrossReferenceRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityReferenceEventsService.ActivityReferencesCrossReferenceRestorePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CrossReferenceRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferenceRestorePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRestorePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreCrossReferenceCommand
  }): Observable<CrossReferenceRestoredResponse> {

    return this.activityReferencesCrossReferenceRestorePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CrossReferenceRestoredResponse>) => r.body as CrossReferenceRestoredResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferenceRestorePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRestorePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreCrossReferenceCommand
  }): Observable<StrictHttpResponse<CrossReferenceRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityReferenceEventsService.ActivityReferencesCrossReferenceRestorePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CrossReferenceRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferenceRestorePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRestorePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreCrossReferenceCommand
  }): Observable<CrossReferenceRestoredResponse> {

    return this.activityReferencesCrossReferenceRestorePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CrossReferenceRestoredResponse>) => r.body as CrossReferenceRestoredResponse)
    );
  }

}
