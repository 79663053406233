import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { Message, MessageService } from 'primeng/api';
import { Observable, Subscription, map, throwError } from 'rxjs';
import { BptSliderComponent } from 'bpt-ui-library/bpt-slider';
import { Activity } from 'model/experiment.interface';
import { CrossReference, CrossReferenceType } from '../../api/models';
import { BookshelfService } from '../../api/search/services';
import { ConditionType, DataType, SearchCriteria, StringMatchType } from '../../api/search/models';
import { UserService } from '../../services/user.service';
import { BptTextInputComponent } from 'bpt-ui-library/bpt-text-input';
import { ActivityReferenceEventsService } from '../../api/data-entry/services';
import { ExperimentService } from '../../experiment/services/experiment.service';
import { AddCrossReferenceCommand, CrossReferenceAddedResponse } from '../../api/data-entry/models';

/**
 * Adds a user-confirmed, system-validated reference based on user-input or not, if cancelled.
 * Currently supports references to an experiment
 */
@Component({
  selector: 'app-cross-reference-slider',
  templateUrl: './cross-reference-slider.component.html',
  styleUrls: ['./cross-reference-slider.component.scss'],
})
export class CrossReferenceSliderComponent {
  @Input() readOnly = false;
  @Input() activity?: Activity;
  @Input() visible = false;
  @Output() closed = new EventEmitter();
  @ViewChild('slider') public slider!: BptSliderComponent;
  @ViewChild('searchInput') public searchInput!: BptTextInputComponent;

  validating?: Subscription;
  isValidating = false;
  isAdding = false;
  searchInputValue?: string;
  get showLoadingSpinner(): boolean {
    return this.isValidating || this.isAdding;
  }

  readonly headerText = $localize`:@@crossReference:Cross Reference`;
  readonly placeholderText = $localize`:@@crossReferencePlaceholder:Enter experiment Reference (e.g. EXP-ZQ23A01234)`;
  readonly titleText = $localize`:@@crossReferenceSliderSubtitle:Add reference to another ELN experiment`;
  readonly invalidReference = $localize`:@@invalidReference:Invalid Reference`;
  readonly cannotReferenceCurrentExperiment = $localize`:@@cannotReferenceCurrentExperiment:The current experiment cannot be referenced`;
  readonly experimentNotFound = $localize`:@@experimentNotFound:Experiment was not found`;
  readonly moreThanOneFound = $localize`:@@moreThanOneFound:More than one found`;
  readonly errorSearchingExperiments = $localize`:@@errorSearchingExperiments:Error searching experiments`;

  constructor(
    private readonly activityReferenceEventsService: ActivityReferenceEventsService,
    private readonly experimentService: ExperimentService,
    private readonly messageService: MessageService,
    private readonly searchService: BookshelfService,
    private readonly userService: UserService,
  ) {
  }

  sliderVisibleChange(visible: boolean) {
    this.visible = visible;
    this.searchInputValue = undefined;
    if (!visible) {
      this.validating?.unsubscribe();
      this.closed.emit();
    }
  }

  searchInputBlurred(_: FocusEvent) {
    // Unicode Default Case Conversion is good enough for the set of characters used in experiment references
    this.searchInputValue = (this.searchInputValue ?? '').toUpperCase();
  }

  onCancel() {
    this.visible = false;
    this.closed.emit();
  }

  onCommit() {
    if (!this.searchInputValue) throw Error('LOGIC ERROR: Should not be able to click button if search input does not have any text');

    const finalizeAdding = () => this.isAdding = false;
    const finalizeValidating = () => this.isValidating = false;

    this.isValidating = true;
    this.isAdding = false;
    this.validating?.unsubscribe();
    this.validating = this.validateExperimentReference(this.searchInputValue).subscribe({
      next: experimentId => {
        this.isAdding = true;
        this.addReference(CrossReferenceType.Experiment, experimentId).subscribe({
          next: () => {
            this.visible = false;
            this.closed.emit();
          },
          complete: finalizeAdding,
          error: finalizeAdding
        });
      },
      complete: finalizeValidating,
      error: finalizeValidating
    });
  }

  /** 
   * Creates an Observable that emits ExperimentId and completes if valid.
   * Otherwise displays error and returns an Observable in an error state. 
   */
  validateExperimentReference(experimentNumber: string): Observable<string> {
    // doesn't really matter if Error strings get localized; They for internal information and are not for programmatic use.
    if (experimentNumber === this.experimentService.currentExperiment?.experimentNumber) {
      this.messageService.add(this.createMessage(this.invalidReference, this.cannotReferenceCurrentExperiment));
      return throwError(() => Error(this.cannotReferenceCurrentExperiment));
    }

    return this.searchService.bookshelfSearchExperimentIndexPost$Json({ body: this.experimentSearchCriteria(experimentNumber) }).pipe(
      map((response) => {
        if (response.totalRecordCount === -1) {
          this.messageService.add(this.createMessage(this.invalidReference, this.errorSearchingExperiments));
          throw Error(this.errorSearchingExperiments);
        }

        const records = response.records.filter((r) => r.experimentNumber === experimentNumber);
        switch (records.length) {
          case 0: 
            this.messageService.add(this.createMessage(this.invalidReference, this.experimentNotFound));
            throw Error(this.experimentNotFound);
          case 1: 
            const experiment = records[0];
            return experiment.experimentId; // success
          default: // should never happen
            this.messageService.add(this.createMessage(this.invalidReference, this.moreThanOneFound));
            throw Error(this.experimentNotFound);
        }
      })
    );
  }

  /** 
   * Sends add cross reference command and upon success 
   *   * adds cross reference to experiment model and notifies via experiment service 
   *   * and creates an Observable that simply completes.
   * Otherwise returns an Observable in an error state. 
   * Note: HTTP interceptor would display any REST command error.
   */
  addReference(type: CrossReferenceType, linkId: string): Observable<void> {
    // defend against some things that can't happen
    if (!this.activity) return throwError(() => Error('LOGIC ERROR: Cannot add a reference to an undefined activity'));
    if (!linkId) return throwError(() => Error('LOGIC ERROR: Cannot add a reference to an undefined id'));

    const referenceId = uuid().toString();
    const command: AddCrossReferenceCommand = {
      experimentId: this.activity.experimentId,
      activityId: this.activity.activityId,
      id: referenceId,
      linkId,
      type,
    };
    return this.activityReferenceEventsService.activityReferencesCrossReferencesPost$Json({ body: command }).pipe(map(
      (response: CrossReferenceAddedResponse) => {
        const crossReference: CrossReference = {
          id: referenceId,
          rowIndex: { isModified: false, value: response.rowIndex },
          type,
          linkId,
          isRemoved: false,
        }
        this.activity?.activityReferences.crossReferences.push(crossReference);
        this.experimentService.crossReferenceAdded.next(crossReference);
      }
    ));
  }

  experimentSearchCriteria(experimentNumber: string): SearchCriteria {
    const labSiteCode = this.userService.currentUser.labSiteCode;
    return {
      bypassSecurity: false,
      filterConditions: [
        {
          conditionType: ConditionType.And,
          filters: [
            {
              dataType: DataType.String,
              columnName: 'experimentNumber',
              matchType: StringMatchType.In,
              values: [experimentNumber],
              isSecurityFlag: false,
            },
            {
              dataType: DataType.String,
              columnName: 'labsiteCode',
              matchType: StringMatchType.Word,
              text: labSiteCode,
              isSecurityFlag: true
            }
          ]
        },
      ],
      sort: [],
      pagination: { pageNumber: 1, pageSize: 2 }, // actually only 0 or 1 result is expected. Asking for at most 2 as a reality check.
    };
  }

  createMessage(summary: string, detail: string): Message {
    return {
      key: 'notification',
      severity: 'error',
      summary,
      detail,
      sticky: false
    };
  }
}
