<ng-template #ClientFacingNotesSlideContent>
  <div class="flex flex-auto flex-wrap flex-column">
    <app-client-facing-note
      #newComment
      id="cfn-new"
      *ngIf="!readOnly && showNote"
      [fieldPlaceholder]="clientFacingNoteConstants.newEntryLabel"
      (whenCommentAdded)="publishClientFacingNoteEvent($event)"
      [visible]="newCommentVisible"
      [clientFacingNote]="newClientFacingNote"
      [isNewClientFacingNote]="true"
    ></app-client-facing-note>
    <ng-container *ngIf="showNote && clientFacingNotes">
      <app-client-facing-note
        *ngFor="let note of clientFacingNotes"
        #note
        [id]="note.cssId"
        [fieldPlaceholder]="clientFacingNoteConstants.newEntryLabel"
        (whenCommentUpdated)="publishClientFacingNoteEvent($event)"
        [clientFacingNote]="note"
        [isNewClientFacingNote]="false"
        [readOnly]="readOnly"
      ></app-client-facing-note>
    </ng-container>
  </div>
</ng-template>
<bpt-slider
  #commentsSlider
  id="commentsSlider"
  [closeOnEscape]="closeOnEsc"
  [(visible)]="visible"
  [headerText]="clientFacingNoteConstants.header"
  [displayFooter]="false"
  class="comments-side-panel"

  [size]="'medium'"
>
  <ng-container *ngTemplateOutlet="ClientFacingNotesSlideContent"></ng-container>
</bpt-slider>
