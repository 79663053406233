/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CachePublishedTemplateCommand } from '../models/cache-published-template-command';
import { TemplateSearchFor } from '../models/template-search-for';
import { TemplateSummary } from '../models/template-summary';
import { TemplateSummaryForLoad } from '../models/template-summary-for-load';

@Injectable({
  providedIn: 'root',
})
export class TemplateService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation templatesTemplatesListGet
   */
  static readonly TemplatesTemplatesListGetPath = '/templates/templates-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesTemplatesListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesTemplatesListGet$Plain$Response(params?: {
    ReferenceIds?: Array<string>;
    puid?: string;
    owningLabsite?: string;
    getLatestVersion?: boolean;
    consumingLabsiteCodes?: string;
    subBusinessUnitCodes?: string;
    templateTypes?: string;
    templateEditors?: string;
    searchFor?: TemplateSearchFor;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<StrictHttpResponse<Array<TemplateSummary>>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateService.TemplatesTemplatesListGetPath, 'get');
    if (params) {
      rb.query('ReferenceIds', params.ReferenceIds, {});
      rb.query('puid', params.puid, {});
      rb.query('owningLabsite', params.owningLabsite, {});
      rb.query('getLatestVersion', params.getLatestVersion, {});
      rb.query('consumingLabsiteCodes', params.consumingLabsiteCodes, {});
      rb.query('subBusinessUnitCodes', params.subBusinessUnitCodes, {});
      rb.query('templateTypes', params.templateTypes, {});
      rb.query('templateEditors', params.templateEditors, {});
      rb.query('searchFor', params.searchFor, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TemplateSummary>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesTemplatesListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesTemplatesListGet$Plain(params?: {
    ReferenceIds?: Array<string>;
    puid?: string;
    owningLabsite?: string;
    getLatestVersion?: boolean;
    consumingLabsiteCodes?: string;
    subBusinessUnitCodes?: string;
    templateTypes?: string;
    templateEditors?: string;
    searchFor?: TemplateSearchFor;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<Array<TemplateSummary>> {

    return this.templatesTemplatesListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TemplateSummary>>) => r.body as Array<TemplateSummary>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesTemplatesListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesTemplatesListGet$Json$Response(params?: {
    ReferenceIds?: Array<string>;
    puid?: string;
    owningLabsite?: string;
    getLatestVersion?: boolean;
    consumingLabsiteCodes?: string;
    subBusinessUnitCodes?: string;
    templateTypes?: string;
    templateEditors?: string;
    searchFor?: TemplateSearchFor;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<StrictHttpResponse<Array<TemplateSummary>>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateService.TemplatesTemplatesListGetPath, 'get');
    if (params) {
      rb.query('ReferenceIds', params.ReferenceIds, {});
      rb.query('puid', params.puid, {});
      rb.query('owningLabsite', params.owningLabsite, {});
      rb.query('getLatestVersion', params.getLatestVersion, {});
      rb.query('consumingLabsiteCodes', params.consumingLabsiteCodes, {});
      rb.query('subBusinessUnitCodes', params.subBusinessUnitCodes, {});
      rb.query('templateTypes', params.templateTypes, {});
      rb.query('templateEditors', params.templateEditors, {});
      rb.query('searchFor', params.searchFor, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TemplateSummary>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesTemplatesListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesTemplatesListGet$Json(params?: {
    ReferenceIds?: Array<string>;
    puid?: string;
    owningLabsite?: string;
    getLatestVersion?: boolean;
    consumingLabsiteCodes?: string;
    subBusinessUnitCodes?: string;
    templateTypes?: string;
    templateEditors?: string;
    searchFor?: TemplateSearchFor;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<Array<TemplateSummary>> {

    return this.templatesTemplatesListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TemplateSummary>>) => r.body as Array<TemplateSummary>)
    );
  }

  /**
   * Path part for operation templatesSearchForLoadGet
   */
  static readonly TemplatesSearchForLoadGetPath = '/templates/search-for-load';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSearchForLoadGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesSearchForLoadGet$Plain$Response(params?: {
    GetLatestVersion?: boolean;
    ConsumingLabsiteCodes?: string;
    SubBusinessUnitCodes?: string;
    TemplateTypes?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<StrictHttpResponse<Array<TemplateSummaryForLoad>>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateService.TemplatesSearchForLoadGetPath, 'get');
    if (params) {
      rb.query('GetLatestVersion', params.GetLatestVersion, {});
      rb.query('ConsumingLabsiteCodes', params.ConsumingLabsiteCodes, {});
      rb.query('SubBusinessUnitCodes', params.SubBusinessUnitCodes, {});
      rb.query('TemplateTypes', params.TemplateTypes, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TemplateSummaryForLoad>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesSearchForLoadGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesSearchForLoadGet$Plain(params?: {
    GetLatestVersion?: boolean;
    ConsumingLabsiteCodes?: string;
    SubBusinessUnitCodes?: string;
    TemplateTypes?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<Array<TemplateSummaryForLoad>> {

    return this.templatesSearchForLoadGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TemplateSummaryForLoad>>) => r.body as Array<TemplateSummaryForLoad>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSearchForLoadGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesSearchForLoadGet$Json$Response(params?: {
    GetLatestVersion?: boolean;
    ConsumingLabsiteCodes?: string;
    SubBusinessUnitCodes?: string;
    TemplateTypes?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<StrictHttpResponse<Array<TemplateSummaryForLoad>>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateService.TemplatesSearchForLoadGetPath, 'get');
    if (params) {
      rb.query('GetLatestVersion', params.GetLatestVersion, {});
      rb.query('ConsumingLabsiteCodes', params.ConsumingLabsiteCodes, {});
      rb.query('SubBusinessUnitCodes', params.SubBusinessUnitCodes, {});
      rb.query('TemplateTypes', params.TemplateTypes, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TemplateSummaryForLoad>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesSearchForLoadGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesSearchForLoadGet$Json(params?: {
    GetLatestVersion?: boolean;
    ConsumingLabsiteCodes?: string;
    SubBusinessUnitCodes?: string;
    TemplateTypes?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<Array<TemplateSummaryForLoad>> {

    return this.templatesSearchForLoadGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TemplateSummaryForLoad>>) => r.body as Array<TemplateSummaryForLoad>)
    );
  }

  /**
   * Path part for operation templatesApplyChangesToCachePost
   */
  static readonly TemplatesApplyChangesToCachePostPath = '/templates/apply-changes-to-cache';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesApplyChangesToCachePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesApplyChangesToCachePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: Array<CachePublishedTemplateCommand>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateService.TemplatesApplyChangesToCachePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesApplyChangesToCachePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesApplyChangesToCachePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: Array<CachePublishedTemplateCommand>
  }): Observable<boolean> {

    return this.templatesApplyChangesToCachePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesApplyChangesToCachePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesApplyChangesToCachePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: Array<CachePublishedTemplateCommand>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TemplateService.TemplatesApplyChangesToCachePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesApplyChangesToCachePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesApplyChangesToCachePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: Array<CachePublishedTemplateCommand>
  }): Observable<boolean> {

    return this.templatesApplyChangesToCachePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
