<bpt-progress-spinner
  i18n-message="@@loadingHistory"
  message="History Loading..."
  [useOverlay]="true"
  *ngIf="isLoading"
></bpt-progress-spinner>

<div
  class="border-500 surface-overlay border-3 border-round p-2 surface-300 min-sized-div"
  *ngIf="visible"
>
<h2 class="panel-header" [pTooltip]="headerTooltip" tooltipPosition="left">{{ headerText }}</h2>
<h5 *ngIf="!isNewClientFacingNote" class="-mt-2">{{ lastEditedBy }}, {{ lastEditedOn }}</h5>
  <div class="min-sized-textbox">
    <bpt-text-input
      #noteInput
      [multiLine]="true"
      [rows]="2"
      [autoResize]="true"
      [label]="fieldLabel"
      [readOnly]="noteIsReadOnly"
      [disabled]="false"
      [placeholder]="fieldPlaceholder"
      [(ngModel)]="commentText"
      (ngModelChange)="refreshEditState(commentText)"
      (click)="beginEdit($event)"
      [contextMenu]="getContextMenu()"
      [suppressContextMenu]="false"      
    ></bpt-text-input>
  </div>
  <div
    class="slider-footer flex justify-content-end"
    *ngIf="isNewClientFacingNote || !noteIsReadOnly"
  >
    <p-button
      class="Cancel"
      styleClass="bpt-button-compact p-button-outlined"
      [disabled]="isCancelButtonDisabled"
      i18n-label="@@Cancel"
      label="Cancel"
      (onClick)="cancel()"
    ></p-button>
    <p-button
      class="Submit"
      styleClass="bpt-button-compact"
      [disabled]="isSubmitButtonDisabled"
      label="Submit"
      i18n-label="@@Submit"
      (onClick)="submit()"
    ></p-button>
  </div>
</div>
