import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ExperimentService } from '../services/experiment.service';
import { ClientFacingNoteContextType, InstrumentEventImpactAssessmentNode, NodeType, StringValue, ValueState, ValueType } from '../../api/models';
import { KeyColumnService } from '../services/key-column.service';
import {  Subscription, filter } from 'rxjs';
import { TabView } from 'primeng/tabview';
import { MenuItem } from 'primeng/api';
import { first } from 'lodash-es';
import { ActivityOutputEventsService } from '../../api/data-entry/services';
import { BptControlSeverityIndicators, ControlCustomDefinition, SeverityIndicatorType } from 'bpt-ui-library/shared';
import { ExperimentWarningService } from '../services/experiment-warning.service';
import { OutputEmpowerService } from '../services/output-empower.service';
import { BptDropdownComponent, DropdownChangeEvent } from 'bpt-ui-library/bpt-dropdown';
import { AuditHistoryDataRecordResponse, ExperimentDataRecordNotification, ExperimentEventType } from '../../api/audit/models';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuditHistoryService } from '../audit-history/audit-history.service';
import { FormContextMenuItem } from 'bpt-ui-library/bpt-context-menu';
import { FormFieldClientFacingNoteContext, ShowClientFacingNotesEventData } from '../comments/client-facing-note/client-facing-note-event.model';
import { CommentContextType } from '../../api/internal-comment/models';
import { CommentService } from '../comments/comment.service';
import { CommentDetails } from '../comments/comment.model';
import { Activity } from '../../model/experiment.interface';
import { BarcodeScannerHelper } from '../../services/barcode-scanner-helper';
import { DataRecordService } from '../services/data-record.service';
import { ActivityOutputCollaboratorService } from '../services/output-collaborator-service';
import { LockType } from '../../model/input-lock.interface';
import { ExperimentNotificationService } from '../../services/experiment-notification.service';
import { ClientFacingNoteCreatedEventNotification, ExperimentWorkflowState } from '../../api/data-entry/models';
import { FeatureService } from '../../services/feature.service';
import { ELNFeatureFlags } from '../../shared/eln-feature-flags';
import { ActivityOutputChromatographyResultSetSummary } from '../../api/models/activity-output-chromatography-result-set-summary';
import { BptFileUploadComponent } from 'bpt-ui-library/bpt-file-upload';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FileUploadModalComponent } from '../file-upload-modal/file-upload-modal.component';
import { FileConfigurationOptions } from '../model/file-configuration-options';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-empower-tabs',
  templateUrl: './empower-tabs.component.html',
  styleUrls: ['./empower-tabs.component.scss']
})
export class EmpowerTabsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() importedResultSetSummary: Array<ActivityOutputChromatographyResultSetSummary> = [];
  nonDeletedResultSets: Array<ActivityOutputChromatographyResultSetSummary> = [];

  @Output() SendResultSetEvent = new EventEmitter<ActivityOutputChromatographyResultSetSummary>();
  @Output() RefreshResultSetButtonId = new EventEmitter<string>();
  @ViewChild('container') container!: ElementRef<HTMLInputElement>;
  @ViewChild('tabview') tabview!: TabView;
  @ViewChild('nonRoutineDropdown') nonRoutineDropdown!: BptDropdownComponent;
  @ViewChild('returnedToServiceDropdown') returnedToServiceDropdown!: BptDropdownComponent;
  @ViewChild('fileUploader') fileUploader!: BptFileUploadComponent;
  @ViewChild('fileUploadModal') fileUploadModal!: OverlayPanel;

  subscriptionList: Subscription[] = [];
  isSampleAliquotAdded = false;
  isInstrumentAdded = false;
  canShowTabView = false;
  attachedFiles = $localize`:@@attachedFiles:Attached Files`;
  uploadTooltip = $localize`:@@uploadTooltip:Upload to Activity`;
  resultMapping = $localize`:@@resultMapping:Result Mapping`;
  isResultMappingHidden = true;
  empowerResultSet = $localize`:@@empowerResultSet:Empower Result Set `;
  instrumentEvent = $localize`:@@instrumentEventImpactAssessment:Instrument Event Impact Assessment`;
  instrumentEventValue!: string;
  selectedTab?: ActivityOutputChromatographyResultSetSummary;
  impactAssessment = $localize`:@@impactAssessment:Impact Assessment`;
  nonRoutineIssueEncountered = $localize`:@@nonRoutineIssueEncountered:Non-Routine Issue Encountered`;
  returnedToService = $localize`:@@returnedToService:Returned to Service`
  completionPercent = 0;
  totalModifiableFields = 2;
  emptyFields = 0;
  fileConfigOptions: FileConfigurationOptions = {
    fileLimit: 10,
    maxFileSize: 1000, //Max file size allowed is 1GB i.e. size unit here is MB
    fileSizeForDisplay: '1gb',
    fileTypes: 'image/*,.csv,.xml,.doc,.docx,.pdf,.txt,.msg,.html,.zip, .docm,.dot,.xltx,.xlsb,.xlsm,.xlsx,.xls',
    allowMultipleFiles: false,
    disabled: false
  };
  fixedTabs: Array<ActivityOutputChromatographyResultSetSummary> = [];
  moreTabs: Array<ActivityOutputChromatographyResultSetSummary> = [];
  public moreTabDropdownOptions: MenuItem[] = [];
  moreTabsHeader = $localize`:@@More:More`;
  uploadFilesLabel =  $localize`:@@uploadFiles:Upload files`;
  instrumentId!: string;
  instrumentName!: string;
  isUserAllowedToEdit?: boolean;
  nonRoutine?: string;
  returnToService?: string;
  dynamicDialogRef!: DynamicDialogRef;
  auditLoading = false;
  internalCommentData?: CommentDetails;
  noCurrentExperimentFound = $localize`:@@throwErrorMessage:No current experiment found`;
  impactAssessmentEnabled = false;
  activeIndex = 0;
  canUploadFiles = false;
  returnedToServiceOptions = [
    {
      label: $localize`:@@notRemovedFromService:Not removed from service`,
      value: 'Not removed from service'
    },
    {
      label: $localize`:@@dateOfReview:Date of review is the return to service date`,
      value: 'Date of review is the return to service date'
    },
    {
      label: $localize`:@@finalSignOffOnListedException:Final sign off on listed exception is return to service date`,
      value: 'Final sign off on listed exception is return to service date'
    },
    {
      label: $localize`:@@finalSignOffOnValidationSummary:Final sign off on validation summary is return to service date`,
      value: 'Final sign off on validation summary is return to service date'
    },
    {
      label: $localize`:@@finalSignOffOnChangeSummary:Final sign off on change summary is return to service date`,
      value: 'Final sign off on change summary is return to service date'
    },
    {
      label: $localize`:@@finalSignOffOnSubsequentEntry:Final sign off on subsequent entry is return to service date`,
      value: 'Final sign off on subsequent entry is return to service date'
    },
    {
      label: $localize`:@@equipmentRetired:Equipment retired - not returned to service`,
      value: 'Equipment retired - not returned to service'
    }
  ]

  nonRoutineOptions = [
    {
      label: $localize`:@@Yes:Yes`,
      value: 'Yes'
    },
    {
      label: $localize`:@@No:No`,
      value: 'No'
    }
  ]

  impactAssessmentDataDefault: InstrumentEventImpactAssessmentNode = {
    activityId: '',
    experimentId: '',
    instrumentId: '',
    nonRoutineIssueEncountered: {
      isModified: false,
      value: { state: ValueState.Empty, type: ValueType.String, value: undefined}
    },
    returnedToService: {
      isModified: false,
      value: { state: ValueState.Empty, type: ValueType.String, value: undefined}
    },
    itemType: NodeType.InstrumentEventImpactAssessment,
    id: '',
    _ts: 0
  }

  public controlCustomDefinitionValidatorForNonRoutine =
    this.getControlCustomDefinitionValidatorForNonRoutine.bind(this);

  public controlCustomDefinitionValidatorForReturnToService =
    this.getControlCustomDefinitionValidatorForReturnToService.bind(this);

  constructor(
    private readonly experimentService: ExperimentService,
    readonly outputEmpowerService: OutputEmpowerService,
    readonly keyColumnService: KeyColumnService,
    private readonly cdr: ChangeDetectorRef,
    private readonly experimentWarningService: ExperimentWarningService,
    private readonly commentService: CommentService,
    readonly activityOutputEventsService: ActivityOutputEventsService,
    readonly auditHistoryService: AuditHistoryService,
    readonly barcodeScannerHelper: BarcodeScannerHelper,
    readonly dataRecordService: DataRecordService,
    readonly activityOutputCollaboratorService: ActivityOutputCollaboratorService,
    readonly experimentNotificationService: ExperimentNotificationService,
    private readonly renderer: Renderer2,
    private readonly featureService: FeatureService,
    private readonly dialogService: DialogService,
    private readonly userService: UserService
  ) {
    this.impactAssessmentEnabled = this.featureService.isEnabled(ELNFeatureFlags.ReleaseToggleImpactAssessmentEnabled);
    this.canShowTabView = this.experimentService._showOutputAttachments;
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    try {
      this.fixedTabs = [];
      this.fixedTabs.push(...this.nonDeletedResultSets);
      this.moreTabs = [];
      this.moreTabDropdownOptions = [];
      this.calculateTabsDistribution();
    } catch (error) {
      console.error("An Error occurred while processing resize", error)
    }
  }

  ngOnInit(): void {
    this.handleSubscriptions();
    this.updateTabViewState();
    this.populateForm();
    this.calculateCompletionPercentage();
    this.fixupTabView();
    this.isUserAllowedToEdit = this.experimentWarningService.isUserAllowedToEdit;
    this.getFileUploadAccess(this.experimentService.currentExperiment?.workflowState!);
    this.impactAssessmentEnabled = this.featureService.isEnabled(ELNFeatureFlags.ReleaseToggleImpactAssessmentEnabled);
  }

  getFileUploadAccess(experimentWorkflowState: ExperimentWorkflowState) {
    this.canUploadFiles = (this.userService.hasAnalystRights(this.userService.currentUser.puid)
      || this.userService.hasReviewerRights(this.userService.currentUser.puid)
      || this.userService.hasSupervisorRights(this.userService.currentUser.puid))
      && (experimentWorkflowState === ExperimentWorkflowState.Setup
        || experimentWorkflowState === ExperimentWorkflowState.InProgress
        || experimentWorkflowState === ExperimentWorkflowState.InReview
        || experimentWorkflowState === ExperimentWorkflowState.InCorrection)
  }

  populateForm() {
    const activityId = this.experimentService.currentActivityId;
    const instrument = this.experimentService.currentExperiment?.instrumentEventImpactAssessmentData?.find((input) => input.activityId === activityId);
    if(!instrument) {
      this.impactAssessmentDataDefault.activityId = activityId;
      this.impactAssessmentDataDefault.experimentId = this.experimentService.currentExperiment?.id ?? '';
      this.experimentService.currentExperiment?.instrumentEventImpactAssessmentData?.push(this.impactAssessmentDataDefault)
    } else {
      this.nonRoutine = (instrument.nonRoutineIssueEncountered.value as StringValue).value;
      this.returnToService = (instrument.returnedToService.value as StringValue).value;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.importedResultSetSummary) {
      this.nonDeletedResultSets = this.importedResultSetSummary.filter(resultSet => !resultSet.isDeleted);
      this.fixedTabs = [...this.nonDeletedResultSets];
      if(this.fixedTabs.length > 0) {
        this.canShowTabView = true;
        this.calculateTabsDistribution();
      }
      if(this.activeIndex >= this.fixedTabs.length && this.fixedTabs.length > 0) {
        this.activeIndex = this.fixedTabs.length - 1;
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.calculateTabsDistribution();
    });
  }

  findEmptyFields() {
    this.emptyFields = 0;

    if(this.nonRoutine === undefined || this.nonRoutine === null || this.nonRoutine === "") {
      this.emptyFields += 1;
    }

    if(this.returnToService === undefined || this.returnToService === null || this.returnToService === "") {
      this.emptyFields += 1;
    }

  }

  calculateCompletionPercentage() {
    this.findEmptyFields();
    this.completionPercent = Math.floor(
      ((this.totalModifiableFields - this.emptyFields) / this.totalModifiableFields) * 100
    );
  }

  calculateTabsDistribution() {
    this.moreTabs = []
    const tabOffset = this.isSampleAliquotAdded ? 1 : 0;
    if (this.isMoreDropdownNeeded()) {
      for (let i = this.nonDeletedResultSets.length - 1 + tabOffset; i > 2; i--) {
        if (this.isResultSetExceedingAvailableWidth(i)) {
          this.moreTabs = [...this.moreTabs, this.nonDeletedResultSets[i - tabOffset]];
          this.fixedTabs = [...this.fixedTabs.slice(0, i - tabOffset), ...this.fixedTabs.slice(i+1 - tabOffset)];
        }
      }
      this.buildMoreTabDropdownOptions();
    }
  }

  uploadFiles() {
    this.dynamicDialogRef = this.dialogService.open(FileUploadModalComponent, {
      width: '50%',
      autoZIndex: true,
      closable: true,
      height: '60%',
      closeOnEscape: true,
      showHeader: true,
      header: this.uploadFilesLabel,
      data: { fileDetails: this.fileConfigOptions, activityId: this.experimentService.currentActivityId, experimentId: this.experimentService.currentExperiment?.id },
      styleClass: 'eln-file-upload-dialog'
    });
  }

  public buildMoreTabDropdownOptions() {
    this.moreTabs.forEach((m) => {
      this.moreTabDropdownOptions.push({
        id: m.resultSetId,
        label: `${this.empowerResultSet} ${m.resultSetId}`,
        styleClass: '',
        command: (event) => {
          this.selectedTab = undefined;
          this.changeTabSelection();
          this.handleMoreTabSelection(m);
          this.adjustTabsHighlight(event);
        }
      });
    });
  }

  private changeTabSelection(): void {
    const selectedTab = this.tabview.findSelectedTab();
    selectedTab.selected = false;
    this.tabview.tabs[this.tabview.tabs.length - 1].selected = true;
    this.tabview.tabChanged = true;
    this.cdr.detectChanges();
  }

  handleMoreTabSelection(tab: ActivityOutputChromatographyResultSetSummary) {
    this.selectedTab = tab;
  }

  private adjustTabsHighlight(event: any): void {
    const style = 'p-dropdown-item-active'
    this.moreTabDropdownOptions.forEach((x) => (x.styleClass = ''));
    event.item.styleClass = style;
  }

  ngOnDestroy() {
    this.subscriptionList.forEach((s) => s.unsubscribe());
  }

  handleSubscriptions() {
    this.subscriptionList.push(
      this.keyColumnService.samples$.pipe(
        filter(_samples => this.impactAssessmentEnabled),
        filter(samples => samples && samples.length > 0)
      ).subscribe((_samples) => {
        this.canShowTabView = true;
        this.isSampleAliquotAdded = true;
        if(this.tabview)
          this.tabview.activeIndex = 0;
        this.cdr.detectChanges();
        this.updateInkBarWidth(this.tabview.activeIndex);
      })
    );


    this.subscriptionList.push(
      this.barcodeScannerHelper.instrumentEventDataSourcePrepared.subscribe((data) => {
        if (this.impactAssessmentEnabled) {
          this.canShowTabView = true;
          this.isInstrumentAdded = true;
          this.instrumentId = data.instrumentNumber;
          this.instrumentName = data.name;
        }
      })
    );

    this.subscriptionList.push(
      this.dataRecordService.maintenanceEventSelectedDataRecordReceiver.subscribe((data) => {
        this.instrumentEventValue = data.nameDescription.value ?? '';
      })
    );

    this.subscriptionList.push(
      this.dataRecordService.nonRoutineIssueEncounteredDataChangeEventNotificationReceiver.subscribe((data) => {
        this.nonRoutine = data.nonRoutineIssueEncountered.value;
      })
    );

    this.subscriptionList.push(
      this.dataRecordService.returnedToServiceDataChangeEventNotificationReceiver.subscribe((data) => {
        this.returnToService = data.returnedToService.value;
      })
    );

    this.subscriptionList.push(
      this.experimentNotificationService.inputLockReceiver.subscribe((lockList) => {
        lockList.forEach((lock) => {
          this.activityOutputCollaboratorService.lockActionItems(lock,this.renderer)
        })
      })
    );

    this.subscriptionList.push(
      this.experimentService.experimentWorkFlowState.subscribe({
        next: (experimentWorkflowState: ExperimentWorkflowState) => {
          this.getFileUploadAccess(experimentWorkflowState);
        }
      })
    );

    this.subscriptionList.push(
      this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe((data) => {
        this.getFileUploadAccess(data.state);
      }));
  }

  updateTabViewState() {
    const activityId = this.experimentService.currentActivityId;
    const activityInput = this.experimentService.currentExperiment?.activityInputs?.find(input => input.activityId === activityId);

    if (activityInput && this.impactAssessmentEnabled) {
      if (activityInput.aliquots?.length > 0) {
        this.canShowTabView = true;
        this.isSampleAliquotAdded = true;
      }

      if (activityInput.instruments && !activityInput.instruments.isRemoved && this.impactAssessmentEnabled) {
        this.canShowTabView = true;
        this.isInstrumentAdded = true;
        this.instrumentId = activityInput.instruments?.activityInputReference;
        this.instrumentName = activityInput.instruments?.name;
        this.instrumentEventValue = JSON.parse(JSON.stringify(activityInput.instruments?.nameDescription.value)).value
      }
    }

    if (!this.canShowTabView && this.experimentService.currentExperiment?.activityOutputChromatographyResultSetsSummary?.some(resultSet => resultSet.activityId === activityId && !resultSet.isDeleted)) {
      this.canShowTabView = true;
    }
  }

  tabChange(selectionChangedEvent: any) {
    this.updateInkBarWidth(selectionChangedEvent.index)
    if(this.moreTabs.length > 0) {
      this.adjustTabsHighlight(selectionChangedEvent);
    }
    this.activeIndex = selectionChangedEvent.index;
  }

  private isMoreDropdownNeeded(): boolean {
    return this.tabview?.navbar?.nativeElement?.clientWidth > this.tabview?.content?.nativeElement?.clientWidth;
  }

  private isResultSetExceedingAvailableWidth(index: number) {
    // The width (in pixels) reserved for the More dropdown button.
    // We subtract this from the total available width to ensure there's room for the button.
    const MORE_BUTTON_WIDTH = 100;
    const moreDropDownCutOff = this.tabview.navbar.nativeElement.children[index].clientWidth - MORE_BUTTON_WIDTH ;
    return (
      this.tabview.navbar.nativeElement.children[index].offsetLeft + this.tabview.navbar.nativeElement.children[index].clientWidth >
      this.tabview.content.nativeElement.clientWidth - moreDropDownCutOff
    );
  }

  private updateInkBarWidth(index = 0) {
    // Adjusts the width of the inkbar to properly fit within the tab
    const INKBAR_WIDTH_ADJUSTMENT = 10;
    setTimeout(() => {
      const totalTabs = this.nonDeletedResultSets.length + (this.isSampleAliquotAdded ? 1 : 0) + (this.isInstrumentAdded ? 1 : 0);
      if (index !== totalTabs - 1) {
        const inkBarWidth = Number(first(this.tabview.inkbar.nativeElement.style.width.split('px')) || '0');
        this.tabview.inkbar.nativeElement.style.width = `${inkBarWidth - INKBAR_WIDTH_ADJUSTMENT}px`;
      }
    });
  }


  public fixupTabView(index = 0) {
    setTimeout(() => {
      if(!this.tabview){
        return;
      }
      this.tabview.updateButtonState();
      this.tabview.updateInkBar();
      this.updateInkBarWidth(index);
    });
  }

  nonRoutineEvent(event: DropdownChangeEvent) {
this.isUserAllowedToEdit = this.experimentWarningService.isUserAllowedToEdit;
    if(this.isUserAllowedToEdit) {
      this.nonRoutineEventHandler(event.value);
      return;
    }
  }

    nonRoutineEventHandler(event : string)
    {
    const { currentActivityId, currentExperiment } = this.experimentService;

    if (!currentExperiment || !currentActivityId || !event) return;

    const instrumentEvent = currentExperiment?.instrumentEventImpactAssessmentData?.find(input => input.activityId === currentActivityId);
    const currentNonRoutineValue = instrumentEvent?.nonRoutineIssueEncountered?.value as StringValue;

    if (!currentNonRoutineValue || event === currentNonRoutineValue.value) return;

    if (instrumentEvent && currentNonRoutineValue?.value && event !== currentNonRoutineValue.value) {
      instrumentEvent.nonRoutineIssueEncountered.isModified = true;
    }

    this.activityOutputEventsService
      .activityOutputEventsChangeNonRoutineSelectionPost$Json({
        body: {
          instrumentId: this.instrumentId,
          activityId: currentActivityId,
          experimentId: currentExperiment.id,
          nonRoutineIssueEncountered: { state: ValueState.Set, type: ValueType.String, value: event }
        }
      })
      .subscribe({
        next: (response) => {
          const newValue = response.impactAssessmentNotification?.nonRoutineIssueEncountered?.value;
          if (newValue) {
            this.nonRoutine = newValue;
            const activityId = this.experimentService.currentActivityId;
            const instrument = this.experimentService.currentExperiment?.instrumentEventImpactAssessmentData?.find((input) => input.activityId === activityId);
              if(instrument)
              {
              instrument.nonRoutineIssueEncountered.value = response?.impactAssessmentNotification?.nonRoutineIssueEncountered;
            }
          }
          this.calculateCompletionPercentage();
        }
      });
  }

  returnToServiceEvent(event: DropdownChangeEvent) {
    this.isUserAllowedToEdit = this.experimentWarningService.isUserAllowedToEdit;
    if(this.isUserAllowedToEdit) {
      this.returnToServiceEventHandler(event.value);
       return;
     }
    }

    returnToServiceEventHandler(event : string)
    {
    const { currentActivityId, currentExperiment } = this.experimentService;

    if (!currentExperiment || !currentActivityId || !event) return;

    const instrumentEvent = currentExperiment?.instrumentEventImpactAssessmentData?.find(input => input.activityId === currentActivityId);
    const currentReturnToServiceValue = instrumentEvent?.returnedToService.value as StringValue;

    if (!currentReturnToServiceValue || event === currentReturnToServiceValue.value) return;

    if (instrumentEvent?.returnedToService && currentReturnToServiceValue?.value) {
      instrumentEvent.returnedToService.isModified = true;
    }

    this.activityOutputEventsService
      .activityOutputEventsChangeReturnToServiceSelectionPost$Json({
        body: {
          instrumentId: this.instrumentId,
          activityId: currentActivityId,
          experimentId: currentExperiment.id,
          returnedToService: { state: ValueState.Set, type: ValueType.String, value: event }
        }
      })
      .subscribe({
        next: (response) => {
          const newValue = response.impactAssessmentNotification?.returnedToService?.value;
          if(newValue) {
            this.returnToService = newValue;
            const activityId = this.experimentService.currentActivityId;
            const instrument = this.experimentService.currentExperiment?.instrumentEventImpactAssessmentData?.find((input) => input.activityId === activityId);
              if(instrument)
              {
              instrument.returnedToService.value = response?.impactAssessmentNotification?.returnedToService;
            }
          }
          this.calculateCompletionPercentage();
        }
      });
  }

  loadImpactAssessmentHistoryDialog(eventType?: ExperimentEventType, event?: string) {
    const activityId = this.experimentService.currentActivityId;
    this.auditLoading = true;
    const experimentId =
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id;
    this.auditHistoryService
      .loadActivityOutputsAuditHistory(experimentId, activityId)
      .subscribe({
        next: (data: AuditHistoryDataRecordResponse) => {
          const filteredRecords = data.dataRecords.filter((dr) => {
            if (eventType) {
              return dr.eventContext.eventType === eventType && (dr as any).instrumentId === this.instrumentId;
            } else {
              return (dr.eventContext.eventType === ExperimentEventType.InstrumentEventNonRoutineIssueEncountered ||
                dr.eventContext.eventType === ExperimentEventType.InstrumentEventReturnedToService) &&
                (dr as any).instrumentId === this.instrumentId;
            }
          }
          );
          this.loadImpactAssessmentHistoryDialogWithCfn(eventType, filteredRecords, experimentId, activityId, event);
        }
      }
      );
  }

  private loadImpactAssessmentHistoryDialogWithCfn(
    eventType: ExperimentEventType | undefined,
    filteredRecords: ExperimentDataRecordNotification[],
    experimentId: string,
    activityId: string,
    event: string | undefined) {
    this.auditHistoryService
      .loadActivityOrModuleAuditHistory(experimentId, [activityId], [])
      .subscribe({
        next: (data) => {
          this.auditLoading = false;
          const dialogTitle = this.determineDialogTitle(eventType);
          const outputFormFieldIdentifiers = event ? [event] : ["Non-Routine Issue Encountered", "Returned to Service"];
          const cfnNumberList = this.getCfnNumbers(outputFormFieldIdentifiers);
          const cfnHistory = [...data.r1.dataRecords.filter((item) => {
            return (item.eventContext.eventType === "clientFacingNoteCreated"
              || item.eventContext.eventType === "clientFacingNoteChanged")
              && cfnNumberList.includes((item as ClientFacingNoteCreatedEventNotification).number)
          }),
          ...data.r2.dataRecords.filter((item) => {
            return (item.eventContext.eventType === "clientFacingNoteCreated"
              || item.eventContext.eventType === "clientFacingNoteChanged")
              && cfnNumberList.includes((item as ClientFacingNoteCreatedEventNotification).number)
          }),
          ...filteredRecords
          ];
          this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(cfnHistory, dialogTitle);
        }
      });
  }

  private getCfnNumbers(outputFormFieldIdentifiers: string[]): number[]{
    const cfnNumbers: any[] = []
    this.experimentService.currentExperiment?.clientFacingNotes
      .filter((cfn) => outputFormFieldIdentifiers.includes(cfn.path[0]))
      .forEach((dataRecord) => {cfnNumbers.push(dataRecord.number)});
    return cfnNumbers;
  }

  private determineDialogTitle(eventType?: ExperimentEventType): string {
    if (eventType === ExperimentEventType.InstrumentEventNonRoutineIssueEncountered) {
      return this.nonRoutineIssueEncountered;
    } else if (eventType === ExperimentEventType.InstrumentEventReturnedToService) {
      return this.returnedToService;
    } else {
      return this.impactAssessment;
    }
  }

  lockDropdown(identifier: string) {
    if (!this.experimentService.currentExperiment) {
      throw new Error(this.noCurrentExperimentFound);
    }
    this.activityOutputCollaboratorService.sendInputStatus(
      LockType.lock,
      this.experimentService.currentExperiment.id,
      [identifier],
      this.instrumentId
    );
  }

  unlockDropdown(identifier: string) {
    if (!this.experimentService.currentExperiment) {
      throw new Error(this.noCurrentExperimentFound);
    }
    this.activityOutputCollaboratorService.sendInputStatus(
      LockType.unlock,
      this.experimentService.currentExperiment.id,
      [identifier],
      this.instrumentId
    );
  }


  public getControlCustomDefinitionValidatorForNonRoutine(): Array<ControlCustomDefinition> {

    const activityId = this.experimentService.currentActivityId;
    const instrumentEvent = this.experimentService.currentExperiment?.instrumentEventImpactAssessmentData?.find((input) => input.activityId === activityId);

    if(!instrumentEvent) {
      return []
    }

    return [
      this.getControlCustomDefinitionForEmptyContent(() => {
        return this.isNonRoutineValueEmpty(instrumentEvent);
      }),
      this.getControlCustomDefinitionForModifiedContent(() => {
        return (
          this.isNonRoutineValueModified(instrumentEvent) &&
          !this.isNonRoutineValueEmpty(instrumentEvent)
        );
      })
    ];
  }

  public getControlCustomDefinitionValidatorForReturnToService(): Array<ControlCustomDefinition> {
    const activityId = this.experimentService.currentActivityId;
    const instrumentEvent = this.experimentService.currentExperiment?.instrumentEventImpactAssessmentData?.find((input) => input.activityId === activityId);

    if(!instrumentEvent) {
      return []
    }

    return [
      this.getControlCustomDefinitionForEmptyContent(() => {
        return this.isReturnedToServiceEmpty(instrumentEvent);
      }),
      this.getControlCustomDefinitionForModifiedContent(() => {
        return (
          this.isReturnedToServiceModified(instrumentEvent) &&
          !this.isReturnedToServiceEmpty(instrumentEvent)
        );
      })
    ];
  }

  public getControlCustomDefinitionForEmptyContent(applyCondition: () => boolean): ControlCustomDefinition {
    return BptControlSeverityIndicators.BuildSeverityIndicator(SeverityIndicatorType.Empty,
      { CanApply: applyCondition });
  }

  public getControlCustomDefinitionForModifiedContent(applyCondition: () => boolean): ControlCustomDefinition {
    return BptControlSeverityIndicators.BuildSeverityIndicator(SeverityIndicatorType.Modified,
      { CanApply: applyCondition });
  }

  public isNonRoutineValueEmpty(
    instrumentImpactAssessment: InstrumentEventImpactAssessmentNode
  ): boolean {
    return (
      (instrumentImpactAssessment.nonRoutineIssueEncountered.value.state === ValueState.Empty ||
        instrumentImpactAssessment.nonRoutineIssueEncountered.value === undefined) ??
      true
    );
  }

  public isNonRoutineValueModified(
    instrumentImpactAssessment: InstrumentEventImpactAssessmentNode
  ): boolean {
    return instrumentImpactAssessment.nonRoutineIssueEncountered?.isModified ?? false;
  }

  public isReturnedToServiceEmpty(
    instrumentImpactAssessment: InstrumentEventImpactAssessmentNode
  ): boolean {
    return (
      (instrumentImpactAssessment.returnedToService?.value?.state === ValueState.Empty ||
        instrumentImpactAssessment.returnedToService?.value === undefined) ??
      true
    );
  }

  public isReturnedToServiceModified(
    instrumentEventsDataSource: InstrumentEventImpactAssessmentNode
  ): boolean {
    return instrumentEventsDataSource.returnedToService?.isModified ?? false;
  }


  getContextMenu(event: string): FormContextMenuItem[] {
    const menu: FormContextMenuItem[] = [
      {
        label: $localize`:@@clientFacingNoteContextMenuOption:Client-facing Notes`,
        action: () => this.showNotesSlider(event),
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />'
      },
      {
        label: $localize`:@@commentsHeader:Internal Comments`,
        action: () => this.openInternalComments(event),
        icon: 'pi pi-comments'
      },
      {
        label: $localize`:@@history:History`,
        action: () => {
          if (event === 'Non-Routine Issue Encountered') {
            this.loadImpactAssessmentHistoryDialog(ExperimentEventType.InstrumentEventNonRoutineIssueEncountered, event);
          } else if (event === 'Returned to Service') {
            this.loadImpactAssessmentHistoryDialog(ExperimentEventType.InstrumentEventReturnedToService, event);
          }
        },
        icon: 'fa fa-history'
      }
    ]

    return menu;
  }

  showNotesSlider = (event: string) => {
    const eventTarget: FormFieldClientFacingNoteContext = {
      formId: this.experimentService.currentActivityId,
      fieldIdentifier: event
    };
    const details: ShowClientFacingNotesEventData = {
      eventContext: {
        contextType: ClientFacingNoteContextType.FormField,
        mode: 'clientFacingNotes'
      },
      targetContext: eventTarget
    };
    const customEvent = new CustomEvent<ShowClientFacingNotesEventData>('ShowSlider', {
      bubbles: true,
      detail: details
    });
    this.container.nativeElement.dispatchEvent(customEvent);
  };

    openInternalComments(event:string) {
    const activity = this.experimentService.currentActivity as Activity;
    this.internalCommentData = {} as CommentDetails;
    this.internalCommentData.nodeId = this.experimentService.currentExperiment?.id || '';
    this.internalCommentData.path = [activity.activityId, this.instrumentEvent, event, CommentContextType.OutputsInstrumentEvent];
    this.internalCommentData.contextType = CommentContextType.FormField;
    this.commentService.openInternalComments(this.internalCommentData);
  }

  getLeadingTabsCount(): number {
    let count = 0;
    if (this.experimentService._showOutputAttachments) {
      count++;
    }
    return count;
  }
}
