import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { KeyboardService } from 'bpt-ui-library/services';
import { InstrumentConnectionHelper } from '../instrument-connection/shared/instrument-connection-helper';
import { InstrumentReadingValue } from '../../api/models/instrument-reading-value';
import { InstrumentCategory, UnitResponse } from '../../api/models';
import { InstrumentType } from '../instrument-connection/shared/instrument-type';
import { Observable, Subscription, map } from 'rxjs';
import { UnitsService } from '../../api/services';
import { ConfirmationService } from 'primeng/api';
import { InstrumentConfigUnits } from '../instrument-connection/shared/instrument-config-units';
import { PhMeasurementCollection } from '../model/instrument-connection/ph-measurement-collection';
import { PhMeterMode } from '../model/instrument-connection/ph-meter-modes';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { Unit } from 'bpt-ui-library/shared';
import { HttpResponse } from '@angular/common/http';
import { TableDataService } from '../data/table/table-data.service';

@Component({
  selector: 'app-instrument-ph-reading',
  templateUrl: './instrument-ph-reading.component.html',
  styleUrls: ['./instrument-ph-reading.component.scss']
})
export class InstrumentPhReadingComponent implements OnInit, OnDestroy {
  @Output() closeEvent = new EventEmitter<string>();
  @Output() unitEvent = new EventEmitter<string>();
  @Output() actualValueEvent = new EventEmitter<InstrumentReadingValue>();
  @Input() columnUnitsForReading?: Unit[];
  @Input() sequentialReadingEnabled = false;
  phMeterName?: string;
  selectedPushValue = true;
  selectedMode?: PhMeterMode;
  disableDropDown = false;
  selectedUnit?: string;
  hidePhField = false;
  hideMvField = false;
  isReadyToCommit = false;
  doesCellAllowMvUnit = false;
  subscriptions: Subscription[] = [];
  /*model values
   temperature, ph and mv
  */
  temperatureValue?: string;
  phValue?: string;
  mvValue?: string;
  masterConfiguredUnit?: string;

  // pH meter reading received from device
  phMeasurementCollection?: PhMeasurementCollection;

  modeOptions = {
    options: [PhMeterMode.pH, PhMeterMode.mV]
  };

  private readonly mvUnitName = 'millivolts';

  constructor(
    private readonly instrumentConnectionHelper: InstrumentConnectionHelper,
    private readonly unitsService: UnitsService,
    private readonly confirmationService: ConfirmationService,
    private readonly keyboardService: KeyboardService,
    @Inject('ExperimentTableDataService') private readonly tableDataService: TableDataService<'experiment'>,
  ) {
    this.handleSubscriptions();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  ngOnInit(): void {
    this.setupReadingConfigs();
    this.handleUnitsForSequentialReading();
  }

  private handleUnitsForSequentialReading() {
    if (this.sequentialReadingEnabled) {
      this.masterConfiguredUnit = this.getMasterConfiguredMode();

      switch (this.masterConfiguredUnit) {
        case PhMeterMode.pH:
          this.disableDropDown = true;
          break;
        case PhMeterMode.mV:
          this.disableDropDown = false;
          break;
      }

      if (this.instrumentConnectionHelper.pHMeterSequentialReadingSelectedUnit
        && this.instrumentConnectionHelper.pHMeterSequentialReadingSelectedUnit !== this.masterConfiguredUnit) {
        this.selectedMode = this.instrumentConnectionHelper.pHMeterSequentialReadingSelectedUnit;
      } else {
        this.selectedMode = this.masterConfiguredUnit as PhMeterMode;
      }

    }
  }

  private getMasterConfiguredMode(): string {
    return localStorage.getItem('configuration-value-unit') ?? this.instrumentConnectionHelper.configuredUnit ?? '';
  }

  temperatureValueChanged() {
    this.isReadyToCommit = this.validateReadyToCommit();
    if (this.sequentialReadingEnabled && this.temperatureValue && this.isReadyToCommit) {
      this.commitValue();
    }
  }

  mvValueChanged() {
    this.isReadyToCommit = this.validateReadyToCommit();
  }

  phValueChanged() {
    this.isReadyToCommit = this.validateReadyToCommit();
  }

  closeOverlay() {
    this.tableDataService.terminateSequentialReadingSession.next(true);
    this.closeEvent.emit();
  }

  modeChanged(currentMode: PhMeterMode) {
    this.hidePhField = (currentMode !== PhMeterMode.pH);
    this.selectedUnit = InstrumentConfigUnits[currentMode];
    this.instrumentConnectionHelper.pHMeterSequentialReadingSelectedUnit = currentMode;
  }

  commitValue() {
    if (!this.phMeasurementCollection || !this.temperatureValue || !this.mvValue || !this.phValue) return;
    const parsedMvValue = parseFloat(this.mvValue);
    const parsedPhValue = parseFloat(this.phValue);
    const instrumentMetaData: { [key: string]: number | string | undefined; } = {
      temperatureValue: parseFloat(this.temperatureValue),
      mvValue: parsedMvValue,
      phValue: parsedPhValue,
      actual: this.selectedMode === PhMeterMode.pH ? parsedPhValue : parsedMvValue,
      temperatureUnit: this.phMeasurementCollection.Measurements[0].TemperatureUnit,
      mvUnit: this.phMeasurementCollection.Measurements[0].PotentialUnit,
      phUnit: this.phMeasurementCollection.Measurements[0].PhUnit,
      phMeterMode: this.selectedMode
    };

    const emittedValue: InstrumentReadingValue = {
      category: InstrumentCategory.PhMeter,
      instrumentMetaData: instrumentMetaData,
      equipmentId: this.phMeasurementCollection.DeviceInfo.SampleId,
      instrumentName: this.phMeasurementCollection.DeviceInfo.InstrumentModel,
      instrumentType: InstrumentType.phMeter,
      manufacturer: this.phMeasurementCollection.DeviceInfo.SoftwareRevision,
      modelNumber: this.phMeasurementCollection.DeviceInfo.InstrumentModel,
      serialNumber: this.phMeasurementCollection.DeviceInfo.SerialNumber
    };

    if (this.selectedUnit !== undefined && this.selectedUnit !== null ) {
      this.getUnit(this.selectedUnit).subscribe((unitId: string) => {
        this.unitEvent.emit(unitId);
        this.actualValueEvent.emit(emittedValue);
        if (this.sequentialReadingEnabled) {
          this.tableDataService.actualValueAdded.next(emittedValue);
        }
        this.closeEvent.emit();
      });
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (!this.keyboardService.isTabOrEnter(event))
      event.preventDefault();
  }

  private setupReadingConfigs(): void {
    this.doesCellAllowMvUnit = this.columnUnitsForReading?.find(c => c.abbreviation === InstrumentConfigUnits.mV && c.name === this.mvUnitName) !== undefined;
    this.selectedMode = this.doesCellAllowMvUnit && this.instrumentConnectionHelper.configuredUnit === PhMeterMode.mV ? PhMeterMode.mV : PhMeterMode.pH;
    this.disableDropDown = !this.doesCellAllowMvUnit;
    this.selectedUnit = InstrumentConfigUnits[this.selectedMode];
  }

  private handleSubscriptions() {
    this.subscriptions.push(
      this.instrumentConnectionHelper.phMeterReadingsReceived.subscribe((data: PhMeasurementCollection) => {
        this.phMeasurementCollection = data;
        if (this.validateReadyToCommit()) {
          this.confirmationService.confirm({
            header: $localize`:@@overwriteConfirmation:Overwrite Confirmation`,
            message: $localize`:@@overwriteConfirmationMessage:Do you want to Overwrite the values ?`,
            accept: () => {
              this.setMeasurements(data);
            }
          });
          return;
        }
        this.setMeasurements(data);
      }))
  }

  private setMeasurements(data: PhMeasurementCollection) {
    const tempUnit = data.Measurements[0].TemperatureUnit.includes('C') ? '°C' : '°F';
    this.temperatureValue = `${data.Measurements[0].TemperatureValue} ${tempUnit}`;
    this.mvValue = `${data.Measurements[0].PotentialValue} ${data.Measurements[0].PotentialUnit}`;
    this.phValue = `${data.Measurements[0].PhValue} ${data.Measurements[0].PhUnit}`;
  }

  private validateReadyToCommit(): boolean {
    if (this.selectedMode === PhMeterMode.mV) {
      return (this.temperatureValue && this.mvValue) ? true : false;
    }
    return (this.temperatureValue && this.mvValue && this.phValue) ? true : false;
  }

  private getUnit(targetUnit: string): Observable<string> {
    return this.unitsService.unitsGet$Json$Response().pipe(
      map((unitsList: HttpResponse<UnitResponse>) => {
        return unitsList.body?.units.find((unit: Unit) => unit.abbreviation === targetUnit)?.id ?? '';
      })
    );
  }
}
