import { Injectable } from '@angular/core';
import { Activity, Experiment, Module } from 'model/experiment.interface';
import { ModuleNode, NodeType, SubBusinessUnit, TemplateType } from '../../../api/models';
import {
  ExperimentTemplateAppliedResponse,
  ExperimentTemplateApplyCommand
} from '../../../api/data-entry/models';
import { ExperimentTemplateInsertLocationOptions } from '../models/experiment-template-insert-location-options';
import { SelectedTemplate } from '../../models/selected-template';
import { ExperimentTemplateEventService } from './experiment-template-event.service';
import { ExperimentTemplateOptionalLocationReferenceResponse } from '../../../api/data-entry/models/experiment-template-optional-location-reference-response';
import { TemplateLocationOptions } from '../../../recipe-template-loader/experiment-template-load/models/insert-location.interface';
import { ControlType, SearchControl } from 'bpt-ui-library/bpt-search';
import { SelectedTemplateCommand } from '../../../recipe-template-loader/models/template-loader-information';
import { cloneDeep } from 'lodash-es';
import { UserService } from '../../../services/user.service';
import { TemplateInsertLocationOptions } from '../../../recipe-template-loader/experiment-template-load/models/recipe-template-insert-location-options';

export type ActiveTemplateModule = Module | ModuleNode;
export type DefinedActiveTemplate = Activity | ActiveTemplateModule;
export type ActiveTemplate = DefinedActiveTemplate | undefined;

type InsertLocation = ExperimentTemplateOptionalLocationReferenceResponse;
@Injectable({
  providedIn: 'root'
})
export class ExperimentTemplateApplyService {
  private static readonly InsertLocationMapOf: {
    [sourceTemplateType: string]: NodeType;
  } = {
    table: NodeType.Module,
    form: NodeType.Module,
    module: NodeType.Activity,
    activity: NodeType.Experiment
  };

  static readonly MapperToSourceTemplateTypeBasedOnInsertOption: {
    [sourceTemplateType: string]: TemplateType;
  } = {
    newModuleExistingActivity: TemplateType.Module,
    newActivity: TemplateType.Activity,
    existingModule: TemplateType.Form,
  };
  private command: ExperimentTemplateApplyCommand = {} as ExperimentTemplateApplyCommand;
  private static readonly BreadcrumbDefaults = { tooltipPosition: 'bottom' };
  titleCount = 0;
  originalTitle = '';
  insertLocationOptions: ExperimentTemplateInsertLocationOptions[] = [];

  constructor(
    private readonly experimentTemplateEventService: ExperimentTemplateEventService,
    private readonly userService: UserService,
    ) {
    this.experimentTemplateEventService.TemplateAppliedResponse.subscribe((response) => {
      this.processExperimentTemplateAppliedResponse(response);
    });
  }

  private processExperimentTemplateAppliedResponse(response: ExperimentTemplateAppliedResponse) {
    if (response.notifications && response.notifications.notifications.length > 0) {
      this.experimentTemplateEventService.TemplateApplyFailedNotification(response.notifications);
    } else {
      this.experimentTemplateEventService.TemplateApplySuccessNotification(response);
    }
  }

  public StartCommandPreparation(
    command: ExperimentTemplateApplyCommand
  ): ExperimentTemplateApplyService {
    command.skipVerifiedTemplateValidation = false;
    this.command = command;
    return this;
  }

  public startCommand(): ExperimentTemplateApplyService {
    this.command.skipVerifiedTemplateValidation = false;
    return this;
  }

  public PublishApplyExperimentTemplateCommand(): ExperimentTemplateApplyService {
    this.setExperimentReference();
    this.experimentTemplateEventService.PublishApplyExperimentTemplateCommand(this.command);
    return this;
  }

  public SetInsertedLocation(
    locationOption: TemplateLocationOptions,
    moduleOrActivityReferenceId: string,
    selectedTemplateLocationNodeId: string | undefined
  ): ExperimentTemplateApplyService {
    switch (locationOption) {
      case TemplateLocationOptions.AddToExistingModule:
        this.setExistingModuleLocation(
          moduleOrActivityReferenceId,
          selectedTemplateLocationNodeId as string
        );
        break;
      case TemplateLocationOptions.AddAsExistingActivity:
        this.setExistingActivityLocation(
          moduleOrActivityReferenceId,
          selectedTemplateLocationNodeId as string
        );
        break;
      case TemplateLocationOptions.AddAsNewModuleAndExistingActivity:
        this.setNewModuleLocation();
        this.setExistingActivityLocation(
          moduleOrActivityReferenceId,
          selectedTemplateLocationNodeId as string
        );
        break;
      case TemplateLocationOptions.AddAsNewActivityAndModule:
        this.setNewModuleLocation();
        this.setNewActivityLocation();
        break;
      case TemplateLocationOptions.AddAsNewActivity:
        this.setNewActivityLocation();
        break;
      case TemplateLocationOptions.None:
        break;
    }
    return this;
  }

  public SetTableTitleWhenSourceTemplateIsTable(title: string): ExperimentTemplateApplyService {
    if (this.command.sourceTemplateType === TemplateType.Table) {
      this.command.templateTitle = title;
    }
    return this;
  }

  public setRootActivityId(activityId: string | undefined): ExperimentTemplateApplyService{
    this.command.activityId = activityId;
    return this;
  }

  public SetFormTitleWhenSourceTemplateIsForm(title: string): ExperimentTemplateApplyService {
    if (this.command.sourceTemplateType === TemplateType.Form) {
      this.command.templateTitle = title;
    }
    return this;
  }

  public setTableRelatedTitle(selectedTemplateLocationId: string): ExperimentTemplateApplyService {
    if (this.command.sourceTemplateType === TemplateType.Table && this.command.moduleReference && !this.command.moduleReference.isSynthetic) {
      this.command.templateTitle = this.generateTitleIfAlreadyExists(
        selectedTemplateLocationId, this.command.templateTitle
      );
    } 
    return this;
  }

  public setFormRelatedTitle(selectedTemplateLocationId: string): ExperimentTemplateApplyService {
    if (this.command.sourceTemplateType === TemplateType.Form && this.command.moduleReference && !this.command.moduleReference.isSynthetic) {
      this.command.templateTitle = this.generateTitleIfAlreadyExists(
        selectedTemplateLocationId, this.command.templateTitle
      );
    }
    return this;
  }

  public SetModuleTitle(title: string): ExperimentTemplateApplyService {
    if (this.command.sourceTemplateType === TemplateType.Module) {
      this.command.templateTitle = title;
    } else if (this.command.moduleReference && this.command.moduleReference.isSynthetic && title) {
      this.command.moduleReference.templateTitle = title;
    }
    return this;
  }

  public setModuleRelatedTitle(selectedTemplateLocationId: string): ExperimentTemplateApplyService {
    if (this.command.sourceTemplateType === TemplateType.Module) {
      this.command.templateTitle = this.generateTitleIfAlreadyExists(
        selectedTemplateLocationId, this.command.templateTitle
      );
    } else if (this.command.moduleReference && this.command.moduleReference.isSynthetic) {
      this.command.moduleReference.templateTitle = this.generateTitleIfAlreadyExists(
        selectedTemplateLocationId, this.command.templateTitle
      );
    }
    return this;
  }

  public SetActivityTitle(title: string): ExperimentTemplateApplyService {
    if (this.command.sourceTemplateType === TemplateType.Activity) {
      this.command.templateTitle = title;
    } else if (
      this.command.activityReference &&
      this.command.activityReference.isSynthetic &&
      title
    ) {
      this.command.activityReference.templateTitle = title;
    }
    return this;
  }

  public setActivityRelatedTitle(): ExperimentTemplateApplyService {
    const experiment = this.experimentTemplateEventService.LoadedExperiment();
    if (this.command.sourceTemplateType === TemplateType.Activity) {
      this.command.templateTitle = this.generateTitleIfAlreadyExists(
        experiment?.id as string, this.command.templateTitle
      );
    } else if (
      this.command.activityReference &&
      this.command.activityReference.isSynthetic 
    ) {
      this.command.activityReference.templateTitle = this.generateTitleIfAlreadyExists(
        experiment?.id as string, this.command.templateTitle
      );
    }
    return this;
  }

  private setExperimentReference() {
    const experiment = this.experimentTemplateEventService.LoadedExperiment();
    this.command.experimentId = experiment?.id as string;
    this.command.experimentNumber = experiment?.experimentNumber as string;
  }
  private setNewModuleLocation(): void {
    if (this.command.sourceTemplateType === TemplateType.Module) {
      return;
    }
    this.command.moduleReference = {
      isSynthetic: true
    } as InsertLocation;
  }

  private generateTitleIfAlreadyExists(selectedTemplateLocationId: string, title: string, originalTitle = ''): string {
    let newTitle = '';
    if(originalTitle === '') {
      originalTitle = title;
    }
    if (
      this.insertLocationOptions
        .find((ilo) => ilo.id === selectedTemplateLocationId)
        ?.childrenTitle.some((ct) => ct.toLowerCase() === title.toLowerCase())
    ) {
      this.titleCount++;
      newTitle = originalTitle.concat(' ', this.titleCount.toString())
      return this.generateTitleIfAlreadyExists(
        selectedTemplateLocationId,
        newTitle, originalTitle
      );
    } else {
      this.originalTitle = title;
      this.titleCount = 0;
      return title;
    }
  }

  private setNewActivityLocation(): void {
    if (this.command.sourceTemplateType === TemplateType.Activity) {
      return;
    }
    if (
      this.command.sourceTemplateType === TemplateType.Form ||
      this.command.sourceTemplateType === TemplateType.Table
    ) {
      this.command.moduleReference = {
        isSynthetic: true,
        templateTitle: this.command.templateTitle 
      } as InsertLocation;
    }
    
    this.command.activityReference = {
      isSynthetic: true
    } as InsertLocation;
  }

  private setExistingModuleLocation(moduleId: string, nodeId: string): void {
    this.command.moduleReference = {
      templateReferenceId: moduleId,
      isSynthetic: false,
      templateTitle: '',
      templateNodeId: nodeId
    } as InsertLocation;
  }

  private setExistingActivityLocation(activityId: string, nodeId: string): void {
    this.command.activityReference = {
      templateReferenceId: activityId,
      isSynthetic: false,
      templateTitle: '',
      templateNodeId: nodeId
    } as InsertLocation;
  }

  public getDefaultLocationBySourceTemplateType(selectedTemplateType: string): string | undefined {
    const currentActiveTemplate: ActiveTemplate = this.getCurrentActiveTemplate();
    if (!currentActiveTemplate) {
      return undefined;
    }
    if (
      currentActiveTemplate.itemType === NodeType.Activity &&
      selectedTemplateType === TemplateType.Module
    ) {
      return (currentActiveTemplate as Activity).activityId;
    }
    if (
      currentActiveTemplate.itemType === NodeType.Module &&
      selectedTemplateType === TemplateType.Module
    ) {
      return this.getActivityIdByActiveModule(currentActiveTemplate as Module);
    }
    if (selectedTemplateType === TemplateType.Form || selectedTemplateType === TemplateType.Table) {
      return this.getActiveModuleIdOrFirstInActivity(currentActiveTemplate);
    }
    return undefined;
  }

  public getDefaultLocationByInsertLocation(insertLocationType: NodeType): string | undefined {
    const currentActiveTemplate: ActiveTemplate = this.getCurrentActiveTemplate();
    if (!currentActiveTemplate) {
      return undefined;
    }
    switch (insertLocationType) {
      case NodeType.Module:
        return this.getActiveModuleIdOrFirstInActivity(currentActiveTemplate);
      case NodeType.Activity:
        return this.getActivityIdByActiveModuleAndActivity(currentActiveTemplate);
      default:
        return undefined;
    }
  }

  private getActivityIdByActiveModuleAndActivity(
    currentActiveTemplate: DefinedActiveTemplate
  ): string {
    if (currentActiveTemplate.itemType === NodeType.Activity) {
      return (currentActiveTemplate as Activity).activityId;
    } else {
      return this.getActivityIdByActiveModule(currentActiveTemplate as Module);
    }
  }

  private getActiveModuleIdOrFirstInActivity(currentActiveTemplate: DefinedActiveTemplate): string {
    if (currentActiveTemplate.itemType === NodeType.Module) {
      return (currentActiveTemplate as Module).moduleId;
    } else {
      return ((currentActiveTemplate as Activity).dataModules[0] as Module).moduleId;
    }
  }

  private getActivityIdByActiveModule(currentActiveTemplate: Module): string {
    const experiment = this.experimentTemplateEventService.LoadedExperiment();
    return experiment?.activities.find((a) =>
      a.dataModules.some((m) => (m as Module).moduleId === currentActiveTemplate.moduleId)
    )?.activityId as string;
  }

  public buildCommandForSourceTemplateSelectionStep(selectedTemplate: SelectedTemplate) {
    let currentChangedCommand: ExperimentTemplateApplyCommand =
      {} as ExperimentTemplateApplyCommand;
    const subscription =
      this.experimentTemplateEventService.LastUpdatedTemplateApplyCommand.subscribe(
        (lastUpdatedCommand) => {
          currentChangedCommand = ExperimentTemplateApplyService.updateSourceTemplateToCommand(
            selectedTemplate,
            lastUpdatedCommand || ({} as ExperimentTemplateApplyCommand)
          );
        }
      );
    subscription.unsubscribe();
    this.command = currentChangedCommand;
    this.experimentTemplateEventService.ApplyTemplateCommandChangedNotification(
      currentChangedCommand
    );
  }

  public ResetExperimentApplyCommand() {
    this.experimentTemplateEventService.ApplyTemplateCommandChangedNotification(
      {} as ExperimentTemplateApplyCommand
    );
  }

  templateSelectionChanged(selectedTemplate: SelectedTemplate): void {
    this.ResetExperimentApplyCommand();
    this.buildCommandForSourceTemplateSelectionStep(selectedTemplate);
  }

  applySelectedTemplate(selectedTemplateInformation: SelectedTemplateCommand): void {
    this.startCommand()
    .SetInsertedLocation(
      selectedTemplateInformation.selectedInsertOption as TemplateLocationOptions,
      selectedTemplateInformation.selectedTemplateLocationId as string,
      this.getNodeIdBySelectedTemplateLocationId(selectedTemplateInformation.insertLocationOptions, selectedTemplateInformation.selectedTemplateLocationId as string)
      )
    .setActivityRelatedTitle()
    .setRootActivityId(selectedTemplateInformation.rootActivityId)
    .setModuleRelatedTitle(selectedTemplateInformation.selectedTemplateLocationId as string)
    .setTableRelatedTitle(selectedTemplateInformation.selectedTemplateLocationId as string)
    .setFormRelatedTitle(selectedTemplateInformation.selectedTemplateLocationId as string)
    .PublishApplyExperimentTemplateCommand()
  }

  private getNodeIdBySelectedTemplateLocationId(insertLocationOptions: Array<TemplateInsertLocationOptions>, selectedTemplateLocationId: string): string | undefined {
    return insertLocationOptions.find((x) => x.id === selectedTemplateLocationId)?.nodeId;
  }

  static loadInsertOptionsForTemplateLoader(currentExperiment : Experiment) {
    const newActivity = { label: $localize`:@@InsertOptionAddNewActivity:New activity`, value: TemplateLocationOptions.AddAsNewActivity };
    const existingModule = { label: $localize`:@@InsertOptionAddToExistingModule:To existing module`, value: TemplateLocationOptions.AddToExistingModule };
    const existingActivityAsModule = { label: $localize`:@@InsertOptionAddToExistingActivityAndNewModule:To existing activity as new module`, value: TemplateLocationOptions.AddAsNewModuleAndExistingActivity }
    if (currentExperiment && currentExperiment.activities.length > 0) {
      return [newActivity, existingActivityAsModule, existingModule];
    } else {
      return [newActivity];
    }
  }

  private static readonly insertLocationMapOf: {
    [sourceTemplateType: string]: string[];
  } = {
      newActivity: [
        TemplateType.Table,
        TemplateType.Form,
        TemplateType.Module,
        TemplateType.Activity
      ],
      existingModule: [TemplateType.Table, TemplateType.Form],
      newModuleExistingActivity: [TemplateType.Table, TemplateType.Form, TemplateType.Module]
    };

  private static buildTemplateTypeFilter(insertOption: string) {
    const type =  ExperimentTemplateApplyService.insertLocationMapOf[insertOption];
    return type.map(t => {
      return { label: t, value: t, inactive: false }
    });
  }

  createTemplateOptionsQuery(insertOption: string) {
    return {
      getLatestVersion: true,
      templateTypes: ExperimentTemplateApplyService.insertLocationMapOf[insertOption].join(','),
      consumingLabsiteCodes: this.userService.currentUser.labSiteCode
    };
  }

  static templateLoaderAlterFilter(insertOption: string, searchControls: Array<SearchControl>, fieldName?: string) {
    ExperimentTemplateApplyService.resetFiltersAccessibility(insertOption, searchControls, fieldName);
  }

  private static resetFiltersAccessibility(
    insertOption: string,
    searchControls: Array<SearchControl>,
    fieldName?: string,
  ) {
    if (fieldName === 'baseType') {
        searchControls.splice(
          searchControls.findIndex((f) => f.fieldName === 'recipeTypes'),
        1,
        ExperimentTemplateApplyService.customizeTypeFilter(insertOption)
      );
    }
  }

  private static customizeTypeFilter(insertOption: string) {
    return {
      controlType: ControlType.List,
      fieldName: 'recipeTypes',
      label: $localize`:@@Type:Type`,
      options: ExperimentTemplateApplyService.buildTemplateTypeFilter(insertOption),
      labelField: 'label',
      valueField: 'value',
      order: 2,
      value: ExperimentTemplateApplyService.insertLocationMapOf[insertOption],
      multiSelect: true,
    }
  }


  static constructFilters(insertOption: string, subBusinessUnits: SubBusinessUnit[]) {
    return [
      {
        controlType: ControlType.List,
        fieldName: 'baseType',
        label: $localize`:@@baseType:Base Type`,
        options: [
          { label: 'Template', value: 'Template' }
        ],
        labelField: 'label',
        valueField: 'value',
        order: 1,
        value: ['Template'],
        multiSelect: true
      },
      {
        controlType: ControlType.List,
        fieldName: 'recipeTypes',
        label: $localize`:@@Type:Type`,
        options: ExperimentTemplateApplyService.buildTemplateTypeFilter(insertOption),
        labelField: 'label',
        valueField: 'value',
        order: 2,
        value: this.insertLocationMapOf[insertOption],
        multiSelect: true,
      },
      {
        controlType: ControlType.List,
        fieldName: 'sbu',
        label: $localize`:@@sbu:SBU`,
        options: subBusinessUnits,
        order: 3,
        value: [],
        multiSelect: true,
        labelField: 'displayLabel',
        valueField: 'code'
      },
      {
        controlType: ControlType.List,
        fieldName: 'client',
        label: $localize`:@@createPreparationClient:Client`,
        options: [],
        order: 4,
        value: [],
        multiSelect: true,
        disabled: true
      },
      {
        controlType: ControlType.List,
        fieldName: 'projects',
        label: $localize`:@@projects:Projects`,
        options: [],
        order: 5,
        value: undefined,
        multiSelect: true,
        disabled: true
      }
    ];
  }

  private static updateSourceTemplateToCommand(
    selectedTemplate: SelectedTemplate,
    currentCommandState: ExperimentTemplateApplyCommand
  ): ExperimentTemplateApplyCommand {
    const currentChangedCommand: ExperimentTemplateApplyCommand = currentCommandState;
    currentChangedCommand.templateReferenceId = selectedTemplate?.id;
    currentChangedCommand.templateTitle = selectedTemplate.name;
    currentChangedCommand.sourceTemplateType = selectedTemplate.templateType;
    return currentChangedCommand;
  }

  private getCurrentActiveTemplate(): ActiveTemplate {
    let currentActiveTemplate: ActiveTemplate;
    const subscription = this.experimentTemplateEventService.ActiveTemplateChanged.subscribe(
      (activeTemplate) => {
        currentActiveTemplate = activeTemplate;
      }
    );
    subscription.unsubscribe();
    return currentActiveTemplate;
  }

  public prepareInsertLocationOptionsBySourceTemplateType(
    sourceTemplateType: TemplateType
  ): ExperimentTemplateInsertLocationOptions[] {
    const insertLocationType =
      ExperimentTemplateApplyService.InsertLocationMapOf[sourceTemplateType] || NodeType.Invalid;

    let options: ExperimentTemplateInsertLocationOptions[] = [];
    if (insertLocationType === NodeType.Experiment) {
      options = [this.prepareInsertLocation()]
    } else {
      options = this.prepareInsertLocationOptionsToApplyTemplate();
      options = options.filter((s) => s.type === insertLocationType);
    }
    this.insertLocationOptions = options;
    return options;
  }

  public prepareExperimentInsertLocation(): ExperimentTemplateInsertLocationOptions {
    const experiment = this.experimentTemplateEventService.LoadedExperiment();
    const title = `Experiment: ${experiment?.title}`;
    return {
      id: experiment?.id as string,
      displayLabel: title,
      displayLabelWithPrefix: title,
      type: NodeType.Experiment,
      breadcrumb: [
        {
          label: experiment?.title as string,
          tooltipOptions: {
            tooltipLabel: experiment?.title as string,
            ...ExperimentTemplateApplyService.BreadcrumbDefaults
          }
        }
      ],
      childrenTitle: [],
      nodeId: experiment?.id as string
    };
  }

  public prepareInsertLocation(): ExperimentTemplateInsertLocationOptions {
    const experiment = this.experimentTemplateEventService.LoadedExperiment();
    const title = `Experiment: ${experiment?.title}`;
    return {
      id: experiment?.id as string,
      displayLabel: title,
      displayLabelWithPrefix: title,
      type: NodeType.Experiment,
      breadcrumb: [
        {
          label: experiment?.title as string,
          tooltipOptions: {
            tooltipLabel: experiment?.title as string,
            ...ExperimentTemplateApplyService.BreadcrumbDefaults
          }
        }
      ],
      childrenTitle: experiment?.activities.map((a : Activity) => a.itemTitle) as string[],
      nodeId: experiment?.id as string
    };
  }

  public prepareInsertLocationOptionsToApplyTemplate(): ExperimentTemplateInsertLocationOptions[] {
    const experiment = this.experimentTemplateEventService.LoadedExperiment();
    let templateList: ExperimentTemplateInsertLocationOptions[] = [];
    if (!experiment) {
      return templateList;
    }
    experiment.activities.forEach((activity) => {
      ExperimentTemplateApplyService.addActivitiesAsInsertLocation(
        templateList,
        activity,
        experiment.title
      );
      templateList = templateList.concat(
        ExperimentTemplateApplyService.prepareModulesAsInsertLocations(activity, experiment.title)
      );
    });
    return templateList;
  }

  private static addActivitiesAsInsertLocation(
    templateList: ExperimentTemplateInsertLocationOptions[],
    activity: Activity,
    experimentTitle: string
  ): void {
    templateList.push({
      id: activity.activityId,
      displayLabel: `${activity.itemTitle}`,
      displayLabelWithPrefix: $localize`:@@activity:Activity: ${activity.itemTitle}`,
      type: activity.itemType,
      activityId: activity.activityId,
      breadcrumb: [
        {
          label: experimentTitle,
          tooltipOptions: {
            tooltipLabel: experimentTitle,
            ...ExperimentTemplateApplyService.BreadcrumbDefaults
          }
        },
        {
          label: activity.itemTitle,
          tooltipOptions: {
            tooltipLabel: activity.itemTitle,
            ...ExperimentTemplateApplyService.BreadcrumbDefaults
          }
        }
      ],
      childrenTitle: activity.dataModules.map((module) => module.moduleLabel),
      nodeId: activity.nodeId as string
    });
  }

  private static prepareModulesAsInsertLocations(
    activity: Activity,
    experimentTitle: string
  ): ExperimentTemplateInsertLocationOptions[] {
    return activity.dataModules.map((module) => {
      return {
        id: (module as any as ModuleNode).moduleId,
        nodeId: module.nodeId as string,
        displayLabel: `${activity.itemTitle} / ${module.moduleLabel}`,
        type: module.itemType,
        displayLabelWithPrefix: [$localize`:@@activity:Activity: ${activity.itemTitle}`, $localize`:@@module:Module: ${module.moduleLabel}`].join(', '),
        childrenTitle: module.items.map((c) => c.itemTitle),
        activityId: activity.activityId,
        breadcrumb: [
          {
            label: experimentTitle,
            tooltipOptions: {
              tooltipLabel: experimentTitle,
              ...ExperimentTemplateApplyService.BreadcrumbDefaults
            }
          },
          {
            label: activity.itemTitle,
            tooltipOptions: {
              tooltipLabel: activity.itemTitle,
              ...ExperimentTemplateApplyService.BreadcrumbDefaults
            }
          },
          {
            label: module.moduleLabel,
            tooltipOptions: {
              tooltipLabel: module.moduleLabel,
              ...ExperimentTemplateApplyService.BreadcrumbDefaults
            }
          }
        ]
      };
    });
  }
}
