import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PreparationService } from '../services/preparation.service';
import { Subscription } from 'rxjs';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { Quantity, Unit, ValueState } from 'bpt-ui-library/shared';
import { LocalDate, ZoneId, ZonedDateTime, Instant, ChronoUnit} from '@js-joda/core';
import { ExpirationModel, ExpirationType } from '../models/preparation.model';
import { BptDateTimeComponent } from 'bpt-ui-library/bpt-datetime';
import { FieldType, InstantValue, LocalDateValue, TimeSelectOption, ValueType } from '../../api/models';
import { PreparationEventService } from '../services/preparation-event.service';
import { PreparationConstants } from '../preparation-constants';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-preparation-expiration-builder',
  templateUrl: './preparation-expiration-builder.component.html',
  styleUrls: ['./preparation-expiration-builder.component.scss']
})
export class PreparationExpirationBuilderComponent implements OnInit, OnDestroy {
  sliderOptions = {
    size: 'small',
    visible: false,
    closeOnEscape: false,
    headerText: PreparationConstants.expirationTitle,
    isFooterSticky: false,
    displayFooter: false,
    displayFooterWithPrimaryButton: false,
    displayFooterWithSecondaryButton: false,
    isModal: true
  };
  optionsForDuration: Array<{ label: string, value: string }> = [];
  duration: any;
  dropdownOptions = {
    showClear: false,
    showFilter: false,
    multiSelect: false
  }
  quantityOptions = {
    commitValueOnEnter: true,
    enableSignificantFigures: false,
    cancelValueOnEscape: true,
    allowNA: false,
    allowDecimal: false,
    highlightAllOnFocus: false,
    suppressContextMenu: true,
  }
  whileSuitableForUse = false;
  subscriptions: Subscription[] = [];
  public isReadOnly = false;
  timeUnits: Unit[] = [];
  expirationData: ExpirationModel = {
    suitableForUse: false,
  };
  selectedDuration! : Quantity;
  @ViewChild('dateTime', { static: false }) dateTime!: BptDateTimeComponent;
  @Input() sourceGridData: ExpirationModel = { suitableForUse: false };
  @Output() closeExpirationSlider = new EventEmitter();
  expiration: Instant | LocalDate | undefined = undefined;
  suitableForUseText = PreparationConstants.suitableForUseText;
  basedOnStability = PreparationConstants.basedOnStability;
  pastExpirationMsg = PreparationConstants.pastExpirationMsg;
  expirationRequired = PreparationConstants.fieldRequired;
  warningSummary = PreparationConstants.warningSummary;
  private readonly currentDate = LocalDate.now();

  expirationOptions = {
    hourFormat: '12' as typeof BptDateTimeComponent.prototype.hourFormat,
    disabled: this.isReadOnly,
    commitValueOnEnter: true,
    errorMessage: '',
    errorFlag: false,
    includeTime: TimeSelectOption.Optional
  }
  isParentRecipe = false;

  constructor(
    private readonly preparationService: PreparationService,
    private readonly preparationEventService: PreparationEventService
  ) {
    this.populateTimeUnits();
  }

  /** IANA time zone id for lab site */
  get labSiteTimeZone(): string {
    return UserService.currentLabSiteTimeZone.id();
  }

  ngOnInit() {
    this.isParentRecipe = this.preparationService.ParentItemType === "Recipe";
    this.isReadOnly = this.preparationService.isSliderContentDisabled();
    this.setSliderState();
    this.expirationData = this.sourceGridData?.expirationDate === undefined && !this.isParentRecipe ? this.preparationService.expirationModel : this.sourceGridData;
    this.setExpirationDetails();
  }

  private setExpirationDetails() {
    this.duration = this.expirationData.duration;
    this.expiration = !this.expirationData?.expirationDate || this.expirationData.suitableForUse ? this.currentDate : this.expirationData.expirationDate;
    this.whileSuitableForUse = this.expirationData.suitableForUse;
  }

  private setSliderState() {
    this.subscriptions.push(this.preparationEventService.openExpirationSlider$.subscribe((showSlider: boolean) => {
      this.sliderOptions.visible = showSlider;
    }));
  }

  /**
* Sets the slider visible state if slider is closed
*/
  sliderVisibleChange(sliderOpen: boolean) {
    if (!sliderOpen) {
      this.resetAndCloseSlider();
      this.closeExpirationSlider.emit(false);
    }
    else if(!this.isParentRecipe){
      this.expiration = this.getExpirationDateToDisplay(this.expiration)
    }
  }

  populateTimeUnits() {
    if (this.preparationService.preparationUnits && this.preparationService.preparationUnits.length === 0)
      this.preparationService.getQuantityUnits();
    this.timeUnits = this.preparationService.preparationUnits[1];
  }

  /**
   * It converts selected duration to provided time unit type and adds it to current expiration value
   */
  convertDuration(duration: Quantity) {
    this.selectedDuration = duration;
    if (duration.value && duration.unitDetails)
      this.addTimeToDate(parseFloat(duration.value), duration.unitDetails.abbreviation)
  }

  addTimeToDate(amount: number, unit: string) {
    const futureDateTime: ZonedDateTime = ZonedDateTime.ofInstant(Instant.now(), ZoneId.systemDefault());
    let selectedExpiration;
    switch (unit) {
      case 'days':
        selectedExpiration = futureDateTime.plusDays(amount);
        break;
      case 'weeks':
        selectedExpiration = futureDateTime.plusWeeks(amount);
        break;
      case 'months':
        selectedExpiration = futureDateTime.plusMonths(amount);
        break;
      case 'years':
        selectedExpiration = futureDateTime.plusYears(amount);
        break;
      case 'min':
        selectedExpiration = futureDateTime.plusMinutes(amount);
        break;
      case 'hours':
        selectedExpiration = futureDateTime.plusHours(amount);
        break;
      default:
        throw new Error('Invalid time unit');
    }
    // If include time is checked then return Instant to bind calendar otherwise return localDate
    this.expiration = unit === 'hours' || unit === 'min'
      ? selectedExpiration.truncatedTo(ChronoUnit.SECONDS).toInstant()
      : selectedExpiration.toLocalDate();

  }

  /**
   * It returns expiration based on whether date is supplied or not. 
   */
  getExpirationDateToDisplay(date?: Instant | LocalDate): Instant | LocalDate {
    return date || this.currentDate;
  }

  /**
   * It sets the expiration value based on calendar selection 
   * It also sets the global futureDateTime value based on calendar date selection and isIncludeTimeChecked
   */
  setDateFromCalendar(date: any) {
    if (date || this.dateTime['calendar'].inputfieldViewChild.nativeElement.value) {
      this.expiration = date;
      this.expirationOptions.errorFlag = false;
      this.expirationOptions.errorMessage = '';
      if (date instanceof Instant) {
        if (date.isBefore(Instant.now())) {
          this.showExpirationWarning();
        }
      }
      else {
        if (date.isBefore(this.currentDate)) {
          this.showExpirationWarning();
        }
      
      }
    }
    else  {
      this.setErrorFlagRequired();
    }
  }
  private setErrorFlagRequired() {
    this.expirationOptions.errorFlag = true;
    this.expirationOptions.errorMessage = this.expirationRequired;
  }
  private showExpirationWarning() {
    this.preparationService.buildToastMessage('notification', 'warn', 'pastExpirationDate', this.pastExpirationMsg, false, this.warningSummary);
  }

  cancelExpiration() {
    this.resetAndCloseSlider();
  }

  commitExpiration() {
    if (!this.dateTime?.['calendar']?.inputfieldViewChild?.nativeElement?.value && !this.whileSuitableForUse && !this.isParentRecipe) {
      this.setErrorFlagRequired();
      return;
    }
    const expiration: ExpirationType = {
      preparationId: this.expirationData.preparationId,
      suitableForUse: this.whileSuitableForUse,
      expirationDate: this.isParentRecipe ? this.whileSuitableForUse ? {
        state: ValueState.NotApplicable,
        type: ValueType.LocalDate
      } : {
        state: ValueState.Empty,
        type: ValueType.LocalDate
      } : this.getExpirationAsExperimentDataValue(this.whileSuitableForUse, this.expiration),
      duration:
        this.selectedDuration && this.selectedDuration.value && this.selectedDuration.unitDetails
          ? this.selectedDuration
          : undefined  
      }

    if (!this.isReadOnly && 
      (expiration.suitableForUse || ( (this.isParentRecipe || (!this.isParentRecipe && expiration.expirationDate?.value)) && !this.expirationOptions.errorFlag))) {
      //Clear duration dropdown
      this.duration = '';
      this.preparationEventService.preparationExpirationTypeChangedEvent(expiration);
      this.preparationEventService.openExpirationSliderSubject.next(false);
      this.closeExpirationSlider.emit(false);
    }
  }
  resetAndCloseSlider() {
    this.closeExpirationSlider.emit(false);
    this.preparationEventService.openExpirationSliderSubject.next(false);
  }
  /**
   * It converts the expiration to either Instant if it is Date type as expected by backend
   * Else returns as is
   */
  getExpirationAsExperimentDataValue(isSuitableForUse: boolean, expiration: Instant | LocalDate | undefined): InstantValue | LocalDateValue {
    if (isSuitableForUse) {
      return {
        type: ValueType.LocalDate,
        state: ValueState.NotApplicable
      };
    }
    if (this.dateTime['calendar'].inputfieldViewChild.nativeElement.value
      && ((expiration instanceof Instant) || (expiration instanceof LocalDate))) {
      return this.preparationService.getExperimentDataValue(FieldType.Datepicker, expiration);
    }

    return {
      type: ValueType.LocalDate,
      state: ValueState.Empty
    }
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }
}
