/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeleteFilesCommand as ElnFileIntegrationApiCommandDeleteFilesCommand } from '../models/ELN/FileIntegration/Api/Command/delete-files-command';
import { DownloadFileCommand as ElnFileIntegrationApiCommandDownloadFileCommand } from '../models/ELN/FileIntegration/Api/Command/download-file-command';
import { FileUploadedResponse as ElnFileIntegrationApiFileFileUploadedResponse } from '../models/ELN/FileIntegration/Api/File/file-uploaded-response';
import { FilesDeletedResponse as ElnFileIntegrationApiFileFilesDeletedResponse } from '../models/ELN/FileIntegration/Api/File/files-deleted-response';

@Injectable({
  providedIn: 'root',
})
export class FileIntegrationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation filesUploadPost
   */
  static readonly FilesUploadPostPath = '/files/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesUploadPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  filesUploadPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: {
'ExperimentId'?: string;
'ActivityId'?: string;
'FolderName'?: string;
'UploadedData'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<ElnFileIntegrationApiFileFileUploadedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileIntegrationService.FilesUploadPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {"style":"simple"});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {"style":"simple"});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElnFileIntegrationApiFileFileUploadedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesUploadPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  filesUploadPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: {
'ExperimentId'?: string;
'ActivityId'?: string;
'FolderName'?: string;
'UploadedData'?: Array<Blob>;
}
  }): Observable<ElnFileIntegrationApiFileFileUploadedResponse> {

    return this.filesUploadPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ElnFileIntegrationApiFileFileUploadedResponse>) => r.body as ElnFileIntegrationApiFileFileUploadedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesUploadPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  filesUploadPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: {
'ExperimentId'?: string;
'ActivityId'?: string;
'FolderName'?: string;
'UploadedData'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<ElnFileIntegrationApiFileFileUploadedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileIntegrationService.FilesUploadPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {"style":"simple"});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {"style":"simple"});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElnFileIntegrationApiFileFileUploadedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesUploadPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  filesUploadPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: {
'ExperimentId'?: string;
'ActivityId'?: string;
'FolderName'?: string;
'UploadedData'?: Array<Blob>;
}
  }): Observable<ElnFileIntegrationApiFileFileUploadedResponse> {

    return this.filesUploadPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ElnFileIntegrationApiFileFileUploadedResponse>) => r.body as ElnFileIntegrationApiFileFileUploadedResponse)
    );
  }

  /**
   * Path part for operation filesDownloadPost
   */
  static readonly FilesDownloadPostPath = '/files/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesDownloadPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDownloadPost$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ElnFileIntegrationApiCommandDownloadFileCommand
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, FileIntegrationService.FilesDownloadPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {"style":"simple"});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesDownloadPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDownloadPost(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ElnFileIntegrationApiCommandDownloadFileCommand
  }): Observable<Blob> {

    return this.filesDownloadPost$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation filesDeletePost
   */
  static readonly FilesDeletePostPath = '/files/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesDeletePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDeletePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ElnFileIntegrationApiCommandDeleteFilesCommand
  }): Observable<StrictHttpResponse<ElnFileIntegrationApiFileFilesDeletedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileIntegrationService.FilesDeletePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {"style":"simple"});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElnFileIntegrationApiFileFilesDeletedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesDeletePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDeletePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ElnFileIntegrationApiCommandDeleteFilesCommand
  }): Observable<ElnFileIntegrationApiFileFilesDeletedResponse> {

    return this.filesDeletePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ElnFileIntegrationApiFileFilesDeletedResponse>) => r.body as ElnFileIntegrationApiFileFilesDeletedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesDeletePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDeletePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ElnFileIntegrationApiCommandDeleteFilesCommand
  }): Observable<StrictHttpResponse<ElnFileIntegrationApiFileFilesDeletedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileIntegrationService.FilesDeletePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {"style":"simple"});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElnFileIntegrationApiFileFilesDeletedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesDeletePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDeletePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ElnFileIntegrationApiCommandDeleteFilesCommand
  }): Observable<ElnFileIntegrationApiFileFilesDeletedResponse> {

    return this.filesDeletePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ElnFileIntegrationApiFileFilesDeletedResponse>) => r.body as ElnFileIntegrationApiFileFilesDeletedResponse)
    );
  }

}
