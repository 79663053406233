import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { v4 as uuidV4 } from 'uuid';
import { DataRecordService, isEventOfType } from './../../services/data-record.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientStateService } from 'services/client-state.service';
import { BaseComponent } from '../../../base/base.component';
import {
  ClientFacingNoteContextType,
  ExperimentWorkflowState,
  FieldType,
  InstrumentReadingValue,
  ModifiableDataValue,
  NumberValue,
  SingleValueSpec,
  SpecType,
  StringArrayValue,
  TimeSelectOption,
  TwoValueRangeSpec,
  Unit,
  ValueState
} from '../../../api/models';
import {
  FieldChangedEventNotification,
  ClientFacingNoteCreatedEventNotification,
  ExperimentDataRecordNotification,
  ClientFacingNoteChangedEventNotification,
  ExperimentEventType,
  ValueType,
} from '../../../api/data-entry/models';
import { guid, NumericAttributes, PicklistAttributes, TextInputs } from 'model/template.interface';
import { ChangeFieldCommand } from '../../../api/data-entry/models/change-field-command';
import { FormEventsService } from '../../../api/data-entry/services/form-events.service';
import { UserService } from 'services/user.service';
import { ClientValidationDetails } from 'model/client-validation-details';
import { finalize } from 'rxjs/operators';
import { User } from 'model/user.interface';
import {
  Experiment,
  FieldDefinition,
  SpecificationValue,
} from 'model/experiment.interface';
import { FormContextMenuItem } from 'bpt-ui-library/bpt-context-menu';
import { BptDateTimeComponent } from 'bpt-ui-library/bpt-datetime';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { FieldLock, LockType } from 'model/input-lock.interface';
import { ExperimentService } from '../../services/experiment.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BptDropdownComponent } from 'bpt-ui-library/bpt-dropdown';
import { DataValueService } from '../../services/data-value.service';
import { last, merge, isEqual, union } from 'lodash-es';
import { BptControlSeverityIndicators, ControlCustomDefinition, MakeProvider, SeverityIndicatorType, NA, Quantity } from 'bpt-ui-library/shared';
import {
  FormFieldClientFacingNoteContext,
  ShowClientFacingNotesEventData
} from '../../comments/client-facing-note/client-facing-note-event.model';
import { FieldDataForCompletionTracking } from '../../model/field-data-for-completion-tracking.interface';
import { AuditHistoryService } from '../../audit-history/audit-history.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ELNAppConstants } from '../../../shared/eln-app-constants';
import { AuditHistoryDataRecordResponse } from '../../../api/audit/models';
import { ExperimentWarningService } from '../../services/experiment-warning.service';
import { BptTextInputComponent } from 'bpt-ui-library/bpt-text-input';
import { BptNumericInputComponent } from 'bpt-ui-library/bpt-numeric-input';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { ClientFacingNoteModel } from '../../comments/client-facing-note/client-facing-note.model';
import { Logger } from 'services/logger.service';
import { BptQuantityComponent } from 'bpt-ui-library/bpt-quantity';
import { UnitLoaderService } from 'services/unit-loader.service';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CommentDetails } from '../../comments/comment.model';
import { CommentContextType, CommentResponse, CommentsResponse, InternalCommentStatus } from '../../../api/internal-comment/models';
import { RuleActionNotification } from '../../../rule-engine/actions/rule-action-notification';
import { SetFieldValueNotificationEvent } from '../../../rule-engine/action-notification/rule-action-notification.service';
import {
  RuleActionExperimentDataValueResult,
  RuleActionObjectResult
} from '../../../rule-engine/actions/rule-action-result';
import { CommentService } from '../../comments/comment.service';
import { ClipboardService } from 'bpt-ui-library/services';
import { FormFieldCopyHelper } from '../../../services/form-field-copy-helper';
import { OverlayPanel } from 'primeng/overlaypanel';
import { InstrumentConfigUnits } from '../../instrument-connection/shared/instrument-config-units';
import { InstrumentConnectionHelper } from '../../instrument-connection/shared/instrument-connection-helper';

@Component({
  selector: 'app-data-field[fieldDefinition][value][experimentId][formId][path]',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [MakeProvider(NgControl)]
})
export class FieldComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() fieldDefinition!: FieldDefinition; // set upon ngOnInit, not optional
  @Input() value!: ModifiableDataValue; // set upon ngOnInit, not optional
  @Input() experimentId!: guid; // set upon ngOnInit, not optional
  @Input() formId!: guid;
  @Input() path!: string[];
  @Input() formEvents!: Observable<FieldLock | ExperimentDataRecordNotification>;
  @Input() greatestTabOrder = 1;
  @Input() noteFlagEnabled = false;
  @Input() clientFacingNoteFlagColor = ELNAppConstants.ClientFacingNoteFlagColor;
  @Input() noteHoverText = '';
  @Input() InternalFlagEnabled = true;
  @Input() InternalCommentsHoverText = '';
  @Input() internalCommentsFlagColor = ELNAppConstants.InternalCommentFlagColor;
  @Input() parentNodeId: string[] = [];
  
  /**
   * 0 or 1 note for this field. Once set, only a few of its property can change; same object is kept.
   */
  @Input()
  public get note(): ClientFacingNoteModel | undefined {
    return this._note;
  }
  set note(value: ClientFacingNoteModel | undefined) {
    this._note = value;
    this.noteFlagEnabled = !!value;
    this.clientFacingNoteFlagColor = ELNAppConstants.ClientFacingNoteFlagColor;
    this.noteHoverText = value?.indicatorText ?? '';
    this.changeDetector.markForCheck();
  }

  @Input()
  public get internalComments(): CommentResponse | undefined {
    return this._internalComments;
  }
  set internalComments(value: CommentResponse | undefined) {
    this._internalComments = value;
    this.InternalFlagEnabled = !!value;
    this.InternalCommentsHoverText = this.getHoverOverText(value?.content ?? '');
    if (value) {
      this.isBottomFlagHollow = value.status === InternalCommentStatus.Pending;
    }
  }

  @Output() fieldChangedEvent = new EventEmitter<ChangeFieldCommand>();
  @Output() fieldReady = new EventEmitter<FieldDataForCompletionTracking>();

  @ViewChild('container') container!: ElementRef<HTMLInputElement>;
  @ViewChild('list') dropdownList!: BptDropdownComponent;
  @ViewChild('editableList') editableList!: BptDropdownComponent;
  @ViewChild('dateTime') dateTime!: BptDateTimeComponent;
  @ViewChild('bptTextbox') bptInput!: BptTextInputComponent;
  @ViewChild('bptInputArea') bptInputArea!: BptTextInputComponent;
  @ViewChild('bptInputEntry') bptInputEntry!: BptTextInputComponent;
  @ViewChild('numericInput') numericInput!: BptNumericInputComponent;
  @ViewChild('quantityInput') quantityInput!: BptQuantityComponent;
  @ViewChild('specInput') specInput!: BptTextInputComponent;
  @ViewChild('overlayPanel') overlayPanel!: OverlayPanel;

  public controlCustomDefinitionValidator = this.getControlCustomDefinitionValidator.bind(this);
  public controlCustomDefinitionValidatorForNumericControl =
  this.getControlCustomDefinitionValidatorForNumericControl.bind(this);
  readonly formFillWithNa = $localize`:@@formFillWithNa:Fill with N/A`;
  get fieldType(): FieldType {
    return this.fieldDefinition?.fieldType
  } 
  label!: string; // set upon ngOnInit, not optional
  listValues?: any[];

  internalCommentData: CommentDetails = {} as CommentDetails;
  allowNA?: boolean;
  defaultUnit?: Unit;
  allowDecimal?: boolean;
  allowNegative?: boolean;
  enableSignificantFigures?: boolean;
  allowedUnits?: Unit[];
  allowedSpecTypes?: SpecType[];
  minNumericValue?: number;
  maxNumericValue?: number;
  canEditExperimentInReviewStateFlag = false;
  minDate?: string;
  maxDate?: string;
  allowTimeSelect?: boolean | TimeSelectOption;
  isFieldReadOnly = false;
  itemTitle?: string;
  labelText?: string;
  allowMultiSelect?: boolean;
  autoResize?: boolean;
  minCharLength?: number;
  maxCharLength?: number;
  maxLength?: number | string;
  id!: string;
  title = '';
  public defaultMaxLength = 50;
  disabled!: boolean;
  validation: ClientValidationDetails;
  user!: User;
  isLoading = false;
  isHistoryLoading = false;
  lockTimeOut = 0;
  focusLockTime = 3000;
  experiment!: Experiment;

  //This is used for fetching the value states on demand.
  modifiableDataValue!: ModifiableDataValue;
  updatedNumericValue!: number;
  dynamicDialogRef!: DynamicDialogRef;
  onlyAllowUnits = false;
  showUnitNameInList?: boolean;
  highlightAllOnFocus?: boolean;
  private readonly subscriptions: Subscription[] = [];

  public get editableInSetup(): boolean {
    return !this.fieldDefinition.fieldAttributes['containsObservableData'];
  }
  isCommentsVisible = false;
  isBottomFlagHollow = true;

  public get primitiveValue(): any {
    return this.dataValueService.getPrimitiveValue(this.fieldType, this.value);
  }

  public get isEmpty(): boolean {
    return this.value?.value?.state === ValueState.Empty || this.value?.value === undefined;
  }

  public get isModified(): boolean {
    return this.value?.isModified ?? false;
  }

  get isOnOutputsPage(): boolean {
    return this.router.url.endsWith('/Outputs');
  }
  
  private _note?: ClientFacingNoteModel;
  private _internalComments?: CommentResponse;

  get isInstrumentConnected(): boolean {
    return this.instrumentConnectionHelper.isInstrumentConnected;
  }

  get isInstrumentConnectionAvailable(): boolean {
    return this.instrumentConnectionHelper.isInstrumentConnectionAvailable;
  }

  /** IANA time zone id for lab site */
  get labSiteTimeZone(): string {
    return UserService.currentLabSiteTimeZone.id();
  }

  /** node id of parent's parent */
  get activityId(): string {
    return this.parentNodeId[0];
  };
  /** node id of parent */
  get moduleId(): string {
    return this.parentNodeId[1];
  }

  unitIdForBalance: string ='';
  overlayVisibleBalance= false;

  constructor(
    clientStateService: ClientStateService,
    route: ActivatedRoute,
    private readonly formEventService: FormEventsService,
    private readonly userService: UserService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly experimentService: ExperimentService,
    private readonly dataValueService: DataValueService,
    private readonly dataRecordService: DataRecordService,
    private readonly logger: Logger,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly experimentWarningService: ExperimentWarningService,
    private readonly unitLoaderService: UnitLoaderService,
    private readonly internalCommentService: CommentService,
    public readonly clipboardService: ClipboardService,
    public readonly formFieldCopyHelper: FormFieldCopyHelper,
    private readonly router: Router,
    private readonly instrumentConnectionHelper: InstrumentConnectionHelper,
  ) {
    super(clientStateService, route);
    this.validation = new ClientValidationDetails();

    this.subscriptions.splice(0, 0,
      this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe((data) => {
        this.modifyPropertiesBasedOnWorkflowState(data.state);
      }),
      this.experimentService.experimentWorkFlowState.subscribe((data) => {
        this.modifyPropertiesBasedOnWorkflowState(data);
      }),
      this.experimentService.clientFacingNoteEvents.subscribe((note) =>
        this.onClientFacingNoteEvent(note)
      )
    );
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  ngOnInit(): void {    
    // To support templates which have fieldAttributes as null.
    // In the future, we will ensure fieldAttributes can only be empty and not null.
    this.fieldDefinition.fieldAttributes = this.fieldDefinition.fieldAttributes || {};
    // for debugging: console.log("FieldComponent " + this.fieldDefinition?.field + " = " + this.value)
    //TODO: why do we duplicate a shallow copy of value?
    this.modifiableDataValue = this.value;
    this.user = this.userService.currentUser;
    this.experiment = this.experimentService.currentExperiment as Experiment;
    this.populateCommentsAndNotes();
    // flatten some attributes for convenience and simpler template code
    this.label = this.fieldDefinition.label;
    this.labelText = this.fieldDefinition.fieldAttributes?.labelText ?? '';

    this.listValues = this.setupListValues(this.fieldDefinition.fieldAttributes as PicklistAttributes);
    this.allowNA = this.fieldDefinition.fieldAttributes?.allowNA ?? true;

    this.allowDecimal = (this.fieldDefinition.fieldAttributes as NumericAttributes)?.allowDecimal;
    this.allowNegative = this.fieldDefinition.fieldAttributes?.allowNegative;
    this.enableSignificantFigures = this.fieldDefinition.fieldAttributes?.enableSignificantFigures;
    this.minNumericValue = this.fieldDefinition.fieldAttributes?.minNumericValue ?? Number.MIN_SAFE_INTEGER;
    this.maxNumericValue = this.fieldDefinition.fieldAttributes?.maxNumericValue ?? Number.MAX_SAFE_INTEGER;

    this.minDate = this.fieldDefinition.fieldAttributes?.allowMinDate
      ? this.fieldDefinition.fieldAttributes?.minDate
      : '';
    this.maxDate = this.fieldDefinition.fieldAttributes?.allowMaxDate
      ? this.fieldDefinition.fieldAttributes?.maxDate
      : '';
    this.allowTimeSelect = this.fieldDefinition.fieldAttributes?.allowTimeSelect;
    this.autoResize = this.fieldDefinition.widthType === 'auto';
    this.minCharLength = this.fieldDefinition.fieldAttributes?.allowMinCharLength
      ? this.fieldDefinition.fieldAttributes?.minCharLength
      : 0;
    this.maxCharLength = this.fieldDefinition.fieldAttributes?.allowMaxCharLength
      ? this.fieldDefinition.fieldAttributes?.maxCharLength
      : 0;

    this.allowMultiSelect = (this.fieldDefinition.fieldAttributes as PicklistAttributes)?.allowMultiSelect;
    this.maxLength = (this.fieldDefinition.fieldAttributes as TextInputs)?.maxLength;
    this.disabled = this.fieldDefinition.disabled ?? false;
    this.id = `${this.path.join('_')}${this.formId}`;

    this.allowedUnits = this.fieldDefinition.fieldAttributes?.allowedUnits
      ? this.fieldDefinition.fieldAttributes?.allowedUnits
        .map((guid: string) => this.unitLoaderService.allUnits.find((unit) => unit.id === guid))
        .filter((unit: Unit) => unit?.isAvailable)
      : [this.unitLoaderService.naUnit]; // make naUnit always available to bpt-quantity

    this.allowedSpecTypes = this.fieldDefinition?.fieldAttributes?.allowedSpecTypes; 

    this.setupDefaultUnit();

    this.showUnitNameInList = this.fieldDefinition?.fieldAttributes?.showUnitNameInList ?? false;
    this.highlightAllOnFocus = this.fieldDefinition?.fieldAttributes?.highlightAllOnFocus;

    this.updateFeatureFlags();
    this.modifyPropertiesBasedOnWorkflowState(this.experiment.workflowState);
    this.subscriptions.push(this.formEvents.subscribe((event) => {
      this.handleFormEvent(event)
    }));
    this.fieldReady.emit({
      path: this.path.join('_'),
      value: this.value?.value,
      isEmpty: this.isEmpty,
      fieldType: this.fieldType,
      formId: this.formId

    });
    this.internalCommentsChanged();
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-field', this.fieldDefinition.field);
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-label', this.fieldDefinition.label);
  }

  ngAfterViewInit(): void {
    if(this.fieldType !== FieldType.Specification) return;
    const elem = this.elementRef.nativeElement.querySelector("bpt-text-input[type=specification] .bpt-component.bpt-text-input input.p-inputtext");
    
    if(!elem) return;

    elem.readOnly = true;

    if (!this.isFieldReadOnly) return;
    
    elem.disabled = true;
  }

  /** In the case where an editable multi-select has an off-list entry, we need to add it to the available options. */
  private setupListValues(picklistAttributes: PicklistAttributes): any[] | undefined {
    if (this.value?.value?.state !== ValueState.Set || this.fieldDefinition.fieldType !== FieldType.EditableList || !picklistAttributes.allowMultiSelect) {
      return picklistAttributes.listValues;
    } 

    const inboundOptions = ((this.value.value as StringArrayValue).value).map((v: string) => { return { label: v, value: v }; });
    // filter the inbound selected options and remove the ones that are present on the picklist.
    const inboundOffListOptions = inboundOptions.filter(inboundOption => !picklistAttributes.listValues?.some(picklistOption => inboundOption.value === picklistOption.value));
    
    inboundOffListOptions.sort((a, b) => a.label.localeCompare(b.label));
    return union(picklistAttributes.listValues, inboundOffListOptions);
  }

  /** Empty with N/A unit is not a valid state */
  private setupDefaultUnit() {
    if (this.fieldDefinition.fieldAttributes?.defaultUnit !== this.unitLoaderService.naUnit.id) {
      this.defaultUnit = this.allowedUnits?.find(
        (unit) => unit.id === this.fieldDefinition.fieldAttributes?.defaultUnit
      );
    }
  }

  private populateCommentsAndNotes() {
    this.note = this.experiment.clientFacingNotes.find(
      (n) => n.nodeId === this.formId && n.path[0] === last(this.path)
    );
    this.internalComments = this.experiment.internalComments?.comments.find(
      (n) => n.path[2] === this.formId && n.path[3] === last(this.path) && n.status !== InternalCommentStatus.Removed
    );
  }

  private updateFeatureFlags() {
    const featureFlags = this.clientStateService.getFeatureFlags(this.clientState);
    this.canEditExperimentInReviewStateFlag =
      !!featureFlags.find(
        (data) =>
          JSON.parse(data).CanEditExperimentInReviewState &&
          JSON.parse(data).CanEditExperimentInReviewState === true
      );
  }

  /**
   * Disables the controls (which are configured as containsObservableData) when experiment in setup state,
   * but once the experiment is started or containsObservableData is off then fields will be disabled based on editable in experiment flag
   */
  private setEditingOfObservableData() {
    if (this.editableInSetup) {
      this.isFieldReadOnly = this.fieldDefinition.disabled ?? this.readOnly;

      if (this.fieldDefinition.fieldType === FieldType.Quantity) {
        const inSetup = this.experiment.workflowState === ExperimentWorkflowState.Setup;
        this.onlyAllowUnits = inSetup && !this.fieldDefinition.fieldAttributes.numberEditableInSetup;
      }
    } else {
      this.isFieldReadOnly = true;
      if (this.fieldDefinition.fieldType === FieldType.Quantity) {
        this.onlyAllowUnits = false;
      }
    }
  }

  private modifyPropertiesBasedOnWorkflowState(data: ExperimentWorkflowState) {
    if (data === ExperimentWorkflowState.Setup) {
      this.setEditingOfObservableData();
    } else if (
      ExperimentService.isExperimentAuthorizedOrCancelled(data) ||
      (data === ExperimentWorkflowState.InReview &&
        this.canEditExperimentInReviewStateFlag !== true)
    ) {
      this.isFieldReadOnly = true;
    } else {
      this.isFieldReadOnly = this.fieldDefinition.disabled ?? this.readOnly;
      if (this.fieldType === FieldType.Quantity) {
        this.onlyAllowUnits = false;
      }
    }
  }

  private handleFormEvent(
    event:
      | FieldLock
      | ExperimentDataRecordNotification
      | RuleActionNotification<SetFieldValueNotificationEvent>
  ) {
    if ('key' in event) {
      this.handleFormFieldLockChangedNotification(event);
    } else if ('eventContext' in event) {
      if (isEventOfType(event, ExperimentEventType.FieldChanged)) {
        this.handleFormFieldChangedNotification(event);
      }
    } else if ('ruleContext' in event) {
      this.handleFormFieldChangedNotificationRule(event);
    }
  }

  private handleFormFieldLockChangedNotification(lock: FieldLock) {
    if (this.id === lock.key) {
      let styleString = '';
      const inputSelector =
        'input.p-inputtext, textarea.p-inputtext, div.p-dropdown, ul.p-inputtext, p-checkbox, p-radiobutton';
      if (lock.lockType === LockType.lock) {
        this.disabled = true;
        this.title = lock.experimentCollaborator.fullName ?? lock.experimentCollaborator.firstName;
        const color = lock.experimentCollaborator.backgroundColor ?? '#ee0fd0';
        styleString = `border: 1px solid ${color}`;
      } else {
        this.disabled = false;
        this.title = '';
      }
      this.renderer.setAttribute(
        this.container.nativeElement.querySelector(inputSelector),
        'style',
        styleString
      );
    }
  }

  /**
   * Collaborative editing
   */
  private handleFormFieldChangedNotification(event: FieldChangedEventNotification) {
    if (
      `${event.path.join('_')}${event.formId}` === this.id &&
      document.activeElement?.closest('.eln-field') !== this.container.nativeElement
    ) {
      merge(
        this.value,
        DataRecordService.getModifiableDataValue(event.newValue, this.value)
      );
      const fieldClass = 'collaborative-changed-element';
      this.renderer.addClass(this.container.nativeElement, fieldClass);
      setTimeout(() => {
        this.renderer.removeClass(this.container.nativeElement, fieldClass);
      }, environment.fieldFlashDelay);
    }
  }

  private handleFormFieldChangedNotificationRule(
    event: RuleActionNotification<SetFieldValueNotificationEvent>
  ) {
    if (`${event.sourceEvent.path.join('_')}${event.sourceEvent.event.templateInstanceId}` === 
      this.id && document.activeElement?.closest('.eln-field') !== this.container.nativeElement) {
      merge(
        this.value,
        DataRecordService.getModifiableDataValue((event.action as RuleActionExperimentDataValueResult).Value, this.value)
      );
      setTimeout(() => {
        this.modelChanged((event.action as RuleActionObjectResult).Value.value, event.ruleContext);
      }, environment.fieldFlashDelay);
    }
  }

  //Detects the input value in the control
  onNumericInputValue = ($event: any) => {
    this.updatedNumericValue = $event.target.value;
  };

  getModifiableDataValue = (value: any) => {
    const newValue = this.dataValueService.getExperimentDataValue(this.fieldType, value);
    this.modifiableDataValue = DataRecordService.getModifiableDataValue(newValue, this.modifiableDataValue);
  };

  //this is a temporary thingy
  quantityEdited = (newQuantity: Quantity) => {
    // event is fired too much
    if (isEqual(newQuantity, this.value)) return;

    // need to remove the nonsensical properties for empty or N/A states that bpt-quantity might give.
    if (newQuantity.state === ValueState.Empty) {
      delete newQuantity.exact;
      delete newQuantity.sigFigs;
      delete newQuantity.value;
    } else if (newQuantity.state === ValueState.NotApplicable) {
      delete newQuantity.exact;
      delete newQuantity.sigFigs;
      delete newQuantity.unitDetails;
      delete newQuantity.value;
    }

    if (isEqual(newQuantity, this.value)) return; // check again due above tweak

    if (!this.value ?? !this.value.value?.state) {
      this.modelEdited(newQuantity);
      return;
    }

    // If not technically equal, we need to drill into the properties we care about
    if (this.value.value.type !== ValueType.Number) throw new Error("LOGIC ERROR: shouldn't get here. Plumbing is wrong.");

    const thisNumber = this.value.value as NumberValue;
    const compare = (left: any, right: any) => {
      return left === right || (!left && !right); // If both left and right are falsey, consider that a match (null vs. undefined, etc)
    };
    if (
      thisNumber &&
      compare(newQuantity.type, thisNumber.type) &&
      compare(newQuantity.state, thisNumber.state) &&
      compare(newQuantity.value, thisNumber.value) &&
      compare(newQuantity.unit, thisNumber.unit) &&
      compare(newQuantity.sigFigs, thisNumber.sigFigs) &&
      compare(newQuantity.exact, thisNumber.exact)
    ) {
      return;
    }

    this.modelEdited(newQuantity);
  };

  modelEdited = (newValue: any) => {
    if (this.fieldDefinition.fieldType === FieldType.Quantity && this.isInstrumentConnectionAvailable && this.isInstrumentConnected) return;
    if ((this.fieldType === FieldType.EditableList || this.fieldType === FieldType.Specification) && newValue === this.primitiveValue) return;
    if (this.experimentWarningService.isUserAllowedToEdit) {
      this.modelChanged(newValue);
    }
  };

  modelChanged = (newValue: any, ruleCommandContext?: any) => {
    // for debugging: console.log( { modelChanged : {formId: this.formId, path: this.path, value: this.value, newValue: newValue } } );

    // Undefined and empty list are equivalent. (Either might be seen from a multi-select dropdown. It's questionable if undefined is even conceptually sound in this case.)
    const isUndefinedOrEmptyArray = (v: any) =>
      v === undefined || (Array.isArray(v) && v.length === 0);
    if (isUndefinedOrEmptyArray(newValue) && isUndefinedOrEmptyArray(this.primitiveValue)) return;

    const dataValue = this.dataValueService.getExperimentDataValue(this.fieldType, newValue);
    this.value = DataRecordService.getModifiableDataValue(dataValue, this.value);
    this.changeField({
      experimentId: this.experimentId,
      formId: this.formId,
      path: this.path,
      newValue: this.value.value,
      ruleContext: {
        correlationId: ruleCommandContext?.correlationId,
        ruleId: ruleCommandContext?.ruleId
      },
      activityId: this.activityId
    });
  };

  private revertChangedModel() {
    // There is only one
    const valueAccessor: ControlValueAccessor 
      =  this.bptInput 
      ?? this.bptInputArea
      ?? this.bptInputEntry
      ?? this.dropdownList
      ?? this.dateTime
      ?? this.editableList
      ?? this.numericInput
      ?? this.quantityInput
      ?? this.specInput;
    valueAccessor.writeValue(this.primitiveValue);
  }

  private changeField(command: ChangeFieldCommand) {
    this.fieldChangedEvent.emit(command);
    this.isLoading = true;
    this.formEventService
      .formEventsChangeFieldPost$Json({ body: command })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.validation.successes.push(
            $localize`:@@ChangedFieldSuccessfully:Changed field successfully`
          );
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }

  showNotesSlider = () => {
    const eventTarget: FormFieldClientFacingNoteContext = {
      formId: this.formId,
      fieldIdentifier: this.path[this.path.length - 1] // Initialize all the way down to the lastmost leaf of the path.
    };
    const details: ShowClientFacingNotesEventData = {
      eventContext: {
        contextType: ClientFacingNoteContextType.FormField,
        mode: 'clientFacingNotes'
      },
      targetContext: eventTarget
    };
    const customEvent = new CustomEvent<ShowClientFacingNotesEventData>('ShowSlider', {
      bubbles: true,
      detail: details
    });
    this.container.nativeElement.dispatchEvent(customEvent);
  };

  noteFlagClicked(_event: MouseEvent): void {
    this.showNotesSlider();
  }
  internalCommentsFlagClicked(_event: MouseEvent): void {
    this.openInternalCommentFromFlag();
  }
  openInternalCommentFromFlag(): void {
    let style = '';
    const cssClass = 'internal-comment-yellow-background';
    const inputSelector =
      'input.p-inputtext, textarea.p-inputtext, div.p-dropdown, ul.p-inputtext, p-checkbox, p-radiobutton, div.p-multiselect, text.p-dropdown-label.p-inputtext';
    const highlightedElements = document.getElementsByClassName('internal-comment-yellow-background');

    Array.from(highlightedElements).forEach((ele) => {
      style = `background-color: ${ELNAppConstants.BackGroundColorWhite}`;
      this.renderer.setAttribute(ele, 'style', style);
      this.renderer.removeClass(ele, cssClass);
    });
    const styleString = `background: ${ELNAppConstants.InternalCommentBackGroundColor} !important`;
    if (this.container.nativeElement.querySelector(inputSelector) !== null) {
    this.renderer.setAttribute(this.container.nativeElement.querySelector(inputSelector),
      'style', styleString);
    }

    const dropDownElement = this.container.nativeElement.querySelector("div.p-dropdown")?.children[1];
    if (dropDownElement) {
      this.renderer.setAttribute(dropDownElement, 'style', styleString);
      this.renderer.addClass(dropDownElement, cssClass);
    }
    if (this.container.nativeElement.querySelector(inputSelector) !== null) {
    this.renderer.addClass(this.container.nativeElement.querySelector(inputSelector), cssClass);
    }
    this.buildInternalComments();
  }

  /** Sets this.internalCommentData and calls internalCommentService.openInternalComments */
  private buildInternalComments() {
    const activity = this.experimentService.currentActivity;
     // module title is both moduleName and moduleLabel or something like that. See what retitle module does if you need to know.
    const module = (activity?.dataModules ?? []).find((mod) => mod.moduleId === this.experimentService.currentModuleId);
    this.internalCommentData = {
      nodeId: this.formId,
      contextType: CommentContextType.FormField,
      // last of path is unique within form as of circa 2022-09-01.
      path: [activity?.activityId, module?.moduleId, this.formId, this.fieldDefinition.field, this.fieldDefinition.label, CommentContextType.Module],
    };
    this.internalCommentService.openInternalComments(this.internalCommentData);
  }

  getContextMenu(): FormContextMenuItem[] {

    const menu: FormContextMenuItem[] = [
      this.formFieldCopyHelper.copyMenuItem(this),
      {
        label: $localize`:@@clientFacingNoteContextMenuOption:Client-facing Notes`,
        action: () => this.showNotesSlider(),
        icon: this.formFieldCopyHelper.copyIcon
      },
      {
        label: $localize`:@@commentsHeader:Internal Comments`,
        action: () => this.openInternalCommentFromFlag(),
        icon: 'pi pi-comments'
      },
      'separator',
      this.fillWithNAOptions(),
      {
        label: $localize`:@@History:History`,
        action: () => {
          this.loadHistoryDialog();
        },
        icon: 'icon-s icon-audit-history'
      }
    ];

    if (this.fieldType === FieldType.Specification && this.isFieldReadOnly) {
      menu.push({
        label: $localize`:@@ViewSpec:View Specification`,
        action: () => {
          this.toggleSpecificationSlider();
        },
        icon: 'pi pi-search'
      });
    }

    return menu;
  }

  /**
   * Gets called to load audit history dialog
   */
  loadHistoryDialog() {
    this.isHistoryLoading = true;
    this.auditHistoryService.loadFormAuditHistory(this.experimentId, this.formId).subscribe({
      next: this.bindDataToAuditHistoryDialog.bind(this)
    });
  }

  private bindDataToAuditHistoryDialog(data: AuditHistoryDataRecordResponse) {
    const fieldChangedRecords: ExperimentDataRecordNotification[] = data.dataRecords
      .filter((d) => d.eventContext.eventType.toString() === ExperimentEventType.FieldChanged)
      .map((m) => m as FieldChangedEventNotification)
      .filter((f) => JSON.stringify(f.path) === JSON.stringify(this.path));
    const fieldNoteRecords: ExperimentDataRecordNotification[] = data.dataRecords
      .filter(
        (d) =>
          d.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated ||
          d.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged
      )
      .map(
        (d) =>
          d as ClientFacingNoteCreatedEventNotification | ClientFacingNoteChangedEventNotification
      )
      .filter((d) => {
        if (d.nodeId !== this.formId) return false;
        const note = this.experiment.clientFacingNotes.find((n) => n.number === d.number);
        return note?.path[0] === last(this.path);
      });

    const dataRecords = fieldChangedRecords.concat(fieldNoteRecords);
    this.isHistoryLoading = false;
    const formTitle = this.experimentService.currentExperimentResponse?.forms.find(
      (f) => f.formId === this.formId
    )?.itemTitle;
    const fieldLabelPath = this.dataRecordService
      .getFieldLabelPathFromField(this.formId, last(this.path) as string)
      .join('/');
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      dataRecords,
      formTitle?.concat(ELNAppConstants.WhiteSpace, '→', ELNAppConstants.WhiteSpace, fieldLabelPath)
    );
  }

  handleKeyDown(e: KeyboardEvent) {
    if (e.key !== 'Tab') {
      window.clearTimeout(this.lockTimeOut);
      this.sendInputStatus(LockType.lock);
    }
  }

  handleEvent(_e: any) {
    window.clearTimeout(this.lockTimeOut);
    this.sendInputStatus(LockType.lock);
  }

  handleHideEvent(_e: any) {
    if (
      !(this.dropdownList?.isFocused) &&
      !(this.editableList?.isFocused) &&
      !(this.dateTime?.isFocused) &&
      !(this.quantityInput && (this.quantityInput.isFocused || this.quantityInput.dropdown.isFocused)
      )
    ) {
      this.endCollaborativeEditing();
    }
  }

  onFocus(_: any) {
    this.beginCollaborativeEditing();
  }

  beginCollaborativeEditing() {
    window.clearTimeout(this.lockTimeOut);
    this.lockTimeOut = window.setTimeout(() => {
      this.sendInputStatus(LockType.lock);
    }, this.focusLockTime);
  }

  endCollaborativeEditing() {
    window.clearTimeout(this.lockTimeOut);
    this.sendInputStatus(LockType.unlock);
  }

  onBlur(_: any) {
    window.clearTimeout(this.lockTimeOut);
    this.sendInputStatus(LockType.unlock);
  }

  sendInputStatus(lockType: LockType) {
    const fieldLock = new FieldLock(
      this.experimentId,
      lockType,
      this.experimentService.currentModuleId,
      this.activityId,
      this.experimentNotificationService.getCollaborator(),
      this.path,
      this.formId
    );
    this.experimentNotificationService.sendInputControlStatus([fieldLock]);
  }

  public getControlCustomDefinitionValidator(): Array<ControlCustomDefinition> {
    return [
      BptControlSeverityIndicators.BuildSeverityIndicator(SeverityIndicatorType.Empty, 
      { CanApply: () => { return this.isEmpty; }}),
      BptControlSeverityIndicators.BuildSeverityIndicator(SeverityIndicatorType.Modified, 
      { CanApply: () => { return this.isModified && !this.isEmpty; }})
    ];
  }

  //For numeric control, ngModelChange triggers after this property binding. Hence, updated value states are not detected for the first time.
  //Hence, this validator is for numeric control to detect the right value states on the blur event
  public getControlCustomDefinitionValidatorForNumericControl(): Array<ControlCustomDefinition> {
    this.getModifiableDataValue(this.updatedNumericValue ?? this.primitiveValue);
    return [
      BptControlSeverityIndicators.BuildSeverityIndicator(SeverityIndicatorType.Empty, 
      { CanApply: () => { return this.isDataValueEmpty() }}),
      BptControlSeverityIndicators.BuildSeverityIndicator(SeverityIndicatorType.Modified, 
      { CanApply: () => { return this.isDataValueModified() && !this.isDataValueEmpty(); }})
    ];
  }

  public isDataValueEmpty(): boolean {
    return (
      (this.modifiableDataValue?.value?.state === ValueState.Empty ||
        this.modifiableDataValue?.value === undefined) ??
      true
    );
  }

  public isDataValueModified(): boolean {
    return this.modifiableDataValue?.isModified ?? false;
  }

  onClientFacingNoteEvent(note: ClientFacingNoteModel): void {
    if (note.contextType !== ClientFacingNoteContextType.FormField) return;
    if (note.nodeId !== this.formId) return;

    const context = note.context as FormFieldClientFacingNoteContext;
    if (context.fieldIdentifier !== last(this.path)) return;

    this.note = note;
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  applyNoteCreatedDataRecord(data: ClientFacingNoteCreatedEventNotification): void {
    const note = this.experiment.clientFacingNotes.find((n) => n.number === data.number);
    if (!note) {
      this.logger.logErrorMessage(
        `Logic Error: trying to applyNoteCreatedDataRecord that is not yet in experiment.clientFacingNotes. 
        Possible handler sequence problem.`
      );
      return;
    }
    this.onClientFacingNoteEvent(note);
  }

  public shortContent(message: string) {
    const limit = 30;
    return message.length <= limit ? message : message.substring(0, limit) + '…';
  }

  public getHoverOverText(message = ''): string {
    return (
      $localize`:@@internalComments:Internal Comments` +
      `:${this.shortContent(
        message.replace(/<[^>]*>/g, ' ').replace(/\s{2,}/g, ' ').replace(/\&nbsp;/g, '')
      )}(click to view)`
    );
  }

  toggleCommentBox(visible: boolean) {
    this.isCommentsVisible = visible;
  }

  /** "subscribeToRefreshInternalComment" (misnamed) */
  internalCommentsChanged() {
    this.subscriptions.push(this.internalCommentService.refreshInternalComment.subscribe((currentContext: CommentsResponse) => {
      if (currentContext !== undefined) {
        this.experiment.internalComments = currentContext;
        this.internalComments = currentContext?.comments.find(
          (n) => n.path[2] === this.formId && n.path[3] === last(this.path) && n.status !== InternalCommentStatus.Removed
        );
      }
    }));
  }

  toggleSpecificationSlider(eventData?: any) {
    this.value ??= { isModified: false, value: { type: ValueType.Specification, state: ValueState.Empty } };
    if (this.value?.value.type !== ValueType.Specification) return; // shouldn't get here.
    if (!this.allowedUnits || !this.allowedSpecTypes) return; // shouldn't get here.
    if (this.isFieldReadOnly && eventData?.type === 'dblclick') return;

    const onChange = new Subject<SpecificationValue>();
    onChange.subscribe({ next: (newValue) => 
      {
        this.modelEdited(newValue);
        this.specInput.valueChangeLazyCommit();
        this.specInput.controlCustomDefinition = this.specInput.evaluateControlCustomDefinition(this.getControlCustomDefinitionValidator());
      }
    });

    const onClose = new Subject<never>();
    onClose.subscribe({ 
      complete: () => {
        this.endCollaborativeEditing();
        onClose.unsubscribe();
        onChange.unsubscribe();
      }});

    this.beginCollaborativeEditing();
    this.experimentService.beginEditSpecification.next({
      id: uuidV4(),
      value: this.value.value as SpecificationValue,
      readOnly: this.isFieldReadOnly && !this.disabled,
      disabled: this.disabled,
      allowedUnits: this.allowedUnits,
      allowedSpecTypes: this.allowedSpecTypes,
      defaultUnit: this.defaultUnit,
      onChange,
      onClose
    });
  }
  
  fillWithNAOptions(): FormContextMenuItem {
    return {
      label: this.formFillWithNa,
      action: () => {
        this.fillWithNAActions();
      },
      icon: 'icon-s icon-not-applicable'
    };
  }

  fillWithNAActions() {
    if (this.allowNA && !this.isFieldReadOnly) {
      switch (this.fieldDefinition.fieldType) {
        case FieldType.Quantity:
          this.quantityEdited({
            state: ValueState.NotApplicable,
            type: ValueType.Number,
            isNA: true,
            isUnitNA: true,
            unit: undefined
          } as Quantity);
          break;
        case FieldType.Specification:
          this.modelEdited({
            type: ValueType.Specification,
            state: ValueState.NotApplicable
          } as SpecificationValue);
          break;
        case FieldType.EditableList:
        case FieldType.List:
          this.modelEdited([NA]);
          break;
        default:
          this.modelEdited(NA);
      }
    }
  }

  public pasteSpec(eventArgs: Event) {
    const pasteFailure = (message: string) => {
      // We could modify this in the future if we decide to have it notify the user.
      this.logger.logWarning(message);
      return false;
    }

    const doesUnitsMatch = (guids: string[], allowedUnits: Unit[], unitLoaderService: UnitLoaderService) => {
      if (guids.length + allowedUnits.length === 0) {// If we add the lengths together and get a non-zero that means at least one of them has length.
        return true; // We can do this because the pasted value doesn't have units and we don't allow any units, so its good.
      }

      // Look up all the guids, making sure to filter out undefined values.
      const units = guids.map((guid: string) => unitLoaderService.allUnits.find((unit) => unit.id === guid))
        .filter((unit) : unit is Unit => unit !== undefined);

      // Because units should be one to one with guids, their lengths must be equal.
      if (units.length !== guids.length) return pasteFailure('Unknown unit found in paste value!');

      // If any of the units are not available, we need to fail to paste.
      return units.reduce((val, unit) => val && unit.isAvailable, true)
        // Check that each of our units are actually found in our allowedUnits.
        ? (units.reduce((val, unit) => val && allowedUnits.includes(unit), true) ||
          pasteFailure('Pasted value has a unit which is not allowed.'))
        // We found an unavailable unit, so we should return false.
        : pasteFailure('Pasted value has a unit which is no longer Available!');
    }

    const processSingleValue = (sv: SingleValueSpec, allowedUnits: Unit[], unitLoaderService: UnitLoaderService) => {
      return doesUnitsMatch([...(sv.value?.unit ? [sv.value.unit] : [])], allowedUnits, unitLoaderService);
    }

    const processTwoValueRange = (tvr: TwoValueRangeSpec, allowedUnits: Unit[], unitLoaderService: UnitLoaderService) => {
      return doesUnitsMatch([
        ...(tvr.lowerValue?.unit ? [tvr.lowerValue.unit] : []), 
        ...(tvr.upperValue?.unit ? [tvr.upperValue.unit] : [])], allowedUnits, unitLoaderService);
    }

    // Do this in a local function to make sure event propagation is still correctly halted.
    const process = async (allowedSpecTypes: SpecType[], allowedUnits: Unit[], unitLoaderService: UnitLoaderService): Promise<SpecificationValue | undefined> => {
      const content: ModifiableDataValue | undefined = await this.clipboardService.readBptCustomContent();
      if (content && 'specType' in content.value && content.value.specType) {
        if (!allowedSpecTypes.includes(content.value.specType)) {
          pasteFailure('Pasted value specType is not an allowed Spec type!');
          return undefined;
        }

        // Check that the pasted content conforms to existing spec definitions.
        switch (content.value.specType) {
          case SpecType.SingleValue:
            if (!processSingleValue(content.value as SingleValueSpec, allowedUnits, unitLoaderService)) return undefined;
            break;
          case SpecType.TwoValueRange:
            if (!processTwoValueRange(content.value as TwoValueRangeSpec, allowedUnits, unitLoaderService)) return undefined;
            break;
          case SpecType.Observation:
            break;
          default: 
            pasteFailure('Could not match specType for pasted value!');
            return undefined;
        }
        
        // Grab focus to ensure that we start collaborative editing.
        this.onFocus(undefined);
        return content.value as SpecificationValue;
      } else if (content?.value?.type === ValueType.Specification && content?.value?.state === ValueState.NotApplicable) {
          // Grab focus to ensure that we start collaborative editing.
          this.onFocus(undefined);
          return content.value as SpecificationValue;
      }

      pasteFailure('Pasted value does not appear to be a specification!');
      return undefined;
    };

    const tryToUpdate = (value: SpecificationValue | undefined) => {
      if (!value || isEqual(this.value?.value, value)) return;
      this.modelEdited(value);
      this.specInput.valueChangeLazyCommit();
      this.specInput.controlCustomDefinition = this.specInput.evaluateControlCustomDefinition(this.getControlCustomDefinitionValidator());
    }

    process(this.allowedSpecTypes ?? [], this.allowedUnits ?? [], this.unitLoaderService).then(tryToUpdate);

    eventArgs?.stopPropagation();
    eventArgs?.preventDefault();
  }

  public async copySpec(eventArgs?: any) {
    await this.copySpecToClipboard();

    eventArgs?.stopPropagation();
    eventArgs?.preventDefault();
  }

  public async copySpecToClipboard() {
    const selectedtext = window.getSelection()?.toString().trim();
    const modelValueText = this.specInput.modelValue?.toString();
    // if nothing is selected or the entire thing is selected, then only copy the selected portion.
    if (selectedtext && selectedtext !== modelValueText) {
      await this.clipboardService.writeText(selectedtext);
    } else {
      await this.clipboardService.writeBptCustomContent(this.value, modelValueText);
    }
  }

  openBalanceModal(event:MouseEvent) {
      if (this.fieldDefinition.fieldType === FieldType.Quantity && this.isInstrumentConnectionAvailable && this.isInstrumentConnected && this.instrumentConnectionHelper._instrumentType === 'Balance') {
      const unitIdForG = this.unitLoaderService.allUnits.find(unit => unit.abbreviation === InstrumentConfigUnits.g)?.id ?? '';
      const unitIdForMg = this.unitLoaderService.allUnits.find(unit => unit.abbreviation === InstrumentConfigUnits.mg)?.id ?? '';
      const allowedUnits:Array<string> = this.fieldDefinition.fieldAttributes.allowedUnits;
      if (this.experimentWarningService.isUserAllowedToEdit && allowedUnits.includes(unitIdForG) && allowedUnits.includes(unitIdForMg)) {
        this.overlayPanel.show(event, event.target);
        this.overlayVisibleBalance = true;
      }
    }
  }
  
  onUnitChange(unitId: string) {
    this.unitIdForBalance = unitId;
  }

  closeOverlay() {
    this.overlayPanel?.hide();
    this.overlayVisibleBalance = false;
  }

   /**
 * Counts the number of significant figures in a measurement value.
 * This function is intended for measurements which are inherently approximate,
 * and not for exact values. The implementation follows standard rules for significant
 * figures, considering decimals and zeros.
 *
 * @param {string} n - The measurement value as a string.
 * @returns {number} Count of significant figures in the value.
 */
   countSigFigs(n: string): number {
    if (n.startsWith('-')) {
        n = n.substring(1);
    }
    if (n.startsWith('0.')) {
      return n.replace(/0\.0*/, '').length;
    }
    const cleaned = n.replace(/^0+/, '');
    if (cleaned.includes('.')) {
      return cleaned.replace('.', '').length;
    } else {
      return cleaned.replace(/0+$/, '').length;
    }
  }

  onInstrumentReadingsReceived(actualValue: InstrumentReadingValue) {
    const actualReadingValue = actualValue.instrumentMetaData?.actual?.toString() ?? '';
    const sigFigsCount = this.countSigFigs(actualReadingValue);
    this.changeField({
      experimentId: this.experimentId,
      formId: this.formId,
      newValue:{
        type: ValueType.Number,
        state: ValueState.Set,
        value: actualReadingValue,
        sigFigs: sigFigsCount,
        unit: this.unitIdForBalance,
        instrumentReading: {
          category: actualValue.category,
          equipmentId: actualValue.equipmentId,
          instrumentName: actualValue.instrumentName,
          instrumentType: actualValue.instrumentType,
          manufacturer: actualValue.manufacturer,
          modelNumber: actualValue.modelNumber,
          serialNumber: actualValue.serialNumber,
          instrumentMetaData: {
            ReadingMethod: actualValue.instrumentMetaData?.ReadingMethod,
            ...actualValue.instrumentMetaData
          }
        }
      } ,
      path: [this.fieldDefinition.field],
      activityId: this.activityId
    });
    return
  }
}
