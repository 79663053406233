import { FieldGroupResponse } from '../api/models/field-group-response';
import { guid } from './template.interface';

// Template node types are bases for experiment nodes
import { ActivityResponse } from '../api/models/activity-response';
import { ModuleResponse } from '../api/models/module-response';
import { TableResponse } from '../api/models/table-response';
import { ModuleItemResponse } from '../api/models/module-item-response';
import { TrackingResponse } from '../api/models/tracking-response';
import {
  ActivityLabItemsNode,
  ActivityNode,
  ColumnSpecification as ApiColumnSpecification,
  ExperimentWorkflowState,
  FieldDefinitionResponse,
  OrganizationResponse,
  TemplateRuleResponse,
  Unit,
  VariablesNode,
  ActivityInputNode,
  ActivityReferences as ApiActivityReferences,
  ActivityOutputChromatographyResultSet,
  FormWithFieldDefinitionsResponse,
  InstrumentEventImpactAssessmentNode,
  ActivityFileNode
} from '../api/models';
import { ClientFacingNoteModel } from '../experiment/comments/client-facing-note/client-facing-note.model';
import {
  ModifiableDataValue,
  ObservationSpec,
  SingleValueSpec,
  SpecificationValue as ApiSpecificationValue,
  SpecType,
  TwoValueRangeSpec,
  ValueState,
  ValueType
} from '../api/data-entry/models'
import { CommentsResponse } from '../api/internal-comment/models';
import { Subject } from 'rxjs';
import { BptGridPreferences } from 'bpt-ui-library/bpt-grid';
import { PreparationItem } from '../preparation/models/preparation-presentation.model';
import { ActivityOutputChromatographyResultSetSummary } from '../api/models/activity-output-chromatography-result-set-summary';
import { 
  ColumnPromptResponse, 
  ConsumableSupplyPromptResponse, 
  InstrumentPromptResponse, 
  MaterialPromptResponse, 
  PreparationPromptResponse, 
  PromptType, 
  ReferenceItem
} from '../api/cookbook/models';
import { NodeCompletionStatus } from '../experiment/model/node-completion-status.interface';


export interface Experiment {
  id: string;
  experimentNumber: string;
  workflowState: ExperimentWorkflowState;
  title: string;
  tags: string[];
  tracking: TrackingResponse;
  organization: OrganizationResponse;
  activities: Activity[];
  lastProcessedDataRecordTimeStamp: string;
  clientFacingNotes: ClientFacingNoteModel[];
  internalComments?: CommentsResponse;
  variablesNode: VariablesNode;
  activityLabItems: Array<ActivityLabItemsNode>;
  activityInputs?: Array<ActivityInputNode>;
  activityOutputChromatographyData?: Array<ActivityOutputChromatographyResultSet>;
  activityOutputChromatographyResultSetsSummary?: Array<ActivityOutputChromatographyResultSetSummary>;
  instrumentEventImpactAssessmentData?: Array<InstrumentEventImpactAssessmentNode>;
  activityFiles: Array<ActivityFileNode>;
  experimentCompletionStatus: NodeCompletionStatus[]
}

export interface TemplateNodeRule {
  rules: TemplateRuleResponse[];
}

export interface ExperimentNode {
  experimentId: guid;
}

export interface Activity
  extends
  Omit<ActivityResponse, 'dataModules'>,
  Omit<ActivityNode, ActivityPropertiesToExclude>,
  ExperimentNode,
  TemplateNodeRule {
  activityId: guid;
  templateId: guid;
  isActivityComplete: boolean;
  activityReferences: ActivityReferences;
  dataModules: Array<Module>;
  preparations: Array<PreparationItem>;
  prompts?: Array<PromptModel>;
  references: Array<ReferenceItem>;
}
type ActivityPropertiesToExclude = '_ts' | 'id' | 'childOrder' | 'createdBy' | 'createdOn' | 'activityReferences';

export interface ActivityInput extends Omit<ActivityInputNode, 'columnDefinitions'>, ModuleItemResponse, ExperimentNode, TemplateNodeRule {
  activityInputId: string;
  columnDefinitions: Array<ColumnSpecification>
}

export interface ActivityReferences extends ApiActivityReferences {
  documentsTable?: Table;
  compendiaTable?: Table;
}

export enum ReferenceGridType {
  CrossReferences = 'crossReferences',
  Compendia = 'compendia',
  Documents = 'documents'
}

export type ModuleItem = Form | Table;
export interface Module extends Omit<ModuleResponse, 'items'>, ExperimentNode, TemplateNodeRule {
  moduleId: guid;
  isModuleComplete: boolean;
  items: Array<ModuleItem>;
}


export interface Form extends FormWithFieldDefinitionsResponse, ModuleItemResponse, ExperimentNode, TemplateNodeRule {
  formId: guid;
}

export type FormItem = FieldGroup | FieldDefinition;

export interface FieldGroup extends FieldGroupResponse {
  minWidth?: number;
  /** The maximum width of the field group, in pixels */
  maxWidth?: number;
}

export interface FieldDefinition extends FieldDefinitionResponse { }

/** 
 * Columns as defined in the experiment. 
 * 
 * Different to the template in that units are full objects and dropdown lists are list of items rather than a list source reference. 
 * Do not confuse with that @see ColumnSpecification aka ApiColumnSpecification.
 * 
 * Note: using a bpt-grid to present and edit column data may require adaptation of the experiment's tables' columns for whatever bpt-grid features are required.
 */
export interface ColumnSpecification extends Omit<ApiColumnSpecification, 'allowedUnits' | 'defaultUnit'> {
  allowedUnits?: Unit[];
  defaultUnit?: Unit;
}

/** 
 * Type of element of Table.value. 
 * 
 * Equivalent to TableRow, except to TypeScript !#*@$!
 * TableValueRow can be used for destructuring. TableRow can be used for "structuring". To convert between types may need `as unknown`
 */
export type TableValueRow = (Exclude<{ [key: string]: ModifiableDataValue }, 'id' | '~isRemoved~'> & { id: string, '~isRemoved~'?: boolean });

export interface Table extends Omit<TableResponse, 'value' | 'columnDefinitions'>, ModuleItemResponse, ExperimentNode, TemplateNodeRule {
  tableId: string;
  columnDefinitions: Array<ColumnSpecification>;
  savedPreferences?: BptGridPreferences;
  preferencesReady?: Subject<BptGridPreferences>;
  value: TableValueRow[];
}

/**
 * Type of a {@link Table.value} element (ie, a row) except for its id field. 
 * @example 
*   const rowFields: ModifiableDataFields = { someField: { isModified: false, value: { type: ValueType.String, state: ValueState.Set, value: 'oldValue' } } };
*   table.value = [{ id: '5a0520a1-2e10-418d-bab6-64435753da42', ...rowFields }];
*/
export type ModifiableDataFields = { [field: string]: ModifiableDataValue };
export type TableRowIdField = { id: string };
export type TableRow = Exclude<ModifiableDataFields | TableRowIdField, keyof TableRowIdField> & TableRowIdField;

export type SpecificationValue =
  { type: ValueType.Specification, state: ValueState.Empty } |
  { type: ValueType.Specification, state: ValueState.NotApplicable } |
  Exclude<ApiSpecificationValue, 'specType'> & ( // actually nothing meaningful from API SpecificationValue but it does serve as a consistency check
    { type: ValueType.Specification, state: ValueState.Set, specType: SpecType.SingleValue } & SingleValueSpec |
    { type: ValueType.Specification, state: ValueState.Set, specType: SpecType.TwoValueRange } & TwoValueRangeSpec |
    { type: ValueType.Specification, state: ValueState.Set, specType: SpecType.Observation } & ObservationSpec
  );

  export interface PromptModel {
    promptItems: Array<MaterialPrompt | ColumnPrompt | InstrumentPrompt | PreparationPrompt | ConsumableSupplyPrompt>;
    promptType: PromptType;
  }
  
  export type MaterialPrompt = MaterialPromptResponse;
  export type ColumnPrompt = ColumnPromptResponse;
  export type InstrumentPrompt = InstrumentPromptResponse;
  export type PreparationPrompt = PreparationPromptResponse;
  export type ConsumableSupplyPrompt = ConsumableSupplyPromptResponse;