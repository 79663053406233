import { ColumnDefinition, ISeverityIndicatorConfig } from 'bpt-ui-library/bpt-grid';
import { NA } from 'bpt-ui-library/shared';
import { ColumnType } from '../../../../app/api/models';
import {  RowActionParams } from '../shared/bpt-eln-grid-row-actions.component';

export class LabItemsConsumablesTableOptions {

  public static readonly ColumnDefinition: { [fieldName: string]: { displayName: string } } = {
    name: { displayName: 'Name' },
    details: { displayName: 'Details' },
    manufacturer: { displayName: 'Manufacturer' },
    lotNumber: { displayName: 'Lot #' },
    expirationDate: { displayName: 'Expiration Date' },
    partNumber: { displayName: 'Part #' },
    catalogNumber: { displayName: 'Catalog #' },
  };

  
  private static readonly cellStyleClass = 'grid-cell-align';
  public static GetColumnDefinitions(allowRowAdd: boolean, severityIndicatorConfig?: ()=>ISeverityIndicatorConfig, rowActionParams?: RowActionParams): ColumnDefinition[] {
    return [
      {
        label: $localize`:@@Id:Row Id`,
        field: 'itemReference',
        width: 'auto',
        hidden: true,
        disableHiding: true,
        lockVisible: true,
        showHeader: false,
        sortable: true,
        editable: allowRowAdd,
        showInColumnChooser: false,
        columnType: ColumnType.RowId,
      },
      {
        label: $localize`:@@Index:Index`,
        field: 'rowIndex',
        columnType: ColumnType.Index,
        hidden: true,
        showInColumnChooser: false,
        editable: false
      },
      {
        label: $localize`:@@ConsumableNameGridHeader:Name`,
        field: 'name',
        width: '280',
        disableHiding: true,
        lockVisible: true,
        showHeader: false,
        sortable: true,
        editable: allowRowAdd,
        cellClass: 'bpt-eln-grid-row-actions',
        columnType:ColumnType.String,
        showInColumnChooser: false,
        severityIndicatorConfig:severityIndicatorConfig,
        filterParams: {
            filterOptions: ['contains'],
            suppressAndOrCondition: true
          }
      },
      {
        label: $localize`:@@ConsumableDetailsGridHeader:Details`,
        field: 'details',
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        cellRendererParams: {
          suppressCount: true
        },
        editable: allowRowAdd,
        width: '280',
        showInColumnChooser: false,
        sortable: true,
        allowAutoResize: true,
        columnType:ColumnType.String,
        severityIndicatorConfig:severityIndicatorConfig
      },
      {
        label: $localize`:@@ConsumableManufacturerGridHeader:Manufacturer`,
        field: 'manufacturer',
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        cellRendererParams: {
          suppressCount: true
        },
        editable: function (params: any) {
          return isCellLocked(params, allowRowAdd);
         },
        width: '280',
        showInColumnChooser: true,
        sortable: true,
        allowAutoResize: true,
        columnType:ColumnType.String,
        severityIndicatorConfig:severityIndicatorConfig
      },
      {
        label: $localize`:@@ConsumableLotNumberGridHeader:Lot #`,
        field: 'lotNumber',
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        cellRendererParams: {
          suppressCount: true
        },
        editable: function (params: any) {
          return isCellLocked(params, allowRowAdd);
         },
        width: '250',
        showInColumnChooser: true,
        sortable: true,
        allowAutoResize: true,
        columnType:ColumnType.String,
        severityIndicatorConfig:severityIndicatorConfig
      },
      {
        field: 'expirationDate',
        label: $localize`:@@ConsumableExpirationDateGridHeader:Expiration Date`,
        width: '200',
        sortable: true,
        editable: function (params: any) {
          return isCellLocked(params, allowRowAdd);
        },
        columnType: ColumnType.Date,
        showInColumnChooser: true,
        severityIndicatorConfig:severityIndicatorConfig,
        valueGetter: (params) => {
          return params.data.expirationDate ?? NA;
        },
        allowNA: true
      },
      {
        label: $localize`:@@ConsumablePartNumberGridHeader:Part #`,
        field: 'partNumber',
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        cellRendererParams: {
          suppressCount: true
        },
        editable:function (params: any) {
          return isCellLocked(params, allowRowAdd);
         },
        width: '250',
        showInColumnChooser: true,
        sortable: true,
        hidden: false,
        allowAutoResize: true,
        columnType:ColumnType.String,
        severityIndicatorConfig:severityIndicatorConfig
      },
      {
        label: $localize`:@@ConsumableCatalogNumberGridHeader:Catalog #`,
        field: 'catalogNumber',
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        cellRendererParams: {
          suppressCount: true
        },
        editable: function (params: any) {
          return isCellLocked(params, allowRowAdd);
         },
        width: '250',
        showInColumnChooser: true,
        sortable: true,
        allowAutoResize: true,
        hidden: false,
        columnType:ColumnType.String,
        severityIndicatorConfig:severityIndicatorConfig
      }
    ];
  }
}

function isCellLocked(params: any, allowRowAdd: boolean) {
  const rowId = params.node.id ?? '';
    const columnName = params.colDef.field;
    const cellLocked = params.context?.inputLocks && params.context?.inputLocks[rowId + columnName];
    return !cellLocked && allowRowAdd;
}

