/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangeRecipeReferenceCellCommand } from '../models/change-recipe-reference-cell-command';
import { ChangeRecipeReferenceCellResponse } from '../models/change-recipe-reference-cell-response';
import { RecipeAddReferenceRowCommand } from '../models/recipe-add-reference-row-command';
import { RecipeReferenceRowsAddedResponse } from '../models/recipe-reference-rows-added-response';

@Injectable({
  providedIn: 'root',
})
export class ReferenceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation referencesAddRecipeReferenceRowsPost
   */
  static readonly ReferencesAddRecipeReferenceRowsPostPath = '/references/add-recipe-reference-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesAddRecipeReferenceRowsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesAddRecipeReferenceRowsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddReferenceRowCommand
  }): Observable<StrictHttpResponse<RecipeReferenceRowsAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ReferenceService.ReferencesAddRecipeReferenceRowsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeReferenceRowsAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referencesAddRecipeReferenceRowsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesAddRecipeReferenceRowsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddReferenceRowCommand
  }): Observable<RecipeReferenceRowsAddedResponse> {

    return this.referencesAddRecipeReferenceRowsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeReferenceRowsAddedResponse>) => r.body as RecipeReferenceRowsAddedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesAddRecipeReferenceRowsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesAddRecipeReferenceRowsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddReferenceRowCommand
  }): Observable<StrictHttpResponse<RecipeReferenceRowsAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ReferenceService.ReferencesAddRecipeReferenceRowsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeReferenceRowsAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referencesAddRecipeReferenceRowsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesAddRecipeReferenceRowsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddReferenceRowCommand
  }): Observable<RecipeReferenceRowsAddedResponse> {

    return this.referencesAddRecipeReferenceRowsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeReferenceRowsAddedResponse>) => r.body as RecipeReferenceRowsAddedResponse)
    );
  }

  /**
   * Path part for operation referencesRecipeReferenceChangeCellPost
   */
  static readonly ReferencesRecipeReferenceChangeCellPostPath = '/references/recipe-reference-change-cell';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesRecipeReferenceChangeCellPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRecipeReferenceChangeCellPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeReferenceCellCommand
  }): Observable<StrictHttpResponse<ChangeRecipeReferenceCellResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ReferenceService.ReferencesRecipeReferenceChangeCellPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeReferenceCellResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referencesRecipeReferenceChangeCellPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRecipeReferenceChangeCellPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeReferenceCellCommand
  }): Observable<ChangeRecipeReferenceCellResponse> {

    return this.referencesRecipeReferenceChangeCellPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeReferenceCellResponse>) => r.body as ChangeRecipeReferenceCellResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesRecipeReferenceChangeCellPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRecipeReferenceChangeCellPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeReferenceCellCommand
  }): Observable<StrictHttpResponse<ChangeRecipeReferenceCellResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ReferenceService.ReferencesRecipeReferenceChangeCellPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeReferenceCellResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `referencesRecipeReferenceChangeCellPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRecipeReferenceChangeCellPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeReferenceCellCommand
  }): Observable<ChangeRecipeReferenceCellResponse> {

    return this.referencesRecipeReferenceChangeCellPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeReferenceCellResponse>) => r.body as ChangeRecipeReferenceCellResponse)
    );
  }

}
