import { Injectable } from '@angular/core';
import { ActionType } from '../models/preparation.model';
import { Observable, Subject } from 'rxjs';
import { UnitLoaderService } from '../../services/unit-loader.service';
import { DataValueService } from '../../experiment/services/data-value.service';
import { ActivityInputType, ActivityLabItemsNode, ColumnType, ExperimentPreparation, ExperimentWorkflowState, 
  ExperimentPreparationStatus, TimeSelectOption, ValueState, ClientFacingNoteContextType } from '../../api/models';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { 
  ClientFacingNoteChangedEventNotification,
  ClientFacingNoteCreatedEventNotification,
  LabItemPreparationRemovedEventNotification, 
  LabItemPreparationRestoredEventNotification, 
  LabItemsPreparationRefreshedNotification, 
  PreparationSubStatus, 
  RemoveLabItemPreparationCommand, 
  RestoreLabItemPreparationCommand, 
  StringTypeDictionaryValue 
} from '../../api/data-entry/models';
import { PreparationRow } from '../preparations.component';
import { ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { PreparationConstants } from '../preparation-constants';
import { guid } from '../../model/template.interface';
import { ExperimentService } from '../../experiment/services/experiment.service';
import { LabItemEventsService } from '../../api/data-entry/services';
import { LabItemWisePermissions, LabItemsFeatureManager } from '../../experiment/labItems/shared/lab-items-feature-manager';
import { DataRecordService } from '../../experiment/services/data-record.service';
import { AuditHistoryService } from '../../experiment/audit-history/audit-history.service';
import { ElnProgressSpinnerService } from '../../eln-progress-spinner-module/eln-progress-spinner.service';
import { SpinnerOptions } from '../../eln-progress-spinner-module/spinner-options.interface';
import { AuditHistoryDataRecordResponse, ExperimentDataRecordNotification, ExperimentEventType } from '../../api/audit/models';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BptControlSeverityIndicator, BptControlSeverityIndicators, NA, SeverityIndicatorType } from 'bpt-ui-library/shared';
import { ICellRendererParams } from 'ag-grid-community';
import { LocalDate, LocalDateTime } from '@js-joda/core';
import { DateAndInstantFormat, formatInstant, formatLocalDate } from '../../shared/date-time-helpers';

@Injectable()

export class PreparationLabItemsService {
  public readonly iconClasses:{[key: string]: string} = {
    'red': 'eln-warning-icon-red',
    'yellow': 'eln-warning-icon-yellow'
  }

  public readonly labItemPreparationRefreshNotification = new Subject<void>();    
  
  public labItemPreparationShouldRefresh: Observable<void> =
    this.labItemPreparationRefreshNotification.asObservable(); 

  public dynamicDialogRef!: DynamicDialogRef;
  public featuresByClientState: string[] = [];
  public readonly preparationRefreshed = new Subject<string>();

  private readonly keyNameOf = <T>(name: Extract<keyof T, string>): string => name;
  missingFieldMessage =
    'Logic error: When a grid is used to render a table, each column definition must have a field value but at least one is missing.';
  preparationTitle = 'Preparation';
  
  getColumnDefinitions(allowRowEdit = false): ColumnDefinition[] {
    return [
      {
        label: $localize`:@@Index:Index`,
        field: 'rowIndex',
        columnType: ColumnType.Index,
        hidden: true,
        showInColumnChooser: false,
        editable: false,
        suppressContextMenu:true
      },
      {
        field: this.keyNameOf<PreparationRow>('id'),
        columnType: ColumnType.RowId,
        label: $localize`:@@preparationRowId:Row ID`,
        cellClass: 'bpt-row-id-cell',
        editable: allowRowEdit,
        alwaysHidden: false,
        hidden: true,
        suppressContextMenu: true
      },
      {
        field: this.keyNameOf<PreparationRow>('preparationNumber'),
        label: $localize`:@@preparationId:Preparation ID`,
        width: PreparationConstants.widthMedium,
        minWidth: PreparationConstants.widthMedium,
        cellClass: 'cell-Link',
        showInColumnChooser: false,        
        editable: allowRowEdit,
        columnType: ColumnType.String,
        filterParams: {
          filterOptions: ['contains']
        },
        severityIndicatorConfig: () => {
          return {
            getIndicator: (params: ICellRendererParams) => {
              let response!: BptControlSeverityIndicator;
              if (params.value !== NA && params.value !== null) {
                return this.getSeverityIndicatorForDiscardedOrConsumed(params);
              } else {
                return response;
              }
            }
          };
        },
      },
      {
        field: this.keyNameOf<PreparationRow>('name'),
        label: $localize`:@@name:Name`,
        width: PreparationConstants.widthSmall,
        minWidth: PreparationConstants.widthSmall,
        columnType: ColumnType.String,
        showInColumnChooser: false,
        editable: allowRowEdit        
      },
      {
        field: this.keyNameOf<PreparationRow>('formulaComponents'),
        label: $localize`:@@formulationOrComponents:Formulation/Components`,
        columnType: ColumnType.String,
        editable: allowRowEdit,
      },
      {
        field: this.keyNameOf<PreparationRow>('status'),
        label: $localize`:@@status:Status`,
        columnType: ColumnType.String,
        width: PreparationConstants.widthSmall,
        minWidth: PreparationConstants.widthSmall,
        editable: allowRowEdit,
        severityIndicatorConfig: () => {
          return {
            getIndicator: (params: ICellRendererParams) => {
              return this.getSeverityIndicatorForStatus(params);
            }
          };
        },
      },
      {
        field: this.keyNameOf<PreparationRow>('expiration'),
        label: $localize`:@@expiration:Expiration`,
        width: PreparationConstants.widthSmall,
        minWidth: PreparationConstants.widthSmall,
        columnType: ColumnType.String,
        allowTimeSelect: TimeSelectOption.Optional,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        editable: allowRowEdit,
        valueGetter: (params) => {
          const expiration = params.data.expiration;
          if (expiration === NA || expiration === undefined) {
            return PreparationConstants.suitableForUseText;
          }
          else {
            if (expiration instanceof LocalDate) {
              return formatLocalDate(expiration);
            }
            else {
              return formatInstant(expiration, DateAndInstantFormat.dateTimeToMinute);
            }
          }
        },
        severityIndicatorConfig: () => {
          return {
            getIndicator: (params: ICellRendererParams) => {
              let response!: BptControlSeverityIndicator;
              if (params.value !== NA && params.value !== null) {
                return this.getSeverityIndicatorForExpiry(params);
              } else {
                return response;
              }
            }
          };
        },
      },
      {
        field: this.keyNameOf<PreparationRow>('storageCondition'),
        label: $localize`:@@storageCondition:Storage Condition`,
        columnType: ColumnType.String,
        width: PreparationConstants.widthSmall,
        minWidth: PreparationConstants.widthSmall,
        editable: allowRowEdit
      },
      {
        field: this.keyNameOf<PreparationRow>('concentration'),
        label: $localize`:@@concentration:Concentration`,
        columnType: ColumnType.String,
        width: PreparationConstants.widthSmall,
        minWidth: PreparationConstants.widthSmall,
        allowNA: true,
        allowNegative: true,
        allowDecimal: true,
        editable: allowRowEdit,
      },
      {
        field: this.keyNameOf<PreparationRow>('description'),
        width: PreparationConstants.widthLarge,
        minWidth: PreparationConstants.widthLarge,
        columnType: ColumnType.String,
        label: $localize`:@@containerDescription:Container Description`,
        valueGetter: (params: any) => {
          if (params.data.description.state === ValueState.Set) {
            return this.buildContainerDescription(params.data.description);
          }
          return "N/A";
        },
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        editable: allowRowEdit
      }
    ];
  }
  
  private static readonly AuditHistoryLoadingMessage: SpinnerOptions = {
    message: $localize`:@@loadingHistory:Loading History...`,
    i18nMessage: `@@loadingHistory`
  };
  
  public readonly eventsForAuditHistory: {
    [itemType: string]: ExperimentEventType[];
  } = {
    preparation: [
      ExperimentEventType.LabItemsPreparationAdded,
      ExperimentEventType.LabItemPreparationRemoved,
      ExperimentEventType.LabItemPreparationRestored,
      ExperimentEventType.LabItemPreparationRefreshed,
      ExperimentEventType.ClientFacingNoteCreated,
      ExperimentEventType.ClientFacingNoteChanged
    ]
  };
  
  private labItemWiseClientFacingCreatedNotesIndex: {
    [itemType: string]: number[];
  } = {};

  public readonly auditDataRecordsFilterByEventType: {
    [itemType: string]: (
      dataRecord: ExperimentDataRecordNotification,
      labItemType: ActivityInputType
    ) => boolean;
  } = {
      clientFacingNoteCreated: (
        dataRecord: ExperimentDataRecordNotification,
        labItemType: ActivityInputType
      ) =>
        this.filterClientFacingNoteCreatedDataRecords(
          dataRecord as ClientFacingNoteCreatedEventNotification,
          labItemType
        ),
      clientFacingNoteChanged: (
        dataRecord: ExperimentDataRecordNotification,
        labItemType: ActivityInputType
      ) =>
        this.filterClientFacingNoteChangedDataRecords(
          dataRecord as ClientFacingNoteChangedEventNotification,
          labItemType
        )
    };

  public filterClientFacingNoteCreatedDataRecords(
    clientFacingNoteRecord: ClientFacingNoteCreatedEventNotification,
    labItemType: ActivityInputType
  ): boolean {
    if (clientFacingNoteRecord.contextType !== ClientFacingNoteContextType.LabItemsPreparation) {
      return false;
    }
    this.labItemWiseClientFacingCreatedNotesIndex[labItemType] = this.labItemWiseClientFacingCreatedNotesIndex[labItemType] ?? [];
    this.labItemWiseClientFacingCreatedNotesIndex[labItemType].push(clientFacingNoteRecord.number);
    return true;
  }

  public filterClientFacingNoteChangedDataRecords(
    clientFacingNoteRecord: ClientFacingNoteChangedEventNotification,
    labItemType: ActivityInputType
  ): boolean {
    return this.labItemWiseClientFacingCreatedNotesIndex[labItemType]?.includes(clientFacingNoteRecord.number);
  }

  constructor(
    public readonly unitLoaderService: UnitLoaderService,
    public readonly dataValueService: DataValueService,
    public readonly messageService: MessageService,
    public readonly confirmationService: ConfirmationService,
    public readonly experimentService: ExperimentService,
    public readonly labItemEventsService: LabItemEventsService,
    private readonly dataRecordService: DataRecordService,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService
  ) {
    this.watchPreparationRemovedNotification();
    this.watchPreparationRestoredNotification();
    this.watchPreparationRefreshedNotification();
  }

  private watchPreparationRefreshedNotification() {
    this.dataRecordService.labItemPreparationRefreshedNotification.subscribe({
      next: (notification) => {
        if(Object.entries(notification.refreshedDataValues).length > 0) {
          this.applyPreparationRefreshedNotification(notification);
        }
      }
    });
  }

  applyPreparationRefreshedNotification(refreshNotification: LabItemsPreparationRefreshedNotification) {
    const refreshedDataValuesEntries = Object.entries(refreshNotification.refreshedDataValues);
    refreshedDataValuesEntries.forEach( entry => {
      entry[0] = entry[0].charAt(0).toUpperCase() + entry[0].substring(1)
    });
    refreshNotification.refreshedDataValues = Object.fromEntries(refreshedDataValuesEntries);
    this.processRefreshedPreparation(refreshNotification);
    this.preparationRefreshed.next(refreshNotification.itemReference);
  }

  styleClassProperties: { [key: string]: string } = {
    rejectButtonStyleClass: 'eln-standard-popup-button p-button-outlined',
    acceptButtonStyleClass: 'eln-standard-popup-button',
    icon: 'pi pi-exclamation-triangle'
  };  

  get experimentId() : string {
    return this.experimentService?.currentExperiment?.id ?? '';
  }

  get activityId() : string {    
    return this.experimentService?.currentActivity?.activityId ?? '';
  }

  public buildContainerDescription(description: StringTypeDictionaryValue): string {
    return this.dataValueService.joinValues(description);
  }

  public refreshPreparation(preparationId: guid, preparationNumber: string) {
    this.sendRequestToRefreshPreparation(preparationId, preparationNumber);
  }

  public removeLabItemPreparation(preparationNumber: string) {
    const message = $localize`:@@preparationRemoveRow:Are you sure you want to remove "${preparationNumber}" from Preparation? The removed preparation can be restored using the "View Removed Rows" option.`;
    this.confirmTheAction(preparationNumber, message, ActionType.Remove);
  }

  public restoreLabItemPreparation(preparationNumber: string, preparationSubStatus?: PreparationSubStatus) {
    const message = $localize`:@@preparationRestoreRow:Are you sure you want to restore "${preparationNumber}" from removed preparations? `;
    this.confirmTheAction(preparationNumber, message, ActionType.Restore, preparationSubStatus);
  }

  private confirmTheAction(preparationNumber: string, message: string, actionType: ActionType, preparationSubStatus?: PreparationSubStatus): void {
    this.confirmationService.confirm({
      message: message,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@Ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        if (actionType === ActionType.Restore) {
          this.sendRequestToRestorePreparation(preparationNumber, preparationSubStatus);
        } else {
          this.sendRequestToRemovePreparation(preparationNumber);
        }
      },
      reject: () => {
      }
    });
  }

  private sendRequestToRemovePreparation(preparationNumber: string) {    
    if (!this.hasRemovePermission(ActivityInputType.Preparation)) {
      this.failedToPerformOperationMessage(preparationNumber);
      return;
    }
    if (this.experimentId && this.activityId) {
    const removeLabItemsPreparationCommand: RemoveLabItemPreparationCommand = {
      activityId: this.activityId,
      experimentId: this.experimentId,
      itemReference: preparationNumber,
    };

    this.labItemEventsService
      .labItemEventsExperimentIdRemovePreparationPost$Json({
        experimentId: removeLabItemsPreparationCommand.experimentId,
        body: removeLabItemsPreparationCommand
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.processRemovedPreparation(response.labItemPreparationRemovedEventNotification);
          }
        }
      });
    }
  }

  private sendRequestToRestorePreparation(preparationNumber: string,
    preparationSubStatus?: PreparationSubStatus) {
    if (!this.hasRestorePermission(ActivityInputType.Preparation)) {
      this.failedToPerformOperationMessage(preparationNumber);
      return;
    }
    if (this.experimentId && this.activityId) {
      const restoreLabItemsPreparationCommand: RestoreLabItemPreparationCommand = {
        activityId: this.activityId,
        experimentId: this.experimentId,
        itemReference: preparationNumber,
        preparationSubStatus: preparationSubStatus
      };

      this.labItemEventsService
        .labItemEventsExperimentIdRestorePreparationPost$Json({
          experimentId: restoreLabItemsPreparationCommand.experimentId,
          body: restoreLabItemsPreparationCommand
        })
        .subscribe({
          next: (response) => {
            if (response.notifications.notifications.length === 0) {
              this.processRestoredPreparation(response.labItemPreparationRestoredEventNotification);
            }
          }
        });
    }
  }

  private sendRequestToRefreshPreparation(preparationId: string, preparationNumber: string) {
    if( this.experimentId && this.activityId) {
      const refreshPreparationCommand = {
        experimentId: this.experimentId,
        activityId: this.activityId,
        preparationId: preparationId,
        itemReference: preparationNumber
      }

      this.labItemEventsService.labItemEventsExperimentIdRefreshPreparationPost$Json({
        experimentId: this.experimentId,
        body: refreshPreparationCommand
      }).subscribe({
        next: (response: LabItemsPreparationRefreshedNotification) => {
          if(Object.entries(response.refreshedDataValues).length === 0) {
            this.messageService.add({
              key: 'notification',
              severity: 'info',
              detail:$localize`:@@LabItemPreparationDataUptoDate:The data on the preparation for the lab item is already up to date ${response.itemReference}`,
              sticky: false
            });
            return;
          }
          this.processRefreshedPreparation(response);
          this.refreshSuccessMessage(response.itemReference);
        }
      });
    }
  }

  private processRemovedPreparation(notification: LabItemPreparationRemovedEventNotification) {    
    const activityLabItemNode = this.getActivityLabItemNode(notification.activityId);
    if (activityLabItemNode) {
      const preparation = activityLabItemNode.preparations.find(
        (i) => i.preparationNumber === notification.itemReference
      );
      if (preparation) {
        preparation.summary.preparationSubStatus = notification.preparationSubStatus;
        this.labItemPreparationRefreshNotification.next();        
        this.removedSuccessMessage(notification.itemReference);
      }
    }
  }

  private processRestoredPreparation(notification: LabItemPreparationRestoredEventNotification) {    
    const activityLabItemNode = this.getActivityLabItemNode(notification.activityId);
    if (activityLabItemNode) {
      const preparation = activityLabItemNode.preparations.find(
        (i) => i.preparationNumber === notification.itemReference
      );
      if (preparation) {
        preparation.summary.preparationSubStatus = undefined;
        this.labItemPreparationRefreshNotification.next();        
        this.restoredSuccessMessage(notification.itemReference);
      }
    }
  }

  processRefreshedPreparation(refreshResponse: LabItemsPreparationRefreshedNotification) {
    const activityLabItemNode = this.getActivityLabItemNode(refreshResponse.activityId);
    if (activityLabItemNode) {
      const preparation = activityLabItemNode.preparations.find(
        (i) => i.preparationNumber === refreshResponse.itemReference
      );
      if (preparation) {
        preparation.name = refreshResponse.refreshedDataValues['Name'] ?? preparation.name;
        preparation.description = refreshResponse.refreshedDataValues['Description'] ?? preparation.description;
        if(preparation.expirationValue) {
          preparation.expirationValue.expirationDateValue = refreshResponse.refreshedDataValues['ExpirationValue.ExpirationDateValue'] ?? preparation.expirationValue?.expirationDateValue;
        }
        preparation.status = refreshResponse.refreshedDataValues['Status'] ?? preparation.status;
        preparation.summary.concentration = refreshResponse.refreshedDataValues['Summary.Concentration'] ?? preparation.summary.concentration;
        preparation.summary.formulaComponents = refreshResponse.refreshedDataValues['Summary.FormulaComponents'] ?? preparation.summary.formulaComponents;
        preparation.summary.storageCondition = refreshResponse.refreshedDataValues['Summary.StorageCondition'] ?? preparation.summary.storageCondition;
        this.refreshAdditionalInformation(preparation, refreshResponse);
        this.refreshInternalInformation(preparation, refreshResponse);
        this.labItemPreparationRefreshNotification.next();
      }
    }
  }

  private refreshAdditionalInformation(preparation: ExperimentPreparation, refreshResponse: LabItemsPreparationRefreshedNotification) {
    if(preparation.additionalInformation) {
      preparation.additionalInformation.analysis = refreshResponse.refreshedDataValues['AdditionalInformation.Analysis'] ?? preparation.additionalInformation.analysis;
      preparation.additionalInformation.subBusinessUnit = refreshResponse.refreshedDataValues['AdditionalInformation.SubBusinessUnit'] ?? preparation.additionalInformation.subBusinessUnit;
      preparation.additionalInformation.method = refreshResponse.refreshedDataValues['AdditionalInformation.Method'] ?? preparation.additionalInformation.method;
      preparation.additionalInformation.compendia = refreshResponse.refreshedDataValues['AdditionalInformation.Compendia'] ?? preparation.additionalInformation.compendia;
      preparation.additionalInformation.client = refreshResponse.refreshedDataValues['AdditionalInformation.Client'] ?? preparation.additionalInformation.client;
      preparation.additionalInformation.project = refreshResponse.refreshedDataValues['AdditionalInformation.Project'] ?? preparation.additionalInformation.project;
      preparation.additionalInformation.preparedBy = refreshResponse.refreshedDataValues['AdditionalInformation.PreparedBy'] ?? preparation.additionalInformation.preparedBy;
      preparation.additionalInformation.reviewedBy = refreshResponse.refreshedDataValues['Status'] === ExperimentPreparationStatus.Reviewed ?  
        refreshResponse.refreshedDataValues['AdditionalInformation.ReviewedBy'] : '';
      preparation.additionalInformation.storedBy = refreshResponse.refreshedDataValues['AdditionalInformation.StoredBy'] ?? preparation.additionalInformation.storedBy;
      preparation.additionalInformation.discardedOrConsumed = refreshResponse.refreshedDataValues['AdditionalInformation.DiscardedOrConsumed'] ?? preparation.additionalInformation.discardedOrConsumed;
      preparation.additionalInformation.discardedOrConsumedOn = refreshResponse.refreshedDataValues['AdditionalInformation.DiscardedOrConsumedOn'] ?? preparation.additionalInformation.discardedOrConsumedOn;
      preparation.additionalInformation.discardedOrConsumedBy = refreshResponse.refreshedDataValues['AdditionalInformation.DiscardedOrConsumedBy'] ?? preparation.additionalInformation.discardedOrConsumedBy;
    }
  }

  private refreshInternalInformation(preparation: ExperimentPreparation, refreshResponse: LabItemsPreparationRefreshedNotification) {
    if(preparation.internalInformation) {
      preparation.internalInformation.disposal = refreshResponse.refreshedDataValues['InternalInformation.Disposal'] ?? preparation.internalInformation.disposal;
      preparation.internalInformation.storageLocation = refreshResponse.refreshedDataValues['InternalInformation.StorageLocation'] ?? preparation.internalInformation.storageLocation;
      preparation.internalInformation.stability = refreshResponse.refreshedDataValues['InternalInformation.Stability'] ?? preparation.internalInformation.stability;
      preparation.internalInformation.originalQuantity = refreshResponse.refreshedDataValues['InternalInformation.OriginalQuantity'] ?? preparation.internalInformation.originalQuantity;
      preparation.internalInformation.hazards = refreshResponse.refreshedDataValues['InternalInformation.Hazards'] ?? preparation.internalInformation.hazards;
      preparation.internalInformation.additionalLabel = refreshResponse.refreshedDataValues['InternalInformation.AdditionalLabel'] ?? preparation.internalInformation.additionalLabel;
    }
  }

  private getActivityLabItemNode(activityId: string) : ActivityLabItemsNode | undefined {
    return this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === activityId
    );
  }

  public getLabItemsRemovedPreparations(): Array<ExperimentPreparation> {
    return this.getPreparationsOf(this.activityId).filter(
      (m) => m.summary.preparationSubStatus === PreparationSubStatus.RemovedAndCannotBeRestored
      || m.summary.preparationSubStatus === PreparationSubStatus.RemovedButCanBeRestored);
  }

  private getPreparationsOf(activityNodeId: string): Array<ExperimentPreparation> {
    return (
      (this.experimentService.currentExperiment?.activityLabItems.find(
        (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === activityNodeId
      )?.preparations as Array<ExperimentPreparation>) || []
    );
  }

  private removedSuccessMessage(itemReference: string) {
    const successMessage = $localize`:@@LabItemRemovedSuccessMessage:The Lab Item ${this.preparationTitle} has been removed successfully.`;
    this.createNotificationMessage(successMessage, itemReference, 'success');
  }

  private restoredSuccessMessage(itemReference: string) {
    const successMessage = $localize`:@@LabItemRestoredSuccessMessage:The Lab Item ${this.preparationTitle} has been restored successfully.`;
    this.createNotificationMessage(successMessage, itemReference, 'success');
  }

  public refreshSuccessMessage(itemReference: string) {
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      detail:$localize`:@@LabItemPreparationDataRefreshed:The lab item preparation has been refreshed successfully ${itemReference}`,
      sticky: false
    });
  }

  private createNotificationMessage(summary: string, detail: string, severity = 'success') {
    const messageObj: Message = {
      key: 'notification',
      severity: severity,
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  private failedToPerformOperationMessage(itemReference: string) {
    this.createNotificationMessage(
      $localize`:@@LabItemsFailedToPerformOperationMessage:The action on the Lab Item Preparation cannot be performed because the experiment workflow state is ${
        this.experimentService.currentExperiment?.workflowState
      }.`,
      `${itemReference}`,
      'error'
    );
  }

  private hasRestorePermission(itemType: ActivityInputType): boolean {
    const permissions = this.evaluateUserPermissionsOnLabItems();
    return permissions[itemType][
      LabItemsFeatureManager.FeatureNamesByItemType[itemType].restoreFeatureName
    ];
  }

  private hasRemovePermission(itemType: ActivityInputType): boolean {
    const permissions = this.evaluateUserPermissionsOnLabItems();
    return permissions[itemType][
      LabItemsFeatureManager.FeatureNamesByItemType[itemType].removeFeatureName
    ];
  }
  
  public evaluateUserPermissionsOnLabItems(
    workflowState?: ExperimentWorkflowState
  ): LabItemWisePermissions {
    const currentWorkFlowState =
      workflowState ||
      this.experimentService.currentExperiment?.workflowState ||
      ExperimentWorkflowState.Setup;

    const permissions: LabItemWisePermissions =
      LabItemsFeatureManager.EvaluateUserPermissionsOnLabItems(
        currentWorkFlowState,
        this.featuresByClientState
      );    
    return permissions;
  }
  
  private watchPreparationRemovedNotification() {
    this.dataRecordService.labItemsPreparationRemovedEventNotificationReceiver.subscribe({            
      next: (notification) => this.processRemovedPreparation(notification)
    });
  }

  private watchPreparationRestoredNotification() {
    this.dataRecordService.labItemsPreparationRestoredEventNotificationReceiver.subscribe({     
      next: (notification) => this.processRestoredPreparation(notification)
    });
  }

  public loadAuditHistoryDialog() {
    this.elnProgressSpinnerService.Show(PreparationLabItemsService.AuditHistoryLoadingMessage);
    this.auditHistoryService
      .loadLabItemsAuditHistory(this.experimentId, this.activityId)
      .subscribe((data: AuditHistoryDataRecordResponse) => {
        var history = this.getAllAuditHistory(
          data,
          this.eventsForAuditHistory[ActivityInputType.Preparation]);        
        this.elnProgressSpinnerService.Hide();
        this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
          history,
          $localize`:@@preparations:Preparations`
        );
      });
  }

  private getAllAuditHistory(
    data: AuditHistoryDataRecordResponse,
    eventTypes: ExperimentEventType[]
  ) {
    return data.dataRecords.filter((record) => {
      return record?.eventContext &&
        eventTypes.indexOf(record.eventContext.eventType) > -1 &&
        (this.auditDataRecordsFilterByEventType[record.eventContext.eventType] === undefined ||
          this.auditDataRecordsFilterByEventType[record.eventContext.eventType](
            record,
            ActivityInputType.Preparation
          ));
    });
  }

  public getExclamationIndicator(tooltip: string, color: string): BptControlSeverityIndicator {
    return {
      indicatorType: SeverityIndicatorType.Exclamation,
      indicatorDefinition: {
        ...BptControlSeverityIndicators.ServerityIndicators[SeverityIndicatorType.Exclamation],
        Tooltip: tooltip,
        IsCustomTooltip: true,
        StyleClass: this.iconClasses[color] + ' icon-exclamation'
      }
    };
  }

  getSeverityIndicatorForExpiry(params: ICellRendererParams): BptControlSeverityIndicator {        
    let response!: BptControlSeverityIndicator;
    let formattedExpiration;
    let isPreparationExpired = false;    
    const currentDate = new Date(LocalDateTime.now().toString());
    const expiration = params.data.expiration;

    if (expiration !== NA && expiration !== null) {
      if (expiration instanceof LocalDate) {
        formattedExpiration = new Date(formatLocalDate(expiration));      
      }
      else {
        formattedExpiration = new Date(formatInstant(expiration, DateAndInstantFormat.dateTimeToMinute));
      }
      isPreparationExpired = formattedExpiration < currentDate;
    }
    if (isPreparationExpired) {
      return this.getExclamationIndicator($localize`:@@PreparationExpired:This preparation is expired.`, 'yellow');    
    } else {
      return response;
    }
  }

  getSeverityIndicatorForDiscardedOrConsumed(params: ICellRendererParams): BptControlSeverityIndicator {
    let response!: BptControlSeverityIndicator;     
    const activityLabItemNode = this.getActivityLabItemNode(this.activityId);
    if (activityLabItemNode) {
      const additionalInformation = activityLabItemNode.preparations.find((i) => i.preparationId === params.data.id)?.additionalInformation;
      if (additionalInformation?.discardedOrConsumed) {
        return this.getExclamationIndicator($localize`:@@PreparationDiscardedConsumed:Preparation is discarded/consumed.`, 'yellow');
      } else {
        return response;
      }
    }
    return response;    
  }

  getSeverityIndicatorForStatus(params: ICellRendererParams): BptControlSeverityIndicator {
    let response!: BptControlSeverityIndicator;     
    const activityLabItemNode = this.getActivityLabItemNode(this.activityId);
    if (activityLabItemNode) {
      const prepStatus = activityLabItemNode.preparations.find((i) => i.preparationId === params.data.id)?.status;
      if (prepStatus === ExperimentPreparationStatus.Pending) {
        return this.getExclamationIndicator($localize`:@@PreparationIsPending:Preparation is pending`, 'yellow');
      }
      else if(prepStatus === ExperimentPreparationStatus.Invalid) {
        return this.getExclamationIndicator($localize`:@@PreparationIsInvalid:Preparation is invalid`, 'red');
      } 
      else {
        return response;
      }
    }
    return response;
  }
}