/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChromatographyDataImportedEventDetails } from '../models/chromatography-data-imported-event-details';
import { ChromatographyDataRefreshedDetails } from '../models/chromatography-data-refreshed-details';

@Injectable({
  providedIn: 'root',
})
export class ExperimentBlobEventsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation experimentEventsEventIdChromatographyImportBlobsBlobNameGet
   */
  static readonly ExperimentEventsEventIdChromatographyImportBlobsBlobNameGetPath = '/experiment-events/{eventId}/chromatography-import/blobs/{blobName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain$Response(params: {
    eventId: string;
    blobName: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ChromatographyDataImportedEventDetails>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentBlobEventsService.ExperimentEventsEventIdChromatographyImportBlobsBlobNameGetPath, 'get');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.path('blobName', params.blobName, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChromatographyDataImportedEventDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain(params: {
    eventId: string;
    blobName: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ChromatographyDataImportedEventDetails> {

    return this.experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChromatographyDataImportedEventDetails>) => r.body as ChromatographyDataImportedEventDetails)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json$Response(params: {
    eventId: string;
    blobName: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ChromatographyDataImportedEventDetails>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentBlobEventsService.ExperimentEventsEventIdChromatographyImportBlobsBlobNameGetPath, 'get');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.path('blobName', params.blobName, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChromatographyDataImportedEventDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json(params: {
    eventId: string;
    blobName: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ChromatographyDataImportedEventDetails> {

    return this.experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChromatographyDataImportedEventDetails>) => r.body as ChromatographyDataImportedEventDetails)
    );
  }

  /**
   * Path part for operation experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet
   */
  static readonly ExperimentEventsEventIdChromatographyRefreshBlobsBlobNameGetPath = '/experiment-events/{eventId}/chromatography-refresh/blobs/{blobName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain$Response(params: {
    eventId: string;
    blobName: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ChromatographyDataRefreshedDetails>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentBlobEventsService.ExperimentEventsEventIdChromatographyRefreshBlobsBlobNameGetPath, 'get');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.path('blobName', params.blobName, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChromatographyDataRefreshedDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain(params: {
    eventId: string;
    blobName: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ChromatographyDataRefreshedDetails> {

    return this.experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChromatographyDataRefreshedDetails>) => r.body as ChromatographyDataRefreshedDetails)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json$Response(params: {
    eventId: string;
    blobName: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ChromatographyDataRefreshedDetails>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentBlobEventsService.ExperimentEventsEventIdChromatographyRefreshBlobsBlobNameGetPath, 'get');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.path('blobName', params.blobName, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChromatographyDataRefreshedDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json(params: {
    eventId: string;
    blobName: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ChromatographyDataRefreshedDetails> {

    return this.experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChromatographyDataRefreshedDetails>) => r.body as ChromatographyDataRefreshedDetails)
    );
  }

}
