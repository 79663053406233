import { Component, OnDestroy, OnInit, ViewChild, Renderer2, ElementRef, LOCALE_ID, Inject } from '@angular/core';
import { ColumnType } from '../../../api/models/column-type';
import { ActivityInputTestTableComponent } from '../activity-input-test-table/activity-input-test-table.component';
import { CellLock, LockType } from 'model/input-lock.interface';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { Experiment } from 'model/experiment.interface';
import {
  BptGridCellValueChangedEvent,
  BptGridComponent,
  BptGridMode,
  BptGridPreferences,
  ColumnDefinition,
  PreferenceAdded,
  PreferenceDeleted,
  PreferenceUpdated,
  PreferenceSelectionChanged,
  GridContextMenuItem,
  ISeverityIndicatorConfig,
  BptRowActionElement,
  BptGridRowActionClickEvent,
  BptGridRowActionConfiguration
} from 'bpt-ui-library/bpt-grid';
import { DataValueService } from '../../services/data-value.service';
import {
  ActivityInputType,
  AliquotTestDomain,
  ClientFacingNoteContextType,
  ModifiableDataValue,
  StringValue,
} from '../../../api/models';
import {
  AddSampleTestCommand,
  ActivityInputCellChangedEventNotification,
  ActivityInputRowRemovedEventNotification,
  ActivityInputRowRestoredEventNotification,
  SampleTestAddedEventNotification,
  RefreshActivityInputRowCommand,
  ActivityInputRowRefreshedEventNotification,
  AliquotTest,
  SampleTestAddedResponse,
  Aliquot
} from '../../../api/data-entry/models';
import { NA } from 'bpt-ui-library/shared';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputsComponent } from '../inputs.component';
import { ActivityInputService } from '../../../api/services/activity-input.service';
import { ActivityInputEventsService } from '../../../api/data-entry/services/activity-input-events.service';
import { UserService } from 'services/user.service';
import { User } from 'model/user.interface';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ExperimentService } from '../../services/experiment.service';
import { BarcodeScannerHelper } from 'services/barcode-scanner-helper';
import { SampleTests } from '../../model/sample-tests';
import { AuditHistoryService } from '../../audit-history/audit-history.service';
import {
  AuditHistoryDataRecordResponse,
  ExperimentDataRecordNotification,
  ExperimentEventType
} from '../../../api/audit/models';
import { DataRecordService } from '../../services/data-record.service';
import { cloneDeep, isEqual, mapValues } from 'lodash-es';
import { ActivityInputSamples } from '../../model/activity-input-samples';
import { UserPreferenceService } from '../../../api/services/user-preference.service';
import { ElnProgressSpinnerService } from '../../../../app/eln-progress-spinner-module/eln-progress-spinner.service';
import { SampleTableGridOptions } from './sample-table-grid-options';
import { GridPreferenceService } from '../../services/grid-preference.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BptGridCellEditEvent } from 'bpt-ui-library/bpt-grid/model/bpt-grid-cell-edit-event.interface';
import { ActivityInputConstants } from '../shared/activity-input-constants';
import { ELNAppConstants } from '../../../shared/eln-app-constants';
import { ActivityInputValidationHelper } from 'services/activity-input-validation-helper';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { CommentService } from '../../comments/comment.service';
import { CommentContextType } from '../../../api/internal-comment/models/comment-context-type';
import { CommentResponse, CommentsResponse, InternalCommentStatus } from '../../../api/internal-comment/models';
import { ShowClientFacingNotesEventData, ActivityInputClientFacingNoteContext } from '../../comments/client-facing-note/client-facing-note-event.model';
import { ClientFacingNoteModel } from '../../comments/client-facing-note/client-facing-note.model';
import { SampleTableRemovedRowsComponent } from './sample-table-removed-rows/sample-table-removed-rows.component';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { ActivatedRoute } from '@angular/router';
import { elnDecodeSpecialChars } from '../../../shared/url-path-serializer';
import { ActivityInputHelper } from '../shared/activity-input-helper';
import { ExperimentCollaboratorsService } from '../../../services/experiment-collaborators.service';
import { CompletionTrackingService } from '../../../services/completion-tracking.services';
const backgroundColorString = 'background-color';
@Component({
  selector: 'app-sample-table',
  templateUrl: './sample-table.component.html',
  styleUrls: ['./sample-table.component.scss']
})
export class SampleTableComponent implements OnInit, OnDestroy {
  columnDefinitions: ColumnDefinition[] = [];
  notApplicableData!: AliquotTestDomain;
  testData: AliquotTestDomain[] = [];
  gridMode = BptGridMode.dataEntry;
  sampleId = 'a3dee1b2-7ece-4262-8b02-b74e9f665187';
  sampleAliquots = $localize`:@@SampleAliquots:Samples & Aliquots`;
  dynamicDialogRef!: DynamicDialogRef;
  sampleTestCommand!: AddSampleTestCommand;
  user!: User;
  testWithSelectedData!: SampleTests;
  selectedTestData!: AliquotTestDomain[];
  experimentId!: string;
  primitiveValue!: { [key: string]: any }[];
  testReportableName = 'testReportableName';
  additionalInformation = 'AdditionalInformation';
  rowIndex = 'rowIndex';
  sample!: ActivityInputSamples;
  containsRemovedRows = false;
  isModalOpen = false;
  sampleTableGridSavedPreferences!: BptGridPreferences;
  subscriptionList: Subscription[] = [];
  lockTimeOut = 0;
  displayScrollButtons = true;
  rowActions: BptRowActionElement[] = [];
  experiment!: Experiment;
  gridActions!: BptGridRowActionConfiguration;
  sampleDataSource!: ActivityInputSamples[];
  previousSampleDataSource: ActivityInputSamples[] = [];
  // TODO Bug created to fix this https://dev.azure.com/BPTCollection/Eurofins%20ELN/_backlogs/backlog/Konark/Backlog%20items/?workitem=3225439
  private readonly dateFormat = 'yyyy-MM-dd';
  reloadGrid = true;
  deleteLock = 'sample-aliquot-delete-row';
  refreshLock = 'sample-aliquot-refresh-row';
  completionPercent = 0;
  private readonly rowActionItems = [ this.deleteLock, this.refreshLock ];  
  private readonly pointerEventsAttr = 'pointer-events';
  private readonly colorString = 'color';

  public isLoading = false;
  private readonly eventsForHistory: ExperimentEventType[] = [
    ExperimentEventType.AliquotAdded,
    ExperimentEventType.ActivityInputAdded,
    ExperimentEventType.ActivityInputCellChanged,
    ExperimentEventType.ActivityInputRowRefreshed,
    ExperimentEventType.ActivityInputRowRestored,
    ExperimentEventType.ActivityInputRowRemoved,
    ExperimentEventType.SampleTestChanged
  ];
  @ViewChild('Grid') grid!: BptGridComponent;

  constructor(
    private readonly dataValueService: DataValueService,
    private readonly validationHelper: ActivityInputValidationHelper,
    private readonly dialogService: DialogService,
    private readonly experimentService: ExperimentService,
    private readonly barcodeScannerHelper: BarcodeScannerHelper,
    private readonly inputsComponent: InputsComponent,
    private readonly userService: UserService,
    private readonly preferenceService: UserPreferenceService,
    private readonly messageService: MessageService,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly dataRecordService: DataRecordService,
    private readonly confirmationService: ConfirmationService,
    private readonly activityInputService: ActivityInputService,
    private readonly activityInputEventsService: ActivityInputEventsService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly gridPreferenceService: GridPreferenceService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly experimentCollaboratorsService: ExperimentCollaboratorsService,
    private readonly commentService: CommentService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly activityInputHelper: ActivityInputHelper,
    private readonly completionTrackingService: CompletionTrackingService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {
  }

  ngOnInit(): void {
    this.handleSubscriptions();
    SampleTableGridOptions.isReadOnly = this.inputsComponent.activityInputComponentsReadonly;
    this.user = { ...this.userService.currentUser };
    this.columnDefinitions = SampleTableGridOptions.prepareColumns(this.openModal);
    this.experiment = this.experimentService.currentExperiment as Experiment;
    this.experimentId = this.experiment?.id;
    this.refreshDataSource();
    this.loadGridPreferences();
    this.internalCommentsChanged();
    this.initializeGridRowActions();
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-id', this.sampleId);
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-title', this.sampleAliquots);
  }

  getPrimitiveDataValueRows(
    incomingRows: { [key: string]: ModifiableDataValue | string }[]
  ): { [key: string]: any }[] {
    return incomingRows.map((row) =>
      mapValues(row, (value: ModifiableDataValue | string, field: string) => {
        return this.dataValueService.getPrimitiveValue(ColumnType.String,
          value as ModifiableDataValue
        ); 
      })
    );
  }

  loadGridPreferences(): void {
    this.preferenceService
      .userPreferencesUserPreferenceKeyGet$Json$Response({
        userPreferenceKey: this.sampleId
      })
      .subscribe((res) => {
        this.sampleTableGridSavedPreferences = {
          enabled: true,
          preferences: this.gridPreferenceService.buildGridPreference(res.body.userPreferences)
        };
      });
  }

  cellEditStartedEvent(e: BptGridCellEditEvent) {
    this.lockTimeOut = window.setTimeout(() => {
      if(e.gridId && e.rowId && e.field)
        this.activityInputHelper.sendInputStatus(LockType.lock, e.gridId, e.rowId, e.field);
    }, 500);
  }

  cellEditStoppedEvent(e: BptGridCellEditEvent) {
    if(!e.gridId || !e.rowId || !e.field) return;
    window.clearTimeout(this.lockTimeOut);
    this.activityInputHelper.sendInputStatus(LockType.unlock, e.gridId, e.rowId, e.field);
  }

  saveNewPreference($event: PreferenceAdded): void {
    this.gridPreferenceService.saveNewPreference($event, this.user.puid,this.sampleTableGridSavedPreferences, this.grid, this.sampleId);
  }

  deletePreference($event: PreferenceDeleted): void {
    this.gridPreferenceService.deletePreference($event);
  }

  updatePreference($event: PreferenceUpdated): void {
    this.gridPreferenceService.updatePreference($event, this.user.puid, this.sampleId);
  }

  changeDefaultPreference($event: PreferenceSelectionChanged): void {
    this.gridPreferenceService.changeDefaultPreference($event, this.user.puid, this.sampleId);
  }

  public severityIndicatorConfig = () => {
    return {
      getIndicator: this.getSeverityIndicator //Called by the ag grid cell renderer
    } as ISeverityIndicatorConfig;
  };

  public severityIndicatorConfigForTest = () => {
    return {
      getIndicator: this.getSeverityIndicatorForTest
    } as ISeverityIndicatorConfig;
  };

  private readonly getSeverityIndicator = (params: ICellRendererParams) => {
    return this.validationHelper.getSeverityIndicatorDefinition(this.sampleDataSource, params);
  }

  private readonly getSeverityIndicatorForTest = (params: ICellRendererParams) => {
    return this.validationHelper.getSeverityIndicatorDefinitionForTest(this.sampleDataSource, params, this.previousSampleDataSource);
  }

  setValidationStyles = (): void => {
    this.columnDefinitions?.forEach((columnDefinition: ColumnDefinition) => {
      if (columnDefinition.editable) {
        columnDefinition.severityIndicatorConfig = this.severityIndicatorConfig;
      }
      else if(columnDefinition.field === this.testReportableName) {
        columnDefinition.severityIndicatorConfig = this.severityIndicatorConfigForTest;
      }
      this.grid?.updateColumnDefinitions(columnDefinition);
    });
  };  

  updateSampleTestDataRecord(data: SampleTestAddedEventNotification) {
      const row = this.sampleDataSource.find(
        (x) => x.aliquotNumber === data.activityInputReference
      );
      if (row) {
        this.previousSampleDataSource = this.getPreviouslyUpdatedDataSource();
        row.aliquotTests = data.aliquotTests;
        this.updateTestProperties(row);
        this.completionPercent = this.completionTrackingService.calculateActivityInputsTableCompletionPercent(this.sampleDataSource, ActivityInputType.Aliquot);
        this.grid?.gridApi?.applyTransaction({
          update: [row]
        });
    }
  }

  updateCellChangedDataRecord(data: ActivityInputCellChangedEventNotification) {
    this.syncCellValueToClientSideMemoryWhenItsChanged(data.activityInputReference, data.propertyValue);
  }

  /**
   * Gets called to load audit history dialog
   */
  loadAuditHistoryDialog(rowId?: string, field?: string) {
    this.isLoading = true;
    this.auditHistoryService
      .loadActivityInputsAuditHistory(this.experimentId, this.experimentService.currentActivityId)
      .subscribe((data: AuditHistoryDataRecordResponse) => {
        data.dataRecords = this.getSampleAliquotRecords(data.dataRecords);
        this.isLoading = false;
        if (!rowId || !field) {
          this.allAuditHistory(data);
          return;
        }
        switch (field) {
          case this.testReportableName:
          case ActivityInputConstants.testName:
          case ActivityInputConstants.testCode:
          case ActivityInputConstants.testKey:
          case ActivityInputConstants.reportableMethodReference:
            this.testSelectionAuditHistory(rowId, field, data);
            break;
          default:
            this.cellChangedAuditHistory(rowId, field, data);
        }
      });
  }

  onGridReady() {
    this.setValidationStyles();
    this.augmentColumnsWithCornerFlagProviderForCells();
    this.activityInputHelper.loadCellLocks(this.grid.gridOptions, this.renderer);
  }

  openModal = (row: any): void => {
    if (this.isModalOpen || this.inputsComponent.activityInputComponentsReadonly) {
      return;
    }
    this.activityInputHelper.sendInputStatus(
      LockType.lock,
      this.grid.gridId ?? '',
      row.data.aliquotNumber ?? '',
      row.column.colId ?? ''
    );
    this.isModalOpen = true;
    this.testData = [];
    this.showTestDetailsRetrievingMessage(row.data.aliquotNumber);
    this.activityInputService
      .activityInputRetrieveSampleTestPost$Json({
        sampleNumber: row.data.sampleNumber,
        body: [row.data.aliquotNumber]
      })
      .subscribe(
        (response) => {
          this.elnProgressSpinnerService.Hide();
          this.testData = response.tests;
          this.testWithSelectedData = {
            Tests: this.testData.length > 0 ? this.testData : [],
            TestIds: this.getTestIdsForSelectedTest(row.data),
            AliquotNumber: row.node.id
          };
          this.dynamicDialogRef = this.dialogService.open(ActivityInputTestTableComponent, {
            width: '55%',
            autoZIndex: true,
            closeOnEscape: true,
            data: this.testWithSelectedData,
            showHeader: true,
            header: $localize`:@@SelectAliquotTest:Select Test(s)`,
            styleClass: 'eln-test-dialog'
          });

          this.dynamicDialogRef.onClose.subscribe((params) => {
            this.isModalOpen = false;
            this.activityInputHelper.sendInputStatus(
              LockType.unlock,
              this.grid.gridId ?? '',
              row.data.aliquotNumber ?? '',
              row.column.colId ?? ''
            );
          });
        },
        (error: any) => {
          this.elnProgressSpinnerService.Hide();
          this.isModalOpen = false;
          this.messageService.add({
            key: 'notification',
            severity: 'error',
            summary: $localize`:@@TestsNotFound:Tests Not found for this sample`,
            detail: ` ${row.node.key}`
          });
        }
      );
  };

  saveTestSelectionCallback = (sampleTest: SampleTests) => {
    if (!!sampleTest) {
      this.selectedTestData = sampleTest.Tests;
      this.prepareSampleCommand(sampleTest.AliquotNumber);
      this.activityInputEventsService
        .activityInputEventsChangeSampleTestPost$Json({
          body: this.sampleTestCommand
        })
        .subscribe({
          next: (response: SampleTestAddedResponse) => this.onSuccessCallback(response, sampleTest),
          error: () => this.onErrorCallback(sampleTest)
         }
        );
    }
  }

  onSuccessCallback(response: SampleTestAddedResponse, sampleTest: SampleTests) {
    this.updateGridDataOnTestSelect(sampleTest.AliquotNumber);
            this.messageService.add({
              key: 'notification',
              severity: 'success',
              summary: $localize`:@@TestAddedSuccessfully:Tests Added Successfully ${response.sampleTestAddedNotification.aliquotTests
                .map((k: AliquotTestDomain) => k.testCode).join(',')}`,
              detail: `for this sample ${response.sampleTestAddedNotification.activityInputReference}`
            });
  }

  onErrorCallback(sampleTest: SampleTests){
   this.messageService.add({
              key: 'notification',
              severity: 'error',
              summary: $localize`:@@SampleTestAdditionFailed:Test Addition failed for this aliquot`,
              detail: ` ${sampleTest.AliquotNumber}`
            });
  }

  getTestIdsForSelectedTest(data: Aliquot) {
    if(data.aliquotTests !== null && data.aliquotTests.length === 1 && data.aliquotTests[0].testCode === NA)
      return [];
    return data.aliquotTests.length > 0 ? data.aliquotTests.map((aqt: AliquotTest) => aqt.testId) : [];
  }

  private readonly deleteCellRendererCallback = (data: ActivityInputSamples) => {
    this.activityInputHelper.sendInputStatus(
      LockType.lock,
      this.grid.gridId,
      data.aliquotNumber,
      this.activityInputHelper.rowDeleteIdentifier
    );
    var command = {
      activityId: this.experimentService.currentActivityId,
      activityInputReference: data.aliquotNumber,
      activityInputType: ActivityInputType.Aliquot,
      experimentId: this.experimentId
    };
    const confirmationMessage = $localize`:@@activityInputRowRemovalConfirmation:Are you sure you want to remove this row?
       The row may restored through use of the View Removed Rows Option.`;
    this.confirmationService.confirm({
      message: `${confirmationMessage}`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@confirmationOk:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@confirmationCancel:Cancel`,
      closeOnEscape: true,
      dismissableMask: false,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.activityInputEventsService
          .activityInputEventsRemoveRowPost$Json$Response({body: command})
          .subscribe({
            next: (response) => {
              this.activityInputHelper.removeAliquotFromSampleSource(data.aliquotNumber, response.body.rowRemovedEventNotification.activityId);
              this.refreshDataSource();
              this.messageService.add({
                key: 'notification',
                severity: 'success',
                summary: $localize`:@@aliquotRemoved:Aliquot Removed Successfully ${response.body.rowRemovedEventNotification.activityInputReference}`
              });
            },
            error: () => {
              this.messageService.add({
                key: 'notification',
                severity: 'error',
                summary: $localize`:@@aliquotRemovedFailed:Failed to remove particular Aliquot`,
                detail: ` ${command.activityInputReference}`
              });
              this.activityInputHelper.sendInputStatus(
                LockType.unlock,
                this.grid.gridId,
                data.aliquotNumber,
                this.activityInputHelper.rowDeleteIdentifier
              );
            }})
      },
      reject: () => {
        this.activityInputHelper.sendInputStatus(
          LockType.unlock,
          this.grid.gridId,
          data.aliquotNumber,
          this.activityInputHelper.rowDeleteIdentifier
        );
      }
    });
  };

  refreshCellRendererCallback = (data: ActivityInputSamples) => {
    const aliquotNumber = data.aliquotNumber;
    this.activityInputHelper.sendInputStatus(
      LockType.lock,
      this.grid.gridId,
      aliquotNumber,
      this.activityInputHelper.rowRefreshIdentifier
    );
    const cell = document.querySelector(
      `ag-grid-angular [row-id="${aliquotNumber}"] [id="${this.activityInputHelper.rowRefreshIdentifier}"] `
    )
    const pendingImg = 'pending-img';
    this.renderer.addClass(cell, pendingImg);
    const aliquot = cloneDeep(this.sampleDataSource.find(sample => sample.aliquotNumber === aliquotNumber));
    if(!aliquot) return;
    aliquot.modifiableFields = [];
    this.activityInputEventsService
      .activityInputEventsRefreshRowPost$Json$Response({
        body: this.getRefreshActivityInputCommand(aliquot)
      })
      .subscribe({
        next: (data) => {
          this.refreshRows(data.body.rowRefreshedEventNotification);
          this.messageService.add({
            key: 'notification',
            severity: 'success',
            summary: $localize`:@@AliquotRefreshSuccess:Aliquot Refreshed Successfully ${aliquotNumber}`
          });
          this.renderer.removeClass(cell, pendingImg);
          this.activityInputHelper.sendInputStatus(
            LockType.unlock,
            this.grid.gridId,
            aliquotNumber,
            this.activityInputHelper.rowRefreshIdentifier
          );
        },
        error: () => {
          this.renderer.removeClass(cell, pendingImg);
        }
      });
  };

  cellValueChanged(e: BptGridCellValueChangedEvent) {
      this.activityInputHelper.cellValueChanged(e, ActivityInputType.Aliquot);
      const propertyValue = this.dataValueService.getExperimentDataValue(ColumnType.String, e.newValue);
    if (e.rowId) {
      this.syncCellValueToClientSideMemoryWhenItsChanged(e.rowId, propertyValue);
    }
  }

  viewRemovedRows() {
    this.dynamicDialogRef = this.dialogService.open(SampleTableRemovedRowsComponent, {
      width: '80%',
      autoZIndex: true,
      closable: true,
      closeOnEscape: true,
      data: {
        isReadOnly: this.inputsComponent.activityInputComponentsReadonly
      },
      styleClass: 'eln-removed-sample-dialog',
      header: $localize`:@@removedRowsModalHeader:Removed Rows for Table: ${this.sampleAliquots}`
    });
  }

  private augmentColumnsWithCornerFlagProviderForCells(): void {
    const flagConfigProvider = (
      flag: 'top-right' | 'bottom-right',
      rowId: string,
      field: string
    ) => {
      if (flag === 'top-right') {
        const note = this.experiment.clientFacingNotes.find((n) =>
          isEqual(n.path, [rowId, field, this.experimentService.currentActivityId])
        ); // can depend on rowId being globally unique
        return {
          enabled: note ? true : false,
          color: ELNAppConstants.ClientFacingNoteFlagColor,
          hoverText: note?.indicatorText ?? '', // by policy for notes, empty is not allowed
          onClick: () => this.showClientFacingNotes(rowId, field)
        };
      }
      if (flag === 'bottom-right') {
        const internalComments = this.experiment.internalComments?.comments.find(
          (x: CommentResponse) => x.path.includes(rowId) && x.path.includes(field)
            && x.path.includes(this.experimentService.currentActivityId) && x.status !== InternalCommentStatus.Removed
        ); // can depend on rowId being globally unique

        return {
          enabled: internalComments ? true : false,
          color: ELNAppConstants.InternalCommentFlagColor,
          isHollow: internalComments?.status === InternalCommentStatus.Pending,
          hoverText: this.activityInputHelper.getHoverOverText(internalComments?.content),
          onClick: () => this.showInternalComments(rowId, field)
        };
      }

      return { enabled: false, color: 'orange', hoverText: 'not used today', onClick: () => { } };
    };

    this.columnDefinitions.forEach(
      (c: ColumnDefinition) => (c.flagConfigProvider = flagConfigProvider)
    );
  }

  showInternalComments(rowId: string, field: string): void {
    const { currentRowId, currentField } = this.commentService.showGridForInternalComments(this.grid?.gridApi, rowId, field);
    const cell = document.querySelector(`ag-grid-angular [row-id="${currentRowId}"] [col-id="${currentField}"]`);
    if (cell) {
      this.renderer.setStyle(
        cell,
        'background-color',
        ELNAppConstants.InternalCommentBackGroundColor
      );
    }
    this.buildInternalComments(currentRowId, currentField, rowId);
  }

  onClientFacingNoteEvent(note: ClientFacingNoteModel): void {
    if (note.contextType !== ClientFacingNoteContextType.ActivityInput) return;
    const context = note.context as ActivityInputClientFacingNoteContext;
    context.activityId = this.experimentService.currentActivityId;
    const rowNode = this.grid.gridApi.getRowNode(context.rowId);
    const column = this.grid.gridColumnApi.getColumn(context.columnField);
    if (!rowNode || !column) return;

    this.grid.gridApi.refreshCells({ force: true, rowNodes: [rowNode], columns: [column] });
  }

  showClientFacingNotes(rowId: string, field: string): void {
    const eventTarget: ActivityInputClientFacingNoteContext = {
      activityInputId: this.grid.gridId,
      columnField: field,
      rowId: rowId,
      activityId: this.experimentService.currentActivityId,
      tableTitle: $localize`:@@SampleAliquots:Samples & Aliquots`,
      label:this.columnDefinitions.find(c => c.field === field)?.label as string
    };
    const details: ShowClientFacingNotesEventData = {
      eventContext: {
        contextType: ClientFacingNoteContextType.ActivityInput,
        mode: 'clientFacingNotes'
      },
      targetContext: eventTarget
    };
    const customEvent = new CustomEvent<ShowClientFacingNotesEventData>('ShowSlider', {
      bubbles: true,
      detail: details
    });
    this.elementRef.nativeElement.dispatchEvent(customEvent);
  }

  getContextMenu(): GridContextMenuItem[] {
    const builtInItems: GridContextMenuItem[] = ['copy', 'copyWithHeaders', 'copyWithGroupHeaders', 'paste', 'separator'];
    return builtInItems.concat(this.getCustomContextMenuItems());
  }

  private refreshDataSource(): void {
    const aliquots = this.activityInputHelper.getSampleAliquots();

    this.sampleDataSource = [...aliquots];
    this.previousSampleDataSource = this.getPreviouslyUpdatedDataSource();
    this.grid?.gridApi?.setRowData(this.sampleDataSource);
    this.containsRemovedRows = (this.activityInputHelper.getSampleAliquots(true)).length > 0;
    this.completionPercent = this.completionTrackingService.calculateActivityInputsTableCompletionPercent(this.sampleDataSource, ActivityInputType.Aliquot);
    this.grid?.gridColumnApi?.autoSizeAllColumns();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptionList);
  }

  private getCustomContextMenuItems() {
    return [
      {
        label: $localize`:@@clientFacingNoteContextMenuOption:Client-facing Notes`,
        action: () => {
          this.checkForValidRow(true);
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />'
      },
      {
        label: $localize`:@@commentsHeader:Internal Comments`,
        action: () => {
          this.checkForValidRow(false);
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />',
      },
      {
        label: $localize`:@@History:History`,
        action: () => this.getHistoryFromContext(),
        icon: '<img src="assets/eln-assets/audit-history.svg" class="ag-icon ag-custom-icon" />'
      }
    ];
  }

  private checkForValidRow(isClientFacingNote: boolean) {
    const cell = this.grid.gridApi.getFocusedCell();
    if (cell) {
      const row = this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
      const colDef = cell.column.getColDef();
      const field = cell.column.getColDef().field as string;
      if (row && row.id && colDef?.field) {
        if (isClientFacingNote) {
          this.showClientFacingNotes(row.id, field);
        } else {
          this.showInternalComments(row.id, field);
        }
      }
    }
  }

  private buildInternalComments(rowId: string | undefined, field: string, parentRowId: string) {
    const activity = this.experiment.activities.find(
      (act) => act.activityId === this.experimentService.currentActivityId
    );
    const column = this.columnDefinitions.find(c => c.field === field)?.label;
    
    if (activity) {
      const cell = this.grid && this.grid.gridApi.getFocusedCell();
    if (cell) {
      const rowIndex = this.grid && this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex)?.rowIndex;
      if (rowIndex !== null && rowIndex !== undefined && column && rowId) {
      this.commentService.setUpDataForInternalComments(
        activity.activityId,
        [activity.activityId, column, rowId, field, (rowIndex + 1).toString(), CommentContextType.SampleAliquot],
        CommentContextType.TableCell
      );
      }
    }
  }
  }

  internalCommentsChanged() {
    this.commentService.refreshInternalComment.subscribe((currentContext: CommentsResponse) => {
      this.experiment.internalComments = currentContext;
      this.grid?.gridApi?.refreshCells({ force: true });
    });
  }

  private cellChangedAuditHistory(
    rowId: string,
    field: string,
    data: AuditHistoryDataRecordResponse
  ) {
    const cellChangedRecords = data.dataRecords
      .filter(
        (d): d is ActivityInputCellChangedEventNotification =>
          d?.eventContext.eventType.toString() === ExperimentEventType.ActivityInputCellChanged
      )
      .filter((c: any) => {
        return (
          (c.activityInputReference === rowId) && (c.propertyName.toLowerCase() === field.toLowerCase())
        );
      });
    const column = this.columnDefinitions.find(c => c.field === field);
    if (!column) return;
    cellChangedRecords.forEach((x: ActivityInputCellChangedEventNotification) => x.propertyName = column.label)

    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      cellChangedRecords,
      $localize`:@@SampleAliquots:Samples & Aliquots`.concat(
        ELNAppConstants.WhiteSpace,
        '→',
        ELNAppConstants.WhiteSpace,
        column.label
      ));
  }

  private getHistoryFromContext(): void {
    const cell = this.grid.gridApi.getFocusedCell();
    if (!cell) return;
    const row = this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
    const field = cell.column.getColDef()?.field as string;
    const rowId = row?.id as string;
    this.loadAuditHistoryDialog(rowId, field);
  }

  private testSelectionAuditHistory(
    rowId: string,
    field: string,
    data: AuditHistoryDataRecordResponse
  ) {
    let cellChangedRecords: ExperimentDataRecordNotification[] = data.dataRecords
      .filter(
        (d: any): d is SampleTestAddedEventNotification =>
          d?.eventContext.eventType.toString() === ExperimentEventType.SampleTestChanged
      )
      .filter((c: any) => c.activityInputReference === rowId);
    const column = this.columnDefinitions.find(c => c.field === field);
    if (!column) return;
    const changedRecords = data.dataRecords
      .filter(
        (d: any): d is ActivityInputCellChangedEventNotification =>
          d?.eventContext.eventType.toString() === ExperimentEventType.ActivityInputCellChanged
      )
      .filter((c: any) => {
        return (c.activityInputReference === rowId && `${c.propertyName.toLowerCase()}` === field.toLowerCase());
      });
    cellChangedRecords = cellChangedRecords.concat(changedRecords);
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      cellChangedRecords,
      $localize`:@@SampleAliquots:Samples & Aliquots`.concat(
        ELNAppConstants.WhiteSpace,
        '→',
        ELNAppConstants.WhiteSpace,
        column.label
      ));
  }

  private handleSubscriptions() {
    this.subscriptionList.push(
      this.inputsComponent.readOnlyOnStateChange.subscribe((val) => {
        SampleTableGridOptions.isReadOnly = val;
        this.columnDefinitions = SampleTableGridOptions.prepareColumns(
          this.openModal
        );
        this.initializeGridRowActions();
        this.setValidationStyles();
        this.reloadGrid = false;
        setTimeout(() => {
          this.reloadGrid = true;
        }, 200);
        this.grid.applyPreviousSelectedPreference();
      })
    );
    this.subscriptionList.push(
      this.activityInputHelper.updateSampleTestDataSource.subscribe({
        next: this.updateSampleTestDataRecord.bind(this)
      })
    );
    this.subscriptionList.push(
      this.activityInputHelper.updateSampleTableCellChangedDataSource.subscribe({
        next: this.updateCellChangedDataRecord.bind(this)
      })
    );
    this.subscriptionList.push(
      this.activityInputHelper.updateSampleAliquotTableDataSource.subscribe({
        next: this.refreshDataSource.bind(this)
      })
    );
    this.subscriptionList.push(
      this.activityInputHelper.refreshSampleTableDataSource.subscribe({
        next: this.refreshDataSource.bind(this)
      })
    );
    this.subscriptionList.push(
      this.barcodeScannerHelper.sampleTableDataSourcePrepared.subscribe((data) => {
        if (data?.activityInputType !== ActivityInputType.Aliquot) return;
        this.refreshDataSource();
      })
    );
    this.subscriptionList.push(this.experimentNotificationService.inputLockReceiver.subscribe((lock) => {
      this.activityInputHelper.applyCellLock(lock, this.grid?.gridOptions, this.renderer);
    }));
    this.subscriptionList.push(this.experimentService.clientFacingNoteEvents.subscribe((note) =>
      this.onClientFacingNoteEvent(note)
    ));
    this.subscriptionList.push(this.activityInputHelper.aliquotRestored.subscribe(() => {
      this.refreshDataSource();
    }));
    this.subscriptionList.push(
      this.activityInputHelper.saveTestSelection.subscribe((data: SampleTests) => {
        this.saveTestSelectionCallback(data);
      })
    );
    this.subscriptionList.push(this.activatedRoute.params.subscribe((params) => {
      const currentActivityTitle = elnDecodeSpecialChars(params['itemTitle']);
      const activity = this.experimentService.currentExperiment?.activities.find(
        (a) => a.itemTitle === currentActivityTitle
      );
      if(!activity) return;
      this.refreshDataSource();
    }));
  }

  private initializeGridRowActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);

    this.gridActions = {
      actions: actionsSubject
    };
  }

  public applyCellLock(lockList: CellLock[]) {
    lockList.forEach((lock) => {
      if (this.experimentService.currentActivityId !== lock.activityId) return;
      if (!this.grid.gridOptions?.context?.inputLocks)
        this.grid.gridOptions.context = { inputLocks: {} };

      this.grid.gridOptions.context.inputLocks[lock.rowId + lock.columnName] =
        lock.lockType === LockType.lock;
      
      if(this.rowActionItems.includes(lock.columnName)) {
        this.lockActionItems(lock);
        return;
      }
      this.lockEditableCells(lock)
    });
  }

  private lockEditableCells(lock: CellLock) {
    const lockOwner = this.experimentCollaboratorsService.getExperimentCollaborator(
      lock.experimentCollaborator.connectionId
    ) ?? lock.experimentCollaborator;
    const cell = document.querySelector(
      `ag-grid-angular [row-id="${lock.rowId}"] [col-id="${lock.columnName}"] `
    );
    if (!cell) return;

    if (lock.lockType === LockType.lock) {
      const borderColor = lockOwner?.backgroundColor ?? 'blue';
      this.renderer.setAttribute(cell.parentElement, 'title', lockOwner?.fullName || '');
      this.renderer.setStyle(cell, backgroundColorString, '#F9F9F9');
      this.renderer.setStyle(cell, 'border', `1px solid ${borderColor}`);
      this.renderer.setStyle(cell, this.pointerEventsAttr, `none`);
    } else {
      this.renderer.removeStyle(cell, 'border');
      this.renderer.removeStyle(cell, backgroundColorString);
      this.renderer.removeAttribute(cell.parentElement, 'title');
      this.renderer.removeStyle(cell, this.pointerEventsAttr);
    }
  }

  private lockActionItems(lock: CellLock) {
    const lockOwner = this.experimentCollaboratorsService.getExperimentCollaborator(
      lock.experimentCollaborator.connectionId
    ) ?? lock.experimentCollaborator;
    const cell = document.querySelector(
      `ag-grid-angular [row-id="${lock.rowId}"] [id="${lock.columnName}"] `
    )
    if(!cell) return;
    if (lock.lockType === LockType.lock) {
      this.renderer.setAttribute(cell.parentElement, 'title', lockOwner?.fullName || '');
      this.renderer.setStyle(cell, this.colorString, '#999999');
      this.renderer.setStyle(cell, 'opacity', `0.5`);
      this.renderer.setStyle(cell, this.pointerEventsAttr, `none`);
    } else {
      this.renderer.removeStyle(cell, this.colorString);
      this.renderer.removeAttribute(cell.parentElement, 'title');
      this.renderer.setStyle(cell, 'opacity', `1`);
      this.renderer.removeStyle(cell, this.pointerEventsAttr);
    }
  }

  private prepareSampleCommand(aliquotNumber: string) {
    this.sampleTestCommand = {
      experimentId: this.experimentId,
      activityId: this.experimentService.currentActivityId,
      activityInputReference: aliquotNumber,
      activityInputType: ActivityInputType.Aliquot,
      aliquotTests: this.selectedTestData,
      sampleId: this.sampleId      
    };
  }

  private allAuditHistory(data: AuditHistoryDataRecordResponse) {
    const history = data.dataRecords.filter((x) => {
      return (
        x !== null &&
        x.eventContext !== null &&
        this.eventsForHistory.indexOf(x.eventContext.eventType) > -1
      );
    });
    history.forEach((x: any) => {
      if (!x.propertyName) return;
      const column = this.columnDefinitions.find(c => c.field?.toLowerCase() === x.propertyName.toLowerCase());
      x.propertyName = column?.label
    });
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      history,
      $localize`:@@SampleAliquots:Samples & Aliquots`);
  }

  private showTestDetailsRetrievingMessage(sampleNumber: string) {
    const spinnerOptions = {
      message: $localize`:@@testRetreival:Retrieving test details from the server for the sample: ${sampleNumber}...`,
      i18nMessage: `@@testRetreival`
    };
    this.elnProgressSpinnerService.Show(spinnerOptions);
  }

  private syncCellValueToClientSideMemoryWhenItsChanged(aliquotNumber: string, newValue: StringValue): void {
    const row = this.sampleDataSource.find((row) => row.aliquotNumber === aliquotNumber);
    if (!row) return 
    row.AdditionalInformation = DataValueService.getStringFromValue(newValue);
    this.activityInputHelper.setAndUpdateModifiableDataField(row.modifiableFields, this.additionalInformation, newValue);
    this.grid.gridApi.refreshCells({ force: true });
    this.grid.gridApi.applyTransaction({update: [row]});
  }

  private getRefreshActivityInputCommand(aliquot: Aliquot): RefreshActivityInputRowCommand {
    return {
      aliquotDetails: [aliquot],
      experimentId: this.experimentId,
      activityInputType: ActivityInputType.Aliquot,
      activityId: this.experimentService.currentActivityId
    };
  }

  refreshRows(data: ActivityInputRowRefreshedEventNotification) {
    data.aliquotsDetails.forEach((detail: any) => {
      const aliquotRowData = this.sampleDataSource.find((row) =>
      row.aliquotNumber === detail.activityInputReference)
      
      if (aliquotRowData) {
        this.refreshSampleRowData(detail, aliquotRowData);
      }
      this.completionPercent = this.completionTrackingService.calculateActivityInputsTableCompletionPercent(this.sampleDataSource, ActivityInputType.Aliquot);
    });
  }

  private refreshSampleRowData(detail: any, aliquotRowData: any) {
    mapValues(detail.modifiedFields, (item: any) => {
      mapValues(Object.keys(aliquotRowData), (propertyName: string) => {
        if (propertyName.toLowerCase() === item.propertyName.toLowerCase())
        {
          aliquotRowData[propertyName] = item.propertyValue.value;
        }
      });
    });
    this.previousSampleDataSource = this.getPreviouslyUpdatedDataSource();
    aliquotRowData.aliquotTests = detail.aliquotTests;
    aliquotRowData = this.updateTestProperties(aliquotRowData);
    this.grid?.gridApi?.applyTransaction({
      update: [aliquotRowData]
    });
    this.grid?.gridApi?.refreshCells({ force: true });
  }

  getPreviouslyUpdatedDataSource(){
    return cloneDeep(this.sampleDataSource);
  }

  private updateTestProperties(row: ActivityInputSamples): ActivityInputSamples {
    row.testCode = row.aliquotTests.map(t => this.activityInputHelper.formatTestProperties(t.testCode)).join(', ');
    row.testKey = row.aliquotTests.map(t => this.activityInputHelper.formatTestProperties(t.testKey)).join(', ');
    row.testName = row.aliquotTests.map(t => this.activityInputHelper.formatTestProperties(t.testName)).join(', ');
    row.testReportableMethodReference = row.aliquotTests.map(t => this.activityInputHelper.formatTestProperties(t.testReportableMethodReference)).join(', ');
    row.testReportableName = row.aliquotTests.map(t => this.activityInputHelper.formatTestProperties(t.testReportableName)).join(', ');
    row.testStatus = row.aliquotTests.map(t => this.activityInputHelper.formatTestProperties(t.testStatus)).join(', ');
    const colDef = this.columnDefinitions.find(c => c.field === this.testReportableName)
    if (colDef) colDef.severityIndicatorConfig = this.severityIndicatorConfigForTest;
    return row;
  }

  private updateGridDataOnTestSelect(aliquotNumber: string) {
    if (!this.selectedTestData) return;
    this.previousSampleDataSource = this.getPreviouslyUpdatedDataSource();
    let row = this.sampleDataSource.find(x => x.aliquotNumber === aliquotNumber);
    const existingAliquot = this.activityInputHelper.getSampleAliquotByAliquotNumber(aliquotNumber);
    existingAliquot!.aliquotTests = this.selectedTestData;
    if (!row) return;    
    row.aliquotTests = this.selectedTestData;
    row = this.updateTestProperties(row);
    this.completionPercent = this.completionTrackingService.calculateActivityInputsTableCompletionPercent(this.sampleDataSource, ActivityInputType.Aliquot);
    this.grid?.gridApi?.applyTransaction({
      update: [row]
    });
    this.grid?.gridApi?.refreshCells({ force: true });
  }

  private getSampleAliquotRecords(dataRecords: ExperimentDataRecordNotification[]): ExperimentDataRecordNotification[] {
    return dataRecords.filter((x: any) => x &&
      (x.activityInputType === ActivityInputType.Aliquot ||
        x.eventContext.eventType === ExperimentEventType.AliquotAdded ||
        x.eventContext.eventType === ExperimentEventType.SampleTestChanged))
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.activityInputHelper.rowDeleteIdentifier,
        enabled: !this.inputsComponent.activityInputComponentsReadonly,
        styleClass: 'far fa-trash-alt',
        click: (e: BptGridRowActionClickEvent) => this.deleteCellRendererCallback(e.params.data),
        tooltip: $localize`:@@RemoveItem:Remove item`
      },
      {
        id: this.activityInputHelper.rowRefreshIdentifier,
        enabled: !this.inputsComponent.activityInputComponentsReadonly,
        styleClass: 'fas fa-sync-alt',
        click: (e: BptGridRowActionClickEvent) => this.refreshCellRendererCallback(e.params.data),
        tooltip: $localize`:@@RefreshItem:Refresh item`
      }
    ];
  }
}
