/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddThread } from '../models/add-thread';
import { CommentsResponse } from '../models/comments-response';
import { EditReply } from '../models/edit-reply';
import { EditThread } from '../models/edit-thread';
import { ReplyAddedResponse } from '../models/reply-added-response';
import { ReplyDeletedResponse } from '../models/reply-deleted-response';
import { ReplyEditedResponse } from '../models/reply-edited-response';
import { ReplyThread } from '../models/reply-thread';
import { ThreadAddedResponse } from '../models/thread-added-response';
import { ThreadEditedResponse } from '../models/thread-edited-response';
import { ThreadRemovedResponse } from '../models/thread-removed-response';
import { ThreadStateChangeResponse } from '../models/thread-state-change-response';

@Injectable({
  providedIn: 'root',
})
export class InternalCommentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation experimentsExperimentIdInternalCommentsGet
   */
  static readonly ExperimentsExperimentIdInternalCommentsGetPath = '/experiments/{experimentId}/internal-comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsGet$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<CommentsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsGetPath, 'get');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsGet$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<CommentsResponse> {

    return this.experimentsExperimentIdInternalCommentsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentsResponse>) => r.body as CommentsResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsGet$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<CommentsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsGetPath, 'get');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsGet$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<CommentsResponse> {

    return this.experimentsExperimentIdInternalCommentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentsResponse>) => r.body as CommentsResponse)
    );
  }

  /**
   * Path part for operation experimentsExperimentIdInternalCommentsAddPost
   */
  static readonly ExperimentsExperimentIdInternalCommentsAddPostPath = '/experiments/{experimentId}/internal-comments/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsAddPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsAddPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddThread
  }): Observable<StrictHttpResponse<ThreadAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsAddPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThreadAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsAddPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsAddPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddThread
  }): Observable<ThreadAddedResponse> {

    return this.experimentsExperimentIdInternalCommentsAddPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ThreadAddedResponse>) => r.body as ThreadAddedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsAddPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsAddPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddThread
  }): Observable<StrictHttpResponse<ThreadAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsAddPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThreadAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsAddPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsAddPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddThread
  }): Observable<ThreadAddedResponse> {

    return this.experimentsExperimentIdInternalCommentsAddPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ThreadAddedResponse>) => r.body as ThreadAddedResponse)
    );
  }

  /**
   * Path part for operation experimentsExperimentIdInternalCommentsCommentIdEditPut
   */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdEditPutPath = '/experiments/{experimentId}/internal-comments/{commentId}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdEditPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdEditPut$Plain$Response(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: EditThread
  }): Observable<StrictHttpResponse<ThreadEditedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdEditPutPath, 'put');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThreadEditedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdEditPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdEditPut$Plain(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: EditThread
  }): Observable<ThreadEditedResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdEditPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ThreadEditedResponse>) => r.body as ThreadEditedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdEditPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdEditPut$Json$Response(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: EditThread
  }): Observable<StrictHttpResponse<ThreadEditedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdEditPutPath, 'put');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThreadEditedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdEditPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdEditPut$Json(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: EditThread
  }): Observable<ThreadEditedResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdEditPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ThreadEditedResponse>) => r.body as ThreadEditedResponse)
    );
  }

  /**
   * Path part for operation experimentsExperimentIdInternalCommentsCommentIdResolvePut
   */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdResolvePutPath = '/experiments/{experimentId}/internal-comments/{commentId}/resolve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain$Response(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ThreadStateChangeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdResolvePutPath, 'put');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThreadStateChangeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ThreadStateChangeResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ThreadStateChangeResponse>) => r.body as ThreadStateChangeResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json$Response(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ThreadStateChangeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdResolvePutPath, 'put');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThreadStateChangeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ThreadStateChangeResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ThreadStateChangeResponse>) => r.body as ThreadStateChangeResponse)
    );
  }

  /**
   * Path part for operation experimentsExperimentIdInternalCommentsCommentIdReopenPut
   */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdReopenPutPath = '/experiments/{experimentId}/internal-comments/{commentId}/reopen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain$Response(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ThreadStateChangeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdReopenPutPath, 'put');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThreadStateChangeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ThreadStateChangeResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ThreadStateChangeResponse>) => r.body as ThreadStateChangeResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json$Response(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ThreadStateChangeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdReopenPutPath, 'put');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThreadStateChangeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ThreadStateChangeResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ThreadStateChangeResponse>) => r.body as ThreadStateChangeResponse)
    );
  }

  /**
   * Path part for operation experimentsExperimentIdInternalCommentsCommentIdDelete
   */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdDeletePath = '/experiments/{experimentId}/internal-comments/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdDelete$Plain$Response(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ThreadRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdDeletePath, 'delete');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThreadRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdDelete$Plain(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ThreadRemovedResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ThreadRemovedResponse>) => r.body as ThreadRemovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdDelete$Json$Response(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ThreadRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdDeletePath, 'delete');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThreadRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdDelete$Json(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ThreadRemovedResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ThreadRemovedResponse>) => r.body as ThreadRemovedResponse)
    );
  }

  /**
   * Path part for operation experimentsExperimentIdInternalCommentsCommentIdAddPost
   */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdAddPostPath = '/experiments/{experimentId}/internal-comments/{commentId}/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdAddPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdAddPost$Plain$Response(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ReplyThread
  }): Observable<StrictHttpResponse<ReplyAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdAddPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdAddPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdAddPost$Plain(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ReplyThread
  }): Observable<ReplyAddedResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdAddPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReplyAddedResponse>) => r.body as ReplyAddedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdAddPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdAddPost$Json$Response(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ReplyThread
  }): Observable<StrictHttpResponse<ReplyAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdAddPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdAddPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdAddPost$Json(params: {
    experimentId: string;
    commentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ReplyThread
  }): Observable<ReplyAddedResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdAddPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReplyAddedResponse>) => r.body as ReplyAddedResponse)
    );
  }

  /**
   * Path part for operation experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut
   */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPutPath = '/experiments/{experimentId}/internal-comments/{commentId}/Replies/{replyIndex}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain$Response(params: {
    experimentId: string;
    commentId: string;
    replyIndex: number;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: EditReply
  }): Observable<StrictHttpResponse<ReplyEditedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPutPath, 'put');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.path('replyIndex', params.replyIndex, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyEditedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain(params: {
    experimentId: string;
    commentId: string;
    replyIndex: number;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: EditReply
  }): Observable<ReplyEditedResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReplyEditedResponse>) => r.body as ReplyEditedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json$Response(params: {
    experimentId: string;
    commentId: string;
    replyIndex: number;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: EditReply
  }): Observable<StrictHttpResponse<ReplyEditedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPutPath, 'put');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.path('replyIndex', params.replyIndex, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyEditedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json(params: {
    experimentId: string;
    commentId: string;
    replyIndex: number;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: EditReply
  }): Observable<ReplyEditedResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReplyEditedResponse>) => r.body as ReplyEditedResponse)
    );
  }

  /**
   * Path part for operation experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete
   */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDeletePath = '/experiments/{experimentId}/internal-comments/{commentId}/Replies/index/{replyIndex}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain$Response(params: {
    experimentId: string;
    commentId: string;
    replyIndex: number;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ReplyDeletedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDeletePath, 'delete');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.path('replyIndex', params.replyIndex, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyDeletedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain(params: {
    experimentId: string;
    commentId: string;
    replyIndex: number;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ReplyDeletedResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReplyDeletedResponse>) => r.body as ReplyDeletedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json$Response(params: {
    experimentId: string;
    commentId: string;
    replyIndex: number;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ReplyDeletedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InternalCommentService.ExperimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDeletePath, 'delete');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('commentId', params.commentId, {});
      rb.path('replyIndex', params.replyIndex, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyDeletedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json(params: {
    experimentId: string;
    commentId: string;
    replyIndex: number;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ReplyDeletedResponse> {

    return this.experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReplyDeletedResponse>) => r.body as ReplyDeletedResponse)
    );
  }

}
