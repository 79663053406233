<div
  *ngIf="
    (labItemsColumnDataSource && labItemsColumnDataSource.length > 0) || containsRemovedRows
  "
  class="eln-lab-items-data eln-table-theme secondary"
>
  <div>
    <span
      id="eln-lab-item-column"
      class="eln-study-activity-aliquot-icon table-title title-absolute"
      >{{ titleOfTable }}
    </span>
</div>
  <bpt-grid
    #labItemsColumnGrid
    *ngIf="reloadGrid"
    class="eln-grid hide-readonly-icons"
    ngClass="true"
    gridId="{{ labItemsColumnId }}"
    [dataSource]="labItemsColumnDataSource"
    [columnDefinitions]="columnDefinitions"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    [showAutoSizeButton]="true"
    [showFilterToggleButton]="true"
    [showGridOptionsButton]="false"
    [paginated]="false"
    [contextMenu]="getContextMenu()"
    (cellValueChanged)="cellValueChanged($event)"
    [rowActionConfigurations]="gridActions"
    (firstDataRendered)="onFirstDataRendered($event)"
    (cellEditStarted)="cellEditStartedEvent($event)"
    (cellEditStopped)="cellEditStoppedEvent($event)"
  >
  <ng-container class="bpt-grid-toolbar-right-layout">
    <a
      class="bpt-grid-toolbar-item table-grid-toolbar-audit-icon"
      align="right"
      id="eln-iconAuditHistoryShow-{{ labItemsColumnId }}"
      (click)="loadAuditHistoryDialog()"
      >
      <span
        i18n-pTooltip="@@viewHistory"
        class="fa fa-history"
        pTooltip="View History"
        tooltipPosition="top"><span class="ml-1 audit" i18n="@@History">History</span></span>
    </a>
  </ng-container>
  <ng-container class="bpt-grid-toolbar-right-layout">
    <a
      *ngIf="containsRemovedRows"
      class="bpt-grid-toolbar-item table-grid-toolbar-view-removed-rows-icon consumable-remove-icon"
      align="right"
      id="eln-iconViewRemovedRowsShow-{{ labItemsColumnId }}"
      (click)="loadRemovedRowsDialog()">
      <span
        i18n-pTooltip="@@viewRemovedRows"
        class="fa icon-view-row"
        pTooltip="View Removed Rows"
        tooltipPosition="top"><span class="ml-1 viewRemovedRows-iconTxt" i18n="@@viewRemovedRows">View Removed
          Rows</span></span>
    </a>
  </ng-container>
  </bpt-grid>
</div>
