import { Injectable } from '@angular/core';
import { NA, Quantity, ValueState } from 'bpt-ui-library/shared';
import { UnitLoaderService } from 'services/unit-loader.service';
import {
  NumberValue,
  ObservationSpec,
  SingleValueSpec,
  SpecComparisonOperator,
  SpecDisplayType,
  SpecificationValue,
  SpecType,
  TwoValueRangeSpec
} from '../../api/data-entry/models';

export type SpecTypeDropdownOption = {
  label: string;
  value: SpecType;
};

export enum OperatorBoundaryType {
  Invalid,
  Lower,
  Upper
}

export enum OperatorExclusivity {
  Invalid,
  Inclusive,
  Exclusive
}

export class SpecComparisonOperatorDropdownOption {
  public get label(): string {
    return `${this.inequalityLabel} (${this.abbreviationLabel})`;
  }

  constructor(public inequalityLabel: string,
    public abbreviationLabel: string,
    public value: SpecComparisonOperator,
    public boundaryType?: OperatorBoundaryType,
    public exclusivity?: OperatorExclusivity) { }
};

@Injectable({
  providedIn: 'root',
})
export class SpecificationService {
  private static readonly Assembly: string = 'ELN.Blazor.Entry' as const;
  public static readonly CacheUnits: string = 'CacheUnits' as const;
  private static readonly ValidateSpecificationValues: string = 'ValidateSpecificationValues' as const;

  constructor(private readonly unitLoaderService: UnitLoaderService) {

  }

  /** valid spec types as label/value pairs to appear in dropdowns */
  public static get validTypesForDropdown(): SpecTypeDropdownOption[] {
    return [
      { label: $localize`:@@observation:Observation`, value: SpecType.Observation },
      { label: $localize`:@@singleValue:Single Value`, value: SpecType.SingleValue },
      { label: $localize`:@@twoValueRange:Two Value Range`, value: SpecType.TwoValueRange }
    ];
  }

  /** valid spec types */
  public static get validTypes(): SpecType[] {
    return this.validTypesForDropdown.map(t => t.value);
  }

  public static get validComparisonOperatorsForDropdown(): SpecComparisonOperatorDropdownOption[] {
    return [
      new SpecComparisonOperatorDropdownOption('=', $localize`:@@EqualToAbbreviation:Equal To`, SpecComparisonOperator.EqualTo),
      new SpecComparisonOperatorDropdownOption('≠', $localize`:@@NotEqualToAbbreviation:Not Equal To`, SpecComparisonOperator.NotEqualTo),
      new SpecComparisonOperatorDropdownOption('>', $localize`:@@MoreThanAbbreviation:MT`, SpecComparisonOperator.MoreThan,
        OperatorBoundaryType.Lower, OperatorExclusivity.Exclusive),
      new SpecComparisonOperatorDropdownOption('<', $localize`:@@LessThanAbbreviation:LT`, SpecComparisonOperator.LessThan,
        OperatorBoundaryType.Upper, OperatorExclusivity.Exclusive),
      new SpecComparisonOperatorDropdownOption('≥', $localize`:@@NotLessThanAbbreviation:NLT`, SpecComparisonOperator.NotLessThan,
        OperatorBoundaryType.Lower, OperatorExclusivity.Inclusive),
      new SpecComparisonOperatorDropdownOption('≤', $localize`:@@NotMoreThanAbbreviation:NMT`, SpecComparisonOperator.NotMoreThan,
        OperatorBoundaryType.Upper, OperatorExclusivity.Inclusive)
    ];
  }

  public getDisplayString(specification: SpecificationValue | undefined): string {
    if (!specification) return '';
    if (specification.state === ValueState.Empty) return '';
    if (specification.state === ValueState.NotApplicable) return NA;

    switch (specification.specType) {
      case SpecType.Observation: 
        return this.getObservationDisplayString(specification);
      case SpecType.SingleValue: 
        return this.getSingleValueDisplayString(specification);
      case SpecType.TwoValueRange: 
        return this.getTwoValueRangeDisplayString(specification);
      default:
        return '';
    }
  }

  public getObservationDisplayString(observation: Partial<ObservationSpec> | undefined): string {
    return observation?.value ?? "";
  }

  public getSingleValueDisplayString(singleValueSpec: Partial<SingleValueSpec> | undefined): string {
    if (
      !singleValueSpec?.displayType ||
      !singleValueSpec.sourceToValueOperator ||
      !singleValueSpec.value?.state ||
      singleValueSpec.value?.state === ValueState.Empty
    ) return '';

    const displayType = singleValueSpec.displayType ? singleValueSpec.displayType : SpecDisplayType.Expression;

    const operatorOption = SpecificationService.validComparisonOperatorsForDropdown.find(o => o.value === singleValueSpec.sourceToValueOperator);
    const operator = displayType === SpecDisplayType.Abbreviation ? operatorOption?.abbreviationLabel : operatorOption?.inequalityLabel;

    return `${operator} ${this.convertNumberValueToQuantity(singleValueSpec.value)?.toString()}`;
  }

  public getTwoValueRangeDisplayString(twoValueRangeSpec: Partial<TwoValueRangeSpec> | undefined): string {
    if (
      !twoValueRangeSpec?.sourceToUpperValueOperator ||
      !twoValueRangeSpec.sourceToLowerValueOperator ||
      !twoValueRangeSpec.upperValue?.state ||
      !twoValueRangeSpec.lowerValue?.state ||
      twoValueRangeSpec.upperValue?.state === ValueState.Empty ||
      twoValueRangeSpec.lowerValue?.state === ValueState.Empty
    ) return '';

    const displayType = twoValueRangeSpec.displayType ? twoValueRangeSpec.displayType : SpecDisplayType.Expression;

    const upperOperatorOption = SpecificationService.validComparisonOperatorsForDropdown.find(o => o.value === twoValueRangeSpec.sourceToUpperValueOperator);
    const upperOperator = twoValueRangeSpec.displayType === SpecDisplayType.Abbreviation ? upperOperatorOption?.abbreviationLabel : upperOperatorOption?.inequalityLabel;

    const lowerOperatorSpecifiedOption = SpecificationService.validComparisonOperatorsForDropdown.find(o => o.value === twoValueRangeSpec.sourceToLowerValueOperator);

    // inequality types put the operator behind the lower value instead of ahead, so we need to reverse the operator so it makes sense.
    const lowerOperatorOption = displayType === SpecDisplayType.Inequality
      ? SpecificationService.validComparisonOperatorsForDropdown.find(o => {
        return o.exclusivity === lowerOperatorSpecifiedOption?.exclusivity && o.boundaryType !== lowerOperatorSpecifiedOption?.boundaryType
      })
      : lowerOperatorSpecifiedOption;
    const lowerOperator = displayType === SpecDisplayType.Abbreviation ? lowerOperatorOption?.abbreviationLabel : lowerOperatorOption?.inequalityLabel;

    const unitsAreEqual = twoValueRangeSpec.lowerValue?.unit === twoValueRangeSpec.upperValue?.unit;

    // if the units are equal, only display them after the upper value.
    const lowerValue = unitsAreEqual ? `${twoValueRangeSpec.lowerValue?.value}` : this.convertNumberValueToQuantity(twoValueRangeSpec.lowerValue)?.toString();
    const upperValue = this.convertNumberValueToQuantity(twoValueRangeSpec.upperValue)?.toString();

    switch (displayType) {
      case SpecDisplayType.Expression:
      case SpecDisplayType.Abbreviation:
        return `${lowerOperator} ${lowerValue} and ${upperOperator} ${upperValue}`;
      case SpecDisplayType.Inequality:
        return `${lowerValue} ${lowerOperator} x ${upperOperator} ${upperValue}`;
      default:
        return '';
    }
  }

  public static validateTwoValueRangeSpecValues(spec: Partial<TwoValueRangeSpec>): { IsValid: boolean, RuleException: string } {
    const validationResult: any = DotNet.invokeMethod(
        SpecificationService.Assembly,
        SpecificationService.ValidateSpecificationValues,
        JSON.stringify(spec.lowerValue),
        JSON.stringify(spec.upperValue)
      );

      return JSON.parse(validationResult.result);
  }

  /** This didn't used to be async, but we made it async. We hope we didn't cause any problems */
  public async cacheUnitsInBlazor(): Promise<void> {
    try {
      await DotNet.invokeMethodAsync(
        SpecificationService.Assembly,
        SpecificationService.CacheUnits,
        JSON.stringify(this.unitLoaderService.allUnits)
      );
    } catch (error) {
      console.error('Unable to invoke CacheUnitList', error);
    }
  }

  public convertNumberValueToQuantity(source: NumberValue | undefined): Quantity | undefined {
    if (!source) return undefined;
    const unit = this.unitLoaderService.allUnits.find(u => u.id === source.unit);
    return new Quantity(source.state, source.value, unit, source.sigFigs, source.exact);
  }
}
