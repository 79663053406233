/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateExperimentPreparationsCommand } from '../models/create-experiment-preparations-command';
import { ExperimentNewPreparations } from '../models/experiment-new-preparations';
import { ExperimentPreparationCellChangeCommand } from '../models/experiment-preparation-cell-change-command';
import { ExperimentPreparationChangeInternalInformationCommand } from '../models/experiment-preparation-change-internal-information-command';
import { ExperimentPreparationChangeStatusCommand } from '../models/experiment-preparation-change-status-command';
import { ExperimentPreparationDiscardOrConsumeCommand } from '../models/experiment-preparation-discard-or-consume-command';
import { PreparationCellChangedResponse } from '../models/preparation-cell-changed-response';
import { PreparationDiscardedOrConsumedResponse } from '../models/preparation-discarded-or-consumed-response';
import { PreparationInternalInformationChangedResponse } from '../models/preparation-internal-information-changed-response';
import { PreparationRemovedResponse } from '../models/preparation-removed-response';
import { PreparationRestoredResponse } from '../models/preparation-restored-response';
import { PreparationStatusChangedResponse } from '../models/preparation-status-changed-response';
import { RemovePreparationCommand } from '../models/remove-preparation-command';
import { RestorePreparationCommand } from '../models/restore-preparation-command';

@Injectable({
  providedIn: 'root',
})
export class PreparationsEventsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation preparationsEventsCreatePreparationsPost
   */
  static readonly PreparationsEventsCreatePreparationsPostPath = '/preparations-events/create-preparations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsCreatePreparationsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsCreatePreparationsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateExperimentPreparationsCommand
  }): Observable<StrictHttpResponse<ExperimentNewPreparations>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsCreatePreparationsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentNewPreparations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsCreatePreparationsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsCreatePreparationsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateExperimentPreparationsCommand
  }): Observable<ExperimentNewPreparations> {

    return this.preparationsEventsCreatePreparationsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentNewPreparations>) => r.body as ExperimentNewPreparations)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsCreatePreparationsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsCreatePreparationsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateExperimentPreparationsCommand
  }): Observable<StrictHttpResponse<ExperimentNewPreparations>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsCreatePreparationsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentNewPreparations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsCreatePreparationsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsCreatePreparationsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateExperimentPreparationsCommand
  }): Observable<ExperimentNewPreparations> {

    return this.preparationsEventsCreatePreparationsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentNewPreparations>) => r.body as ExperimentNewPreparations)
    );
  }

  /**
   * Path part for operation preparationsEventsRemovePreparationPost
   */
  static readonly PreparationsEventsRemovePreparationPostPath = '/preparations-events/remove-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsRemovePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRemovePreparationPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemovePreparationCommand
  }): Observable<StrictHttpResponse<PreparationRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsRemovePreparationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsRemovePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRemovePreparationPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemovePreparationCommand
  }): Observable<PreparationRemovedResponse> {

    return this.preparationsEventsRemovePreparationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationRemovedResponse>) => r.body as PreparationRemovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsRemovePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRemovePreparationPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemovePreparationCommand
  }): Observable<StrictHttpResponse<PreparationRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsRemovePreparationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsRemovePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRemovePreparationPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemovePreparationCommand
  }): Observable<PreparationRemovedResponse> {

    return this.preparationsEventsRemovePreparationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationRemovedResponse>) => r.body as PreparationRemovedResponse)
    );
  }

  /**
   * Path part for operation preparationsEventsRestorePreparationPost
   */
  static readonly PreparationsEventsRestorePreparationPostPath = '/preparations-events/restore-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsRestorePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRestorePreparationPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestorePreparationCommand
  }): Observable<StrictHttpResponse<PreparationRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsRestorePreparationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsRestorePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRestorePreparationPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestorePreparationCommand
  }): Observable<PreparationRestoredResponse> {

    return this.preparationsEventsRestorePreparationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationRestoredResponse>) => r.body as PreparationRestoredResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsRestorePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRestorePreparationPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestorePreparationCommand
  }): Observable<StrictHttpResponse<PreparationRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsRestorePreparationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsRestorePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRestorePreparationPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestorePreparationCommand
  }): Observable<PreparationRestoredResponse> {

    return this.preparationsEventsRestorePreparationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationRestoredResponse>) => r.body as PreparationRestoredResponse)
    );
  }

  /**
   * Path part for operation preparationsEventsDiscardOrConsumePreparationPost
   */
  static readonly PreparationsEventsDiscardOrConsumePreparationPostPath = '/preparations-events/discard-or-consume-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsDiscardOrConsumePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsDiscardOrConsumePreparationPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationDiscardOrConsumeCommand
  }): Observable<StrictHttpResponse<PreparationDiscardedOrConsumedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsDiscardOrConsumePreparationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationDiscardedOrConsumedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsDiscardOrConsumePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsDiscardOrConsumePreparationPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationDiscardOrConsumeCommand
  }): Observable<PreparationDiscardedOrConsumedResponse> {

    return this.preparationsEventsDiscardOrConsumePreparationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationDiscardedOrConsumedResponse>) => r.body as PreparationDiscardedOrConsumedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsDiscardOrConsumePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsDiscardOrConsumePreparationPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationDiscardOrConsumeCommand
  }): Observable<StrictHttpResponse<PreparationDiscardedOrConsumedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsDiscardOrConsumePreparationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationDiscardedOrConsumedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsDiscardOrConsumePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsDiscardOrConsumePreparationPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationDiscardOrConsumeCommand
  }): Observable<PreparationDiscardedOrConsumedResponse> {

    return this.preparationsEventsDiscardOrConsumePreparationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationDiscardedOrConsumedResponse>) => r.body as PreparationDiscardedOrConsumedResponse)
    );
  }

  /**
   * Path part for operation preparationsEventsChangePreparationInternalInformationPost
   */
  static readonly PreparationsEventsChangePreparationInternalInformationPostPath = '/preparations-events/change-preparation-internal-information';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsChangePreparationInternalInformationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationInternalInformationPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationChangeInternalInformationCommand
  }): Observable<StrictHttpResponse<PreparationInternalInformationChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsChangePreparationInternalInformationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationInternalInformationChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsChangePreparationInternalInformationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationInternalInformationPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationChangeInternalInformationCommand
  }): Observable<PreparationInternalInformationChangedResponse> {

    return this.preparationsEventsChangePreparationInternalInformationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationInternalInformationChangedResponse>) => r.body as PreparationInternalInformationChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsChangePreparationInternalInformationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationInternalInformationPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationChangeInternalInformationCommand
  }): Observable<StrictHttpResponse<PreparationInternalInformationChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsChangePreparationInternalInformationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationInternalInformationChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsChangePreparationInternalInformationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationInternalInformationPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationChangeInternalInformationCommand
  }): Observable<PreparationInternalInformationChangedResponse> {

    return this.preparationsEventsChangePreparationInternalInformationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationInternalInformationChangedResponse>) => r.body as PreparationInternalInformationChangedResponse)
    );
  }

  /**
   * Path part for operation preparationsEventsPreparationCellChangePost
   */
  static readonly PreparationsEventsPreparationCellChangePostPath = '/preparations-events/preparation-cell-change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsPreparationCellChangePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsPreparationCellChangePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationCellChangeCommand
  }): Observable<StrictHttpResponse<PreparationCellChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsPreparationCellChangePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationCellChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsPreparationCellChangePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsPreparationCellChangePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationCellChangeCommand
  }): Observable<PreparationCellChangedResponse> {

    return this.preparationsEventsPreparationCellChangePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationCellChangedResponse>) => r.body as PreparationCellChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsPreparationCellChangePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsPreparationCellChangePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationCellChangeCommand
  }): Observable<StrictHttpResponse<PreparationCellChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsPreparationCellChangePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationCellChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsPreparationCellChangePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsPreparationCellChangePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationCellChangeCommand
  }): Observable<PreparationCellChangedResponse> {

    return this.preparationsEventsPreparationCellChangePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationCellChangedResponse>) => r.body as PreparationCellChangedResponse)
    );
  }

  /**
   * Path part for operation preparationsEventsChangePreparationStatusPost
   */
  static readonly PreparationsEventsChangePreparationStatusPostPath = '/preparations-events/change-preparation-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsChangePreparationStatusPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationStatusPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationChangeStatusCommand
  }): Observable<StrictHttpResponse<PreparationStatusChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsChangePreparationStatusPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationStatusChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsChangePreparationStatusPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationStatusPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationChangeStatusCommand
  }): Observable<PreparationStatusChangedResponse> {

    return this.preparationsEventsChangePreparationStatusPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationStatusChangedResponse>) => r.body as PreparationStatusChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsChangePreparationStatusPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationStatusPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationChangeStatusCommand
  }): Observable<StrictHttpResponse<PreparationStatusChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsEventsService.PreparationsEventsChangePreparationStatusPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationStatusChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsChangePreparationStatusPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationStatusPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentPreparationChangeStatusCommand
  }): Observable<PreparationStatusChangedResponse> {

    return this.preparationsEventsChangePreparationStatusPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationStatusChangedResponse>) => r.body as PreparationStatusChangedResponse)
    );
  }

}
