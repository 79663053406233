import { Routes } from '@angular/router';
import { CoverComponent } from './cover/cover.component';
import { DataComponent } from './data/data.component';
import { ExperimentComponent } from './experiment.component';
import { ViewsComponent } from './views/views.component';
import { ClientState } from '../app.states';
import { InputsComponent } from './inputs/inputs.component';
import { OutputsComponent } from './outputs/outputs.component';
import { UnsavedChangesGuard } from '../routing/unsaved-changes.guard';
import { LabItemsComponent } from './labItems/lab-items/lab-items.component';
import { ReferencesComponent } from './references/references.component';
import { ExperimentPreparationsComponent } from './preparations/experiment-preparations/experiment-preparations.component'
import { blazorStartResolver } from '../shared/blazor-js/blazor-start-resolver';
export const EXPERIMENT_ROUTES: Routes = [
  {
    path: ':experimentNumber',
    component: ExperimentComponent,
    data: { title: 'Experiment', clientState: ClientState.EXPERIMENT },
    resolve: {
      preloadScripts: blazorStartResolver
    },
    children: [
      { path: '', redirectTo: 'cover', pathMatch: 'full' },
      { path: 'cover', component: CoverComponent, data: { clientState: ClientState.EXPERIMENT_COVER },  canDeactivate: [UnsavedChangesGuard], },
      { path: ':itemTitle/Modules', component: DataComponent, data: { clientState: ClientState.EXPERIMENT_ACTIVITIES },  canDeactivate: [UnsavedChangesGuard], },
      { path: ':itemTitle/References', component: ReferencesComponent, data: { clientState: ClientState.EXPERIMENT_ACTIVITIES } },
      { path: ':itemTitle/Inputs', component: InputsComponent, data: { clientState: ClientState.EXPERIMENT_ACTIVITIES } },
      { path: 'views', component: ViewsComponent, data: { clientState: ClientState.EXPERIMENT_VIEWS } },
      { path: ':itemTitle/Preparations', component: ExperimentPreparationsComponent, data: { clientState: ClientState.EXPERIMENT_ACTIVITIES } },
      { path: ':itemTitle/LabItems', component: LabItemsComponent, data: { clientState: ClientState.EXPERIMENT_ACTIVITIES } },
      { path: ':itemTitle/Outputs', component: OutputsComponent, data: { clientState: ClientState.EXPERIMENT_ACTIVITIES } }
    ]  
  }
];
