/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangeExperimentAssignedAnalystsCommand } from '../models/change-experiment-assigned-analysts-command';
import { ChangeExperimentAssignedReviewersCommand } from '../models/change-experiment-assigned-reviewers-command';
import { ChangeExperimentAssignedSupervisorsCommand } from '../models/change-experiment-assigned-supervisors-command';
import { ChangeExperimentAuthorizationDueDateCommand } from '../models/change-experiment-authorization-due-date-command';
import { ChangeExperimentNodeOrderCommand } from '../models/change-experiment-node-order-command';
import { ChangeExperimentNodeTitleCommand } from '../models/change-experiment-node-title-command';
import { ChangeExperimentScheduledReviewStartDateCommand } from '../models/change-experiment-scheduled-review-start-date-command';
import { ChangeExperimentScheduledStartDateCommand } from '../models/change-experiment-scheduled-start-date-command';
import { ChangeExperimentSubBusinessUnitsCommand } from '../models/change-experiment-sub-business-units-command';
import { ChangeExperimentTagsCommand } from '../models/change-experiment-tags-command';
import { ChangeExperimentTitleCommand } from '../models/change-experiment-title-command';
import { CreateExperimentCommand } from '../models/create-experiment-command';
import { ExperimentAssignedAnalystsChangedResponse } from '../models/experiment-assigned-analysts-changed-response';
import { ExperimentAssignedReviewersChangedResponse } from '../models/experiment-assigned-reviewers-changed-response';
import { ExperimentAssignedSupervisorsChangedResponse } from '../models/experiment-assigned-supervisors-changed-response';
import { ExperimentAuthorizationDueDateChangedResponse } from '../models/experiment-authorization-due-date-changed-response';
import { ExperimentAuthorizedResponse } from '../models/experiment-authorized-response';
import { ExperimentCancelledResponse } from '../models/experiment-cancelled-response';
import { ExperimentCreatedResponse } from '../models/experiment-created-response';
import { ExperimentNodeOrderChangedNotification } from '../models/experiment-node-order-changed-notification';
import { ExperimentNodeTitleChangedNotification } from '../models/experiment-node-title-changed-notification';
import { ExperimentRestoredResponse } from '../models/experiment-restored-response';
import { ExperimentScheduledReviewStartDateChangedResponse } from '../models/experiment-scheduled-review-start-date-changed-response';
import { ExperimentScheduledStartDateChangedResponse } from '../models/experiment-scheduled-start-date-changed-response';
import { ExperimentSentForCorrectionResponse } from '../models/experiment-sent-for-correction-response';
import { ExperimentSentForReviewResponse } from '../models/experiment-sent-for-review-response';
import { ExperimentStartedResponse } from '../models/experiment-started-response';
import { ExperimentSubBusinessUnitsChangedResponse } from '../models/experiment-sub-business-units-changed-response';
import { ExperimentTagsChangedResponse } from '../models/experiment-tags-changed-response';
import { ExperimentTemplateAppliedResponse } from '../models/experiment-template-applied-response';
import { ExperimentTemplateApplyCommand } from '../models/experiment-template-apply-command';
import { ExperimentTitleChangedResponse } from '../models/experiment-title-changed-response';
import { SetVariableCommand } from '../models/set-variable-command';
import { SetVariableResponse } from '../models/set-variable-response';

@Injectable({
  providedIn: 'root',
})
export class ExperimentEventsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation experimentEventsCreateExperimentPost
   */
  static readonly ExperimentEventsCreateExperimentPostPath = '/experiment-events/create-experiment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsCreateExperimentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsCreateExperimentPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateExperimentCommand
  }): Observable<StrictHttpResponse<ExperimentCreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsCreateExperimentPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentCreatedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsCreateExperimentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsCreateExperimentPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateExperimentCommand
  }): Observable<ExperimentCreatedResponse> {

    return this.experimentEventsCreateExperimentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentCreatedResponse>) => r.body as ExperimentCreatedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsCreateExperimentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsCreateExperimentPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateExperimentCommand
  }): Observable<StrictHttpResponse<ExperimentCreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsCreateExperimentPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentCreatedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsCreateExperimentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsCreateExperimentPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateExperimentCommand
  }): Observable<ExperimentCreatedResponse> {

    return this.experimentEventsCreateExperimentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentCreatedResponse>) => r.body as ExperimentCreatedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsExperimentIdClosePost
   */
  static readonly ExperimentEventsExperimentIdClosePostPath = '/experiment-events/{experimentId}/close';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdClosePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdClosePost$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdClosePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdClosePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdClosePost(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<void> {

    return this.experimentEventsExperimentIdClosePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation experimentEventsExperimentIdStartPost
   */
  static readonly ExperimentEventsExperimentIdStartPostPath = '/experiment-events/{experimentId}/start';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdStartPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdStartPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentStartedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdStartPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentStartedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdStartPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdStartPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentStartedResponse> {

    return this.experimentEventsExperimentIdStartPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentStartedResponse>) => r.body as ExperimentStartedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdStartPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdStartPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentStartedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdStartPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentStartedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdStartPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdStartPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentStartedResponse> {

    return this.experimentEventsExperimentIdStartPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentStartedResponse>) => r.body as ExperimentStartedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsExperimentIdSendForReviewPost
   */
  static readonly ExperimentEventsExperimentIdSendForReviewPostPath = '/experiment-events/{experimentId}/send-for-review';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdSendForReviewPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForReviewPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentSentForReviewResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdSendForReviewPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentSentForReviewResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdSendForReviewPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForReviewPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentSentForReviewResponse> {

    return this.experimentEventsExperimentIdSendForReviewPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentSentForReviewResponse>) => r.body as ExperimentSentForReviewResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdSendForReviewPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForReviewPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentSentForReviewResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdSendForReviewPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentSentForReviewResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdSendForReviewPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForReviewPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentSentForReviewResponse> {

    return this.experimentEventsExperimentIdSendForReviewPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentSentForReviewResponse>) => r.body as ExperimentSentForReviewResponse)
    );
  }

  /**
   * Path part for operation experimentEventsExperimentIdSetVariablePost
   */
  static readonly ExperimentEventsExperimentIdSetVariablePostPath = '/experiment-events/{experimentId}/set-variable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdSetVariablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsExperimentIdSetVariablePost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SetVariableCommand
  }): Observable<StrictHttpResponse<SetVariableResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdSetVariablePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetVariableResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdSetVariablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsExperimentIdSetVariablePost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SetVariableCommand
  }): Observable<SetVariableResponse> {

    return this.experimentEventsExperimentIdSetVariablePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SetVariableResponse>) => r.body as SetVariableResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdSetVariablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsExperimentIdSetVariablePost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SetVariableCommand
  }): Observable<StrictHttpResponse<SetVariableResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdSetVariablePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetVariableResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdSetVariablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsExperimentIdSetVariablePost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SetVariableCommand
  }): Observable<SetVariableResponse> {

    return this.experimentEventsExperimentIdSetVariablePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SetVariableResponse>) => r.body as SetVariableResponse)
    );
  }

  /**
   * Path part for operation experimentEventsExperimentIdSendForCorrectionPost
   */
  static readonly ExperimentEventsExperimentIdSendForCorrectionPostPath = '/experiment-events/{experimentId}/send-for-correction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdSendForCorrectionPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForCorrectionPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentSentForCorrectionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdSendForCorrectionPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentSentForCorrectionResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdSendForCorrectionPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForCorrectionPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentSentForCorrectionResponse> {

    return this.experimentEventsExperimentIdSendForCorrectionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentSentForCorrectionResponse>) => r.body as ExperimentSentForCorrectionResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdSendForCorrectionPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForCorrectionPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentSentForCorrectionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdSendForCorrectionPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentSentForCorrectionResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdSendForCorrectionPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForCorrectionPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentSentForCorrectionResponse> {

    return this.experimentEventsExperimentIdSendForCorrectionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentSentForCorrectionResponse>) => r.body as ExperimentSentForCorrectionResponse)
    );
  }

  /**
   * Path part for operation experimentEventsExperimentIdCancelPost
   */
  static readonly ExperimentEventsExperimentIdCancelPostPath = '/experiment-events/{experimentId}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdCancelPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdCancelPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentCancelledResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdCancelPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentCancelledResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdCancelPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdCancelPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentCancelledResponse> {

    return this.experimentEventsExperimentIdCancelPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentCancelledResponse>) => r.body as ExperimentCancelledResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdCancelPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdCancelPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentCancelledResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdCancelPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentCancelledResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdCancelPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdCancelPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentCancelledResponse> {

    return this.experimentEventsExperimentIdCancelPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentCancelledResponse>) => r.body as ExperimentCancelledResponse)
    );
  }

  /**
   * Path part for operation experimentEventsExperimentIdRestorePost
   */
  static readonly ExperimentEventsExperimentIdRestorePostPath = '/experiment-events/{experimentId}/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdRestorePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdRestorePost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdRestorePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdRestorePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdRestorePost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentRestoredResponse> {

    return this.experimentEventsExperimentIdRestorePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentRestoredResponse>) => r.body as ExperimentRestoredResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdRestorePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdRestorePost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdRestorePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdRestorePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdRestorePost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentRestoredResponse> {

    return this.experimentEventsExperimentIdRestorePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentRestoredResponse>) => r.body as ExperimentRestoredResponse)
    );
  }

  /**
   * Path part for operation experimentEventsExperimentIdAuthorizePost
   */
  static readonly ExperimentEventsExperimentIdAuthorizePostPath = '/experiment-events/{experimentId}/authorize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdAuthorizePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdAuthorizePost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentAuthorizedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdAuthorizePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentAuthorizedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdAuthorizePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdAuthorizePost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentAuthorizedResponse> {

    return this.experimentEventsExperimentIdAuthorizePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentAuthorizedResponse>) => r.body as ExperimentAuthorizedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdAuthorizePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdAuthorizePost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentAuthorizedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsExperimentIdAuthorizePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentAuthorizedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdAuthorizePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdAuthorizePost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentAuthorizedResponse> {

    return this.experimentEventsExperimentIdAuthorizePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentAuthorizedResponse>) => r.body as ExperimentAuthorizedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsChangeTitlePost
   */
  static readonly ExperimentEventsChangeTitlePostPath = '/experiment-events/change-title';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeTitlePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTitlePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentTitleCommand
  }): Observable<StrictHttpResponse<ExperimentTitleChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeTitlePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentTitleChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeTitlePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTitlePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentTitleCommand
  }): Observable<ExperimentTitleChangedResponse> {

    return this.experimentEventsChangeTitlePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentTitleChangedResponse>) => r.body as ExperimentTitleChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeTitlePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTitlePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentTitleCommand
  }): Observable<StrictHttpResponse<ExperimentTitleChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeTitlePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentTitleChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeTitlePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTitlePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentTitleCommand
  }): Observable<ExperimentTitleChangedResponse> {

    return this.experimentEventsChangeTitlePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentTitleChangedResponse>) => r.body as ExperimentTitleChangedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsChangeTagsPost
   */
  static readonly ExperimentEventsChangeTagsPostPath = '/experiment-events/change-tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeTagsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTagsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentTagsCommand
  }): Observable<StrictHttpResponse<ExperimentTagsChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeTagsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentTagsChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeTagsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTagsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentTagsCommand
  }): Observable<ExperimentTagsChangedResponse> {

    return this.experimentEventsChangeTagsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentTagsChangedResponse>) => r.body as ExperimentTagsChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeTagsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTagsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentTagsCommand
  }): Observable<StrictHttpResponse<ExperimentTagsChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeTagsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentTagsChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeTagsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTagsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentTagsCommand
  }): Observable<ExperimentTagsChangedResponse> {

    return this.experimentEventsChangeTagsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentTagsChangedResponse>) => r.body as ExperimentTagsChangedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsChangeSubBusinessUnitsPost
   */
  static readonly ExperimentEventsChangeSubBusinessUnitsPostPath = '/experiment-events/change-sub-business-units';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeSubBusinessUnitsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeSubBusinessUnitsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentSubBusinessUnitsCommand
  }): Observable<StrictHttpResponse<ExperimentSubBusinessUnitsChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeSubBusinessUnitsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentSubBusinessUnitsChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeSubBusinessUnitsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeSubBusinessUnitsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentSubBusinessUnitsCommand
  }): Observable<ExperimentSubBusinessUnitsChangedResponse> {

    return this.experimentEventsChangeSubBusinessUnitsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentSubBusinessUnitsChangedResponse>) => r.body as ExperimentSubBusinessUnitsChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeSubBusinessUnitsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeSubBusinessUnitsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentSubBusinessUnitsCommand
  }): Observable<StrictHttpResponse<ExperimentSubBusinessUnitsChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeSubBusinessUnitsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentSubBusinessUnitsChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeSubBusinessUnitsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeSubBusinessUnitsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentSubBusinessUnitsCommand
  }): Observable<ExperimentSubBusinessUnitsChangedResponse> {

    return this.experimentEventsChangeSubBusinessUnitsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentSubBusinessUnitsChangedResponse>) => r.body as ExperimentSubBusinessUnitsChangedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsChangeAssignedSupervisorsPost
   */
  static readonly ExperimentEventsChangeAssignedSupervisorsPostPath = '/experiment-events/change-assigned-supervisors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAssignedSupervisorsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedSupervisorsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAssignedSupervisorsCommand
  }): Observable<StrictHttpResponse<ExperimentAssignedSupervisorsChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeAssignedSupervisorsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentAssignedSupervisorsChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAssignedSupervisorsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedSupervisorsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAssignedSupervisorsCommand
  }): Observable<ExperimentAssignedSupervisorsChangedResponse> {

    return this.experimentEventsChangeAssignedSupervisorsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentAssignedSupervisorsChangedResponse>) => r.body as ExperimentAssignedSupervisorsChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAssignedSupervisorsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedSupervisorsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAssignedSupervisorsCommand
  }): Observable<StrictHttpResponse<ExperimentAssignedSupervisorsChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeAssignedSupervisorsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentAssignedSupervisorsChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAssignedSupervisorsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedSupervisorsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAssignedSupervisorsCommand
  }): Observable<ExperimentAssignedSupervisorsChangedResponse> {

    return this.experimentEventsChangeAssignedSupervisorsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentAssignedSupervisorsChangedResponse>) => r.body as ExperimentAssignedSupervisorsChangedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsChangeAssignedReviewersPost
   */
  static readonly ExperimentEventsChangeAssignedReviewersPostPath = '/experiment-events/change-assigned-reviewers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAssignedReviewersPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedReviewersPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAssignedReviewersCommand
  }): Observable<StrictHttpResponse<ExperimentAssignedReviewersChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeAssignedReviewersPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentAssignedReviewersChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAssignedReviewersPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedReviewersPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAssignedReviewersCommand
  }): Observable<ExperimentAssignedReviewersChangedResponse> {

    return this.experimentEventsChangeAssignedReviewersPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentAssignedReviewersChangedResponse>) => r.body as ExperimentAssignedReviewersChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAssignedReviewersPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedReviewersPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAssignedReviewersCommand
  }): Observable<StrictHttpResponse<ExperimentAssignedReviewersChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeAssignedReviewersPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentAssignedReviewersChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAssignedReviewersPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedReviewersPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAssignedReviewersCommand
  }): Observable<ExperimentAssignedReviewersChangedResponse> {

    return this.experimentEventsChangeAssignedReviewersPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentAssignedReviewersChangedResponse>) => r.body as ExperimentAssignedReviewersChangedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsChangeAssignedAnalystsPost
   */
  static readonly ExperimentEventsChangeAssignedAnalystsPostPath = '/experiment-events/change-assigned-analysts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAssignedAnalystsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedAnalystsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAssignedAnalystsCommand
  }): Observable<StrictHttpResponse<ExperimentAssignedAnalystsChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeAssignedAnalystsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentAssignedAnalystsChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAssignedAnalystsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedAnalystsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAssignedAnalystsCommand
  }): Observable<ExperimentAssignedAnalystsChangedResponse> {

    return this.experimentEventsChangeAssignedAnalystsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentAssignedAnalystsChangedResponse>) => r.body as ExperimentAssignedAnalystsChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAssignedAnalystsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedAnalystsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAssignedAnalystsCommand
  }): Observable<StrictHttpResponse<ExperimentAssignedAnalystsChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeAssignedAnalystsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentAssignedAnalystsChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAssignedAnalystsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedAnalystsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAssignedAnalystsCommand
  }): Observable<ExperimentAssignedAnalystsChangedResponse> {

    return this.experimentEventsChangeAssignedAnalystsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentAssignedAnalystsChangedResponse>) => r.body as ExperimentAssignedAnalystsChangedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsChangeScheduledStartDatePost
   */
  static readonly ExperimentEventsChangeScheduledStartDatePostPath = '/experiment-events/change-scheduled-start-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeScheduledStartDatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledStartDatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentScheduledStartDateCommand
  }): Observable<StrictHttpResponse<ExperimentScheduledStartDateChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeScheduledStartDatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentScheduledStartDateChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeScheduledStartDatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledStartDatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentScheduledStartDateCommand
  }): Observable<ExperimentScheduledStartDateChangedResponse> {

    return this.experimentEventsChangeScheduledStartDatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentScheduledStartDateChangedResponse>) => r.body as ExperimentScheduledStartDateChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeScheduledStartDatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledStartDatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentScheduledStartDateCommand
  }): Observable<StrictHttpResponse<ExperimentScheduledStartDateChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeScheduledStartDatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentScheduledStartDateChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeScheduledStartDatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledStartDatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentScheduledStartDateCommand
  }): Observable<ExperimentScheduledStartDateChangedResponse> {

    return this.experimentEventsChangeScheduledStartDatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentScheduledStartDateChangedResponse>) => r.body as ExperimentScheduledStartDateChangedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsChangeScheduledReviewStartDatePost
   */
  static readonly ExperimentEventsChangeScheduledReviewStartDatePostPath = '/experiment-events/change-scheduled-review-start-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeScheduledReviewStartDatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledReviewStartDatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentScheduledReviewStartDateCommand
  }): Observable<StrictHttpResponse<ExperimentScheduledReviewStartDateChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeScheduledReviewStartDatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentScheduledReviewStartDateChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeScheduledReviewStartDatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledReviewStartDatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentScheduledReviewStartDateCommand
  }): Observable<ExperimentScheduledReviewStartDateChangedResponse> {

    return this.experimentEventsChangeScheduledReviewStartDatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentScheduledReviewStartDateChangedResponse>) => r.body as ExperimentScheduledReviewStartDateChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeScheduledReviewStartDatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledReviewStartDatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentScheduledReviewStartDateCommand
  }): Observable<StrictHttpResponse<ExperimentScheduledReviewStartDateChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeScheduledReviewStartDatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentScheduledReviewStartDateChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeScheduledReviewStartDatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledReviewStartDatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentScheduledReviewStartDateCommand
  }): Observable<ExperimentScheduledReviewStartDateChangedResponse> {

    return this.experimentEventsChangeScheduledReviewStartDatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentScheduledReviewStartDateChangedResponse>) => r.body as ExperimentScheduledReviewStartDateChangedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsChangeAuthorizationDueDatePost
   */
  static readonly ExperimentEventsChangeAuthorizationDueDatePostPath = '/experiment-events/change-authorization-due-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAuthorizationDueDatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAuthorizationDueDatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAuthorizationDueDateCommand
  }): Observable<StrictHttpResponse<ExperimentAuthorizationDueDateChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeAuthorizationDueDatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentAuthorizationDueDateChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAuthorizationDueDatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAuthorizationDueDatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAuthorizationDueDateCommand
  }): Observable<ExperimentAuthorizationDueDateChangedResponse> {

    return this.experimentEventsChangeAuthorizationDueDatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentAuthorizationDueDateChangedResponse>) => r.body as ExperimentAuthorizationDueDateChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAuthorizationDueDatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAuthorizationDueDatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAuthorizationDueDateCommand
  }): Observable<StrictHttpResponse<ExperimentAuthorizationDueDateChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeAuthorizationDueDatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentAuthorizationDueDateChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAuthorizationDueDatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAuthorizationDueDatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentAuthorizationDueDateCommand
  }): Observable<ExperimentAuthorizationDueDateChangedResponse> {

    return this.experimentEventsChangeAuthorizationDueDatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentAuthorizationDueDateChangedResponse>) => r.body as ExperimentAuthorizationDueDateChangedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsAddTemplatePost
   */
  static readonly ExperimentEventsAddTemplatePostPath = '/experiment-events/add-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsAddTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsAddTemplatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentTemplateApplyCommand
  }): Observable<StrictHttpResponse<ExperimentTemplateAppliedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsAddTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentTemplateAppliedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsAddTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsAddTemplatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentTemplateApplyCommand
  }): Observable<ExperimentTemplateAppliedResponse> {

    return this.experimentEventsAddTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentTemplateAppliedResponse>) => r.body as ExperimentTemplateAppliedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsAddTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsAddTemplatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentTemplateApplyCommand
  }): Observable<StrictHttpResponse<ExperimentTemplateAppliedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsAddTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentTemplateAppliedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsAddTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsAddTemplatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentTemplateApplyCommand
  }): Observable<ExperimentTemplateAppliedResponse> {

    return this.experimentEventsAddTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentTemplateAppliedResponse>) => r.body as ExperimentTemplateAppliedResponse)
    );
  }

  /**
   * Path part for operation experimentEventsChangeNodeTitlePost
   */
  static readonly ExperimentEventsChangeNodeTitlePostPath = '/experiment-events/change-node-title';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeNodeTitlePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeTitlePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentNodeTitleCommand
  }): Observable<StrictHttpResponse<ExperimentNodeTitleChangedNotification>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeNodeTitlePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentNodeTitleChangedNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeNodeTitlePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeTitlePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentNodeTitleCommand
  }): Observable<ExperimentNodeTitleChangedNotification> {

    return this.experimentEventsChangeNodeTitlePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentNodeTitleChangedNotification>) => r.body as ExperimentNodeTitleChangedNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeNodeTitlePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeTitlePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentNodeTitleCommand
  }): Observable<StrictHttpResponse<ExperimentNodeTitleChangedNotification>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeNodeTitlePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentNodeTitleChangedNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeNodeTitlePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeTitlePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentNodeTitleCommand
  }): Observable<ExperimentNodeTitleChangedNotification> {

    return this.experimentEventsChangeNodeTitlePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentNodeTitleChangedNotification>) => r.body as ExperimentNodeTitleChangedNotification)
    );
  }

  /**
   * Path part for operation experimentEventsChangeNodeOrderPost
   */
  static readonly ExperimentEventsChangeNodeOrderPostPath = '/experiment-events/change-node-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeNodeOrderPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeOrderPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentNodeOrderCommand
  }): Observable<StrictHttpResponse<ExperimentNodeOrderChangedNotification>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeNodeOrderPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentNodeOrderChangedNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeNodeOrderPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeOrderPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentNodeOrderCommand
  }): Observable<ExperimentNodeOrderChangedNotification> {

    return this.experimentEventsChangeNodeOrderPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentNodeOrderChangedNotification>) => r.body as ExperimentNodeOrderChangedNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeNodeOrderPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeOrderPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentNodeOrderCommand
  }): Observable<StrictHttpResponse<ExperimentNodeOrderChangedNotification>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentEventsService.ExperimentEventsChangeNodeOrderPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentNodeOrderChangedNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeNodeOrderPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeOrderPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeExperimentNodeOrderCommand
  }): Observable<ExperimentNodeOrderChangedNotification> {

    return this.experimentEventsChangeNodeOrderPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentNodeOrderChangedNotification>) => r.body as ExperimentNodeOrderChangedNotification)
    );
  }

}
