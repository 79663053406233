import { Component, OnInit, ViewChild } from '@angular/core';
import { FilesTabGridOptions } from './files-tab-grid-options';
import { BptGridComponent, BptGridMode, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { FilesTabHelper } from './shared/files-tab-helper';
import { ElnProgressSpinnerService } from '../../eln-progress-spinner-module/eln-progress-spinner.service';
import { NavigationEnd, Router } from '@angular/router';
import { FileUploadedResponse } from '../../api/file-integration/models/ELN/FileIntegration/Api/File/file-uploaded-response';
import { ExperimentService } from '../services/experiment.service';
import { FileDetails } from '../../api/models/file-details';
import { FileDetails as ActivityFileDetails } from '../../experiment/model/file-details';
import { Subscription, filter } from 'rxjs';
import { UserService } from '../../api/services';
import { ActivityFileNode, NodeType, User } from '../../api/models';
import { AttachedFileEventNotification, DeleteFilesCommand } from '../../api/data-entry/models';
import { FilesDeletedResponse } from '../../api/file-integration/models/ELN/FileIntegration/Api/File/files-deleted-response';
import { MessageService } from 'primeng/api';
import { DataRecordService } from '../services/data-record.service';
import { saveAs } from 'file-saver';
import { MimeTypeService } from './shared/mime-type-service';
import { FileIntegrationService } from '../../api/file-integration/services/file-integration.service';
import { StrictHttpResponse } from '../../api/strict-http-response';
import { DownloadFileCommand } from '../../api/file-integration/models/ELN/FileIntegration/Api/Command/download-file-command';

@Component({
  selector: 'app-files-tab',
  templateUrl: './files-tab.component.html',
  styleUrls: ['./files-tab.component.scss']
})
export class FilesTabComponent implements OnInit {
  gridId = 'fileGrid';
  fileData: ActivityFileDetails[] = [];
  columnDefinitions: ColumnDefinition[] = [];
  gridMode = BptGridMode.dataView;
  subscriptionList: Subscription[] = [];

  private readonly fileTypesToOpenInNewTab = ['jpg', 'png', 'jpeg', 'pdf', 'gif', 'bmp', 'svg+xml'];
  users: User[] = [];
  @ViewChild('filesGrid') filesGrid!:BptGridComponent; 

  constructor(private readonly filesTabHelper: FilesTabHelper,
    private readonly fileIntegrationService: FileIntegrationService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly experimentService : ExperimentService,
    private readonly router: Router,
    private readonly currentUserService: UserService,
    private readonly messageService: MessageService,
    private readonly dataRecordService: DataRecordService,
    private readonly mimeTypeService: MimeTypeService) {
    this.handleSubscriptions();
  }

  ngOnInit(): void {
    this.columnDefinitions = FilesTabGridOptions.prepareColumns(this.downloadAttachments);  
    this.loadUsers();
    this.watchActivitySelectionChanges();
  }

  private setDataSource()
  {
    this.fileData = [];
    const files = this.experimentService.currentExperiment?.activityFiles.filter(k => k.activityId === this.experimentService.currentActivityId)[0];
    files?.files!.forEach((fileDetails: FileDetails) => {
      this.fileData.push(this.getFileData(fileDetails));      
    });
    this.refreshGridWithUpdatedData();
  }

  downloadAttachments = (event: any): void => {
    this.downloadFiles(event.data);
  };

  private handleSubscriptions() {
    this.subscriptionList.push(
    this.filesTabHelper.fileDeleteActionClicked.subscribe((fileId: string) => {
      const deleteCommand: DeleteFilesCommand = {
        experimentId: this.experimentService.currentExperiment!.id,
        activityId: this.experimentService.currentActivityId,
        fileIds: [fileId]
      }
      this.elnProgressSpinnerService.Show({
        message: $localize`:@@fileDeleting:Deleting File...`,
        i18nMessage: '@@fileDeleting'
      })
      this.fileIntegrationService.filesDeletePost$Json({body: deleteCommand}).subscribe((result) => {
        const deletedFileIds = result.fileIds;
        this.fileData = this.fileData.filter(file => !deletedFileIds.includes(file.fileId));
        this.deleteActivityFilesNode(result);
        this.refreshGridWithUpdatedData();
        this.elnProgressSpinnerService.Hide();
        this.messageService.add({
          key: 'notification',
          severity: 'success',
          summary: $localize`:@@fileDeleteSuccess:File deleted successfully`
        });
      });
    }));

    this.subscriptionList.push(
    this.filesTabHelper.fileDownloadActionClicked.subscribe((fileId: string) => {
      const file = this.fileData.find(x => x.fileId === fileId);
      if(!file) return;
      this.downloadFiles(file, true);
    }));

    this.subscriptionList.push(
    this.experimentService.activityFileUploaded.subscribe((fileUploadedResponse: FileUploadedResponse) => {      
      this.setDataSourcePostUpload(fileUploadedResponse);
    }));

    this.subscriptionList.push(
      this.dataRecordService.attachedFileEventNotification.subscribe((fileAttachedNotification: AttachedFileEventNotification) => {
        const fileUploadResponse : FileUploadedResponse = {
          activityId: fileAttachedNotification.activityId,
          experimentId: fileAttachedNotification.eventContext.experimentId,
          fileId: fileAttachedNotification.fileId,
          size: fileAttachedNotification.size,
          title: fileAttachedNotification.title,
          type: fileAttachedNotification.type,
          uploadedBy: fileAttachedNotification.uploadedBy,
          uploadedOn: fileAttachedNotification.uploadedOn,
          notifications: fileAttachedNotification.notifications
        };
        this.setDataSourcePostUpload(fileUploadResponse);
      })
    );
  }

  setDataSourcePostUpload(fileUploadedResponse: FileUploadedResponse) {
    const activityFiles = this.experimentService.currentExperiment?.activityFiles.filter(f => f.activityId === fileUploadedResponse.activityId);       
    let files: ActivityFileNode;
    let fileExists = false;
    if (activityFiles !== undefined && activityFiles.length > 0) {
        files =  activityFiles[0];
        fileExists = files?.files.find(x => x.fileId === fileUploadedResponse.fileId) !==undefined;
        if(!fileExists)       
          files?.files.push(this.getFileDataForExperiment(fileUploadedResponse));  
    } else {
        files = {
            activityId: fileUploadedResponse.activityId,
            experimentId: this.experimentService.currentExperiment?.id!,
            files: [],
            itemType: NodeType.ActivityFiles,
            _ts: 0,
            id: this.experimentService.currentExperiment?.id!
         };
        files?.files.push(this.getFileDataForExperiment(fileUploadedResponse));  
        this.experimentService.currentExperiment?.activityFiles?.push(files);         
    }
    if(!fileExists){
      this.setDataSource();
    }
  }

  private deleteActivityFilesNode(result: FilesDeletedResponse) {
    const activityFiles = this.experimentService.currentExperiment?.activityFiles.find(activityFile => activityFile.activityId === result.activityId);
    if (activityFiles) {
      activityFiles.files = activityFiles.files.filter(file => !result.fileIds.includes(file.fileId));
    }
  }

  downloadFiles(file: ActivityFileDetails, ignoreTypeAndDownload = false): void {
    const command: DownloadFileCommand = { fileId: file.fileId };
    this.fileIntegrationService
      .filesDownloadPost$Response({body: command})
      .subscribe({
        next: (response: StrictHttpResponse<Blob>) => {
          const fileExtention = file.fileName.split('.').pop();
          if (!fileExtention) return;

          const mimeType = this.mimeTypeService.getMimeType(fileExtention);
          const blob = new Blob([response.body as any], { type: mimeType })
          if (ignoreTypeAndDownload || !this.fileTypesToOpenInNewTab.includes(fileExtention)) {
            saveAs(blob, file.fileName);
            return;
          }
          const url: string = URL.createObjectURL(blob);
          window.open(url);
        }
      }
    );
  };
  
  private watchActivitySelectionChanges() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((route: { url: string }) => {
        if (route.url.toLowerCase().includes('/outputs')) {
          this.setDataSource();
        }
      });
  }

  private refreshGridWithUpdatedData() {
    this.filesGrid?.gridApi?.setRowData(this.fileData);
    this.filesGrid?.gridApi?.refreshCells({force: true});
  }

  private loadUsers(): void {
    this.currentUserService
      .usersActiveUsersPost$Json({
        body: ['ELN Reviewer', 'ELN Supervisor', 'ELN Analyst']
      })
      .subscribe({
        next: (data: User[]) => { 
          this.users = data;         
          this.setDataSource();
          this.watchActivitySelectionChanges();
        }
      });
  }

  ngOnDestroy() {
    this.subscriptionList.forEach((s) => s.unsubscribe());
  }

  private getFileDataForExperiment(fileUploadedResponse: FileUploadedResponse) {    
    return { 
      fileId: fileUploadedResponse.fileId, 
      title: fileUploadedResponse.title, 
      uploadedBy: fileUploadedResponse.uploadedBy, 
      size: fileUploadedResponse.size, 
      uploadedOn: fileUploadedResponse.uploadedOn, 
      type: fileUploadedResponse.type 
    }
  }

  private getFileData(data: FileDetails) {
    const uploadedUser = this.users.filter(user => user.puid === data.uploadedBy.value);
    return { 
      fileId: data.fileId, 
      fileName: data.title, 
      uploadedBy: uploadedUser[0].fullName, 
      fileSize: data.size,
      uploadedOn: data.uploadedOn
     }
  }
}
