/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserPreference } from '../models/user-preference';
import { UserPreferenceListResponse } from '../models/user-preference-list-response';
import { UserPreferenceResponse } from '../models/user-preference-response';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userPreferencesUserPreferenceUserPreferenceKeyGet
   */
  static readonly UserPreferencesUserPreferenceUserPreferenceKeyGetPath = '/user-preferences/user-preference/{userPreferenceKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceUserPreferenceKeyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceUserPreferenceKeyGet$Plain$Response(params: {
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<UserPreferenceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesUserPreferenceUserPreferenceKeyGetPath, 'get');
    if (params) {
      rb.path('userPreferenceKey', params.userPreferenceKey, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceUserPreferenceKeyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceUserPreferenceKeyGet$Plain(params: {
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<UserPreferenceResponse> {

    return this.userPreferencesUserPreferenceUserPreferenceKeyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>) => r.body as UserPreferenceResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceUserPreferenceKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceUserPreferenceKeyGet$Json$Response(params: {
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<UserPreferenceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesUserPreferenceUserPreferenceKeyGetPath, 'get');
    if (params) {
      rb.path('userPreferenceKey', params.userPreferenceKey, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceUserPreferenceKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceUserPreferenceKeyGet$Json(params: {
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<UserPreferenceResponse> {

    return this.userPreferencesUserPreferenceUserPreferenceKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>) => r.body as UserPreferenceResponse)
    );
  }

  /**
   * Path part for operation userPreferencesUserPreferenceKeyGet
   */
  static readonly UserPreferencesUserPreferenceKeyGetPath = '/user-preferences/{userPreferenceKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceKeyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyGet$Plain$Response(params: {
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<UserPreferenceListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesUserPreferenceKeyGetPath, 'get');
    if (params) {
      rb.path('userPreferenceKey', params.userPreferenceKey, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceListResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceKeyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyGet$Plain(params: {
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<UserPreferenceListResponse> {

    return this.userPreferencesUserPreferenceKeyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceListResponse>) => r.body as UserPreferenceListResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyGet$Json$Response(params: {
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<UserPreferenceListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesUserPreferenceKeyGetPath, 'get');
    if (params) {
      rb.path('userPreferenceKey', params.userPreferenceKey, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceListResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyGet$Json(params: {
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<UserPreferenceListResponse> {

    return this.userPreferencesUserPreferenceKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceListResponse>) => r.body as UserPreferenceListResponse)
    );
  }

  /**
   * Path part for operation userPreferencesUserBulkPreferencePost
   */
  static readonly UserPreferencesUserBulkPreferencePostPath = '/user-preferences/user-bulk-preference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserBulkPreferencePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUserBulkPreferencePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: Array<string>
  }): Observable<StrictHttpResponse<UserPreferenceListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesUserBulkPreferencePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceListResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserBulkPreferencePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUserBulkPreferencePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: Array<string>
  }): Observable<UserPreferenceListResponse> {

    return this.userPreferencesUserBulkPreferencePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceListResponse>) => r.body as UserPreferenceListResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserBulkPreferencePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUserBulkPreferencePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: Array<string>
  }): Observable<StrictHttpResponse<UserPreferenceListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesUserBulkPreferencePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceListResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserBulkPreferencePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUserBulkPreferencePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: Array<string>
  }): Observable<UserPreferenceListResponse> {

    return this.userPreferencesUserBulkPreferencePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceListResponse>) => r.body as UserPreferenceListResponse)
    );
  }

  /**
   * Path part for operation userPreferencesSaveUserPreferencePost
   */
  static readonly UserPreferencesSaveUserPreferencePostPath = '/user-preferences/save-user-preference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesSaveUserPreferencePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesSaveUserPreferencePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UserPreference
  }): Observable<StrictHttpResponse<UserPreferenceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesSaveUserPreferencePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesSaveUserPreferencePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesSaveUserPreferencePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UserPreference
  }): Observable<UserPreferenceResponse> {

    return this.userPreferencesSaveUserPreferencePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>) => r.body as UserPreferenceResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesSaveUserPreferencePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesSaveUserPreferencePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UserPreference
  }): Observable<StrictHttpResponse<UserPreferenceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesSaveUserPreferencePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesSaveUserPreferencePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesSaveUserPreferencePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UserPreference
  }): Observable<UserPreferenceResponse> {

    return this.userPreferencesSaveUserPreferencePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>) => r.body as UserPreferenceResponse)
    );
  }

  /**
   * Path part for operation userPreferencesUpdateUserPreferencePut
   */
  static readonly UserPreferencesUpdateUserPreferencePutPath = '/user-preferences/update-user-preference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUpdateUserPreferencePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUpdateUserPreferencePut$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UserPreference
  }): Observable<StrictHttpResponse<UserPreferenceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesUpdateUserPreferencePutPath, 'put');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUpdateUserPreferencePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUpdateUserPreferencePut$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UserPreference
  }): Observable<UserPreferenceResponse> {

    return this.userPreferencesUpdateUserPreferencePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>) => r.body as UserPreferenceResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUpdateUserPreferencePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUpdateUserPreferencePut$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UserPreference
  }): Observable<StrictHttpResponse<UserPreferenceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesUpdateUserPreferencePutPath, 'put');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUpdateUserPreferencePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUpdateUserPreferencePut$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UserPreference
  }): Observable<UserPreferenceResponse> {

    return this.userPreferencesUpdateUserPreferencePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>) => r.body as UserPreferenceResponse)
    );
  }

  /**
   * Path part for operation userPreferencesUserPreferenceKeyUserPreferenceNamePost
   */
  static readonly UserPreferencesUserPreferenceKeyUserPreferenceNamePostPath = '/user-preferences/{userPreferenceKey}/{userPreferenceName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain$Response(params: {
    userPreferenceId?: string;
    userPreferenceName: string;
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<UserPreferenceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesUserPreferenceKeyUserPreferenceNamePostPath, 'post');
    if (params) {
      rb.query('userPreferenceId', params.userPreferenceId, {});
      rb.path('userPreferenceName', params.userPreferenceName, {});
      rb.path('userPreferenceKey', params.userPreferenceKey, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain(params: {
    userPreferenceId?: string;
    userPreferenceName: string;
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<UserPreferenceResponse> {

    return this.userPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>) => r.body as UserPreferenceResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json$Response(params: {
    userPreferenceId?: string;
    userPreferenceName: string;
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<UserPreferenceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesUserPreferenceKeyUserPreferenceNamePostPath, 'post');
    if (params) {
      rb.query('userPreferenceId', params.userPreferenceId, {});
      rb.path('userPreferenceName', params.userPreferenceName, {});
      rb.path('userPreferenceKey', params.userPreferenceKey, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json(params: {
    userPreferenceId?: string;
    userPreferenceName: string;
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<UserPreferenceResponse> {

    return this.userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>) => r.body as UserPreferenceResponse)
    );
  }

  /**
   * Path part for operation userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost
   */
  static readonly UserPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPostPath = '/user-preferences/set-default/{userPreferenceId}/{userPreferenceKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain$Response(params: {
    userPreferenceId: string;
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<UserPreferenceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPostPath, 'post');
    if (params) {
      rb.path('userPreferenceId', params.userPreferenceId, {});
      rb.path('userPreferenceKey', params.userPreferenceKey, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain(params: {
    userPreferenceId: string;
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<UserPreferenceResponse> {

    return this.userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>) => r.body as UserPreferenceResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json$Response(params: {
    userPreferenceId: string;
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<UserPreferenceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPostPath, 'post');
    if (params) {
      rb.path('userPreferenceId', params.userPreferenceId, {});
      rb.path('userPreferenceKey', params.userPreferenceKey, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json(params: {
    userPreferenceId: string;
    userPreferenceKey: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<UserPreferenceResponse> {

    return this.userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>) => r.body as UserPreferenceResponse)
    );
  }

  /**
   * Path part for operation userPreferencesUserPreferenceIdDelete
   */
  static readonly UserPreferencesUserPreferenceIdDeletePath = '/user-preferences/{userPreferenceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceIdDelete$Plain$Response(params: {
    userPreferenceId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<UserPreferenceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesUserPreferenceIdDeletePath, 'delete');
    if (params) {
      rb.path('userPreferenceId', params.userPreferenceId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceIdDelete$Plain(params: {
    userPreferenceId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<UserPreferenceResponse> {

    return this.userPreferencesUserPreferenceIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>) => r.body as UserPreferenceResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceIdDelete$Json$Response(params: {
    userPreferenceId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<UserPreferenceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPreferenceService.UserPreferencesUserPreferenceIdDeletePath, 'delete');
    if (params) {
      rb.path('userPreferenceId', params.userPreferenceId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceIdDelete$Json(params: {
    userPreferenceId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<UserPreferenceResponse> {

    return this.userPreferencesUserPreferenceIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>) => r.body as UserPreferenceResponse)
    );
  }

}
