/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PreparationInternalInformationChangedResponse } from '../models/preparation-internal-information-changed-response';
import { PreparationRemovedResponse } from '../models/preparation-removed-response';
import { RecipeAddPreparationCommand } from '../models/recipe-add-preparation-command';
import { RecipeNewPreparations } from '../models/recipe-new-preparations';
import { RecipePreparationCellChangeCommand } from '../models/recipe-preparation-cell-change-command';
import { RecipePreparationCellChangedResponse } from '../models/recipe-preparation-cell-changed-response';
import { RecipePreparationChangeInternalInformationCommand } from '../models/recipe-preparation-change-internal-information-command';
import { RecipeRemovePreparationCommand } from '../models/recipe-remove-preparation-command';

@Injectable({
  providedIn: 'root',
})
export class PreparationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation preparationsCreatePreparationPost
   */
  static readonly PreparationsCreatePreparationPostPath = '/preparations/create-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsCreatePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCreatePreparationPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddPreparationCommand
  }): Observable<StrictHttpResponse<RecipeNewPreparations>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsService.PreparationsCreatePreparationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeNewPreparations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsCreatePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCreatePreparationPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddPreparationCommand
  }): Observable<RecipeNewPreparations> {

    return this.preparationsCreatePreparationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeNewPreparations>) => r.body as RecipeNewPreparations)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsCreatePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCreatePreparationPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddPreparationCommand
  }): Observable<StrictHttpResponse<RecipeNewPreparations>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsService.PreparationsCreatePreparationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeNewPreparations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsCreatePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCreatePreparationPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddPreparationCommand
  }): Observable<RecipeNewPreparations> {

    return this.preparationsCreatePreparationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeNewPreparations>) => r.body as RecipeNewPreparations)
    );
  }

  /**
   * Path part for operation preparationsCellChangePost
   */
  static readonly PreparationsCellChangePostPath = '/preparations/cell-change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsCellChangePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCellChangePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipePreparationCellChangeCommand
  }): Observable<StrictHttpResponse<RecipePreparationCellChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsService.PreparationsCellChangePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipePreparationCellChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsCellChangePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCellChangePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipePreparationCellChangeCommand
  }): Observable<RecipePreparationCellChangedResponse> {

    return this.preparationsCellChangePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipePreparationCellChangedResponse>) => r.body as RecipePreparationCellChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsCellChangePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCellChangePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipePreparationCellChangeCommand
  }): Observable<StrictHttpResponse<RecipePreparationCellChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsService.PreparationsCellChangePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipePreparationCellChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsCellChangePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCellChangePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipePreparationCellChangeCommand
  }): Observable<RecipePreparationCellChangedResponse> {

    return this.preparationsCellChangePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipePreparationCellChangedResponse>) => r.body as RecipePreparationCellChangedResponse)
    );
  }

  /**
   * Path part for operation preparationsRemovePreparationPost
   */
  static readonly PreparationsRemovePreparationPostPath = '/preparations/remove-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsRemovePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsRemovePreparationPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeRemovePreparationCommand
  }): Observable<StrictHttpResponse<PreparationRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsService.PreparationsRemovePreparationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsRemovePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsRemovePreparationPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeRemovePreparationCommand
  }): Observable<PreparationRemovedResponse> {

    return this.preparationsRemovePreparationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationRemovedResponse>) => r.body as PreparationRemovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsRemovePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsRemovePreparationPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeRemovePreparationCommand
  }): Observable<StrictHttpResponse<PreparationRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsService.PreparationsRemovePreparationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsRemovePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsRemovePreparationPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeRemovePreparationCommand
  }): Observable<PreparationRemovedResponse> {

    return this.preparationsRemovePreparationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationRemovedResponse>) => r.body as PreparationRemovedResponse)
    );
  }

  /**
   * Path part for operation preparationsChangeInternalInformationPost
   */
  static readonly PreparationsChangeInternalInformationPostPath = '/preparations/change-internal-information';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsChangeInternalInformationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsChangeInternalInformationPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipePreparationChangeInternalInformationCommand
  }): Observable<StrictHttpResponse<PreparationInternalInformationChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsService.PreparationsChangeInternalInformationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationInternalInformationChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsChangeInternalInformationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsChangeInternalInformationPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipePreparationChangeInternalInformationCommand
  }): Observable<PreparationInternalInformationChangedResponse> {

    return this.preparationsChangeInternalInformationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationInternalInformationChangedResponse>) => r.body as PreparationInternalInformationChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsChangeInternalInformationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsChangeInternalInformationPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipePreparationChangeInternalInformationCommand
  }): Observable<StrictHttpResponse<PreparationInternalInformationChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PreparationsService.PreparationsChangeInternalInformationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreparationInternalInformationChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preparationsChangeInternalInformationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsChangeInternalInformationPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipePreparationChangeInternalInformationCommand
  }): Observable<PreparationInternalInformationChangedResponse> {

    return this.preparationsChangeInternalInformationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PreparationInternalInformationChangedResponse>) => r.body as PreparationInternalInformationChangedResponse)
    );
  }

}
