import { ColumnDefinition, ISeverityIndicatorConfig } from 'bpt-ui-library/bpt-grid';
import { Observable } from 'rxjs';
import { ActivityInputType, ColumnType } from '../../../../api/models';
import {
  LabItemsFeatureManager,
  LabItemWisePermissions
} from '../../shared/lab-items-feature-manager';
import { BptElnGridColumnLinkoutComponent } from '../../shared/bpt-eln-grid-column-linkout/bpt-eln-grid-column-linkout.component';
import { ICellRendererParams } from 'ag-grid-community';
import { BptControlSeverityIndicator, NA } from 'bpt-ui-library/shared';
import { LabItemsService } from '../../lab-items.service';
import { LocalDate } from '@js-joda/core';

export class LabItemsInstrumentTableOptions {
  public static readonly ColumnDefinition: { [fieldName: string]: { displayName: string }} = {
    code: { displayName: 'Instrument ID' },
    name: { displayName: 'Name' },
    groups: { displayName: 'Groups' },
    manufacturerName: { displayName: 'Manufacturer' },
    model: { displayName: 'Model' },
    useStatus: { displayName: 'Status' },
    nextMaintenanceDate: { displayName: 'Next Maintenance' },
    nextQualificationDate: { displayName: 'Next Qualification' },
    restrictedUse: { displayName: 'Restricted use' },
    AdditionalInformation: { displayName: 'Additional Information' }
  };

  private static readonly cellStyleClass = 'grid-cell-align';

  public static GetColumnDefinitions(
    latestLabItemsPermissions?: Observable<LabItemWisePermissions>,
    severityIndicatorConfig?: ()=>ISeverityIndicatorConfig
  ): ColumnDefinition[] {
    const itemType = ActivityInputType.InstrumentDetails;
    return [
      {
        label: $localize`:@@preparationRowId:Row ID`,
        field: 'code',
        columnType: ColumnType.RowId,
        hidden: true,
        editable: false,
        showInColumnChooser: false
      },
      {
        label: $localize`:@@Index:Index`,
        field: 'rowIndex',
        columnType: ColumnType.Index,
        hidden: true,
        showInColumnChooser: false,
        editable: false
      },
      {
        label: $localize`:@@instrumentId:Instrument ID`,
        field: 'code',
        width: 'auto',
        minWidth: 300,
        disableHiding: true,
        lockVisible: true,
        showHeader: false,
        sortable: true,
        editable: false,
        cellClass: 'bpt-eln-grid-row-actions',
        cellRenderer: BptElnGridColumnLinkoutComponent,
        cellRendererParams: {
          disableLinkOut: true,
          suppressCount: true,
        },
        showInColumnChooser: false
      },
      {
        label: $localize`:@@name:Name`,
        field: 'name',
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        cellRendererParams: {
          suppressCount: true
        },
        editable: false,
        width: 'auto',
        columnType: ColumnType.String,
        showInColumnChooser: false,
        sortable: true,
        allowAutoResize: true
      },
      {
        field: 'groups',
        label: $localize`:@@InstrumentGridGroupHeader:Groups`,
        width: 'auto',
        editable: false,
        allowAutoResize: true,
        sortable: true,
        hidden: true,
        columnType: ColumnType.String,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: true
      },
      {
        field: 'manufacturerName',
        label: $localize`:@@manufacturer:Manufacturer`,
        width: 'auto',
        editable: false,
        allowAutoResize: true,
        sortable: true,
        columnType: ColumnType.String,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: true
      },
      {
        field: 'model',
        label: $localize`:@@model:Model`,
        width: 'auto',
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true
      },
      {
        field: 'useStatus',
        label: $localize`:@@status:Status`,
        width: 'auto',
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true,
        severityIndicatorConfig: () => {
          return {
            getIndicator: (params: ICellRendererParams) => { 
              return this.severityIndicator(params);
            }
          };
        }
      },
      {
        field: 'nextMaintenanceDate',
        label: $localize`:@@InstrumentNextMaintenanceGridHeader:Next Maintenance`,
        width: 'auto',
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true,
        severityIndicatorConfig: () => {
          return {
            getIndicator: (params: ICellRendererParams) => {
              let response!: BptControlSeverityIndicator;
              if (params.value !== NA && params.value !== null) {
                return this.severityIndicator(params);
              } else {
                return response;
              }
            }
          };
        }
      },
      {
        field: 'nextQualificationDate',
        label: $localize`:@@InstrumentNextQualificationGridHeader:Next Qualification`,
        width: 'auto',
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true,
        severityIndicatorConfig: () => {
          return {
            getIndicator: (params: ICellRendererParams) => {
              let response!: BptControlSeverityIndicator;
              if (params.value !== NA && params.value !== null) {
                return this.severityIndicator(params);
              } else {
                return response;
              }
            }
          };
        }
      },
      {
        field: 'restrictedUse',
        label: $localize`:@@InstrumentRestrictedUseGridHeader:Restricted use`,
        width: 'auto',
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true
      },
      {
        field: 'AdditionalInformation',
        label: $localize`:@@additionalInfo:Additional Information`,
        width: 'auto',
        sortable: true,
        allowMultiLine: true,
        editable: function (params) {
          let permissions: LabItemWisePermissions = {};
          latestLabItemsPermissions?.subscribe({
            next: (latestPermissions) => {
              permissions = latestPermissions;
            }
          });
          return (
            params.data.code &&
            permissions[itemType][
              LabItemsFeatureManager.FeatureNamesByItemType[itemType].cellChangeFeatureName
            ]
          );
        },
        columnType: 'string',
        hidden: true,
        allowAutoResize: true,
        showInColumnChooser: true,
        severityIndicatorConfig: severityIndicatorConfig
      }
    ];
  }

  static severityIndicator(params: ICellRendererParams): BptControlSeverityIndicator {
    let response!: BptControlSeverityIndicator;
    const isInstrumentExpired: boolean = (params.data.nextMaintenanceDate !== NA &&
      params.data.nextMaintenanceDate !== null &&
      params.data.nextMaintenanceDate < LocalDate.now().toString()) || (params.data.nextQualificationDate !== NA &&
        params.data.nextQualificationDate !== null &&
        params.data.nextQualificationDate < LocalDate.now().toString());

    if (params.data.useStatus === 'Inactive' && isInstrumentExpired) {
      return LabItemsService.getExclamationIndicator($localize`:@@InstrumentIsExpiredAndInactive:The scanned instrument is Expired and status is Inactive.`);
    } else if (params.data.useStatus === 'Inactive' || params.data.useStatus === 'Retired') {
      return LabItemsService.getExclamationIndicator($localize`:@@InvalidStatus:Invalid Status`);
    } else if (isInstrumentExpired) {
      return LabItemsService.getExclamationIndicator($localize`:@@InstrumentExpired:The scanned instrument is Expired.`);
    } else {
      return response;
    }
  }
}
