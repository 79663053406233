import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html-pipe/safe-html-pipe.directive';
import { SpecificationInputComponent } from './specification-input/specification-input.component';
import { BptSliderModule } from 'bpt-ui-library/bpt-slider';
import { BptDropdownModule } from 'bpt-ui-library/bpt-dropdown';
import { BptQuantityModule } from 'bpt-ui-library/bpt-quantity';
import { BptTextInputModule } from 'bpt-ui-library/bpt-text-input';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SpecificationService } from './specification-input/specification.service';
import { CrossReferenceSliderComponent } from './cross-reference-slider/cross-reference-slider.component';
import { BptSearchModule } from 'bpt-ui-library/bpt-search';
import {
  BptProgressSpinnerComponent,
  BptProgressSpinnerModule
} from 'bpt-ui-library/bpt-progress-spinner';
import { CompletionTrackingComponent } from './completion-tracking/completion-tracking.component';
import { ProgressBarModule } from 'primeng/progressbar';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    SpecificationInputComponent,
    CrossReferenceSliderComponent,
    CompletionTrackingComponent
  ],
  imports: [
    BptDropdownModule,
    BptSliderModule,
    BptQuantityModule,
    BptTextInputModule,
    CheckboxModule,
    RadioButtonModule,
    CommonModule,
    FormsModule,
    BptSearchModule,
    BptProgressSpinnerModule,
    ReactiveFormsModule,
    ProgressBarModule
  ],
  exports: [
    SafeHtmlPipe,
    SpecificationInputComponent,
    CrossReferenceSliderComponent,
    BptProgressSpinnerComponent,
    CompletionTrackingComponent
  ],
  providers: [SpecificationService]
})
export class SharedModule {}
