import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Activity, Table, Form, Module, ModuleItem } from 'model/experiment.interface';
import { Message, MessageService } from 'primeng/api';
import { Observable, of, Subject } from 'rxjs';
import {
  NodeType,
  ExperimentWorkflowState,
  ChangeExperimentNodeOrderCommand,
  ExperimentNodeOrderChangedNotification
} from '../../api/data-entry/models';
import { ExperimentEventsService } from '../../api/data-entry/services';
import { DataRecordService } from './data-record.service';
import { ExperimentService } from './experiment.service';
import { ClientStateService } from 'services/client-state.service';

@Injectable()
export class ExperimentNodeReOrderService {
  private static _hasPermissionToReOrderNode = true;
  public get HasPermissionToReOrderNode(): boolean {
    return ExperimentNodeReOrderService._hasPermissionToReOrderNode;
  }
  public static readonly NotAllowedWorkflowStates: ExperimentWorkflowState[] = [
    ExperimentWorkflowState.Authorized,
    ExperimentWorkflowState.Cancelled
  ];
  public readonly nodeOrderChanged = new Subject<ExperimentNodeOrderChangedNotification>();
  constructor(
    private readonly experimentEventsApiService: ExperimentEventsService,
    public readonly experimentService: ExperimentService,
    private readonly messageService: MessageService,
    private readonly datarecordService: DataRecordService,
    private readonly location: Location,
    private readonly clientStateService: ClientStateService
  ) {
    this.watchNodeOrderChanges();
  }

  private watchNodeOrderChanges(): void {
    this.datarecordService.experimentNodeOrderChangedNotificationReceiver.subscribe({
      next: this.nodeOrderChangedByOtherUsers.bind(this)
    });
  }

  private readonly nodeOrderSyncHandler: {
    [nodeType: string]: (notification: ExperimentNodeOrderChangedNotification) => void;
  } = {
    activity: this.updateChangedActivityOrder.bind(this),
    module: this.moduleOrderChangedByOtherUser.bind(this),
    table: this.moduleItemOrderChangedByOtherUser.bind(this),
    form: this.moduleItemOrderChangedByOtherUser.bind(this)
  };

  private readonly moduleItemOrderNotificationHandler: {
    [nodeType: string]: (
      module: Module,
      notification: ExperimentNodeOrderChangedNotification,
      moduleItem: ModuleItem
    ) => void;
  } = {
    table: this.tableOrderChangedByOtherUser.bind(this),
    form: this.formOrderChangedByOtherUser.bind(this)
  };

  private updateChangedActivityOrder(
    notification: ExperimentNodeOrderChangedNotification,
    changedByOtherUser = true
  ): void {
    const node = this.experimentService.currentExperiment?.activities;
    const movedNode = this.experimentService.currentExperiment?.activities.find(
      (activity) => activity.activityId === notification.nodeId
    );
    if (node && movedNode) {
      if (changedByOtherUser) {
        this.OrderChangedNotificationMessage(
          movedNode.itemTitle,
          notification.position,
          notification.nodeType
        );
      }
    }
    this.experimentService.currentExperiment?.activities.forEach((item, index) => {
      if (item.activityId === notification.nodeId)
        this.experimentService.currentExperiment?.activities.splice(index, 1);
    });
    this.experimentService.currentExperiment?.activities.splice(
      notification.position,
      0,
      movedNode as Activity
    );
    this.nodeOrderChanged.next(notification);
  }

  private moduleOrderChangedByOtherUser(
    notification: ExperimentNodeOrderChangedNotification,
    changedByOtherUser = true
  ): void {
    let moveNodeModule;
      const movedInNode = this.experimentService.currentExperiment?.activities.find(
        (activity) => activity.activityId === notification.parentId
      );

      movedInNode?.dataModules.forEach((activityItem, index) => {
        if (activityItem.moduleId === notification.nodeId) {
          moveNodeModule= activityItem.moduleLabel;
        }
      });
    if(changedByOtherUser && moveNodeModule){
      this.OrderChangedNotificationMessage(
        moveNodeModule,
        notification.position,
        notification.nodeType
      );
    }

    if(this.experimentService.currentActivityId === notification.parentId){
      this.nodeOrderChanged.next(notification);
    }
    else{
      this.experimentService.currentExperiment?.activities.forEach((activity) => {
        const node = activity.dataModules.find(
          (activityItem) => activityItem.moduleId === notification.nodeId
        );
  
        const movedNode = this.experimentService.currentExperiment?.activities.find(
          (activity) => activity.activityId === notification.parentId
        );
  
        movedNode?.dataModules.forEach((activityItem, index) => {
          if (activityItem.moduleId === notification.nodeId && node) {
            movedNode.dataModules.splice(index, 1);
            movedNode.dataModules.splice(notification.position, 0, node);
          }
        });
      });
    }
  }

  private moduleItemOrderChangedByOtherUser(
    notification: ExperimentNodeOrderChangedNotification
  ): void {
    let moduleItemToLookInto!: Table | Form;
    this.experimentService.currentExperiment?.activities.forEach((activity) => {
      activity.dataModules.forEach((module) => {
        if (module.moduleId === notification.parentId) {
          module.items.forEach((moduleItem) => {
            if (
              ((moduleItem.itemType === NodeType.Table &&
              (moduleItem as Table).tableId === notification.nodeId)) ||
              ((moduleItem.itemType === NodeType.Form &&
                (moduleItem as Form).formId === notification.nodeId))
            ) {
              moduleItemToLookInto = moduleItem;
              this.moduleItemOrderNotificationHandler[notification.nodeType](
                module,
                notification,
                moduleItem
              );
            }
          });
        }
      });
    });
    if(moduleItemToLookInto){
      this.OrderChangedNotificationMessage(
        moduleItemToLookInto.itemTitle,
        notification.position,
        notification.nodeType
      );
    }
  }

  reOrderFormInModule(
    module: Module,
    notification: ExperimentNodeOrderChangedNotification,
    table: Form
  ) {
    module.items.forEach((moduleItem, index) => {
      if ((moduleItem as Form).formId === notification.nodeId) {
        const formToBeReOrdered = moduleItem;
        module.items.splice(index, 1);
        module.items.splice(notification.position, 0, formToBeReOrdered as Table);
      }
    });
  }

  reOrderTableInModule(
    module: Module,
    notification: ExperimentNodeOrderChangedNotification,
    form: Table
  ) {
    module.items.forEach((moduleItem, index) => {
      if ((moduleItem as Table).tableId === notification.nodeId) {
        const tableToBeReOrdered = moduleItem;
        module.items.splice(index, 1);
        module.items.splice(notification.position, 0, tableToBeReOrdered as Table);
      }
    });
  }

  private tableOrderChangedByOtherUser(
    module: Module,
    notification: ExperimentNodeOrderChangedNotification,
    table: ModuleItem
  ): void {
    if (this.experimentService.currentModuleId === notification.parentId) {
      this.nodeOrderChanged.next(notification);
    } else {
      this.reOrderTableInModule(module, notification, table as Table);
    }
    
  }

  private formOrderChangedByOtherUser(
    module: Module,
    notification: ExperimentNodeOrderChangedNotification,
    form: ModuleItem
  ): void {
    if (this.experimentService.currentModuleId === notification.parentId) {
      this.nodeOrderChanged.next(notification);
    } else {
      this.reOrderFormInModule(module, notification, form as Form);
    }
  }

  private nodeOrderChangedByOtherUsers(notification: ExperimentNodeOrderChangedNotification): void {
    this.nodeOrderSyncHandler[notification.nodeType](notification);
  }

  changeNodeReorder(
    nodeType: NodeType,
    nodeId: string,
    parentId: string,
    position: number,
    nodeTitle: string,
    rootParentId: string
  ): Observable<ExperimentNodeOrderChangedNotification | undefined> {
    const reOrderSuccessStatus = new Subject<ExperimentNodeOrderChangedNotification | undefined>();
    if (!this.hasPermission()) {
      this.failedToPerformOperationMessageBecauseUnAuthorized(nodeType);
      return of(undefined);
    }

    if (
      ExperimentNodeReOrderService.NotAllowedWorkflowStates.includes(
        this.experimentService.currentExperiment?.workflowState as ExperimentWorkflowState
      )
    ) {
      this.failedToPerformOperationMessage(nodeType);
      return of(undefined);
    }

    this.experimentEventsApiService
      .experimentEventsChangeNodeOrderPost$Json({
        body: this.prepareCommandToChangeNodeReOrderFor(nodeType, nodeId, parentId, position, rootParentId)
      })
      .subscribe({
        next: (response) => {
          this.processReOrderSuccessResponse(response, nodeTitle);
          reOrderSuccessStatus.next(response);
        }
      });
    return reOrderSuccessStatus.asObservable();
  }

  private prepareCommandToChangeNodeReOrderFor(
    nodeType: NodeType,
    nodeId: string,
    parentId: string,
    position: number,
    rootParentId: string
  ): ChangeExperimentNodeOrderCommand {
    return {
      experimentId: this.experimentService.currentExperiment?.id as string,
      nodeId,
      nodeType,
      parentId,
      position,
      activityId: rootParentId
    };
  }

  private processReOrderSuccessResponse(
    response: ExperimentNodeOrderChangedNotification,
    ItemTitle: string
  ): void {
    this.OrderChangedNotificationMessage(ItemTitle, response.position, response.nodeType);
  }

  private OrderChangedNotificationMessage(
    movedLabel: string,
    newPosition: number,
    nodeType: string
  ) {
    this.createNotificationMessage(
      $localize`:@@ExperimentnodeOrderChangedMessage:The ${nodeType} "${movedLabel}" has been moved to ${
        newPosition + 1
      } position.`,
      ''
    );
  }

  private createNotificationMessage(summary: string, detail: string, severity = 'success') {
    const messageObj: Message = {
      key: 'notification',
      severity: severity,
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  public hasPermission(): boolean {
    const featureFlags = this.clientStateService.getFeatureFlags('eln.experiment');
    ExperimentNodeReOrderService._hasPermissionToReOrderNode = featureFlags.some((data) => {
      if (!data || !JSON.parse(data)) {
        return false;
      }
      const parsedData = JSON.parse(data);
      return parsedData.CanReOrderNode && parsedData.CanReOrderNode === true;
    });
    return ExperimentNodeReOrderService._hasPermissionToReOrderNode;
  }

  public hasPermissionWithRespectToFlow(): boolean {
    if (
      ExperimentNodeReOrderService.NotAllowedWorkflowStates.includes(
        this.experimentService.currentExperiment?.workflowState as ExperimentWorkflowState
      )
    ) {
      return false;
    }
    return true;
  }

  private failedToPerformOperationMessageBecauseUnAuthorized(nodeType: string) {
    this.createNotificationMessage(
      $localize`:@@NodeReOrderFailedToPerformOperationMessageUnAuthorized:The reorder ${nodeType} action cannot be performed, because your auhtorization is failed.`,
      ``,
      'error'
    );
  }

  private failedToPerformOperationMessage(nodeType: string) {
    const state = this.experimentService.currentExperiment?.workflowState;
    const type = nodeType;
    this.createNotificationMessage(
      $localize`:@@NodeReOrderFailedToPerformOperationMessage:The reorder ${type} action cannot be performed, because the experiment workflow state is ${state}.`,
      ``,
      'error'
    );
  }
}
