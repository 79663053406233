import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  BptGridCellValueChangedEvent,
  BptGridComponent,
  BptGridMode,
  BptGridRowActionClickEvent,
  BptGridRowActionConfiguration,
  BptRowActionElement,
  ColumnDefinition,
  GridContextMenuItem,
  IFlagConfig,
  ISeverityIndicatorConfig
} from 'bpt-ui-library/bpt-grid';
import { LabItemRefreshedContext, LabItemsService } from '../../lab-items.service';
import { LabItemsInstrumentTableOptions } from './lab-items-instrument-table-options';
import { BaseComponent } from '../../../../base/base.component';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';
import { filter } from 'rxjs/operators';
import {
  ActivityInputType,
  ClientFacingNoteContextType,
  LabItemsInstrument
} from '../../../../api/models';
import { BptElnGridRowActionsComponent } from '../../shared/bpt-eln-grid-row-actions.component';
import { GridActionsPositionManager } from '../../shared/grid-actions-position-manager';
import { LabItemWisePermissions } from '../../shared/lab-items-feature-manager';
import { DialogService } from 'primeng/dynamicdialog';
import { LabItemsRemovedInstrumentComponent } from '../lab-items-removed-instrument/lab-items-removed-instrument.component';
import { CommentContextType } from '../../../../api/internal-comment/models';
import { FlagRendererService } from '../../../../experiment/services/flag-renderer.service';
import { ELNAppConstants } from '../../../../shared/eln-app-constants';
import { Experiment } from 'model/experiment.interface';
import { ICellRendererParams } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { ExperimentService } from '../../../services/experiment.service';

@Component({
  selector: 'app-lab-items-instrument',
  templateUrl: './lab-items-instrument.component.html',
  styleUrls: ['./lab-items-instrument.component.scss']
})
export class LabItemsInstrumentComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() titleOfTable = $localize`:@@LabItemsInstrumentTableTitle:Instruments`;
  @Input() labItemsInstrumentDataSource: LabItemsInstrument[] = [];
  @ViewChild('labItemsInstrumentGrid') labItemsInstrumentGrid!: BptGridComponent;

  public readonly gridActionsPositionManager = new GridActionsPositionManager(
    'app-lab-items-instrument',
    BptElnGridRowActionsComponent.RowActionsWidth
  );

  labItemsInstrumentId = 'labItemsInstrument';
  columnDefinitions: ColumnDefinition[] = [];
  gridMode = BptGridMode.dataEntry;
  gridActions!: BptGridRowActionConfiguration;
  containsRemovedRows = false;
  displayScrollButtons = true;
  rowActions: BptRowActionElement[] = [];
  wasEmpty = false;
  public styleRootVariables = '';
  experiment!: Experiment;
  reloadGrid = true;

  constructor(
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly labItemsService: LabItemsService,
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly flagRendererService: FlagRendererService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly experimentService: ExperimentService,
  ) {
    super(clientStateService, activatedRoute);
  }

  ngOnInit(): void {
    this.addGridActions();  
    this.columnDefinitions = LabItemsInstrumentTableOptions.GetColumnDefinitions(
      this.labItemsService.latestLabItemsPermissions,
      this.severityIndicatorConfig
    );
    this.refreshDataSource();
    this.watchInstrumentRefreshNotification();
    this.watchInternalCommentsNotification();
    this.watchInstrumentAddedNotification();
    this.watchInstrumentUpdatedNotification();
    this.watchClientFacingNoteNotification();
    this.watchRouterPathChanges();
    this.evaluateAccessibilityForRowActions();
    this.watchWorkFlowStateChanges();
    this.labItemsService.watchForActivityTitleDeterminationThroughRoute(this.activatedRoute);
    this.flagRendererService.ClientFacingNoteAdded.subscribe({
      next: (note) => {
        this.elementRef.nativeElement.dispatchEvent(note);
      }
    });
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }

  onGridReady() {
    this.augmentColumnsWithCornerFlagProviderForCells();
    this.setValidationStyles();
  }

  private watchInternalCommentsNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.internalCommentsRefresh.subscribe({
        next: () => {
          this.labItemsInstrumentGrid?.gridApi?.refreshCells({ force: true });
        }
      })
    );
  }
  public severityIndicatorConfig = () => {
    return {
      getIndicator: this.getSeverityIndicator //Called by the ag grid cell renderer
    } as ISeverityIndicatorConfig;
  };

  public readonly getSeverityIndicator = (params: ICellRendererParams) => {
    return this.labItemsService.getSeverityIndicatorDefinition(
      this.labItemsInstrumentDataSource,
      params
    );
  };
  private watchClientFacingNoteNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.experimentService.clientFacingNoteEvents.subscribe({
        next: () => {
          this.labItemsInstrumentGrid?.gridApi?.refreshCells({ force: true });
        }
      })
    );
  }

  getContextMenu(): GridContextMenuItem[] {
    return [
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'paste',
      'separator',
      {
        label: $localize`:@@clientFacingNoteContextMenuOption:Client-facing Notes`,
        action: () => {
          const cell = this.labItemsInstrumentGrid.gridApi.getFocusedCell();
          if (cell) {
            const row = this.labItemsInstrumentGrid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const colDef = cell.column.getColDef();
            const field = colDef.field as string; // template validation prevents undefined
            if (row && row.id && colDef?.field) {
              this.flagRendererService.showClientFacingNotes(
                [
                  row.id,
                  field,
                  this.labItemsService.experimentService.currentActivityId,
                  ActivityInputType.InstrumentDetails
                ],
                ClientFacingNoteContextType.LabItems,
                this.labItemsService.experimentService.currentActivityId
              );
            }
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />',
        // TODO : This is to be removed as part of Product Backlog Item 3239397: TECH : Revert the code for Restrict users from editing the experiment changes
      },
      {
        label: $localize`:@@commentsHeader:Internal Comments`,
        action: () => {
          const cell =
            this.labItemsInstrumentGrid && this.labItemsInstrumentGrid.gridApi.getFocusedCell();
          if (cell) {
            const row =
              this.labItemsInstrumentGrid &&
              this.labItemsInstrumentGrid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const field = cell.column.getColDef().field as string;
            const fieldName = LabItemsInstrumentTableOptions.ColumnDefinition[field].displayName;
            const currentRowIndex =  this.labItemsInstrumentGrid.gridApi.getDisplayedRowAtIndex(cell.rowIndex)?.rowIndex;
            if (row && row.id && row.rowIndex != null && currentRowIndex != null)
              this.flagRendererService.showInternalComments(
                [ 
                  this.labItemsService.experimentService.currentActivityId,
                  fieldName,
                  row.id,
                  field,
                  (row.rowIndex + 1).toString(),
                  ActivityInputType.InstrumentDetails
                ],
                CommentContextType.TableCell,
              );
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />'
      }
    ];
  }
  loadAuditHistoryDialog() {
    this.labItemsService.loadAuditHistoryDialog(ActivityInputType.InstrumentDetails);
  }

  private augmentColumnsWithCornerFlagProviderForCells(): any {
    const flagConfigProvider = (
      flag: 'top-right' | 'bottom-right',
      rowId: string,
      field: string
    ): IFlagConfig => {
      const fieldName = LabItemsInstrumentTableOptions.ColumnDefinition[field].displayName;
      return this.flagRendererService.getFlagRendererConfig(
        flag,
        this.flagRendererService.getPathBasedOnFlag(flag, fieldName, rowId, field, CommentContextType.LabItems, ActivityInputType.InstrumentDetails),
        CommentContextType.TableCell,
        rowId
      );
    };
    this.columnDefinitions.forEach((c: ColumnDefinition) => {
      c.flagConfigProvider = flagConfigProvider;
    });
    return flagConfigProvider;
  }
  private refreshDataSource(): void {
    const instruments = this.labItemsService.getLabItemsInstruments();
    instruments.forEach((instrument) => {
      this.labItemsService.unpackModifiableDataValues<LabItemsInstrument>(
        instrument,
        instrument.tableData,
        this.columnDefinitions,
        ActivityInputType.InstrumentDetails
      );
    });
    this.labItemsInstrumentDataSource = [...instruments];
    this.labItemsInstrumentGrid?.gridApi?.setRowData(this.labItemsInstrumentDataSource);
    this.containsRemovedRows = this.labItemsService.getLabItemsRemovedInstruments().length > 0;
    this.labItemsInstrumentGrid?.gridColumnApi?.autoSizeAllColumns();
  }

  private watchInstrumentRefreshNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.instrumentShouldRefresh.subscribe({
        next: () => this.refreshDataSource()
      })
    );
  }

  private readonly setValidationStyles = (): void => {
    this.columnDefinitions?.forEach((columnDefinition: ColumnDefinition) => {
      if (columnDefinition.editable) {
        columnDefinition.cellClassRules = this.labItemsService.getCssRules(
          this.labItemsInstrumentDataSource
        );
      }
      this.labItemsInstrumentGrid?.updateColumnDefinitions(columnDefinition);
    });
  };

  private watchInstrumentAddedNotification(): void {
    this.activeSubscriptions.push(
      this.labItemsService.InstrumentAdded.subscribe({
        next: this.addNewInstrumentIntoGrid.bind(this)
      })
    );
  }

  private watchInstrumentUpdatedNotification(): void {
    this.activeSubscriptions.push(
      this.labItemsService.InstrumentUpdated.subscribe({
        next: this.updateInstrumentIntoGrid.bind(this)
      })
    );
    this.activeSubscriptions.push(
      this.labItemsService.cellChangedNotificationByItemType[
        ActivityInputType.InstrumentDetails
      ].subscribe({
        next: this.applyCellChange.bind(this)
      })
    );
  }

  private addNewInstrumentIntoGrid(_instrument: LabItemsInstrument): void {
    this.labItemsInstrumentGrid?.gridColumnApi.autoSizeAllColumns();
  }

  private updateInstrumentIntoGrid(item: LabItemsInstrument): void {
    this.labItemsInstrumentGrid?.gridApi.applyTransaction({
      update: [item]
    });
  }

  private evaluatePermissions(): LabItemWisePermissions {
    const features = this.clientStateService.getFeatureFlags(this.clientState);
    this.labItemsService.featuresByClientState = features;
    return this.labItemsService.evaluateUserPermissionsOnLabItems();
  }

  private watchRouterPathChanges() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((_route: { url: string }) => {
        if (_route.url.toLowerCase().includes('/labitems')) {
          this.refreshDataSource();
        }
      });
  }

  private addGridActions() {
    this.rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(this.rowActions);

    this.gridActions = {
      actions: actionsSubject
    };
  }

  private getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: 'lab-items-instrument-delete-row',
        enabled: this.evaluatePermissions()[ActivityInputType.InstrumentDetails]['eln-remove-lab-item'],
        styleClass: 'far fa-trash-alt',
        click: (e: BptGridRowActionClickEvent) => this.labItemsService.removeHandler[ActivityInputType.InstrumentDetails](e.params.data),
        tooltip: $localize`:@@RemoveItem:Remove item`
      },
      {
        id: 'lab-items-instrument-refresh-row',
        enabled: this.evaluatePermissions()[ActivityInputType.InstrumentDetails]['eln-refresh-lab-item'],
        styleClass: 'fas fa-sync-alt',
        click: (e: BptGridRowActionClickEvent) => this.handleRefresh(e),
        tooltip: $localize`:@@RefreshItem:Refresh item`
      }
    ];
  }

  handleRefresh(e: BptGridRowActionClickEvent) {
    const icon = document.getElementById('lab-items-instrument-refresh-row');
    icon?.classList.add('pi-spin');
    this.labItemsService.refreshHandler[ActivityInputType.InstrumentDetails](e.params.data).subscribe({
      next: () => {
        icon?.classList.remove('pi-spin');
      },
      error: () => {
        icon?.classList.remove('pi-spin');
      }
    });
  }

  public evaluateAccessibilityForRowActions(): void {
    this.activeSubscriptions.push(this.labItemsService.LabItemsFeatureAccessChange.subscribe((permissions: any) => {
      if(this.rowActions.length > 0) {
      this.rowActions[0].enabled = permissions.instrumentDetails['eln-remove-lab-item']
      this.rowActions[1].enabled = permissions.instrumentDetails['eln-refresh-lab-item'];
      }
    })
    )
  }

  cellValueChanged(e: BptGridCellValueChangedEvent) {
    this.labItemsService.labItemsCellValueChanged(
      ActivityInputType.InstrumentDetails,
      e,
      this.labItemsInstrumentGrid as BptGridComponent
    );
    this.refreshDataSource();
  }

  private watchWorkFlowStateChanges() {
    this.experimentService.experimentWorkFlowState.subscribe({
      next: (state) => {        
        this.reloadGrid = false;
        setTimeout(() => {
          this.reloadGrid = true;
        }, 200);
      }
    });    
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    if (this.labItemsInstrumentGrid) {
      this.gridActionsPositionManager.updatePosition(
        this.labItemsInstrumentGrid.gridApi,
        this.labItemsInstrumentGrid.gridColumnApi
      );
    }
  }

  applyCellChange(labItemRefreshedContext: LabItemRefreshedContext): void {
    const rowNode = this.labItemsInstrumentGrid?.gridApi.getRowNode(labItemRefreshedContext.rowId);
    if (rowNode) {
      LabItemsService.UpdateCellValueInGrid(labItemRefreshedContext, rowNode);
      if (labItemRefreshedContext.skipFlash) {
        return;
      }
      this.labItemsInstrumentGrid?.gridApi.flashCells({
        rowNodes: [rowNode],
        columns: labItemRefreshedContext.changedColumns
      });
    }
  }

  loadRemovedRowsDialog() {
    this.dialogService.open(LabItemsRemovedInstrumentComponent, {
      width: '80%',
      autoZIndex: true,
      closeOnEscape: true,
      closable: true,
      header: $localize`:@@labItemsRemovedInstrumentAliquots:Removed Instruments`,
      styleClass: 'eln-removed-instrument-dialog'
    });
  }

}
