<div class="preparation-layout">
  <div class="eln-activity-preparation">
    <div class="eln-activity-preparation-header">
      <span id="eln-preparationModuleHeader" class="eln-preparation-header flex flex-row">{{preparationModuleHeader}}
        <span
          id="eln-preparationIconHelperTextShow"
          i18n-pTooltip="@@activityPreparationHelperText"
          class="pi pi-info-circle mt-1 mr-3 ml-2 eln-preparation-info align-self-center"
          pTooltip="Add, View, Edit Preparation"
          tooltipPosition="top"></span>
          <span *ngIf="completionTrackHandler" class="flex vertical-align-middle">
            <app-completion-tracking 
            [completionPercent]="completionPercent"
            class="align-self-end"
            ></app-completion-tracking>
          </span>
      </span>
      <div class="inline-flex ml-auto mb-3 column-gap-3">
        <p-button
          i18n-label="@@CreatePreparation"
          styleClass="bpt-button-compact"
          [label]="preparationsOptions.buttonLabel"
          id="eln-btnCreatePreparation"
          [disabled]="disableCreatePreparation"
          (onClick)="openCreatePreparationSlider()"></p-button>
      </div>
    </div>
    <div *ngIf="preparations.length > 0; then preparationGrid else noPreparation">
    </div>
    <ng-template #noPreparation>
      <div
        class="eln-activity-preparation-no-data flex align-items-center justify-content-center">
        <div class="eln-activity-preparation-no-data-img flex flex-column align-items-center">
          <img src="../../../assets/eln-assets/no-data.svg" alt="" id="eln-preparation-no-data-img"
            class="preparation-no-data-img" />
          <span class="activity-preparation-no-data-text mt-4">{{youDoNotHaveAnyPreparationBanner }}</span>
          <span class="activity-preparation-no-data-text">{{ yourPreparationWillAppearHereBanner }}</span>
          <p-button
            i18n-label="@@CreatePreparation"
            styleClass="bpt-button-compact p-button-outlined"
            class="mt-4"
            [label]="preparationsOptions.buttonLabel"
            [disabled]="disableCreatePreparation"
            id="eln-btnCreatePreparation"
            (onClick)="openCreatePreparationSlider()"></p-button>
        </div>
      </div>
    </ng-template>
    <ng-template #preparationGrid>
      <div class="preparation-grid-layout">
        <bpt-grid  #preparationsGrid
          *ngIf="reloadGrid"
          class="eln-grid eln-preparation-grid"
          [ngClass]="primitiveValue.length === 0 ? 'hideStatusBar' : 'showStatusBar'"
          [columnDefinitions]="columnDefinitions"
          [showAutoSizeButton]="gridOptions.showAutoSizeButton"
          [showFilterToggleButton]="gridOptions.showFilterToggleButton"
          [showGridOptionsButton]="gridOptions.showGridOptionsButton"
          [allowRowAdd]="gridOptions.allowRowAdd"
          [dataSource]="primitiveValue"
          [gridMode]="gridOptions.gridMode"
          [statusBar]="statusBarGetter"
          [readOnly]="readOnly"
          [rowActionConfigurations]="gridActions"
          (cellValueChanged)="cellValueChanged($event)"
          (cellEditStopped)="cellEditStopped($event)"
          (gridReady)="onGridReady()"
          rowSelection="multiple"
          (newGridPreferenceAdded)="saveNewGridPreference($event)"
          (gridPreferenceDeleted)="deleteGridPreference($event)"
          (gridPreferenceUpdated)="updateGridPreference($event)"
          (gridPreferenceSelectionChanged)="changeDefaultGridPreference($event)"
          (firstDataRendered)="onFirstDataRendered($event)"
          [contextMenu]="getContextMenu()"
          [suppressContextMenu]="false">
          <ng-container class="bpt-grid-toolbar-right-layout">
            <a
              class="bpt-grid-toolbar-item table-grid-toolbar-audit-icon"
              align="right"
              id="eln-iconAuditHistoryShow-preparation"
              (click)="loadAuditHistoryDialog()">
              <span
                i18n-pTooltip="@@viewHistory"
                class="fa fa-history"
                pTooltip="View History"
                tooltipPosition="top">
                <span class="ml-1 audit" i18n="@@History">History</span></span>
            </a>
          </ng-container>
          <ng-container *ngIf="containsRemovedRows" class="bpt-grid-toolbar-right-layout">
              <a 
                  class="bpt-grid-toolbar-item table-grid-toolbar-view-removed-rows-icon reference-remove-icon ml-3"
                 align="right"
                 id="eln-iconViewRemovedRowsShow-{{ preparationViewRemovedId }}"
                 (click)="loadRemovedRowsDialog()">
                  <span
                      i18n-pTooltip="@@viewRemovedRows"
                      class="fa icon-view-row"
                      pTooltip="View Removed Rows"
                      tooltipPosition="top"><span class="ml-1 viewRemovedRows-iconTxt" i18n="@@viewRemovedRows"
                                                  >View Removed Rows</span></span>
              </a>
          </ng-container>
        </bpt-grid>
      </div>
    </ng-template>
  </div>
</div>

<app-preparation-internal-information
  *ngIf="showInternalInfoSlider"
  [showSlider]="showInternalInfoSlider"
  [isReadOnlySlider]="disableGrid && isParentRecipe"
  [preparationData]="preparationDataForInternalInformation"
  (closeSlider)="closeInternalInfoSlider($event)">
</app-preparation-internal-information>
<app-create-preparation *ngIf="showSlider"
  [showSlider]="showSlider" (closeSlider)="closeCreatePreparationSlider($event)"
  [rowsPresentInPreparationsGrid]="noOfPreparationsInActivity"></app-create-preparation>
<app-preparation-expiration-builder *ngIf="showExpirationSlider"
  [sourceGridData]="sourceGridData"
  (closeExpirationSlider)="closeExpirationSlider($event)"></app-preparation-expiration-builder>
<app-preparation-container-description-builder></app-preparation-container-description-builder>