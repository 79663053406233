import { Injectable } from '@angular/core';
import { CellDataForCompletionTracking, TableDataForCompletionTracking } from '../experiment/model/cell-data-for-completion-tracking.interface';
import { ActivityInputType, AddRowEventNotification, Cell, CellChangedEventNotification, FieldChangedEventNotification, Row, ValueState } from '../api/data-entry/models';
import { ExperimentService } from '../experiment/services/experiment.service';
import { PreparationConstants } from '../preparation/preparation-constants';
import { DataRecordService } from '../experiment/services/data-record.service';
import { FieldDataForCompletionTracking } from '../experiment/model/field-data-for-completion-tracking.interface';
import { FieldDefinition, FieldGroup, Form, Table } from '../model/experiment.interface';
import { ExperimentDataValue, FieldDefinitionResponse, FieldGroupResponse, FieldType, FormItemType, ModifiableDataValue, NodeType } from '../api/models';
import { mapValues } from 'lodash-es';
import { TableDataService } from '../experiment/data/table/table-data.service';
import { ActivityInputSamples } from '../experiment/model/activity-input-samples';
import { ActivityInputStudyActivity } from '../experiment/model/activity-input-study-activity';

/**
  * Tracks the "WHAT" is complete or not of some things: fields/cells/etc. 
  *
  * Note: CompletionTrackingService is not the only story. 
  *       There are also:
  *         * functions that compute, top-down, the "IS" a tree is complete or not (e.g. checkActivityCompletion)
  *         * observables to receive status changes (e.g., setActivityCompletionStatus)
 */
@Injectable({
  providedIn: 'root'
})
export class CompletionTrackingService {

  public readonly idealCompletionPercentage: number = 100;
  public readonly columnsToBeExcludedForCalculation: string[] = ['itemReference', 'id', 'rowIndex', 'preparationId', 'preparationNumber', TableDataService.isRemovedColumn];
  totalFieldsForOtherForm = 0;
  allFormFields: {
    [key: string]: any;
  }[] = []
  constructor(private readonly experimentService: ExperimentService) { }

  /*The method can be used when the input is of non table row form*/
  calculateCrossReferencesCompletionPercentage(totalTableCells: number, emptyCellsCount: number, initialCompletionPercent: number, tableInfo: TableDataForCompletionTracking) {
    const completionPercent = totalTableCells === 0 ? 
      this.idealCompletionPercentage : 
      Math.floor(((totalTableCells - emptyCellsCount) / totalTableCells) * this.idealCompletionPercentage);

    this.updateTableCompletionStatusExperimentService(completionPercent, initialCompletionPercent, tableInfo, false);
    return completionPercent;
  }

  calculateActivityInputsTableCompletionPercent(rows: ActivityInputSamples[] | ActivityInputStudyActivity[], tableType: ActivityInputType): number {
    let completionPercent = 0;
    let completedRows = 0;
    if (rows.length === 0) return 100;
    switch(tableType) {
      case ActivityInputType.Aliquot:
        (rows as ActivityInputSamples[]).forEach(row => {
          completedRows = row.aliquotTests.length > 0 ? completedRows + 1 : completedRows;
        });
        completionPercent = (completedRows/rows.length) * 100;
        break;
      case ActivityInputType.Material:
        (rows as ActivityInputStudyActivity[]).forEach(row => {
          completedRows = row.studyActivities.length > 0 ? completedRows + 1 : completedRows;
        });
        completionPercent = (completedRows/rows.length) * 100;
        break;
    }
    return Math.floor(completionPercent);
  }

  private populateCellsForCompletionTracking(rows: any, columnDataFields: string[], isTable = true): CellDataForCompletionTracking[] {
    const cellDataForCompletionTracking = [];
    for (const row of rows) {
      for (const fieldId of columnDataFields) {
        if (this.columnsToBeExcludedForCalculation.includes(fieldId)) continue;

        const cellValue = (row)[fieldId];
        cellDataForCompletionTracking.push({
          rowId: row.id ?? row.itemReference ?? row.preparationId,
          columnName: fieldId,
          state: this.getState(cellValue, isTable, fieldId) 
        });
      }
    }
    return cellDataForCompletionTracking;
  }

  private calculateCompletionPercent(cellDataForCompletionTracking: CellDataForCompletionTracking[]): number {
    let completionPercent = 0;
    if (cellDataForCompletionTracking.length === 0) {
      completionPercent = this.idealCompletionPercentage;
    } else {
      const totalTableCells = cellDataForCompletionTracking.length;
      let emptyCellsCount = 0;
      cellDataForCompletionTracking.forEach((cell) => {
        if (cell.state === ValueState.Empty || cell.state === undefined) {
          emptyCellsCount += 1;
        }
      });
      completionPercent = Math.floor(((totalTableCells - emptyCellsCount) / totalTableCells) * this.idealCompletionPercentage);
    }
    return completionPercent;
  }

  /**
   * This method is used when the input is in table row form.
   * @param rows collection of table rows
   * @param initialCompletionPercentage current completion percentage before calculation
   * @param columnDataFieldIds collection of columns
   * @param tableInfo Table details
   * @param isTable true when the input is of tablerow.
   */
  populateCompletionPercentage(
    rows: any, 
    initialCompletionPercent: number, 
    columnDataFieldIds: string[], 
    tableInfo: TableDataForCompletionTracking, 
    isTable = true, 
    isForOtherActivity = false
  ) {
    const cellDataForCompletionTracking = this.populateCellsForCompletionTracking(rows, columnDataFieldIds, isTable);
    const completionPercent = this.calculateCompletionPercent(cellDataForCompletionTracking);
    if (isTable) {
      this.updateTableCompletionStatusExperimentService(completionPercent, initialCompletionPercent, tableInfo, isForOtherActivity);
    }
    return completionPercent;
  }

  private getState(cellValue: any, isTable: boolean, fieldName: string) {
    if (isTable) {
      return cellValue?.value?.state ?? ValueState.Empty;
    } else {
      if (fieldName === PreparationConstants.preparationConcentrationColumn) {
        return cellValue.state; 
      }
      return cellValue ? ValueState.Set : ValueState.Empty;
    }
  }

  private updateTableCompletionStatusExperimentService(
    completionPercent: number, 
    initialCompletionPercent: number, 
    tableInfo: TableDataForCompletionTracking, 
    isForOtherActivity: boolean
  ) {
    if (isForOtherActivity) {
      this.experimentService.nodeCompletionStatus.next({
        title: tableInfo.tableTitle,
        id: tableInfo.tableId,
        isComplete: completionPercent === 100
      });
    } else {
      const gotTo100 = initialCompletionPercent < this.idealCompletionPercentage && completionPercent === this.idealCompletionPercentage;
      const fellFrom100 = initialCompletionPercent === this.idealCompletionPercentage && completionPercent < this.idealCompletionPercentage;
      if (gotTo100 || fellFrom100) {
        this.experimentService.nodeCompletionStatus.next({
          title: tableInfo.tableTitle,
          id: tableInfo.tableId,
          isComplete: gotTo100
        });
      }
    }
  }

  updateCompletionTrackingForOtherTable(data: AddRowEventNotification | CellChangedEventNotification) {
    const tableId = data && "tableId" in data ? data.tableId : data?.tableIds[0];
    if (tableId && !this.isFromCurrentActivity(NodeType.Table, tableId)) {
      const table = this.experimentService.getTable(tableId);
      if (table) {
        if (data && "rowIds" in data) {
          this.updateTableValueWithIncomingData(table, data);
        }
        else{
          this.updateTableWithNewlyAddedRows(data, table);
        }
        const tableTitle = table?.itemTitle;
        const tableInfo: TableDataForCompletionTracking = {
          tableId: tableId,
          tableTitle: tableTitle
        };
        const columnDataFieldIds: string[] = table.columnDefinitions.map(item => item.field);
        this.populateCompletionPercentage(table.value, 0, columnDataFieldIds, tableInfo, true, true);
      }
    }
  }

  private updateTableValueWithIncomingData(table: Table, data: CellChangedEventNotification) {
    table.value.forEach(row => {
      if (data.rowIds.includes(row.id)) {
        data.columnValues?.forEach((column: Cell) => {
          const propertyValue = DataRecordService.getModifiableDataValue(
            column.propertyValue,
            row[column.propertyName]
          );
          const columnType = table?.columnDefinitions.find(
            (c) => c.field === column.propertyName
          )?.columnType;
          if (column.propertyName && columnType) {
            row[column.propertyName] = propertyValue;
          }
        });
      }
    });
  }

  private updateTableWithNewlyAddedRows(data: AddRowEventNotification, table: Table) {
    const mapValue: (value: ExperimentDataValue) => ModifiableDataValue = (value) => ({
      isModified: false,
      value: value
    });
    const newRows = data.rows.map((r: any) => ({
      id: r.id,
      ...mapValues(this.unpackRowData(r), mapValue)
    }));
    table.value.push(...newRows);
  }

  private unpackRowData(row: Row): { [key: string]: ExperimentDataValue } {
    const dataValues: { [key: string]: ExperimentDataValue } = {};
    row.data.forEach((cell) => {
      dataValues[cell.propertyName] = cell.propertyValue;
    });
    return dataValues;
  }

  updateCompletionStatusForOtherForm(data: FieldChangedEventNotification) {
    const completionTrackingArrayForOtherForm: FieldDataForCompletionTracking[] = [];
    this.totalFieldsForOtherForm = 0;
    const form = this.experimentService.getForm(data.formId);
    if (form && !this.isFromCurrentActivity(NodeType.Form, data.formId)) {
      this.allFormFields = this.getAllFieldsFromForm(this.experimentService.getForm(data.formId)?.value);
      this.calculateTotalFieldsAndUpdateArray(form, completionTrackingArrayForOtherForm, data);
      const completionPercent = this.getCompletionPercentForOtherForm(completionTrackingArrayForOtherForm);
      const formTitle = form.itemTitle;
      this.updateFormCompletionStatus(formTitle, data.formId, completionPercent)
    }
  }

  private getCompletionPercentForOtherForm(completionTrackingArrayForOtherForm: FieldDataForCompletionTracking[]) {
    let emptyFields = 0;
    completionTrackingArrayForOtherForm?.forEach((field) => {
      if (field.isEmpty || !field.value) {
        emptyFields += 1;
      }
    });
    return Math.floor(
      ((this.totalFieldsForOtherForm - emptyFields) / this.totalFieldsForOtherForm) * 100
    );
  }

  private updateFormCompletionStatus(formTitle: string, formId: string, completionPercent: number) {
    this.experimentService.nodeCompletionStatus.next({
      title: formTitle,
      id: formId,
      isComplete: completionPercent === 100
    });
  }

  private calculateTotalFieldsAndUpdateArray(
    container: Form | FieldGroup, 
    completionTrackingArrayForOtherForm: FieldDataForCompletionTracking[], 
    data: FieldChangedEventNotification, 
    fieldGroup?: string
  ) {
    container.fieldDefinitions.forEach((item) => {
      if (item.itemType === FormItemType.FieldGroup) {
        this.calculateTotalFieldsAndUpdateArray(item as FieldGroup, completionTrackingArrayForOtherForm, data, item.field);
      }
      else if (item.itemType === FormItemType.Field && (item as FieldDefinition).fieldType !== FieldType.Textblock) {
        this.updateCompletionArrayForForm(fieldGroup, item, completionTrackingArrayForOtherForm, data);
      }
    })
  }

  private updateCompletionArrayForForm(
    fieldGroup: string | undefined, 
    item: FieldGroupResponse | FieldDefinitionResponse, 
    completionTrackingArrayForOtherForm: FieldDataForCompletionTracking[], 
    data: FieldChangedEventNotification
  ) {
    const path = fieldGroup ? `${fieldGroup}_${item.field}`: item.field;
    if (completionTrackingArrayForOtherForm.filter(field => field.path === path && field.formId === data.formId).length === 0) {
      this.pushToCompletionTrackingArray(path, data, completionTrackingArrayForOtherForm, item, fieldGroup);
    }
    else if (completionTrackingArrayForOtherForm.filter(field => field.path === path && field.formId === data.formId).length > 0) {
      this.updateExistingValueInCompletionArray(completionTrackingArrayForOtherForm, data);
    }
  }

  private pushToCompletionTrackingArray(
    path: string, 
    data: FieldChangedEventNotification, 
    completionTrackingArrayForOtherForm: FieldDataForCompletionTracking[], 
    item: FieldGroupResponse | FieldDefinitionResponse, 
    fieldGroup: string | undefined
  ) {
    if (path === data.path.join('_')) {
      this.totalFieldsForOtherForm += 1;
      this.pushDataFieldToCompletionArray(completionTrackingArrayForOtherForm, item, fieldGroup, data.newValue, data.formId);
    } else {
      this.pushOtherFieldsToArray(item, completionTrackingArrayForOtherForm, fieldGroup, data);
    }
  }

  private pushOtherFieldsToArray(
    item: FieldGroupResponse | FieldDefinitionResponse, 
    completionTrackingArrayForOtherForm: FieldDataForCompletionTracking[], 
    fieldGroup: string | undefined, 
    data: FieldChangedEventNotification
  ) {
    let value: any;
    if (this.allFormFields) {
      for (const field of this.allFormFields) {
        if (item.field in field) {
          value = field[item.field].value;
          break;
        }
      }
      this.totalFieldsForOtherForm += 1;
      this.pushDataFieldToCompletionArray(completionTrackingArrayForOtherForm, item, fieldGroup, value, data.formId);
    }
  }

  private updateExistingValueInCompletionArray(completionTrackingArrayForOtherForm: FieldDataForCompletionTracking[], data: FieldChangedEventNotification) {
    completionTrackingArrayForOtherForm.forEach(fieldData => {
      if (fieldData.path === data.path.join('_') && fieldData.formId === data.formId) {
        fieldData.value = data.newValue as any;
        fieldData.isEmpty = data.newValue.state === ValueState.Empty;
      }
    });
  }

  private pushDataFieldToCompletionArray(
    completionTrackingArrayForOtherForm: FieldDataForCompletionTracking[], 
    item: FieldGroupResponse | FieldDefinitionResponse, 
    fieldGroup: string | undefined, 
    value: ExperimentDataValue,
    formId: string
  ) {
    completionTrackingArrayForOtherForm.push({
      fieldType: (item as FieldDefinitionResponse).fieldType,
      path: fieldGroup? `${fieldGroup}_${item.field}`: item.field,
      value: value,
      isEmpty: !value || value.state === ValueState.Empty,
      formId: formId,
    });
  }

  getAllFieldsFromForm(data: any): { [key: string]: ModifiableDataValue }[] {
    const fields = [];
    for (const key in data) {
      if (data[key]?.value) {
        fields.push({ [key]: data[key] });
      } else if (typeof data[key] === "object") {
        fields.push(...this.getAllFieldsFromForm(data[key]));
      }
    }
    return fields;
  }

  calculateFormCompletionPercentage(totalFields: number, isFormReady: boolean, form: Form, completionTrackingArray: FieldDataForCompletionTracking[]) {
    let emptyFields = 0;
    completionTrackingArray?.forEach((field) => {
      if (field.isEmpty || !field.value) {
        emptyFields += 1;
      }
    });
    const completionPercent = Math.floor(
      ((totalFields - emptyFields) / totalFields) * 100
    );
    this.sendFormCompletionStatus(completionPercent, isFormReady, form);
    return completionPercent;
  }

  sendFormCompletionStatus(completionPercent: number, isFormReady: boolean, form: Form) {
    const allFieldsComplete = 100;
    if (completionPercent === allFieldsComplete && isFormReady) {
      this.experimentService.nodeCompletionStatus.next({
        title: form.itemTitle,
        id: form.formId,
        isComplete: true
      });
    } else if (completionPercent < allFieldsComplete && isFormReady) {
      this.experimentService.nodeCompletionStatus.next({
        title: form.itemTitle,
        id: form.formId,
        isComplete: false
      });
    }
  }

  updateValueInFormCompletionTrackingArray(fieldPath: string[], newValue: ExperimentDataValue, completionTrackingArray: FieldDataForCompletionTracking[]) {
    completionTrackingArray.forEach((field) => {
      if (field.path === fieldPath.join('_')) {
        field.value = newValue;
        field.isEmpty = newValue.state === ValueState.Empty;
      }
    });
  }

  isFromCurrentActivity(nodeType: NodeType, nodeId: string) {
    const activity = this.experimentService.currentActivity;
    const module = (activity?.dataModules ?? []).find(
      (mod) => mod.moduleId === this.experimentService.currentModuleId
    );
    return nodeType === NodeType.Table ? module?.items.some((i) => i.itemType === NodeType.Table && (i as Table).tableId === nodeId)
       : module?.items.some((i) => i.itemType === NodeType.Form && (i as Form).formId === nodeId
    );
  }
}
