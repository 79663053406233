/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddActivityInputCommand } from '../models/add-activity-input-command';
import { AddExperimentScannedItemsCommand } from '../models/add-experiment-scanned-items-command';
import { MaterialAliquotDetails } from '../models/material-aliquot-details';
import { SampleTestResponse } from '../models/sample-test-response';
import { StudyActivityResponse } from '../models/study-activity-response';

@Injectable({
  providedIn: 'root',
})
export class ActivityInputService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation activityInputPost
   */
  static readonly ActivityInputPostPath = '/activity-input';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputPost$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddActivityInputCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputService.ActivityInputPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputPost(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddActivityInputCommand
  }): Observable<void> {

    return this.activityInputPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation activityInputAddExperimentScannedItemsPost
   */
  static readonly ActivityInputAddExperimentScannedItemsPostPath = '/activity-input/add-experiment-scanned-items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputAddExperimentScannedItemsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputAddExperimentScannedItemsPost$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddExperimentScannedItemsCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputService.ActivityInputAddExperimentScannedItemsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputAddExperimentScannedItemsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputAddExperimentScannedItemsPost(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddExperimentScannedItemsCommand
  }): Observable<void> {

    return this.activityInputAddExperimentScannedItemsPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation activityInputRetrieveSampleTestPost
   */
  static readonly ActivityInputRetrieveSampleTestPostPath = '/activity-input/retrieve-sample-test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputRetrieveSampleTestPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputRetrieveSampleTestPost$Plain$Response(params?: {
    sampleNumber?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: Array<string>
  }): Observable<StrictHttpResponse<SampleTestResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputService.ActivityInputRetrieveSampleTestPostPath, 'post');
    if (params) {
      rb.query('sampleNumber', params.sampleNumber, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SampleTestResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputRetrieveSampleTestPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputRetrieveSampleTestPost$Plain(params?: {
    sampleNumber?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: Array<string>
  }): Observable<SampleTestResponse> {

    return this.activityInputRetrieveSampleTestPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SampleTestResponse>) => r.body as SampleTestResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputRetrieveSampleTestPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputRetrieveSampleTestPost$Json$Response(params?: {
    sampleNumber?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: Array<string>
  }): Observable<StrictHttpResponse<SampleTestResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputService.ActivityInputRetrieveSampleTestPostPath, 'post');
    if (params) {
      rb.query('sampleNumber', params.sampleNumber, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SampleTestResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputRetrieveSampleTestPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputRetrieveSampleTestPost$Json(params?: {
    sampleNumber?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: Array<string>
  }): Observable<SampleTestResponse> {

    return this.activityInputRetrieveSampleTestPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SampleTestResponse>) => r.body as SampleTestResponse)
    );
  }

  /**
   * Path part for operation activityInputRetrieveStudyActivityPost
   */
  static readonly ActivityInputRetrieveStudyActivityPostPath = '/activity-input/retrieve-study-activity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputRetrieveStudyActivityPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveStudyActivityPost$Plain$Response(params?: {
    materialAliquotNumber?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<StudyActivityResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputService.ActivityInputRetrieveStudyActivityPostPath, 'post');
    if (params) {
      rb.query('materialAliquotNumber', params.materialAliquotNumber, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StudyActivityResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputRetrieveStudyActivityPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveStudyActivityPost$Plain(params?: {
    materialAliquotNumber?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StudyActivityResponse> {

    return this.activityInputRetrieveStudyActivityPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StudyActivityResponse>) => r.body as StudyActivityResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputRetrieveStudyActivityPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveStudyActivityPost$Json$Response(params?: {
    materialAliquotNumber?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<StudyActivityResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputService.ActivityInputRetrieveStudyActivityPostPath, 'post');
    if (params) {
      rb.query('materialAliquotNumber', params.materialAliquotNumber, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StudyActivityResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputRetrieveStudyActivityPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveStudyActivityPost$Json(params?: {
    materialAliquotNumber?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StudyActivityResponse> {

    return this.activityInputRetrieveStudyActivityPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StudyActivityResponse>) => r.body as StudyActivityResponse)
    );
  }

  /**
   * Path part for operation activityInputRetrieveMaterialDetailsPost
   */
  static readonly ActivityInputRetrieveMaterialDetailsPostPath = '/activity-input/retrieve-material-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputRetrieveMaterialDetailsPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveMaterialDetailsPost$Plain$Response(params?: {
    materialAliquotNumber?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<MaterialAliquotDetails>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputService.ActivityInputRetrieveMaterialDetailsPostPath, 'post');
    if (params) {
      rb.query('materialAliquotNumber', params.materialAliquotNumber, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MaterialAliquotDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputRetrieveMaterialDetailsPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveMaterialDetailsPost$Plain(params?: {
    materialAliquotNumber?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<MaterialAliquotDetails> {

    return this.activityInputRetrieveMaterialDetailsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MaterialAliquotDetails>) => r.body as MaterialAliquotDetails)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputRetrieveMaterialDetailsPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveMaterialDetailsPost$Json$Response(params?: {
    materialAliquotNumber?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<MaterialAliquotDetails>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputService.ActivityInputRetrieveMaterialDetailsPostPath, 'post');
    if (params) {
      rb.query('materialAliquotNumber', params.materialAliquotNumber, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MaterialAliquotDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputRetrieveMaterialDetailsPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveMaterialDetailsPost$Json(params?: {
    materialAliquotNumber?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<MaterialAliquotDetails> {

    return this.activityInputRetrieveMaterialDetailsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MaterialAliquotDetails>) => r.body as MaterialAliquotDetails)
    );
  }

}
