/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AttachFileCommand } from '../models/attach-file-command';
import { AttachedFileResponse } from '../models/attached-file-response';
import { ChangeNonRoutineIssueEncounteredCommand } from '../models/change-non-routine-issue-encountered-command';
import { ChangeReturnedToServiceCommand } from '../models/change-returned-to-service-command';
import { ChromatographyDataImportResponse } from '../models/chromatography-data-import-response';
import { ChromatographyDataRemovedResponse } from '../models/chromatography-data-removed-response';
import { ChromatographyDataRowRefreshResponse } from '../models/chromatography-data-row-refresh-response';
import { DeleteFilesCommand } from '../models/delete-files-command';
import { DeletedFilesResponse } from '../models/deleted-files-response';
import { ImportChromatographyDataCommand } from '../models/import-chromatography-data-command';
import { NonRoutineIssueEncounteredDataResponse } from '../models/non-routine-issue-encountered-data-response';
import { RefreshChromatographyDataCommand } from '../models/refresh-chromatography-data-command';
import { RemoveChromatographyDataCommand } from '../models/remove-chromatography-data-command';
import { ReturnedToServiceDataResponse } from '../models/returned-to-service-data-response';

@Injectable({
  providedIn: 'root',
})
export class ActivityOutputEventsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation activityOutputEventsRefreshChromatographyDataPost
   */
  static readonly ActivityOutputEventsRefreshChromatographyDataPostPath = '/activity-output-events/refresh-chromatography-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsRefreshChromatographyDataPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRefreshChromatographyDataPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshChromatographyDataCommand
  }): Observable<StrictHttpResponse<ChromatographyDataRowRefreshResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsRefreshChromatographyDataPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChromatographyDataRowRefreshResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsRefreshChromatographyDataPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRefreshChromatographyDataPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshChromatographyDataCommand
  }): Observable<ChromatographyDataRowRefreshResponse> {

    return this.activityOutputEventsRefreshChromatographyDataPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChromatographyDataRowRefreshResponse>) => r.body as ChromatographyDataRowRefreshResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsRefreshChromatographyDataPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRefreshChromatographyDataPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshChromatographyDataCommand
  }): Observable<StrictHttpResponse<ChromatographyDataRowRefreshResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsRefreshChromatographyDataPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChromatographyDataRowRefreshResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsRefreshChromatographyDataPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRefreshChromatographyDataPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshChromatographyDataCommand
  }): Observable<ChromatographyDataRowRefreshResponse> {

    return this.activityOutputEventsRefreshChromatographyDataPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChromatographyDataRowRefreshResponse>) => r.body as ChromatographyDataRowRefreshResponse)
    );
  }

  /**
   * Path part for operation activityOutputEventsImportChromatographyDataPost
   */
  static readonly ActivityOutputEventsImportChromatographyDataPostPath = '/activity-output-events/import-chromatography-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsImportChromatographyDataPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsImportChromatographyDataPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ImportChromatographyDataCommand
  }): Observable<StrictHttpResponse<ChromatographyDataImportResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsImportChromatographyDataPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChromatographyDataImportResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsImportChromatographyDataPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsImportChromatographyDataPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ImportChromatographyDataCommand
  }): Observable<ChromatographyDataImportResponse> {

    return this.activityOutputEventsImportChromatographyDataPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChromatographyDataImportResponse>) => r.body as ChromatographyDataImportResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsImportChromatographyDataPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsImportChromatographyDataPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ImportChromatographyDataCommand
  }): Observable<StrictHttpResponse<ChromatographyDataImportResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsImportChromatographyDataPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChromatographyDataImportResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsImportChromatographyDataPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsImportChromatographyDataPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ImportChromatographyDataCommand
  }): Observable<ChromatographyDataImportResponse> {

    return this.activityOutputEventsImportChromatographyDataPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChromatographyDataImportResponse>) => r.body as ChromatographyDataImportResponse)
    );
  }

  /**
   * Path part for operation activityOutputEventsRemoveChromatographyDataPost
   */
  static readonly ActivityOutputEventsRemoveChromatographyDataPostPath = '/activity-output-events/remove-chromatography-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsRemoveChromatographyDataPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRemoveChromatographyDataPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveChromatographyDataCommand
  }): Observable<StrictHttpResponse<ChromatographyDataRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsRemoveChromatographyDataPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChromatographyDataRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsRemoveChromatographyDataPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRemoveChromatographyDataPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveChromatographyDataCommand
  }): Observable<ChromatographyDataRemovedResponse> {

    return this.activityOutputEventsRemoveChromatographyDataPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChromatographyDataRemovedResponse>) => r.body as ChromatographyDataRemovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsRemoveChromatographyDataPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRemoveChromatographyDataPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveChromatographyDataCommand
  }): Observable<StrictHttpResponse<ChromatographyDataRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsRemoveChromatographyDataPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChromatographyDataRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsRemoveChromatographyDataPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRemoveChromatographyDataPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveChromatographyDataCommand
  }): Observable<ChromatographyDataRemovedResponse> {

    return this.activityOutputEventsRemoveChromatographyDataPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChromatographyDataRemovedResponse>) => r.body as ChromatographyDataRemovedResponse)
    );
  }

  /**
   * Path part for operation activityOutputEventsChangeNonRoutineSelectionPost
   */
  static readonly ActivityOutputEventsChangeNonRoutineSelectionPostPath = '/activity-output-events/change-non-routine-selection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsChangeNonRoutineSelectionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeNonRoutineSelectionPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeNonRoutineIssueEncounteredCommand
  }): Observable<StrictHttpResponse<NonRoutineIssueEncounteredDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsChangeNonRoutineSelectionPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NonRoutineIssueEncounteredDataResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsChangeNonRoutineSelectionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeNonRoutineSelectionPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeNonRoutineIssueEncounteredCommand
  }): Observable<NonRoutineIssueEncounteredDataResponse> {

    return this.activityOutputEventsChangeNonRoutineSelectionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NonRoutineIssueEncounteredDataResponse>) => r.body as NonRoutineIssueEncounteredDataResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsChangeNonRoutineSelectionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeNonRoutineSelectionPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeNonRoutineIssueEncounteredCommand
  }): Observable<StrictHttpResponse<NonRoutineIssueEncounteredDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsChangeNonRoutineSelectionPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NonRoutineIssueEncounteredDataResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsChangeNonRoutineSelectionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeNonRoutineSelectionPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeNonRoutineIssueEncounteredCommand
  }): Observable<NonRoutineIssueEncounteredDataResponse> {

    return this.activityOutputEventsChangeNonRoutineSelectionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NonRoutineIssueEncounteredDataResponse>) => r.body as NonRoutineIssueEncounteredDataResponse)
    );
  }

  /**
   * Path part for operation activityOutputEventsChangeReturnToServiceSelectionPost
   */
  static readonly ActivityOutputEventsChangeReturnToServiceSelectionPostPath = '/activity-output-events/change-return-to-service-selection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsChangeReturnToServiceSelectionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeReturnToServiceSelectionPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeReturnedToServiceCommand
  }): Observable<StrictHttpResponse<ReturnedToServiceDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsChangeReturnToServiceSelectionPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReturnedToServiceDataResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsChangeReturnToServiceSelectionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeReturnToServiceSelectionPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeReturnedToServiceCommand
  }): Observable<ReturnedToServiceDataResponse> {

    return this.activityOutputEventsChangeReturnToServiceSelectionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReturnedToServiceDataResponse>) => r.body as ReturnedToServiceDataResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsChangeReturnToServiceSelectionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeReturnToServiceSelectionPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeReturnedToServiceCommand
  }): Observable<StrictHttpResponse<ReturnedToServiceDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsChangeReturnToServiceSelectionPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReturnedToServiceDataResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsChangeReturnToServiceSelectionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeReturnToServiceSelectionPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeReturnedToServiceCommand
  }): Observable<ReturnedToServiceDataResponse> {

    return this.activityOutputEventsChangeReturnToServiceSelectionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReturnedToServiceDataResponse>) => r.body as ReturnedToServiceDataResponse)
    );
  }

  /**
   * Path part for operation activityOutputEventsAttachFilesPost
   */
  static readonly ActivityOutputEventsAttachFilesPostPath = '/activity-output-events/attach-files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsAttachFilesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsAttachFilesPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AttachFileCommand
  }): Observable<StrictHttpResponse<AttachedFileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsAttachFilesPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AttachedFileResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsAttachFilesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsAttachFilesPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AttachFileCommand
  }): Observable<AttachedFileResponse> {

    return this.activityOutputEventsAttachFilesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AttachedFileResponse>) => r.body as AttachedFileResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsAttachFilesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsAttachFilesPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AttachFileCommand
  }): Observable<StrictHttpResponse<AttachedFileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsAttachFilesPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AttachedFileResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsAttachFilesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsAttachFilesPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AttachFileCommand
  }): Observable<AttachedFileResponse> {

    return this.activityOutputEventsAttachFilesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AttachedFileResponse>) => r.body as AttachedFileResponse)
    );
  }

  /**
   * Path part for operation activityOutputEventsDeleteFilesPost
   */
  static readonly ActivityOutputEventsDeleteFilesPostPath = '/activity-output-events/delete-files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsDeleteFilesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsDeleteFilesPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: DeleteFilesCommand
  }): Observable<StrictHttpResponse<DeletedFilesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsDeleteFilesPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeletedFilesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsDeleteFilesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsDeleteFilesPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: DeleteFilesCommand
  }): Observable<DeletedFilesResponse> {

    return this.activityOutputEventsDeleteFilesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DeletedFilesResponse>) => r.body as DeletedFilesResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsDeleteFilesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsDeleteFilesPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: DeleteFilesCommand
  }): Observable<StrictHttpResponse<DeletedFilesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityOutputEventsService.ActivityOutputEventsDeleteFilesPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeletedFilesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsDeleteFilesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsDeleteFilesPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: DeleteFilesCommand
  }): Observable<DeletedFilesResponse> {

    return this.activityOutputEventsDeleteFilesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DeletedFilesResponse>) => r.body as DeletedFilesResponse)
    );
  }

}
