import { Injectable } from '@angular/core';
import { RecentSearch } from 'bpt-ui-library/bpt-search';
import { BptIconDictionary, BptSizes } from 'bpt-ui-library/shared';
import { BehaviorSubject, map, Observable, of, Subject, tap } from 'rxjs';
import { UserPreferenceService } from '../.../../../api/services';
import {
  TemplateType as searchTemplateType,
  TemplateSummary
} from '../.../../../api/search/models';
import {
  TemplateType as coreServicesTemplateType,
  UserPreference} from '../.../../../api/models';
import { TemplateService } from '../.../../../api/search/services';
import { TemplateListPresentationHelper } from '../models/template-list-presentation-helper';
import { TemplateSearchCommand } from '../models/template-search-command.interface';

import { UserService } from '../.../../../services/user.service';
import * as uuid from 'uuid';
import { isEmpty } from 'lodash-es';
import { DateAndInstantFormat, formatInstant } from '../../shared/date-time-helpers';

@Injectable()
export class TemplateLoaderService {
  private leastTemplateTypes: string[] = [];
  private favouriteTemplatePreferences: UserPreference[] = [];
  public static readonly favoriteTemplateUserPreferenceKey: string = 'eln-favorite-templates';
  public templateSearchResultSource: Subject<RecentSearch[]> = new Subject<RecentSearch[]>();
  private readonly templateSearchResultStore: BehaviorSubject<RecentSearch[]> = new BehaviorSubject<
    RecentSearch[]
  >([]);
  private recentTemplateSummaryList?: TemplateSummary[];
  constructor(
    private readonly templateSearchService: TemplateService,
    private readonly userPreferenceApiService: UserPreferenceService,
    private readonly userService: UserService,
  ) {
    this.subscribeToTemplateSearchResults();
  }

  flushSubscribers(): void {
    this.templateSearchResultSource = new Subject<RecentSearch[]>();
    this.subscribeToTemplateSearchResults();
  }

  private subscribeToTemplateSearchResults(): void {
    this.templateSearchResultSource.subscribe((result) => {
      this.templateSearchResultStore.next(result);
    });
  }

  public restoreSearchResults() {
    let suggestions: RecentSearch[] = [];
    const subscription = this.templateSearchResultStore.subscribe((recentResults) => {
      suggestions = recentResults;
    });
    subscription.unsubscribe();
    this.templateSearchResultSource.next(suggestions);
  }

  leastTemplateTypesToFilter(templateTypes: string[]): void {
    this.leastTemplateTypes = templateTypes;
  }

  refreshTemplatesOnly(searchCriteria: TemplateSearchCommand): void {
    const isValid = this.validateSearchCriteria(searchCriteria);
    if (!isValid) {
      // Complete the request
      this.templateSearchResultSource.next([]);
      return;
    }
    this.fetchTemplatesOnly(searchCriteria);
  }

  markTemplateAsFavorite(id: string): Observable<boolean> {
    if (this.isFavoriteTemplate(id)) {
      return of(true);
    }
    return this.addPreferenceToFavoriteTemplates(id);
  }

  removeFavoriteTemplate(id: string): Observable<boolean> {
    const userPreferenceIdIndex = this.favouriteTemplatePreferences.findIndex(
      (preference) => preference.userPreferenceValue === id
    );
    if (userPreferenceIdIndex >= 0) {
      const preferenceId =
        this.favouriteTemplatePreferences[userPreferenceIdIndex].userPreferenceId;
      if (!this.favouriteTemplatePreferences) {
        return of(false);
      }
      return this.deletePreference(preferenceId);
    } else {
      return of(false);
    }
  }

  private fetchTemplatesOnly(searchCriteria: TemplateSearchCommand) {
    this.templateSearchService
      .templatesTemplatesListGet$Json(searchCriteria)
      .pipe(
        tap(response => this.recentTemplateSummaryList = response),
        map((response) => this.mapTemplateSearchResultsToRecentSearch(response)),
        tap((finalizedTemplateList) => this.templateSearchResultSource.next(finalizedTemplateList))
      )
      .subscribe({
        error: () => {
          this.templateSearchResultSource.next([]);
        }
      });
  }

  public fetchFavoriteTemplates(): void {
    this.userPreferenceApiService
      .userPreferencesUserPreferenceKeyGet$Json({
        userPreferenceKey: TemplateLoaderService.favoriteTemplateUserPreferenceKey
      })
      .subscribe({
        next: (data) => {
          this.favouriteTemplatePreferences = data.userPreferences;
          this.syncFavoritesToTemplateList();
        }
      });
  }

  private syncFavoritesToTemplateList(): void{
    if(this.recentTemplateSummaryList){
      this.templateSearchResultSource.next(this.mapTemplateSearchResultsToRecentSearch(this.recentTemplateSummaryList));
    }
  }

  private validateSearchCriteria(searchCommand: TemplateSearchCommand): boolean {
    return this.validateSearchCriteriaToTemplateTypes(searchCommand.templateTypes as string);
  }

  private validateSearchCriteriaToTemplateTypes(templateTypesCriteria: string): boolean {
    if (this.leastTemplateTypes.length === 0) {
      return true;
    }
    if (!templateTypesCriteria || templateTypesCriteria.length === 0) {
      return false;
    }
    const searchCriterialTemplateTypes = templateTypesCriteria.split(',');
    return this.leastTemplateTypes.some((templateType) =>
      searchCriterialTemplateTypes.includes(templateType)
    );
  }

  private deletePreference(preferenceId: string): Observable<boolean> {
    const acknowledgement = new Subject<boolean>();
    this.userPreferenceApiService
      .userPreferencesUserPreferenceIdDelete$Json({
        userPreferenceId: preferenceId
      })
      .subscribe(() => {
        acknowledgement.next(true);
        this.favouriteTemplatePreferences = this.favouriteTemplatePreferences.filter(
          (preference) => preference.userPreferenceId !== preferenceId
        );
      });
    return acknowledgement.asObservable();
  }

  private addPreferenceToFavoriteTemplates(id: string): Observable<boolean> {
    const acknowledgement = new Subject<boolean>();
    this.userPreferenceApiService
      .userPreferencesSaveUserPreferencePost$Json({
        body: this.getDefaultUserPreferenceOfFavoriteTemplate(id)
      })
      .subscribe((result) => {
        this.favouriteTemplatePreferences.push(result.userPreference!);
        acknowledgement.next(true);
      });
    return acknowledgement.asObservable();
  }

  private isFavoriteTemplate(id: string): boolean {
    if (isEmpty(this.favouriteTemplatePreferences)) {
      return false;
    } else {
      return this.favouriteTemplatePreferences.some(
        (preference) => preference.userPreferenceValue === id
      );
    }
  }

  private getDefaultUserPreferenceOfFavoriteTemplate(id: string): UserPreference {
    return {
      userPuid: this.userService.currentUser.puid,
      userPreferenceId: uuid.v4(),
      userPreferenceKey: TemplateLoaderService.favoriteTemplateUserPreferenceKey,
      userPreferenceName: 'ELN user favorite templates',
      userPreferenceValue: id,
      isDefault: false
    };
  }

  private orderTheTemplates(templatesSummary: TemplateSummary[]): TemplateSummary[] {
    const favorites: TemplateSummary[] = [];
    const orderedTemplates: TemplateSummary[] = [];
    for (let index = 0; index < templatesSummary.length; index++) {
      const template = templatesSummary.slice(index, index + 1);
      if (this.isFavoriteTemplate(template[0].id)) {
        favorites.unshift(template[0]);
      } else {
        orderedTemplates.unshift(template[0]);
      }
    }
    this.sortTemplates(orderedTemplates);
    this.sortTemplates(favorites);
    orderedTemplates.unshift(...favorites);
    return orderedTemplates;
  }

  private sortTemplates(templatesSummary: TemplateSummary[]): void {
    templatesSummary.sort((lt, rt) => lt.number.localeCompare(rt.number));
  }

  private mapTemplateSearchResultsToRecentSearch(
    templatesSummary: TemplateSummary[]
  ): RecentSearch[] {
    templatesSummary = this.orderTheTemplates(templatesSummary);
    return templatesSummary.map((summary) => {
      return {
        id: 0,
        text: summary.name,
        groupingFieldsData: {
          isCore: summary.isCore,
          id: summary.id,
          templateNumber: summary.number,
          templateType: TemplateLoaderService.convertTemplateType(summary.templateType)
        },
        subText:
          summary.number +
          $localize` Version: V` +
          summary.version +
          $localize` Edited on: ` +
          formatInstant(summary.templateManagement.lastEditedOn, DateAndInstantFormat.date),
        selectedValue: summary.name + $localize`:@@version:(Version ${summary.version})`,
        leadingIcon: TemplateListPresentationHelper.getTemplateTypeDisplayIconDefinition(
          summary.templateType
        ),
        tailIcons: [
          {
            ...(this.isFavoriteTemplate(summary.id)
              ? BptIconDictionary.StarFill
              : BptIconDictionary.Star),
            size: { iconSize: BptSizes.Small },
            styleClass: 'primary-action-color',
            id: summary.id
          }
        ]
      };
    });
  }

  private static convertTemplateType(templateType: searchTemplateType): coreServicesTemplateType {
    switch (templateType) {
      case searchTemplateType.Activity:
        return coreServicesTemplateType.Activity;
      case searchTemplateType.Module:
        return coreServicesTemplateType.Module;
      case searchTemplateType.Table:
        return coreServicesTemplateType.Table;
      case searchTemplateType.Form:
        return coreServicesTemplateType.Form;
    }
    return coreServicesTemplateType.Invalid;
  }
}
