import { Injectable } from '@angular/core';
import { CustomerAnalyticalProject } from '../api/models';
import { CustomerAnalyticalProjectService } from '../api/services';
import { Subject } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectLogLoaderService {
  public isDataLoaded = new Subject<boolean>();
  private readonly _loadedResult: {
    readonly clientLinkedProjects: { [clientId: string]: string[] };
    readonly clients: { label: string; value: string }[];
    readonly projects: { label: string; value: string }[];
  } = {
    clientLinkedProjects: {},
    clients: [],
    projects: []
  };

  constructor(private readonly customerAnalyticalProjectService: CustomerAnalyticalProjectService,
    private readonly userService: UserService) {
    this.loadProjectLogs();
  }

  private loadProjectLogs() {
    const labsiteCode=this.userService.currentUser.labSiteCode?? "";
    this.customerAnalyticalProjectService
      .customerAnalyticalProjectsLabsiteCodeGet$Json({labsiteCode: labsiteCode})
      .subscribe({
        next: (response) => {
          response.forEach((record: CustomerAnalyticalProject) => {
            this._loadedResult.clientLinkedProjects[record.clientId] = this.handleKeyValueEntry(
              this._loadedResult.clientLinkedProjects[record.clientId],
              record.customerAnalyticalProjectId
            );
            this.getClient(record.clientId) ??
              this._loadedResult.clients.push({
                label: record.clientName,
                value: record.clientId
              });
            this.getProject(record.customerAnalyticalProjectId) ??
              this._loadedResult.projects.push({
                label: record.customerAnalyticalProjectName,
                value: record.customerAnalyticalProjectId
              });
            });
            this.isDataLoaded.next(true);
        },
        error: (e) => {
          console.log(e);
          this.isDataLoaded.next(false);
        }
      });
  }

  private handleKeyValueEntry(obj: any[], value: any): any {
    if (!obj) {
      obj = [value];
    } else {
      obj.push(value);
    }
    return obj;
  }

  public getClient(clientId: string): { label: string; value: string } | undefined {
    return this._loadedResult.clients.find((client) => client.value === clientId);
  }

  public getProject(projectId: string): { label: string; value: string } | undefined {
    return this._loadedResult.projects.find((project) => project.value === projectId);
  }

  private getProjects(projectIds: string[]): { label: string; value: string }[] {
    return !!projectIds? this._loadedResult.projects.filter((project) => projectIds.includes(project.value)) : [];
  }

  /**
   * 
   * @param clientIds List of client id's which are selected under client dropdown
   * @returns List of valid projects for given client id's
   */
  public fetchProjectsLinkedToClients(clientIds: string[]): { label: string; value: string }[] {
    var result: { label: string; value: string }[] = [];
    !!clientIds && clientIds.length > 0 &&
    clientIds.filter((clientId) => !!clientId).forEach((clientId) => {
      result = result.concat(this.getProjects(this._loadedResult.clientLinkedProjects[clientId]));
    });
    return result;
  }

  /**
   * 
   * @returns A list of client options to be populated under client dropdown
   */
  public getAllClients(): { label: string; value: string }[] {
    return this._loadedResult.clients;
  }

  /**
   * 
   * @param projectIds Corresponds to already selected projects from project dropdown
   * @param clientIds Corresponds to the clients selected from clients dropdown
   * @returns A list of valid projectId selections
   */
  public filterOutInvalidProjectSelections(projectIds: string[], clientIds: string[]): string[] {
    const result = [
      ...new Set(
        this.fetchProjectsLinkedToClients(clientIds)
          .map((project) => project.value)
          .filter((projectId) => projectIds.includes(projectId))
      )
    ];
    return result ?? [];
  }
}
