import { Component, OnDestroy, OnInit } from '@angular/core';
import { PreparationService } from '../services/preparation.service';
import { ContainerDescription } from '../models/preparation.model';
import { StringTypeDictionaryValue, ValueState, ValueType } from '../../api/data-entry/models';
import { Subscription } from 'rxjs';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { PreparationEventService } from '../services/preparation-event.service';
@Component({
  selector: 'app-preparation-container-description-builder',
  templateUrl: './preparation-container-description-builder.component.html',
  styleUrls: ['./preparation-container-description-builder.component.scss']
})
export class PreparationContainerDescriptionBuilderComponent implements OnInit, OnDestroy {
  public isReadOnly = false;
  constructor(private readonly preparationService: PreparationService,
    private readonly preparationEventService: PreparationEventService) { }

  sliderOptions = {
    size: 'small',
    visible: false,
    closeOnEscape: false,
    headerText: $localize`:@@OpenContainerDescription:Container Description`,
    isFooterSticky: false,
    displayFooter: false,
    displayFooterWithPrimaryButton: false,
    displayFooterWithSecondaryButton: false,
    isModal: true
  };
  descModels: ContainerDescription = {
  };
  optionsForColor: Array<{ label: string, value: string }> = [];
  optionsForMaterial: Array<{ label: string, value: string }> = [];
  optionsForType: Array<{ label: string, value: string }> = [];
  optionsForExposure: Array<{ label: string, value: string }> = [];
  subscriptions: Subscription[] = [];

  ngOnInit() {
    this.bindContainerDescription()
    this.populateDropDownsFromPickLists()
    this.subscriptions.push(this.preparationEventService.getSliderObservable().subscribe((isVisible: boolean) => {
      this.sliderOptions.visible = isVisible;
    }));
  }

  /**
   * Binds the container description with appropriate selected values from the create preparation slider
   */
  bindContainerDescription() {
    this.subscriptions.push(this.preparationEventService.getContainerDescriptionModel().subscribe(descModel => {
      this.isReadOnly = this.preparationService.isSliderContentDisabled();
      this.descModels = {};  
      if (descModel?.value) {
          this.descModels = {
            color: descModel?.value['color'],
            exposure: descModel?.value['exposure'],
            type: descModel?.value['type'],
            material: descModel?.value['material'],
          };
        }
    }));
  }

  /**
   * Populates all the dropdowns in the container description slider
   */
  populateDropDownsFromPickLists() {
    const pickLists = this.preparationService.getPickListsForPreparation();
    this.subscriptions.push(pickLists.color.subscribe((colorData) => {
      this.optionsForColor = colorData.items;
    }));
    this.subscriptions.push(pickLists.material.subscribe((materialData) => {
      this.optionsForMaterial = materialData.items;
    }));
    this.subscriptions.push(pickLists.type.subscribe((typeData) => {
      this.optionsForType = typeData.items;
    }));
    this.subscriptions.push(pickLists.exposure.subscribe((exposureData) => {
      this.optionsForExposure = exposureData.items;
    }));

  }

  /**
   * Commits the selected container description and sends them to create preparations slider
   */
  commitContainerDescription() {
  if(!this.isReadOnly) {
    let containerDescription 
    const descriptionValues: string[] = [];
    Object.values(this.descModels).forEach((model) => {
      if (model?.trim()) {
        descriptionValues.push(model);
      }
    });
    if (descriptionValues.length > 0) {
      containerDescription = this.getContainerDescription();
    }
    else {
      containerDescription = {
        type: ValueType.StringDictionary,
        state: ValueState.NotApplicable,
        value: {}
      }
    }
    this.preparationEventService.preparationContainerDescriptionChangedEvent(containerDescription);
    this.sliderOptions.visible = false;
  }
  }

  /**
   * Gets the Container description 
   */
  private getContainerDescription(): StringTypeDictionaryValue {
    return {
      type: ValueType.StringDictionary,
      state: ValueState.Set,
      value: {
        color: this.descModels.color ? this.descModels.color : '',
        material: this.descModels.material ? this.descModels.material : '',
        type: this.descModels.type ? this.descModels.type : '',
        exposure: this.descModels.exposure ? this.descModels.exposure : ''
      }
    }
  }

  /**
   * Cancels the container description changes
   */
  cancelContainerDescription() {
    this.sliderOptions.visible = false;
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }
}
