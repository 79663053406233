/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ActivityNodesResponse } from '../models/activity-nodes-response';
import { ActivityOutputChromatographyResultSet } from '../models/activity-output-chromatography-result-set';
import { ExperimentResponse } from '../models/experiment-response';

@Injectable({
  providedIn: 'root',
})
export class ExperimentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation experimentNodesExperimentNumberGet
   */
  static readonly ExperimentNodesExperimentNumberGetPath = '/experiment-nodes/{experimentNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentNumberGet$Plain$Response(params: {
    experimentNumber: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesExperimentNumberGetPath, 'get');
    if (params) {
      rb.path('experimentNumber', params.experimentNumber, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentNumberGet$Plain(params: {
    experimentNumber: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentResponse> {

    return this.experimentNodesExperimentNumberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>) => r.body as ExperimentResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentNumberGet$Json$Response(params: {
    experimentNumber: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesExperimentNumberGetPath, 'get');
    if (params) {
      rb.path('experimentNumber', params.experimentNumber, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentNumberGet$Json(params: {
    experimentNumber: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentResponse> {

    return this.experimentNodesExperimentNumberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>) => r.body as ExperimentResponse)
    );
  }

  /**
   * Path part for operation experimentNodesSummaryExperimentNumberGet
   */
  static readonly ExperimentNodesSummaryExperimentNumberGetPath = '/experiment-nodes/summary/{experimentNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesSummaryExperimentNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberGet$Plain$Response(params: {
    experimentNumber: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesSummaryExperimentNumberGetPath, 'get');
    if (params) {
      rb.path('experimentNumber', params.experimentNumber, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesSummaryExperimentNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberGet$Plain(params: {
    experimentNumber: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentResponse> {

    return this.experimentNodesSummaryExperimentNumberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>) => r.body as ExperimentResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesSummaryExperimentNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberGet$Json$Response(params: {
    experimentNumber: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesSummaryExperimentNumberGetPath, 'get');
    if (params) {
      rb.path('experimentNumber', params.experimentNumber, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesSummaryExperimentNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberGet$Json(params: {
    experimentNumber: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentResponse> {

    return this.experimentNodesSummaryExperimentNumberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>) => r.body as ExperimentResponse)
    );
  }

  /**
   * Path part for operation experimentNodesSummaryExperimentNumberActivityActivityIdGet
   */
  static readonly ExperimentNodesSummaryExperimentNumberActivityActivityIdGetPath = '/experiment-nodes/summary/{experimentNumber}/activity/{activityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain$Response(params: {
    experimentNumber: string;
    activityId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesSummaryExperimentNumberActivityActivityIdGetPath, 'get');
    if (params) {
      rb.path('experimentNumber', params.experimentNumber, {});
      rb.path('activityId', params.activityId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain(params: {
    experimentNumber: string;
    activityId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentResponse> {

    return this.experimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>) => r.body as ExperimentResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesSummaryExperimentNumberActivityActivityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberActivityActivityIdGet$Json$Response(params: {
    experimentNumber: string;
    activityId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesSummaryExperimentNumberActivityActivityIdGetPath, 'get');
    if (params) {
      rb.path('experimentNumber', params.experimentNumber, {});
      rb.path('activityId', params.activityId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesSummaryExperimentNumberActivityActivityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberActivityActivityIdGet$Json(params: {
    experimentNumber: string;
    activityId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentResponse> {

    return this.experimentNodesSummaryExperimentNumberActivityActivityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>) => r.body as ExperimentResponse)
    );
  }

  /**
   * Path part for operation experimentNodesExperimentIdActivityNodesActivityIdGet
   */
  static readonly ExperimentNodesExperimentIdActivityNodesActivityIdGetPath = '/experiment-nodes/{experimentId}/activity-nodes/{activityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdActivityNodesActivityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdActivityNodesActivityIdGet$Plain$Response(params: {
    experimentId: string;
    activityId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ActivityNodesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesExperimentIdActivityNodesActivityIdGetPath, 'get');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('activityId', params.activityId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityNodesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdActivityNodesActivityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdActivityNodesActivityIdGet$Plain(params: {
    experimentId: string;
    activityId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ActivityNodesResponse> {

    return this.experimentNodesExperimentIdActivityNodesActivityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ActivityNodesResponse>) => r.body as ActivityNodesResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdActivityNodesActivityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdActivityNodesActivityIdGet$Json$Response(params: {
    experimentId: string;
    activityId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ActivityNodesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesExperimentIdActivityNodesActivityIdGetPath, 'get');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('activityId', params.activityId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityNodesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdActivityNodesActivityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdActivityNodesActivityIdGet$Json(params: {
    experimentId: string;
    activityId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ActivityNodesResponse> {

    return this.experimentNodesExperimentIdActivityNodesActivityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ActivityNodesResponse>) => r.body as ActivityNodesResponse)
    );
  }

  /**
   * Path part for operation experimentNodesByIdExperimentIdGet
   */
  static readonly ExperimentNodesByIdExperimentIdGetPath = '/experiment-nodes/by-id/{experimentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesByIdExperimentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesByIdExperimentIdGet$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesByIdExperimentIdGetPath, 'get');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesByIdExperimentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesByIdExperimentIdGet$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentResponse> {

    return this.experimentNodesByIdExperimentIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>) => r.body as ExperimentResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesByIdExperimentIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesByIdExperimentIdGet$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ExperimentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesByIdExperimentIdGetPath, 'get');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesByIdExperimentIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesByIdExperimentIdGet$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ExperimentResponse> {

    return this.experimentNodesByIdExperimentIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>) => r.body as ExperimentResponse)
    );
  }

  /**
   * Path part for operation experimentNodesExperimentIdAmICollaboratorGet
   */
  static readonly ExperimentNodesExperimentIdAmICollaboratorGetPath = '/experiment-nodes/{experimentId}/am-i-collaborator';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdAmICollaboratorGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdAmICollaboratorGet$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesExperimentIdAmICollaboratorGetPath, 'get');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdAmICollaboratorGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdAmICollaboratorGet$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<boolean> {

    return this.experimentNodesExperimentIdAmICollaboratorGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdAmICollaboratorGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdAmICollaboratorGet$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesExperimentIdAmICollaboratorGetPath, 'get');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdAmICollaboratorGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdAmICollaboratorGet$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<boolean> {

    return this.experimentNodesExperimentIdAmICollaboratorGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet
   */
  static readonly ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGetPath = '/experiment-nodes/{experimentId}/chromatography-result-sets/{resultSetInstanceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain$Response(params: {
    experimentId: string;
    resultSetInstanceId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ActivityOutputChromatographyResultSet>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGetPath, 'get');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('resultSetInstanceId', params.resultSetInstanceId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityOutputChromatographyResultSet>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain(params: {
    experimentId: string;
    resultSetInstanceId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ActivityOutputChromatographyResultSet> {

    return this.experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ActivityOutputChromatographyResultSet>) => r.body as ActivityOutputChromatographyResultSet)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json$Response(params: {
    experimentId: string;
    resultSetInstanceId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<ActivityOutputChromatographyResultSet>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGetPath, 'get');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('resultSetInstanceId', params.resultSetInstanceId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityOutputChromatographyResultSet>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json(params: {
    experimentId: string;
    resultSetInstanceId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<ActivityOutputChromatographyResultSet> {

    return this.experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ActivityOutputChromatographyResultSet>) => r.body as ActivityOutputChromatographyResultSet)
    );
  }

  /**
   * Path part for operation experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet
   */
  static readonly ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGetPath = '/experiment-nodes/{experimentId}/chromatography-result-sets/{resultSetInstanceId}/result-ids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain$Response(params: {
    experimentId: string;
    resultSetInstanceId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGetPath, 'get');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('resultSetInstanceId', params.resultSetInstanceId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain(params: {
    experimentId: string;
    resultSetInstanceId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<Array<string>> {

    return this.experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json$Response(params: {
    experimentId: string;
    resultSetInstanceId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentService.ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGetPath, 'get');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('resultSetInstanceId', params.resultSetInstanceId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json(params: {
    experimentId: string;
    resultSetInstanceId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<Array<string>> {

    return this.experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

}
