/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddRecipeRowCommand } from '../models/add-recipe-row-command';
import { AddRecipeRowResponse } from '../models/add-recipe-row-response';
import { CancelRecipeCommand } from '../models/cancel-recipe-command';
import { CancelRecipeResponse } from '../models/cancel-recipe-response';
import { ChangeRecipeAdditionalNotesCommand } from '../models/change-recipe-additional-notes-command';
import { ChangeRecipeAdditionalNotesResponse } from '../models/change-recipe-additional-notes-response';
import { ChangeRecipeAnalysisTechniqueCommand } from '../models/change-recipe-analysis-technique-command';
import { ChangeRecipeAnalysisTechniqueResponse } from '../models/change-recipe-analysis-technique-response';
import { ChangeRecipeApprovalRequiredCommand } from '../models/change-recipe-approval-required-command';
import { ChangeRecipeApprovalRequiredResponse } from '../models/change-recipe-approval-required-response';
import { ChangeRecipeApprovedCommand } from '../models/change-recipe-approved-command';
import { ChangeRecipeApprovedResponse } from '../models/change-recipe-approved-response';
import { ChangeRecipeApproversCommand } from '../models/change-recipe-approvers-command';
import { ChangeRecipeApproversResponse } from '../models/change-recipe-approvers-response';
import { ChangeRecipeAvailabilityCommand } from '../models/change-recipe-availability-command';
import { ChangeRecipeAvailabilityResponse } from '../models/change-recipe-availability-response';
import { ChangeRecipeCellCommand } from '../models/change-recipe-cell-command';
import { ChangeRecipeCellResponse } from '../models/change-recipe-cell-response';
import { ChangeRecipeClientsCommand } from '../models/change-recipe-clients-command';
import { ChangeRecipeClientsResponse } from '../models/change-recipe-clients-response';
import { ChangeRecipeCompendiaCommand } from '../models/change-recipe-compendia-command';
import { ChangeRecipeCompendiaResponse } from '../models/change-recipe-compendia-response';
import { ChangeRecipeConsumingLabsitesCommand } from '../models/change-recipe-consuming-labsites-command';
import { ChangeRecipeConsumingLabsitesResponse } from '../models/change-recipe-consuming-labsites-response';
import { ChangeRecipeEditorsCommand } from '../models/change-recipe-editors-command';
import { ChangeRecipeEditorsResponse } from '../models/change-recipe-editors-response';
import { ChangeRecipeFieldCommand } from '../models/change-recipe-field-command';
import { ChangeRecipeFieldResponse } from '../models/change-recipe-field-response';
import { ChangeRecipeMethodCommand } from '../models/change-recipe-method-command';
import { ChangeRecipeMethodResponse } from '../models/change-recipe-method-response';
import { ChangeRecipeNameCommand } from '../models/change-recipe-name-command';
import { ChangeRecipeNameResponse } from '../models/change-recipe-name-response';
import { ChangeRecipeNodeReOrderCommand } from '../models/change-recipe-node-re-order-command';
import { ChangeRecipeNodeTitleCommand } from '../models/change-recipe-node-title-command';
import { ChangeRecipePreLoadDataTransformOptionsCommand } from '../models/change-recipe-pre-load-data-transform-options-command';
import { ChangeRecipePreLoadDataTransformOptionsResponse } from '../models/change-recipe-pre-load-data-transform-options-response';
import { ChangeRecipeProjectsCommand } from '../models/change-recipe-projects-command';
import { ChangeRecipeProjectsResponse } from '../models/change-recipe-projects-response';
import { ChangeRecipePurposeCommand } from '../models/change-recipe-purpose-command';
import { ChangeRecipePurposeResponse } from '../models/change-recipe-purpose-response';
import { ChangeRecipeReviewStatusCommand } from '../models/change-recipe-review-status-command';
import { ChangeRecipeReviewStatusResponse } from '../models/change-recipe-review-status-response';
import { ChangeRecipeSatisfiesPurposeCommand } from '../models/change-recipe-satisfies-purpose-command';
import { ChangeRecipeSatisfiesPurposeResponse } from '../models/change-recipe-satisfies-purpose-response';
import { ChangeRecipeSubBusinessUnitsCommand } from '../models/change-recipe-sub-business-units-command';
import { ChangeRecipeSubBusinessUnitsResponse } from '../models/change-recipe-sub-business-units-response';
import { ChangeRecipeTagsCommand } from '../models/change-recipe-tags-command';
import { ChangeRecipeTagsResponse } from '../models/change-recipe-tags-response';
import { ChangeRecipeVerifiedCommand } from '../models/change-recipe-verified-command';
import { ChangeRecipeVerifiersCommand } from '../models/change-recipe-verifiers-command';
import { ChangeRecipeVerifiersResponse } from '../models/change-recipe-verifiers-response';
import { ChangeVerifiedRecipeResponse } from '../models/change-verified-recipe-response';
import { CopiedRecipeResponse } from '../models/copied-recipe-response';
import { CopyRecipeCommand } from '../models/copy-recipe-command';
import { CreateRecipeCommand } from '../models/create-recipe-command';
import { CreateRecipeResponse } from '../models/create-recipe-response';
import { RecipeAddTemplateCommand } from '../models/recipe-add-template-command';
import { RecipeChangeToPendingApprovalCommand } from '../models/recipe-change-to-pending-approval-command';
import { RecipeChangeToPendingApprovalResponse } from '../models/recipe-change-to-pending-approval-response';
import { RecipeChangeToPendingVerificationCommand } from '../models/recipe-change-to-pending-verification-command';
import { RecipeChangeToPendingVerificationResponse } from '../models/recipe-change-to-pending-verification-response';
import { RecipeDeleteTemplateCommand } from '../models/recipe-delete-template-command';
import { RecipeNodeOrderChangedResponse } from '../models/recipe-node-order-changed-response';
import { RecipeNodeTitleChangedResponse } from '../models/recipe-node-title-changed-response';
import { RecipePublishedCommand } from '../models/recipe-published-command';
import { RecipePublishedResponse } from '../models/recipe-published-response';
import { RecipeReturnToInDraftCommand } from '../models/recipe-return-to-in-draft-command';
import { RecipeReturnToInDraftResponse } from '../models/recipe-return-to-in-draft-response';
import { RecipeTemplateAddedResponse } from '../models/recipe-template-added-response';
import { RecipeTemplateDeletedResponse } from '../models/recipe-template-deleted-response';
import { RemoveRecipeRowCommand } from '../models/remove-recipe-row-command';
import { RemoveRecipeRowResponse } from '../models/remove-recipe-row-response';
import { RenumberRecipeRowsCommand } from '../models/renumber-recipe-rows-command';
import { RenumberRecipeRowsResponse } from '../models/renumber-recipe-rows-response';
import { RestoreRecipeCommand } from '../models/restore-recipe-command';
import { RestoreRecipeResponse } from '../models/restore-recipe-response';
import { RestoreRecipeRowCommand } from '../models/restore-recipe-row-command';
import { RestoreRecipeRowResponse } from '../models/restore-recipe-row-response';
import { RetireRecipeCommand } from '../models/retire-recipe-command';
import { RetireRecipeResponse } from '../models/retire-recipe-response';
import { SetVariableCommand } from '../models/set-variable-command';
import { SetVariableResponse } from '../models/set-variable-response';
import { StartNewVersionCommand } from '../models/start-new-version-command';
import { StartNewVersionResponse } from '../models/start-new-version-response';

@Injectable({
  providedIn: 'root',
})
export class RecipeEventsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation recipeEventsCreateRecipePost
   */
  static readonly RecipeEventsCreateRecipePostPath = '/recipe-events/create-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsCreateRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCreateRecipePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: CreateRecipeCommand
  }): Observable<StrictHttpResponse<CreateRecipeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsCreateRecipePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateRecipeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsCreateRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCreateRecipePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: CreateRecipeCommand
  }): Observable<CreateRecipeResponse> {

    return this.recipeEventsCreateRecipePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreateRecipeResponse>) => r.body as CreateRecipeResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsCreateRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCreateRecipePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: CreateRecipeCommand
  }): Observable<StrictHttpResponse<CreateRecipeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsCreateRecipePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateRecipeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsCreateRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCreateRecipePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: CreateRecipeCommand
  }): Observable<CreateRecipeResponse> {

    return this.recipeEventsCreateRecipePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreateRecipeResponse>) => r.body as CreateRecipeResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeNamePost
   */
  static readonly RecipeEventsChangeRecipeNamePostPath = '/recipe-events/change-recipe-name';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeNamePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeNamePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeNameCommand
  }): Observable<StrictHttpResponse<ChangeRecipeNameResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeNamePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeNameResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeNamePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeNamePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeNameCommand
  }): Observable<ChangeRecipeNameResponse> {

    return this.recipeEventsChangeRecipeNamePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeNameResponse>) => r.body as ChangeRecipeNameResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeNamePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeNamePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeNameCommand
  }): Observable<StrictHttpResponse<ChangeRecipeNameResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeNamePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeNameResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeNamePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeNamePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeNameCommand
  }): Observable<ChangeRecipeNameResponse> {

    return this.recipeEventsChangeRecipeNamePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeNameResponse>) => r.body as ChangeRecipeNameResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeEditorsPost
   */
  static readonly RecipeEventsChangeRecipeEditorsPostPath = '/recipe-events/change-recipe-editors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeEditorsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeEditorsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeEditorsCommand
  }): Observable<StrictHttpResponse<ChangeRecipeEditorsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeEditorsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeEditorsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeEditorsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeEditorsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeEditorsCommand
  }): Observable<ChangeRecipeEditorsResponse> {

    return this.recipeEventsChangeRecipeEditorsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeEditorsResponse>) => r.body as ChangeRecipeEditorsResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeEditorsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeEditorsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeEditorsCommand
  }): Observable<StrictHttpResponse<ChangeRecipeEditorsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeEditorsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeEditorsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeEditorsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeEditorsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeEditorsCommand
  }): Observable<ChangeRecipeEditorsResponse> {

    return this.recipeEventsChangeRecipeEditorsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeEditorsResponse>) => r.body as ChangeRecipeEditorsResponse)
    );
  }

  /**
   * Path part for operation recipeEventsCancelRecipePost
   */
  static readonly RecipeEventsCancelRecipePostPath = '/recipe-events/cancel-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsCancelRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCancelRecipePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: CancelRecipeCommand
  }): Observable<StrictHttpResponse<CancelRecipeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsCancelRecipePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CancelRecipeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsCancelRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCancelRecipePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: CancelRecipeCommand
  }): Observable<CancelRecipeResponse> {

    return this.recipeEventsCancelRecipePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CancelRecipeResponse>) => r.body as CancelRecipeResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsCancelRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCancelRecipePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: CancelRecipeCommand
  }): Observable<StrictHttpResponse<CancelRecipeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsCancelRecipePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CancelRecipeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsCancelRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCancelRecipePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: CancelRecipeCommand
  }): Observable<CancelRecipeResponse> {

    return this.recipeEventsCancelRecipePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CancelRecipeResponse>) => r.body as CancelRecipeResponse)
    );
  }

  /**
   * Path part for operation recipeEventsRestoreRecipePost
   */
  static readonly RecipeEventsRestoreRecipePostPath = '/recipe-events/restore-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRestoreRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRecipePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RestoreRecipeCommand
  }): Observable<StrictHttpResponse<RestoreRecipeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRestoreRecipePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RestoreRecipeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRestoreRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRecipePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RestoreRecipeCommand
  }): Observable<RestoreRecipeResponse> {

    return this.recipeEventsRestoreRecipePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RestoreRecipeResponse>) => r.body as RestoreRecipeResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRestoreRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRecipePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RestoreRecipeCommand
  }): Observable<StrictHttpResponse<RestoreRecipeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRestoreRecipePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RestoreRecipeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRestoreRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRecipePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RestoreRecipeCommand
  }): Observable<RestoreRecipeResponse> {

    return this.recipeEventsRestoreRecipePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RestoreRecipeResponse>) => r.body as RestoreRecipeResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeSatisfiesPurposePost
   */
  static readonly RecipeEventsChangeRecipeSatisfiesPurposePostPath = '/recipe-events/change-recipe-satisfies-purpose';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeSatisfiesPurposePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSatisfiesPurposePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeSatisfiesPurposeCommand
  }): Observable<StrictHttpResponse<ChangeRecipeSatisfiesPurposeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeSatisfiesPurposePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeSatisfiesPurposeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeSatisfiesPurposePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSatisfiesPurposePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeSatisfiesPurposeCommand
  }): Observable<ChangeRecipeSatisfiesPurposeResponse> {

    return this.recipeEventsChangeRecipeSatisfiesPurposePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeSatisfiesPurposeResponse>) => r.body as ChangeRecipeSatisfiesPurposeResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeSatisfiesPurposePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSatisfiesPurposePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeSatisfiesPurposeCommand
  }): Observable<StrictHttpResponse<ChangeRecipeSatisfiesPurposeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeSatisfiesPurposePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeSatisfiesPurposeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeSatisfiesPurposePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSatisfiesPurposePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeSatisfiesPurposeCommand
  }): Observable<ChangeRecipeSatisfiesPurposeResponse> {

    return this.recipeEventsChangeRecipeSatisfiesPurposePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeSatisfiesPurposeResponse>) => r.body as ChangeRecipeSatisfiesPurposeResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeReviewPost
   */
  static readonly RecipeEventsChangeRecipeReviewPostPath = '/recipe-events/change-recipe-review';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeReviewPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeReviewPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeReviewStatusCommand
  }): Observable<StrictHttpResponse<ChangeRecipeReviewStatusResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeReviewPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeReviewStatusResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeReviewPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeReviewPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeReviewStatusCommand
  }): Observable<ChangeRecipeReviewStatusResponse> {

    return this.recipeEventsChangeRecipeReviewPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeReviewStatusResponse>) => r.body as ChangeRecipeReviewStatusResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeReviewPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeReviewPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeReviewStatusCommand
  }): Observable<StrictHttpResponse<ChangeRecipeReviewStatusResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeReviewPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeReviewStatusResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeReviewPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeReviewPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeReviewStatusCommand
  }): Observable<ChangeRecipeReviewStatusResponse> {

    return this.recipeEventsChangeRecipeReviewPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeReviewStatusResponse>) => r.body as ChangeRecipeReviewStatusResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeVerifyRecipePost
   */
  static readonly RecipeEventsChangeVerifyRecipePostPath = '/recipe-events/change-verify-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeVerifyRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeVerifyRecipePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeVerifiedCommand
  }): Observable<StrictHttpResponse<ChangeVerifiedRecipeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeVerifyRecipePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeVerifiedRecipeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeVerifyRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeVerifyRecipePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeVerifiedCommand
  }): Observable<ChangeVerifiedRecipeResponse> {

    return this.recipeEventsChangeVerifyRecipePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeVerifiedRecipeResponse>) => r.body as ChangeVerifiedRecipeResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeVerifyRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeVerifyRecipePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeVerifiedCommand
  }): Observable<StrictHttpResponse<ChangeVerifiedRecipeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeVerifyRecipePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeVerifiedRecipeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeVerifyRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeVerifyRecipePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeVerifiedCommand
  }): Observable<ChangeVerifiedRecipeResponse> {

    return this.recipeEventsChangeVerifyRecipePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeVerifiedRecipeResponse>) => r.body as ChangeVerifiedRecipeResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeApprovalRequiredPost
   */
  static readonly RecipeEventsChangeRecipeApprovalRequiredPostPath = '/recipe-events/change-recipe-approval-required';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeApprovalRequiredPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovalRequiredPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeApprovalRequiredCommand
  }): Observable<StrictHttpResponse<ChangeRecipeApprovalRequiredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeApprovalRequiredPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeApprovalRequiredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeApprovalRequiredPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovalRequiredPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeApprovalRequiredCommand
  }): Observable<ChangeRecipeApprovalRequiredResponse> {

    return this.recipeEventsChangeRecipeApprovalRequiredPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeApprovalRequiredResponse>) => r.body as ChangeRecipeApprovalRequiredResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeApprovalRequiredPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovalRequiredPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeApprovalRequiredCommand
  }): Observable<StrictHttpResponse<ChangeRecipeApprovalRequiredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeApprovalRequiredPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeApprovalRequiredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeApprovalRequiredPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovalRequiredPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeApprovalRequiredCommand
  }): Observable<ChangeRecipeApprovalRequiredResponse> {

    return this.recipeEventsChangeRecipeApprovalRequiredPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeApprovalRequiredResponse>) => r.body as ChangeRecipeApprovalRequiredResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipePurposePost
   */
  static readonly RecipeEventsChangeRecipePurposePostPath = '/recipe-events/change-recipe-purpose';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipePurposePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipePurposePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipePurposeCommand
  }): Observable<StrictHttpResponse<ChangeRecipePurposeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipePurposePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipePurposeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipePurposePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipePurposePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipePurposeCommand
  }): Observable<ChangeRecipePurposeResponse> {

    return this.recipeEventsChangeRecipePurposePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipePurposeResponse>) => r.body as ChangeRecipePurposeResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipePurposePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipePurposePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipePurposeCommand
  }): Observable<StrictHttpResponse<ChangeRecipePurposeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipePurposePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipePurposeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipePurposePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipePurposePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipePurposeCommand
  }): Observable<ChangeRecipePurposeResponse> {

    return this.recipeEventsChangeRecipePurposePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipePurposeResponse>) => r.body as ChangeRecipePurposeResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeAdditionalNotesPost
   */
  static readonly RecipeEventsChangeRecipeAdditionalNotesPostPath = '/recipe-events/change-recipe-additional-notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeAdditionalNotesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAdditionalNotesPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeAdditionalNotesCommand
  }): Observable<StrictHttpResponse<ChangeRecipeAdditionalNotesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeAdditionalNotesPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeAdditionalNotesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeAdditionalNotesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAdditionalNotesPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeAdditionalNotesCommand
  }): Observable<ChangeRecipeAdditionalNotesResponse> {

    return this.recipeEventsChangeRecipeAdditionalNotesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeAdditionalNotesResponse>) => r.body as ChangeRecipeAdditionalNotesResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeAdditionalNotesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAdditionalNotesPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeAdditionalNotesCommand
  }): Observable<StrictHttpResponse<ChangeRecipeAdditionalNotesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeAdditionalNotesPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeAdditionalNotesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeAdditionalNotesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAdditionalNotesPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeAdditionalNotesCommand
  }): Observable<ChangeRecipeAdditionalNotesResponse> {

    return this.recipeEventsChangeRecipeAdditionalNotesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeAdditionalNotesResponse>) => r.body as ChangeRecipeAdditionalNotesResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeMethodPost
   */
  static readonly RecipeEventsChangeRecipeMethodPostPath = '/recipe-events/change-recipe-method';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeMethodPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeMethodPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeMethodCommand
  }): Observable<StrictHttpResponse<ChangeRecipeMethodResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeMethodPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeMethodResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeMethodPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeMethodPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeMethodCommand
  }): Observable<ChangeRecipeMethodResponse> {

    return this.recipeEventsChangeRecipeMethodPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeMethodResponse>) => r.body as ChangeRecipeMethodResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeMethodPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeMethodPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeMethodCommand
  }): Observable<StrictHttpResponse<ChangeRecipeMethodResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeMethodPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeMethodResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeMethodPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeMethodPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeMethodCommand
  }): Observable<ChangeRecipeMethodResponse> {

    return this.recipeEventsChangeRecipeMethodPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeMethodResponse>) => r.body as ChangeRecipeMethodResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeAnalysisTechniquePost
   */
  static readonly RecipeEventsChangeRecipeAnalysisTechniquePostPath = '/recipe-events/change-recipe-analysis-technique';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeAnalysisTechniquePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAnalysisTechniquePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeAnalysisTechniqueCommand
  }): Observable<StrictHttpResponse<ChangeRecipeAnalysisTechniqueResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeAnalysisTechniquePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeAnalysisTechniqueResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeAnalysisTechniquePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAnalysisTechniquePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeAnalysisTechniqueCommand
  }): Observable<ChangeRecipeAnalysisTechniqueResponse> {

    return this.recipeEventsChangeRecipeAnalysisTechniquePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeAnalysisTechniqueResponse>) => r.body as ChangeRecipeAnalysisTechniqueResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeAnalysisTechniquePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAnalysisTechniquePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeAnalysisTechniqueCommand
  }): Observable<StrictHttpResponse<ChangeRecipeAnalysisTechniqueResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeAnalysisTechniquePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeAnalysisTechniqueResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeAnalysisTechniquePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAnalysisTechniquePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeAnalysisTechniqueCommand
  }): Observable<ChangeRecipeAnalysisTechniqueResponse> {

    return this.recipeEventsChangeRecipeAnalysisTechniquePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeAnalysisTechniqueResponse>) => r.body as ChangeRecipeAnalysisTechniqueResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeVerifierPost
   */
  static readonly RecipeEventsChangeRecipeVerifierPostPath = '/recipe-events/change-recipe-verifier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeVerifierPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeVerifierPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeVerifiersCommand
  }): Observable<StrictHttpResponse<ChangeRecipeVerifiersResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeVerifierPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeVerifiersResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeVerifierPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeVerifierPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeVerifiersCommand
  }): Observable<ChangeRecipeVerifiersResponse> {

    return this.recipeEventsChangeRecipeVerifierPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeVerifiersResponse>) => r.body as ChangeRecipeVerifiersResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeVerifierPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeVerifierPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeVerifiersCommand
  }): Observable<StrictHttpResponse<ChangeRecipeVerifiersResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeVerifierPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeVerifiersResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeVerifierPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeVerifierPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeVerifiersCommand
  }): Observable<ChangeRecipeVerifiersResponse> {

    return this.recipeEventsChangeRecipeVerifierPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeVerifiersResponse>) => r.body as ChangeRecipeVerifiersResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeApproverPost
   */
  static readonly RecipeEventsChangeRecipeApproverPostPath = '/recipe-events/change-recipe-approver';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeApproverPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApproverPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeApproversCommand
  }): Observable<StrictHttpResponse<ChangeRecipeApproversResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeApproverPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeApproversResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeApproverPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApproverPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeApproversCommand
  }): Observable<ChangeRecipeApproversResponse> {

    return this.recipeEventsChangeRecipeApproverPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeApproversResponse>) => r.body as ChangeRecipeApproversResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeApproverPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApproverPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeApproversCommand
  }): Observable<StrictHttpResponse<ChangeRecipeApproversResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeApproverPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeApproversResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeApproverPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApproverPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeApproversCommand
  }): Observable<ChangeRecipeApproversResponse> {

    return this.recipeEventsChangeRecipeApproverPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeApproversResponse>) => r.body as ChangeRecipeApproversResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeClientPost
   */
  static readonly RecipeEventsChangeRecipeClientPostPath = '/recipe-events/change-recipe-client';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeClientPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeClientPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeClientsCommand
  }): Observable<StrictHttpResponse<ChangeRecipeClientsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeClientPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeClientsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeClientPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeClientPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeClientsCommand
  }): Observable<ChangeRecipeClientsResponse> {

    return this.recipeEventsChangeRecipeClientPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeClientsResponse>) => r.body as ChangeRecipeClientsResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeClientPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeClientPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeClientsCommand
  }): Observable<StrictHttpResponse<ChangeRecipeClientsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeClientPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeClientsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeClientPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeClientPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeClientsCommand
  }): Observable<ChangeRecipeClientsResponse> {

    return this.recipeEventsChangeRecipeClientPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeClientsResponse>) => r.body as ChangeRecipeClientsResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeProjectPost
   */
  static readonly RecipeEventsChangeRecipeProjectPostPath = '/recipe-events/change-recipe-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeProjectPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeProjectPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeProjectsCommand
  }): Observable<StrictHttpResponse<ChangeRecipeProjectsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeProjectPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeProjectsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeProjectPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeProjectPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeProjectsCommand
  }): Observable<ChangeRecipeProjectsResponse> {

    return this.recipeEventsChangeRecipeProjectPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeProjectsResponse>) => r.body as ChangeRecipeProjectsResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeProjectPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeProjectPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeProjectsCommand
  }): Observable<StrictHttpResponse<ChangeRecipeProjectsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeProjectPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeProjectsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeProjectPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeProjectPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeProjectsCommand
  }): Observable<ChangeRecipeProjectsResponse> {

    return this.recipeEventsChangeRecipeProjectPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeProjectsResponse>) => r.body as ChangeRecipeProjectsResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeCompendiaPost
   */
  static readonly RecipeEventsChangeRecipeCompendiaPostPath = '/recipe-events/change-recipe-compendia';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeCompendiaPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeCompendiaPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeCompendiaCommand
  }): Observable<StrictHttpResponse<ChangeRecipeCompendiaResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeCompendiaPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeCompendiaResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeCompendiaPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeCompendiaPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeCompendiaCommand
  }): Observable<ChangeRecipeCompendiaResponse> {

    return this.recipeEventsChangeRecipeCompendiaPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeCompendiaResponse>) => r.body as ChangeRecipeCompendiaResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeCompendiaPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeCompendiaPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeCompendiaCommand
  }): Observable<StrictHttpResponse<ChangeRecipeCompendiaResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeCompendiaPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeCompendiaResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeCompendiaPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeCompendiaPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeCompendiaCommand
  }): Observable<ChangeRecipeCompendiaResponse> {

    return this.recipeEventsChangeRecipeCompendiaPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeCompendiaResponse>) => r.body as ChangeRecipeCompendiaResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeTagPost
   */
  static readonly RecipeEventsChangeRecipeTagPostPath = '/recipe-events/change-recipe-tag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeTagPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeTagPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeTagsCommand
  }): Observable<StrictHttpResponse<ChangeRecipeTagsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeTagPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeTagsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeTagPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeTagPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeTagsCommand
  }): Observable<ChangeRecipeTagsResponse> {

    return this.recipeEventsChangeRecipeTagPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeTagsResponse>) => r.body as ChangeRecipeTagsResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeTagPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeTagPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeTagsCommand
  }): Observable<StrictHttpResponse<ChangeRecipeTagsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeTagPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeTagsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeTagPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeTagPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeTagsCommand
  }): Observable<ChangeRecipeTagsResponse> {

    return this.recipeEventsChangeRecipeTagPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeTagsResponse>) => r.body as ChangeRecipeTagsResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeSubBusinessUnitPost
   */
  static readonly RecipeEventsChangeRecipeSubBusinessUnitPostPath = '/recipe-events/change-recipe-sub-business-unit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeSubBusinessUnitPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSubBusinessUnitPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeSubBusinessUnitsCommand
  }): Observable<StrictHttpResponse<ChangeRecipeSubBusinessUnitsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeSubBusinessUnitPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeSubBusinessUnitsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeSubBusinessUnitPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSubBusinessUnitPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeSubBusinessUnitsCommand
  }): Observable<ChangeRecipeSubBusinessUnitsResponse> {

    return this.recipeEventsChangeRecipeSubBusinessUnitPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeSubBusinessUnitsResponse>) => r.body as ChangeRecipeSubBusinessUnitsResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeSubBusinessUnitPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSubBusinessUnitPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeSubBusinessUnitsCommand
  }): Observable<StrictHttpResponse<ChangeRecipeSubBusinessUnitsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeSubBusinessUnitPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeSubBusinessUnitsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeSubBusinessUnitPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSubBusinessUnitPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeSubBusinessUnitsCommand
  }): Observable<ChangeRecipeSubBusinessUnitsResponse> {

    return this.recipeEventsChangeRecipeSubBusinessUnitPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeSubBusinessUnitsResponse>) => r.body as ChangeRecipeSubBusinessUnitsResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeApprovedPost
   */
  static readonly RecipeEventsChangeRecipeApprovedPostPath = '/recipe-events/change-recipe-approved';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeApprovedPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovedPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeApprovedCommand
  }): Observable<StrictHttpResponse<ChangeRecipeApprovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeApprovedPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeApprovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeApprovedPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovedPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeApprovedCommand
  }): Observable<ChangeRecipeApprovedResponse> {

    return this.recipeEventsChangeRecipeApprovedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeApprovedResponse>) => r.body as ChangeRecipeApprovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeApprovedPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovedPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeApprovedCommand
  }): Observable<StrictHttpResponse<ChangeRecipeApprovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeApprovedPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeApprovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeApprovedPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovedPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeApprovedCommand
  }): Observable<ChangeRecipeApprovedResponse> {

    return this.recipeEventsChangeRecipeApprovedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeApprovedResponse>) => r.body as ChangeRecipeApprovedResponse)
    );
  }

  /**
   * Path part for operation recipeEventsCopyRecipePost
   */
  static readonly RecipeEventsCopyRecipePostPath = '/recipe-events/copy-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsCopyRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCopyRecipePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: CopyRecipeCommand
  }): Observable<StrictHttpResponse<CopiedRecipeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsCopyRecipePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CopiedRecipeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsCopyRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCopyRecipePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: CopyRecipeCommand
  }): Observable<CopiedRecipeResponse> {

    return this.recipeEventsCopyRecipePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CopiedRecipeResponse>) => r.body as CopiedRecipeResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsCopyRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCopyRecipePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: CopyRecipeCommand
  }): Observable<StrictHttpResponse<CopiedRecipeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsCopyRecipePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CopiedRecipeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsCopyRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCopyRecipePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: CopyRecipeCommand
  }): Observable<CopiedRecipeResponse> {

    return this.recipeEventsCopyRecipePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CopiedRecipeResponse>) => r.body as CopiedRecipeResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeRecipeConsumingLabsitePost
   */
  static readonly RecipeEventsChangeRecipeConsumingLabsitePostPath = '/recipe-events/change-recipe-consuming-labsite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeConsumingLabsitePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeConsumingLabsitePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeConsumingLabsitesCommand
  }): Observable<StrictHttpResponse<ChangeRecipeConsumingLabsitesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeConsumingLabsitePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeConsumingLabsitesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeConsumingLabsitePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeConsumingLabsitePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeConsumingLabsitesCommand
  }): Observable<ChangeRecipeConsumingLabsitesResponse> {

    return this.recipeEventsChangeRecipeConsumingLabsitePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeConsumingLabsitesResponse>) => r.body as ChangeRecipeConsumingLabsitesResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeConsumingLabsitePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeConsumingLabsitePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeConsumingLabsitesCommand
  }): Observable<StrictHttpResponse<ChangeRecipeConsumingLabsitesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeRecipeConsumingLabsitePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeConsumingLabsitesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeConsumingLabsitePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeConsumingLabsitePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeConsumingLabsitesCommand
  }): Observable<ChangeRecipeConsumingLabsitesResponse> {

    return this.recipeEventsChangeRecipeConsumingLabsitePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeConsumingLabsitesResponse>) => r.body as ChangeRecipeConsumingLabsitesResponse)
    );
  }

  /**
   * Path part for operation recipeEventsAddTemplatePost
   */
  static readonly RecipeEventsAddTemplatePostPath = '/recipe-events/add-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsAddTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddTemplatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddTemplateCommand
  }): Observable<StrictHttpResponse<RecipeTemplateAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsAddTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeTemplateAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsAddTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddTemplatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddTemplateCommand
  }): Observable<RecipeTemplateAddedResponse> {

    return this.recipeEventsAddTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeTemplateAddedResponse>) => r.body as RecipeTemplateAddedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsAddTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddTemplatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddTemplateCommand
  }): Observable<StrictHttpResponse<RecipeTemplateAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsAddTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeTemplateAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsAddTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddTemplatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddTemplateCommand
  }): Observable<RecipeTemplateAddedResponse> {

    return this.recipeEventsAddTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeTemplateAddedResponse>) => r.body as RecipeTemplateAddedResponse)
    );
  }

  /**
   * Path part for operation recipeEventsDeleteTemplatePost
   */
  static readonly RecipeEventsDeleteTemplatePostPath = '/recipe-events/delete-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsDeleteTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsDeleteTemplatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeDeleteTemplateCommand
  }): Observable<StrictHttpResponse<RecipeTemplateDeletedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsDeleteTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeTemplateDeletedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsDeleteTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsDeleteTemplatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeDeleteTemplateCommand
  }): Observable<RecipeTemplateDeletedResponse> {

    return this.recipeEventsDeleteTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeTemplateDeletedResponse>) => r.body as RecipeTemplateDeletedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsDeleteTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsDeleteTemplatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeDeleteTemplateCommand
  }): Observable<StrictHttpResponse<RecipeTemplateDeletedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsDeleteTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeTemplateDeletedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsDeleteTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsDeleteTemplatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeDeleteTemplateCommand
  }): Observable<RecipeTemplateDeletedResponse> {

    return this.recipeEventsDeleteTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeTemplateDeletedResponse>) => r.body as RecipeTemplateDeletedResponse)
    );
  }

  /**
   * Path part for operation recipeEventsRecipeChangeToPendingverificationPost
   */
  static readonly RecipeEventsRecipeChangeToPendingverificationPostPath = '/recipe-events/recipe-change-to-pendingverification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipeChangeToPendingverificationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingverificationPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeChangeToPendingVerificationCommand
  }): Observable<StrictHttpResponse<RecipeChangeToPendingVerificationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRecipeChangeToPendingverificationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeChangeToPendingVerificationResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipeChangeToPendingverificationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingverificationPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeChangeToPendingVerificationCommand
  }): Observable<RecipeChangeToPendingVerificationResponse> {

    return this.recipeEventsRecipeChangeToPendingverificationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeChangeToPendingVerificationResponse>) => r.body as RecipeChangeToPendingVerificationResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipeChangeToPendingverificationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingverificationPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeChangeToPendingVerificationCommand
  }): Observable<StrictHttpResponse<RecipeChangeToPendingVerificationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRecipeChangeToPendingverificationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeChangeToPendingVerificationResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipeChangeToPendingverificationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingverificationPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeChangeToPendingVerificationCommand
  }): Observable<RecipeChangeToPendingVerificationResponse> {

    return this.recipeEventsRecipeChangeToPendingverificationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeChangeToPendingVerificationResponse>) => r.body as RecipeChangeToPendingVerificationResponse)
    );
  }

  /**
   * Path part for operation recipeEventsRetireRecipePost
   */
  static readonly RecipeEventsRetireRecipePostPath = '/recipe-events/retire-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRetireRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRetireRecipePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RetireRecipeCommand
  }): Observable<StrictHttpResponse<RetireRecipeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRetireRecipePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RetireRecipeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRetireRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRetireRecipePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RetireRecipeCommand
  }): Observable<RetireRecipeResponse> {

    return this.recipeEventsRetireRecipePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RetireRecipeResponse>) => r.body as RetireRecipeResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRetireRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRetireRecipePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RetireRecipeCommand
  }): Observable<StrictHttpResponse<RetireRecipeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRetireRecipePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RetireRecipeResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRetireRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRetireRecipePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RetireRecipeCommand
  }): Observable<RetireRecipeResponse> {

    return this.recipeEventsRetireRecipePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RetireRecipeResponse>) => r.body as RetireRecipeResponse)
    );
  }

  /**
   * Path part for operation recipeEventsRecipeChangeToPendingapprovalPost
   */
  static readonly RecipeEventsRecipeChangeToPendingapprovalPostPath = '/recipe-events/recipe-change-to-pendingapproval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipeChangeToPendingapprovalPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingapprovalPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeChangeToPendingApprovalCommand
  }): Observable<StrictHttpResponse<RecipeChangeToPendingApprovalResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRecipeChangeToPendingapprovalPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeChangeToPendingApprovalResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipeChangeToPendingapprovalPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingapprovalPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeChangeToPendingApprovalCommand
  }): Observable<RecipeChangeToPendingApprovalResponse> {

    return this.recipeEventsRecipeChangeToPendingapprovalPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeChangeToPendingApprovalResponse>) => r.body as RecipeChangeToPendingApprovalResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipeChangeToPendingapprovalPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingapprovalPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeChangeToPendingApprovalCommand
  }): Observable<StrictHttpResponse<RecipeChangeToPendingApprovalResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRecipeChangeToPendingapprovalPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeChangeToPendingApprovalResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipeChangeToPendingapprovalPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingapprovalPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeChangeToPendingApprovalCommand
  }): Observable<RecipeChangeToPendingApprovalResponse> {

    return this.recipeEventsRecipeChangeToPendingapprovalPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeChangeToPendingApprovalResponse>) => r.body as RecipeChangeToPendingApprovalResponse)
    );
  }

  /**
   * Path part for operation recipeEventsRecipePublishedPost
   */
  static readonly RecipeEventsRecipePublishedPostPath = '/recipe-events/recipe-published';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipePublishedPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipePublishedPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipePublishedCommand
  }): Observable<StrictHttpResponse<RecipePublishedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRecipePublishedPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipePublishedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipePublishedPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipePublishedPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipePublishedCommand
  }): Observable<RecipePublishedResponse> {

    return this.recipeEventsRecipePublishedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipePublishedResponse>) => r.body as RecipePublishedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipePublishedPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipePublishedPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipePublishedCommand
  }): Observable<StrictHttpResponse<RecipePublishedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRecipePublishedPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipePublishedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipePublishedPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipePublishedPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipePublishedCommand
  }): Observable<RecipePublishedResponse> {

    return this.recipeEventsRecipePublishedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipePublishedResponse>) => r.body as RecipePublishedResponse)
    );
  }

  /**
   * Path part for operation recipeEventsRecipeReturnToIndraftPost
   */
  static readonly RecipeEventsRecipeReturnToIndraftPostPath = '/recipe-events/recipe-return-to-indraft';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipeReturnToIndraftPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeReturnToIndraftPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeReturnToInDraftCommand
  }): Observable<StrictHttpResponse<RecipeReturnToInDraftResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRecipeReturnToIndraftPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeReturnToInDraftResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipeReturnToIndraftPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeReturnToIndraftPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeReturnToInDraftCommand
  }): Observable<RecipeReturnToInDraftResponse> {

    return this.recipeEventsRecipeReturnToIndraftPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeReturnToInDraftResponse>) => r.body as RecipeReturnToInDraftResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipeReturnToIndraftPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeReturnToIndraftPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeReturnToInDraftCommand
  }): Observable<StrictHttpResponse<RecipeReturnToInDraftResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRecipeReturnToIndraftPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeReturnToInDraftResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipeReturnToIndraftPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeReturnToIndraftPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeReturnToInDraftCommand
  }): Observable<RecipeReturnToInDraftResponse> {

    return this.recipeEventsRecipeReturnToIndraftPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeReturnToInDraftResponse>) => r.body as RecipeReturnToInDraftResponse)
    );
  }

  /**
   * Path part for operation recipeEventsStartNewPost
   */
  static readonly RecipeEventsStartNewPostPath = '/recipe-events/start-new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsStartNewPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsStartNewPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: StartNewVersionCommand
  }): Observable<StrictHttpResponse<StartNewVersionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsStartNewPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StartNewVersionResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsStartNewPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsStartNewPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: StartNewVersionCommand
  }): Observable<StartNewVersionResponse> {

    return this.recipeEventsStartNewPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StartNewVersionResponse>) => r.body as StartNewVersionResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsStartNewPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsStartNewPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: StartNewVersionCommand
  }): Observable<StrictHttpResponse<StartNewVersionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsStartNewPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StartNewVersionResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsStartNewPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsStartNewPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: StartNewVersionCommand
  }): Observable<StartNewVersionResponse> {

    return this.recipeEventsStartNewPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StartNewVersionResponse>) => r.body as StartNewVersionResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeAvailabilityPost
   */
  static readonly RecipeEventsChangeAvailabilityPostPath = '/recipe-events/change-availability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeAvailabilityPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeAvailabilityPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeAvailabilityCommand
  }): Observable<StrictHttpResponse<ChangeRecipeAvailabilityResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeAvailabilityPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeAvailabilityResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeAvailabilityPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeAvailabilityPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeAvailabilityCommand
  }): Observable<ChangeRecipeAvailabilityResponse> {

    return this.recipeEventsChangeAvailabilityPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeAvailabilityResponse>) => r.body as ChangeRecipeAvailabilityResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeAvailabilityPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeAvailabilityPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeAvailabilityCommand
  }): Observable<StrictHttpResponse<ChangeRecipeAvailabilityResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeAvailabilityPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeAvailabilityResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeAvailabilityPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeAvailabilityPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeAvailabilityCommand
  }): Observable<ChangeRecipeAvailabilityResponse> {

    return this.recipeEventsChangeAvailabilityPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeAvailabilityResponse>) => r.body as ChangeRecipeAvailabilityResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeFieldPost
   */
  static readonly RecipeEventsChangeFieldPostPath = '/recipe-events/change-field';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeFieldPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeFieldPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeFieldCommand
  }): Observable<StrictHttpResponse<ChangeRecipeFieldResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeFieldPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeFieldResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeFieldPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeFieldPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeFieldCommand
  }): Observable<ChangeRecipeFieldResponse> {

    return this.recipeEventsChangeFieldPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeFieldResponse>) => r.body as ChangeRecipeFieldResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeFieldPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeFieldPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeFieldCommand
  }): Observable<StrictHttpResponse<ChangeRecipeFieldResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeFieldPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeFieldResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeFieldPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeFieldPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeFieldCommand
  }): Observable<ChangeRecipeFieldResponse> {

    return this.recipeEventsChangeFieldPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeFieldResponse>) => r.body as ChangeRecipeFieldResponse)
    );
  }

  /**
   * Path part for operation recipeEventsAddRowsPost
   */
  static readonly RecipeEventsAddRowsPostPath = '/recipe-events/add-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsAddRowsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddRowsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: AddRecipeRowCommand
  }): Observable<StrictHttpResponse<AddRecipeRowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsAddRowsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddRecipeRowResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsAddRowsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddRowsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: AddRecipeRowCommand
  }): Observable<AddRecipeRowResponse> {

    return this.recipeEventsAddRowsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AddRecipeRowResponse>) => r.body as AddRecipeRowResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsAddRowsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddRowsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: AddRecipeRowCommand
  }): Observable<StrictHttpResponse<AddRecipeRowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsAddRowsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddRecipeRowResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsAddRowsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddRowsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: AddRecipeRowCommand
  }): Observable<AddRecipeRowResponse> {

    return this.recipeEventsAddRowsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AddRecipeRowResponse>) => r.body as AddRecipeRowResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeCellsPost
   */
  static readonly RecipeEventsChangeCellsPostPath = '/recipe-events/change-cells';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeCellsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeCellsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeCellCommand
  }): Observable<StrictHttpResponse<ChangeRecipeCellResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeCellsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeCellResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeCellsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeCellsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeCellCommand
  }): Observable<ChangeRecipeCellResponse> {

    return this.recipeEventsChangeCellsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeCellResponse>) => r.body as ChangeRecipeCellResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeCellsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeCellsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeCellCommand
  }): Observable<StrictHttpResponse<ChangeRecipeCellResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeCellsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipeCellResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeCellsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeCellsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeCellCommand
  }): Observable<ChangeRecipeCellResponse> {

    return this.recipeEventsChangeCellsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipeCellResponse>) => r.body as ChangeRecipeCellResponse)
    );
  }

  /**
   * Path part for operation recipeEventsRemoveRowsPost
   */
  static readonly RecipeEventsRemoveRowsPostPath = '/recipe-events/remove-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRemoveRowsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRemoveRowsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RemoveRecipeRowCommand
  }): Observable<StrictHttpResponse<RemoveRecipeRowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRemoveRowsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RemoveRecipeRowResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRemoveRowsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRemoveRowsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RemoveRecipeRowCommand
  }): Observable<RemoveRecipeRowResponse> {

    return this.recipeEventsRemoveRowsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RemoveRecipeRowResponse>) => r.body as RemoveRecipeRowResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRemoveRowsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRemoveRowsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RemoveRecipeRowCommand
  }): Observable<StrictHttpResponse<RemoveRecipeRowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRemoveRowsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RemoveRecipeRowResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRemoveRowsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRemoveRowsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RemoveRecipeRowCommand
  }): Observable<RemoveRecipeRowResponse> {

    return this.recipeEventsRemoveRowsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RemoveRecipeRowResponse>) => r.body as RemoveRecipeRowResponse)
    );
  }

  /**
   * Path part for operation recipeEventsRestoreRowsPost
   */
  static readonly RecipeEventsRestoreRowsPostPath = '/recipe-events/restore-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRestoreRowsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRowsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RestoreRecipeRowCommand
  }): Observable<StrictHttpResponse<RestoreRecipeRowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRestoreRowsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RestoreRecipeRowResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRestoreRowsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRowsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RestoreRecipeRowCommand
  }): Observable<RestoreRecipeRowResponse> {

    return this.recipeEventsRestoreRowsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RestoreRecipeRowResponse>) => r.body as RestoreRecipeRowResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRestoreRowsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRowsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RestoreRecipeRowCommand
  }): Observable<StrictHttpResponse<RestoreRecipeRowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRestoreRowsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RestoreRecipeRowResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRestoreRowsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRowsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RestoreRecipeRowCommand
  }): Observable<RestoreRecipeRowResponse> {

    return this.recipeEventsRestoreRowsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RestoreRecipeRowResponse>) => r.body as RestoreRecipeRowResponse)
    );
  }

  /**
   * Path part for operation recipeEventsRenumberRowsPost
   */
  static readonly RecipeEventsRenumberRowsPostPath = '/recipe-events/renumber-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRenumberRowsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRenumberRowsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RenumberRecipeRowsCommand
  }): Observable<StrictHttpResponse<RenumberRecipeRowsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRenumberRowsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RenumberRecipeRowsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRenumberRowsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRenumberRowsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RenumberRecipeRowsCommand
  }): Observable<RenumberRecipeRowsResponse> {

    return this.recipeEventsRenumberRowsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RenumberRecipeRowsResponse>) => r.body as RenumberRecipeRowsResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRenumberRowsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRenumberRowsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RenumberRecipeRowsCommand
  }): Observable<StrictHttpResponse<RenumberRecipeRowsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsRenumberRowsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RenumberRecipeRowsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRenumberRowsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRenumberRowsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RenumberRecipeRowsCommand
  }): Observable<RenumberRecipeRowsResponse> {

    return this.recipeEventsRenumberRowsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RenumberRecipeRowsResponse>) => r.body as RenumberRecipeRowsResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeNodeTitlePost
   */
  static readonly RecipeEventsChangeNodeTitlePostPath = '/recipe-events/change-node-title';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeNodeTitlePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeTitlePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeNodeTitleCommand
  }): Observable<StrictHttpResponse<RecipeNodeTitleChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeNodeTitlePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeNodeTitleChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeNodeTitlePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeTitlePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeNodeTitleCommand
  }): Observable<RecipeNodeTitleChangedResponse> {

    return this.recipeEventsChangeNodeTitlePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeNodeTitleChangedResponse>) => r.body as RecipeNodeTitleChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeNodeTitlePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeTitlePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeNodeTitleCommand
  }): Observable<StrictHttpResponse<RecipeNodeTitleChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeNodeTitlePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeNodeTitleChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeNodeTitlePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeTitlePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeNodeTitleCommand
  }): Observable<RecipeNodeTitleChangedResponse> {

    return this.recipeEventsChangeNodeTitlePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeNodeTitleChangedResponse>) => r.body as RecipeNodeTitleChangedResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangeNodeOrderPost
   */
  static readonly RecipeEventsChangeNodeOrderPostPath = '/recipe-events/change-node-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeNodeOrderPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeOrderPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeNodeReOrderCommand
  }): Observable<StrictHttpResponse<RecipeNodeOrderChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeNodeOrderPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeNodeOrderChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeNodeOrderPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeOrderPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeNodeReOrderCommand
  }): Observable<RecipeNodeOrderChangedResponse> {

    return this.recipeEventsChangeNodeOrderPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeNodeOrderChangedResponse>) => r.body as RecipeNodeOrderChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeNodeOrderPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeOrderPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeNodeReOrderCommand
  }): Observable<StrictHttpResponse<RecipeNodeOrderChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangeNodeOrderPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeNodeOrderChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeNodeOrderPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeOrderPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipeNodeReOrderCommand
  }): Observable<RecipeNodeOrderChangedResponse> {

    return this.recipeEventsChangeNodeOrderPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeNodeOrderChangedResponse>) => r.body as RecipeNodeOrderChangedResponse)
    );
  }

  /**
   * Path part for operation recipeEventsSetVariablePost
   */
  static readonly RecipeEventsSetVariablePostPath = '/recipe-events/set-variable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsSetVariablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsSetVariablePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SetVariableCommand
  }): Observable<StrictHttpResponse<SetVariableResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsSetVariablePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetVariableResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsSetVariablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsSetVariablePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SetVariableCommand
  }): Observable<SetVariableResponse> {

    return this.recipeEventsSetVariablePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SetVariableResponse>) => r.body as SetVariableResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsSetVariablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsSetVariablePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SetVariableCommand
  }): Observable<StrictHttpResponse<SetVariableResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsSetVariablePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetVariableResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsSetVariablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsSetVariablePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SetVariableCommand
  }): Observable<SetVariableResponse> {

    return this.recipeEventsSetVariablePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SetVariableResponse>) => r.body as SetVariableResponse)
    );
  }

  /**
   * Path part for operation recipeEventsChangePreLoadDataTransformOptionsPost
   */
  static readonly RecipeEventsChangePreLoadDataTransformOptionsPostPath = '/recipe-events/change-pre-load-data-transform-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangePreLoadDataTransformOptionsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangePreLoadDataTransformOptionsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipePreLoadDataTransformOptionsCommand
  }): Observable<StrictHttpResponse<ChangeRecipePreLoadDataTransformOptionsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangePreLoadDataTransformOptionsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipePreLoadDataTransformOptionsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangePreLoadDataTransformOptionsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangePreLoadDataTransformOptionsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipePreLoadDataTransformOptionsCommand
  }): Observable<ChangeRecipePreLoadDataTransformOptionsResponse> {

    return this.recipeEventsChangePreLoadDataTransformOptionsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipePreLoadDataTransformOptionsResponse>) => r.body as ChangeRecipePreLoadDataTransformOptionsResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangePreLoadDataTransformOptionsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangePreLoadDataTransformOptionsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipePreLoadDataTransformOptionsCommand
  }): Observable<StrictHttpResponse<ChangeRecipePreLoadDataTransformOptionsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeEventsService.RecipeEventsChangePreLoadDataTransformOptionsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRecipePreLoadDataTransformOptionsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangePreLoadDataTransformOptionsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangePreLoadDataTransformOptionsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ChangeRecipePreLoadDataTransformOptionsCommand
  }): Observable<ChangeRecipePreLoadDataTransformOptionsResponse> {

    return this.recipeEventsChangePreLoadDataTransformOptionsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeRecipePreLoadDataTransformOptionsResponse>) => r.body as ChangeRecipePreLoadDataTransformOptionsResponse)
    );
  }

}
