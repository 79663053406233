<div *ngIf="overlayVisibleBalance || overlayVisiblePh" class="overlay-backdrop"></div>

<div *ngIf="table && !table.isHidden" class="module-item module-table" (keydown)="$event.stopPropagation()">
  <bpt-progress-spinner
    i18n-message="@@loadingHistory"
    message="History Loading..."
    [useOverlay]="true"
    *ngIf="isLoading">
  </bpt-progress-spinner>
  <div class="col-10 eln-exp-table-header module-item-title flex flex-row align-items-center gap-3">
    <p-contextMenu [target]="tableTitle" [model]="items"appendTo="body"></p-contextMenu>
    <div class="tableTitle bpt-eln-node-inline-editing-input-size minimum-height
    flex align-items-center" (contextmenu)="onContextMenu();" #tableTitle>
      <span id="{{table.tableId}}-title" *ngIf="!retitleEnabled"
        class="eln-module-tab-header" [innerText]="table.itemTitle"
        (dblclick)="retitleEnabled = true">
      </span>
      <div class="bpt-eln-inline-control">
        <app-experiment-node-title-changer
          *ngIf="retitleEnabled"
          [nodeId]="table.tableId"
          [parentNodeId]="parentNodeId"
          [nodeType]="nodeType.Table"
          [currentTitle]="table.itemTitle"
          (titleChangeHasCancelled)="retitleEnabled = false;"
          (titleChanged)="table.itemTitle = $event.title;retitleEnabled = false">
        </app-experiment-node-title-changer>
      </div>
    </div>
    <app-completion-tracking [completionPercent]=completionPercent></app-completion-tracking>
  </div>
  <div *ngIf="containsRemovedRows" class="-mt-4 mb-3 p-0 align-items-center inline-block flex align-content-center" style="min-height: auto;">
    <span class="p-1 inline-block" i18n="@@containsRemovedRows">This table contains removed rows.</span>
    <p-button styleClass="p-button-link p-1" class="p-0" (click)="loadRemovedRowsDialog()" i18n="@@viewRemovedRows">View Removed Rows</p-button>
  </div>
  <bpt-grid
    #Grid
    gridId="{{ table.tableId }}"
    class="eln-grid"
    ngClass="{{ table.allowReadOnly ? 'eln-disabled' : '' }}"
    [tabIndex]="greatestTabOrder + AdditionalCountOfTabOrderReservation"
    [dataSource]="nonRemovedData"
    [useJsJodaTypes]="true"
    (cellValueChanged)="cellValueEditing($event)"
    (valuesPasted)="pasteValues($event)"
    [beforeAddRow] = "confirmAddRow"
    (rowsAdded)="rowsAdded($event)"
    [columnDefinitions]="columnDefinitions"
    [readOnly]="combinedReadOnly"
    [showAutoSizeButton]="table.allowRowAdd || numberOfRows > 0"
    [showFilterToggleButton]="table.allowRowAdd || numberOfRows > 0"
    [showGridOptionsButton]="false"
    [allowRowAdd]="table.allowRowAdd"
    [addMultipleRows]="table.allowMultipleRows"
    [paginated]="table.allowPagination"
    (newGridPreferenceAdded)="saveNewPreference($event)"
    (gridPreferenceDeleted)="deletePreference($event)"
    (gridPreferenceUpdated)="updatePreference($event)"
    (gridPreferenceSelectionChanged)="changeDefaultPreference($event)"
    [rowsPerPage]="50"
    [rowsPerPageOptions]="[50, 100, 200, 500]"
    [suppressContextMenu]="suppressContextMenu"
    [contextMenu]="getContextMenu()"
    (cellEditStarted)="cellEditStartedEvent($event)"
    (cellEditStopped)="cellEditStoppedEvent($event)"
    (cellKeyDown)="cellKeyDownEvent($event)"
    (gridReady)="onGridReady()"
    (firstDataRendered)="onFirstDataRendered($event)"
    [paginationOverlayOptions]="gridPaginationOverlayOptions"
    (mousedown)="$event.stopPropagation()"
    [cellObjectCallbacks]="cellObjectCallbacks"
    [cellPasteObjectCallbacks]="cellPasteObjectCallbacks"
    [ianaTimeZone]="labSiteTimeZone">
    <ng-container class="bpt-grid-toolbar-right-layout" *ngIf="numberOfRows > 0">
      <a
        class="table-grid-toolbar-audit-icon"
        (click)="loadAuditHistoryDialog()"
        [tabindex]="greatestTabOrder <= 0 ? 1 : greatestTabOrder + 1">
        <span
          i18n-pTooltip="@@viewHistory"
          class="fa fa-history"
          pTooltip="View History"
          tooltipPosition="top"><span class="ml-1 audit" i18n="@@History">History</span></span>
      </a>
    </ng-container>
    <ng-container class="bpt-grid-toolbar-right-layout">
      <a *ngIf="containsRemovedRows"
        class="bpt-grid-toolbar-item table-grid-toolbar-view-removed-rows-icon reference-remove-icon ml-3"
        (click)="loadRemovedRowsDialog()">
        <span
          i18n-pTooltip="@@viewRemovedRows"
          class="fa icon-view-row"
          pTooltip="View Removed Rows"
          tooltipPosition="top"
          ><span class="ml-1 viewRemovedRows-iconTxt" i18n="@@viewRemovedRows"
            >View Removed Rows</span
          ></span
        >
      </a>
    </ng-container>    
  </bpt-grid>
</div>

<p-overlayPanel #op [dismissable]="false">
  <app-instrument-balance-reading
    *ngIf="overlayVisibleBalance"
    (closeEvent)="closeOverlay()"
    (actualValueEvent)="onInstrumentReadingsReceived($event, false)"
    (unitEvent)="onUnitChange($event)"
    [sequentialReadingEnabled]="sequentialReadingEnabled"
    [showTarget]="showTarget"
    [targetValue]="targetValue"
  >
  </app-instrument-balance-reading>
  <app-instrument-ph-reading
    *ngIf="overlayVisiblePh"
    (closeEvent)="closeOverlay()"
    (unitEvent)="onUnitChange($event)"
    (actualValueEvent)="onInstrumentReadingsReceived($event, false)"
    [columnUnitsForReading] = "columnUnitsForReading"
    [sequentialReadingEnabled]="sequentialReadingEnabled">
  </app-instrument-ph-reading>
</p-overlayPanel>
