import { ClientFacingNote, ClientFacingNoteContextType, ModifiableDataValue, Puid, ValueState, ValueType } from "../../../../../src/app/api/models";
import { DataValueService } from "../../services/data-value.service";
import { 
  ActivityInputClientFacingNoteContext,
  ClientFacingNoteContext,
  FormFieldClientFacingNoteContext, 
  LabItemsClientFacingNoteContext, 
  TableCellClientFacingNoteContext,
  CrossReferenceClientFacingNoteContext,
  PreparationsClientFacingNoteContext,
  LabItemsPreparationClientFacingNoteContext
} from "./client-facing-note-event.model";


/**
 * An existing or new client-facing note. 
 * * A new note does not have a number. 
 * * Maintains a shadow state for content being edited: currentComment, isBeingEdited
 * * Provides a small amount of history: lastEditedOn, lastEditedBy
 */
export class ClientFacingNoteModel implements Partial<ClientFacingNote> {
  /**
   * Number of an existing note or undefined for a new note
   */
  number?: number;
  lastEditedBy?: Puid;
  lastEditedOn?: string;
  /**
   * Last saved content with StringValue
   */
  content: ModifiableDataValue;
  contextType: ClientFacingNoteContextType;
  private _context!: ClientFacingNoteContext; // set in constructor via setter
  get context(): ClientFacingNoteContext {
    return this._context;
  }
  set context(value: ClientFacingNoteContext) {
    this._context = value;
    if ('nodeId' in this._context) {
      this.nodeId = this._context['nodeId'];
    } else {
      this.nodeId = '';
    }
    if ('path' in this._context) {
      this.path = this._context['path'] as Array<string>;
    } else {
      this.path = [];
    }
  }

  /**
   * Potentially unsaved content that can be saved to or restored from the saved content.
   */
  currentComment: string;
  isBeingEdited = false;
  nodeId: string;
  path: string[];

  public get cssId(): string {
    return `cfn-${this.number}`;
  }

  private get shortContent() {
    const limit = 30;
    return this.currentComment.length <= limit
      ? this.currentComment
      : this.currentComment.substring(0, limit) + '…';
  }

  public get indicatorText(): string {
    return $localize`:@@clientFacingNote:Client-facing Note` + ` ${this.number}: ${this.shortContent} (click to view)`;
  }

  public getTableCellContext(): TableCellClientFacingNoteContext | undefined {
    if (this.contextType === ClientFacingNoteContextType.TableCell) {
      return this.context as TableCellClientFacingNoteContext;
    }
    return undefined;
  }

  public getCrossReferenceCellContext(): CrossReferenceClientFacingNoteContext | undefined {
    if (this.contextType !== ClientFacingNoteContextType.CrossReference)  return undefined;

    const context = this.context as CrossReferenceClientFacingNoteContext; 
    if (!(context.columnField && context.rowId && context.activityId)) return undefined;
      
    return context;
  }

  public getLabItemCellContext(): LabItemsClientFacingNoteContext | undefined {
    if (this.contextType === ClientFacingNoteContextType.LabItems) {
      return this.context as LabItemsClientFacingNoteContext;
    }
    return undefined;
  }

  public getPreparationsCellContext(): PreparationsClientFacingNoteContext | undefined {
    if (this.contextType === ClientFacingNoteContextType.Preparations) {
      return this.context as PreparationsClientFacingNoteContext; 
    }
    return undefined;
  }
  public getLabItemPreparationsCellContext(): LabItemsPreparationClientFacingNoteContext | undefined {
    if (this.contextType === ClientFacingNoteContextType.LabItemsPreparation) {
      return this.context as LabItemsPreparationClientFacingNoteContext; 
    }
    return undefined;
  }

  public getActivityInputContext(): ActivityInputClientFacingNoteContext | undefined {
    if (this.contextType === ClientFacingNoteContextType.ActivityInput) {
      return this.context as ActivityInputClientFacingNoteContext;
    }
    return undefined;
  }

  public getFormFieldContext(): FormFieldClientFacingNoteContext | undefined {
    if (this.contextType === ClientFacingNoteContextType.FormField) {
      return this.context as FormFieldClientFacingNoteContext;
    }
    return undefined;
  }

  public saveChanges(): void {
    this.content.value = DataValueService.getValueFromString(this.currentComment);
  }

  public discardChanges(): void {
    this.currentComment = DataValueService.getStringFromValue(this.content.value);
  }

  public isDirty(): boolean {
    return this.currentComment !== DataValueService.getStringFromValue(this.content.value);
  }

  constructor(
    contextType: ClientFacingNoteContextType,
    note?: ClientFacingNote | undefined,
    context?: ClientFacingNoteContext | undefined
  ) {
    note ??= {
      contextType: contextType,
      content: {
        isModified: false,
        value: { type: ValueType.String, state: ValueState.Empty }
      },
      lastEditedBy: { value: '' },
      lastEditedOn: '',
      nodeId: '',
      number: 0,
      path: []
    };
    context ??= this.getContext(note);
    this.context = context;

    this.number = note.number;
    this.lastEditedBy = note.lastEditedBy;
    this.lastEditedOn = note.lastEditedOn;
    this.content = note.content;
    this.contextType = note.contextType;
    this.context = context;
    this.currentComment = DataValueService.getStringFromValue(this.content.value);
    this.isBeingEdited = false;

    // Initialize nodeId and path
    switch (contextType) {
      case ClientFacingNoteContextType.TableCell:
        const tblContext = context as TableCellClientFacingNoteContext;
        this.nodeId = tblContext.tableId;
        this.path = [tblContext.rowId, tblContext.columnField];
        break;
      case ClientFacingNoteContextType.FormField:
        const formContext = context as FormFieldClientFacingNoteContext;
        this.nodeId = formContext.formId;
        this.path = [formContext.fieldIdentifier];
        break;
      case ClientFacingNoteContextType.ActivityInput:
        const aContext = context as ActivityInputClientFacingNoteContext;
        this.nodeId = aContext.activityInputId;
        this.path = [aContext.rowId, aContext.columnField, aContext.activityId];
        break;
      case ClientFacingNoteContextType.LabItems:
        const lContext = context as LabItemsClientFacingNoteContext;
        this.nodeId = lContext.labItemId;
        this.path = [lContext.rowId, lContext.columnField, lContext.labItemId, lContext.labItemType]
        break;
      case ClientFacingNoteContextType.Preparations:
        const pContext = context as PreparationsClientFacingNoteContext;
        this.nodeId = pContext.nodeId;
        this.path = [pContext.rowId, pContext.columnField, pContext.nodeId];
        break;
      case ClientFacingNoteContextType.LabItemsPreparation:
        const labItemPreparationContext = context as LabItemsPreparationClientFacingNoteContext;
        this.nodeId = labItemPreparationContext.nodeId;
        this.path = [labItemPreparationContext.rowId, labItemPreparationContext.columnField, labItemPreparationContext.nodeId];
        break;
      case ClientFacingNoteContextType.CrossReference:
        const xrefContext = context as CrossReferenceClientFacingNoteContext;
        this.nodeId = xrefContext.activityId;
        this.path = [xrefContext.rowId, xrefContext.columnField]
        break;
      default:
        throw new Error(`Logic Error: Not Implemented. ClientFacingNoteContextType ${contextType}`);
    }
  }

  private getContext(note: ClientFacingNote): ClientFacingNoteContext {
    return ClientFacingNoteModel.getContextByTypeAndPath(note.nodeId, note.contextType, note.path);
  }

  public static getContextByTypeAndPath(nodeId:string, contextType: ClientFacingNoteContextType, path: string[] ){
    switch (contextType) {
      case ClientFacingNoteContextType.TableCell:
        return { tableId: nodeId, rowId: path[0], columnField: path[1] };
      case ClientFacingNoteContextType.LabItems:
        return { labItemId: path[2], rowId: path[0], columnField: path[1], labItemType: path[3] };
      case ClientFacingNoteContextType.Preparations:
        return { nodeId: path[2], preparationsTableId: path[2].concat('-Preparations'), rowId: path[0], columnField: path[1] }
      case ClientFacingNoteContextType.LabItemsPreparation:
        return { nodeId: path[2], preparationsTableId: path[2].concat('-labItemsPreparations'), rowId: path[0], columnField: path[1],labItemsPreparationIdentifier:"labItemsPreparation" }
      case ClientFacingNoteContextType.FormField:
        return { formId: nodeId, fieldIdentifier: path[0] };
      case ClientFacingNoteContextType.CrossReference:
          return { activityId: nodeId, rowId: path[0], columnField: path[1] };
      case ClientFacingNoteContextType.ActivityInput:
        return { activityInputId: nodeId, rowId: path[0], columnField: path[1], activityId: path[2], tableTitle: path[3], label:path[4] };
      case ClientFacingNoteContextType.Experiment:
      case ClientFacingNoteContextType.Activity:
      case ClientFacingNoteContextType.ActivityGroup:
      case ClientFacingNoteContextType.Module:
      case ClientFacingNoteContextType.Form:
      case ClientFacingNoteContextType.Table:
      default: // default to table cell. from here on out, this should never get hit unless we're accessing a note older than 10/25/2022.
        return { tableId: nodeId, rowId: path[0], columnField: path[1] };
    }
  }
}
