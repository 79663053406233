import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, NgZone } from '@angular/core';
import { ActivatedRoute, ActivationStart, NavigationEnd, Router } from '@angular/router';
import { Activity, Experiment, Form, ReferenceGridType, SpecificationValue, Table } from 'model/experiment.interface';
import { Message, ConfirmationService, MessageService, MenuItem } from 'primeng/api';
import { Observable, Subject, Subscription, throwError, map, forkJoin, of } from 'rxjs';
import { take, finalize, first, filter } from 'rxjs/operators';
import { replace } from 'lodash-es';
import { ActivityInputHelper } from './inputs/shared/activity-input-helper';
import { ClientStateService } from 'services/client-state.service';
import {
  NodeType,
  TemplateType,
  ExperimentWorkflowState,
  ExperimentRaisedFlag,
  ExperimentFlagType,
  ActivityInputType,
  ActivityLabItemsNode,
  FormWithFieldDefinitionsResponse,
  SubBusinessUnit,
  ClientFacingNoteContextType
} from '../../app/api/models';
import {
  ApplyReferenceTemplateCommand,
  ClientFacingNoteCreatedEventNotification,
  ExperimentCancelledResponse,
  ExperimentEventType,
  ExperimentRestoredResponse,
  ExperimentStartedResponse,
  ReferenceTemplateAppliedResponse,
  ReferenceTemplateType,
  SetVariableCommand
} from '../../app/api/data-entry/models';
import { BaseComponent } from '../base/base.component';
import { ExperimentService, SpecificationEditorContext } from './services/experiment.service';
import { TemplateLoaderResult } from '../template-loader/models/template-loader-result';
import { SelectedTemplate } from '../template-loader/models/selected-template';
import { TemplateLoaderType } from '../template-loader/models/template-loader-type';
import { ExperimentTemplateEventService } from '../template-loader/experiment-template-load/services/experiment-template-event.service';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { ClientValidationDetails } from 'model/client-validation-details';
import { UserService } from 'services/user.service';
import { User } from 'model/user.interface';
import { ExperimentEventsService } from '../api/data-entry/services/experiment-events.service';
import { DataRecordService, SetVariableEventNotification } from './services/data-record.service';
import { WorkflowEventNotification } from '../api/data-entry/models/workflow-event-notification';
import { ExperimentSentForReviewResponse } from '../api/data-entry/models/experiment-sent-for-review-response';
import { ExperimentAuthorizedResponse } from '../api/data-entry/models/experiment-authorized-response';
import { ExperimentSentForCorrectionResponse } from '../api/data-entry/models/experiment-sent-for-correction-response';
import { elnEncodeSpecialChars } from '../shared/url-path-serializer';
import { AccessibilityTypes, ClientState } from '../app.states';
import { ToolbarIcon } from 'bpt-ui-library/bpt-toolbar/model/toolbar-icons.interface';
import { MainMenuItem } from 'bpt-ui-library/bpt-layout/model/main-menu-item.interface';
import { AuditHistoryService } from './audit-history/audit-history.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuditHistoryDataRecordResponse, ExperimentDataRecordNotification } from '../api/audit/models';
import { ShowNotesOrCommentsEventData } from './comments/client-facing-note/client-facing-note-event.model';
import { ElnProgressSpinnerService } from '../eln-progress-spinner-module/eln-progress-spinner.service';
import { NodeCompletionStatus } from './model/node-completion-status.interface';
import { ExperimentWarningService } from './services/experiment-warning.service';
import { RuleActionHandlerHostService } from './../rule-engine/handler/rule-action-handler-host.service';
import { ExperimentOptionsHelper } from '../shared/experiment-options-helper';
import { RuleActionNotificationService } from './../rule-engine/action-notification/rule-action-notification.service';
import { ActivityInputInstrumentEvent } from './model/activity-input-instrument-event';
import { BarcodeScannerHelper } from 'services/barcode-scanner-helper';
import { UnsubscribeAll } from '../shared/rx-js-helpers';
import { Title } from '@angular/platform-browser';
import { LabItemsService } from './labItems/lab-items.service';
import { CommentContextType } from '../api/internal-comment/models';
import { CommentDetails } from './comments/comment.model';
import { CommentService } from './comments/comment.service';
import { FeatureService } from 'services/feature.service';
import { ELNFeatureFlags } from '../shared/eln-feature-flags';
import { DataPackageService } from './data-packages/data-package.service';
import { LabItemsFeatureManager } from './labItems/shared/lab-items-feature-manager';
import { ActivityReferenceEventsService } from '../api/data-entry/services';
import { ExperimentNodeRetitleService } from './services/experiment-node-re-title.service';
import { TemplateLoaderService } from '../template-loader/services/template-loader.service';
import { ExperimentUserPreferenceService } from './services/experiment-user-preference.service';
import { ActivityReferencesPseudoModuleId, ActivityReferencesPseudoModuleTitle } from './references/references.component';
import { OutputEmpowerService } from './services/output-empower.service';
import { ExperimentNodeReOrderService } from './services/experiment-node-re-order.service';
import { AuthResult, AuthStatusType } from '../shared/authentication-maintainance/model/auth.model';
import { AuthenticationHelperService } from '../shared/authentication-maintainance/services/authentication-helper.service';
import { ConfigurationService } from '../services/configuration.service';
import { ExperimentPreparationService } from './preparations/experiment-preparations/services/experiment-preparation.service';
import { BptReoderedEvent } from '../layout/main-menu/main-menu.component';
import { CompletionTrackingService } from '../services/completion-tracking.services';
import { SearchControl } from 'bpt-ui-library/bpt-search';
import { LoaderSearchCriteria } from '../recipe-template-loader/experiment-template-load/models/finalised-search-criteria';
import { SelectedTemplateCommand } from '../recipe-template-loader/models/template-loader-information';
import { TemplateLocationOptions } from '../recipe-template-loader/experiment-template-load/models/insert-location.interface';
import { TemplateLoaderFilterChangedEvent } from '../recipe-template-loader/models/template-loader-filter-changed-event.interface';
import { ExperimentTemplateApplyService } from '../template-loader/experiment-template-load/services/experiment-template-apply.service';
import { TemplateInsertLocationOptions } from '../recipe-template-loader/experiment-template-load/models/recipe-template-insert-location-options';
import { InstrumentConnectionHelper } from './instrument-connection/shared/instrument-connection-helper';
import { InstrumentType } from './instrument-connection/shared/instrument-type';
import { ActivityInputItemState } from './barcode-scanner/activity-input-item-state';
import { PhMeterService } from './services/ph-meter.service';
import { InstrumentNotificationService } from './instrument-connection/shared/instrument-notification-service';
import { ClientFacingNoteModel } from './comments/client-facing-note/client-facing-note.model';

/**
 * Root of the tree of components for an Experiment. Loads the one experiment asynchronously, succeeding or failing.
 * Never destroyed while page is open. Can be depended on to be present for the entire life of viewing the one experiment.
 */
@Component({
  selector: 'app-experiment',
  templateUrl: './experiment.component.html',
  styleUrls: ['./experiment.component.scss']
})
export class ExperimentComponent extends BaseComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  private readonly reOrderSubscription: Subscription[] = [];
  public experimentNumber?: string;
  public experiment?: Experiment;
  auditLoading = false;
  loadingAppliedTemplate = false;
  isInvalid = false;
  errorMessages: Message[] = [];
  successMessages: Message[] = [];
  menuItems: MainMenuItem[] = [];
  user!: User;
  internalCommentData: CommentDetails = {} as CommentDetails;
  validation!: ClientValidationDetails;
  experimentWorkFlowEventSubscription?: Subscription;
  experimentCancelOptionSubscription?: Subscription;
  beginEditSpecification?: Subscription;
  specificationEditorContext?: SpecificationEditorContext;
  specificationEditorContextId = (_index: number, item: SpecificationEditorContext | undefined) =>
    item?.id ?? '00000000-0000-0000-0000-000000000000'; //  for trackBy, default cannot be undefined; so return a constant to mean no context
  canStartExperimentFlag = true;
  canPendingCancelFlag?: ExperimentRaisedFlag;
  canCancelExperiment = false;
  canRestoreExperiment = false;
  canAddTemplate = true;
  canSendExperimentForReviewFromInProgressFlag = false;
  canSendExperimentForReviewFromCorrectionFlag = false;
  canSendExperimentForCorrectionFromAuthorizedStateFlag = false;
  canSendExperimentForCorrectionFromReviewFlag = false;
  canSendExperimentForAuthorizationFlag = false;
  canEditExperimentInReviewStateFlag = false;
  isOutputsEnabled = false;
  sliderVisible = false;
  showTemplateLoader = false;
  disableAddTemplateButton = true;
  disableAddInstrumentEvent = true;
  disableAddConsumable = true;
  disableAddReferences = true;
  hideAddInstrumentEvent = true;
  hideAddConsumableButton = false;
  isBarcodeOpenedManually: boolean = true;
  templateLocationLookup: Array<{ id: string; name: string; type: NodeType }> = [];
  selectedTemplateName: string | undefined = undefined;
  selectedTemplate: SelectedTemplate | undefined = undefined;
  public insertLocationOptions: TemplateInsertLocationOptions[] = [];
  @Output() showComments: EventEmitter<ShowNotesOrCommentsEventData> =
    new EventEmitter<ShowNotesOrCommentsEventData>();
  templateLoaderType: TemplateLoaderType = TemplateLoaderType.TemplateToExperiment;
  inactiveTimeOut = 0;
  stopTimer = false;
  userInactiveTimeOut = 7200000;
  confirmationTimeOut = 60000;
  workflowStateTransitionOptions = [];
  showReconnectDialog = false;
  isReload = false;
  showConnecting = false;
  showBarcodeScanner = false;
  showBarcodeSlider = false;
  showCrossReferenceSlider = false;
  toolbarIcons: ToolbarIcon[] = [];
  dynamicDialogRef!: DynamicDialogRef;
  showAddTemplateButton = true;
  loadingMessage = $localize`:@@loadingExperiment:Loading Experiment...`;
  previewLoadingMessage = $localize`:@@loadingPreviewMessage:Loading Preview...`;
  workflowOptions: MenuItem[] = [];
  currentWorkflowState = '';
  currentWorkflowIcon = '';
  currentContextActivityId?: string;
  canRaiseExperimentFlags!: boolean;
  searchControls: Array<SearchControl> = [];
  experimentFlags: Array<ExperimentRaisedFlag> = [];
  activityInputSubscription?: Subscription;
  activityInputSelectedSubscription?: Subscription;
  BarcodeScannerOpenModeOptions: typeof ActivityInputType = ActivityInputType;
  scannerOpenMode?: ActivityInputType;
  instrumentType?: InstrumentType;
  currentClientState = ClientState.EXPERIMENT_COVER;
  addConsumable = new Subject<void>();
  historyIcon = 'fas fa-history';
  activityMenuStyleClass = 'eln-activity-menu-item';
  activityIconClass = 'pi pi-comments';
  consumableId = 'b3dee1b2-7ece-4262-8b02-b74e9f665188';
  toolTip = $localize`:@@TemplateNameSearchPlaceHolder:Search by Template Name or Number`;
  titleOfConsumableAndSupplyTable = $localize`:@@LabItemsConsumablesTableTitle:Consumables and Supplies`;
  newActivity = { label: $localize`:@@InsertOptionAddNewActivity:New activity`, value: TemplateLocationOptions.AddAsNewActivity };
  existingModule = { label: $localize`:@@InsertOptionAddToExistingModule:To existing module`, value: TemplateLocationOptions.AddToExistingModule };
  existingActivityAsModule = { label: $localize`:@@InsertOptionAddToExistingActivityAndNewModule:To existing activity as new module`, value: TemplateLocationOptions.AddAsNewModuleAndExistingActivity }
  loaderSearchCriteria: LoaderSearchCriteria = {};
  isCollaborator = false;
  insertOptions: Array<{ label: string, value: string }> = [];
  isReviewer = this.userService.hasReviewerRights(this.userService.currentUser.puid);
  enableWorkflowAction = !this.readOnly;
  isMandatoryExperimentFieldsProvided = true;
  acceptCallback = () => {};

  private _loading = false;
  get loading(): boolean {
    return this._loading;
  }
  set loading(value: boolean) {
    this._loading = value;
  }

  get routeIsReferencesComponent(): boolean {
    return this.router.url.endsWith('/References');
  }

  get currentActivity(): Activity | undefined {
    return this.experimentService.currentActivity;
  }

  get activityHasCrossReferences(): boolean {
    return (this.currentActivity?.activityReferences.crossReferences.length ?? 0) > 0;
  }

  get activityHasDocumentReferences(): boolean {
    return !!this.currentActivity?.activityReferences.documentReferencesTableId;
  }

  get activityHasCompendiumReferences(): boolean {
    return !!this.currentActivity?.activityReferences.compendiaReferencesTableId;
  }

  readonly includeOnlyInputsEventForAuditHistory: ExperimentEventType[] = [
    ExperimentEventType.ActivityInputCellChanged,
    ExperimentEventType.AliquotAdded,
    ExperimentEventType.ActivityInputAdded,
    ExperimentEventType.ActivityInputRowRefreshed,
    ExperimentEventType.ActivityInputRowRemoved,
    ExperimentEventType.ActivityInputRowRestored,
    ExperimentEventType.ClientFacingNoteChanged,
    ExperimentEventType.ClientFacingNoteCreated,
    ExperimentEventType.MaterialAdded,

    /**
     * Instrument Related Events
     */
    ExperimentEventType.InstrumentAdded,
    ExperimentEventType.InstrumentRemovedFromServiceChanged,
    ExperimentEventType.InstrumentDescriptionChanged,
    ExperimentEventType.InstrumentEventReturnedToService,
    ExperimentEventType.InstrumentDateRemovedChanged,

    /**
     * Selection Events
     */
    ExperimentEventType.StudyActivitySelected,
    ExperimentEventType.MaintenanceEventSelected,
    ExperimentEventType.SampleTestChanged,
  ]

  readonly includeOnlyLabItemsEventForAuditHistory: ExperimentEventType[] = [

    ExperimentEventType.LabItemsCellChanged,
    ExperimentEventType.ClientFacingNoteChanged,
    ExperimentEventType.ClientFacingNoteCreated,
    /**
     * Material Related Events
     */
    ExperimentEventType.LabItemsMaterialAdded,
    ExperimentEventType.LabItemsMaterialRemoved,
    ExperimentEventType.LabItemsMaterialRestored,
    ExperimentEventType.LabItemsMaterialRefreshed,

    /**
     * Instrument Related Events
     */
    ExperimentEventType.LabItemsInstrumentAdded,
    ExperimentEventType.LabItemsInstrumentRemoved,
    ExperimentEventType.LabItemsInstrumentRestored,
    ExperimentEventType.LabItemsInstrumentRefreshed,

    /**
     * Instrument Column Related Events
     */
    ExperimentEventType.InstrumentColumnAdded,
    ExperimentEventType.LabItemsInstrumentColumnRefreshed,
    ExperimentEventType.LabItemsInstrumentColumnRemoved,
    ExperimentEventType.LabItemsInstrumentColumnRestored,

    /**
     * Consumable Related Events
     */
    ExperimentEventType.LabItemsConsumableAdded,
    ExperimentEventType.LabItemsConsumableRemoved,
    ExperimentEventType.LabItemsConsumableRestored,

    /*
     * LabItem Preparation Related Events
     */
    ExperimentEventType.LabItemsPreparationAdded,
    ExperimentEventType.LabItemPreparationRefreshed,
    ExperimentEventType.LabItemPreparationRemoved,
    ExperimentEventType.LabItemPreparationRestored,
  ]

  readonly preparationEventsForAuditHistory: ExperimentEventType[] = [
    /**
     * Events related to Preparations only
     */
    ExperimentEventType.ExperimentPreparationCreated,
    ExperimentEventType.ExperimentPreparationCellChanged,
    ExperimentEventType.ExperimentPreparationDiscardedOrConsumed,
    ExperimentEventType.ExperimentPreparationInternalInformationChanged,
    ExperimentEventType.ExperimentPreparationRemoved,
    ExperimentEventType.ExperimentPreparationRestored,
    ExperimentEventType.ExperimentPreparationStatusChanged,
    ExperimentEventType.ClientFacingNoteChanged,
    ExperimentEventType.ClientFacingNoteCreated
  ]
  private readonly WorkflowStatusNames = {
    setup: $localize`:@@setupState:Setup`,
    inProgress: $localize`:@@inProgressState:In Progress`,
    inReview: $localize`:@@inReviewState:In Review`,
    inCorrection: $localize`:@@inCorrectionState:In Correction`,
    cancelled: $localize`:@@cancelledState:Cancelled`,
    authorized: $localize`:@@authorizedState:Authorized`,
    restored: $localize`:@@restoredState:Restored`
  };

  private readonly WorkflowStatusIcons = {
    setup: 'pi pi-cog',
    inProgress: 'icon-review icon-s',
    inReview: 'pi pi-eye',
    inCorrection: 'icon-correction icon-s',
    cancelled: 'pi pi-file-excel',
    authorized: 'icon-eln1 icon-s',
    restored: 'pi pi-replay'
  };

  experimentOptions = ExperimentOptionsHelper.getOptionsFromRoute();
  subBusinessUnits: SubBusinessUnit[] = [];
  constructor(
    clientStateService: ClientStateService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly experimentService: ExperimentService,
    private readonly userService: UserService,
    private readonly experimentTemplateEventService: ExperimentTemplateEventService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    public readonly experimentEventService: ExperimentEventsService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly dataRecordService: DataRecordService,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly experimentWarningService: ExperimentWarningService,
    private readonly _ruleActionHandlerHostService: RuleActionHandlerHostService,
    private readonly ruleActionNotificationService: RuleActionNotificationService,
    private readonly barcodeScannerHelper: BarcodeScannerHelper,
    private readonly browserTitle: Title,
    private readonly _labItemServiceHost: LabItemsService,
    public readonly activityInputHelper: ActivityInputHelper,
    private readonly commentService: CommentService,
    private readonly featureService: FeatureService,
    private readonly ngZone: NgZone,
    private readonly dataPackageService: DataPackageService,
    private readonly activityReferenceEventsService: ActivityReferenceEventsService,
    private readonly experimentNodeReTitleService: ExperimentNodeRetitleService,
    private readonly templateLoaderService: TemplateLoaderService,
    private readonly instrumentConnectionHelper: InstrumentConnectionHelper,
    private readonly outputEmpowerService: OutputEmpowerService,
    private readonly experimentUserPreferenceService: ExperimentUserPreferenceService,
    public readonly experimentNodeReOrderService: ExperimentNodeReOrderService,
    private readonly authenticationHelperService: AuthenticationHelperService,
    private readonly experimentPreparationService: ExperimentPreparationService,
    private readonly completionTrackingService: CompletionTrackingService,
    public readonly templateApplyService: ExperimentTemplateApplyService,
    public readonly pHMeterService: PhMeterService,
    public readonly instrumentNotificationService: InstrumentNotificationService
  ) {
    super(clientStateService, route);
    this.user = { ...this.userService.currentUser };
    this.createTemplateOptionsQuery();
    this.subscriptions.splice(0, 0,
      this.dataRecordService.fieldChangedDataRecordReceiver.subscribe((data) =>
        this.completionTrackingService.updateCompletionStatusForOtherForm(data)
      ),
      this.dataRecordService.cellChangedDataRecordReceiver.subscribe((data) =>
        this.completionTrackingService.updateCompletionTrackingForOtherTable(data)
      ),
      this.dataRecordService.addRowsDataRecordReceiver.subscribe((data) =>
        this.completionTrackingService.updateCompletionTrackingForOtherTable(data)
      ),
      this.dataRecordService.experimentWorkFlowDataRecordReceiver
        .subscribe((data: WorkflowEventNotification) => this.applyExperimentWorkflowDataRecord(data)),
      this.experimentService.nodeCompletionStatus
        .subscribe((data: NodeCompletionStatus) => this.updateCompletionStatus(data)),
      this.ruleActionNotificationService.SetVariableActionResponse
        .subscribe((data: SetVariableCommand) => this.experimentService.setVariableUsingCommand(data)),
      this.dataRecordService.setVariableDataRecordReceiver
        .subscribe((data: SetVariableEventNotification) => this.experimentService.setVariableUsingCommand(data)),
      this.experimentService.activityCompletionStatus
        .subscribe((activity: Activity) => this.updateMenuItems(activity)),
      this.experimentService.experimentFlags
        .subscribe((flags: Array<ExperimentRaisedFlag>) => {
          this.experimentFlags = flags;
          this.setUpExperimentFlags();
          this.setCurrentWorkflowTransitionAction();
        }),
      this.router.events
        .pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe((_route: { url: string }) => {
          if (_route.url.toLowerCase().includes('/labitems')) {
            if (this.doesConsumableExistsForLabItem())
              this.hideAddConsumableButton = true;
            else this.hideAddConsumableButton = false;
          }
          else this.hideAddConsumableButton = true;
        }),
      this.experimentService.reviewerIsNowCollaborator
        .subscribe(() => {
          this.evaluateCurrentWorkflowTransitionActions()
        }),
      this.experimentService._isCurrentUserCollaboratorSubject$.subscribe((isUserBecameCollaborator: boolean) => {
        this.isCollaborator = isUserBecameCollaborator;
        this.setCurrentWorkflowTransitionAction(isUserBecameCollaborator);
      })
    );
    this.experimentService.instrumentEventRemoved.subscribe(() => this.setStateOfAddInstrumentButton(this.experiment?.workflowState as any));
    experimentNodeReTitleService.ActivityTitleChanged.subscribe({
      next: () => {
        this.loadMenu();
      }
    });

    this.instrumentConnectionHelper.phMeterConnectionSuccess.subscribe(() => {
      this.isBarcodeOpenedManually = false;
      if (pHMeterService.retainConnection()) {
        this.messageService.add({
          key: 'notification',
          severity: 'success',
          summary: $localize`:@@pHMeterConnectionRestored:Connection Restored : Connected to pH Meter`,
          detail: pHMeterService.formattedIdentification('\n'),
          sticky: false
        });
      }
      else {
        this.openBarcodeSlider(ActivityInputType.InstrumentDetails, InstrumentType.phMeter);
      }
    });

    this.barcodeScannerHelper.barcodeScanFailed.subscribe(() => {
      if (this.instrumentType === InstrumentType.phMeter) {
        this.closeBarcodeSlider(false);
        this.pHMeterService.disconnect();
      }
    });

    this.experimentService.barcodeScanned.subscribe(({ scanStatus, scannerMode, instrumentType }) => {
      this.processPhMeterScan(scanStatus, scannerMode, instrumentType);
    });

    this.barcodeScannerHelper.barcodeScanSuccess.subscribe((successScan) => {
      if (successScan && this.instrumentType === InstrumentType.phMeter) {
        this.processPhMeterScan(ActivityInputItemState.Success, ActivityInputType.InstrumentDetails, this.instrumentType);
      }
      else if (!successScan && this.instrumentType === InstrumentType.phMeter) {
        this.processPhMeterScan(ActivityInputItemState.Warning, ActivityInputType.InstrumentDetails, this.instrumentType);
      }
    });

    this.reOrderSubscription.push(experimentNodeReOrderService.nodeOrderChanged.subscribe({
      next: (data) => {
        if (data.nodeType === NodeType.Activity) {
          let activityIndex = -1;
          const activity = this.experiment?.activities.find(
            (act, index) => {
              activityIndex = index;
              return act.activityId === data.nodeId;
            }
          );
          this.experiment?.activities.splice(activityIndex, 1);
          this.experiment?.activities.splice(data.position, 0, activity as Activity);
          this.loadMenu();
        }
      }
    }));

    this.experimentService.experimentWorkFlowState.subscribe({
      next: (_state) => {
        this.loadMenu();
      }
    })
    this.templateLoaderService.fetchFavoriteTemplates();
  }

  @HostListener('ShowSlider', ['$event'])
  onShowSliderEventCaptured(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.showComments.emit(event.detail);
  }

  ngOnInit(): void {
    this.experimentPreparationService.subscribePreparationFieldsFilled();
    this.setLoadingMessage();
    this.loading = true;
    this.watchTemplateAppliedNotification();
    this.validation = new ClientValidationDetails();
    this.user = { ...this.userService.currentUser };
    this.initialize();
    this.experiment = this.experimentService.currentExperiment; // Note: this is suspicious; normal case is that this component is initialized before we have an experiment.
    this.subscriptions.splice(0, 0,
      this.route.paramMap.subscribe((params) => {
        this.experimentNumber = params.get('experimentNumber') ?? undefined;
        if (!this.experimentNumber) return;
        this.route.children[0].data.subscribe((params) => {
          this.currentClientState = params.clientState;
        });
        this.loadExperiment(this.experimentNumber);
      }),
      this.experimentNotificationService.connectionStartedAlert?.subscribe((_success) => {
        this.stopTimer = false;
        this.showReconnectDialog = false;
        this.showConnecting = false;
        this.startTimer();
      }),
      this.experimentNotificationService.reconnectedAlert?.subscribe((_success) => {
        this.stopTimer = false;
        this.showReconnectDialog = false;
        this.showConnecting = false;
        this.startTimer();
      }),
      this.experimentService.getSubBusinessUnits().subscribe(value => {
        this.subBusinessUnits = value.labsites[0].subBusinessUnits;
      }),
      this.experimentNotificationService.connectionLostAlert?.subscribe((_success) => {
        if (!this.isReload) {
          this.stopTimer = true;
          this.showReconnectDialog = true;
        }
      }),
      this.router.events
        .pipe(filter((event: any) => event instanceof ActivationStart))
        .subscribe((data) => {
          this.currentClientState = data.snapshot.data.clientState;
          this.setupBarcodeScanner();
          this.browserTitle.setTitle(this.experimentNumber as string);
        }),
        this.barcodeScannerHelper.instrumentEventDataSourcePrepared.subscribe((data: ActivityInputInstrumentEvent) => {
          if (this.userService.hasOnlyReviewerRights() ||
          (!!data && data.activityInputType === ActivityInputType.Instrument && data.instrumentNumber && data.instrumentNumber.length > 0))
          this.setStateOfAddInstrumentButton(this.experiment?.workflowState as ExperimentWorkflowState);
        else this.disableAddInstrumentEvent = false;
      }),
      this.barcodeScannerHelper.activityInputSelected.subscribe((isActivityInputSelected: boolean) => {
        if (isActivityInputSelected) {
          this.hideAddInstrumentEvent = false;
          this.setStateOfAddInstrumentButton(this.experiment?.workflowState as ExperimentWorkflowState);
        } else {
          this.hideAddInstrumentEvent = true;
        }
      }),
      this.experimentService.beginEditSpecification
        .subscribe({
          next: (context) => {
            this.specificationEditorContext = context;
          }
        }),
      this.experimentService.labItemsConsumableAdded.subscribe(() => {
        this.hideAddConsumableButton = true;
      })
    );
    this.browserTitle.setTitle(this.experimentNumber as string);
    this.watchForMandatoryExperimentFields();
  }

  watchForMandatoryExperimentFields() {
    this.watchForTitleEmpty();
    this.watchForTitleChanged();
    this.watchForTitleNotChanged();
    this.watchForSubBusinessUnitNotSelected();
    this.watchForSubBusinessUnitSelected();
    this.watchForSubBusinessUnitNotChanged();
  }

  get isExperimentAuthorizedOrCancelled() {
    return ExperimentService.isExperimentAuthorizedOrCancelled(this.experiment?.workflowState as ExperimentWorkflowState);
  }

  private watchForTitleEmpty(): void {
    this.experimentService.isExperimentTitleEmpty.subscribe({
      next: (response) => {
        if (response) {
          this.isMandatoryExperimentFieldsProvided = false;
          this.setEnableWorkflowAction();
        }
      }
    });
  }

  processPhMeterScan(scanStatus: ActivityInputItemState, scannerMode: ActivityInputType, instrumentType: InstrumentType) {
    if (scannerMode === ActivityInputType.InstrumentDetails && instrumentType === InstrumentType.phMeter) {
      this.closeBarcodeSlider(false);
      this.messageService.add({
        key: 'notification',
        severity: scanStatus === ActivityInputItemState.Success ? 'success' : 'error',
        summary: '',
        detail: scanStatus === ActivityInputItemState.Success ? $localize`:@@pHMeterInstrumentAddedToTheLabItems:Instrument added to the Lab Item` :
          $localize`:@@pHMeterInstrumentfailedToAddToTheLabItems:Invalid instrument ID`,
        sticky: false
      });
      this.isBarcodeOpenedManually = true;

      if (scanStatus === ActivityInputItemState.Warning) {
        if (this.instrumentConnectionHelper.phName) {
          this.instrumentNotificationService.disconnectFromInstrument(this.instrumentConnectionHelper.phName, InstrumentType.phMeter);
        }
        this.pHMeterService.disconnect();
      }
      else {
        this.pHMeterService.phMeterConnected();
      }
    }
  }

  private watchForTitleChanged(): void {
    this.experimentService.isExperimentTitleChanged.subscribe({
      next: (response) => {
        if (response && this.experiment) {
          this.experiment.title = response;
          this.isMandatoryExperimentFieldsProvided = true;
          this.setEnableWorkflowAction();
        }
      }
    });
  }

  private watchForTitleNotChanged(): void {
    this.experimentService.isExperimentTitleNotChanged.subscribe({
      next: (response) => {
        if (response) {
          this.isMandatoryExperimentFieldsProvided = true;
          this.setEnableWorkflowAction();
        }
      }
    });
  }

  private watchForSubBusinessUnitNotSelected(): void {
    this.experimentService.isSubBusinessUnitNotSelected.subscribe({
      next: (response) => {
        if (response) {
          this.isMandatoryExperimentFieldsProvided = false;
          this.setEnableWorkflowAction();
        }
      }
    });
  }

  private watchForSubBusinessUnitNotChanged(): void {
    this.experimentService.isSubBusinessUnitNotChanged.subscribe({
      next: (response) => {
        if (response) {
          this.isMandatoryExperimentFieldsProvided = true;
          this.setEnableWorkflowAction();
        }
      }
    });
  }

  private watchForSubBusinessUnitSelected(): void {
    this.experimentService.isSubBusinessUnitSelected.subscribe({
      next: (response) => {
        if (response) {
          this.isMandatoryExperimentFieldsProvided = true;
          this.setEnableWorkflowAction();
        }
      }
    });
  }

  private setEnableWorkflowAction(): void {
    this.enableWorkflowAction = !this.readOnly && this.isMandatoryExperimentFieldsProvided;
  }

  setLoadingMessage() {
    this.loadingMessage = ExperimentOptionsHelper.getOptionsFromRoute().previewMode
      ? this.previewLoadingMessage
      : this.loadingMessage;
  }

  updateCompletionStatus(nodeStatus: NodeCompletionStatus) {
    if (!this.experiment) return;

    const existing = this.experiment.experimentCompletionStatus.find(c => c.id === nodeStatus.id);
    if (!existing) {
      this.experiment.experimentCompletionStatus.push(nodeStatus);
    } else {
      existing.isComplete = nodeStatus.isComplete;
    }
  }

  /** Check if all complete, including preparations and show a toast if not */
  checkExperimentCompletion(): boolean {
    if (!this.experiment) return false; // punt

    let isComplete = true; // hypothesis
    let areInputsComplete = true;
    let isPreparationsComplete = this.experimentPreparationService.allPreparationsFieldsFilled; // check for current activity completion
    if (isPreparationsComplete) {
      isPreparationsComplete = this.experimentService.checkPreparationsAreComplete();
    }
    if (this.experiment?.activityInputs) {
      areInputsComplete = this.experimentService.checkInputsCompletion(this.experiment.activityInputs);
    }
    for (const nodeStatus of this.experiment.experimentCompletionStatus) {
      if (nodeStatus.isComplete === false || !isPreparationsComplete || !areInputsComplete) {
        isComplete = false;
        break;
      }
    }
    if (!isComplete) {
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        id: 'allFieldsNotCompleteMsg',
        summary: $localize`:@@notAllFieldsCompleteSummary:Not all fields are complete`,
        detail: $localize`:@@allFieldsNotComplete:All fields must be complete before transitioning the experiment.`,
        sticky: false
      });
    }
    return isComplete;
  }

  applyExperimentWorkflowDataRecord(data: WorkflowEventNotification) {
    const receivedWorkflowState = data.state;
    this.setStateOfAddReferenceButtons(receivedWorkflowState);
    this.setStateOfAddTemplateButton(receivedWorkflowState);
    this.setStateOfAddInstrumentButton(receivedWorkflowState);
    this.setStateOfAddConsumableButton(receivedWorkflowState);
    this.setUpExperimentFlags();
    switch (receivedWorkflowState) {
      case ExperimentWorkflowState.InReview:
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.inReview}"`,
          $localize`:@@successSentForReviewMessageDetails:The experiment was sent for review successfully by ${data.eventContext.puid
            } current experiment status is '${this.WorkflowStatusNames.inReview}'.`
        );
        break;
      case ExperimentWorkflowState.InCorrection:
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.inCorrection}"`,
          $localize`:@@successSentForCorrectionMessageDetails:The experiment was sent for correction successfully by ${data.eventContext.puid
            } current experiment status is '${this.WorkflowStatusNames.inCorrection}'.`
        );
        break;
      case ExperimentWorkflowState.Cancelled:
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.cancelled}"`,
          $localize`:@@successCancelledMessageDetails:The experiment was cancelled successfully by ${data.eventContext.puid
            } current experiment status is '${this.WorkflowStatusNames.cancelled}'.`
        );
        break;
      case ExperimentWorkflowState.Authorized:
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.authorized}"`,
          $localize`:@@successAuthorizedMessageDetails:The experiment was authorized successfully by ${data.eventContext.puid
            } current experiment status is '${this.WorkflowStatusNames.authorized}'.`
        );
        break;
      case ExperimentWorkflowState.Setup:
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.restored}"`,
          $localize`:@@successRestoredMessageDetails:The experiment was restored successfully by ${data.eventContext.puid
            } current experiment status is '${this.WorkflowStatusNames.setup}'.`
        );
        break;
      default:
        this.createSuccessNotificationMessage(
          $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.inProgress}"`,
          $localize`:@@successStartMessageDetails:The experiment was started successfully by ${data.eventContext.puid
            } current experiment status is '${this.WorkflowStatusNames.inProgress}'.`
        );
    }

    if (this.experiment) {
      this.experiment.workflowState = receivedWorkflowState;
      this.evaluateCurrentWorkflowTransitionActions();
      this.setupBarcodeScanner();
      this.loadMenu();
    }
  }
  windowVar = window as any;
  startExperiment = () => {
    if (this.experiment) {
      const startObs = this.experimentEventService.experimentEventsExperimentIdStartPost$Json({
        experimentId: this.experiment.id
      });
      this.processResponse(
        startObs,
        (experimentStartResponse: ExperimentStartedResponse, experiment: Experiment) => {
          experiment.workflowState =
            experimentStartResponse.experimentStartedEventNotification.state;
          this.createSuccessNotificationMessage(
            $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.inProgress}"`,
            $localize`:@@experimentStartedMessageDetails:The experiment has been started successfully`
          );
          this.experimentService.experimentWorkFlowState.next(ExperimentWorkflowState.InProgress);
          this.setStateOfAddReferenceButtons(experiment?.workflowState);
          this.setStateOfAddTemplateButton(experiment?.workflowState);
          this.setStateOfAddInstrumentButton(experiment?.workflowState);
          this.setStateOfAddConsumableButton(experiment?.workflowState);
          this.setUpExperimentFlags();
          this.setCurrentWorkflowTransitionAction();
          this.setupBarcodeScanner();
        }
      );
    }
  };

  cancelExperiment = () => {
    if (this.experiment) {
      const cancelObs = this.experimentEventService.experimentEventsExperimentIdCancelPost$Json({
        experimentId: this.experiment.id
      });
      this.processResponse(
        cancelObs,
        (experimentCancelledResponse: ExperimentCancelledResponse, experiment: Experiment) => {
          experiment.workflowState =
            experimentCancelledResponse?.experimentCancelledEventNotification?.state;
          this.createSuccessNotificationMessage(
            $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.cancelled}"`,
            $localize`:@@experimentCancelledMessageDetails:The experiment has been cancelled successfully`
          );
          this.experimentService.experimentWorkFlowState.next(ExperimentWorkflowState.Cancelled);
          this.setStateOfAddReferenceButtons(experiment?.workflowState);
          this.setStateOfAddTemplateButton(experiment?.workflowState);
          this.setStateOfAddInstrumentButton(experiment?.workflowState);
          this.setStateOfAddConsumableButton(experiment?.workflowState);
          this.setUpExperimentFlags();
          this.setCurrentWorkflowTransitionAction();
          this.setupBarcodeScanner();
        }
      );
    }
  };

  restoreExperiment = () => {
    if (this.experiment) {
      const cancelObs = this.experimentEventService.experimentEventsExperimentIdRestorePost$Json({
        experimentId: this.experiment.id
      });
      this.processResponse(
        cancelObs,
        (experimentRestoredResponse: ExperimentRestoredResponse, experiment: Experiment) => {
          if (experimentRestoredResponse.hasOwnProperty('experimentRestoredEventNotification')) {
            experiment.workflowState =
              experimentRestoredResponse?.experimentRestoredEventNotification?.state;
          }
          this.createSuccessNotificationMessage(
            $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.restored}"`,
            $localize`:@@experimentRestoredMessageDetails:The experiment has been restored successfully`
          );
          this.experimentService.experimentWorkFlowState.next(experiment.workflowState);
          this.setStateOfAddReferenceButtons(experiment?.workflowState);
          this.setStateOfAddTemplateButton(experiment?.workflowState);
          this.setStateOfAddInstrumentButton(experiment?.workflowState);
          this.setStateOfAddConsumableButton(experiment?.workflowState);
          this.setUpExperimentFlags();
          this.setCurrentWorkflowTransitionAction();
          this.setupBarcodeScanner();
        }
      );
    }
  };

  sendExperimentForReview = () => {
    if (this.experiment) {
      const sendForReviewObs =
          this.experimentEventService.experimentEventsExperimentIdSendForReviewPost$Json({
            experimentId: this.experiment.id
          });
        this.processResponse(
          sendForReviewObs,
          (experimentStartResponse: ExperimentSentForReviewResponse, experiment: Experiment) => {
            experiment.workflowState =
              experimentStartResponse.experimentSentForReviewEventNotification.state;
            this.createSuccessNotificationMessage(
              $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.inReview}"`,
              $localize`:@@experimentSentForReviewMessageDetails:The experiment has been sent for review successfully`
            );
            this.experimentService.experimentWorkFlowState.next(ExperimentWorkflowState.InReview);
            this.setStateOfAddReferenceButtons(experiment?.workflowState);
            this.setStateOfAddTemplateButton(experiment?.workflowState);
            this.setStateOfAddInstrumentButton(experiment?.workflowState);
            this.setStateOfAddConsumableButton(experiment?.workflowState);
            this.setUpExperimentFlags();
            this.evaluateCurrentWorkflowTransitionActions();
            this.setupBarcodeScanner();
          }
        );
    }
  };

  sendExperimentForCorrection = () => {
    if (this.experiment) {
      const sendForCorrectionObs =
        this.experimentEventService.experimentEventsExperimentIdSendForCorrectionPost$Json({
          experimentId: this.experiment.id
        });
      this.processResponse(
        sendForCorrectionObs,
        (experimentStartResponse: ExperimentSentForCorrectionResponse, experiment: Experiment) => {
          experiment.workflowState =
            experimentStartResponse.experimentSentForCorrectionEventNotification.state;
          this.createSuccessNotificationMessage(
            $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.inCorrection}"`,
            $localize`:@@experimentSentForCorrectionMessageDetails:The experiment has been sent for correction successfully`
          );
          this.experimentService.experimentWorkFlowState.next(ExperimentWorkflowState.InCorrection);
          this.setStateOfAddReferenceButtons(experiment?.workflowState);
          this.setStateOfAddTemplateButton(experiment?.workflowState);
          this.setStateOfAddInstrumentButton(experiment?.workflowState);
          this.setStateOfAddConsumableButton(experiment?.workflowState);
          this.setUpExperimentFlags();
          this.setCurrentWorkflowTransitionAction();
          this.setupBarcodeScanner();
        }
      );
    }
  };

  authorizeExperiment = () => {
    if (this.experiment) {
      if (!this.checkExperimentCompletion()) {
        return;
      } else {
        if (this.showNotificationWhenPendingPreparationsAreFound()) return;
        const authorizeObs =
          this.experimentEventService.experimentEventsExperimentIdAuthorizePost$Json({
            experimentId: this.experiment.id
          });
        this.processResponse(
          authorizeObs,
          (experimentStartResponse: ExperimentAuthorizedResponse, experiment: Experiment) => {
            experiment.workflowState =
              experimentStartResponse.experimentAuthorizedEventNotification.state;
            this.createSuccessNotificationMessage(
              $localize`:@@transitionedTo:Transitioned to "${this.WorkflowStatusNames.authorized}"`,
              $localize`:@@experimentAuthorizedMessageDetails:The experiment has been authorized successfully`
            );
            this.experimentService.experimentWorkFlowState.next(ExperimentWorkflowState.Authorized);
            this.setStateOfAddReferenceButtons(experiment?.workflowState);
            this.setStateOfAddTemplateButton(experiment?.workflowState);
            this.setStateOfAddInstrumentButton(experiment?.workflowState);
            this.setStateOfAddConsumableButton(experiment?.workflowState);
            this.setUpExperimentFlags();
            this.setCurrentWorkflowTransitionAction();
            this.setupBarcodeScanner();
          }
        );
      }
    }
  };

  private showNotificationWhenPendingPreparationsAreFound() {
    const hasPendingPreparations = this.experimentService.checkAnyExperimentPreparationsInPending();
    if (hasPendingPreparations) {
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        id: 'somePreparationsPendingMsg',
        summary: $localize`:@@somePreparationsPendingSummary:Cannot be Authorized`,
        detail: $localize`:@@somePreparationsPending:Some Preparations are Pending`,
        sticky: false
      });
      return hasPendingPreparations;
    }
    const hasPendingLabItemsPreparations = this.experimentService.checkAnyLabItemsPreparationsInPending();
    if (hasPendingLabItemsPreparations) {
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        id: 'someLabItemsPreparationsPendingMsg',
        summary: $localize`:@@somePreparationsPendingSummary:Cannot be Authorized`,
        detail: $localize`:@@SomeLabItemsPreparationsPending:Some Preparations lab items are not reviewed`,
        sticky: false
      });
      return hasPendingLabItemsPreparations;
    }
    return false;
  }
  private readonly confirmStartExperiment = () => {
    const confirmationMessage = $localize`:@@experimentStartConfirmation:Please confirm you wish to start the experiment.`;
    this.confirmWorkflowStateTransition(confirmationMessage, () => this.startExperiment());
  };

  private readonly confirmCancelExperiment = () => {
    const confirmationMessage = $localize`:@@experimentCancelConfirmation:Please confirm you wish to CANCEL the experiment.`;
    this.confirmWorkflowStateTransition(confirmationMessage, () =>
      this.reauthBeforeExperimentTransition(this.cancelExperiment.bind(this))
    );
  };
  private readonly confirmRestoreExperiment = () => {
    const confirmationMessage = $localize`:@@experimentRestoreConfirmation:Please confirm you wish to Restore the experiment.`;
    this.confirmWorkflowStateTransition(confirmationMessage, () =>
      this.reauthBeforeExperimentTransition(this.restoreExperiment.bind(this))
    );
  };

  private readonly confirmSendForReview = () => {
    // Consider an extra call here before asking the use to confirm something that won't be allowed (or refactor), such as: if (!this.checkExperimentCompletion()) return;
    const confirmationMessage = $localize`:@@experimentSendForReviewConfirmation:Please confirm you wish to transition the experiment to "In Review".`;
    this.acceptCallback = () => {
      if (!this.checkExperimentCompletion()) {
        return;
      }
      this.reauthBeforeExperimentTransition(this.sendExperimentForReview.bind(this))
    }
    this.confirmWorkflowStateTransition(confirmationMessage, this.acceptCallback);
  };

  private readonly confirmSendForCorrection = () => {
    const confirmationMessage = $localize`:@@experimentSendForCorrectionConfirmation:Please confirm you wish to send the experiment for correction.`;
    this.confirmWorkflowStateTransition(confirmationMessage, () =>
      this.reauthBeforeExperimentTransition(this.sendExperimentForCorrection.bind(this))
    );
  };

  private readonly confirmAuthorization = () => {
    /**
     * Before moving experiment to authorized state if any preparations are pending then should not call fams authentication
     */
    if (this.showNotificationWhenPendingPreparationsAreFound()) {
      return
    }
    const confirmationMessage = $localize`:@@experimentAuthorizeConfirmation:Please confirm you wish to authorize the experiment.`;
    this.confirmWorkflowStateTransition(confirmationMessage, () => {
      this.reauthBeforeExperimentTransition(this.authorizeExperiment.bind(this));
    }
    );
  };

  setCurrentWorkflowTransitionAction(isCurrentUserCollaborator = false): void {
    this.currentWorkflowState =
      this.WorkflowStatusNames[this.experiment?.workflowState as ExperimentWorkflowState];
    this.currentWorkflowIcon =
      this.WorkflowStatusIcons[this.experiment?.workflowState as ExperimentWorkflowState];

    const workflowActions = [];
    if (this.isTransitionValidForStart()) {
      workflowActions.push({
        id: 'eln-btnStart',
        label: $localize`:@@StartExperimentButton:Start Experiment`,
        icon: this.WorkflowStatusIcons[ExperimentWorkflowState.InProgress],
        command: () => this.confirmStartExperiment()
      });
    }
    if (this.isTransitionValidForCancel()) {
      const isCancelExperimentButtonEnabled = this.canPendingCancelFlag &&
        this.canPendingCancelFlag.raisedBy.value.toLocaleLowerCase() !==
        this.user.puid.toLocaleLowerCase() &&
        this.canCancelExperiment;

      workflowActions.push({
        id: 'eln-btnCancel',
        label: $localize`:@@CancelExperiment:Cancel Experiment`,
        icon: this.WorkflowStatusIcons[ExperimentWorkflowState.Cancelled],
        disabled: !isCancelExperimentButtonEnabled,
        tooltipOptions: {
          tooltipLabel: $localize`:@@cancelExperimentTooltipMessage:Only a Supervisor who did not raise the Pending Cancel flag can Cancel an Experiment`,
          disabled: isCancelExperimentButtonEnabled
        },
        command: () => this.confirmCancelExperiment()
      });
    }
    if (
      this.experiment &&
      ((this.experiment.workflowState === ExperimentWorkflowState.InProgress &&
        this.canSendExperimentForReviewFromInProgressFlag) ||
        (this.experiment.workflowState === ExperimentWorkflowState.InCorrection &&
          this.canSendExperimentForReviewFromCorrectionFlag))
    ) {
      workflowActions.push({
        id: 'eln-btnSendForReview',
        label: $localize`:@@ExperimentSendForReview:Send to Review`,
        icon: this.WorkflowStatusIcons[ExperimentWorkflowState.InReview],
        command: () => this.confirmSendForReview()
      });
    }
    if (
      this.experiment &&
      ((this.experiment.workflowState === ExperimentWorkflowState.InReview &&
        this.canSendExperimentForCorrectionFromReviewFlag) ||
        (this.experiment.workflowState === ExperimentWorkflowState.Authorized &&
          this.canSendExperimentForCorrectionFromAuthorizedStateFlag))
    ) {
      workflowActions.push({
        id: 'eln-btnSendForCorrection',
        label: $localize`:@@ExperimentSendForCorrection:Send for Correction`,
        icon: this.WorkflowStatusIcons[ExperimentWorkflowState.InCorrection],
        command: () => this.confirmSendForCorrection()
      });
    }
    if (
      this.experiment &&
      this.experiment.workflowState === ExperimentWorkflowState.InReview
    ) {
      workflowActions.push(this.getExperimentAuthorizeMenuOption(isCurrentUserCollaborator));
    }
    if (this.isTransitionValidForRestore()) {
      workflowActions.push({
        id: 'eln-btnRestore',
        label: $localize`:@@RestoreExperiment:Restore Experiment`,
        icon: this.WorkflowStatusIcons[ExperimentWorkflowState.Restored],
        command: () => this.confirmRestoreExperiment()
      });
    }
    this.outputEmpowerService.setStateForEmpowerOperations(
      this.experiment?.workflowState as ExperimentWorkflowState,
      this.userService.hasAnalystRights(this.userService.currentUser.puid),
      this.userService.hasReviewerRights(this.userService.currentUser.puid),
      this.userService.hasSupervisorRights(this.userService.currentUser.puid)
    );
    this.workflowOptions = workflowActions;
  }

  onContextMenuClick(event: any) {
    const activityTitle = event.target.innerText;
    const activity = this.experimentService.currentActivity;
    if (activityTitle !== activity?.itemTitle) {
      const currentClickedContextActivity = this.experiment?.activities.find((act) => act.itemTitle === activityTitle);
      this.currentContextActivityId = currentClickedContextActivity?.activityId;
    } else {
      this.currentContextActivityId = this.experimentService.currentActivityId;
    }
  }

  isTransitionValidForCancel() {
    return (
      this.experiment &&
      (this.experiment.workflowState === ExperimentWorkflowState.InProgress ||
        this.experiment.workflowState === ExperimentWorkflowState.Setup ||
        this.experiment.workflowState === ExperimentWorkflowState.InCorrection)
    );
  }

  isTransitionValidForStart() {
    return (
      this.experiment &&
      this.experiment.workflowState === ExperimentWorkflowState.Setup &&
      this.canStartExperimentFlag
    );
  }
  isTransitionValidForRestore() {
    return (
      this.experiment &&
      this.experiment.workflowState === ExperimentWorkflowState.Cancelled &&
      this.canRestoreExperiment
    );
  }
  closeExperiment() {
    if (window.opener?.location?.pathname?.startsWith('/bookshelf')) {
      window.close();
      window.opener.focus();
    } else {
      this.router.navigate(['/'], { replaceUrl: true });
    }
  }

  getErrorMessage(message: string): Message {
    return {
      severity: 'error',
      summary: $localize`:@@error:Error`,
      detail: message
    };
  }

  /** This notification for template added by self */
  private watchTemplateAppliedNotification() {
    this.experimentTemplateEventService.TemplateApplied.subscribe(response => {
      // Loads the new node subtree indirectly by reloading the whole tree. This makes some sense to observing browser. (But see #)
      // Even the browser that applied the template won't have or see the cloned subtree with its generated nodeIds until then.
      this.reloadExpt(response.templateAppliedEventNotification.templateTitle);
    });
  }

  loadInsertOptionsForExperiment() {
    this.insertOptions = ExperimentTemplateApplyService.loadInsertOptionsForTemplateLoader(this.experiment as Experiment);
  }

  private reloadExpt(templateTitle: string): void {
    this.loadingAppliedTemplate = true;
    this.loadExperiment(
      this.experimentService.currentExperiment?.experimentNumber as string,
      true,
      templateTitle
    );
  }

  private showTemplateAppliedSuccessMessage(templateTitle: string) {
    this.successMessages.push({
      severity: 'success',
      summary: $localize`:@@success:Success`,
      detail: $localize`:@@templateApplySuccess:Template: ${templateTitle} Loaded successfully`
    });
  }

  addTemplateEvent(): void {
    if (this.experimentWarningService.isUserAllowedToEdit) {
      this.addTemplate();
      return;
    }
  }

  addTemplate(): void {
    this.successMessages = [];
    this.showBarcodeSlider = false;
    this.showCrossReferenceSlider = false;
    this.showTemplateLoader = true;
  }
  addLabItemsConsumable(): void {
    this.hideAddConsumableButton = true;
    this.experimentService.addLabItemsConsumable.next();
  }

  showCrossReferencesSliderEvent(): void {
    this.showCrossReferenceSlider = true;
  }

  addDocumentsTableEvent(): void {
    this.applyReferenceTemplate(ReferenceTemplateType.Documents);
  }

  addCompendiaTableEvent(): void {
    this.applyReferenceTemplate(ReferenceTemplateType.Compendia);
  }

  openBarcodeSlider(barcodeScannerOpenMode: ActivityInputType, instrumentType?: InstrumentType): void {
    if (this.scannerOpenMode === barcodeScannerOpenMode && this.showBarcodeSlider) return;
    this.instrumentType = instrumentType;
    this.showBarcodeSlider = false;
    setTimeout(() => {
      this.showBarcodeSlider = true;
    }, 100)

    this.scannerOpenMode = barcodeScannerOpenMode;
  }
  closeBarcodeSlider(isManualTrigger = true): void {
    this.showBarcodeSlider = false;
    if (isManualTrigger && this.instrumentType === InstrumentType.phMeter) {
      this.experimentService.barcodeScanned.next({
        scanStatus: ActivityInputItemState.Warning,
        scannerMode: this.scannerOpenMode as ActivityInputType,
        instrumentType: this.instrumentType as InstrumentType
      });
    }
  }

  specificationValueChanged(newValue: SpecificationValue): void {
    this.specificationEditorContext?.onChange.next(newValue);
  }

  closeSpecificationInputSlider(): void {
    this.specificationEditorContext?.onChange.complete();
    this.specificationEditorContext?.onClose?.next(undefined as never);
    this.specificationEditorContext?.onClose?.complete();
    this.specificationEditorContext = undefined; // destroys slider; ready to create a new one when needed.
  }

  crossReferenceSliderClosed(): void {
    this.showCrossReferenceSlider = false;
  }

  templateSelectionChanged(_templateLoaderResult: SelectedTemplate): void {
    this.templateApplyService.templateSelectionChanged(_templateLoaderResult);
  }

  templateLoaderFilterChanged(filterData: TemplateLoaderFilterChangedEvent) {
    ExperimentTemplateApplyService.templateLoaderAlterFilter(
      filterData.selectedInsertOption, this.searchControls, filterData.fieldName)
  }

  templateLoaderFilterCleared(insertLocationOptions: string) {
    this.searchControls = ExperimentTemplateApplyService.constructFilters(insertLocationOptions, this.subBusinessUnits)
  }

  templateSelected(selectedTemplateInformation: SelectedTemplateCommand) {
    this.templateApplyService.applySelectedTemplate(selectedTemplateInformation);
  }

  templateLoaderInsertOptionChanged(insertOption: string) {
    this.searchControls = ExperimentTemplateApplyService.constructFilters(insertOption, this.subBusinessUnits);
    const sourceTemplateType = ExperimentTemplateApplyService.MapperToSourceTemplateTypeBasedOnInsertOption[insertOption]
    this.insertLocationOptions = this.templateApplyService.prepareInsertLocationOptionsBySourceTemplateType(sourceTemplateType);
    this.loaderSearchCriteria.templateSearchCriteria = this.templateApplyService.createTemplateOptionsQuery(insertOption);
  }

  createTemplateOptionsQuery() {
    this.loaderSearchCriteria.templateSearchCriteria = {
      getLatestVersion: true,
      templateTypes: `${TemplateType.Activity},${TemplateType.Module},${TemplateType.Form},${TemplateType.Table}`,
      consumingLabsiteCodes: this.user.labSiteCode
    };
  }

  updateTemplateSelection(_templateLoaderResult: TemplateLoaderResult<SelectedTemplate>): void {
    this.showTemplateLoader = false;
  }

  getBarcodeScannerIcon(): ToolbarIcon[] {
    return [
      {
        icons: [
          {
            label: $localize`:@@BarcodeScanner:Barcode scanner`,
            text: $localize`:@@Barcode:Barcode`,
            icon: 'pi pi-qrcode',
            command: () => this.openBarcodeSlider(ActivityInputType.Aliquot)
          }
        ],
        position: 'left'
      }
    ];
  }

  private loadExperiment(
    experimentNumber: string,
    notifyActiveTemplate = false,
    appliedTemplateName = ''
  ) {
    this.experimentService
      .loadExperiment(experimentNumber)
      .pipe(take(1))
      .subscribe((experiment) => {
        this.experiment = experiment;
        this.loadMenu();
        this.loading = false;
        this.isInvalid = !experiment;
        if (notifyActiveTemplate) {
          this.experimentTemplateEventService.ExperimentHasRefreshed(this.experiment as Experiment);
          this.showTemplateAppliedSuccessMessage(appliedTemplateName);
        }
        if (this.isInvalid) {
          this.errorMessages = [
            this.getErrorMessage(
              $localize`:@@noExperimentFound:No Experiment found for ${this.experimentNumber}`
            )
          ];
        }
        this.canEditExperimentInReviewStateFlag =
          this.clientStateService
            .getFeatureFlags(this.clientState)
            .some(data => JSON.parse(data).CanEditExperimentInReviewState);
        this.experimentFlags = this.experimentService.currentExperimentResponse?.experimentFlag.raisedFlags ?? [];
        this.setUpExperimentFlags();
        this.evaluateCurrentWorkflowTransitionActions();
        if (this.experiment) {
          this.setStateOfAddReferenceButtons(this.experiment.workflowState);
          this.setStateOfAddTemplateButton(this.experiment.workflowState);
          this.setStateOfAddInstrumentButton(this.experiment.workflowState);
          this.setStateOfAddConsumableButton(this.experiment.workflowState);
          this.initializeActivityCompletionInfo(this.experiment.activities);
          this.initializeLabItemsCompletionInfo(this.experiment.activityLabItems);
          this.setupBarcodeScanner();
          this.loadInsertOptionsForExperiment();
        }
        this.experimentUserPreferenceService.loadExperimentUserPreferences()
      });
  }

  initializeActivityCompletionInfo(activityArray: Activity[]) {
    if (!this.experiment) return;

    for (const activity of activityArray) {
      // Note: modules have no data of their own today, so considering only their children
      // lab items is processed separately see updateLabItemsCompletionInfo
      this.experiment.experimentCompletionStatus.push({
        title: [ activity.itemTitle, ActivityReferencesPseudoModuleTitle, $localize`:@@CrossReferences:Cross References` ].join(),
        id: `${activity.activityId}/crossReferences`,
        isComplete: ExperimentService.isCrossReferencesComplete(activity.activityReferences.crossReferences),
      });
      for (const module of activity.dataModules) {
        // Note: modules have no data of their own today, so considering only their children
        for (const item of module.items) {
          if (item.itemType === NodeType.Table) {
            this.experiment.experimentCompletionStatus.push({
              title: item.itemTitle,
              id: (item as Table).tableId,
              isComplete: this.experimentService.isTableComplete(item as Table)
            });
          } else if (item.itemType === NodeType.Form) {
            this.experiment.experimentCompletionStatus.push({
              title: item.itemTitle,
              id: (item as Form).formId,
              isComplete: this.experimentService.isFormComplete(item as FormWithFieldDefinitionsResponse)
            });
          }
        }
      }
    }
  }

  initializeLabItemsCompletionInfo(labItemsNodes: ActivityLabItemsNode[]) {
    if (!this.experiment) return;

    for (const labItem of labItemsNodes) {
      this.experiment.experimentCompletionStatus.push({
        title: this.titleOfConsumableAndSupplyTable,
        id: this.consumableId,
        isComplete: this.experimentService.isConsumableComplete(labItem as any)
      });
    }
  }

  private setStateOfAddTemplateButton(data: ExperimentWorkflowState) {
    this.disableAddTemplateButton =
      this.userService.hasOnlyReviewerRights() ||
      ExperimentService.isExperimentAuthorizedOrCancelled(data) ||
      data === ExperimentWorkflowState.InReview;
    this.disableAddTemplateButton = this.disableAddTemplateButton || !this.canAddTemplate;
  }

  private setStateOfAddInstrumentButton(data: ExperimentWorkflowState) {
    this.disableAddInstrumentEvent = this.userService.hasOnlyReviewerRights() ||
      ExperimentService.isExperimentAuthorizedOrCancelled(data) || this.doesInstrumentEventExistsForActivityInput() ||
      ExperimentService.isExperimentInReview(data);
    //This has created regression issues for labitems and hence commented. Ideally, ActivityId should never be set to empty programmatically
    //this.experimentService.currentActivityId = '';
  }

  private setStateOfAddConsumableButton(data: ExperimentWorkflowState) {
    this.disableAddConsumable = this.userService.hasOnlyReviewerRights() ||
      (data !== ExperimentWorkflowState.Setup &&
        data !== ExperimentWorkflowState.InProgress &&
        data !== ExperimentWorkflowState.InCorrection);
  }

  private setStateOfAddReferenceButtons(state: ExperimentWorkflowState) {
    this.disableAddReferences =
      this.userService.hasOnlyReviewerRights()
      || state === ExperimentWorkflowState.InReview
      || state === ExperimentWorkflowState.Authorized
      || state === ExperimentWorkflowState.Cancelled;
  }

  private loadMenu() {
    if (this.experiment) {
      const activityItems = this.experiment.activities.map((activity) => {
        const activityMenuItem: MainMenuItem = {
          label: activity?.itemTitle?.trim().replace(/\s+/g, ' ') ?? '',
          icon: 'far fa-file-alt',
          routerLink: `${elnEncodeSpecialChars(activity.itemTitle?.trim().replace(/\s+/g, ' '))}/Modules`,
          id: `eln-menu-activity-${replace(activity.itemTitle?.trim().replace(/\s+/g, ' '), / /g, '')}`,
          tooltipOptions: {
            tooltipLabel: activity?.itemTitle?.trim().replace(/\s+/g, ' ') ?? ''
          },
          items: this.buildActivitySubMenu(activity)
        } as MainMenuItem;
        activityMenuItem.contextMenuItems = this.buildActivityContextMenuItems(activity, activityMenuItem);
        return activityMenuItem;
      });
      // Cover is always available, and is shown first
      const coverItem: MainMenuItem = {
        label: $localize`:@@coverPageTitle:Cover`,
        routerLink: 'cover',
        id: 'eln-menu-cover',
        contextMenuItems: this.buildCoverPageContextMenuItems(),
        tooltipOptions: {
          tooltipLabel: $localize`:@@coverPageTitle:Cover`
        },
        icon: 'pi pi-book'
      };
      const dataPackage: MainMenuItem = {
        label: $localize`:@@DataPackage:Data Package`,
        id: 'eln-menu-dataPackage',
        command: () => {
          this.loadDataPackageSlider();
        },
        tooltipOptions: {
          tooltipLabel: $localize`:@@DataPackage:Data Package`
        },
        icon: 'icon-outline-briefcase'
      };
      // We'll include Views last, even though we may not needed it
      // Add viewsItem to menuItems below to include views once more.
      // Removed because currently not implemented as per Bug 2890929: Remove Views button appearing on left nav
      const viewsItem: MainMenuItem = {
        label: $localize`:@@viewsPageTitle:Views`,
        routerLink: 'views',
        icon: 'far fa-eye',
        id: 'eln-menu-views',
        tooltipOptions: {
          tooltipLabel: $localize`:@@viewsPageTitle:Views`
        }
      };

      this.menuItems = [coverItem, ...activityItems, dataPackage];
    } else {
      this.menuItems = [];
    }
  }

  loadDataPackageSlider() {
    this.dataPackageService.openSliderPanel(this.experiment?.activities);
  }

  private buildActivityContextMenuItems(activity: Activity, activityMenuItem: MainMenuItem) {
    return [
      {
        label: $localize`:@@internalComments:Internal Comments`,
        icon: this.activityIconClass,
        id: `eln-context-ic-activity-${replace(activity.itemTitle, / /g, '')}`,
        command: () => {
          this.loadInternalCommentsForActivityLevel();
        }
      },
      {
        label: $localize`:@@history:History`,
        icon: this.historyIcon,
        id: `eln-context-activity-${replace(activity.itemTitle, / /g, '')}`,
        command: () => {
          this.loadActivityAuditHistory(activity?.activityId ?? '', activity?.itemTitle);
        }
      },
      this.experimentNodeReTitleService.bindRetitleActivityMenuItemContext(activity, activityMenuItem)
    ];
  }

  private buildCoverPageContextMenuItems(): MenuItem[] | undefined {
    return [
      {
        label: $localize`:@@internalComments:Internal Comments`,
        icon: this.activityIconClass,
        id: 'eln-contextInternalCommentsCover',
        command: () => {
          this.loadInternalCommentsForExperimentLevel();
        }
      },
      {
        label: $localize`:@@history:History`,
        icon: this.historyIcon,
        id: 'eln-contextAuditHistoryCover',
        command: () => {
          this.loadCoverAuditHistory($localize`:@@coverPageTitle:Cover`);
        }
      }
    ];
  }

  historyIconLabel: { [key: string]: string } = {
    "label": $localize`:@@history:History`, "icon": 'fas fa-history',
  }

  activityMenuItemStyleClass: { [key: string]: string } = {
    styleClass: this.activityMenuStyleClass
  }

  buildActivitySubMenu(act: Activity) {
    const items = [];

    if (this.featureService.isEnabled(ELNFeatureFlags.CanShowReferences)) {
      items.push({
        id: `eln-menu-references-${act.activityId}`,
        label: $localize`:@@references:References`,
        styleClass: this.activityMenuStyleClass,
        routerLink: `${elnEncodeSpecialChars(act?.itemTitle)}/References`,
        contextMenuComparatorPropertyName: 'id',
        contextMenuItems: [
          {
            label: $localize`:@@history:History`,
            icon: this.historyIcon,
            id: `eln-context-activity-${replace(act.itemTitle, / /g, '')}`,
            command: () => {
              this.loadReferencesAuditHistory(act.activityId);
            }
          },
          {
            label: $localize`:@@internalComments:Internal Comments`,
            icon: this.activityIconClass,
            id: 'eln-contextInternalCommentsCover',
            command: (() => {
              this.loadInternalCommentsForReferencesLevel();
            })
          }
        ],
      });
    }

    items.push(...[
      {
        id: `eln-menu-activityInput-${act.activityId}`,
        label: $localize`:@@activityInputPageTitle:Inputs`,
        routerLink: `${elnEncodeSpecialChars(act?.itemTitle)}/Inputs`,
        styleClass: this.activityMenuStyleClass,
        contextMenuItems: [
          {
            label: $localize`:@@history:History`,
            icon: this.historyIcon,
            id: `eln-context-activity-${replace(act.itemTitle, / /g, '')}`,
            command: () => {
              this.loadActivityInputAuditHistory(act?.activityId ?? '');
            }
          },
          {
            label: $localize`:@@internalComments:Internal Comments`,
            icon: this.activityIconClass,
            id: 'eln-contextInternalCommentsInputs',
            command: () => {
              this.loadInternalCommentsForInputLevel();
            }
          }
        ],
      },
      {
        id: `eln-menu-activity-labitems-${replace(act.itemTitle, / /g, '')}`,
        label: $localize`:@@activityLabItemsPageTitle:Lab Items`,
        routerLink: `${elnEncodeSpecialChars(act?.itemTitle)}/LabItems`,
        styleClass: this.activityMenuStyleClass,
        contextMenuComparatorPropertyName: 'id',
        contextMenuItems: [
          {
            label: $localize`:@@history:History`,
            icon: this.historyIcon,
            id: `eln-context-labItems-${replace(act.itemTitle, / /g, '')}`,
            command: () => {
              this.loadLabItemsAuditHistory();
            }
          },
          {
            label: $localize`:@@internalComments:Internal Comments`,
            icon: this.activityIconClass,
            id: `eln-context-internal-comments-${replace(act.itemTitle, / /g, '')}`,
            state: {
              targetId: act.activityId
            },
            command: (event: { item: MainMenuItem }) => {
              this.loadInternalCommentsForLabItemLevel(event.item.state?.targetId);
            }
          }
        ]
      },
      {
        //TODO Below will be updated once this PBI is done: 3221407: Invalid HTML to select an ID
        id: 'eln-menu-activityPreparation' + act.itemTitle,
        label: $localize`:@@preparations:Preparations`,
        routerLink: `${elnEncodeSpecialChars(act?.itemTitle)}/Preparations`,
        styleClass: this.activityMenuStyleClass,
        escape: false,
        contextMenuItems: [
          {
            label: $localize`:@@history:History`,
            icon: this.historyIcon,
            id: `eln-context-preparations-${replace(act.itemTitle, / /g, '')}`,
            command: () => {
              this.loadPreparationAuditHistory();
            }
          },
          {
            label: $localize`:@@internalComments:Internal Comments`,
            icon: this.activityIconClass,
            id: `eln-context-internal-comments-${replace(act.itemTitle, / /g, '')}`,
            state: {
              targetId: act.activityId
            },
            command: () => {
              this.loadInternalCommentsForPreparationLevel();
            }
          }
        ]
      },
      {
        id: 'eln-menu-activityModule' + act.itemTitle,
        label: act.isActivityComplete ?
          $localize`:@@ActivityModulePageTitle:Modules` :
          $localize`:@@ActivityModulePageTitle:Modules` + `<span class="icon-exclamation-mark icon-s icon-navbar"></span>`,
        routerLink: `${elnEncodeSpecialChars(act?.itemTitle)}/Modules`,
        tooltipOptions: !act.isActivityComplete ? {
          tooltipLabel: $localize`:@@ActivityIncomplete:There is a module with incomplete items`,
          tooltipPosition: 'top',
          positionTop: 10,
          positionLeft: 95
        } : undefined,
        styleClass: this.activityMenuStyleClass,
        escape: false
      }
    ]);
    this.isOutputsEnabled = this.featureService.isEnabled(ELNFeatureFlags.ActivityOutputsEnabled);
    if (this.isOutputsEnabled) {
      const outputItem = {
        id: `eln-menu-activityOutput-${elnEncodeSpecialChars(act?.itemTitle)}`,
        label: $localize`:@@outputs:Outputs`,
        routerLink: `${elnEncodeSpecialChars(act?.itemTitle)}/Outputs`,
        styleClass: this.activityMenuStyleClass,
        contextMenuItems: [
          {
            label: $localize`:@@history:History`,
            icon: this.historyIcon,
            id: `eln-context-outputs-${replace(act.itemTitle, / /g, '')}`,
            command: () => {
              this.loadActivityOutputAuditHistory();
            }
          },
          {
            label: $localize`:@@internalComments:Internal Comments`,
            icon: this.activityIconClass,
            id: 'eln-contextInternalCommentsOutputs',
            command: () => {
              this.loadInternalCommentsForOutputLevel();
            }
          }
        ]
      };
      items.push(outputItem);
    }

    return items;
  }

  /** "loadInternalCommentsForActivityInputs" */
  loadInternalCommentsForInputLevel() {
    const activity = this.experimentService.currentActivity;
    if (activity) {
      this.openInternalComments(
        activity.activityId,
        [activity.activityId, CommentContextType.ActivityInput],
        CommentContextType.Activity
      );
    }
  }

  loadInternalCommentsForOutputLevel() {
    const activity = this.experimentService.currentActivity;
    if (activity) {
      this.openInternalComments(
        activity.activityId,
        [activity.activityId, CommentContextType.OutputsInstrumentEvent],
        CommentContextType.Activity
      );
    }
  }

  loadInternalCommentsForActivityLevel() {
    const activity = this.experiment?.activities.find(
      (act) => act.activityId === this.currentContextActivityId
    );
    if (activity) {
      this.openInternalComments(
        this.experiment!.id,
        [activity?.activityId, CommentContextType.Activity],
        CommentContextType.Activity
      );
    }
  }

  loadInternalCommentsForExperimentLevel() {
    if (this.experiment) {
      this.openInternalComments(
        this.experiment.id,
        [this.experiment.id, this.experiment.experimentNumber, 'Experiment'],
        CommentContextType.Experiment
      );
    }
  }

  loadInternalCommentsForReferencesLevel() {
    const activity = this.experimentService.currentActivity;
    if (activity) {
      this.openInternalComments(
        activity.activityId,
        [activity.activityId, ActivityReferencesPseudoModuleId],
        CommentContextType.Activity
      );
    }
  }

  loadInternalCommentsForLabItemLevel(targetId: string) {
    const activity = this.experiment?.activities.find(
      (act) => act.activityId === targetId
    );
    if (activity) {
      this.openInternalComments(
        activity.activityId,
        [activity.activityId, CommentContextType.LabItems],
        CommentContextType.Activity
      );
    }
  }

  loadInternalCommentsForPreparationLevel() {
    const activity = this.experimentService.currentActivity;
    if (activity) {
      this.openInternalComments(
        activity.activityId,
        [activity.activityId, CommentContextType.Preparations],
        CommentContextType.Activity
      );
    }
  }

  openInternalComments(nodeId: string, path: string[], contextType: CommentContextType) {
    this.internalCommentData.nodeId = nodeId;
    this.internalCommentData.path = path;
    this.internalCommentData.contextType = contextType;
    this.commentService.openInternalComments(this.internalCommentData);
  }

  loadCoverAuditHistory(menuLabel: string) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    this.auditLoading = true;
    const experimentId =
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id;

    this.auditHistoryService.loadExperimentAuditHistory(experimentId).subscribe((data) => {
      this.auditLoading = false;
      data.dataRecords.forEach((record:any) => {
        if(record.eventContext.eventType === "experimentTemplateApplied")
        record.templateTitle = record.activityReferenceNumber !=="NA"?
       `${record.templateTitle} ${record.activityReferenceNumber}`:record.templateTitle
      })
      this.loadAuditDialog(data.dataRecords.filter((item) => {
        return item.eventContext.eventType !== "experimentNodeOrderChanged"
      }), menuLabel);
    });
  }

  loadActivityAuditHistory(activityId: string, activityTitle: string) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    const response = this.experimentService.currentExperimentResponse;
    this.auditLoading = true;
    const experimentId =
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id;
    const moduleIds = response?.activities.find((a) => a.activityId === activityId)?.childOrder;
    const childOrders = response?.modules
      .filter((m) => moduleIds?.includes(m.moduleId))
      .map((m) => m.childOrder)
      .flat();
    const formIds = response?.forms
      .filter((f) => childOrders?.includes(f.formId))
      .map((f) => f.formId);
    const tableIds = response?.tables
      .filter((t) => childOrders?.includes(t.tableId))
      .map((t) => t.tableId);

    this.auditHistoryService
      .loadActivityOrModuleAuditHistory(experimentId, tableIds?.concat(moduleIds || []).concat(activityId), formIds)
      .subscribe({
        next: (data) => {
          this.auditLoading = false;
          this.loadAuditDialog([...data.r1.dataRecords.filter((item) => {
            return item.eventContext.eventType !== "experimentNodeOrderChanged"
          }),
          ...data.r2.dataRecords.filter((item) => {
            return item.eventContext.eventType !== "experimentNodeOrderChanged"
          })], activityTitle);
        }
      });
  }

  loadReferencesAuditHistory(activityId: string) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    const title = $localize`:@@references:References`;

    const experimentId = this.experiment?.id;
    const activity = this.experiment?.activities.find(a => a.activityId === activityId);
    if (!activity || !experimentId) return;

    const filterDataRecords = (dataRecords: ExperimentDataRecordNotification[]) => dataRecords
      .filter((r: ExperimentDataRecordNotification) => {
        return (
          r.eventContext.eventType === ExperimentEventType.RowsAdded
          || r.eventContext.eventType === ExperimentEventType.CellChanged
          || r.eventContext.eventType === ExperimentEventType.ActivityCrossReferenceAdded
          || r.eventContext.eventType === ExperimentEventType.ActivityCrossReferenceChanged
          || r.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated
          || r.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged
        );
      });
    const filterActivityReferenceTemplateAppliedRecords = (dataRecords: ExperimentDataRecordNotification[]) => dataRecords
      .filter((r: ExperimentDataRecordNotification) => {
        return (
          r.eventContext.eventType === ExperimentEventType.RowsAdded
          || r.eventContext.eventType === ExperimentEventType.CellChanged
          || r.eventContext.eventType === ExperimentEventType.ActivityReferenceTemplateApplied
        );
      });

    this.auditLoading = true;
    forkJoin({
      crossReferences: this.auditHistoryService.loadTableAuditHistory(experimentId, activity.activityId),

      documents: activity.activityReferences.documentReferencesTableId
        ? this.auditHistoryService.loadTableAuditHistory(experimentId, activity.activityReferences.documentReferencesTableId)
        : of(undefined),

      compendia: activity.activityReferences.compendiaReferencesTableId
        ? this.auditHistoryService.loadTableAuditHistory(experimentId, activity.activityReferences.compendiaReferencesTableId)
        : of(undefined)

    }).subscribe((data: {
      crossReferences: AuditHistoryDataRecordResponse,
      documents: AuditHistoryDataRecordResponse | undefined,
      compendia: AuditHistoryDataRecordResponse | undefined
    }) => {
      const dataRecords: ExperimentDataRecordNotification[] = [];
      if (data.crossReferences.dataRecords) {
        dataRecords.push(...filterDataRecords(data.crossReferences.dataRecords));
      }
      if (data.documents?.dataRecords) {
        dataRecords.push(...filterActivityReferenceTemplateAppliedRecords(data.documents.dataRecords));
      }
      if (data.compendia?.dataRecords) {
        dataRecords.push(...filterActivityReferenceTemplateAppliedRecords(data.compendia.dataRecords));
      }
      this.auditLoading = false;
      this.loadAuditDialog(dataRecords, title);
    });
  }

  loadPreparationAuditHistory() {
    const title = $localize`:@@preparations:Preparations`;
    const eventsToFilter = this.preparationEventsForAuditHistory;
    this.loadAuditHistoryForPreparation(title, eventsToFilter)
  }

  private loadAuditHistory(header: string, eventsToFilter: ExperimentEventType[]) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    const title = header;
    const experimentId = this.experiment?.id;
    const activity = this.experimentService.currentActivity;
    if (!experimentId || !activity) return;

    const filterDataRecords = (dataRecords: ExperimentDataRecordNotification[]) => dataRecords
      .filter((r: ExperimentDataRecordNotification) => {
        return (
          eventsToFilter.includes(r.eventContext.eventType)
        );
      })     
      .filter(dr => (!('number' in dr)) ||
        ((this.lookupNote(dr.number as number)?.contextType === ClientFacingNoteContextType.LabItems ||
          this.lookupNote(dr.number as number)?.contextType === ClientFacingNoteContextType.LabItemsPreparation) &&
          (dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated ||
            dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged)));

    this.auditLoading = true;
    this.auditHistoryService.loadLabItemsAuditHistory(experimentId, activity.activityId)
      .subscribe((data: AuditHistoryDataRecordResponse) => {        
        const dataRecords = filterDataRecords(data.dataRecords ?? []);
        this.auditLoading = false;
        this.loadAuditDialog(dataRecords, title);
      });
  }

  private loadAuditHistoryForPreparation(header: string, eventsToFilter: ExperimentEventType[]) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    const title = header;
    const experimentId = this.experiment?.id;
    const activity = this.experimentService.currentActivity;
    if (!experimentId || !activity) return;

    const filterDataRecords = (dataRecords: ExperimentDataRecordNotification[]) => dataRecords
      .filter((r: ExperimentDataRecordNotification) => {
        return (eventsToFilter.includes(r.eventContext.eventType));
      })      
      .filter(dr => (!('number' in dr)) ||
        ((this.lookupNote(dr.number as number)?.contextType === ClientFacingNoteContextType.Preparations) &&
          (dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated || dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged)));

    this.auditLoading = true;
    this.subscriptions.push(this.auditHistoryService.loadPreparationsAuditHistory(experimentId, activity.activityId)
      .subscribe((data: AuditHistoryDataRecordResponse) => {
        const dataRecords = filterDataRecords(data.dataRecords ?? []);
        this.auditLoading = false;
        this.loadAuditDialog(dataRecords, title);
      }));
  }

  public lookupNote(number: number): ClientFacingNoteModel | undefined {
    return this.experimentService.currentExperiment?.clientFacingNotes.find(n => n.number === number);
  }
  
  loadLabItemsAuditHistory() {
    const title = $localize`:@@labItems:LabItems`;
    const eventsToFilter = this.includeOnlyLabItemsEventForAuditHistory;
    this.loadAuditHistory(title, eventsToFilter);
  }

  loadActivityInputAuditHistory(activityId: string) {
    const title = $localize`:@@activityInputPageTitle:Inputs`;
    const eventsToFilter = this.includeOnlyInputsEventForAuditHistory;
    this.loadAuditHistoryForInputs(title, eventsToFilter);
  }

  private loadAuditHistoryForInputs(header: string, eventsToFilter: ExperimentEventType[]) {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    const title = header;
    const experimentId = this.experiment?.id;
    const activity = this.experimentService.currentActivity;
    if (!experimentId || !activity) return;

    const filterDataRecords = (dataRecords: ExperimentDataRecordNotification[]) => dataRecords
      .filter((r: ExperimentDataRecordNotification) => {
        return (
          eventsToFilter.includes(r.eventContext.eventType)
        );
      });

    this.auditLoading = true;
    this.auditHistoryService.loadActivityInputsAuditHistory(experimentId, activity.activityId)
      .subscribe((data: AuditHistoryDataRecordResponse) => {
        const dataRecords: ExperimentDataRecordNotification[] = [];
        if (data.dataRecords) {
          dataRecords.push(...filterDataRecords(data.dataRecords));
        }
        this.auditLoading = false;
        this.loadAuditDialog(dataRecords, title);
      });
  }

  /**
   * Gets called to load audit history dialog
   */
  loadAuditDialog(dataRecords: ExperimentDataRecordNotification[], menuLabel: string) {
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(dataRecords, menuLabel);
  }

  loadActivityOutputAuditHistory() {
    this.loadingMessage = $localize`:@@loadingHistory:Loading History...`;
    this.auditLoading = true;
    const experimentId =
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id;
    const activity = this.experimentService.currentActivity;
    if (!activity) return;

    this.auditHistoryService
      .loadActivityOrModuleAuditHistory(experimentId, [(activity.activityId)], [])
      .subscribe({
        next: (data) => {
          this.loadActivityCompleteOutputAuditHistory(experimentId, activity.activityId, [...data.r1.dataRecords.filter((item) => {
            return ((item.eventContext.eventType === "clientFacingNoteCreated"
              || item.eventContext.eventType === "clientFacingNoteChanged")
              && (item as ClientFacingNoteCreatedEventNotification).nodeId === activity.activityId
              && (item as ClientFacingNoteCreatedEventNotification).contextType !== "labItems")
              || item.eventContext.eventType === ExperimentEventType.ActivityFilesAdded
              || item.eventContext.eventType === ExperimentEventType.ActivityFilesDeleted
          }),
          ...data.r2.dataRecords.filter((item) => {
            return item.eventContext.eventType === "clientFacingNoteCreated"
              || item.eventContext.eventType === "clientFacingNoteChanged"
              && (item as ClientFacingNoteCreatedEventNotification).nodeId === activity.activityId
              && (item as ClientFacingNoteCreatedEventNotification).contextType !== "labItems"
          })
          ])
        }
      });
  }

  private loadActivityCompleteOutputAuditHistory(experimentId: string, activityId: string, clientFacingNoteDataRecords: ExperimentDataRecordNotification[]) {
    const activityOutputTitle = $localize`:@@outputs:Outputs`;
    this.auditHistoryService
      .loadActivityOutputsAuditHistory(experimentId, activityId)
      .subscribe({
        next: (data) => {
          this.auditLoading = false;
          const history = [...data.dataRecords, ...clientFacingNoteDataRecords]
          this.loadAuditDialog(history, activityOutputTitle);
        }
      });
  }

  private processResponse(
    obs: Observable<any>,
    acknowledgment: (response: any, experiment: Experiment) => void
  ) {
    obs
      .pipe(
        first(),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        (data: any) => {
          acknowledgment(data, this.experiment!);
        },
        () => {
          this.validation.errorTitle = $localize`:@@receivedErrorFromServer:Received following error from server`;
        }
      );
  }



  private canEnableBarcodeScannerIcon(): boolean {
    const featureFlags = this.clientStateService.getFeatureFlags(ClientState.EXPERIMENT_ACTIVITIES);
    const visibility = this.clientStateService.getClientStateVisibility(ClientState.EXPERIMENT_ACTIVITIES);
    const labItemsAccessFeatures = LabItemsFeatureManager.EvaluateUserPermissionsOnLabItems(this.experiment?.workflowState as ExperimentWorkflowState, featureFlags);

    return (
      !this.userService.hasOnlyReviewerRights() &&
      visibility === AccessibilityTypes.ReadWrite && this.currentClientState === ClientState.EXPERIMENT_ACTIVITIES &&
      labItemsAccessFeatures[ActivityInputType.Material].PermittedWorkflowStatesToAddItems
    );
  }
  public setupBarcodeScanner() {
    this.showBarcodeScanner = this.canEnableBarcodeScannerIcon();
  }

  private initialize() {
    this.toolbarIcons = this.getBarcodeScannerIcon();
    const featureFlags = this.clientStateService.getFeatureFlags(this.clientState);
    const commonFeatureFlags = this.clientStateService.getFeatureFlags(ClientState.BOOKSHELF);
    this.canStartExperimentFlag =
      featureFlags.find(
        (data) =>
          JSON.parse(data).CanStartExperiment && JSON.parse(data).CanStartExperiment === true
      ) !== (null || undefined);

    this.canCancelExperiment =
      featureFlags.find(
        (data) =>
          JSON.parse(data).CanCancelExperiment && JSON.parse(data).CanCancelExperiment === true
      ) !== (null || undefined);

    this.canAddTemplate =
      commonFeatureFlags.find(
        (data) =>
          JSON.parse(data).CanAddTemplate && JSON.parse(data).CanAddTemplate === true
      ) !== (null || undefined);

    this.canRestoreExperiment =
      featureFlags.find(
        (data) =>
          JSON.parse(data).CanRestoreExperiment && JSON.parse(data).CanRestoreExperiment === true
      ) !== (null || undefined);

    this.canSendExperimentForReviewFromInProgressFlag =
      featureFlags.find(
        (data) =>
          JSON.parse(data).CanSendExperimentForReviewFromInProgress === true
      ) !== (null || undefined);

    this.canSendExperimentForReviewFromCorrectionFlag =
      featureFlags.find(
        (data) =>
          JSON.parse(data).CanSendExperimentForReviewFromCorrection === true
      ) !== (null || undefined);

    this.canSendExperimentForReviewFromCorrectionFlag =
      featureFlags.find(
        (data) =>
          JSON.parse(data).CanSendExperimentForCorrectionFromReview === true
      ) !== (null || undefined);

    this.canSendExperimentForCorrectionFromReviewFlag =
      featureFlags.find(
        (data) =>
          JSON.parse(data).CanSendExperimentForCorrectionFromReview === true
      ) !== (null || undefined);

    this.canSendExperimentForCorrectionFromAuthorizedStateFlag =
      featureFlags.find(
        (data) =>
          JSON.parse(data).CanSendExperimentForCorrectionFromAuthorizedState === true
      ) !== (null || undefined);

    this.canSendExperimentForAuthorizationFlag =
      featureFlags.find(
        (data) =>
          JSON.parse(data).CanSendExperimentForAuthorization === true
      ) !== (null || undefined);

    this.setUpExperimentFlags();
  }

  private setUpExperimentFlags() {
    const visibility = this.clientStateService.getClientStateVisibility(ClientState.EXPERIMENT);
    const isExperimentCompleted = ExperimentService.isExperimentAuthorizedOrCancelled(this.experiment?.workflowState as ExperimentWorkflowState)

    if (visibility) {
      if (this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InReview) {
        this.canRaiseExperimentFlags = this.canEditExperimentInReviewStateFlag;
      }
      else {
        this.canRaiseExperimentFlags = isExperimentCompleted ? false : true;
      }
    }

    this.canPendingCancelFlag = this.experimentFlags.find(
      (flag) =>
        flag.flag.toLocaleLowerCase() === ExperimentFlagType.PendingCancel.toLocaleLowerCase()
    );
  }

  private confirmWorkflowStateTransition(message: string, acceptCallback: () => void) {
    this.confirmationService.confirm({
      message: `${message}`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@Yes:Yes`,
      rejectVisible: true,
      rejectLabel: $localize`:@@No:No`,
      closeOnEscape: true,
      dismissableMask: false,
      accept: acceptCallback
    });
  }

  private createSuccessNotificationMessage(summary: string, detail: string) {
    const messageObj: Message = {
      key: 'notification',
      severity: 'success',
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  updateMenuItems(activity: Activity) {
    const moduleId = 'eln-menu-activityModule';
    const menuItemIndex = this.menuItems.findIndex((m) => m.label === activity.itemTitle);
    this.menuItems[menuItemIndex].items?.forEach((item) => {
      if (item.id === moduleId + activity.itemTitle) {
        if (!activity.isActivityComplete) {
          item.label =
            $localize`:@@activityModulePageTitle:Modules` +
            `<span class="icon-exclamation-mark icon-s icon-navbar"></span>`;
          item.tooltipOptions = {
            tooltipLabel: $localize`:@@activityIncomplete:There is a module with incomplete items`,
            tooltipPosition: 'top',
            positionTop: 10,
            positionLeft: 95
          };
        } else {
          item.label = $localize`:@@activityModulePageTitle:Modules`;
          item.tooltipOptions = {
            tooltipLabel: ''
          };
        }
      }
    });
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
    UnsubscribeAll(this.activeSubscriptions); // duplicate property, either is fine, both is fine too.
    UnsubscribeAll(this.reOrderSubscription);
  }

  startTimer(): void {
    this.inactiveTimeOut = window.setTimeout(() => {
      this.confirmSignalRDisconnect();
    }, this.userInactiveTimeOut);
  }

  confirmSignalRDisconnect() {
    const confirmationTimer = window.setTimeout(() => {
      this.confirmationService.close();
      this.disconnectUser();
    }, this.confirmationTimeOut);

    this.confirmationService.confirm({
      message: $localize`:@@signalRDisconnectMessage:You will be disconnected from this Experiment in 1 min due to inactivity. If you would like to remain connected then click "OK" below.`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      icon: 'pi pi-exclamation-triangle',
      closeOnEscape: true,
      dismissableMask: false,
      acceptLabel: $localize`:@@Ok:OK`,
      rejectLabel: $localize`:@@cancel:Cancel`,
      accept: () => {
        clearTimeout(confirmationTimer);
        this.startTimer();
      },
      reject: () => {
        this.disconnectUser();
      }
    });
  }

  private disconnectUser() {
    clearTimeout(this.inactiveTimeOut);
    this.stopTimer = true;
    this.showReconnectDialog = true;
    this.experimentNotificationService.disconnectUser();
  }

  reConnect() {
    this.ngZone.run(() => {
      this.showConnecting = true;
    })
    this.experimentNotificationService.joinAnExperiment();
  }

  doesInstrumentEventExistsForActivityInput() {
    const instrument = this.experimentService.currentExperiment?.activityInputs?.filter(
      (a: any) => a.activityId === this.experimentService.currentActivityId
    )?.find(k => !k.instruments?.isRemoved)?.instruments;
    return instrument as any;
  }


  doesConsumableExistsForLabItem() {
    const currentActivityId = this.experimentService.currentActivity?.activityId;
    const activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) =>
        labItemNode.nodeId === currentActivityId
    );
    return !!activityLabItemNode?.consumables && activityLabItemNode?.consumables?.length > 0;
  }

  @HostListener('mousedown')
  @HostListener('keydown')
  resetTimer() {
    this.ngZone.runOutsideAngular(() => {
      clearTimeout(this.inactiveTimeOut);
      if (!this.stopTimer) {
        this.startTimer();
      }
    });
  }

  activitiesReOrdered(e: BptReoderedEvent<any>) {
    if (this.experimentService.currentExperiment?.activities.length === 1) return;

    const activity = this.experimentService.currentExperiment?.activities.find(a => a.itemTitle === e.item[0].label);
    if (!activity) return;

    this.experimentNodeReOrderService.changeNodeReorder(NodeType.Activity, activity.activityId, activity.experimentId, e.newPosition, activity.itemTitle, activity.activityId);
  }

  @HostListener('window:beforeunload')
  unload() {
    this.isReload = true;
    this.DisconnectPhMeterOnRefresh();
  }

  private DisconnectPhMeterOnRefresh() {
    const isPhMeterConnectedInCurrentTab = this.pHMeterService.getPhMeterActiveTab();
    if (
      isPhMeterConnectedInCurrentTab
      && +isPhMeterConnectedInCurrentTab
      && this.instrumentConnectionHelper.phName
      && localStorage.getItem('instrumentType') === InstrumentType.phMeter
    ) {
      this.instrumentNotificationService.disconnectFromInstrument(this.instrumentConnectionHelper.phName, InstrumentType.phMeter);
    }
  }



  applyReferenceTemplate(type: ReferenceTemplateType) {
    const finalizeAdding = () => this.experimentService.isLoadingDocumentsOrCompendia = false;

    this.experimentService.isLoadingDocumentsOrCompendia = true;
    this.addReferenceTemplate(type).subscribe({
      complete: finalizeAdding,
      error: finalizeAdding
    });
  }

  addReferenceTemplate(type: ReferenceTemplateType): Observable<void> {
    // defend against a thing that can't happen
    const undefinedActivityErrorMsg = 'LOGIC ERROR: Cannot add a reference to an undefined activity';
    if (!this.currentActivity) return throwError(() => Error(undefinedActivityErrorMsg));

    const command: ApplyReferenceTemplateCommand = {
      experimentId: this.currentActivity.experimentId,
      activityId: this.currentActivity.activityId,
      type,
    };
    return this.activityReferenceEventsService.activityReferencesApplyReferenceTemplatePost$Json({ body: command }).pipe(map(
      (response: ReferenceTemplateAppliedResponse) => {
        if (!this.currentActivity) throw undefinedActivityErrorMsg;
        const tableId = response.tableId;
        switch (type) {
          case ReferenceTemplateType.Documents:
            this.currentActivity.activityReferences.documentReferencesTableId = tableId;
            this.experimentService.referenceTypeAdded.next(ReferenceGridType.Documents);
            break;
          case ReferenceTemplateType.Compendia:
            this.currentActivity.activityReferences.compendiaReferencesTableId = tableId;
            this.experimentService.referenceTypeAdded.next(ReferenceGridType.Compendia);
            break;
        }

        this.experimentService.renderAppliedTemplate(
          this.currentActivity, {
          type: command.type,
          tableId: response.tableId,
          templateId: response.templateId,
          activityId: command.activityId,
          experimentId: command.experimentId,
          notifications: response.notifications
        }
        )
      }
    ));
  }

  /** To Support the PE environment until FAMS enabled there. */
  reauthBeforeExperimentTransition(transitionCallback: () => void): void {
    if (!this.featureService.isEnabled(ELNFeatureFlags.EnableESignatureForExperimentTransition)
      || !ConfigurationService.isLimsHosted) {
      transitionCallback();
      return;
    }
    (window as any).Master?.oidcTokenManager?.checkFamsAndGetWindowPopupForReauth();
    const request = {
      successCallback: (authResult: AuthResult) => {
        if (authResult && authResult.status === AuthStatusType.Success && authResult.token) {
          transitionCallback();
        }
      },
      messageForUnauthorizedCredentials: $localize`:@@TransitionInvalidCredentials:Credentials are incorrect and the status update was not successful.`
    };
    this.authenticationHelperService.handleReauthenticationResponse(request);
  }

  evaluateCurrentWorkflowTransitionActions(experimentLoad = false): void {
    if (
      this.experiment && (this.experiment.workflowState === ExperimentWorkflowState.InReview ||
        this.experiment.workflowState === ExperimentWorkflowState.InProgress ||
        this.experiment.workflowState === ExperimentWorkflowState.Setup ||
        this.experiment.workflowState === ExperimentWorkflowState.InCorrection
      )
      && this.canSendExperimentForAuthorizationFlag
    ) {
      this.experimentService.amICollaborator(experimentLoad).subscribe({
        next: (amICollaborator) => {
          if (amICollaborator) this.isCollaborator = amICollaborator;
          this.setCurrentWorkflowTransitionAction(amICollaborator);
        }
      });
      return;
    }
    this.setCurrentWorkflowTransitionAction();
  }

  private getExperimentAuthorizeMenuOption(isCurrentUserCollaborator: boolean) {
    return {
      id: 'eln-btnAuthorize',
      label: $localize`:@@AuthorizeExperiment:Authorize`,
      icon: this.WorkflowStatusIcons[ExperimentWorkflowState.Authorized],
      disabled: !this.canSendExperimentForAuthorizationFlag || isCurrentUserCollaborator,
      tooltipOptions: {
        tooltipLabel:
          isCurrentUserCollaborator ?
            $localize`:@@collaboratorsNotAuthorized:Collaborators are not allowed to Authorize the experiment`
            : $localize`:@@authorizationTooltipMessage:Only a Reviewer can Authorize an Experiment`,
        disabled: this.canSendExperimentForAuthorizationFlag && !isCurrentUserCollaborator
      },
      command: () => this.confirmAuthorization()
    }
  }
}
