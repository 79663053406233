/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeviceResponse as ElnLabelsApiEntitiesDeviceResponse } from '../models/ELN/Labels/API/Entities/device-response';
import { LabelTemplateResponse as ElnLabelsApiEntitiesLabelTemplateResponse } from '../models/ELN/Labels/API/Entities/label-template-response';
import { PrinterStatusResponse as ElnLabelsApiEntitiesPrinterStatusResponse } from '../models/ELN/Labels/API/Entities/printer-status-response';
import { LabelType as ElnLabelsDomainEnumsLabelType } from '../models/ELN/Labels/Domain/Enums/label-type';

@Injectable({
  providedIn: 'root',
})
export class LabelsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation labelsLabelTemplatesTemplateTypeGet
   */
  static readonly LabelsLabelTemplatesTemplateTypeGetPath = '/labels/label-templates/{templateType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelsLabelTemplatesTemplateTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsLabelTemplatesTemplateTypeGet$Plain$Response(params: {
    templateType: ElnLabelsDomainEnumsLabelType;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<StrictHttpResponse<ElnLabelsApiEntitiesLabelTemplateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabelsService.LabelsLabelTemplatesTemplateTypeGetPath, 'get');
    if (params) {
      rb.path('templateType', params.templateType, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElnLabelsApiEntitiesLabelTemplateResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labelsLabelTemplatesTemplateTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsLabelTemplatesTemplateTypeGet$Plain(params: {
    templateType: ElnLabelsDomainEnumsLabelType;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<ElnLabelsApiEntitiesLabelTemplateResponse> {

    return this.labelsLabelTemplatesTemplateTypeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ElnLabelsApiEntitiesLabelTemplateResponse>) => r.body as ElnLabelsApiEntitiesLabelTemplateResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelsLabelTemplatesTemplateTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsLabelTemplatesTemplateTypeGet$Json$Response(params: {
    templateType: ElnLabelsDomainEnumsLabelType;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<StrictHttpResponse<ElnLabelsApiEntitiesLabelTemplateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabelsService.LabelsLabelTemplatesTemplateTypeGetPath, 'get');
    if (params) {
      rb.path('templateType', params.templateType, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElnLabelsApiEntitiesLabelTemplateResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labelsLabelTemplatesTemplateTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsLabelTemplatesTemplateTypeGet$Json(params: {
    templateType: ElnLabelsDomainEnumsLabelType;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<ElnLabelsApiEntitiesLabelTemplateResponse> {

    return this.labelsLabelTemplatesTemplateTypeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ElnLabelsApiEntitiesLabelTemplateResponse>) => r.body as ElnLabelsApiEntitiesLabelTemplateResponse)
    );
  }

  /**
   * Path part for operation labelsPrintersGet
   */
  static readonly LabelsPrintersGetPath = '/labels/printers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelsPrintersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrintersGet$Plain$Response(params?: {
    mediaId?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<StrictHttpResponse<ElnLabelsApiEntitiesDeviceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabelsService.LabelsPrintersGetPath, 'get');
    if (params) {
      rb.query('mediaId', params.mediaId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElnLabelsApiEntitiesDeviceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labelsPrintersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrintersGet$Plain(params?: {
    mediaId?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<ElnLabelsApiEntitiesDeviceResponse> {

    return this.labelsPrintersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ElnLabelsApiEntitiesDeviceResponse>) => r.body as ElnLabelsApiEntitiesDeviceResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelsPrintersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrintersGet$Json$Response(params?: {
    mediaId?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<StrictHttpResponse<ElnLabelsApiEntitiesDeviceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabelsService.LabelsPrintersGetPath, 'get');
    if (params) {
      rb.query('mediaId', params.mediaId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElnLabelsApiEntitiesDeviceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labelsPrintersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrintersGet$Json(params?: {
    mediaId?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<ElnLabelsApiEntitiesDeviceResponse> {

    return this.labelsPrintersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ElnLabelsApiEntitiesDeviceResponse>) => r.body as ElnLabelsApiEntitiesDeviceResponse)
    );
  }

  /**
   * Path part for operation labelsPrinterStatusPrinterIdGet
   */
  static readonly LabelsPrinterStatusPrinterIdGetPath = '/labels/printer-status/{printerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelsPrinterStatusPrinterIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrinterStatusPrinterIdGet$Plain$Response(params: {
    printerId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<StrictHttpResponse<ElnLabelsApiEntitiesPrinterStatusResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabelsService.LabelsPrinterStatusPrinterIdGetPath, 'get');
    if (params) {
      rb.path('printerId', params.printerId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElnLabelsApiEntitiesPrinterStatusResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labelsPrinterStatusPrinterIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrinterStatusPrinterIdGet$Plain(params: {
    printerId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<ElnLabelsApiEntitiesPrinterStatusResponse> {

    return this.labelsPrinterStatusPrinterIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ElnLabelsApiEntitiesPrinterStatusResponse>) => r.body as ElnLabelsApiEntitiesPrinterStatusResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelsPrinterStatusPrinterIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrinterStatusPrinterIdGet$Json$Response(params: {
    printerId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<StrictHttpResponse<ElnLabelsApiEntitiesPrinterStatusResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabelsService.LabelsPrinterStatusPrinterIdGetPath, 'get');
    if (params) {
      rb.path('printerId', params.printerId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElnLabelsApiEntitiesPrinterStatusResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labelsPrinterStatusPrinterIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrinterStatusPrinterIdGet$Json(params: {
    printerId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
  }): Observable<ElnLabelsApiEntitiesPrinterStatusResponse> {

    return this.labelsPrinterStatusPrinterIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ElnLabelsApiEntitiesPrinterStatusResponse>) => r.body as ElnLabelsApiEntitiesPrinterStatusResponse)
    );
  }

}
