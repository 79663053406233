/* tslint:disable */
/* eslint-disable */
export enum ExperimentEventType {
  ExperimentCreated = 'experimentCreated',
  ExperimentStarted = 'experimentStarted',
  ExperimentRestored = 'experimentRestored',
  ExperimentSentForReview = 'experimentSentForReview',
  ExperimentSentForCorrection = 'experimentSentForCorrection',
  ExperimentCancelled = 'experimentCancelled',
  ExperimentAuthorized = 'experimentAuthorized',
  TitleChanged = 'titleChanged',
  TagsChanged = 'tagsChanged',
  CellChanged = 'cellChanged',
  RowRemoved = 'rowRemoved',
  FieldChanged = 'fieldChanged',
  AssignedSupervisorsChanged = 'assignedSupervisorsChanged',
  AssignedReviewersChanged = 'assignedReviewersChanged',
  AssignedAnalystsChanged = 'assignedAnalystsChanged',
  ScheduledStartDateChanged = 'scheduledStartDateChanged',
  ScheduledReviewStartDateChanged = 'scheduledReviewStartDateChanged',
  AuthorizationDueDateChanged = 'authorizationDueDateChanged',
  SubBusinessUnitsChanged = 'subBusinessUnitsChanged',
  ExperimentTemplateApplied = 'experimentTemplateApplied',
  ActivityInputAdded = 'activityInputAdded',
  AliquotAdded = 'aliquotAdded',
  MaterialAdded = 'materialAdded',
  InstrumentAdded = 'instrumentAdded',
  InstrumentColumnAdded = 'instrumentColumnAdded',
  ClientFacingNoteCreated = 'clientFacingNoteCreated',
  ClientFacingNoteChanged = 'clientFacingNoteChanged',
  SampleTestChanged = 'sampleTestChanged',
  ActivityInputCellChanged = 'activityInputCellChanged',
  ActivityInputRowRestored = 'activityInputRowRestored',
  ActivityInputRowRemoved = 'activityInputRowRemoved',
  ActivityInputRowRefreshed = 'activityInputRowRefreshed',
  VariableCreated = 'variableCreated',
  StudyActivitySelected = 'studyActivitySelected',
  MaintenanceEventSelected = 'maintenanceEventSelected',
  LabItemsMaterialAdded = 'labItemsMaterialAdded',
  InstrumentDescriptionChanged = 'instrumentDescriptionChanged',
  InstrumentDateRemovedChanged = 'instrumentDateRemovedChanged',
  InstrumentRemovedFromServiceChanged = 'instrumentRemovedFromServiceChanged',
  LabItemsInstrumentAdded = 'labItemsInstrumentAdded',
  LabItemsInstrumentColumnRemoved = 'labItemsInstrumentColumnRemoved',
  LabItemsInstrumentRemoved = 'labItemsInstrumentRemoved',
  LabItemsInstrumentRefreshed = 'labItemsInstrumentRefreshed',
  LabItemsInstrumentColumnRefreshed = 'labItemsInstrumentColumnRefreshed',
  LabItemsInstrumentColumnRestored = 'labItemsInstrumentColumnRestored',
  LabItemsInstrumentRestored = 'labItemsInstrumentRestored',
  LabItemsInstrumentAdditionalInformationChanged = 'labItemsInstrumentAdditionalInformationChanged',
  LabItemsMaterialRemoved = 'labItemsMaterialRemoved',
  LabItemsMaterialRefreshed = 'labItemsMaterialRefreshed',
  LabItemsMaterialRestored = 'labItemsMaterialRestored',
  LabItemsCellChanged = 'labItemsCellChanged',
  LabItemsMaterialAdditionalInformationChanged = 'labItemsMaterialAdditionalInformationChanged',
  LabItemsConsumableAdded = 'labItemsConsumableAdded',
  LabItemsConsumableRemoved = 'labItemsConsumableRemoved',
  LabItemsConsumableRestored = 'labItemsConsumableRestored',
  ActivityCrossReferenceAdded = 'activityCrossReferenceAdded',
  ActivityCrossReferenceChanged = 'activityCrossReferenceChanged',
  ActivityCrossReferenceRemoved = 'activityCrossReferenceRemoved',
  ActivityCrossReferenceRestored = 'activityCrossReferenceRestored',
  ActivityReferenceTemplateApplied = 'activityReferenceTemplateApplied',
  ExperimentNodeTitleChanged = 'experimentNodeTitleChanged',
  ChromatographyDataImported = 'chromatographyDataImported',
  ChromatographyDataRefreshed = 'chromatographyDataRefreshed',
  ChromatographyDataRemoved = 'chromatographyDataRemoved',
  ExperimentNodeOrderChanged = 'experimentNodeOrderChanged',
  ExperimentPreparationCreated = 'experimentPreparationCreated',
  ExperimentPreparationRemoved = 'experimentPreparationRemoved',
  ExperimentPreparationRestored = 'experimentPreparationRestored',
  ExperimentPreparationDiscardedOrConsumed = 'experimentPreparationDiscardedOrConsumed',
  ExperimentPreparationInternalInformationChanged = 'experimentPreparationInternalInformationChanged',
  ExperimentPreparationCellChanged = 'experimentPreparationCellChanged',
  ExperimentPreparationStatusChanged = 'experimentPreparationStatusChanged',
  InstrumentEventNonRoutineIssueEncountered = 'instrumentEventNonRoutineIssueEncountered',
  InstrumentEventReturnedToService = 'instrumentEventReturnedToService',
  LabItemsPreparationAdded = 'labItemsPreparationAdded',
  LabItemPreparationRefreshed = 'labItemPreparationRefreshed',
  RowsAdded = 'rowsAdded',
  ActivityFilesAdded = 'activityFilesAdded',
  ActivityFilesDeleted = 'activityFilesDeleted',
  RowRestored = 'rowRestored',
  LabItemPreparationRestored = 'labItemPreparationRestored',
  LabItemPreparationRemoved = 'labItemPreparationRemoved',
  RowsRenumbered = 'rowsRenumbered'
}
