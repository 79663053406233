<bpt-progress-spinner
  *ngIf="isLoading"
  [useOverlay]="true"
  i18n-message="@@loadingExperiment"
  message="Loading Experiment..."
></bpt-progress-spinner>

<div
  class="panelView bpt-form-layout standard-padding mt-3 pt-6"
  [ngClass]="{ 'lims-hosted': isLimsHosted }"
>
  <div class="eln-main-content">
    <div class="notification-container flex gap-4 flex-column">
      <div *ngIf="validation.successes.length > 0">
        <p-messages severity="success" styleClass="p-mr-2">
          <ng-template pTemplate>
            <span class="p-message-icon pi pi-check"></span>
            <span class="p-ml-2">{{ validation.successTitle }}</span>
            <ul>
              <li *ngFor="let success of validation.successes">{{ success }}</li>
            </ul>
          </ng-template>
        </p-messages>
      </div>
      <div *ngIf="validation.errors.length > 0">
        <p-messages severity="error" styleClass="p-mr-2">
          <ng-template pTemplate>
            <span class="p-message-icon pi pi-times-circle"></span>
            <span class="p-ml-2">{{ validation.errorTitle }}</span>
            <ul>
              <li *ngFor="let error of validation.errors">{{ error }}</li>
            </ul>
          </ng-template>
        </p-messages>
      </div>
      <div *ngIf="validation.warnings.length > 0">
        <p-messages severity="warn" styleClass="p-mr-2" id="eln-cover-component-warning">
          <ng-template pTemplate>
            <span class="p-message-icon pi pi-exclamation-triangle"></span>
            <span class="p-ml-2">{{ validation.warningTitle }}</span>
            <ul>
              <li *ngFor="let warn of validation.warnings">{{ warn }}</li>
            </ul>
          </ng-template>
        </p-messages>
      </div>
    </div>
    <div class="fields">
      <div class="flex flex-v-center mb-2">
        <div class="flex-grow-1">
          <h4
            id="eln-GeneralInfoHeader"
            class="bpt-font-family font-size-13 font-semibold eln-mt-10 eln-mb-10"
          >
            {{ generalInformation | uppercase }}
          </h4>
        </div>
        <div *ngIf="isAuditEnabled" class="justify-content-end">
          <a
            class="audit-Icon m-0"
            id="eln-experiment-cover-audit-icon"
            (click)="loadAuditHistoryDialog()"
          >
            <span
              i18n-pTooltip="@@viewHistory"
              class="fa fa-history"
              pTooltip="View History"
              tooltipPosition="left"
              ><span class="ml-1 audit" i18n="@@History">History</span></span
            >
          </a>
        </div>
      </div>

      <div
        class="flex flex-wrap justify-content-start pl-5 pb-5 readonly-fields-container eln-space-evenly mb-5 gap-6"
      >
        <div class="flex flex-column align-items-start justify-content-start eln-readonly-field">
          <h6 id="eln-status" class="eln-label text-sm mb-1 font-normal eln-mt-20">
            {{ referenceTitle | titlecase }}
          </h6>
          <div class="flex align-items-baseline gap-2">
            <span id="eln-experiment-reference-title" [innerText]="experiment.experimentNumber"></span>
            <em id="{{copyExperimentReferenceId}}" class="bpt-eln-link icon-copy-line width-auto"
            [pTooltip]="copyExperimentCurrentTooltip"
            tooltipPosition="bottom"
            (click)="copyReferenceToClipboard()"
            (mouseout)="copyExperimentCurrentTooltip = copyExperimentTooltip"></em>
          </div>
        </div>
        <div class="flex flex-column align-items-start justify-content-start eln-readonly-field">
          <h6 id="eln-createdBy" class="eln-label text-sm mb-1 font-normal eln-mt-20 sm-no-mt">
            {{ createdBy | titlecase }}
          </h6>
          <span id="elnCreatedByFullname" [innerText]="createdByFullName"></span>
        </div>
      </div>

      <div class="flex eln-flex-container">
        <div class="flex eln-flex flex-two-column-layout">
          <div class="eln-flex-item">
            <bpt-text-input
              inputId="eln-txtExperimentTitle"
              i18n-label="@@title"
              label="Title"
              [(ngModel)]="coverPageCommand.title"
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="initiateTitleEditing($event)"
              i18n-placeholder="@@titlePlaceHolder"
              placeholder="Enter Title"
              [readOnly]="isCoverPageReadOnly"
              [required]="true"
            ></bpt-text-input>
          </div>

          <div class="eln-flex-item">
            <bpt-tags
              i18n-label="@@tags"
              [(ngModel)]="coverPageCommand.tags"
              (ngModelChange)="initiateTagsEditing($event)"
              [ngModelOptions]="{ updateOn: 'blur' }"
              [useCommaSeparator]="true"
              label="Tags"
              i18n-placeholder="@@tagsPlaceHolder"
              placeholder="Enter Tags"
              [readOnly]="isCoverPageReadOnly"
              id="eln-drpTags"
            ></bpt-tags>
          </div>
        </div>
        <h5 id="eln-assignmentTitle" class="d-contents eln-mt-20 eln-mb-20 eln-mbx-0">
          {{ assignments | uppercase }}
        </h5>
        <div class="flex eln-flex flex-two-column-layout">
          <div class="eln-flex-item">
            <app-user-select
              i18n-label="@@AssignedAnalysts"
              [(value)]="coverPageCommand.assignedAnalysts"
              [roles]="['ELN Analyst']"
              [setCurrentUser]="false"
              [ngModelOptions]="{ updateOn: 'blur' }"
              [multiSelect]="true"
              i18n-placeholder="@@AssignedAnalystsPlaceHolder"
              (valueChange)="initiateAssignedAnalystsEditing($event)"
              (userListFetched)="userListFetched($event)"
              placeholder="Select Assigned Analyst(s)"
              label="Assigned Analyst(s)"
              [readOnly]="isCoverPageReadOnly"
              id="eln-drpAssignedAnalysts"
              [usersToBeExcludedFromDisplay]="coverPageCommand.assignedReviewers"
            ></app-user-select>
          </div>

          <div class="eln-flex-item">
            <app-user-select
              i18n-label="@@assignedSupervisors"
              [(value)]="coverPageCommand.assignedSupervisors"
              [roles]="['ELN Supervisor']"
              [setCurrentUser]="false"
              [multiSelect]="true"
              i18n-placeholder="@@assignedSupervisorsPlaceHolder"
              (valueChange)="initiateAssignedSupervisorsEditing($event)"
              placeholder="Select the Assigned Supervisor(s)"
              [ngModelOptions]="{ updateOn: 'blur' }"
              label="Assigned Supervisor(s)"
              [readOnly]="isCoverPageReadOnly"
              id="eln-drpAssignedSupervisors"
              (userListFetched)="userListFetched($event)"
            ></app-user-select>
          </div>
        </div>
        <div class="flex eln-flex flex-two-column-layout">
          <div class="eln-flex-item">
            <app-user-select
              i18n-label="@@assignedReviewers"
              [(value)]="coverPageCommand.assignedReviewers"
              [roles]="['ELN Reviewer']"
              [ngModelOptions]="{ updateOn: 'blur' }"
              [setCurrentUser]="false"
              [multiSelect]="true"
              i18n-placeholder="@@assignedReviewersPlaceHolder"
              (valueChange)="initiateAssignedReviewersEditing($event)"
              placeholder="Select the Assigned Reviewer(s)"
              label="Assigned Reviewer(s)"
              [readOnly]="isCoverPageReadOnly"
              id="eln-drpAssignedReviewers"
              (userListFetched)="userListFetched($event)"
              [usersToBeExcludedFromDisplay]="coverPageCommand.assignedAnalysts"
            ></app-user-select>
          </div>
          <div class="eln-flex-item">
            <app-sub-business-unit-select
              i18n-label="@@subBusinessUnit"
              label="Sub Business Unit(s)"
              [readonly]="isCoverPageReadOnly"
              [ngModelOptions]="{ updateOn: 'blur' }"
              [(value)]="coverPageCommand.sbu"
              i18n-placeholder="@@subBusinessUnitPlaceHolder"
              placeholder="Sub Business Unit(s)"
              (valueChange)="initiateSubBusinessUnitsEditing($event)"
              (displayLabelModified)="updateSelectedSubBusinessUnitsLabel($event)"
              id="eln-drpSubBusinessUnits"
              [required]="true"
            ></app-sub-business-unit-select>
          </div>
        </div>
        <h5 id="eln-targetDatesTitle" class="d-contents eln-mt-20 eln-mb-20 eln-mbx-0">
          {{ targetDates | uppercase }}
        </h5>
        <div class="flex eln-flex flex-two-column-layout">
          <div class="eln-flex-item">
            <bpt-datetime
              i18n-label="@@authorizationDue"
              label="Authorization Due"
              [commitValueOnEnter]=true
              i18n-placeholder="@@authorizationDuePlaceHolder"
              placeholder="Select the Authorization Due"
              [(ngModel)]="coverPageCommand.authorizationDueDate"
              [useJsJodaTypes]="true"
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="initiateAuthorizationDueDateEditing($event)"
              [hourFormat]="'12'"
              [readOnly]="isCoverPageReadOnly"
              id="eln-drpAuthorizationDueDate"
            ></bpt-datetime>
          </div>

          <div class="eln-flex-item">
            <bpt-datetime
              i18n-label="@@scheduledStart"
              [commitValueOnEnter]=true
              label="Scheduled Start"
              i18n-placeholder="@@scheduledStartPlaceHolder"
              placeholder="Select the Scheduled Start"
              [(ngModel)]="coverPageCommand.scheduledStartDate"
              [useJsJodaTypes]="true"
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="initiateScheduledStartDateEditing($event)"
              [hourFormat]="'12'"
              [readOnly]="isCoverPageReadOnly"
              id="eln-drpScheduledStartDate"
            ></bpt-datetime>
          </div>
        </div>
        <div class="flex eln-flex flex-two-column-layout">
          <div class="eln-flex-item">
            <bpt-datetime
              i18n-label="@@scheduledReviewStart"
              [commitValueOnEnter]=true
              label="Scheduled Review Start "
              i18n-placeholder="@@scheduledReviewStartDatePlaceHolder"
              placeholder="Select the Scheduled Review Start"
              [(ngModel)]="coverPageCommand.scheduledReviewStartDate"
              [useJsJodaTypes]="true"
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="initiateScheduledReviewStartDateEditing($event)"
              [hourFormat]="'12'"
              [readOnly]="isCoverPageReadOnly"
              id="eln-drpScheduledReviewStartDate"
            ></bpt-datetime>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
