/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RecipeRecordAggregationSearchResult } from '../models/recipe-record-aggregation-search-result';
import { RecipeRecordSearchResult } from '../models/recipe-record-search-result';
import { RecipeTemplateDetailSearchCommand } from '../models/recipe-template-detail-search-command';
import { RecipeTemplateSearchResponse } from '../models/recipe-template-search-response';
import { SearchCriteria } from '../models/search-criteria';

@Injectable({
  providedIn: 'root',
})
export class RecipeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation recipeSearchRecipeIndexPost
   */
  static readonly RecipeSearchRecipeIndexPostPath = '/recipe/search-recipe-index';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeSearchRecipeIndexPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchRecipeIndexPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<StrictHttpResponse<RecipeRecordSearchResult>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeService.RecipeSearchRecipeIndexPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeRecordSearchResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeSearchRecipeIndexPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchRecipeIndexPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<RecipeRecordSearchResult> {

    return this.recipeSearchRecipeIndexPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeRecordSearchResult>) => r.body as RecipeRecordSearchResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeSearchRecipeIndexPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchRecipeIndexPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<StrictHttpResponse<RecipeRecordSearchResult>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeService.RecipeSearchRecipeIndexPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeRecordSearchResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeSearchRecipeIndexPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchRecipeIndexPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<RecipeRecordSearchResult> {

    return this.recipeSearchRecipeIndexPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeRecordSearchResult>) => r.body as RecipeRecordSearchResult)
    );
  }

  /**
   * Path part for operation recipeSearchAggregateRecipeIndexPost
   */
  static readonly RecipeSearchAggregateRecipeIndexPostPath = '/recipe/search-aggregate-recipe-index';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeSearchAggregateRecipeIndexPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchAggregateRecipeIndexPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<StrictHttpResponse<RecipeRecordAggregationSearchResult>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeService.RecipeSearchAggregateRecipeIndexPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeRecordAggregationSearchResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeSearchAggregateRecipeIndexPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchAggregateRecipeIndexPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<RecipeRecordAggregationSearchResult> {

    return this.recipeSearchAggregateRecipeIndexPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeRecordAggregationSearchResult>) => r.body as RecipeRecordAggregationSearchResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeSearchAggregateRecipeIndexPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchAggregateRecipeIndexPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<StrictHttpResponse<RecipeRecordAggregationSearchResult>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeService.RecipeSearchAggregateRecipeIndexPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeRecordAggregationSearchResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeSearchAggregateRecipeIndexPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchAggregateRecipeIndexPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<RecipeRecordAggregationSearchResult> {

    return this.recipeSearchAggregateRecipeIndexPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeRecordAggregationSearchResult>) => r.body as RecipeRecordAggregationSearchResult)
    );
  }

  /**
   * Path part for operation recipeRecipeTemplateDetailsPost
   */
  static readonly RecipeRecipeTemplateDetailsPostPath = '/recipe/recipe-template-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeRecipeTemplateDetailsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeRecipeTemplateDetailsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeTemplateDetailSearchCommand
  }): Observable<StrictHttpResponse<RecipeTemplateSearchResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeService.RecipeRecipeTemplateDetailsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeTemplateSearchResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeRecipeTemplateDetailsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeRecipeTemplateDetailsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeTemplateDetailSearchCommand
  }): Observable<RecipeTemplateSearchResponse> {

    return this.recipeRecipeTemplateDetailsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeTemplateSearchResponse>) => r.body as RecipeTemplateSearchResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeRecipeTemplateDetailsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeRecipeTemplateDetailsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeTemplateDetailSearchCommand
  }): Observable<StrictHttpResponse<RecipeTemplateSearchResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RecipeService.RecipeRecipeTemplateDetailsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeTemplateSearchResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recipeRecipeTemplateDetailsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeRecipeTemplateDetailsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeTemplateDetailSearchCommand
  }): Observable<RecipeTemplateSearchResponse> {

    return this.recipeRecipeTemplateDetailsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeTemplateSearchResponse>) => r.body as RecipeTemplateSearchResponse)
    );
  }

}
