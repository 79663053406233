import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition, GridContextMenuItem } from 'bpt-ui-library/bpt-grid';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LabItemWisePermissions } from '../../experiment/labItems/shared/lab-items-feature-manager';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { ClientStateService } from '../../services/client-state.service';
import { ExperimentPreparationService } from '../../experiment/preparations/experiment-preparations/services/experiment-preparation.service';
import { PreparationEventService } from '../services/preparation-event.service';
import { PreparationService } from '../services/preparation.service';
import { ICellRendererParams } from 'ag-grid-community';
import { PreparationRow } from '../preparations.component';
import { ExperimentPreparationStatus } from '../../api/models';

@Component({
  selector: 'app-removed-preparations',
  templateUrl: './removed-preparations.component.html',
  styleUrls: ['./removed-preparations.component.scss']
})

export class RemovedPreparationsComponent implements OnInit, OnDestroy {
  @Input() titleOfTable = $localize`:@@preparationColumnTableTitle:Preparations`;
  removedPreparationData!: { [key: string]: any }[];
  @ViewChild('preparationsRemovedGrid') preparationsRemovedGrid!: BptGridComponent;
  subHeading = $localize`:@@preparationsRemovedColumnSubHeading:Please click on the restore icon from the table to restore removed preparation`;
  gridMode = BptGridMode.dataView;
  permission!: LabItemWisePermissions;
  columnDefinitions: ColumnDefinition[] = [];
  rowActions: BptRowActionElement[] = [];
  protected activeSubscriptions: Subscription[] = [];
  removedRowsColumnId = 'removedPreparationRows';
  gridActions!: BptGridRowActionConfiguration;
  isDataRendered = false;
  reloadGrid = true;
  constructor(
    private readonly experimentPreparationService: ExperimentPreparationService,
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly preparationEventService: PreparationEventService,
    private readonly preparationService: PreparationService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
  ) {
  }

  ngOnInit(): void {
    this.watchColumnRestoredNotification();
    this.columnDefinitions = this.preparationService.columnDefinitions;
    this.addGridActions();
    this.reloadGrid = false;
    setTimeout(() => {
      this.reloadGrid = true;
    }, 200);
    this.refreshDataSource();
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-id', this.removedRowsColumnId);
  }

  private refreshDataSource(): void {
    const removedRows = this.preparationService.getAllPreparationsFromCurrentActivity(this.experimentPreparationService.removedPreparationsDetails.filter(prep => prep.isRemoved));
    this.removedPreparationData = [...this.preparationService.getPrimitiveDataValueRows(removedRows as any)];
    this.preparationsRemovedGrid?.gridApi?.setRowData(this.removedPreparationData);
    this.preparationsRemovedGrid?.gridApi?.refreshCells();
    this.isDataRendered = true;
  }

  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);
    this.gridActions = {
      actions: actionsSubject,
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.experimentPreparationService.columnRestoreActionId,
        enabled:(input: ICellRendererParams) => {
          if(!this.experimentPreparationService.isRestoreButtonEnabled) {
            return false;
          }
          const data = input.data as PreparationRow;
          if (data.status.toLocaleLowerCase() === ExperimentPreparationStatus.Reviewed) {
            return false;
          }
          return true;
        },
        styleClass: 'icon-s icon-restore-icon',
        click: this.rowRestoreActionClick.bind(this),
        tooltip: $localize`:@@restorePreparation:Restore preparation`
      }
    ];
  }

  private rowRestoreActionClick(e: BptGridRowActionClickEvent) {
    this.preparationService.restorePreparation(e.params.data.id, e.params.data.preparationNumber);
  }

  getContextMenu(): GridContextMenuItem[] {
    return [];
  }

  private watchColumnRestoredNotification(): void {
    this.activeSubscriptions.push(
      this.preparationEventService.preparationRestoredSuccessFlag.subscribe((data: boolean) => {
        const preparationRestoredSuccessMessage = $localize`:@@preparationRestoredSuccessMessage:The Preparation has been restored successfully`
        this.preparationService.buildToastMessage('notification', 'success', 'addSuccessToast', preparationRestoredSuccessMessage, false)
        this.refreshDataSource();
      })
    )
  }


  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }
}
