import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FeatureService } from 'services/feature.service';
import { Logger } from 'services/logger.service';
import { CurrentState } from './app.states';
import  * as titleCaseHelper from '../app/shared/title-case-helper'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ELN';

  private eventSubscription?: Subscription;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title,
    private readonly logService: Logger,
    private readonly featureManager: FeatureService,
    private readonly  titleCasePipe:TitleCasePipe,
    @Inject('Window') private readonly window: Window
  ) {
    titleCaseHelper.setTitleCasePipe(this.titleCasePipe)
  }
  async ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.eventSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let newTitle = '';
          let child = this.activatedRoute.firstChild;
          while (child?.firstChild) {
            child = child.firstChild;
            if (child?.snapshot?.data['title']) {
              newTitle = `${newTitle}${newTitle ? ' - ' : ''}${child.snapshot.data['title']}`;
            }
          }
          return newTitle || appTitle;
        })
      )
      .subscribe((title: string) => {
        this.titleService.setTitle(title);
      });

    this.goToState();
    this.logService.openLogViewer(this.window);
    await this.featureManager.loadFeatureFlags();
  }

  /**
   * This method will check for the current state loaded in window object by Helium
   * Based on the client state, the corresponding module will be redirected in ELN
   * This function works only within Helium UI
   */
  goToState() {
    var windowVar = window as any;
    if (windowVar['currentState']) {
      if (CurrentState[windowVar['currentState']]) {
        this.router.navigateByUrl(CurrentState[windowVar['currentState']]);
      }
    }
  }

  ngOnDestroy(): void {
    this.eventSubscription?.unsubscribe();
  }
}
