import { ColumnApi, FirstDataRenderedEvent, GridApi, GridOptions } from 'ag-grid-community';

export class GridActionsPositionManager {
  public styleDynamicVariables = '';
  constructor(public readonly selectorOfComponent: string, public readonly offsetWidth: number) {}

  public applicationGridOptions = (): GridOptions => {
    const gridOptions: GridOptions = {
      onColumnPinned: (params) => {
        this.updatePosition(params.api, params.columnApi);
      },
      onBodyScrollEnd: (params) => {
        this.updatePosition(params.api, params.columnApi);
      }
    };
    return gridOptions;
  };

  public updatePosition(gridApi: GridApi, columnsApi: ColumnApi) {
    this.finalizePosition(gridApi, columnsApi);
    setTimeout(() => {
      this.finalizePosition(gridApi, columnsApi);
    }, 1000);
  }

  private finalizePosition(gridApi: GridApi, columnsApi: ColumnApi) {
    let noHScrollFallbackLeft = -1;
    columnsApi.getAllDisplayedColumns()?.forEach((col) => {
      if (!col.isPinned()) {
        noHScrollFallbackLeft = col.getLeft() || -1;
        if(noHScrollFallbackLeft !== -1){
          noHScrollFallbackLeft += col.getActualWidth();
        }
      }
    });
    this.styleDynamicVariables = this.calculateLeftPosition(
      gridApi,
      this.selectorOfComponent,
      noHScrollFallbackLeft
    );
  }

  public calculateLeftPosition(
    gridApi: GridApi,
    selectorOfComponent: string,
    alternativeLeft: number = -1
  ) {
    const scroll = document.querySelector(`${selectorOfComponent} div.ag-body-horizontal-scroll`);
    let left = (gridApi.getHorizontalPixelRange().right || this.offsetWidth) - this.offsetWidth;
    if (scroll && scroll.clientHeight === 0) {
      left = alternativeLeft === -1 ? left : (alternativeLeft - this.offsetWidth);
    }
    return `--${selectorOfComponent}-action-left:${left}px`;
  }

  public firstDataRendered(event: FirstDataRenderedEvent): void {
    this.updatePosition(event.api, event.columnApi);
    setTimeout(() => {
      event.columnApi.autoSizeAllColumns();
    }, 250);
  }
}
