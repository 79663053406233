/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ColumnHeadersRequest } from '../models/column-headers-request';
import { ColumnHeadersResponse } from '../models/column-headers-response';
import { QuartersWithResultSetsRequest } from '../models/quarters-with-result-sets-request';
import { QuartersWithResultSetsResponse } from '../models/quarters-with-result-sets-response';
import { ResultAndPeakCollectionResponse } from '../models/result-and-peak-collection-response';
import { ResultAndPeakRequest } from '../models/result-and-peak-request';

@Injectable({
  providedIn: 'root',
})
export class EmpowerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiEmpowerQuarterAndResultsetsPost
   */
  static readonly ApiEmpowerQuarterAndResultsetsPostPath = '/api/empower/quarter-and-resultsets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmpowerQuarterAndResultsetsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerQuarterAndResultsetsPost$Plain$Response(params?: {
    body?: QuartersWithResultSetsRequest
  }): Observable<StrictHttpResponse<QuartersWithResultSetsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmpowerService.ApiEmpowerQuarterAndResultsetsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuartersWithResultSetsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmpowerQuarterAndResultsetsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerQuarterAndResultsetsPost$Plain(params?: {
    body?: QuartersWithResultSetsRequest
  }): Observable<QuartersWithResultSetsResponse> {

    return this.apiEmpowerQuarterAndResultsetsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QuartersWithResultSetsResponse>) => r.body as QuartersWithResultSetsResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmpowerQuarterAndResultsetsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerQuarterAndResultsetsPost$Json$Response(params?: {
    body?: QuartersWithResultSetsRequest
  }): Observable<StrictHttpResponse<QuartersWithResultSetsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmpowerService.ApiEmpowerQuarterAndResultsetsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuartersWithResultSetsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmpowerQuarterAndResultsetsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerQuarterAndResultsetsPost$Json(params?: {
    body?: QuartersWithResultSetsRequest
  }): Observable<QuartersWithResultSetsResponse> {

    return this.apiEmpowerQuarterAndResultsetsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QuartersWithResultSetsResponse>) => r.body as QuartersWithResultSetsResponse)
    );
  }

  /**
   * Path part for operation apiEmpowerColumnNamesPost
   */
  static readonly ApiEmpowerColumnNamesPostPath = '/api/empower/column-names';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmpowerColumnNamesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerColumnNamesPost$Plain$Response(params?: {
    body?: ColumnHeadersRequest
  }): Observable<StrictHttpResponse<ColumnHeadersResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmpowerService.ApiEmpowerColumnNamesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ColumnHeadersResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmpowerColumnNamesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerColumnNamesPost$Plain(params?: {
    body?: ColumnHeadersRequest
  }): Observable<ColumnHeadersResponse> {

    return this.apiEmpowerColumnNamesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ColumnHeadersResponse>) => r.body as ColumnHeadersResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmpowerColumnNamesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerColumnNamesPost$Json$Response(params?: {
    body?: ColumnHeadersRequest
  }): Observable<StrictHttpResponse<ColumnHeadersResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmpowerService.ApiEmpowerColumnNamesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ColumnHeadersResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmpowerColumnNamesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerColumnNamesPost$Json(params?: {
    body?: ColumnHeadersRequest
  }): Observable<ColumnHeadersResponse> {

    return this.apiEmpowerColumnNamesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ColumnHeadersResponse>) => r.body as ColumnHeadersResponse)
    );
  }

  /**
   * Path part for operation apiEmpowerPeaksAndResultsPost
   */
  static readonly ApiEmpowerPeaksAndResultsPostPath = '/api/empower/peaks-and-results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmpowerPeaksAndResultsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerPeaksAndResultsPost$Plain$Response(params?: {
    body?: ResultAndPeakRequest
  }): Observable<StrictHttpResponse<ResultAndPeakCollectionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmpowerService.ApiEmpowerPeaksAndResultsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultAndPeakCollectionResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmpowerPeaksAndResultsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerPeaksAndResultsPost$Plain(params?: {
    body?: ResultAndPeakRequest
  }): Observable<ResultAndPeakCollectionResponse> {

    return this.apiEmpowerPeaksAndResultsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultAndPeakCollectionResponse>) => r.body as ResultAndPeakCollectionResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmpowerPeaksAndResultsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerPeaksAndResultsPost$Json$Response(params?: {
    body?: ResultAndPeakRequest
  }): Observable<StrictHttpResponse<ResultAndPeakCollectionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmpowerService.ApiEmpowerPeaksAndResultsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultAndPeakCollectionResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmpowerPeaksAndResultsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerPeaksAndResultsPost$Json(params?: {
    body?: ResultAndPeakRequest
  }): Observable<ResultAndPeakCollectionResponse> {

    return this.apiEmpowerPeaksAndResultsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultAndPeakCollectionResponse>) => r.body as ResultAndPeakCollectionResponse)
    );
  }

}
