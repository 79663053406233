<form #form="ngForm"
  novalidate>
  <bpt-slider #slider
    class="specificationInput-slider flex flex-auto flex-wrap flex-column"
    id="specificationInputSlider"
    [headerText]="headerText"
    [size]="'large'"
    [closeOnEscape]="true"
    [(visible)]="visible"
    (visibleChange)="onVisibleChanged($event)"
    [displayFooter]="!readOnly"
    [isPrimaryButtonDisabled]="validate() ? false : true"
    primaryButtonLabel="Commit"
    i18n-primaryButtonLabel="@@commit"
    (footerPrimaryButtonClick)="onCommit()"
    secondaryButtonLabel="Cancel"
    i18n-secondaryButtonLabel="@@cancel"
    (footerSecondaryButtonClick)="onCancel()">

    <div class="input-wrapper">
      <!-- This div works around a bug in bpt-dropdown's isQuantity function -->
      <bpt-dropdown
        id="specificationInputSlider-type"
        name="type"
        ngDefaultControl
        [commitValueOnEnter]="true"
        [(ngModel)]="specificationType"
        [options]="specTypes"
        [labelField]="'label'"
        [valueField]="'value'"
        label="Type"
        i18n-label="@@type"
        [readOnly]="readOnly"
        [allowNA]="true"
        [showClear]="true"
        (dropdownClear)="clearType($event)">
      </bpt-dropdown>

      <ng-container *ngIf="observationModel">
        <bpt-text-input
          id="specificationInputSlider-value"
          class="specificationInputSlider-value"
          name="value"
          ngDefaultControl
          label="Value"
          i18n-label="@@value"
          [commitValueOnEnter]="true"
          [(ngModel)]="observationModel.value"
          [required]="true"
          [readOnly]="readOnly"
          [multiLine]="false"
          placeholder="Value"
          i18n-placeholder="@@value"
          (ngModelChange)="valueInputChanged($event)"
          (textInputBlur)="valueInputBlurred($event)">
        </bpt-text-input>

        <p-checkbox
          class="compliance-checkbox"
          name="compliance-checkbox"
          ngDefaultControl
          [binary]="true"
          [(ngModel)]="complianceAssessment"
          [readonly]="readOnly"
          label="Compliance Assessment Needed"
          i18n-label="@@complianceAssessmentNeeded">
        </p-checkbox>
        <br />
        <p-checkbox
          class="compliance-expectExactMatch"
          name="expectExactMatch-checkbox"
          ngDefaultControl
          [disabled]="disabled || !complianceAssessment"
          [binary]="true"
          [(ngModel)]="exactMatchCompliance"
          [readonly]="readOnly"
          label="Expect Exact Match"
          i18n-label="@@expectExactMatch">
        </p-checkbox>
      </ng-container>

      <ng-container *ngIf="singleValueModel || twoValueRangeModel">

        <div class="specValueControlGroup" *ngIf="singleValueModel">
          <div class="grid">
            <div class="col">
              <bpt-dropdown
                id="singleValueOperatorDropdown"
                name="operatorDropdown"
                label="Operator"
                i18n-label="@@operator"
                ngDefaultControl
                [commitValueOnEnter]="true"
                [options]="specOperatorTypes"
                [required]="true"
                [readOnly]="readOnly"
                [labelField]="'label'"
                [valueField]="'value'"
                [(ngModel)]="singleComparisonOperator">
              </bpt-dropdown>
            </div>

            <div class="col">
              <bpt-quantity
                id="singleValueQuantity"
                label="Value"
                i18n-label="@@value"
                name="singleValueQuantity"
                ngDefaultControl
                [commitValueOnEnter]="true"
                [required]="true"
                [enableSignificantFigures]="true"
                [allowedUnits]="allowedUnits"
                [cancelValueOnEscape]="true"
                [ngModel]="singleNumberValue"
                [ngModelOptions]="{ updateOn: 'change' }"
                [readOnly]="readOnly"
                [allowNA]="true"
                [allowNegative]="true"
                [allowDecimal]="true"
                [highlightAllOnFocus]="false"
                [suppressContextMenu]="true"
                [enableSignificantFigures]="true"
                (quantityChange)="singleNumberValue = $event">
              </bpt-quantity>
            </div>
          </div>
        </div>

        <div *ngIf="twoValueRangeModel">
          <div class="specValueControlGroup">
            <div class="grid">
              <div class="col">
                <bpt-dropdown
                  id="minimumOperatorDropdown"
                  name="minimumOperatorDropdown"
                  label="Minimum Operator"
                  i18n-label="@@minimumOperator"
                  [commitValueOnEnter]="true"
                  ngDefaultControl
                  [options]="specLowerComparisonOperators"
                  [required]="true"
                  [readOnly]="readOnly"
                  [labelField]="'label'"
                  [valueField]="'value'"
                  [(ngModel)]="twoValueLowerComparisonOperator">
                </bpt-dropdown>
              </div>

              <div class="col">
                <bpt-quantity
                  id="minimumValueQuantity"
                  label="Minimum Value"
                  i18n-label="@@minimumValue"
                  name="minimumValueQuantity"
                  [commitValueOnEnter]="true"
                  ngDefaultControl
                  [required]="true"
                  [enableSignificantFigures]="true"
                  [allowedUnits]="allowedUnits"
                  [cancelValueOnEscape]="true"
                  [ngModel]="twoValueLowerNumberValue"
                  [ngModelOptions]="{ updateOn: 'change' }"
                  [readOnly]="readOnly"
                  [allowNA]="true"
                  [allowNegative]="true"
                  [allowDecimal]="true"
                  [highlightAllOnFocus]="false"
                  [suppressContextMenu]="true"
                  [enableSignificantFigures]="true"
                  (quantityChange)="twoValueLowerNumberValue = $event">
                </bpt-quantity>
              </div>
            </div>
          </div>
          <div class="specValueControlGroup">
            <div class="grid">
              <div class="col">
                <bpt-dropdown
                  id="maximumOperatorDropdown"
                  name="maximumOperatorDropdown"
                  label="Maximum Operator"
                  i18n-label="@@maximumOperator"
                  [commitValueOnEnter]="true"
                  ngDefaultControl
                  [options]="specUpperComparisonOperators"
                  [required]="true"
                  [readOnly]="readOnly"
                  [labelField]="'label'"
                  [valueField]="'value'"
                  [(ngModel)]="twoValueUpperComparisonOperator">
                </bpt-dropdown>
              </div>

              <div class="col">
                <bpt-quantity
                  id="maximumValueQuantity"
                  label="Maximum Value"
                  i18n-label="@@maximumValue"
                  name="maximumValueQuantity"
                  [commitValueOnEnter]="true"
                  ngDefaultControl
                  [required]="true"
                  [enableSignificantFigures]="true"
                  [allowedUnits]="allowedUnits"
                  [cancelValueOnEscape]="true"
                  [ngModel]="twoValueUpperNumberValue"
                  [ngModelOptions]="{ updateOn: 'change' }"
                  [readOnly]="readOnly"
                  [allowNA]="true"
                  [allowNegative]="true"
                  [allowDecimal]="true"
                  [highlightAllOnFocus]="false"
                  [suppressContextMenu]="true"
                  [enableSignificantFigures]="true"
                  (quantityChange)="twoValueUpperNumberValue = $event">
                </bpt-quantity>
              </div>
            </div>
          </div>
        </div>

        <span
          class="spec-label"
          id="specificationInputSlider-specLabel"
          i18n="@@specification"
          >Specification</span>

        <bpt-text-input
          id="specificationInputSlider-specDisplay"
          class="specificationInputSlider-specDisplay"
          name="specDisplay"
          ngDefaultControl
          [commitValueOnEnter]="true"
          [(ngModel)]="specificationDisplay"
          [readOnly]="true">
        </bpt-text-input>

        <div class="specStyleRadioButtons">

          <p-radioButton
            id="specStyleRadioButton-expression"
            name="specStyleRadioButtonGroup"
            class="specStyleRadioButton-expression"
            label="Use &quot;&lt;&quot; style"
            i18n-label="@@useExpressionStyle"
            [(ngModel)]="specDisplayStyle"
            [value]="specDisplayType.Expression"
            [disabled]="readOnly">
          </p-radioButton>

          <p-radioButton
            id="specStyleRadioButton-abbreviation"
            name="specStyleRadioButtonGroup"
            class="specStyleRadioButton-abbreviation"
            label="Use &quot;NMT&quot; style"
            i18n-label="@@useAbbreviationStyle"
            [(ngModel)]="specDisplayStyle"
            [value]="specDisplayType.Abbreviation"
            [disabled]="readOnly">
          </p-radioButton>

          <p-radioButton
            *ngIf="twoValueRangeModel"
            id="specStyleRadioButton-inequality"
            name="specStyleRadioButtonGroup"
            class="specStyleRadioButton-inequality"
            label="Use &quot;&lt; x &lt;&quot; style"
            i18n-label="@@useInequalityStyle"
            [(ngModel)]="specDisplayStyle"
            [value]="specDisplayType.Inequality"
            [disabled]="readOnly">
          </p-radioButton>

        </div>
        <br />
        <p-checkbox
          id="singleValue-compliance-checkbox"
          class="compliance-checkbox"
          name="compliance-checkbox"
          ngDefaultControl
          [binary]="true"
          [(ngModel)]="complianceAssessment"
          label="Compliance Assessment Needed"
          i18n-label="@@complianceAssessmentNeeded"
          [readonly]="readOnly">
        </p-checkbox>
        <br />
        <div class="specComplianceRadioButtons">
          <p-radioButton
            id="specComplianceRadioButton-round"
            name="specComplianceRadioButtonGroup"
            class="specComplianceRadioButton-round"
            label="Round result to spec"
            i18n-label="@@roundToSpec"
            [disabled]="disabled || !complianceAssessment || readOnly"
            [value]="true"
            [(ngModel)]="roundCompliance">
          </p-radioButton>

          <p-radioButton
            id="specComplianceRadioButton-truncate"
            name="specComplianceRadioButtonGroup"
            class="specComplianceRadioButton-truncate"
            label="Truncate result to spec"
            i18n-label="@@truncateToSpec"
            [disabled]="disabled || !complianceAssessment || readOnly"
            [value]="false"
            [(ngModel)]="roundCompliance">
          </p-radioButton>
        </div>
        <div class="singleValue-scale-checkbox-container" *ngIf="showScalingControls">
          <p-checkbox
            id="singleValue-scale-up-checkbox"
            class="singleValue-scale-checkbox"
            name="singleValue-scale-up-checkbox"
            ngDefaultControl
            [binary]="true"
            [(ngModel)]="allowScaleUp"
            label="Allow Scaling Up"
            i18n-label="@@allowScalingUp"
            [readonly]="readOnly">
          </p-checkbox>

          <p-checkbox
            id="singleValue-scale-down-checkbox"
            class="singleValue-scale-checkbox"
            name="singleValue-scale-down-checkbox"
            ngDefaultControl
            [binary]="true"
            [(ngModel)]="allowScaleDown"
            label="Allow Scaling Down"
            i18n-label="@@allowScalingDown"
            [readonly]="readOnly">
          </p-checkbox>
        </div>
      </ng-container>

      <ng-container *ngIf="!singleValueModel && !twoValueRangeModel">
        <span
          class="spec-label"
          id="specificationInputSlider-specLabel"
          i18n="@@specification"
          >Specification</span>

        <bpt-text-input
          id="specificationInputSlider-specDisplay"
          class="specificationInputSlider-specDisplay"
          name="specDisplay"
          ngDefaultControl
          [commitValueOnEnter]="true"
          [(ngModel)]="specificationDisplay"
          [readOnly]="true">
        </bpt-text-input>
      </ng-container>
    </div>
  </bpt-slider>
</form>
