/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Labsite } from '../models/labsite';
import { LabsiteGetResponse } from '../models/labsite-get-response';

@Injectable({
  providedIn: 'root',
})
export class LabsiteService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation labsitesLabsiteCodeSubBusinessUnitsGet
   */
  static readonly LabsitesLabsiteCodeSubBusinessUnitsGetPath = '/labsites/{labsiteCode}/sub-business-units';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labsitesLabsiteCodeSubBusinessUnitsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesLabsiteCodeSubBusinessUnitsGet$Plain$Response(params: {
    labsiteCode: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<LabsiteGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabsiteService.LabsitesLabsiteCodeSubBusinessUnitsGetPath, 'get');
    if (params) {
      rb.path('labsiteCode', params.labsiteCode, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabsiteGetResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labsitesLabsiteCodeSubBusinessUnitsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesLabsiteCodeSubBusinessUnitsGet$Plain(params: {
    labsiteCode: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<LabsiteGetResponse> {

    return this.labsitesLabsiteCodeSubBusinessUnitsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabsiteGetResponse>) => r.body as LabsiteGetResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labsitesLabsiteCodeSubBusinessUnitsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesLabsiteCodeSubBusinessUnitsGet$Json$Response(params: {
    labsiteCode: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<LabsiteGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabsiteService.LabsitesLabsiteCodeSubBusinessUnitsGetPath, 'get');
    if (params) {
      rb.path('labsiteCode', params.labsiteCode, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabsiteGetResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labsitesLabsiteCodeSubBusinessUnitsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesLabsiteCodeSubBusinessUnitsGet$Json(params: {
    labsiteCode: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<LabsiteGetResponse> {

    return this.labsitesLabsiteCodeSubBusinessUnitsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabsiteGetResponse>) => r.body as LabsiteGetResponse)
    );
  }

  /**
   * Path part for operation labsitesSubBusinessUnitsGet
   */
  static readonly LabsitesSubBusinessUnitsGetPath = '/labsites/sub-business-units';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labsitesSubBusinessUnitsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesSubBusinessUnitsGet$Plain$Response(params?: {
    labsiteCodes?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<LabsiteGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabsiteService.LabsitesSubBusinessUnitsGetPath, 'get');
    if (params) {
      rb.query('labsiteCodes', params.labsiteCodes, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabsiteGetResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labsitesSubBusinessUnitsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesSubBusinessUnitsGet$Plain(params?: {
    labsiteCodes?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<LabsiteGetResponse> {

    return this.labsitesSubBusinessUnitsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabsiteGetResponse>) => r.body as LabsiteGetResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labsitesSubBusinessUnitsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesSubBusinessUnitsGet$Json$Response(params?: {
    labsiteCodes?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<LabsiteGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabsiteService.LabsitesSubBusinessUnitsGetPath, 'get');
    if (params) {
      rb.query('labsiteCodes', params.labsiteCodes, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabsiteGetResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labsitesSubBusinessUnitsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesSubBusinessUnitsGet$Json(params?: {
    labsiteCodes?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<LabsiteGetResponse> {

    return this.labsitesSubBusinessUnitsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabsiteGetResponse>) => r.body as LabsiteGetResponse)
    );
  }

  /**
   * Path part for operation labsitesGet
   */
  static readonly LabsitesGetPath = '/labsites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labsitesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesGet$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<Array<Labsite>>> {

    const rb = new RequestBuilder(this.rootUrl, LabsiteService.LabsitesGetPath, 'get');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Labsite>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labsitesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesGet$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<Array<Labsite>> {

    return this.labsitesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Labsite>>) => r.body as Array<Labsite>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labsitesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesGet$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<Array<Labsite>>> {

    const rb = new RequestBuilder(this.rootUrl, LabsiteService.LabsitesGetPath, 'get');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Labsite>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labsitesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesGet$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<Array<Labsite>> {

    return this.labsitesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Labsite>>) => r.body as Array<Labsite>)
    );
  }

}
