import { Injectable } from '@angular/core';
import { NA } from 'bpt-ui-library/shared';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { Unit, UnitList } from '../api/models';
import { UnitsService } from '../api/services';

@Injectable({
  providedIn: 'root'
})
export class UnitLoaderService {
  allUnits$: Observable<Unit[]> = of([]);
  allUnitLists$: Observable<UnitList[]> = of([]);

  private _allUnits: Unit[] | undefined;
  /**
   * All units that were last loaded, if any.
   * Use `allUnits$` if an observable is needed, which can be used to pre-load this array.
   * May want to filter a list by IsAvailable and/or within a list by IsAvailable.
   */
  get allUnits(): Unit[] {
    if (!this._allUnits) throw Error('Could not find units. Units may not be loaded yet.'); //
    return this._allUnits;
  }

  private _allUnitLists: UnitList[] | undefined;
  /**
   * All unit lists that were last loaded, if any.
   * Use `allUnitLists$` if an observable is needed, which can be used to pre-load this array.
   * May want to filter by IsAvailable.
   */
  get allUnitLists(): UnitList[] {
    if (!this._allUnitLists) throw Error('Could not find unit lists. Units may not be loaded yet.'); //
    return this._allUnitLists;
  }

  private _naUnit: Unit | undefined;
  /**
   * N/A unit from units that were last loaded, if any.
   * Use `allUnitLists$` if an observable is needed, which can be used to pre-load this value.
   */
  get naUnit(): Unit {
    if (!this._naUnit) throw Error('Could not find N/A Unit. Units may not be loaded yet.'); //
    return this._naUnit;
  }

  constructor(private readonly unitService: UnitsService) {}

  public loadUnits(): void{
    this.allUnits$ = this.unitService.unitsGet$Json({ includeInactiveUnits: true }).pipe(
      map((data) => data.units),
      tap((units) => this._allUnits = units),
      tap((units) => this._naUnit = units.find((u) => u.name === NA)),
      shareReplay()
    );
    this.allUnitLists$ = this.unitService.unitsListsGet$Json().pipe(
      map((data) => data.unitLists),
      tap((unitLists) => this._allUnitLists = unitLists),
      shareReplay()
    );
  }
}
