/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeviceMonitorDto } from '../models/device-monitor-dto';
import { DeviceMonitorStatusDto } from '../models/device-monitor-status-dto';
import { InstrumentCreateDto } from '../models/instrument-create-dto';
import { InstrumentDto } from '../models/instrument-dto';
import { InstrumentSearchDto } from '../models/instrument-search-dto';
import { InstrumentSearchResultsDto } from '../models/instrument-search-results-dto';
import { UpdateInstrumentAvailabilityDto } from '../models/update-instrument-availability-dto';
import { UpdateInstrumentStatusDto } from '../models/update-instrument-status-dto';

@Injectable({
  providedIn: 'root',
})
export class InstrumentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation instrumentsGet
   */
  static readonly InstrumentsGetPath = '/instruments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<InstrumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InstrumentDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsGet$Plain(params?: {
  }): Observable<Array<InstrumentDto>> {

    return this.instrumentsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InstrumentDto>>) => r.body as Array<InstrumentDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<InstrumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InstrumentDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsGet$Json(params?: {
  }): Observable<Array<InstrumentDto>> {

    return this.instrumentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InstrumentDto>>) => r.body as Array<InstrumentDto>)
    );
  }

  /**
   * Path part for operation instrumentsInstrumentDetailsGet
   */
  static readonly InstrumentsInstrumentDetailsGetPath = '/instruments/instrument-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsInstrumentDetailsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsInstrumentDetailsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<InstrumentCreateDto>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsInstrumentDetailsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentCreateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsInstrumentDetailsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsInstrumentDetailsGet$Plain(params?: {
  }): Observable<InstrumentCreateDto> {

    return this.instrumentsInstrumentDetailsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentCreateDto>) => r.body as InstrumentCreateDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsInstrumentDetailsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsInstrumentDetailsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<InstrumentCreateDto>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsInstrumentDetailsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentCreateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsInstrumentDetailsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsInstrumentDetailsGet$Json(params?: {
  }): Observable<InstrumentCreateDto> {

    return this.instrumentsInstrumentDetailsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentCreateDto>) => r.body as InstrumentCreateDto)
    );
  }

  /**
   * Path part for operation instrumentsIdGet
   */
  static readonly InstrumentsIdGetPath = '/instruments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsIdGet$Plain$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<InstrumentDto>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsIdGet$Plain(params: {
    id: string;
  }): Observable<InstrumentDto> {

    return this.instrumentsIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentDto>) => r.body as InstrumentDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsIdGet$Json$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<InstrumentDto>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsIdGet$Json(params: {
    id: string;
  }): Observable<InstrumentDto> {

    return this.instrumentsIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentDto>) => r.body as InstrumentDto)
    );
  }

  /**
   * Path part for operation instrumentsIdPost
   */
  static readonly InstrumentsIdPostPath = '/instruments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsIdPost$Plain$Response(params: {
    id: string;
    body?: InstrumentDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsIdPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsIdPost$Plain(params: {
    id: string;
    body?: InstrumentDto
  }): Observable<string> {

    return this.instrumentsIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsIdPost$Json$Response(params: {
    id: string;
    body?: InstrumentDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsIdPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsIdPost$Json(params: {
    id: string;
    body?: InstrumentDto
  }): Observable<string> {

    return this.instrumentsIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation instrumentsAddInstrumentPost
   */
  static readonly InstrumentsAddInstrumentPostPath = '/instruments/add-instrument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsAddInstrumentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsAddInstrumentPost$Plain$Response(params?: {
    body?: InstrumentDto
  }): Observable<StrictHttpResponse<InstrumentDto>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsAddInstrumentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsAddInstrumentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsAddInstrumentPost$Plain(params?: {
    body?: InstrumentDto
  }): Observable<InstrumentDto> {

    return this.instrumentsAddInstrumentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentDto>) => r.body as InstrumentDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsAddInstrumentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsAddInstrumentPost$Json$Response(params?: {
    body?: InstrumentDto
  }): Observable<StrictHttpResponse<InstrumentDto>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsAddInstrumentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsAddInstrumentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsAddInstrumentPost$Json(params?: {
    body?: InstrumentDto
  }): Observable<InstrumentDto> {

    return this.instrumentsAddInstrumentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentDto>) => r.body as InstrumentDto)
    );
  }

  /**
   * Path part for operation instrumentsRefreshDeviceMonitorPut
   */
  static readonly InstrumentsRefreshDeviceMonitorPutPath = '/instruments/refresh-device-monitor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsRefreshDeviceMonitorPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsRefreshDeviceMonitorPut$Plain$Response(params?: {
    body?: DeviceMonitorDto
  }): Observable<StrictHttpResponse<DeviceMonitorStatusDto>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsRefreshDeviceMonitorPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceMonitorStatusDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsRefreshDeviceMonitorPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsRefreshDeviceMonitorPut$Plain(params?: {
    body?: DeviceMonitorDto
  }): Observable<DeviceMonitorStatusDto> {

    return this.instrumentsRefreshDeviceMonitorPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceMonitorStatusDto>) => r.body as DeviceMonitorStatusDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsRefreshDeviceMonitorPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsRefreshDeviceMonitorPut$Json$Response(params?: {
    body?: DeviceMonitorDto
  }): Observable<StrictHttpResponse<DeviceMonitorStatusDto>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsRefreshDeviceMonitorPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceMonitorStatusDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsRefreshDeviceMonitorPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsRefreshDeviceMonitorPut$Json(params?: {
    body?: DeviceMonitorDto
  }): Observable<DeviceMonitorStatusDto> {

    return this.instrumentsRefreshDeviceMonitorPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceMonitorStatusDto>) => r.body as DeviceMonitorStatusDto)
    );
  }

  /**
   * Path part for operation instrumentsSearchPost
   */
  static readonly InstrumentsSearchPostPath = '/instruments/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsSearchPost$Plain$Response(params?: {
    body?: InstrumentSearchDto
  }): Observable<StrictHttpResponse<InstrumentSearchResultsDto>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentSearchResultsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsSearchPost$Plain(params?: {
    body?: InstrumentSearchDto
  }): Observable<InstrumentSearchResultsDto> {

    return this.instrumentsSearchPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentSearchResultsDto>) => r.body as InstrumentSearchResultsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsSearchPost$Json$Response(params?: {
    body?: InstrumentSearchDto
  }): Observable<StrictHttpResponse<InstrumentSearchResultsDto>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentSearchResultsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsSearchPost$Json(params?: {
    body?: InstrumentSearchDto
  }): Observable<InstrumentSearchResultsDto> {

    return this.instrumentsSearchPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentSearchResultsDto>) => r.body as InstrumentSearchResultsDto)
    );
  }

  /**
   * Path part for operation instrumentsUpdateInstrumentStatusPut
   */
  static readonly InstrumentsUpdateInstrumentStatusPutPath = '/instruments/update-instrument-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsUpdateInstrumentStatusPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsUpdateInstrumentStatusPut$Response(params?: {
    body?: UpdateInstrumentStatusDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsUpdateInstrumentStatusPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsUpdateInstrumentStatusPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsUpdateInstrumentStatusPut(params?: {
    body?: UpdateInstrumentStatusDto
  }): Observable<void> {

    return this.instrumentsUpdateInstrumentStatusPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation instrumentsUpdateInstrumentAvailabilityPut
   */
  static readonly InstrumentsUpdateInstrumentAvailabilityPutPath = '/instruments/update-instrument-availability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsUpdateInstrumentAvailabilityPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsUpdateInstrumentAvailabilityPut$Response(params?: {
    body?: UpdateInstrumentAvailabilityDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsUpdateInstrumentAvailabilityPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsUpdateInstrumentAvailabilityPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsUpdateInstrumentAvailabilityPut(params?: {
    body?: UpdateInstrumentAvailabilityDto
  }): Observable<void> {

    return this.instrumentsUpdateInstrumentAvailabilityPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation instrumentsConfigByKeyConfigKeyGet
   */
  static readonly InstrumentsConfigByKeyConfigKeyGetPath = '/instruments/config-by-key/{configKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsConfigByKeyConfigKeyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsConfigByKeyConfigKeyGet$Plain$Response(params: {
    configKey: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsConfigByKeyConfigKeyGetPath, 'get');
    if (params) {
      rb.path('configKey', params.configKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsConfigByKeyConfigKeyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsConfigByKeyConfigKeyGet$Plain(params: {
    configKey: string;
  }): Observable<string> {

    return this.instrumentsConfigByKeyConfigKeyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsConfigByKeyConfigKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsConfigByKeyConfigKeyGet$Json$Response(params: {
    configKey: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsConfigByKeyConfigKeyGetPath, 'get');
    if (params) {
      rb.path('configKey', params.configKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsConfigByKeyConfigKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsConfigByKeyConfigKeyGet$Json(params: {
    configKey: string;
  }): Observable<string> {

    return this.instrumentsConfigByKeyConfigKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation instrumentsHeartbeatGet
   */
  static readonly InstrumentsHeartbeatGetPath = '/instruments/heartbeat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsHeartbeatGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsHeartbeatGet$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InstrumentService.InstrumentsHeartbeatGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `instrumentsHeartbeatGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsHeartbeatGet(params?: {
  }): Observable<void> {

    return this.instrumentsHeartbeatGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
