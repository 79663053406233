/* tslint:disable */
/* eslint-disable */
export enum RecipeType {
  ActivityGroup = 'activityGroup',
  Activity = 'activity',
  Module = 'module',
  TableForm = 'tableForm',
  Form = 'form',
  Table = 'table',
  None = 'none'
}
