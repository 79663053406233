/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ActivityInputCellChangedResponse } from '../models/activity-input-cell-changed-response';
import { ActivityInputRowRefreshedResponse } from '../models/activity-input-row-refreshed-response';
import { ActivityInputRowRemovedResponse } from '../models/activity-input-row-removed-response';
import { ActivityInputRowRestoredResponse } from '../models/activity-input-row-restored-response';
import { AddSampleTestCommand } from '../models/add-sample-test-command';
import { ChangeActivityInputCellCommand } from '../models/change-activity-input-cell-command';
import { ChangeInstrumentDateRemovedCommand } from '../models/change-instrument-date-removed-command';
import { ChangeInstrumentDescriptionCommand } from '../models/change-instrument-description-command';
import { ChangeInstrumentRemovedFromServiceCommand } from '../models/change-instrument-removed-from-service-command';
import { InstrumentDateRemovedChangedResponse } from '../models/instrument-date-removed-changed-response';
import { InstrumentDescriptionChangedResponse } from '../models/instrument-description-changed-response';
import { InstrumentRemovedFromServiceChangedResponse } from '../models/instrument-removed-from-service-changed-response';
import { MaintenanceEventSelectedResponse } from '../models/maintenance-event-selected-response';
import { RefreshActivityInputRowCommand } from '../models/refresh-activity-input-row-command';
import { RemoveActivityInputRowCommand } from '../models/remove-activity-input-row-command';
import { RestoreActivityInputRowCommand } from '../models/restore-activity-input-row-command';
import { SampleTestAddedResponse } from '../models/sample-test-added-response';
import { SelectMaintenanceEventCommand } from '../models/select-maintenance-event-command';
import { SelectStudyActivityCommand } from '../models/select-study-activity-command';
import { StudyActivitySelectedResponse } from '../models/study-activity-selected-response';

@Injectable({
  providedIn: 'root',
})
export class ActivityInputEventsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation activityInputEventsChangeSampleTestPost
   */
  static readonly ActivityInputEventsChangeSampleTestPostPath = '/activity-input-events/change-sample-test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeSampleTestPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeSampleTestPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddSampleTestCommand
  }): Observable<StrictHttpResponse<SampleTestAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsChangeSampleTestPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SampleTestAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeSampleTestPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeSampleTestPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddSampleTestCommand
  }): Observable<SampleTestAddedResponse> {

    return this.activityInputEventsChangeSampleTestPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SampleTestAddedResponse>) => r.body as SampleTestAddedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeSampleTestPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeSampleTestPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddSampleTestCommand
  }): Observable<StrictHttpResponse<SampleTestAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsChangeSampleTestPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SampleTestAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeSampleTestPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeSampleTestPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddSampleTestCommand
  }): Observable<SampleTestAddedResponse> {

    return this.activityInputEventsChangeSampleTestPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SampleTestAddedResponse>) => r.body as SampleTestAddedResponse)
    );
  }

  /**
   * Path part for operation activityInputEventsChangeCellValuePost
   */
  static readonly ActivityInputEventsChangeCellValuePostPath = '/activity-input-events/change-cell-value';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeCellValuePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeCellValuePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeActivityInputCellCommand
  }): Observable<StrictHttpResponse<ActivityInputCellChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsChangeCellValuePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityInputCellChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeCellValuePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeCellValuePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeActivityInputCellCommand
  }): Observable<ActivityInputCellChangedResponse> {

    return this.activityInputEventsChangeCellValuePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ActivityInputCellChangedResponse>) => r.body as ActivityInputCellChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeCellValuePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeCellValuePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeActivityInputCellCommand
  }): Observable<StrictHttpResponse<ActivityInputCellChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsChangeCellValuePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityInputCellChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeCellValuePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeCellValuePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeActivityInputCellCommand
  }): Observable<ActivityInputCellChangedResponse> {

    return this.activityInputEventsChangeCellValuePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ActivityInputCellChangedResponse>) => r.body as ActivityInputCellChangedResponse)
    );
  }

  /**
   * Path part for operation activityInputEventsRestoreRowPost
   */
  static readonly ActivityInputEventsRestoreRowPostPath = '/activity-input-events/restore-row';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsRestoreRowPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRestoreRowPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreActivityInputRowCommand
  }): Observable<StrictHttpResponse<ActivityInputRowRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsRestoreRowPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityInputRowRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsRestoreRowPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRestoreRowPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreActivityInputRowCommand
  }): Observable<ActivityInputRowRestoredResponse> {

    return this.activityInputEventsRestoreRowPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ActivityInputRowRestoredResponse>) => r.body as ActivityInputRowRestoredResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsRestoreRowPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRestoreRowPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreActivityInputRowCommand
  }): Observable<StrictHttpResponse<ActivityInputRowRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsRestoreRowPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityInputRowRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsRestoreRowPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRestoreRowPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreActivityInputRowCommand
  }): Observable<ActivityInputRowRestoredResponse> {

    return this.activityInputEventsRestoreRowPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ActivityInputRowRestoredResponse>) => r.body as ActivityInputRowRestoredResponse)
    );
  }

  /**
   * Path part for operation activityInputEventsRemoveRowPost
   */
  static readonly ActivityInputEventsRemoveRowPostPath = '/activity-input-events/remove-row';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsRemoveRowPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRemoveRowPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveActivityInputRowCommand
  }): Observable<StrictHttpResponse<ActivityInputRowRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsRemoveRowPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityInputRowRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsRemoveRowPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRemoveRowPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveActivityInputRowCommand
  }): Observable<ActivityInputRowRemovedResponse> {

    return this.activityInputEventsRemoveRowPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ActivityInputRowRemovedResponse>) => r.body as ActivityInputRowRemovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsRemoveRowPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRemoveRowPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveActivityInputRowCommand
  }): Observable<StrictHttpResponse<ActivityInputRowRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsRemoveRowPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityInputRowRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsRemoveRowPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRemoveRowPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveActivityInputRowCommand
  }): Observable<ActivityInputRowRemovedResponse> {

    return this.activityInputEventsRemoveRowPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ActivityInputRowRemovedResponse>) => r.body as ActivityInputRowRemovedResponse)
    );
  }

  /**
   * Path part for operation activityInputEventsRefreshRowPost
   */
  static readonly ActivityInputEventsRefreshRowPostPath = '/activity-input-events/refresh-row';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsRefreshRowPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRefreshRowPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshActivityInputRowCommand
  }): Observable<StrictHttpResponse<ActivityInputRowRefreshedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsRefreshRowPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityInputRowRefreshedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsRefreshRowPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRefreshRowPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshActivityInputRowCommand
  }): Observable<ActivityInputRowRefreshedResponse> {

    return this.activityInputEventsRefreshRowPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ActivityInputRowRefreshedResponse>) => r.body as ActivityInputRowRefreshedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsRefreshRowPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRefreshRowPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshActivityInputRowCommand
  }): Observable<StrictHttpResponse<ActivityInputRowRefreshedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsRefreshRowPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityInputRowRefreshedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsRefreshRowPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRefreshRowPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshActivityInputRowCommand
  }): Observable<ActivityInputRowRefreshedResponse> {

    return this.activityInputEventsRefreshRowPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ActivityInputRowRefreshedResponse>) => r.body as ActivityInputRowRefreshedResponse)
    );
  }

  /**
   * Path part for operation activityInputEventsSelectStudyActivityPost
   */
  static readonly ActivityInputEventsSelectStudyActivityPostPath = '/activity-input-events/select-study-activity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsSelectStudyActivityPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectStudyActivityPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SelectStudyActivityCommand
  }): Observable<StrictHttpResponse<StudyActivitySelectedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsSelectStudyActivityPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StudyActivitySelectedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsSelectStudyActivityPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectStudyActivityPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SelectStudyActivityCommand
  }): Observable<StudyActivitySelectedResponse> {

    return this.activityInputEventsSelectStudyActivityPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StudyActivitySelectedResponse>) => r.body as StudyActivitySelectedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsSelectStudyActivityPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectStudyActivityPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SelectStudyActivityCommand
  }): Observable<StrictHttpResponse<StudyActivitySelectedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsSelectStudyActivityPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StudyActivitySelectedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsSelectStudyActivityPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectStudyActivityPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SelectStudyActivityCommand
  }): Observable<StudyActivitySelectedResponse> {

    return this.activityInputEventsSelectStudyActivityPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StudyActivitySelectedResponse>) => r.body as StudyActivitySelectedResponse)
    );
  }

  /**
   * Path part for operation activityInputEventsSelectMaintenanceEventPost
   */
  static readonly ActivityInputEventsSelectMaintenanceEventPostPath = '/activity-input-events/select-maintenance-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsSelectMaintenanceEventPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectMaintenanceEventPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SelectMaintenanceEventCommand
  }): Observable<StrictHttpResponse<MaintenanceEventSelectedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsSelectMaintenanceEventPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MaintenanceEventSelectedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsSelectMaintenanceEventPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectMaintenanceEventPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SelectMaintenanceEventCommand
  }): Observable<MaintenanceEventSelectedResponse> {

    return this.activityInputEventsSelectMaintenanceEventPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MaintenanceEventSelectedResponse>) => r.body as MaintenanceEventSelectedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsSelectMaintenanceEventPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectMaintenanceEventPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SelectMaintenanceEventCommand
  }): Observable<StrictHttpResponse<MaintenanceEventSelectedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsSelectMaintenanceEventPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MaintenanceEventSelectedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsSelectMaintenanceEventPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectMaintenanceEventPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SelectMaintenanceEventCommand
  }): Observable<MaintenanceEventSelectedResponse> {

    return this.activityInputEventsSelectMaintenanceEventPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MaintenanceEventSelectedResponse>) => r.body as MaintenanceEventSelectedResponse)
    );
  }

  /**
   * Path part for operation activityInputEventsChangeInstrumentDescriptionPost
   */
  static readonly ActivityInputEventsChangeInstrumentDescriptionPostPath = '/activity-input-events/change-instrument-description';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeInstrumentDescriptionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDescriptionPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeInstrumentDescriptionCommand
  }): Observable<StrictHttpResponse<InstrumentDescriptionChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsChangeInstrumentDescriptionPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentDescriptionChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeInstrumentDescriptionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDescriptionPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeInstrumentDescriptionCommand
  }): Observable<InstrumentDescriptionChangedResponse> {

    return this.activityInputEventsChangeInstrumentDescriptionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentDescriptionChangedResponse>) => r.body as InstrumentDescriptionChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeInstrumentDescriptionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDescriptionPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeInstrumentDescriptionCommand
  }): Observable<StrictHttpResponse<InstrumentDescriptionChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsChangeInstrumentDescriptionPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentDescriptionChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeInstrumentDescriptionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDescriptionPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeInstrumentDescriptionCommand
  }): Observable<InstrumentDescriptionChangedResponse> {

    return this.activityInputEventsChangeInstrumentDescriptionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentDescriptionChangedResponse>) => r.body as InstrumentDescriptionChangedResponse)
    );
  }

  /**
   * Path part for operation activityInputEventsChangeInstrumentDateRemovedPost
   */
  static readonly ActivityInputEventsChangeInstrumentDateRemovedPostPath = '/activity-input-events/change-instrument-date-removed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeInstrumentDateRemovedPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDateRemovedPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeInstrumentDateRemovedCommand
  }): Observable<StrictHttpResponse<InstrumentDateRemovedChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsChangeInstrumentDateRemovedPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentDateRemovedChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeInstrumentDateRemovedPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDateRemovedPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeInstrumentDateRemovedCommand
  }): Observable<InstrumentDateRemovedChangedResponse> {

    return this.activityInputEventsChangeInstrumentDateRemovedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentDateRemovedChangedResponse>) => r.body as InstrumentDateRemovedChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeInstrumentDateRemovedPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDateRemovedPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeInstrumentDateRemovedCommand
  }): Observable<StrictHttpResponse<InstrumentDateRemovedChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsChangeInstrumentDateRemovedPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentDateRemovedChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeInstrumentDateRemovedPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDateRemovedPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeInstrumentDateRemovedCommand
  }): Observable<InstrumentDateRemovedChangedResponse> {

    return this.activityInputEventsChangeInstrumentDateRemovedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentDateRemovedChangedResponse>) => r.body as InstrumentDateRemovedChangedResponse)
    );
  }

  /**
   * Path part for operation activityInputEventsChangeInstrumentRemovedFromServicePost
   */
  static readonly ActivityInputEventsChangeInstrumentRemovedFromServicePostPath = '/activity-input-events/change-instrument-removed-from-service';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeInstrumentRemovedFromServicePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentRemovedFromServicePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeInstrumentRemovedFromServiceCommand
  }): Observable<StrictHttpResponse<InstrumentRemovedFromServiceChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsChangeInstrumentRemovedFromServicePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentRemovedFromServiceChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeInstrumentRemovedFromServicePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentRemovedFromServicePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeInstrumentRemovedFromServiceCommand
  }): Observable<InstrumentRemovedFromServiceChangedResponse> {

    return this.activityInputEventsChangeInstrumentRemovedFromServicePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentRemovedFromServiceChangedResponse>) => r.body as InstrumentRemovedFromServiceChangedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeInstrumentRemovedFromServicePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentRemovedFromServicePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeInstrumentRemovedFromServiceCommand
  }): Observable<StrictHttpResponse<InstrumentRemovedFromServiceChangedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputEventsService.ActivityInputEventsChangeInstrumentRemovedFromServicePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstrumentRemovedFromServiceChangedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeInstrumentRemovedFromServicePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentRemovedFromServicePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeInstrumentRemovedFromServiceCommand
  }): Observable<InstrumentRemovedFromServiceChangedResponse> {

    return this.activityInputEventsChangeInstrumentRemovedFromServicePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InstrumentRemovedFromServiceChangedResponse>) => r.body as InstrumentRemovedFromServiceChangedResponse)
    );
  }

}
