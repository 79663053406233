import { EmpowerLoginDialog } from '../model/empower-login-interface';
import { Column, QuarterResultSet, ResultAndPeak } from '../../api/instrument-integration/models';

import { EmpowerSelectedResultSet } from '../model/empower-selected-result-set';
import { BptTreeNode } from 'bpt-ui-library/bpt-tree/model/bpttreenode.interface';
import {
  ActivityOutputChromatographyResultSet,
  ChromatographyDataCell,
  ChromatographyDataImportedEventDetails,
  ChromatographyDataPeakRow,
  ChromatographyDataRefreshedDetails,
  ChromatographyDataResultRow,
  EmpowerColumnType,
  EmpowerDatabase,
  ExperimentWorkflowState,
  NodeType
} from '../../api/models';
import { BehaviorSubject, Observable, of, Subject, tap } from 'rxjs';
import {
  ChromatographyDataImportedEventNotification,
  ChromatographyDataRefreshedEventNotification
} from '../../api/data-entry/models';
import { ExperimentService } from '../services/experiment.service';
import { Injectable } from '@angular/core';
import { DataRecordService } from './data-record.service';
import { ExperimentBlobEventsService } from '../../api/services';
import { Message, MessageService } from 'primeng/api';
import { ActivityOutputCollaboratorService } from './output-collaborator-service';
import { LockType } from '../../model/input-lock.interface';
import { ElnProgressSpinnerService } from '../../eln-progress-spinner-module/eln-progress-spinner.service';
import { ActivityOutputChromatographyResultSetSummary } from '../../api/models/activity-output-chromatography-result-set-summary';
import { QueueChromatographyResultSetImportRequestCommand } from '../../api/eln-instruments/models/ELN/InstrumentIntegration/Domain/Chromatography/queue-chromatography-result-set-import-request-command';
import { QueueChromatographyResultSetRefreshRequestCommand } from '../../api/eln-instruments/models/ELN/InstrumentIntegration/Domain/Chromatography/queue-chromatography-result-set-refresh-request-command';
import { ChromatographyIntegrationService } from '../../api/eln-instruments/services';
import { ExperimentEventType } from '../../api/audit/models';
import { ExperimentNotificationOnlyResponse } from '../model/experiment-notification-only-response.model';
export type ElnEmpowerTreeNode = BptTreeNode & { fullPath: string };

export type ActivityEmpowerEventsGrid = {
  newResultSetSummaryApplied: Subject<ActivityOutputChromatographyResultSetSummary>;
  newResultSetContentsAreApplied: Subject<ActivityOutputChromatographyResultSet>;
};

export type ResultSetEventsGrid = {
  refreshed: Subject<ActivityOutputChromatographyResultSet>;
};

export type EmpowerEventsGrid = {
  activityEventsGrid: ActivityEmpowerEventsGrid;
  currentResultSetEvents: ResultSetEventsGrid;
};

@Injectable({
  providedIn: 'root'
})
export class OutputEmpowerService {
  canShowEmpowerLogin = false;
  canShowEmpowerStructure = false;
  selectedResultSet!: EmpowerSelectedResultSet;
  canImportResultSets = false;
  quartersResultSets: Array<QuarterResultSet> = [];
  resultSetTreeData: ElnEmpowerTreeNode[] = [];
  emptyQuarters = false;
  disableEmpowerLogin = true;
  empowerPasswordMaxLength = 31;
  empowerResponse!: string;
  empowerDatabaseOptions!: EmpowerDatabase[];
  empowerData: EmpowerLoginDialog = {
    username: '',
    password: '',
    project: '',
    database: null,
    usernameEmpty: false,
    passwordEmpty: false,
    databaseEmpty: false,
    projectEmpty: false,
    databaseReadOnly: false,
    projectReadOnly: false
  };
  empowerOptions = {
    draggable: false,
    resizable: false,
    modal: true,
    blockScroll: true,
    closable: true,
    selection: 'single'
  };
  errorText = $localize`:@@fieldRequired:The field is required`;
  isRefreshEvent = false;
  isRefreshAuthenticated = new BehaviorSubject<boolean>(false);
  refreshAuthentication = this.isRefreshAuthenticated.asObservable();
  disableEmpowerOperations = false;

  noCurrentExperimentFound = $localize`:@@throwErrorMessage:No current experiment found`;

  readonly activityEventsGrid: { [key: string]: ActivityEmpowerEventsGrid } = {};
  readonly resultSetEventsGrid: { [key: string]: ResultSetEventsGrid } = {};

  public static readonly blobMissingError = $localize`:@@empowerEventBlobMissing:The import request from empower failed to process. Please contact the IT administrator.`;
  public static readonly refreshTitle = $localize`:@@Refresh:Refresh`;
  public static readonly importTitle = $localize`:@@import:Import`;

  public readonly serverErrorMessageHandlers: {
    [key: string]: (notification: ExperimentNotificationOnlyResponse, operationTitle: string) => void;
  } = {
    RequestCannotBeProcessedWhenExperimentIsAuthorized:
      this.displayErrorMessageForOperation.bind(this),
    RequestCannotBeProcessedWhenExperimentIsCancelled:
      this.displayErrorMessageForOperation.bind(this)
  };

  // Used for user authentication during login for refresh
  changeRefreshAuthenticated(isUserAuthenticated: boolean) {
    this.isRefreshAuthenticated.next(isUserAuthenticated);
  }

  constructor(
    private readonly experimentService: ExperimentService,
    private readonly eventsNotificationService: DataRecordService,
    private readonly experimentBlobEventsService: ExperimentBlobEventsService,
    private readonly messageService: MessageService,
    private readonly activityOutputCollaboratorService: ActivityOutputCollaboratorService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly chromatographyIntegrationApiService: ChromatographyIntegrationService
  ) {
    this.eventsNotificationService.chromatographyDataImportedEventNotificationReceiver.subscribe({
      next: this.addSummaryForNewlyImportedResultSetAndDownloadItsContents.bind(this)
    });
    this.eventsNotificationService.chromatographyDataRefreshedEventNotificationReceiver.subscribe({
      next: this.downloadAndApplyRefreshedContentsOfResultSet.bind(this)
    });
    [
      {
        operationType: ExperimentEventType.ChromatographyDataImported,
        operationTitle: OutputEmpowerService.importTitle
      },
      {
        operationType: ExperimentEventType.ChromatographyDataRefreshed,
        operationTitle: OutputEmpowerService.refreshTitle
      }
    ].forEach((knownError) => {
      this.eventsNotificationService.experimentNotificationOnlyReceiver[
        knownError.operationType
      ]?.subscribe({
        next: (notification) =>
          this.readNotificationToDisplayErrorMessage(notification, knownError.operationTitle)
      });
    });
  }

  convertToTree() {
    const resultSetTreeData: ElnEmpowerTreeNode[] = [];
    this.quartersResultSets.forEach((quarter: QuarterResultSet) => {
      const children = [];
      const resultSets = quarter.resultSets || [];
      for (const resultSet of resultSets) {
        children.push({
          label: resultSet.resultSetId,
          id: resultSet.resultSetId,
          key: resultSet.resultSetId,
          icon: 'icon-s icon-document-report',
          type: 'resultSet',
          children: []
        });
      }
      resultSetTreeData.push({
        label: quarter.quarterName?.split('\\')[3],
        key: quarter.quarterName?.split('\\')[3],
        fullPath: quarter.quarterName,
        type: 'quarter',
        expandedIcon: 'pi pi-folder-open',
        collapsedIcon: 'pi pi-folder',
        children: children
      });
    });
    return resultSetTreeData;
  }

  digestQuarterResultSetsResponse(quartersResultSets: Array<QuarterResultSet>) {
    this.quartersResultSets = quartersResultSets;
    this.canShowEmpowerLogin = false;
    this.canShowEmpowerStructure = true;
    this.emptyQuarters = this.quartersResultSets.length === 0;
    if (this.quartersResultSets.length === 0) {
      return;
    }
    this.resultSetTreeData = this.convertToTree();
  }

  resetLoginData() {
    this.empowerData.usernameEmpty = false;
    this.empowerData.passwordEmpty = false;
    this.empowerData.databaseEmpty = false;
    this.empowerData.projectEmpty = false;
    this.empowerData.databaseReadOnly = false;
    this.empowerData.projectReadOnly = false;
    this.empowerData.username = '';
    this.empowerData.password = '';
    this.empowerData.project = '';
    this.empowerData.database = null;
    this.empowerResponse = '';
    this.isRefreshEvent = false;
    this.isRefreshAuthenticated.next(false);
  }

  cacheLoginData(): void {
    sessionStorage.setItem('empowerUsername', this.empowerData.username);
    if (this.empowerData.database?.label) {
      sessionStorage.setItem('empowerDatabase', this.empowerData.database?.label);
    }
  }

  getCachedDatabase(): EmpowerDatabase {
    return { label: sessionStorage.getItem('empowerDatabase') || '' };
  }

  getCachedUsername(): string {
    return sessionStorage.getItem('empowerUsername') || '';
  }

  isCachedDataExists(): boolean {
    return !!sessionStorage.getItem('empowerUsername');
  }

  clearCachedData() {
    sessionStorage.removeItem('empowerUsername');
    sessionStorage.removeItem('empowerDatabase');
  }

  public static readonly convertToChromatographyDataCellFromColumn = (
    resultValue: Column
  ): ChromatographyDataCell => {
    return {
      name: resultValue.name,
      type: EmpowerColumnType.Results,
      value: resultValue.value
    };
  };

  public static readonly convertToChromatographyDataPeakRowFromPeakValues = (
    peakValues: Array<Column>
  ): ChromatographyDataPeakRow => {
    return {
      peakValues: peakValues.map((peakValue) => {
        return {
          name: peakValue.name,
          type: EmpowerColumnType.Peaks,
          value: peakValue.value
        };
      })
    };
  };

  formatResultSetData(resultSetData: ResultAndPeak[]): Array<ChromatographyDataResultRow> {
    const formattedResultsetData: Array<ChromatographyDataResultRow> = [];
    resultSetData.forEach((result) => {
      const chromatographyDataResultRow: ChromatographyDataResultRow = {
        resultId:
          result.resultValues.find((resultValue) => resultValue.name === 'ResultId')?.value ?? '',
        resultValues: result.resultValues.map(
          OutputEmpowerService.convertToChromatographyDataCellFromColumn
        ),
        peakRows: result.peakValues.map(
          OutputEmpowerService.convertToChromatographyDataPeakRowFromPeakValues
        )
      };
      formattedResultsetData.push(chromatographyDataResultRow);
    });
    return formattedResultsetData;
  }

  setStateForEmpowerOperations(
    workflowState: ExperimentWorkflowState,
    hasAnalystRights: boolean,
    hasReviewerRights: boolean,
    hasSupervisorRights: boolean
  ) {
    if (
      !this.hasOnlyReviewerRights(hasReviewerRights, hasAnalystRights, hasSupervisorRights) &&
      ((hasAnalystRights &&
        (workflowState === ExperimentWorkflowState.InProgress ||
          workflowState === ExperimentWorkflowState.InCorrection)) ||
        (hasSupervisorRights &&
          (workflowState === ExperimentWorkflowState.InProgress ||
            workflowState === ExperimentWorkflowState.InCorrection ||
            workflowState === ExperimentWorkflowState.InReview)))
    ) {
      this.disableEmpowerOperations = false;
    } else {
      this.disableEmpowerOperations = true;
    }
  }

  // to store chromatographyDataId and resultSetId for currently removed empower resultSets
  public static removedChromatographyData: Map<string, string> = new Map<string, string>();

  hasOnlyReviewerRights(
    hasReviewerRights: boolean,
    hasAnalystRights: boolean,
    hasSupervisorRights: boolean
  ) {
    return hasReviewerRights && !hasAnalystRights && !hasSupervisorRights;
  }

  queueResultSetImportRequest(
    command: QueueChromatographyResultSetImportRequestCommand,
    successcallback: () => void
  ): void {
    this.elnProgressSpinnerService.Show(OutputEmpowerService.CreatingRequest);
    this.chromatographyIntegrationApiService
      .chromatographyIntegrationResultSetImportRequestPost({
        body: command
      })
      .subscribe({
        next: () => {
          this.showAcknowledgementOfRequest($localize`:@@empoweImportRequest:Empower Import`);
          this.elnProgressSpinnerService.Hide();
          successcallback();
        },
        error: () => {
          this.elnProgressSpinnerService.Hide();
        }
      });
  }

  queueResultSetRefreshRequest(
    chromatographyId: string,
    command: QueueChromatographyResultSetRefreshRequestCommand
  ): void {
    this.elnProgressSpinnerService.Show(OutputEmpowerService.CreatingRequest);
    this.chromatographyIntegrationApiService
      .chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost({
        body: command,
        chromatographyId: chromatographyId
      })
      .subscribe({
        next: () => {
          this.showAcknowledgementOfRequest(
            $localize`:@@empoweImportRequest:Empower Result Set Refresh`
          );
          this.canShowEmpowerLogin = false;
          this.resetLoginData();
          this.elnProgressSpinnerService.Hide();
        },
        error: (errorResponse) => {
          if (errorResponse.error.notifications) {
            this.elnProgressSpinnerService.Hide();
          }
        }
      });
  }

  private addSummaryForNewlyImportedResultSetAndDownloadItsContents(
    event: ChromatographyDataImportedEventNotification
  ): void {
    if (
      !event.eventContext.blobReference ||
      !event.eventContext.blobReference.blobName
    ) {
      this.displayError(OutputEmpowerService.blobMissingError);
      return;
    }

    this.experimentBlobEventsService
      .experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json({
        eventId: event.eventId,
        blobName: event.eventContext.blobReference.blobName
      })
      .subscribe({
        next: (eventDetails) => this.processContentsOfNewlyImportedResultSet(event, eventDetails)
      });
  }

  private processContentsOfNewlyImportedResultSet(
    event: ChromatographyDataImportedEventNotification,
    resultSetContentOfEvent: ChromatographyDataImportedEventDetails
  ): void {
    const resultSetContent = this.asNewResultSetContents(resultSetContentOfEvent);
    this.experimentService.currentExperiment?.activityOutputChromatographyData?.push(
      resultSetContent
    );
    this.activityEventsGrid[resultSetContent.activityId]?.newResultSetContentsAreApplied.next(
      resultSetContent
    );
    const summary = this.asNewResultSetSummary(event);
    this.experimentService.currentExperiment?.activityOutputChromatographyResultSetsSummary?.push(
      this.asNewResultSetSummary(event)
    );
    this.activityEventsGrid[event.activityId]?.newResultSetSummaryApplied.next(summary);
    this.empowerRequestResultAppliedMessage(
      OutputEmpowerService.importTitle,
      resultSetContentOfEvent.activityId,
      resultSetContentOfEvent.resultSetId
    );
  }

  private asNewResultSetSummary(
    event: ChromatographyDataImportedEventNotification
  ): ActivityOutputChromatographyResultSetSummary {
    return {
      activityId: event.activityId,
      blobReferences: [],
      chromatographyDataId: event.chromatographyDataId,
      experimentId: event.eventContext.experimentId,
      isDeleted: false,
      resultSetId: event.resultSetId,
      id: event.chromatographyDataId,
      itemType: NodeType.ChromatographyDataSummary,
      databaseName: event.databaseName,
      projectFilePath: event.projectFilePath,
      selected: false,
      _ts: 0
    };
  }

  private asNewResultSetContents(
    resultSetDetails: ChromatographyDataImportedEventDetails
  ): ActivityOutputChromatographyResultSet {
    return {
      activityId: resultSetDetails.activityId,
      databaseName: resultSetDetails.databaseName,
      projectFilePath: resultSetDetails.projectFilePath,
      resultSetData: resultSetDetails.resultSetData,
      resultSetId: resultSetDetails.resultSetId,
      id: resultSetDetails.chromatographyId,
      itemType: NodeType.ChromatographyData,
      chromatographyDataId: resultSetDetails.chromatographyId,
      experimentId: resultSetDetails.experimentId,
      isDeleted: false,
      _ts: 0
    };
  }

  private downloadAndApplyRefreshedContentsOfResultSet(
    event: ChromatographyDataRefreshedEventNotification
  ): void {
    const currentResultSet =
      this.experimentService.currentExperiment?.activityOutputChromatographyData?.find(
        (resultSet) => resultSet.chromatographyDataId === event.chromatographyDataId
      );
    if (!currentResultSet) {
      this.displayError(
        this.getResultSetMissing(OutputEmpowerService.refreshTitle, event.activityId)
      );
      return;
    }

    if (
      (!event.resultsAdded || event.resultsAdded.length === 0) &&
      (!event.resultsRemoved || event.resultsRemoved.length === 0)
    ) {
      this.showRefreshedNotification(currentResultSet.resultSetId);
      return;
    }

    if (
      !event.eventContext.blobReference ||
      !event.eventContext.blobReference.blobName
    ) {
      this.displayError(OutputEmpowerService.blobMissingError);
      return;
    }

    this.experimentBlobEventsService
      .experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json({
        eventId: event.eventId,
        blobName: event.eventContext.blobReference.blobName
      })
      .subscribe({
        next: (refreshedContentsOfResultSet) =>
          this.applyRefreshedContentsOfResultSet(
            event,
            refreshedContentsOfResultSet,
            currentResultSet
          )
      });
  }

  private applyRefreshedContentsOfResultSet(
    event: ChromatographyDataRefreshedEventNotification,
    resultSetRefreshedContents: ChromatographyDataRefreshedDetails,
    currentResultSet: ActivityOutputChromatographyResultSet
  ): void {
    resultSetRefreshedContents.resultsAdded?.forEach((resultValue) => {
      currentResultSet.resultSetData?.push(resultValue);
    });
    event.resultsRemoved?.forEach((resultIdRemovedOne) => {
      currentResultSet.resultSetData = currentResultSet.resultSetData?.filter(
        (result) => result.resultId !== resultIdRemovedOne
      );
    });
    this.resultSetEventsGrid[currentResultSet.chromatographyDataId]?.refreshed.next(
      currentResultSet
    );
    this.refreshCompleted(currentResultSet);
  }

  private refreshCompleted(currentResultSet: ActivityOutputChromatographyResultSet): void {
    this.activityOutputCollaboratorService.sendInputStatus(
      LockType.unlock,
      currentResultSet.experimentId,
      [this.activityOutputCollaboratorService.refreshIdentifier],
      currentResultSet.chromatographyDataId
    );
    this.canShowEmpowerLogin = false;
    this.resetLoginData();
    this.empowerRequestResultAppliedMessage(
      OutputEmpowerService.refreshTitle,
      currentResultSet.activityId,
      currentResultSet.resultSetId
    );
  }

  private showRefreshedNotification(resultSetId: string) {
    const messageObj: Message = {
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@empowerRefreshComplete: Empower Refresh Complete`,
      detail: $localize`:@@empowerRefreshCompleteMessageDetails:ResultSet ID "${resultSetId}" was refreshed.`,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  removeResultSet(chromatographyDataId: string): void {
    if (!this.experimentService.currentExperiment) {
      throw new Error(this.noCurrentExperimentFound);
    }
    if (this.experimentService.currentExperiment.activityOutputChromatographyData) {
      const index =
        this.experimentService.currentExperiment.activityOutputChromatographyData.findIndex(
          (resultSet) => resultSet.chromatographyDataId === chromatographyDataId
        );
      this.experimentService.currentExperiment.activityOutputChromatographyData.splice(index, 1);
    }
    if (this.experimentService.currentExperiment.activityOutputChromatographyResultSetsSummary) {
      const index =
        this.experimentService.currentExperiment.activityOutputChromatographyResultSetsSummary.findIndex(
          (resultSet) => resultSet.chromatographyDataId === chromatographyDataId
        );
      this.experimentService.currentExperiment.activityOutputChromatographyResultSetsSummary.splice(
        index,
        1
      );
    }
  }

  public GetEmpowerFullEventGirdFor(
    activityId: string,
    chromatographyDataId: string
  ): EmpowerEventsGrid {
    if (!this.activityEventsGrid[activityId]) {
      this.activityEventsGrid[activityId] = {
        newResultSetSummaryApplied: new Subject<ActivityOutputChromatographyResultSetSummary>(),
        newResultSetContentsAreApplied: new Subject<ActivityOutputChromatographyResultSet>()
      };
    }

    if (chromatographyDataId && !this.resultSetEventsGrid[chromatographyDataId]) {
      this.resultSetEventsGrid[chromatographyDataId] = {
        refreshed: new Subject<ActivityOutputChromatographyResultSet>()
      };
    }
    return {
      activityEventsGrid: this.activityEventsGrid[activityId],
      currentResultSetEvents: this.resultSetEventsGrid[chromatographyDataId]
    };
  }

  public GetEmpowerActivityEventGirdFor(activityId: string): ActivityEmpowerEventsGrid {
    if (!this.activityEventsGrid[activityId]) {
      this.activityEventsGrid[activityId] = {
        newResultSetSummaryApplied: new Subject<ActivityOutputChromatographyResultSetSummary>(),
        newResultSetContentsAreApplied: new Subject<ActivityOutputChromatographyResultSet>()
      };
    }
    return this.activityEventsGrid[activityId];
  }

  static readonly FetchingResultSetSpinnerOptions = {
    message: $localize`:@@fetchingResultSet:Fetching Result Set`,
    i18nMessage: `@@fetchingResultSet`
  };

  static readonly CreatingRequest = {
    message: $localize`:@@creatingRequest:Creating request`,
    i18nMessage: `@@creatingRequest`
  };

  public GetResultSetData(
    chromatographyDataId: string,
    experimentId: string
  ): Observable<ActivityOutputChromatographyResultSet> {
    const resultSet =
      this.experimentService.currentExperiment?.activityOutputChromatographyData?.find(
        (resultSet) => resultSet.chromatographyDataId === chromatographyDataId
      );
    if (resultSet) {
      return of(resultSet);
    }
    this.elnProgressSpinnerService.Show(OutputEmpowerService.FetchingResultSetSpinnerOptions);
    return this.experimentService.experimentNodesService
      .experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json({
        experimentId: experimentId,
        resultSetInstanceId: chromatographyDataId
      })
      .pipe(
        tap((resultSet) => {
          this.experimentService.currentExperiment?.activityOutputChromatographyData?.push(
            resultSet
          );
          this.elnProgressSpinnerService.Hide();
        })
      );
  }

  private showAcknowledgementOfRequest(requestTitle: string): void {
    const messageObj: Message = {
      key: 'notification',
      severity: 'info',
      summary: requestTitle,
      detail: $localize`:@@empowerRequestAcknowledgement:The Request has been created. You will be notified once it has been processed.`,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  private empowerRequestResultAppliedMessage(
    requestType: string,
    activityId: string,
    resultSetId: string
  ): void {
    const messageObj: Message = {
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@empower:Empower`,
      detail: $localize`:@@empowerRequestProcessed:${requestType} complete for Empower ResultSet ${resultSetId} - ${this.getActivityTitleByActivityId(
        activityId
      )}`,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  private getResultSetMissing(
    requestType: string,
    activityId: string,
    resultSetId?: string
  ): string {
    return $localize`:@@resultSetBNotFound:${requestType} not complete for Empower ResultSet ${
      resultSetId || ''
    } - ${this.getActivityTitleByActivityId(activityId)}, because the result set is missing.`;
  }

  private getActivityTitleByActivityId(activityId: string): string {
    return (
      this.experimentService.currentExperiment?.activities.find(
        (activity) => activity.activityId === activityId
      )?.itemTitle ?? ''
    );
  }

  private displayError(detail: string): void {
    const errorMessage: Message = {
      key: 'notification',
      severity: 'error',
      summary: $localize`:@@empowerError:Empower Error`,
      detail: detail,
      sticky: false
    };
    this.messageService.add(errorMessage);
  }

  private readNotificationToDisplayErrorMessage(
    notification: ExperimentNotificationOnlyResponse,
    operationTitle: string
  ): void {
    let isKnownError = false;
    notification.notifications.notifications.forEach((notificationDetails, index) => {
      if (this.serverErrorMessageHandlers[notificationDetails.translationKey]) {
        isKnownError = true;
        this.serverErrorMessageHandlers[notificationDetails.translationKey](
          notification,
          operationTitle
        );
      }
    });
    if (!isKnownError) {
      this.eventsNotificationService.displayUnHandledErrorNotification(notification.notifications, notification.operationType);
    }
  }

  private displayErrorMessageForOperation(
    notification: ExperimentNotificationOnlyResponse,
    operationTitle: string
  ): void {
    const requestContext = this.GetRequestContextFromNotification(notification);
    const errorMessage = $localize`:@@empowerRequestFailedGenericErrorMessage:${operationTitle} Failed for Empower ResultSet ${requestContext.resultSetId} - ${requestContext.activityTitle}`;
    this.displayError(errorMessage);
  }

  private GetRequestContextFromNotification(notification: ExperimentNotificationOnlyResponse): {
    activityTitle: string;
    resultSetId: string;
  } {
    const activityId = notification.operationLevelProperties.activityId as string;
    const resultSetId = notification.operationLevelProperties.resultSetId as string;
    return { resultSetId, activityTitle: this.getActivityTitleByActivityId(activityId) ?? '' };
  }

}
