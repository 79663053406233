import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UserService } from '../../services/user.service';
import { PreparationService } from '../services/preparation.service';
import { ModifiableDataValue, NumberValue, User, UserPicklistResponse } from '../../api/models';
import { PreparationItem } from '../models/preparation-presentation.model';
import { Unit } from 'bpt-ui-library/shared/unit';
import { Quantity, ValueState } from 'bpt-ui-library/shared';
import { Observable, Subject, Subscription } from 'rxjs';
import { ChangePreparationInternalInformationCommand, DiscardOrConsumePreparationCommand } from '../../api/data-entry/models';
import { Instant } from '@js-joda/core';
import { PreparationEventService } from '../services/preparation-event.service';
import { PreparationConstants } from '../preparation-constants';
import { DateAndInstantFormat, formatInstant } from '../../shared/date-time-helpers';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { nth } from 'lodash-es';

@Component({
  selector: 'app-preparation-internal-information',
  templateUrl: './preparation-internal-information.component.html',
  styleUrls: ['./preparation-internal-information.component.scss']
})
export class PreparationInternalInformationComponent implements OnChanges, OnDestroy {

  @Input() showSlider = false;
  @Input() isReadOnlySlider = false;
  @Input() preparationId = '';
  @Input() preparationData = {} as PreparationItem;

  @Output() closeSlider = new EventEmitter();
  sliderCloseConfirmation = new Subject<boolean>();
  private readonly subscriptions: Subscription[] = [];
  readonly fieldRequiredMessage = PreparationConstants.fieldRequired;
  isSbuValueSet = true;
  isParentRecipe: boolean;
  value!: ModifiableDataValue;
  changeInternalInformationCommand!: ChangePreparationInternalInformationCommand;
  discardOrConsumePreparationCommand!: DiscardOrConsumePreparationCommand;
  currentUserName = '';
  unsavedChangesDictionary: { [key: string]: boolean } = {};

  preparationDisplayModel: Partial<{
    disposal: string;
    storageLocation: string,
    stability: Quantity,
    originalQuantity: Quantity,
    hazards: string,
    additionalLabel: string,
    analysis: string,
    method: string,
    compendia: string,
    client: string,
    project: string,
    preparedBy: string,
    reviewedBy: string,
    storedBy: string,
    subBusinessUnit: string,
    discardedOrConsumed: boolean,
    discardedOrConsumedBy: string,
    discardedOrConsumedOn: string
  }> = {};

  sliderOptions = {
    size: 'medium',
    // Options : 'large','medium','small'
    visible: false,
    closeOnEscape: false,
    headerText: $localize`:@@preparationInternalInformation:Preparation Internal Information`,
    isFooterSticky: true,
    displayFooter: true,
    displayFooterWithPrimaryButton: true,
    displayFooterWithSecondaryButton: true,
    isModal: true,
    primaryButtonLabel: $localize`:@@commit:Commit`,
    secondaryButtonLabel: $localize`:@@cancel:Cancel`
  };

  additionalInformationHeaderText = $localize`:@@preparationAdditionalInformation:Additional Information`;

  dropdownOptions = {
    editable: true,
    showClear: false,
    showFilter: false,
    multiSelect: false
  }

  quantityOptions = {
    commitValueOnEnter: true,
    enableSignificantFigures: true,
    cancelValueOnEscape: true,
    allowNA: true,
    allowDecimal: true,
    highlightAllOnFocus: false,
    suppressContextMenu: true,
  }

  clientDropdownOptions = {
    showClear: true,
  }

  dropdownPicklists = {
    disposal: [{ label: '', value: '' }],
    compendia: [{ label: '', value: '' }],
    client: [{ label: '', value: '' }],
    project: [{ label: '', value: '' }],
  }

  stabilityUnits: Unit[] = []
  originalQuantityUnits: Unit[] = []

  checkboxOptions = {
    discardedLabel: $localize`:@@preparationDiscardedCheckbox:Discarded/Consumed`,
    discardedByLabel: $localize`:@@discardedOrConsumedBy:Discarded/Consumed by`
  };
  onTextValue = $localize`:@@on:on`;
  currentUser = '';
  disableProjects = true;
  currentUserLabsites: string[] | undefined;
  preparationsDropdownPicklists = {
    storageCondition: {} as Observable<UserPicklistResponse>,
    disposal: {} as Observable<UserPicklistResponse>,
    compendia: {} as Observable<UserPicklistResponse>,
    color: {} as Observable<UserPicklistResponse>,
    material: {} as Observable<UserPicklistResponse>,
    type: {} as Observable<UserPicklistResponse>,
    exposure: {} as Observable<UserPicklistResponse>
  };

  constructor(
    private readonly preparationService: PreparationService,
    private readonly preparationEventService: PreparationEventService,
  ) {
    this.isParentRecipe = preparationService.ParentItemType === 'Recipe';
    this.preparationsDropdownPicklists = this.preparationService.getPickListsForPreparation();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.sliderOptions.visible = this.showSlider;
    if (changes['showSlider'] && changes['showSlider'].currentValue === true) {
      const currentUser = this.preparationService.getCurrentUser();
      this.currentUser = currentUser.puid;
      this.currentUserName = UserService.getFullNameAndPuid(currentUser);
      this.currentUserLabsites = currentUser.labSiteCode ? [currentUser.labSiteCode] : [];
      this.getPreparationUnits();
      this.getDropdownPicklists();
      this.initializeCommandObjects();
      if (this.preparationData.additionalInformation?.client !== undefined && this.preparationData.additionalInformation.client.length > 0) {
        this.disableProjects = false;
        this.dropdownPicklists.project = this.preparationService.fetchProjectsLinkedToClients([this.preparationData.additionalInformation.client]);
      }
      this.mapDisplayModel();
    }
  }

  getDropdownPicklists() {
    this.dropdownPicklists.client = this.preparationService.getClients();
    this.subscriptions.push(
      this.preparationsDropdownPicklists.compendia.subscribe({
        next: (dropdown: any) => {
          this.dropdownPicklists.compendia = dropdown.items;
        }
      })
    )
    this.subscriptions.push(
      this.preparationsDropdownPicklists.disposal.subscribe({
        next: (dropdown: any) => {
          this.dropdownPicklists.disposal = dropdown.items;
        }
      })
    )
  }

  getPreparationUnits() {
    if (this.preparationService.preparationUnits.length === 0) {
      this.preparationService.getQuantityUnits();
    }
    this.stabilityUnits = nth(this.preparationService.preparationUnits, 1)!;
    this.originalQuantityUnits = nth(this.preparationService.preparationUnits, 2)!;
  }

  sliderVisibleChange(sliderOpen: boolean) {
    if (!sliderOpen) {
      this.onSliderClose();
    }
  }

  /*
* 
* Method will trigger when you click close icon of slider user to implement any logic with closing slider
*/
  sliderClosing = () => {
    this.onSliderClose();
    return this.sliderCloseConfirmation.asObservable();
  }

  initializeCommandObjects() {
    this.changeInternalInformationCommand = {
      analysis: undefined,
      client: undefined,
      compendia: undefined,
      disposal: undefined,
      hazards: undefined,
      method: undefined,
      moduleContext: undefined,
      originalQuantity: undefined,
      preparationId: this.preparationData.preparationId,
      preparedBy: this.isParentRecipe ? '' : this.preparationData.additionalInformation ? this.preparationData.additionalInformation.preparedBy : '',
      project: undefined,
      stability: undefined,
      storageLocation: undefined,
      storedBy: undefined,
      subBusinessUnit: undefined,
      type: undefined,
    };
    this.discardOrConsumePreparationCommand = {
      discardedOrConsumed: false,
      preparationId: this.preparationData.preparationId,
      moduleContext: undefined
    }
  }
  /**
   * Sets the data that needs to be displayed in the slider
   */
  mapDisplayModel() {
    this.mapInternalInformation();
    this.mapAdditionalInformation();
  }

  mapInternalInformation() {
    this.preparationDisplayModel.disposal = this.preparationData.internalInformation?.disposal ? this.preparationData.internalInformation?.disposal : '';
    this.preparationDisplayModel.storageLocation = this.preparationData.internalInformation?.storageLocation ? this.preparationData.internalInformation?.storageLocation : '';
    this.preparationDisplayModel.stability = this.preparationData.internalInformation?.stability ?
      this.getQuantity(this.preparationData.internalInformation?.stability, 'stability') : new Quantity(ValueState.Empty);
    this.preparationDisplayModel.originalQuantity = this.preparationData.internalInformation?.originalQuantity ?
      this.getQuantity(this.preparationData.internalInformation?.originalQuantity, 'originalQuantity') : new Quantity(ValueState.Empty);
    this.preparationDisplayModel.hazards = this.preparationData.internalInformation?.hazards ? this.preparationData.internalInformation?.hazards : '';
    this.preparationDisplayModel.additionalLabel = this.preparationData.internalInformation?.additionalLabel ? this.preparationData.internalInformation?.additionalLabel : '';
  }

  mapAdditionalInformation() {
    this.preparationDisplayModel.analysis = this.preparationData.additionalInformation?.analysis ? this.preparationData.additionalInformation.analysis : '';
    this.preparationDisplayModel.method = this.preparationData.additionalInformation?.method ? this.preparationData.additionalInformation.method : '';
    this.preparationDisplayModel.compendia = this.preparationData.additionalInformation?.compendia ? this.preparationData.additionalInformation.compendia : '';
    this.preparationDisplayModel.client = this.preparationData.additionalInformation?.client ? this.preparationData.additionalInformation.client : '';
    this.preparationDisplayModel.project = this.preparationData.additionalInformation?.project ? this.preparationData.additionalInformation.project : '';
    this.preparationDisplayModel.subBusinessUnit = this.preparationData.additionalInformation?.subBusinessUnit ? this.preparationData.additionalInformation.subBusinessUnit : '';
    this.preparationDisplayModel.discardedOrConsumed = this.preparationData.additionalInformation?.discardedOrConsumed ? this.preparationData.additionalInformation.discardedOrConsumed : false;
    this.preparationDisplayModel.discardedOrConsumedOn = this.preparationData.additionalInformation?.discardedOrConsumedOn ?
      formatInstant(this.preparationData.additionalInformation.discardedOrConsumedOn, DateAndInstantFormat.date) : '';
  }

  getQuantity(quantityValue: NumberValue, property: string): Quantity {
    let quantityUnit;
    switch (property) {
      case "stability":
        quantityUnit = this.stabilityUnits?.find(unit => unit.id === quantityValue.unit);
        break;
      case "originalQuantity":
        quantityUnit = this.originalQuantityUnits?.find(unit => unit.id === quantityValue.unit);
        break;
    }
    return new Quantity(quantityValue.state, quantityValue.value, quantityUnit, quantityValue.sigFigs, quantityValue.exact);
  }

  /**
 * It converts the edited quantity to a type suitable to add in preparation object
 */
  quantityChanged(newValue: Quantity, property: string) {
    this.updateQuantityInPreparation(newValue, property);
  }

  /**
 * It updates the quantity into respective preparation object based on the property passed
 */
  updateQuantityInPreparation(newQuantity: Quantity, property: string) {
    const dataValue = this.preparationService.getQuantityPrimitiveValue(newQuantity);
    this.unsavedChangesDictionary[property] = (newQuantity.state !== 'empty' && newQuantity.unitDetails !== undefined);
    switch (property) {
      case 'originalQuantity':
        this.changeInternalInformationCommand.originalQuantity = dataValue;
        break;
      case 'stability':
        this.changeInternalInformationCommand.stability = dataValue;
        break;
    }
  }

  /**
   * The user select component emits the list of fetched Users to the parent component. Only when that list is received
   * the parent component is able to update the user names with the their full names and puid. This method fetches the full 
   * names of the users which need to be displayed in the fields tht deal with user names.
   */
  updateUserNameFields(users: Array<User>) {
    if (this.preparationData.additionalInformation?.discardedOrConsumedBy) {
      this.preparationDisplayModel.discardedOrConsumedBy =
        users.find(
          (u) => u.puid.toLowerCase() === this.preparationData.additionalInformation?.discardedOrConsumedBy?.toLowerCase())?.fullName
        ?? this.preparationDisplayModel.discardedOrConsumedBy;
    }
    if (this.preparationData.additionalInformation?.reviewedBy) {
      this.preparationDisplayModel.reviewedBy = this.preparationService.getUsersWithReviewerRole().find(
        (u) => u.puid.toLowerCase() === this.preparationData.additionalInformation?.reviewedBy?.toLowerCase())?.fullName
        ?? this.preparationDisplayModel.reviewedBy;
    }
    if (this.preparationData.additionalInformation)
      this.preparationDisplayModel.preparedBy = this.isParentRecipe ? '' : this.preparationData.additionalInformation?.preparedBy;
    this.preparationDisplayModel.storedBy = this.isParentRecipe || !this.preparationData.additionalInformation?.storedBy ? '' :this.preparationData.additionalInformation?.storedBy;
  }

  /**
   * Updates the values for the various form fields in the slider
   */
  modelChanged(newValue: any, property: string) {
    switch (property) {
      case "disposal":
        this.changeInternalInformationCommand.disposal = newValue;
        this.preparationDisplayModel.disposal = newValue;
        this.unsavedChangesDictionary['disposal'] = this.preparationData.internalInformation?.disposal !== newValue;
        break;
      case "storageLocation":
        this.unsavedChangesDictionary['storageLocation'] = this.preparationData.internalInformation?.storageLocation !== newValue;
        this.changeInternalInformationCommand.storageLocation = newValue;
        this.changeInternalInformationCommand.storedBy = this.currentUser;
        this.preparationDisplayModel.storageLocation = newValue;
        this.preparationDisplayModel.storedBy = this.unsavedChangesDictionary['storageLocation'] ? this.currentUser : '';
        break;
      case "analysis":
        this.preparationDisplayModel.analysis = newValue;
        this.changeInternalInformationCommand.analysis = newValue;
        this.unsavedChangesDictionary['analysis'] = this.preparationData.additionalInformation?.analysis !== newValue;
        break;
      case "compendia":
        this.changeInternalInformationCommand.compendia = newValue;
        this.preparationDisplayModel.compendia = newValue;
        this.unsavedChangesDictionary['compendia'] = this.preparationData.additionalInformation?.compendia !== newValue;
        break
      case "hazards":
        this.changeInternalInformationCommand.hazards = newValue;
        this.preparationDisplayModel.hazards = newValue;
        this.unsavedChangesDictionary['hazards'] = this.preparationData.internalInformation?.hazards !== newValue;
        break
      case "additionalLabel":
        this.changeInternalInformationCommand.additionalLabel = newValue;
        this.preparationDisplayModel.additionalLabel = newValue;
        this.unsavedChangesDictionary['additionalLabel'] = this.preparationData.internalInformation?.additionalLabel !== newValue;
        break
      case "method":
        this.changeInternalInformationCommand.method = newValue;
        this.preparationDisplayModel.method = newValue;
        this.unsavedChangesDictionary['method'] = this.preparationData.additionalInformation?.method !== newValue;
        break
      case "preparedBy":
        this.changeInternalInformationCommand.preparedBy = newValue;
        this.preparationDisplayModel.preparedBy = newValue;
        this.unsavedChangesDictionary['preparedBy'] = this.preparationData.additionalInformation?.preparedBy !== newValue;
        break
      case "storedBy":
        this.changeInternalInformationCommand.storedBy = newValue;
        this.preparationDisplayModel.storedBy = newValue;
        this.unsavedChangesDictionary['storedBy'] = this.preparationData.additionalInformation?.storedBy !== newValue;
        break
      case "subBusinessUnit":
        if (!newValue) {
          this.unsavedChangesDictionary['subBusinessUnit'] = false;
        }
        else {
          this.changeInternalInformationCommand.subBusinessUnit = newValue;
          this.preparationDisplayModel.subBusinessUnit = newValue;
          this.unsavedChangesDictionary['subBusinessUnit'] = this.preparationData.additionalInformation?.subBusinessUnit !== newValue;
        }
        break
      case "client":
        this.changeInternalInformationCommand.client = newValue.value;
        this.preparationDisplayModel.client = newValue.value;
        this.disableProjects = false;
        this.dropdownPicklists.project = this.preparationService.fetchProjectsLinkedToClients([newValue.value]);
        this.unsavedChangesDictionary['client'] = this.preparationData.additionalInformation?.client !== newValue.value;
        break;
      case "project":
        this.changeInternalInformationCommand.project = newValue;
        this.preparationDisplayModel.project = newValue;
        this.unsavedChangesDictionary['project'] = this.preparationData.additionalInformation?.project !== newValue;
        break;
    }
  }

  preparationDiscardedClicked(event: any) {
    this.preparationDisplayModel.discardedOrConsumed = event.checked;
    this.discardOrConsumePreparationCommand.discardedOrConsumed = event.checked;
    this.unsavedChangesDictionary['discardedOrConsumed'] = this.preparationData.additionalInformation?.discardedOrConsumed !== event.checked;
    if (event.checked) {
      this.preparationDisplayModel.discardedOrConsumedBy = this.currentUserName;
      this.preparationDisplayModel.discardedOrConsumedOn = formatInstant(Instant.now(), DateAndInstantFormat.date);
    }
    else {
      this.preparationDisplayModel.discardedOrConsumedBy = "";
      this.preparationDisplayModel.discardedOrConsumedOn = "";
    }
  }

  clientSelectionCleared() {
    this.changeInternalInformationCommand.client = "";
    this.changeInternalInformationCommand.project = "";
    this.preparationDisplayModel.client = "";
    this.preparationDisplayModel.project = "";
    this.disableProjects = true;
    this.dropdownPicklists.project = [{ label: '', value: '' }];
    this.unsavedChangesDictionary['client'] = true;
    this.unsavedChangesDictionary['project'] = this.preparationData.additionalInformation?.project !== "";
  }

  async checkForUnSavedChanges() {
    try {
      await this.preparationService.confirmUnsavedChangesInternalInfo();
      this.closeInternalInfoSlider();
    } catch (error) {
    }
  }

  closeInternalInfoSlider() {
    this.sliderOptions.visible = false;
    this.closeSlider.emit(this.sliderOptions.visible);
  }

  onSliderClose() {
    if (Object.values(this.unsavedChangesDictionary).some(field => field === true)) {
      this.checkForUnSavedChanges();
    }
    else {
      this.closeInternalInfoSlider();
    }
  }

  commitValues() {
    if (!this.checkValidationBeforeCommitting()|| this.isReadOnlySlider) {
      return;
    }
    if (Object.values(this.unsavedChangesDictionary).some(field => field === true)) {
      if (this.unsavedChangesDictionary['discardedOrConsumed'] === true) {
        this.preparationEventService.preparationDiscardedOrConsumedEvent(this.discardOrConsumePreparationCommand);
      }
      if (Object.keys(this.unsavedChangesDictionary).some(field => field?.trim() !== "discardedOrConsumed" && this.unsavedChangesDictionary[field] === true)) {
        this.preparationEventService.preparationInternalInformationChangedEvent(this.changeInternalInformationCommand);
      }
      this.closeInternalInfoSlider();
    }
    else {
      this.closeInternalInfoSlider();
    }
  }

  checkValidationBeforeCommitting(): boolean {
    this.isSbuValueSet = this.preparationDisplayModel.subBusinessUnit !== undefined && this.preparationDisplayModel.subBusinessUnit.length !== 0;
    if (!this.isSbuValueSet) {
      this.preparationService.buildToastMessage('notification', 'error', 'checkValidations', PreparationConstants.RequiredFieldsValidation, false);
    }
    return this.isSbuValueSet;
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }
}