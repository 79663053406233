<div class="eln-activity-lab-items">
  <div class="eln-activity-lab-items-header">
    <span id="eln-LabItemsModuleHeader" i18n="@@labItemsModuleHeader" class="eln-lab-items-header"
      >Lab Items</span
    >
    <span
      id="eln-LabItemsIconHelperTextShow"
      i18n-pTooltip="@@activityLabItemsHelperText"
      class="pi pi-info-circle eln-lab-items-info"
      pTooltip="Add Materials, Reagents, Instruments, Columns, Consumables and Preparations"
      tooltipPosition="top"
    ></span>
  </div>
  <div
    *ngIf="(!labItemsMaterials || !labItemsMaterials.labItemsMaterialDataSource || 
    (labItemsMaterials.labItemsMaterialDataSource.length === 0 && !labItemsMaterials.containsRemovedRows && !labItemsColumns.containsRemovedRows && !labItemsInstruments.containsRemovedRows && !labItemsConsumables.containsRemovedRows)) 
    && (!labItemsInstruments || !labItemsInstruments.labItemsInstrumentDataSource || 
    labItemsInstruments.labItemsInstrumentDataSource.length === 0)  
    && (!labItemsColumns || !labItemsColumns.labItemsColumnDataSource || 
    labItemsColumns.labItemsColumnDataSource.length === 0) &&
    (!labItemsConsumables || !labItemsConsumables.labItemsConsumableDataSource || 
    labItemsConsumables.labItemsConsumableDataSource.length === 0)
    && (!labItemsPreparation || !labItemsPreparation.labItemPreparations || 
    labItemsPreparation.labItemPreparations.length === 0)"
    class="eln-activity-labitems-no-data flex align-items-center justify-content-center"
  >
    <div class="eln-activity-labitems-no-data-img flex flex-column align-items-center">
      <img
        src="../../../assets/eln-assets/no-data.svg"
        alt=""
        id="eln-lab-items-no-data-img"
        class="lab-items-no-data-img"
      /><span>{{ labItemsDoNotExistBanner }}</span>
    </div>
  </div>
  <app-lab-items-material #labItemsMaterials></app-lab-items-material>
  <app-lab-items-instrument #labItemsInstruments></app-lab-items-instrument>
  <app-lab-items-column #labItemsColumns></app-lab-items-column>
  <app-lab-items-consumable #labItemsConsumables></app-lab-items-consumable>
  <app-lab-items-preparation #labItemsPreparation> </app-lab-items-preparation>
</div>
