import { Renderer2 } from '@angular/core';
import { GridApi, IRowNode } from 'ag-grid-community';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable, Subject, finalize, tap } from 'rxjs';
import {
  Cell,
  ChangeCellCommand as ChangeExperimentCellsCommand,
  RemoveRowCommand as RemoveExperimentRowCommand,
  RenumberRowsCommand as RenumberExperimentRowsCommand,
  RestoreRowCommand as RestoreExperimentRowCommand,
  Row,
  RowsRenumberedResponse as RenumberExperimentRowsResponse,
  ValueState
} from '../../../api/data-entry/models';
import { ColumnSpecification, Table, TableValueRow } from '../../../model/experiment.interface';
import { BptGridComponent, BptGridRowsAddedEvent, ColumnDefinition, ColumnType, GridContextMenuItem } from 'bpt-ui-library/bpt-grid';
import { ColumnType as ApiColumnType, InstrumentReadingValue, ModifiableDataValue, NotificationResult, NumberValue, Unit, ValueType } from '../../../api/models';
import { ELNAppConstants } from '../../../shared/eln-app-constants';
import { ClipboardService } from 'bpt-ui-library/services';
import { UnitLoaderService } from '../../../services/unit-loader.service';
import { ChangeRecipeCellCommand, RemoveRecipeRowCommand, RenumberRecipeRowsCommand, RenumberRecipeRowsResponse, RestoreRecipeRowCommand } from '../../../api/cookbook/models';
import { mapValues, values } from 'lodash-es';
import { DataValueService } from '../../services/data-value.service';
import { NA, Quantity } from 'bpt-ui-library/shared';
import { RemovedRowsComponent, RemovedRowsDialogConfigData } from './removed-rows/removed-rows.component';
import { DialogService } from 'primeng/dynamicdialog';
import { AddRowEventParams } from 'bpt-ui-library/bpt-grid/model/add-row-event-params.interface';

/** Observable of just notifications, for where no data is needed. */
export type ResponseObservable = Observable<{ notifications: NotificationResult }>;

export type ChangeCellsCommand<T extends 'experiment' | 'recipe'> =
  T extends 'experiment' ? ChangeExperimentCellsCommand :
  T extends 'recipe' ? ChangeRecipeCellCommand : never
export type ChangeCellsPost<T extends 'experiment' | 'recipe'> = (command: ChangeCellsCommand<T>) => ResponseObservable;
export type RemoveRowCommand<T extends 'experiment' | 'recipe'> =
  T extends 'experiment' ? RemoveExperimentRowCommand :
  T extends 'recipe' ? RemoveRecipeRowCommand : never
export type RemoveRowPost<T extends 'experiment' | 'recipe'> = (command: RemoveRowCommand<T>) => ResponseObservable;
export type RestoreRowCommand<T extends 'experiment' | 'recipe'> =
  T extends 'experiment' ? RestoreExperimentRowCommand :
  T extends 'recipe' ? RestoreRecipeRowCommand : never
export type RestoreRowPost<T extends 'experiment' | 'recipe'> = (command: RestoreRowCommand<T>) => ResponseObservable;
export type RenumberRowsCommand<T extends 'experiment' | 'recipe'> =
  T extends 'experiment' ? RenumberExperimentRowsCommand :
  T extends 'recipe' ? RenumberRecipeRowsCommand : never
export type RenumberRowsPost<T extends 'experiment' | 'recipe'> = (command: RemoveRowCommand<T>) => Observable<RenumberRecipeRowsResponse | RenumberExperimentRowsResponse>;

export type TableContext = {
  table: Table,
  grid: {
    gridApi: GridApi
  },
  isLoading: boolean,
  fillWithNaMenuItem: () => GridContextMenuItem,
  getViewSpecMenuItem: () => GridContextMenuItem | undefined,
}

/**
 * Multipurpose shared code for RecipeTableComponent and experiment TableComponent: 
 *   * Adapter between them and their different APIs
 */
export class TableDataService<T extends 'experiment' | 'recipe'> {
  styleClassProperties: { [key: string]: string } = {
    rejectButtonStyleClass: 'eln-standard-popup-button p-button-outlined',
    acceptButtonStyleClass: 'eln-standard-popup-button',
    icon: 'pi pi-exclamation-triangle'
  };

  public readonly actualValueAdded = new Subject<InstrumentReadingValue>();
  /** Notifies of row removed by rowId */
  public readonly rowRemoved = new Subject<string>();
  /** Notifies of row restored by rowId */
  public readonly rowRestored = new Subject<string>();
  public readonly sequentialReadingEnabled = new Subject<boolean>();
  public readonly terminateSequentialReadingSession = new Subject<boolean>();
  static readonly isRemovedColumn = '~isRemoved~';

  constructor(
    private readonly clipboardService: ClipboardService,
    private readonly confirmationService: ConfirmationService,
    private readonly dataValueService: DataValueService,
    private readonly messageService: MessageService,
    private readonly unitLoaderService: UnitLoaderService,
    private readonly dialogService: DialogService,
    private readonly changeCellsPost: (command: ChangeCellsCommand<T>) => ResponseObservable,
    private readonly removeRowPost: (command: RemoveRowCommand<T>) => ResponseObservable,
    private readonly restoreRowPost: (command: RestoreRowCommand<T>) => ResponseObservable,
    private readonly renumberRowsPost: (command: RenumberRowsCommand<T>) => Observable<RenumberRecipeRowsResponse | RenumberExperimentRowsResponse>,
  ) { }

  public static getRowIdField(columnDefinitions: { columnType: ColumnDefinition['columnType'] | ApiColumnType, field?: string }[]): string | undefined {
    return columnDefinitions.find(cd => cd.columnType === ColumnType.rowId)?.field;
  }

  confirmThenRemoveRow(tableContext: TableContext, rowId: string, activityId: string | undefined = undefined) {
    this.confirmationService.confirm({
      message:
        $localize`:@@LabItemsRemoveConsumableConfirmationMessage:Are you sure you want to remove this row? The row may restored through use of the View Removed Rows option.`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => this.sendRequestToRemoveRow(tableContext, rowId, activityId),
    });
  }

  public confirmThenRestoreRow(table: TableContext, rowId: string, activityId: string | undefined = undefined) {
    this.confirmationService.confirm({
      message: $localize`:@@restoreRowConfirmation:Are you sure you wish to restore this row?`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendRequestToRestoreRow(table, rowId, activityId);
      },
    });
  }

  /** In addition to Removing the Row, it also does needed subsequent actions such as potential Step # Renumbering */
  private sendRequestToRemoveRow(tableContext: TableContext, rowId: string, activityId: string | undefined) {
    const documentIdProperty = 'experimentId' in tableContext ? 'experimentId' : 'recipeId';
    const documentId = (tableContext as any)[documentIdProperty];
    const command = {
      [documentIdProperty]: documentId,
      rowId,
      tableId: tableContext.table.tableId,
      activityId: activityId
    } as RemoveRowCommand<T>;
    const stepCol = tableContext.table.columnDefinitions.find(c => c.columnType === ColumnType.stepNumber)?.field;
    this.removeRowPost(command)
      .pipe(finalize(() => tableContext.isLoading = false))
      .subscribe({
        next: () => {
          if (stepCol) {
            this.sendRequestToRenumberRows(tableContext, rowId, stepCol, true, activityId);
          } else {
            this.processRemovedRow(tableContext.table, rowId);
          }
        },
        error: () => {
          //error is handled by ExceptionInterceptor
        },
        complete: () => {
          tableContext.isLoading = false;
        }
      });
  }

  sendRequestToRenumberRows(tableContext: TableContext, rowId: string, stepCol: string,
    rowRemoval = false, activityId: string | undefined = undefined) {
    const documentIdProperty = 'experimentId' in tableContext ? 'experimentId' : 'recipeId';
    const documentId = (tableContext as any)[documentIdProperty];
    const command = {
      [documentIdProperty]: documentId,
      tableId: tableContext.table.tableId,
      rowId,
      oldStepNumbers: this.getOldStepNumbers(tableContext, rowId, rowRemoval),
      stepNumberField: stepCol,
      activityId: activityId
    } as unknown as RenumberRowsCommand<T>;
    this.renumberRowsPost(command)
      .pipe(finalize(() => (tableContext.isLoading = false)))
      .subscribe({
        next: rowsRenumberedResponse => {
          if (rowRemoval) {
            this.processRemovedRow(tableContext.table, rowId, rowsRenumberedResponse.newStepNumbers, stepCol);
          } else {
            this.assignNewStepNumbers(rowsRenumberedResponse.newStepNumbers, tableContext.table, stepCol);
            this.refreshDataSource(tableContext);
          }
        }
      });
  }

  postChangeCellCommand(tableContext: TableContext, commandValues: ChangeExperimentCellsCommand | ChangeRecipeCellCommand,
    activityId: string | undefined = undefined) {
    const stepCol = tableContext.table.columnDefinitions.find(c => c.columnType === ColumnType.stepNumber); // By policy, there can only be one
    if (stepCol) {
      if (!stepCol.field) throw new Error('LOGIC ERROR: All columns must have field defined');

      const stepNumberChanged = commandValues.columnValues.some(c => c.propertyName === stepCol.field);
      if (stepNumberChanged && commandValues.rowIds.length === 1) {
        /*
         * if >1 row was changed somehow (e.g. multi-row pasting), We don't want to do anything since that would cause chaos.
         * So, in that case, renumbering is DIY.
        */
        const rowNode = tableContext.grid.gridApi.getRowNode(commandValues.rowIds[0]);
        if (!rowNode?.id) throw new Error('LOGIC ERROR: Could not find row node that changed in the table');

        this.sendRequestToRenumberRows(tableContext, rowNode.id, stepCol.field, false, activityId);
      }
    }
  }

  loadRemovedRowsDialog(tableContext: TableContext, activityId: string | undefined = undefined) {
    const data: RemovedRowsDialogConfigData = {
      data: tableContext.table,
      restoreRow: (rowId: string) => this.confirmThenRestoreRow(tableContext, rowId, activityId),
      getPrimitiveDataValueRows: (rows, columns) => this.getPrimitiveDataValueRows(rows, columns as ColumnSpecification[]),
      rowRestored: this.rowRestored,
    };
    this.dialogService.open(RemovedRowsComponent, {
      width: '80%',
      autoZIndex: true,
      height: '50%',
      closable: true,
      closeOnEscape: true,
      header: $localize`:@@removedRowsModalHeader:Removed Rows for Table: ${tableContext.table.itemTitle}`,
      styleClass: 'eln-removed-rows-dialog',
      data,
    });
  }

  sendRequestToRestoreRow(tableContext: TableContext, rowId: string, activityId: string | undefined = undefined) {
    const documentIdProperty = 'experimentId' in tableContext ? 'experimentId' : 'recipeId';
    const documentId = (tableContext as any)[documentIdProperty];
    const command = {
      [documentIdProperty]: documentId,
      rowId,
      tableId: tableContext.table.tableId,
      activityId: activityId
    } as RestoreRowCommand<T>;
    this.restoreRowPost(command)
      .pipe(finalize(() => tableContext.isLoading = false))
      .subscribe({
        next: () => {
          this.processRestoredRow(tableContext, rowId, activityId);
        },
        error: () => {
          //error is handled by ExceptionInterceptor
        },
        complete: () => {
          tableContext.isLoading = false;
        }
      });
  }

  private processRemovedRow(table: Table, rowId: string, newStepNumbers?: { [key: string]: number }, stepNumberField?: string) {
    const idField = TableDataService.getRowIdField(table.columnDefinitions);
    if (!idField) throw new Error("LOGIC ERROR: This class cannot be use with a table that doesn't have a rowId column.");
    const row = table.value.find(row => (row as any)[idField] === rowId);
    if (!row) throw new Error('Expected to find row in table with ID: ' + rowId);

    row[TableDataService.isRemovedColumn] = true;

    if (stepNumberField) {
      this.assignNewStepNumbers(newStepNumbers, table, stepNumberField);
    }
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@rowRemovedMessage:Row ${row.rowIndex.value.value} Removed Successfully`,
    });

    this.rowRemoved.next(rowId);
  }

  public assignNewStepNumbers(newStepNumbers: { [key: string]: number } | undefined, table: Table, stepNumberField: string) {
    for (const id in newStepNumbers) {
      const step = newStepNumbers[id];
      const row = table.value.find(row => row.id === id);
      if (!row) throw new Error("LOGIC ERROR: Got IDs back from Renumber Rows API that aren't in the table");

      // By policy, we do not mark rows renumbered this way as "modified" (unless previously-modified)
      row[stepNumberField].value.value = step.toString();
    }
  }

  private async processRestoredRow(tableContext: TableContext, rowId: string, activityId: string | undefined) {
    const row = tableContext.table.value.find(row => row.id === rowId);
    if (!row) throw new Error('Expected to find row in table with ID: ' + rowId);

    row[TableDataService.isRemovedColumn] = false;

    const toastAndEmit = () => {
      this.messageService.add({
        key: 'notification',
        severity: 'success',
        summary: $localize`:@@rowRestoredMessage:Row ${row.rowIndex.value.value} Restored Successfully`,
      });

      this.rowRestored.next(rowId);
    };
    if (tableContext.table.columnDefinitions.some(c => c.columnType === ColumnType.stepNumber)) {
      this.assignNextStepNumber(tableContext, row, activityId)
        .subscribe({
          next: () => toastAndEmit()
        });
    } else {
      toastAndEmit();
    }
  }

  static rowIsRemoved(row: { [key: string]: any }): boolean {
    return row[TableDataService.isRemovedColumn] ?? false;
  }

  rowsAdded(tableContext: TableContext, e: BptGridRowsAddedEvent): Row[] {
    return e.values.map((row: any) => {
      delete row[TableDataService.isRemovedColumn]; // Remove this pseudo-column so it doesn't get overwritten with EDV
      const { id, ...data } = row;
      const toCellWithDefaultUnitsAppliedToQuantity = (fieldValue: any, fieldName: string) => {
        const column = tableContext.table.columnDefinitions.find(c => c.field === fieldName);
        if (column && column.columnType === ColumnType.quantity) {
          if (fieldValue) DataValueService.pruneQuantity(fieldValue);
          fieldValue = this.applyDefaultUnitToQuantity(
            fieldName,
            fieldValue,
            tableContext.grid.gridApi.getRowNode(id),
            column
          );
        } else if (column?.columnType === ColumnType.specification) {
          if (!fieldValue) {
            fieldValue = { type: ValueType.Specification, state: ValueState.Empty };
          }
        }
        return {
          propertyName: fieldName,
          propertyValue: this.dataValueService.getExperimentDataValue(
            column?.columnType,
            fieldValue
          )
        };
      };

      return {
        id,
        data: values(mapValues(data, toCellWithDefaultUnitsAppliedToQuantity)),
      };
    });
  }

  refreshDataSource(tableContext: TableContext): { [key: string]: any }[] {
    const primitiveValue = this.getPrimitiveDataValueRows(tableContext.table.value, tableContext.table.columnDefinitions);
    tableContext.grid.gridApi.setRowData(primitiveValue.filter(row => !TableDataService.rowIsRemoved(row)));
    tableContext.grid.gridApi.refreshCells({ force: true });
    return primitiveValue;
  }

  /**
   * Applies default unit for cell value, update grid cell if needed.
   * @returns Returns the same, updated or replacement fieldValue
   */
  private applyDefaultUnitToQuantity(
    fieldName: string,
    fieldValue: any,
    gridRow: IRowNode | undefined,
    column: ColumnSpecification
  ): any {
    if (column.defaultUnit) {
      // quantity needs to be Empty with a unit or Set with a unit or NotApplicable
      if (fieldValue) {
        // value in row can sometime already have a valued added the grid
        if (
          (fieldValue.state === ValueState.Empty || fieldValue.state === ValueState.Set) &&
          !fieldValue.unit
        ) {
          fieldValue.unitDetails = column.defaultUnit;
          gridRow?.setDataValue(fieldName, fieldValue);
        }
      } else if (column.defaultUnit.name !== NA) {
        // this is the normal case: value is missing in new row
        const number: NumberValue = {
          type: ValueType.Number,
          state: ValueState.Empty,
          unit: column.defaultUnit.id
        };
        fieldValue = number; // fixes up parameter!!!
        const quantity = new Quantity(
          number.state,
          number.value,
          column.defaultUnit,
          number.sigFigs,
          number.exact
        );
        gridRow?.setDataValue(fieldName, quantity);
      }
    }
    return fieldValue;
  }

  /** 
   * Creates an observable that posts a change-cell with computed step number.
   * Computed step number is the next higher from all the non-removed rows or 1 when there are none.
  */
  private assignNextStepNumber(tableContext: TableContext, row: TableValueRow, activityId: string | undefined) {
    const stepCol = tableContext.table.columnDefinitions.find(c => c.columnType === ColumnType.stepNumber)?.field;
    if (!stepCol) throw new Error("LOGIC ERROR: You're not supposed to call this method unless you've already determined there is a stepNumber column");

    const stepNums = tableContext.table.value.filter(r => r.id !== row.id)
      .filter(r => r[stepCol].value.state === ValueState.Set)
      .filter(r => !TableDataService.rowIsRemoved(r))
      .map(r => Number.parseInt(r[stepCol].value.value as string));

    const nextStep: NumberValue = {
      type: ValueType.Number,
      state: ValueState.Set,
      value: stepNums.length ? (Math.max(...stepNums) + 1).toString() : '1',
      exact: true
    };

    const documentIdProperty = 'experimentId' in tableContext ? 'experimentId' : 'recipeId';
    const documentId = (tableContext as any)[documentIdProperty];
    const command = {
      [documentIdProperty]: documentId,
      tableIds: [tableContext.table.tableId],
      columnValues: [{
        propertyName: stepCol,
        propertyValue: nextStep
      }],
      rowIds: [row.id],
      activityId: activityId
    } as ChangeCellsCommand<T>;
    return this.changeCellsPost(command)
      .pipe(tap(() => row[stepCol] = { value: nextStep, isModified: true })); // Will always be modified, by policy
  }

  /** Creates a dictionary of VISIBLE (non-removed) rows with SET (valid) Step Numbers keyed by rowId */
  getOldStepNumbers(tableContext: TableContext, rowId: string | undefined, rowRemoval = false): { [key: string]: number } {
    const stepCol = tableContext.table.columnDefinitions.find(c => c.columnType === ColumnType.stepNumber)?.field;

    if (!stepCol) throw new Error('Step Number not found in table');

    return Object.fromEntries(tableContext.table.value
      .filter(r => !rowRemoval || r.id !== rowId) // Only want to filter out current row if it was removed (since not actually marked remove yet)
      .filter(r => !TableDataService.rowIsRemoved(r))
      .filter(r => r[stepCol].value.state === ValueState.Set)
      .map(row => [row.id, Number.parseInt(row[stepCol].value.value as string)])
    );
  }

  getContextMenu(
    tableContext: TableContext,
    internalCommentsMenuOption: GridContextMenuItem | undefined = undefined,
    sequentialReadingMenuOption: GridContextMenuItem | undefined = undefined,
    clientFacingNoteMenuOption: GridContextMenuItem | undefined = undefined,
    historyMenuOption: GridContextMenuItem | undefined = undefined,
    activityId: string | undefined = undefined
  ) {
    const menu: GridContextMenuItem[] = [
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'paste',
      'separator',
    ];

    if (this.hasOnlyQuantitiesSelected(tableContext.grid.gridApi, tableContext.table.columnDefinitions as ColumnDefinition[])) {
      menu.push(this.getAdvancedCopyMenuItems(tableContext.grid.gridApi, tableContext.table.value), 'separator');
    }

    if (sequentialReadingMenuOption) menu.push(sequentialReadingMenuOption);
    if (clientFacingNoteMenuOption) menu.push(clientFacingNoteMenuOption);
    if (internalCommentsMenuOption) {
      menu.push(internalCommentsMenuOption);
      menu.push('separator');
    }

    menu.push(tableContext.fillWithNaMenuItem());

    const removeRowItem = this.getRowRemovedMenuItem(tableContext, activityId);
    if (removeRowItem) menu.push(removeRowItem);

    const viewSpecItem = tableContext.getViewSpecMenuItem();

    if (historyMenuOption || viewSpecItem) menu.push('separator');

    if (historyMenuOption) menu.push(historyMenuOption);
    if (viewSpecItem) menu.push(viewSpecItem);

    return menu;
  }

  private getAdvancedCopyMenuItems(gridApi: GridApi, tableValue: TableValueRow[]): GridContextMenuItem {
    return {
      label: $localize`:@@AdvancedCopy:Advanced Copy`,
      subitems: [
        {
          label: $localize`:@@CopyUnits:Copy Units`,
          action: async () => {
            await this.clipboardService.writeText(this.copyUnits(gridApi, tableValue));
          }
        },
        {
          label: $localize`:@@CopyValues:Copy Values`,
          action: async () => {
            await this.clipboardService.writeText(this.copyValues(gridApi, tableValue));
          }
        }
      ],
      icon: '<img src="assets/eln-assets/copy.svg" class="ag-icon ag-custom-icon" />'
    };
  }

  private copyUnits(gridApi: GridApi, tableValue: TableValueRow[]) {
    const value = this.getSelectedCellValues(gridApi, tableValue)[0]; //Seems like we only ever need to care about the first set of values.
    const propIsNumVal = (prop: Cell['propertyValue']): prop is NumberValue => prop.type === ValueType.Number;
    const cellToUnit = (cell: Cell) => propIsNumVal(cell.propertyValue) ? cell.propertyValue.unit : undefined;
    const findUnit = (guid: string | undefined) => this.unitLoaderService.allUnits.find(unit => unit.id === guid);

    const units: {
      [row: string]: (Unit | undefined)[];
    } = {};

    Object.keys(value).forEach(key => {
      units[key] = value[key].map(cell => cellToUnit(cell)).map(guid => findUnit(guid));
    });

    return Object.values(units)
      .map(units => units.map(unit => unit?.abbreviation).join('\t'))
      .join('\r\n')
      .trim();
  }

  private copyValues(gridApi: GridApi, tableValue: TableValueRow[]) {
    const value = this.getSelectedCellValues(gridApi, tableValue)[0]; //Seems like we only ever need to care about the first set of values.
    const propIsNumVal = (prop: Cell['propertyValue']): prop is NumberValue => prop.type === ValueType.Number;
    const cellToValue = (cell: Cell) => propIsNumVal(cell.propertyValue) ? cell.propertyValue.value : undefined;

    const values: {
      [row: string]: (string | undefined)[];
    } = {};

    Object.keys(value).forEach(key => {
      values[key] = value[key].map(cellToValue);
    });

    return Object.values(values)
      .map(row => row.join('\t'))
      .join('\r\n')
      .trim();
  }

  getSelectedCellValues(gridApi: GridApi, tableValue: TableValueRow[]): Array<{ [key: string]: Array<Cell> }> {
    const cellRange = gridApi.getCellRanges() ?? [];
    const values: Array<{ [key: string]: Array<Cell> }> = [];
    cellRange.forEach(cells => {
      const rowNodes: Array<IRowNode> = [];
      const columnValues: { [key: string]: Array<Cell> } = {};
      const startRowIndex = cells.startRow?.rowIndex as number;
      const endRowIndex = cells.endRow?.rowIndex ?? startRowIndex;

      for (
        let rowIndex = Math.min(startRowIndex, endRowIndex);
        rowIndex <= Math.max(startRowIndex, endRowIndex);
        rowIndex++
      ) {
        rowNodes.push(gridApi.getDisplayedRowAtIndex(rowIndex) as IRowNode);
        const rowId = rowNodes[rowNodes.length - 1].id as string;
        columnValues[rowId] = [];
        const rowData = tableValue.find(data => data.id === rowId);
        if (!rowData) return;
        cells.columns.forEach((column) => {
          const colId = column.getColId();
          columnValues[rowId].push({
            propertyName: colId,
            propertyValue: rowData[colId].value
          });
        });
      }
      values.push(columnValues);
    });
    return values;
  }

  private hasOnlyQuantitiesSelected(gridApi: GridApi, columnDefinitions: ColumnDefinition[]): boolean {
    const cells = [
      ...new Set([
        ...(gridApi.getCellRanges() ?? []).flatMap((cell) =>
          cell.columns.map(col => col.getColId())
        )
      ])
    ];
    return (
      cells.length > 0 &&
      cells.reduce((sofar, colId) => {
        const targetColumnIndex = columnDefinitions.findIndex(
          colDef => colDef.field === colId
        );
        if (targetColumnIndex === -1) return false;

        // Add check here when doing work for PBI 3227080: Step Value - Copy/paste considerations
        return (
          sofar && columnDefinitions[targetColumnIndex].columnType === ColumnType.quantity
        );
      }, true)
    );
  }

  private getRowRemovedMenuItem(tableContext: TableContext, activityId: string | undefined): GridContextMenuItem | undefined {
    if (!tableContext.table.allowRowRemoval) return undefined;

    const cell = tableContext.grid.gridApi.getFocusedCell();
    if (cell?.column.getColId() === 'rowIndex') {
      const row = tableContext.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
      const rowId = row?.id;
      if (!rowId) return undefined; // Row may have already been removed. This can happen sometimes right after the row was removed and it's harmless

      return {
        label: $localize`:@@removeRow:Remove Row`,
        action: () => {
          this.confirmThenRemoveRow(tableContext, rowId, activityId);
        },
        icon: '<img class="far fa-trash-alt" />'
      };
    }

    return undefined;
  }

  showInternalComments(rowId: string, field: string, renderer: Renderer2, buildInternalComments: (rowId: string | undefined, field: string) => void): void {
    const cell = document.querySelector(`ag-grid-angular [row-id="${rowId}"] [col-id="${field}"]`);
    if (cell) {
      renderer.setStyle(cell, 'background-color', ELNAppConstants.InternalCommentBackGroundColor);
    }

    buildInternalComments(rowId, field);
  }

  getPrimitiveDataValueRows(incomingRows: TableValueRow[], columnDefinitions: ColumnSpecification[]): { [key: string]: any }[] {
    return incomingRows.map(row =>
      mapValues(row, (value: TableValueRow, field: string) => {
        if (field === TableDataService.isRemovedColumn) return value;

        const column = columnDefinitions.find(d => d.field === field);
        if (!column) {
          const msg = 'Logic error: When a grid is used to render a table, each column definition must have a field value but at least one is missing: expected column with field: '
            + field;
          console.error(msg);
          return value;
        }

        if (column.columnType === ColumnType.rowId || field === TableDataService.isRemovedColumn) return value; // never a ModifiableDataValue

        return this.dataValueService.getPrimitiveValue(
          column.columnType ?? ApiColumnType.String,
          // Type asserting to unknown is required for seeing the TableValueRow type as a ModifiableDataValue.
          value as unknown as ModifiableDataValue
        );
      })
    );
  }

  /** Refresh is required before adding a row in order to get assigned a correct Step Number */
  refreshBeforeAddRow(tableContext: TableContext, grid: BptGridComponent, params: AddRowEventParams, addRows: (params: AddRowEventParams) => void) {
    this.refreshDataSource(tableContext);
    const primitiveData = this.getPrimitiveDataValueRows(tableContext.table.value, tableContext.table.columnDefinitions);
    grid.dataSource = primitiveData.filter((row: { [key: string]: any }) => !TableDataService.rowIsRemoved(row));
    addRows(params);
  }
}
