/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ExperimentFlagRaisedCommand } from '../models/experiment-flag-raised-command';
import { ExperimentFlagRaisedEventResponse } from '../models/experiment-flag-raised-event-response';
import { ExperimentFlagUnraisedCommand } from '../models/experiment-flag-unraised-command';
import { ExperimentFlagUnraisedEventResponse } from '../models/experiment-flag-unraised-event-response';

@Injectable({
  providedIn: 'root',
})
export class ExperimentFlagService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation experimentFlagsRaiseFlagPost
   */
  static readonly ExperimentFlagsRaiseFlagPostPath = '/experiment-flags/raise-flag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentFlagsRaiseFlagPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsRaiseFlagPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentFlagRaisedCommand
  }): Observable<StrictHttpResponse<ExperimentFlagRaisedEventResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentFlagService.ExperimentFlagsRaiseFlagPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentFlagRaisedEventResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentFlagsRaiseFlagPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsRaiseFlagPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentFlagRaisedCommand
  }): Observable<ExperimentFlagRaisedEventResponse> {

    return this.experimentFlagsRaiseFlagPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentFlagRaisedEventResponse>) => r.body as ExperimentFlagRaisedEventResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentFlagsRaiseFlagPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsRaiseFlagPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentFlagRaisedCommand
  }): Observable<StrictHttpResponse<ExperimentFlagRaisedEventResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentFlagService.ExperimentFlagsRaiseFlagPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentFlagRaisedEventResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentFlagsRaiseFlagPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsRaiseFlagPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentFlagRaisedCommand
  }): Observable<ExperimentFlagRaisedEventResponse> {

    return this.experimentFlagsRaiseFlagPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentFlagRaisedEventResponse>) => r.body as ExperimentFlagRaisedEventResponse)
    );
  }

  /**
   * Path part for operation experimentFlagsLowerFlagPost
   */
  static readonly ExperimentFlagsLowerFlagPostPath = '/experiment-flags/lower-flag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentFlagsLowerFlagPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsLowerFlagPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentFlagUnraisedCommand
  }): Observable<StrictHttpResponse<ExperimentFlagUnraisedEventResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentFlagService.ExperimentFlagsLowerFlagPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentFlagUnraisedEventResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentFlagsLowerFlagPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsLowerFlagPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentFlagUnraisedCommand
  }): Observable<ExperimentFlagUnraisedEventResponse> {

    return this.experimentFlagsLowerFlagPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentFlagUnraisedEventResponse>) => r.body as ExperimentFlagUnraisedEventResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentFlagsLowerFlagPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsLowerFlagPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentFlagUnraisedCommand
  }): Observable<StrictHttpResponse<ExperimentFlagUnraisedEventResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExperimentFlagService.ExperimentFlagsLowerFlagPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentFlagUnraisedEventResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `experimentFlagsLowerFlagPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsLowerFlagPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentFlagUnraisedCommand
  }): Observable<ExperimentFlagUnraisedEventResponse> {

    return this.experimentFlagsLowerFlagPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentFlagUnraisedEventResponse>) => r.body as ExperimentFlagUnraisedEventResponse)
    );
  }

}
