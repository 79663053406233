/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { QueueChromatographyResultSetImportRequestCommand as ElnInstrumentIntegrationDomainChromatographyQueueChromatographyResultSetImportRequestCommand } from '../models/ELN/InstrumentIntegration/Domain/Chromatography/queue-chromatography-result-set-import-request-command';
import { QueueChromatographyResultSetRefreshRequestCommand as ElnInstrumentIntegrationDomainChromatographyQueueChromatographyResultSetRefreshRequestCommand } from '../models/ELN/InstrumentIntegration/Domain/Chromatography/queue-chromatography-result-set-refresh-request-command';

@Injectable({
  providedIn: 'root',
})
export class ChromatographyIntegrationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation chromatographyIntegrationResultSetImportRequestPost
   */
  static readonly ChromatographyIntegrationResultSetImportRequestPostPath = '/chromatography-integration/result-set-import-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chromatographyIntegrationResultSetImportRequestPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographyIntegrationResultSetImportRequestPost$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ElnInstrumentIntegrationDomainChromatographyQueueChromatographyResultSetImportRequestCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ChromatographyIntegrationService.ChromatographyIntegrationResultSetImportRequestPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {"style":"simple"});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chromatographyIntegrationResultSetImportRequestPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographyIntegrationResultSetImportRequestPost(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ElnInstrumentIntegrationDomainChromatographyQueueChromatographyResultSetImportRequestCommand
  }): Observable<void> {

    return this.chromatographyIntegrationResultSetImportRequestPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost
   */
  static readonly ChromatographyIntegrationResultSetsChromatographyIdRefreshRequestPostPath = '/chromatography-integration/result-sets/{chromatographyId}/refresh-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost$Response(params: {
    chromatographyId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ElnInstrumentIntegrationDomainChromatographyQueueChromatographyResultSetRefreshRequestCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ChromatographyIntegrationService.ChromatographyIntegrationResultSetsChromatographyIdRefreshRequestPostPath, 'post');
    if (params) {
      rb.path('chromatographyId', params.chromatographyId, {"style":"simple"});
      rb.header('X-User-Puid', params['X-User-Puid'], {"style":"simple"});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost(params: {
    chromatographyId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: ElnInstrumentIntegrationDomainChromatographyQueueChromatographyResultSetRefreshRequestCommand
  }): Observable<void> {

    return this.chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
