import { ActivityInputType, ExperimentWorkflowState } from '../../../api/data-entry/models';
export type LabItemWisePermissions = { [key: string]: { [key: string]: boolean } };

export class LabItemsFeatureManager {
  static readonly refreshLabItem = 'eln-refresh-lab-item';
  static readonly restoreLabItem = 'eln-restore-lab-item';
  static readonly deleteLabItem = 'eln-remove-lab-item';

  public static readonly FeatureNamesByItemType: {
    [itemType: string]: {
      removeFeatureName: string;
      refreshFeatureName: string;
      restoreFeatureName: string;
      cellChangeFeatureName: string;
    };
  } = {
    material: {
      // When radium is update will change this to PermittedWorkflowStatesToRemoveMaterial.
      removeFeatureName: 'PermittedWorkflowStatesToRemoveInstrument',
      refreshFeatureName: 'PermittedWorkflowStatesToRefreshMaterial',
      restoreFeatureName: 'PermittedWorkflowStatesToRestoreMaterial',
      cellChangeFeatureName: 'PermittedToChangeMaterial'
    },
    instrumentDetails: {
      removeFeatureName: 'PermittedWorkflowStatesToRemoveInstrument',
      refreshFeatureName: 'PermittedWorkflowStatesToRefreshInstrument',
      restoreFeatureName: 'PermittedWorkflowStatesToRestoreInstrument',
      cellChangeFeatureName: 'PermittedToChangeInstrument'
    },
    instrumentColumn: {
      removeFeatureName: 'PermittedWorkflowStatesToRemoveColumn',
      refreshFeatureName: 'PermittedWorkflowStatesToRefreshColumn',
      restoreFeatureName: 'PermittedWorkflowStatesToRestoreColumn',
      cellChangeFeatureName: 'PermittedToChangeColumn'
    },
    preparation: {
      // LabItem Preparation does not require Cell change feature because it is always readonly
      removeFeatureName: 'PermittedWorkflowStatesToRemovePreparation',
      refreshFeatureName: 'PermittedWorkflowStatesToRefreshPreparation',
      restoreFeatureName: 'PermittedWorkflowStatesToRestorePreparation',
      cellChangeFeatureName: ''
    }
  };

  public static readonly LabItemsDataModifiableFeatures = [
    {
      feature: 'PermittedWorkflowStatesToAddItems',
      expectedValue: true,
      elementIdOfActionOption: 'scanMaterial',
      itemType: ActivityInputType.Material
    },
    {
      feature: 'PermittedWorkflowStatesToAddItems',
      expectedValue: true,
      elementIdOfActionOption: 'scanInstrument',
      itemType: ActivityInputType.InstrumentDetails
    },
    {
      feature: 'PermittedWorkflowStatesToAddItems',
      expectedValue: true,
      elementIdOfActionOption: 'scanInstrument',
      itemType: ActivityInputType.InstrumentColumn
    },
    {
      feature:
        LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.Material].removeFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.deleteLabItem,
      itemType: ActivityInputType.Material
    },
    {
      feature:
        LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.Material]
          .restoreFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.restoreLabItem,
      itemType: ActivityInputType.Material
    },
    {
      feature:
        LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.Material]
          .refreshFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.refreshLabItem,
      itemType: ActivityInputType.Material
    },
    {
      feature:
        LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.InstrumentDetails]
          .refreshFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.refreshLabItem,
      itemType: ActivityInputType.InstrumentDetails
    },
    {
      feature:
        LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.InstrumentDetails]
          .removeFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.deleteLabItem,
      itemType: ActivityInputType.InstrumentDetails
    },
    {
      feature:
        LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.InstrumentColumn]
          .removeFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.deleteLabItem,
      itemType: ActivityInputType.InstrumentColumn
    },
    {
      feature:
        LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.InstrumentColumn]
          .refreshFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.refreshLabItem,
      itemType: ActivityInputType.InstrumentColumn
    },
    {
      feature:
        LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.InstrumentDetails]
          .restoreFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.restoreLabItem,
      itemType: ActivityInputType.InstrumentDetails
    },
    {
      feature:
        LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.InstrumentColumn]
          .restoreFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.restoreLabItem,
      itemType: ActivityInputType.InstrumentColumn
    },
    {
      feature:
        LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.Preparation]
          .removeFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.deleteLabItem,
      itemType: ActivityInputType.Preparation
    },
    {
      feature:
        LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.Preparation]
          .restoreFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.restoreLabItem,
      itemType: ActivityInputType.Preparation
    },
    {
      feature:
        LabItemsFeatureManager.FeatureNamesByItemType[ActivityInputType.Preparation]
          .refreshFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.refreshLabItem,
      itemType: ActivityInputType.Preparation
    }
  ];

  private static getDefaultPermissions(): LabItemWisePermissions {
    const permissions: LabItemWisePermissions = {
      material: {
        enabled: false
      },
      instrumentDetails: {
        enabled: false
      },
      instrumentColumn: {
        enabled: false
      },
      preparation: {
        enabled: false
      }
    };
    permissions[ActivityInputType.Material][
      LabItemsFeatureManager.FeatureNamesByItemType[
        ActivityInputType.Material
      ].cellChangeFeatureName
    ] = false;

    permissions[ActivityInputType.InstrumentDetails][
      LabItemsFeatureManager.FeatureNamesByItemType[
        ActivityInputType.InstrumentDetails
      ].cellChangeFeatureName
    ] = false;

    permissions[ActivityInputType.InstrumentColumn][
      LabItemsFeatureManager.FeatureNamesByItemType[
        ActivityInputType.InstrumentColumn
      ].cellChangeFeatureName
    ] = false;

    return permissions;
  }

  public static EvaluateUserPermissionsOnLabItems(
    workflowState: ExperimentWorkflowState,
    featuresByClientState: string[]
  ): LabItemWisePermissions {
    const permissions = LabItemsFeatureManager.getDefaultPermissions();
    LabItemsFeatureManager.LabItemsDataModifiableFeatures.forEach((featureToLookup) => {

      permissions[featureToLookup.itemType][featureToLookup.feature] =
        permissions[featureToLookup.itemType][featureToLookup.feature] || false;
      permissions[featureToLookup.itemType][featureToLookup.elementIdOfActionOption] =
        permissions[featureToLookup.itemType][featureToLookup.elementIdOfActionOption] || false;

      featuresByClientState.forEach((feature) => {
        if (feature) {
          const features = JSON.parse(feature);
          const hasAccess =
            features[featureToLookup.feature] &&
            (features[featureToLookup.feature] as Array<string>).includes(workflowState);

          if (
            features[featureToLookup.feature] &&
            !permissions[featureToLookup.itemType][featureToLookup.feature]
          ) {
            permissions[featureToLookup.itemType].enabled =
              permissions[featureToLookup.itemType].enabled || hasAccess;
            permissions[featureToLookup.itemType][featureToLookup.feature] = hasAccess;
            permissions[featureToLookup.itemType][featureToLookup.elementIdOfActionOption] =
              hasAccess;
          }
        }
      });
    });

    this.setCorrelatedPermissions(permissions);
    return permissions;
  }

  private static setCorrelatedPermissions(permissions: LabItemWisePermissions): void {
    this.setChangeFieldCorrelatedPermission(permissions, ActivityInputType.Material);
    this.setChangeFieldCorrelatedPermission(permissions, ActivityInputType.InstrumentDetails);
    this.setChangeFieldCorrelatedPermission(permissions, ActivityInputType.InstrumentColumn);    
  }

  private static setChangeFieldCorrelatedPermission(
    permissions: LabItemWisePermissions,
    itemType: ActivityInputType
  ): void {
    permissions[itemType][
      LabItemsFeatureManager.FeatureNamesByItemType[itemType].cellChangeFeatureName
    ] =
      permissions[itemType][
        LabItemsFeatureManager.FeatureNamesByItemType[itemType].refreshFeatureName
      ];
  }
}
