import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { LabItemsService } from '../../lab-items.service';
import { BaseComponent } from '../../../../base/base.component';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';
import { ActivityInputType, Consumable } from '../../../../api/models';
import { LabItemsConsumablesTableOptions } from '../lab-items-consumable-table-options';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-lab-items-removed-consumable',
  templateUrl: './lab-items-removed-consumable.component.html',
  styleUrls: ['./lab-items-removed-consumable.component.scss']
})
export class LabItemsRemovedConsumableComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() titleOfTable = $localize`:@@LabItemsConsumableTableTitle:Consumables and Supplies`;
  @Input()
  subHeading = $localize`:@@labitemsRemovedConsumableSubHeading:Please click on the restore icon from the table to restore removed consumables`;
  labItemsRemovedConsumableDataSource: Consumable[] = [];
  @ViewChild('labItemsRemovedConsumableGrid') labItemsRemovedConsumableGrid?: BptGridComponent;

  labItemsConsumableId!: string;
  columnDefinitions: ColumnDefinition[] = [];
  gridMode = BptGridMode.dataView;
  containsRemovedRows = false;
  wasEmpty = false;
  gridActions!: BptGridRowActionConfiguration;

  constructor(
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly labItemsService: LabItemsService,
  ) {
    super(clientStateService, activatedRoute);
    this.columnDefinitions = LabItemsConsumablesTableOptions.GetColumnDefinitions(false);
    this.refreshDataSource();
  }

  ngOnInit(): void {
    this.labItemsConsumableId = this.labItemsService.consumableRemovedRowsGridId;
    this.watchConsumableRestoredNotification();
    this.addGridActions();    
  }
  
  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }
  
  onGridReady() {
  }
 
  loadAuditHistoryDialog() {
    this.labItemsService.loadAuditHistoryDialog(ActivityInputType.Consumable);
  }

  private refreshDataSource(): void {
    const removedConsumables = this.labItemsService.getLabItemsRemovedConsumables();
    removedConsumables.forEach((consumable) => {
      this.labItemsService.unpackModifiableDataValues<Consumable>(
        consumable,
        consumable.tableData,
        this.columnDefinitions,
        ActivityInputType.Consumable
      );
    });
    this.labItemsRemovedConsumableDataSource = [...removedConsumables];
    this.labItemsRemovedConsumableGrid?.gridApi?.setRowData(this.labItemsRemovedConsumableDataSource);
    this.labItemsRemovedConsumableGrid?.gridColumnApi.autoSizeAllColumns()
  }

  private watchConsumableRestoredNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.ConsumableRestored.subscribe({
        next: () => this.refreshDataSource()
      })
    );
  }

  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);
  
    this.gridActions = {
      actions: actionsSubject,
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.labItemsService.consumableRestoreActionId,
        enabled: this.labItemsService.getRowActionPermission(),
        styleClass: 'icon-s icon-restore-icon',
        click: this.rowRestoreActionClick.bind(this),
        tooltip: $localize`:@@RestoreItem:Restore item`
      }
    ];
  }

  private rowRestoreActionClick(e: BptGridRowActionClickEvent) {    
    e.params.data.id = e.params.rowIndex;
    this.labItemsService.restoreHandler[ActivityInputType.Consumable](e.params.data);
  }
}
