<div class="expiration-layout-content">
  <bpt-slider
    #elnPreparationExpiration
    [(visible)]="sliderOptions.visible"
    [closeOnEscape]="sliderOptions.closeOnEscape"
    [headerText]="sliderOptions.headerText"
    [size]="sliderOptions.size"
    [isFooterSticky]="sliderOptions.isFooterSticky"
    [displayFooter]="sliderOptions.displayFooter"
    [displayFooterWithPrimaryButton]="false"
    [displayFooterWithSecondaryButton]="false"
    [isModal]="sliderOptions.isModal"
    (visibleChange)="sliderVisibleChange($event)">
    <div class="flex eln-flex-container" id="flex-container">
      <bpt-quantity
        id="eln-expiration-duration"
        label="Duration"
        i18n-label="@@duration"
        name="duration"
        [commitValueOnEnter]="quantityOptions.commitValueOnEnter"
        ngDefaultControl
        [enableSignificantFigures]="quantityOptions.enableSignificantFigures"
        [allowedUnits]="timeUnits"
        [cancelValueOnEscape]="quantityOptions.cancelValueOnEscape"
        [ngModel]="duration"
        [ngModelOptions]="{ updateOn: 'change' }"
        [allowNA]="quantityOptions.allowNA"
        [allowDecimal]="quantityOptions.allowDecimal"
        [highlightAllOnFocus]="quantityOptions.highlightAllOnFocus"
        [suppressContextMenu]="quantityOptions.suppressContextMenu"
        [tabIndex]=3
        (quantityChange)="convertDuration($event)"
        [disabled]="isReadOnly || whileSuitableForUse"
        class="mb-4"></bpt-quantity>
    </div>
    <div class="flex eln-flex ">
      <p-checkbox
        [binary]="true"
        [(ngModel)]="whileSuitableForUse"
        i18n-label="@@whileSuitable"
        label="While suitable for use"
        id="eln-suitableForUse"
        class="mb-4"
        [disabled]="isReadOnly">
      </p-checkbox>
    </div>
    <div class="flex eln-flex flex-row gap-3">
      <bpt-text-input *ngIf="whileSuitableForUse"
        inputId="eln-expiration-suitableForUse"
        i18n-label="@@expiration"
        label="Expiration"
        [ngModel]="isParentRecipe ? '' : suitableForUseText "
        [ngModelOptions]="{ updateOn: 'blur' }"
        [disabled]="true"
        [required]="true && !isParentRecipe"
        class="mb-4">
      </bpt-text-input>
      <bpt-datetime *ngIf="!whileSuitableForUse"
        #dateTime
        i18n-label="@@expiration"
        [commitValueOnEnter]="expirationOptions.commitValueOnEnter"
        label="Expiration"
        [(ngModel)]="isParentRecipe ? '' :expiration"
        (ngModelChange)="setDateFromCalendar($event)"
        id="eln-expiration"
        [required]="true && !isParentRecipe"
        [includeTime]="expirationOptions.includeTime"
        [hourFormat]="expirationOptions.hourFormat"
        class="mb-4"
        [disabled]="isReadOnly || whileSuitableForUse"
        [errorMessage]="expirationOptions.errorMessage"
        [errorFlag]="expirationOptions.errorFlag"
        [disabled]="isParentRecipe"
        [useJsJodaTypes]="true"
        [ianaTimeZone]="labSiteTimeZone">
      </bpt-datetime>
    </div>
    <div class="flex flex-row gap-3">
      <p-button
        class="preparation-expiration-cancel-button w-6"
        styleClass="bpt-button-compact p-button-outlined"
        i18n-label="@@Cancel"
        label="Cancel"
        (onClick)="cancelExpiration()"></p-button>
      <p-button
        class="preparation-expiration-commit-button w-6"
        i18n-label="@@CommitPreparationsContainerDesc"
        styleClass="bpt-button-compact"
        label="Commit"
        (onClick)="commitExpiration()"
        [disabled]="isReadOnly"></p-button>
    </div>
  </bpt-slider>
</div>