import { Injectable } from '@angular/core';
import { ClientStatePermission } from 'model/client-state-permission.interface';
import { ConstantClientState } from '../integrations/data/clientstate/constant-client-state';
import { ELimsClientState } from '../integrations/data/clientstate/elims-client-state';
import { ClientState } from '../integrations/data/clientstate/client-state.interface';
import { AccessibilityTypes } from '../app.states';

@Injectable({
  providedIn: 'root'
})
export class ClientStateService {
  clientStatePermissions: ClientStatePermission[] = [];
  clientState: ClientState;
  private readOnly = false;

  /** 
   * Master is the global namespace in Helium which holds data like CurrentUser and ClientStatePermission
   * It is accessible through window object when ELN runs inside Helium
  */
   master = (window as any).Master;
 
   get isClientStateReadOnly(): boolean {
     return this.readOnly;
   }
 
   set isClientStateReadOnly(isClientStateReadOnly: boolean) {
     this.readOnly = isClientStateReadOnly;
   }

  constructor() {
    if (this.master) {
      this.clientState = new ELimsClientState(this.master);
    } else {
      this.clientState = new ConstantClientState();
    }
  }

  async getClientStatePermissions(): Promise<boolean> {
    this.clientStatePermissions = await this.clientState.getClientStatePermissions();
    return true;
  }

  /**
   * Gets the accessibility level of the user for the component/page alias Client State in Helium
   * @param clientState ex: `eln.experiment` or `eln.bookshelf` or `eln.newexperiment`
   * @returns ReadWrite or ReadOnly or Hidden
   */
  getClientStateVisibility(state: string): string {
    const result = (this.clientStatePermissions.filter((data: any) =>
      data.clientState === state
    ));
    if (result.length > 1) {
      if(result.find(item => item.visibility === AccessibilityTypes.ReadWrite)){
        return AccessibilityTypes.ReadWrite;
      }
      else if (result.find(item => item.visibility === AccessibilityTypes.ReadOnly)){
        return AccessibilityTypes.ReadOnly;
      }
      else{
        return AccessibilityTypes.Hidden;
      }
    }
    else if (result.length > 0) {
      return result[0].visibility;
    }
    else{
      return AccessibilityTypes.Hidden;
    }
  }

  getFeatureFlags(state: string): string[] {
    const result: string[] = [];
    const permissions = (this.clientStatePermissions.filter((data: any) =>
      data.clientState === state && data.featureFlags !== null
    ));
    if (permissions.length > 0) {
      permissions.forEach(data => result.push(data.featureFlags));
    }
    return result;
  }
}
