import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ExperimentFlagService } from '../../api/data-entry/services/experiment-flag.service';
import {
  ExperimentFlagRaisedEventResponse,
  ExperimentFlagType,
  ExperimentFlagUnraisedEventResponse,
  ExperimentWorkflowState,
  WorkflowEventNotification
} from '../../api/data-entry/models';
import { ExperimentRaisedFlag } from '../../api/models';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../base/base.component';
import { UserService } from 'services/user.service';
import { User } from 'model/user.interface';
import { Observable } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { ExperimentService } from '../services/experiment.service';
import { Experiment } from 'model/experiment.interface';
import { ClientValidationDetails } from 'model/client-validation-details';
import { NonDataRecordService } from '../services/non-data-record.service';
import { ExperimentFlagRaisedNotification } from '../model/ExperimentFlagRaisedNotification';
import { ExperimentFlagUnraisedNotification } from '../model/ExperimentFlagUnraisedNotification';
import { DataRecordService } from '../services/data-record.service';
import { AccessibilityTypes } from '../../app.states';

@Component({
  selector: 'app-experiment-flags',
  templateUrl: './experiment-flags.component.html',
  styleUrls: ['./experiment-flags.component.scss']
})
export class ExperimentFlagsComponent extends BaseComponent implements OnInit {
  @Input() userHasAccess!: boolean;
  user!: User;
  experiment!: Experiment;
  validation!: ClientValidationDetails;
  experimentFlags!: Array<ExperimentRaisedFlag>;
  options = {
    showSpinner: false,
    useOverlay: true,
    spinnerMessage: ''
  };

  constructor(
    readonly clientStateService: ClientStateService,
    readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly messageService: MessageService,
    private readonly experimentService: ExperimentService,
    private readonly experimentFlagService: ExperimentFlagService,
    private readonly nonDataRecordService: NonDataRecordService,
    private readonly dataRecordService: DataRecordService
  ) {
    super(clientStateService, route);
    this.user = { ...this.userService.currentUser };
    if (!this.experimentService.currentExperiment) {
      throw new Error(
        'LOGIC ERROR: ExperimentFlagsComponent cannot be used without an experiment in this.experimentService.currentExperiment'
      );
    }

    this.experiment = this.experimentService.currentExperiment;
    this.experimentFlags =
      this.experimentService.currentExperimentResponse?.experimentFlag.raisedFlags ?? [];
    this.validation = new ClientValidationDetails();
    this.nonDataRecordService.experimentFlagRaisedNotification.subscribe(
      (notification: ExperimentFlagRaisedNotification) => {
        this.applyExperimentFlagRaisedNotification(notification);
      }
    );
    this.nonDataRecordService.experimentFlagUnraisedNotification.subscribe(
      (notification: ExperimentFlagUnraisedNotification) => {
        this.applyExperimentFlagUnraisedNotification(notification);
      }
    );
    this.experimentService.experimentWorkFlowState.subscribe(
      (workflowState: ExperimentWorkflowState) => {
        this.experimentWorkflowAction(workflowState);
      }
    );
    this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe(
      (notification: WorkflowEventNotification) => {
        this.experimentWorkflowDataRecordAction(notification.state);
      }
    );
  }

  ngOnInit(): void {
    this.updateFlagsInfo();
  }

  flagsInfo = [
    {
      icon: 'icon-hand-1 icon-m',
      tooltipText: $localize`:@@supervisorNeeded:Supervisor Needed`,
      flagType: ExperimentFlagType.SupervisorNeeded,
      isRaised: false,
      isDisabled: false,
      flagStyle: ''
    },
    {
      icon: 'icon-eye-witness icon-m',
      tooltipText: $localize`:@@witnessNeeded:Witness Needed`,
      flagType: ExperimentFlagType.WitnessNeeded,
      isRaised: false,
      isDisabled: false,
      flagStyle: ''
    },
    {
      icon: 'icon-cancel icon-m',
      tooltipText: $localize`:@@pendingCancel:Pending Cancel`,
      flagType: ExperimentFlagType.PendingCancel,
      isRaised: false,
      isDisabled: false,
      flagStyle: ''
    }
  ];

  private readonly spinnerMessagesForRaising = {
    supervisorNeeded: $localize`:@@supervisorNeededRaising:Raising Supervisor Needed Flag...`,
    witnessNeeded: $localize`:@@witnessNeededRaising:Raising Witness Needed Flag...`,
    pendingCancel: $localize`:@@pendingCancelRaising:Raising Pending Cancel Flag...`
  };

  private readonly spinnerMessageForUnraising = {
    supervisorNeeded: $localize`:@@supervisorNeededUnRaising:Lowering Supervisor Needed Flag...`,
    witnessNeeded: $localize`:@@witnessNeededUnraising:Lowering Witness Needed Flag...`,
    pendingCancel: $localize`:@@pendingCancelUnraising:Lowering Pending Cancel Flag...`
  };

  private getFlagRaisedToastMessages(flagType: ExperimentFlagType, user?: string) {
    const message = {
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@ExperimentFlag:Experiment Flag`,
      detail: '',
      sticky: false
    };
    switch (flagType) {
      case ExperimentFlagType.SupervisorNeeded:
        message.detail =
          user === undefined
            ? $localize`:@@supervisorNeededRaised:Supervisor Needed Flag Raised`
            : $localize`:@@supervisorNeededRaisedBy:Supervisor Needed Flag Raised By ${user}`;
        break;
      case ExperimentFlagType.WitnessNeeded:
        message.detail =
          user === undefined
            ? $localize`:@@witnessNeededRaised:Witness Needed Flag Raised`
            : $localize`:@@witnessNeededRaisedBy:Witness Needed Flag Raised By ${user}`;
        break;
      case ExperimentFlagType.PendingCancel:
        message.detail =
          user === undefined
            ? $localize`:@@pendingCancelRaised:Pending Cancel Flag Raised`
            : $localize`:@@pendingCancelRaisedBy:Pending Cancel Flag Raised By ${user}`;
        break;
    }
    return message;
  }

  private getFlagUnRaisedToastMessages(flag: ExperimentFlagType, user?: string) {
    const msg = {
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@ExperimentFlag:Experiment Flag`,
      detail: '',
      sticky: false
    };
    switch (flag) {
      case ExperimentFlagType.SupervisorNeeded:
        msg.detail = user
          ? $localize`:@@supervisorNeededUnraisedBy:Supervisor Needed Flag Lowered By ${user}`
          : $localize`:@@supervisorNeededUnraised:Supervisor Needed Flag Lowered`;
        break;
      case ExperimentFlagType.WitnessNeeded:
        msg.detail = user
          ? $localize`:@@witnessNeededUnraisedBy:Witness Needed Flag Lowered By ${user}`
          : $localize`:@@witnessNeededUnraised:Witness Needed Flag Lowered`;
        break;
      case ExperimentFlagType.PendingCancel:
        msg.detail = user
          ? $localize`:@@pendingCancelUnraisedBy:Pending Cancel Flag Lowered By ${user}`
          : $localize`:@@pendingCancelUnraised:Pending Cancel Flag Lowered`;
        break;
    }
    return msg;
  }

  private applyExperimentFlagRaisedNotification(
    flagRaisedNotification: ExperimentFlagRaisedNotification
  ) {
    const flag = flagRaisedNotification.eventContext.changeContext.flag;
    this.experimentFlags.push({
      flag: flag,
      raisedBy: flagRaisedNotification.eventContext.changeContext.raisedBy,
      raisedOn: flagRaisedNotification.raisedOn
    });
    const messageObj = this.getFlagRaisedToastMessages(
      flag,
      flagRaisedNotification.eventContext.changeContext.raisedBy.value
    );
    this.messageService.add(messageObj);
    this.updateFlagsInfo();
    this.experimentService.setExperimentFlags(this.experimentFlags);
  }

  private applyExperimentFlagUnraisedNotification(
    flagUnraisedNotification: ExperimentFlagUnraisedNotification
  ) {
    const flag = flagUnraisedNotification.eventContext.changeContext.flag;
    const flagIndex = this.experimentFlags.findIndex(
      (x) => x.flag.toLowerCase() === flag.toLowerCase()
    );
    if (flagIndex !== -1) {
      this.experimentFlags.splice(flagIndex, 1);
      const messageObj = this.getFlagUnRaisedToastMessages(
        flag,
        flagUnraisedNotification.eventContext.changeContext.raisedBy.value
      );
      this.messageService.add(messageObj);
      this.updateFlagsInfo();
      this.experimentService.setExperimentFlags(this.experimentFlags);
    }
  }

  private updateFlagsInfo() {
    for (const flag of this.flagsInfo) {
      const findFlag = this.findFlag(flag.flagType);
      if (findFlag) {
        flag.isRaised = true;
        flag.flagStyle = `eln-experimentFlag-${flag.flagType}-clicked active`;
      } else {
        flag.isRaised = false;
      }
      flag.isDisabled = this.userHasAccess ? false : true;
    }
  }

  private findFlag(flagType: ExperimentFlagType): ExperimentRaisedFlag {
    return this.experimentFlags.find(
      (x) => x.flag.toLowerCase() === flagType.toLowerCase()
    ) as ExperimentRaisedFlag;
  }

  toggleExperimentFlag(flag: ExperimentFlagType, isRaised: boolean) {
    if (!this.userHasAccess) return;
    this.options.showSpinner = true;
    if (isRaised) {
      const messageObj = this.getFlagUnRaisedToastMessages(flag);
      this.options.spinnerMessage = this.spinnerMessageForUnraising[flag];
      const lowerFlagObs = this.experimentFlagService.experimentFlagsLowerFlagPost$Json({
        body: {
          experimentId: this.experiment.id,
          flag: flag
        }
      });
      this.processResponse(
        lowerFlagObs,
        (flagUnraisedResponse: ExperimentFlagUnraisedEventResponse) => {
          const index = this.experimentFlags.findIndex(
            (x) => x.flag.toLowerCase() === flagUnraisedResponse.flag.toLowerCase()
          );
          this.experimentFlags.splice(index, 1);
          this.messageService.add(messageObj);
          this.updateFlagsInfo();
          this.experimentService.setExperimentFlags(this.experimentFlags);
        }
      );
    } else {
      const messageObj = this.getFlagRaisedToastMessages(flag);
      this.options.spinnerMessage = this.spinnerMessagesForRaising[flag];
      const raiseFlagObs = this.experimentFlagService.experimentFlagsRaiseFlagPost$Json({
        body: {
          experimentId: this.experiment.id,
          flag: flag
        }
      });
      this.processResponse(
        raiseFlagObs,
        (flagRaisedResponse: ExperimentFlagRaisedEventResponse) => {
          this.experimentFlags.push({
            flag: flagRaisedResponse.flag,
            raisedBy: flagRaisedResponse.raisedBy,
            raisedOn: flagRaisedResponse.raisedOn
          });
          this.messageService.add(messageObj);
          this.updateFlagsInfo();
          this.experimentService.setExperimentFlags(this.experimentFlags);
        }
      );
    }
  }

  private processResponse(obs: Observable<any>, acknowledgment: (response: any) => void) {
    obs
      .pipe(
        first(),
        finalize(() => {
          this.options.showSpinner = false;
        })
      )
      .subscribe(
        (data: any) => {
          acknowledgment(data);
        },
        (error: any) => {
          this.validation.errorTitle = $localize`:@@receivedErrorFromServer:Received following error from server`;
        }
      );
  }

  async lowerAllFlagsInAuthorizedOrCanceled() {
    for (const flagData of this.experimentFlags) {
      await this.experimentFlagService
        .experimentFlagsLowerFlagPost$Json({
          body: {
            experimentId: this.experiment.id,
            flag: flagData.flag
          }
        })
        .toPromise();
    }
    this.experimentFlags.splice(0);
    this.userHasAccess = false;
    this.updateFlagsInfo();
  }

  private isUserReviewerOrSupervisor():boolean {
    return this.clientStateService.getFeatureFlags(this.clientState).find(
      (data) => {
        const receivedData = JSON.parse(data);
        return (receivedData.CanEditExperimentInReviewState && receivedData.CanEditExperimentInReviewState === true)
      }
    ) ? true : false; 
  }


  experimentWorkflowAction(workflowState: ExperimentWorkflowState) {
    const visibility = this.clientStateService.getClientStateVisibility(this.clientState) === AccessibilityTypes.ReadWrite;
    if(ExperimentService.isExperimentAuthorizedOrCancelled(workflowState)) {
      this.lowerAllFlagsInAuthorizedOrCanceled();
    } else {
      const editabilityInReviewState = workflowState === ExperimentWorkflowState.InReview && 
                                      !this.isUserReviewerOrSupervisor();
      this.userHasAccess = editabilityInReviewState ? false : visibility;
      this.updateFlagsInfo();
    } 
  }

  experimentWorkflowDataRecordAction(workflowState: ExperimentWorkflowState) {
    const access = this.clientStateService.getClientStateVisibility(this.clientState);
    const accessibility = access === AccessibilityTypes.ReadWrite;
    if(ExperimentService.isExperimentAuthorizedOrCancelled(workflowState)) {
      this.userHasAccess = false
    }
    else {
      const nonEditableInReviewState = workflowState === ExperimentWorkflowState.InReview && 
                                      !this.isUserReviewerOrSupervisor();
      this.userHasAccess = nonEditableInReviewState ? false : accessibility;
    }
    this.updateFlagsInfo();
  }
}
