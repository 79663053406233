import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import {
  BptAlignmentTypes,
  BptIconDefinition,
  BptIconDictionary,
  IconClickEvent
} from 'bpt-ui-library/shared';
import {
  RecentSearch,
  SuggestionsTab,
  AdvancedSuggestionOptions,
  BptSearchComponent,
  SearchControl
} from 'bpt-ui-library/bpt-search';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { TemplateLoaderType } from './models/template-loader-type';
import { TemplateLoaderService } from './services/template-loader.service';
import { dropdownBasicList } from 'model/drop-down-basic-list';
import { TemplateListPresentationHelper } from './models/template-list-presentation-helper';
import { BptSliderComponent } from 'bpt-ui-library/bpt-slider';
import { SelectedTemplate } from './models/selected-template';
import { TemplateLoaderResult } from './models/template-loader-result';
import { NodeType, TemplateType } from '../api/models';
import { TemplateEventService } from './experiment-template-load/services/template-event.service';
import { TemplateInsertLocationOptions } from './experiment-template-load/models/recipe-template-insert-location-options';
import { UserService } from '../services/user.service';
import { ConditionType, DataType, SearchCriteria, SortDirection, StringMatchType } from '../api/search/models';
import { LoaderSearchCriteria } from './experiment-template-load/models/finalised-search-criteria';
import { SelectedTemplateCommand } from './models/template-loader-information';
import { ExperimentTemplateEventService } from '../template-loader/experiment-template-load/services/experiment-template-event.service';
import { SpinnerOptions } from '../eln-progress-spinner-module/spinner-options.interface';
import { ElnProgressSpinnerService } from '../eln-progress-spinner-module/eln-progress-spinner.service';
import { UnsubscribeAll } from '../shared/rx-js-helpers';
import { TemplateLocationOptions } from './experiment-template-load/models/insert-location.interface';
import { _filters } from './models/inline-filter.interface';
import { TemplateLoaderFilterChangedEvent } from './models/template-loader-filter-changed-event.interface';

type valueType = string[] | string | undefined;

@Component({
  selector: 'app-recipe-and-template-loader',
  templateUrl: './recipe-and-template-loader.component.html',
  styleUrls: ['./recipe-and-template-loader.component.scss'],
})
export class RecipeAndTemplateLoaderComponent implements OnInit, AfterViewInit {
  // Input Decorators
  @Input() loaderSearchCriteria : LoaderSearchCriteria = {};
  @Input() templateLoaderType : TemplateLoaderType = TemplateLoaderType.CreateExperiment;
  @Input() readOnly = false;
  @Input() templateLocationOptions : { id: string; name: string; type: NodeType }[] = []; //templateLocationOptions, activeTemplate
  @Input() searchControls : Array<SearchControl> = [];
  @Input() sliderSize = 'medium';
  @Input() showInlineFilters = false;
  @Input() headerTitle = $localize`:@@searchTemplate:Search Template`;
  @Input() locationRequired = true;
  @Input() insertOptions : Array<{ label: string, value: string }> = [];
  @Input() insertLocationOptions : TemplateInsertLocationOptions[] = [];
  @Input() confirmationMessage : { [key: string]: string } = {};
  @Input() loadConfirmationEvent : (args: any) => Observable<any> = function (): Observable<any> {
    return of(false);
  };
  @Input() toolTip = $localize`:@@searchTooltip:Search by Name or Number`;

  // ViewChild's
  @ViewChild('elnTemplateSlider') slider!: BptSliderComponent;
  @ViewChild('templateSearch') templateSearchControl!: BptSearchComponent;

  // Events
  @Output() templateLoaderFilterClearedEvent: EventEmitter<string> = new EventEmitter();
  favoriteIconChange = new Subject<BptIconDefinition | null>();
  advancedSearchResultsCountNotifier: Subject<number> = new Subject<number>();
  templatesSearchResults = this.templateLoaderService.templateSearchResultSource.asObservable();
  @Output() templateLoaderResult: EventEmitter<TemplateLoaderResult<SelectedTemplate>> =
    new EventEmitter<TemplateLoaderResult<SelectedTemplate>>();
  @Output() templateLoaderInsertOptionChangedEvent: EventEmitter<TemplateLocationOptions> = new EventEmitter();
  @Output() templateLoaderFilterChangedEvent: EventEmitter<TemplateLoaderFilterChangedEvent> = new EventEmitter();
  @Output() confirmationDialogEvent: EventEmitter<any> = new EventEmitter();
  @Output() templateSelectionChangedEvent: EventEmitter<SelectedTemplate> = new EventEmitter();
  @Output() templatesLoadEvent: EventEmitter<SelectedTemplateCommand> = new EventEmitter();

  // Declarations
  selectedTemplate: SelectedTemplate | undefined = undefined;
  selectedTemplateOriginValue: RecentSearch | undefined;
  templateTypesLookup: dropdownBasicList<string>[] = [];
  loading = false;
  readonly = false;
  enableLoadButton = false;
  private readonly subscriptions: Subscription[] = [];
  activityId: string | undefined;

  templateType = TemplateType.Table;
  templateLocation = '';
  selectedTemplateLocation: string | undefined;
  selectedInsertOption: string | undefined;
  selectedTemplateLocationId: string | undefined;
  selectedTemplateNumber = '';
  advancedSearchCriteria: {
    subBusinessUnitCodes: valueType;
    templateEditors: valueType;
    templateTypes: valueType;
  } = {
      subBusinessUnitCodes: [],
      templateEditors: [],
      templateTypes: []
    };

  private readonly templateTabs: SuggestionsTab[] = [
    { label: $localize`All`, filterFieldName: 'all' },
    { label: $localize`Core`, filterFieldName: 'isCore', filterFieldValue: true }
  ];

  templateSuggestionOptions: AdvancedSuggestionOptions = {
    preserveRecentSearchHistory: false,
    valueField: 'selectedValue',
    grouped: true,
    tabGroup: {
      tabs: this.templateTabs,
      headerAlignment: { horizontal: BptAlignmentTypes.Left },
      showSuggestionCount: true
    }
  } as AdvancedSuggestionOptions;

  sliderOptions = {
    disabled: false,
    readOnly: false,
    showEvents: false,
    containerStyleClass: 'containerStyleClass',
    size: 'large',
    visible: true,
    closeOnEscape: true,
    headerText: '',
    closeOptionTitle: $localize`:@@close:Close`,
    isFooterSticky: true,
    primaryButtonLabel: $localize`:@@okTitle:Load`,
    displayFooter: false,
    displayFooterWithPrimaryButton: false,
    displayFooterWithSecondaryButton: false,
  };
  errorText = '';
  recentSearchLimit = 10;

  // Server Messages, They are unused but they need be here to retain them while I18n extracts translation keys
  private static readonly TemplateNotVerified = $localize`:@@TemplateNotVerified:Selected Template should be verified.`;
  private static readonly TableAndFormNotFound = $localize`:@@TableAndFormNotFound:No Form/Table was found in selected Template.`;
  private static readonly UnVerifiedTableAndFormFound = $localize`:@@UnVerifiedTableAndFormFound:All Forms and Tables should be verified in selected Template`;
  private static readonly NoVisibleTableAndFormFound = $localize`:@@NoVisibleTableAndFormFound:At least one Form or Table should be visible in selected Template.`;
  private static readonly ModuleNotFound = $localize`:@@ModuleNotFound:No Module was found in selected Template.`;
  private static readonly UnVerifiedModuleFound = $localize`:@@UnVerifiedModuleFound:All modules should be verified in selected Template.`;
  private static readonly NoVisibleModuleFound = $localize`:@@NoVisibleModuleFound:At least one module should be visible in selected Template.`;
  private static readonly MissingModuleItems = $localize`:@@MissingModuleItems:Some module items are missing in selected Template.`

  private static readonly MatchTemplateTitle: (
    template: RecentSearch,
    searchKeyword: string
  ) => boolean = function (template: RecentSearch, searchKeyword: string) {
    return template.text.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0;
  };
  private static readonly MatchTemplateNumber: (
    template: RecentSearch,
    searchKeyword: string
  ) => boolean = function (template: RecentSearch, searchKeyword: string) {
    if (!template.groupingFieldsData) {
      return false;
    }
    return (
      (template.groupingFieldsData[TemplateListPresentationHelper.templateNumber] as string)
        .toLowerCase()
        .indexOf(searchKeyword.toLowerCase()) >= 0
    );
  };

  private static readonly MatchTemplateTechniqueANdAnalysis: (
    template: RecentSearch,
    searchKeyword: string
  ) => boolean = function (template: RecentSearch, searchKeyword: string) {
    if (!template.groupingFieldsData || !template.groupingFieldsData.templateTechniquesAndAnalysis) {
      return false;
    }
    return (
      (template.groupingFieldsData['templateTechniquesAndAnalysis'] as string)
        .toLowerCase()
        .indexOf(searchKeyword.toLowerCase()) >= 0
    );
  };

  private readonly spinnerOptions: SpinnerOptions = {
    message: $localize`:@@applyingExperiment:Applying Template...`,
    i18nMessage: `@@applyingExperiment`
  };

  constructor(
    private readonly templateLoaderService: TemplateLoaderService,
    private readonly templateEventService: TemplateEventService,
    private readonly experimentTemplateEventService: ExperimentTemplateEventService,
    public readonly userService: UserService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly changeDetector: ChangeDetectorRef,
  ) {
    //reset the outdated subscriptions to safeguard the returning instances from receiving the duplicate notifications
    this.templateLoaderService.flushSubscribers();
    this.loaderSearchCriteria.templateSearchCriteria = {getLatestVersion: true};
    this.templatesSearchResults =
      this.templateLoaderService.templateSearchResultSource.asObservable();
    this.setTemplateSearchEvents();
  }

  ngOnInit(): void {
    this.setTemplateApplyService();
    this.setTemplateSearchEvents();
    this.watchTemplateSearchResults();
    this.prepareTemplateTypesLookup();
    this.copySearchCriteriaToAdvanced();
    this.watchSpinner();
    this.sliderOptions.size = this.sliderSize;
    this.selectedInsertOption = this.insertOptions[0].value;
  }

  private watchTemplateChanges(): void {
    this.subscriptions.push(this.templateEventService.TemplateAppliedResponse.subscribe(
      (_templateAddedResponse) => {
        this.closeSlider(new Event('Template Applied in Recipe.'));
      }
    ));

    this.subscriptions.push(this.experimentTemplateEventService.TemplateAppliedResponse.subscribe(
      (_templateAddedResponse) => {
        this.closeSlider(new Event('Template Applied in Experiment'));
      }
    ));
  }

  ngAfterViewInit(): void {
    this.watchAutoCompleteSuggestionPanelShowStatus();
    this.watchAdvanceSearchCancelAction();
  }

  private watchAdvanceSearchCancelAction() {
    if (this.templateSearchControl) {
      this.templateSearchControl.getInitialData = (): Array<any> => {
        this.advancedSearchCriteria.subBusinessUnitCodes = [];
        this.advancedSearchCriteria.templateEditors = [];
        return [];
      };
    }
  }

  selectionChanged(e: RecentSearch | void) {
    this.templateLocation = '';
    if (e) {
      e.groupingFieldsData = e.groupingFieldsData ?? {};
      this.selectedTemplateOriginValue = e;
      this.selectedTemplateNumber = e.groupingFieldsData.templateNumber;
      this.selectedTemplate = {
        id: e.groupingFieldsData[TemplateListPresentationHelper.templateId],
        name: e.text,
        label: e.selectedValue as string,
        templateType: e.groupingFieldsData[TemplateListPresentationHelper.templateType],
        isRecipe: e.groupingFieldsData[TemplateListPresentationHelper.isRecipe],
        number: this.selectedTemplateNumber
      };
    } else {
      this.selectedTemplate = undefined;
    }
    this.setTemplateSelection();
    this.toggleLoadButton();
  }

  insertOptionChanged(insertOption: TemplateLocationOptions) {
    if (insertOption === TemplateLocationOptions.AddToExistingModule ||
      insertOption === TemplateLocationOptions.AddAsNewModuleAndExistingActivity) {
      this.locationRequired = true;
    } else {
      this.locationRequired = false;
    }  
    this.clearSelectedItem();  
    this.templateLoaderInsertOptionChangedEvent.emit(insertOption);
    this.toggleLoadButton();
    this.checkValidSelection(insertOption);
    this.loadInitialTemplates();
  }

  checkValidSelection(insertOption: TemplateLocationOptions) {
    if ((insertOption === TemplateLocationOptions.AddToExistingModule ||
      insertOption === TemplateLocationOptions.AddAsNewModuleAndExistingActivity) && 
      this.selectedTemplate?.templateType === TemplateType.Activity) {
        this.clearSelectedItem();
    }

    if (insertOption === TemplateLocationOptions.AddToExistingModule && this.selectedTemplate?.templateType === TemplateType.Module){
      this.clearSelectedItem();
    }

    if ((insertOption === TemplateLocationOptions.AddAsNewModule ||
      insertOption === TemplateLocationOptions.AddAsNewTableOrForm) && this.selectedTemplate?.templateType === TemplateType.Activity) {
        this.clearSelectedItem();
    }

    if (insertOption === TemplateLocationOptions.AddAsNewTableOrForm && this.selectedTemplate?.templateType === TemplateType.Module) {
        this.clearSelectedItem();
    }
  }

  modelChanged = (newValue: any) => {
    if (
      (newValue === '' || newValue === undefined) &&
      (this.templateLocation === '' || this.templateLocation === undefined)
    ) {
      return;
    }
    this.templateLocation = newValue;
    this.selectedTemplateLocation = newValue;
    this.selectedTemplateLocationId = newValue;
    this.toggleLoadButton();

    this.activityId = this.insertLocationOptions.filter(o => o.id === newValue)[0].activityId;
  };

  getSearchResultsCount = (): Observable<number> => {
    // This feature is not applicable here, hence sending it as invalid number.
    return of(-1);
  };

  // Filter template by its title and number.
  filterTemplatesBySearchKeyword = (template: RecentSearch, searchKeyword: string): boolean => {
    return (
      RecipeAndTemplateLoaderComponent.MatchTemplateTitle(template, searchKeyword) ||
      RecipeAndTemplateLoaderComponent.MatchTemplateNumber(template, searchKeyword) ||
      RecipeAndTemplateLoaderComponent.MatchTemplateTechniqueANdAnalysis(template, searchKeyword)
    );
  };

  applyFavoriteTemplatePreferences = (
    e: IconClickEvent
  ): Observable<BptIconDefinition | undefined> => {
    const acknowledgement = new Subject<BptIconDefinition | undefined>();
    if (e.iconDefinition.iconClass === BptIconDictionary.Star.iconClass) {
      this.makeTemplateAsFavorite(e, acknowledgement);
    } else {
      this.removeTemplateToFavorite(e, acknowledgement);
    }
    return acknowledgement.asObservable();
  };

  saveSearch = (): Observable<boolean> => {
    return of(true);
  };

  clear() {
    this.clearSelectedItem();
    this.loading = true;
    this.templateLoaderFilterClearedEvent.emit(this.selectedInsertOption);
    this.loadInitialTemplates();
  }

  filterTemplatesToAdvancedSearchCriteria = (
    _filters: Array<_filters>,
    text: string, //this is unused, but this is need in order to get proper filed name
    fieldName?: string
  ): Observable<number> => {
    const baseTypes = _filters.find((f: { columnName: string }) => f.columnName === 'baseType')?.values;
    const types = _filters.find((f: { columnName: string }) => f.columnName === 'recipeTypes')?.values;
    this.templateLoaderFilterChangedEvent.emit({ _filters, selectedInsertOption: this.selectedInsertOption as string, fieldName});
    this.clearSelectedItem();
    if ((baseTypes && baseTypes.length === 1 && baseTypes.includes('Template')) && types) {
      this.loadTemplatesWithFilters(_filters);
    } else if (baseTypes && baseTypes.length === 1 && baseTypes.includes('Recipe') && types) {
      this.loadRecipeWithFilters(_filters);
    } else if ((baseTypes && baseTypes.length === 2) && types) {
      this.loadRecipesAndTemplatesWithFilters(_filters);
    } else {
      this.templateLoaderService.cancelPendingCalls();
      this.loading = false;
      this.templatesSearchResults = of([]);
    }
    return this.advancedSearchResultsCountNotifier.asObservable();
  };

  setSearchCriteria(_filters: Array<_filters>) {
    const searchCriteria = { ...this.loaderSearchCriteria.templateSearchCriteria };
    searchCriteria.subBusinessUnitCodes = this.getFilterValue(_filters, 'sbu');
    searchCriteria.templateTypes =  this.getFilterValue(_filters, 'recipeTypes');
    searchCriteria.templateEditors = this.getFilterValue(_filters, 'templateEditors');
    return searchCriteria;
  }

  loadTemplatesWithFilters(_filters: Array<_filters>) {
    this.loading = true;
    const searchCriteria = this.setSearchCriteria(_filters);
    this.templateLoaderService.fetchTemplatesOnly(searchCriteria);
    this.copySearchCriteriaToAdvanced();
  }

  loadRecipeWithFilters(_filters: Array<_filters>) {
    this.loading = true;
    const recipeSearchCriteria = this.constructRecipeSearchCommandFromFilter(_filters);
    this.templateLoaderService.fetchRecipesOnly(recipeSearchCriteria);
  }

  loadRecipesAndTemplatesWithFilters(_filters: Array<_filters>) {
    this.loading = true;
    const searchCriteria = this.setSearchCriteria(_filters);
    this.clearSelectedItem();
    const recipeSearchCriteria = this.constructRecipeSearchCommandFromFilter(_filters);
    this.templateLoaderService.fetchRecipesAndTemplatesOnly(searchCriteria, recipeSearchCriteria);
    this.copySearchCriteriaToAdvanced();
  }

  getFilterValue(_filters: Array<_filters>, columnName: string) {
    const filterItem = _filters.find((f) => f.columnName === columnName);
    if (filterItem) {
      return (filterItem.values).join(',');
    } else {
      return undefined;
    }
  }

  performSliderClosingActivity(isClosed: boolean): void {
    if (!isClosed) {
      this.templateLoaderResult.next({ selectedTemplate: undefined, cancelled: true });
    }
  }

  closeSlider(event: any) {
    this.performSliderClosingActivity(false);
    this.slider.closeSlider(event);
  }

  private copySearchCriteriaToAdvanced() {
    this.templateLoaderService.leastTemplateTypesToFilter(
      (this.loaderSearchCriteria.templateSearchCriteria?.templateTypes ?? '').split(',')
    );
    this.advancedSearchCriteria.subBusinessUnitCodes =
      this.loaderSearchCriteria.templateSearchCriteria?.subBusinessUnitCodes?.split(',') ?? [];
    this.advancedSearchCriteria.templateEditors =
      this.loaderSearchCriteria.templateSearchCriteria?.templateEditors?.split(',') ?? [];
    this.advancedSearchCriteria.templateTypes = this.loaderSearchCriteria.templateSearchCriteria?.templateTypes?.split(',') ?? [];
  }

  private setTemplateSearchEvents() {
    this.templateSuggestionOptions.suggestions = () => this.templatesSearchResults;
    this.templateSuggestionOptions.typeaheadFilterPredicate = this.filterTemplatesBySearchKeyword;
    this.templateSuggestionOptions.actionIconClick = this.applyFavoriteTemplatePreferences;
  }

  private loadInitialTemplates() {
    this.loading = true;
    if(this.loaderSearchCriteria.recipeSearchCriteria && this.loaderSearchCriteria.templateSearchCriteria) {
      this.templateLoaderService.fetchRecipesAndTemplatesOnly(this.loaderSearchCriteria.templateSearchCriteria, this.loaderSearchCriteria.recipeSearchCriteria );
    }
    else if(this.loaderSearchCriteria.templateSearchCriteria) {
      this.templateLoaderService.fetchTemplatesOnly(this.loaderSearchCriteria.templateSearchCriteria);
    }
  }

  private watchTemplateSearchResults() {
    this.templateLoaderService.templateSearchResultSource.subscribe((templates) => {
      this.notifyAdvancedSearchResultCount(templates.length);
      this.showAutoCompleteSuggestions();
      this.loading = false;
    });
  }

  private notifyAdvancedSearchResultCount(count: number): void {
    if (this.advancedSearchResultsCountNotifier) {
      // let Search control fully initialize with subscription.
      setTimeout(() => {
        this.advancedSearchResultsCountNotifier.next(count);
      }, 250);
    }
  }

  private showAutoCompleteSuggestions(): void {
    // Let Autocomplete control to settle down hence 500 milli seconds should be more than enough
    setTimeout(() => {
      if (
        this.templateSearchControl &&
        this.templateSearchControl.autoComplete &&
        this.templateSearchControl.autoComplete.handleDropdownClick &&
        !this.templateSearchControl.autoComplete.overlayVisible
      ) {
        this.templateSearchControl.autoComplete.handleDropdownClick(new Event('focus'));
      }
    }, 500);
  }

  private prepareTemplateTypesLookup(): void {
    this.templateTypesLookup = TemplateListPresentationHelper.getTemplateTypeLookup(
      this.loaderSearchCriteria.templateSearchCriteria?.templateTypes
    );
  }

  private removeTemplateToFavorite(
    e: IconClickEvent,
    acknowledgement: Subject<BptIconDefinition | undefined>
  ) {
    this.templateLoaderService
      .removeFavoriteTemplate(e.iconDefinition.id as string)
      .subscribe((isSaved) => {
        if (isSaved) {
          acknowledgement.next(BptIconDictionary.Star);
        } else {
          acknowledgement.next(BptIconDictionary.StarFill);
        }
      });
  }

  private makeTemplateAsFavorite(
    e: IconClickEvent,
    acknowledgement: Subject<BptIconDefinition | undefined>
  ) {
    this.templateLoaderService.markTemplateAsFavorite(e.iconDefinition.id as string).subscribe({
      next: (isSaved) => {
        this.loading = false;
        if (isSaved) {
          acknowledgement.next(BptIconDictionary.StarFill);
        } else {
          acknowledgement.next(BptIconDictionary.Star);
        }
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  private watchAutoCompleteSuggestionPanelShowStatus(): void {
    if (this.templateSearchControl?.autoComplete) {
      this.templateSearchControl.autoComplete.onShow.subscribe(() => {
        this.hideResultsLabel();
        this.templateSearchControl.autoComplete.completeMethod.next({ query: this.templateSearchControl.autoComplete.inputEL.nativeElement.value });
      });
    }
  }
  private hideResultsLabel(): void {
    this.templateSearchControl.showResult = false;
  }

  private clearSelectedItem() {
    if (this.templateSearchControl?.autoComplete) {
      this.templateSearchControl.autoComplete.selectItem("");
    }
  }

  togglePanel() {
    const propertiesPanel = document.getElementById('new');
    if (propertiesPanel) {
      if (propertiesPanel.style.display === 'block') {
        propertiesPanel.style.display = 'none';
      } else {
        propertiesPanel.style.display = 'block';
      }
    }
  }

  constructRecipeSearchCommandFromFilter(_filters: Array<_filters>) {
    const pageSize = 10000;
    const labSiteCode = this.userService.currentUser.labSiteCode;
    let sbus: string[] | undefined = [];
    let clients: string[] | undefined = [];
    let projects: string[] | undefined = [];
    let recipeTypes: string[] | undefined = [];
    if (_filters) {
      sbus = _filters.find((f: { columnName: string }) => f.columnName === 'sbu')?.values;
      clients = _filters.find((f: { columnName: string }) => f.columnName === 'client')?.values;
      projects = _filters.find((f: { columnName: string }) => f.columnName === 'projects')?.values;
      recipeTypes = _filters.find((f: { columnName: string }) => f.columnName === 'recipeTypes')?.values;
    }
    const recipeCriteriaSearch: SearchCriteria = {
      bypassSecurity: false,
      filterConditions: [
        {
          conditionType: ConditionType.And,
          filters: [
            {
              columnName: 'state',
              matchType: StringMatchType.Not,
              text: 'cancelled',
              isSecurityFlag: true,
              dataType: DataType.String
            },
            {
              columnName: 'state',
              matchType: StringMatchType.Not,
              text: 'retired',
              isSecurityFlag: true,
              dataType: DataType.String
            },
            {
              columnName: `subBusinessUnits.code`,
              matchType: StringMatchType.In,
              values: sbus,
              isSecurityFlag: false,
              dataType: DataType.String
            },
            {
              columnName: `clients.clientId.keyword`,
              matchType: StringMatchType.In,
              values: clients,
              isSecurityFlag: false,
              dataType: DataType.String
            },
            {
              columnName: `projects.projectId.keyword`,
              matchType: StringMatchType.In,
              values: projects,
              isSecurityFlag: false,
              dataType: DataType.String
            },
            {
              columnName: `type`,
              matchType: StringMatchType.In,
              values: recipeTypes,
              isSecurityFlag: false,
              dataType: DataType.String
            }
          ]
        },
        {
          conditionType: ConditionType.Or,
          filters: [
            {
              columnName: 'labsiteCode',
              matchType: StringMatchType.Word,
              text: labSiteCode,
              isSecurityFlag: true,
              dataType: DataType.String
            },
            {
              columnName: 'consumingLabsites',
              matchType: StringMatchType.Word,
              text: labSiteCode,
              isSecurityFlag: true,
              dataType: DataType.String
            }
          ]
        }
      ],
      pagination: { pageNumber: 1, pageSize },
      sort: [
        {
          columnType: DataType.Date,
          columnName: 'createdOn',
          order: 1,
          sortDirection: SortDirection.Descending
        }
      ]
    };
    return recipeCriteriaSearch;
  }

  // Members for Adding Template to Existing Recipe
  confirmLoad() {
    this.loadConfirmationEvent(this.selectedInsertOption).subscribe((value) => {
      if (value) {
        const confirmationMessage = this.selectedInsertOption === TemplateLocationOptions.AddAsNewActivity ? this.confirmationMessage['recipeAddActivityTemplateConfirmation'] :
        this.selectedInsertOption === TemplateLocationOptions.AddAsNewModule ? this.confirmationMessage['recipeAddModuleTemplateConfirmation'] : '';
        this.confirmationDialogEvent.emit([confirmationMessage, this.load, this.close])
      } else {
        this.load();
        this.enableLoadButton = false;
      }
    })
  }

  private readonly close = () => {
    this.closeSlider(new Event('Cancel Template Load'))
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions)
  }

  private watchSpinner() {
    this.elnProgressSpinnerService.RegisterObservableToHideSpinner(
      this.experimentTemplateEventService.experimentRefreshed
    );
    this.elnProgressSpinnerService.RegisterObservableToHideSpinner(
      this.templateEventService.recipeRefreshed
    );
  }

  private readonly load = () => {
    const templateInformationToLoad: SelectedTemplateCommand = {
      selectedTemplate: this.selectedTemplate,
      selectedInsertOption: this.selectedInsertOption,
      selectedTemplateLocationId: this.selectedTemplateLocationId,
      selectedTemplateNumber: this.selectedTemplateNumber,
      insertLocationOptions: this.insertLocationOptions,
      rootActivityId: this.activityId
    }
    this.elnProgressSpinnerService.Show(this.spinnerOptions);
    this.changeDetector.detectChanges();
    this.templatesLoadEvent.emit(templateInformationToLoad);
  }

  private setTemplateApplyService() {
    if ([TemplateLoaderType.TemplateToExperiment, TemplateLoaderType.TemplateToRecipe].includes(this.templateLoaderType)) {
      this.templateEventService.TemplateApplied.subscribe(() => {
        this.slider.closeSlider(new Event('Template Applied'));
      });
      this.experimentTemplateEventService.TemplateApplied.subscribe(() => {
        this.slider.closeSlider(new Event('Template Applied'));
      });
      this.watchTemplateChanges();
    }
  }

  private setTemplateSelection(): void {
    if (
      (this.templateLoaderType !== TemplateLoaderType.TemplateToExperiment &&
        this.templateLoaderType !== TemplateLoaderType.TemplateToRecipe) ||
      !this.selectedTemplate
    ) {
      return;
    }
    this.templateSelectionChangedEvent.next(this.selectedTemplate);
  }

  private toggleLoadButton() {
    let valid = true;
    if (!this.selectedInsertOption || this.selectedInsertOption === '') {
      valid = false;
    }
    if (this.locationRequired && (!this.selectedTemplateLocation || this.selectedTemplateLocation === '')) {
      valid = false;
    }
    if (!this.selectedTemplate)
      valid = false;

    this.enableLoadButton = valid;
  }
}
