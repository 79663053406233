<div class="instrument-overlay">
  <div class="overlay-header row">
    <div class="eln-modalTitle">{{ balanceName }}</div>
    <div i18n-pTooltip="@@close" (click)="closeOverlay()"
      class="icon-x icon-m overlay-cancel">
    </div>
  </div>
  <div class="instrument-config row">
    <bpt-dropdown
      [options]="['Direct', 'Difference', 'Residual Difference']"
      [ngModel]="selectedMethod"
      (dropdownChange)="onMethodChanged($event)"></bpt-dropdown>
    <bpt-dropdown
      [options]="['g', 'mg']"
      [(ngModel)]="selectedUnit"
      (dropdownChange)="unitConversion()"></bpt-dropdown>
  </div>
  <div class="row">
    <div class="column">
      <div class="sub-column-left" *ngIf="!isToleranceInvalid">
        <label class="config-labels" [ngClass]="{'hide':!isBlowerVisible}">
          <p-checkbox [binary]="true" [(ngModel)]="blowerValue" [disabled]="!isBlowerEditable" (ngModelChange)="addBlowerBasedToleranceRange()"></p-checkbox>
          <span i18n="@@blowerOn" class="label-text" [ngClass]="{'disabled-text':!isBlowerEditable}">Blower On</span>
        </label>
      </div>
      <div *ngIf="isToleranceInvalid" class="error-div">
        <span i18n="@@toleranceInvalid"class="error-text">Invalid Tolerance Range</span>
      </div>
      <div class="sub-column-right" *ngIf="!isToleranceInvalid">
        <span i18n="@@toleranceNA"class="error-text" *ngIf="isToleranceNA">Tolerance Not Available</span>
        <span i18n="@@place5"class="place-5-and-4"  *ngIf="!isToleranceNA" [ngClass]="{'hide':!minValue4Place && !maxValue4Place}">5-Place</span>
      </div>
    </div>
    <div class="column">
      <div class="sub-column">
        <label class="config-labels">
          <span i18n="@@min" *ngIf="!isToleranceInvalid" [ngClass]="{'error-text': isToleranceNA }">Min: {{minValue5Place}}</span>
        </label>
      </div>
      <div class="sub-column">
        <label class="config-labels">
          <span i18n="@@max" *ngIf="!isToleranceInvalid" [ngClass]="{'error-text': isToleranceNA}">Max: {{maxValue5Place}}</span>
        </label>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="minValue4Place && maxValue4Place && !isToleranceNA">
    <div class="column">
      <div class="sub-column-left" *ngIf="isBlowerVisible">
        <span style="display: inline-block"></span>
      </div>
      <div class="sub-column-right">
        <span i18n="@@place4"class="place-5-and-4">4-Place</span>
      </div>
    </div>
    <div class="column">
      <div class="sub-column">
        <label class="config-labels">
          <span i18n="@@min" [ngClass]="{'error-text': isToleranceNA }">Min: {{minValue4Place}}</span>
        </label>
      </div>
      <div class="sub-column">
        <label class="config-labels">
          <span i18n="@@max" [ngClass]="{'error-text': isToleranceNA }">Max: {{maxValue4Place}}</span>
        </label>
      </div>
    </div>
  </div>
  <div *ngIf="selectedMethod === 'Direct'" class="row">
    <div class="inputBox">
      <bpt-text-input
        #directTareInput
        id="eln-directTare"
        [(ngModel)]="directTare"
        i18n-label="@@tare"
        label="Tare"
        [disabled]="false"
        (textInputFocus)="setBindingField(true)"
        (ngModelChange)="onDirectTareChange()">
        >
      </bpt-text-input>
    </div>
    <div class="row instrument-config">
      <bpt-text-input
        #directActualInput
        [(ngModel)]="directActual"
        id="eln-directActual"
        i18n-label="@@actual"
        label="Actual"
        [disabled]="false"
        (textInputFocus)="setBindingField(false)"
        (ngModelChange)="onDirectActualChange()">
      </bpt-text-input>
      <bpt-text-input
        ngClass="{{ showTarget ? '' : 'hide' }}"
        [(ngModel)]="specificationTarget"
        i18n-label="@@target"
        label="Target"
        [disabled]="true">
      </bpt-text-input>
    </div>
  </div>
  <div *ngIf="selectedMethod === 'Difference'" class="row">
    <div class="inputBox">
      <bpt-text-input
        #differencePanInput
        id="eln-differencePan"
        [(ngModel)]="differencePan"
        i18n-label="@@pan"
        label="Pan"
        [disabled]="false"
        (textInputFocus)="setBindingField(true)"
        (ngModelChange)="onDifferencePanChanged()">
      </bpt-text-input>
    </div>
    <div class="row instrument-config">
      <bpt-text-input
        #differencePanSampleInput
        id="eln-differencePanSample"
        [(ngModel)]="differencePanSample"
        i18n-label="@@panSample"
        label="Pan + Sample"
        [disabled]="false"
        (textInputFocus)="setBindingField(false)"
        (ngModelChange)="onDifferencePanSampleChanged()">
      </bpt-text-input>
      <bpt-text-input
        ngClass="{{ showTarget ? '' : 'hide' }}"
        id="eln-differencePanSampleTarget"
        [(ngModel)]="differencePanSampleTarget"
        i18n-label="@@target"
        label="Target"
        [disabled]="true">
      </bpt-text-input>
    </div>
    <div class="row instrument-config">
      <bpt-text-input
        #differenceActualInput
        id="eln-differenceActual"
        [(ngModel)]="differenceActual"
        label="Actual"
        i18n-label="@@actual"
        [disabled]="true"
        (ngModelChange)="onDifferenceActualChanged()">
      </bpt-text-input>
      <bpt-text-input
        ngClass="{{ showTarget ? '' : 'hide' }}"
        [(ngModel)]="specificationTarget"
        i18n-label="@@target"
        label="Target"
        [disabled]="true">
      </bpt-text-input>
    </div>
  </div>
  <div *ngIf="selectedMethod === 'Residual Difference'" class="row">
    <div class="inputBox">
      <bpt-text-input
        #residualDifferencePanSampleInput
        id="eln-residualDifferencePanSample"
        [(ngModel)]="residualDifferencePanSample"
        label="Pan + Sample"
        i18n-label="@@panSample"
        [disabled]="false"
        (textInputFocus)="setBindingField(true)"
        (ngModelChange)="onResidualDifferencePanSampleChanged()">
      </bpt-text-input>
    </div>
    <div class="row instrument-config">
      <bpt-text-input
        #residualDifferencePanResidualInput
        id="eln-residualDifferencePanResidual"
        [(ngModel)]="residualDifferencePanResidual"
        i18n-label="@@panResidual"
        label="Pan + Residual"
        [disabled]="false"
        (textInputFocus)="setBindingField(false)"
        (ngModelChange)="onResidualDifferencePanResidualChange()">
      </bpt-text-input>
      <bpt-text-input
        ngClass="{{ showTarget ? '' : 'hide' }}"
        [(ngModel)]="residualDifferencePanResidualTarget"
        i18n-label="@@target"
        label="Target"
        [disabled]="true">
      </bpt-text-input>
    </div>
    <div class="row instrument-config">
      <bpt-text-input
        #residualDifferenceActualInput
        id="residualDifferenceActual"
        [(ngModel)]="residualDifferenceActual"
        label="Actual"
        i18n-label="@@actual"
        [disabled]="true"
        (ngModelChange)="onResidualDifferenceActualChange()">
      </bpt-text-input>
      <bpt-text-input
        ngClass="{{ showTarget ? '' : 'hide' }}"
        id="eln-residualDifferenceTarget"
        [(ngModel)]="specificationTarget"
        label="Target"
        i18n-label="@@target"
        [disabled]="true">
      </bpt-text-input>
    </div>
  </div>

  <div class="row bottom">
    <label class="config-labels">
      <p-checkbox [binary]="true" [disabled]="sequentialReadingEnabled" [(ngModel)]="selectedPushValue"
        (ngModelChange)="getReadingsForPush()"
        i18n-label="@@pushFromBalance" label="Push from balance"></p-checkbox>
    </label>
    <span>
      <p-button
        *ngIf="isCommit"
        id="commit-overlay"
        label="Commit"
        i18n-label="@@commit"
        styleClass="bpt-button-compact p-button-outlined btn-commit"
        (onClick)="commitValue()"></p-button>
      <p-button *ngIf="!isCommit"
        #cancel
        id="close-overlay"
        label="Cancel"
        i18n-label="@@cancel"
        styleClass="bpt-button-compact p-button-outlined btn-cancel"
        (onClick)="closeOverlay()"></p-button>
      <span class="button">
        <p-button
          id="instrument-get-reading"
          i18n-label="@@getReading"
          label="Get Reading"
          styleClass="bpt-button-compact"
          [disabled]="selectedPushValue"
          (onClick)="getReadings()">
        </p-button>
      </span>
    </span>

  </div>
</div>