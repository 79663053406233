import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClientStateService } from 'services/client-state.service';
import { AccessibilityTypes } from '../app.states';

@Component({
  selector: 'app-base',
  template: '',
  styleUrls: []
})
export class BaseComponent {
  /** Subscriptions that may need to be unsubscribed from especially upon ngOnDestroy. "Active" doesn't mean anything.  */
  protected activeSubscriptions: Subscription[] = [];

  private _readOnly = false;
  protected get readOnly() {
    return this._readOnly;
  }
  @Input() protected set readOnly(value) {
    this._readOnly = value;
  }
  
  public unsavedChanges = false;
  protected clientState: string;
  constructor(
    public clientStateService: ClientStateService,
    public activatedRoute: ActivatedRoute
  ) {
    this.clientState = activatedRoute.snapshot.data?.clientState;
    if (activatedRoute.snapshot.data?.clientState) {
      if (this.clientStateService.isClientStateReadOnly) {
        this.readOnly = true;
      } else {
        if (
          this.clientStateService.getClientStateVisibility(this.clientState) ===
          AccessibilityTypes.ReadOnly
        ) {
          this.readOnly = true;
          this.clientStateService.isClientStateReadOnly = true;
        } else {
          this.clientStateService.isClientStateReadOnly = false;
        }
      }
    } else {
      this.clientStateService.isClientStateReadOnly = false;
    }
  }
}
