export enum TemplateLocationOptions {
  None = 'none',
  AddToExistingModule = 'existingModule',
  AddAsNewModuleAndExistingActivity = 'newModuleExistingActivity',
  AddAsNewActivityAndModule = 'newActivityAndModule',
  AddAsExistingActivity = 'existingActivity',
  AddAsNewActivity = 'newActivity',
  AddAsNewModule = 'newModule',
  AddAsNewTableOrForm = 'newFormOrTable'
}
