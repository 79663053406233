import { Injectable } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ValueState } from '../../api/models';
import { DataValueService } from './data-value.service';
import { BptControlSeverityIndicator, SeverityIndicatorType } from 'bpt-ui-library/shared';
import { ModifiableDataFields, TableValueRow } from '../../model/experiment.interface';

@Injectable({
  providedIn: 'root'
})
export class DataValidationsService {
  constructor(private readonly dataValueService: DataValueService) { }

  /**
   * Returns a an object of type SeverityIndicatorType which maps to a styleClass and tooltip. 
   * 
   * Any new severity-indicator icon should be added to bpt-control-severity-indicator
   * a cell changed event and computes if the class should be applied to the cell.
   * 
   * @param tableData pass in the array of rows (e.g. table.value from the experiment model)
   * @param params cell renderer params to identify the column
   */
  public getSeverityIndicatorDefinition = (data: TableValueRow[], params: ICellRendererParams): BptControlSeverityIndicator => {
    const noIndicator = { indicatorType: '' as SeverityIndicatorType };
    const row = data.find(r => r.id === params.node.data.id);
    if (!row) return { indicatorType: SeverityIndicatorType.Empty }; // row not in model yet but value is missing if incoming value is undefined or null
    const field = params.colDef?.field;
    if (!field) return noIndicator; // can't happen

    if (field === 'id') return noIndicator;

    const cellValue = row[field as keyof ModifiableDataFields];
    if (!cellValue || cellValue.value.state === ValueState.Empty) return { indicatorType: SeverityIndicatorType.Empty };

    if (cellValue.isModified) return { indicatorType: SeverityIndicatorType.Modified };
    
    return noIndicator;
  }
}
