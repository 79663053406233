import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Logger } from 'services/logger.service';
import { NotificationDetails } from '../../api/models';
import { NotificationResult } from '../../../app/api/models/notification-result';
import { ElnProgressSpinnerService } from '../../../app/eln-progress-spinner-module/eln-progress-spinner.service';

@Injectable({
  providedIn: 'root'
})
export class ExceptionInterceptor implements HttpInterceptor {
  public static readonly NotificationsToSkip: string[] = ['TemplateCannotBePublished'];
  public static readonly EndpointsToIgnoreErrors: string[] = [
    `user-preferences/eln-favorite-templates`,
    `api/empower/quarter-and-resultsets`,
    `api/empower/column-names`,
    `/instruments/`
  ];
  public static readonly StatusCodeWiseErrorsForEndPoints: { [key: string]: { [key: number]: { key: string; errorMessage: string } } } = {
    '/files/download':
    {
      0: {
        key: $localize`:@@error:Error`,
        errorMessage: $localize`:@@fileDownloadClientFailure:File download request could not be processed at the moment.Please check your network connection and try again. If the issue persists, please contact administrator.`
      },
    },
    '/files/upload': {
      504: {
        key: $localize`:@@error:Error`,
        errorMessage: $localize`:@@fileUpload504Error:Your request took too long to process.Please check your network connection and try again. If the issue persists, please contact administrator.`
      }
    }
  }

  constructor(
    private readonly messageService: MessageService,
    private readonly logger: Logger,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        //Normal flow do nothing.
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.elnProgressSpinnerService.Hide();
        this.logger.logError(error);
        if (ExceptionInterceptor.ignoreError(req)) {
          return throwError(() => error);
        }
        const errorMsg = this.getErrorMessage(error, req);
        if (errorMsg === error) {
          return throwError(error);
        }
        this.addErrorMessage(errorMsg as { key: string; errorMessage: string });
        return throwError(error);
      })
    );
  }
  private addErrorMessage(message: { key: string; errorMessage: string }) {
    this.messageService.add({
      key: 'notification',
      severity: 'error',
      summary: message.key,
      detail: message.errorMessage,
      sticky: true
    });
    document.body.click();
  }

  private getErrorMessage(
    errorResponse: HttpErrorResponse,
    request: HttpRequest<any>
  ): { key: string; errorMessage: string } | HttpErrorResponse {
    const message: { key: string; errorMessage: string } = {
      key: 'unknown',
      errorMessage: $localize`:@@defaultServerApiError:Something went wrong!. Please contact the IT administrator.`
    };
    if (errorResponse.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      message.key = 'Client Error';
      message.errorMessage = `An error occurred: ${errorResponse.error?.message}`;
      ExceptionInterceptor.setErrorWhenMessageForStatusCodeExists(message, 0, request);
    } else {
      // The backend returned an unsuccessful response code.
      //server error
      const notificationResult: NotificationResult = errorResponse?.error
        ?.notifications as NotificationResult;
      if (notificationResult && notificationResult.notifications.length > 0) {
        if (
          notificationResult.notifications.some((nt) =>
            ExceptionInterceptor.NotificationsToSkip.includes(nt.translationKey)
          )
        ) {
          return errorResponse;
        }
        message.key = $localize`:@@error:Error`;
        message.errorMessage = this.localizeNotificationMessage(
          notificationResult.notifications[0]
        );
      } else {
        message.key = $localize`:@@error:Error`;
        this.buildErrorMessage(errorResponse, request, message);
      }
    }
    return message;
  }

  private buildErrorMessage(
    errorResponse: HttpErrorResponse,
    request: HttpRequest<any>,
    message: { key: string; errorMessage: string }
  ) {
    switch (errorResponse.status) {
      case 0:
        message.errorMessage = $localize`:@@defaultServerApiError:Something went wrong!. Please contact the IT administrator.`;
        ExceptionInterceptor.setErrorWhenMessageForStatusCodeExists(message, 0, request);
        break;
      case 504:
        message.errorMessage = $localize`:@@504:Request Timeout. Please contact the IT administrator.`;
        ExceptionInterceptor.setErrorWhenMessageForStatusCodeExists(message, 504, request);
        break;
      case 400:
        message.errorMessage = $localize`:@@400:Bad Request. Please contact the IT administrator.`;
        break;
      case 401:
        message.errorMessage = $localize`:@@401:Your authorization failed. Please try refreshing the page and fill in the correct credentials.`;
        break;
      case 403:
        message.errorMessage = $localize`:@@403:You don't have permission to access the requested resource. Please contact the IT administrator.`;
        break;
      case 404:
        message.errorMessage = $localize`:@@404:The requested resource does not exist. Please contact the IT administrator.`;
        break;
      case 412:
        message.errorMessage = $localize`:@@412:You have not selected the lab site of the resource you are attempting to view. Please try again after selecting the appropriate lab site.`;
        break;
      case 500:
        if (
          errorResponse.error &&
          errorResponse.error.Message &&
          errorResponse.error.Message.includes('Schema')
        ) {
          // This works only in the lower environment
          message.errorMessage = errorResponse.error.Message;
        } else {
          const correlationId = errorResponse.error ? errorResponse.error.CorrelationId : '';
          message.key = $localize`:@@500:Error: Please contact the IT administrator.`;
          message.errorMessage = $localize`:@@correlationId:Reference Number: ${correlationId}`;
        }
        break;
      case 503:
        message.errorMessage = $localize`:@@503:The requested service is not available. Please contact the IT administrator.`;
        break;
      default:
        message.errorMessage = $localize`:@@defaultServerApiError:Something went wrong!. Please contact the IT administrator.`;
    }
  }

  private localizeNotificationMessage(notification: NotificationDetails): string {
    const localize = this.getLocalize();
    // "Any" type can not avoided here as TemplateStringArray has many members which are not needed to be supplied from our end
    const translatedMessage = localize({
      '0': `:@@${notification.translationKey}:${notification.translationKey}`,
      raw: [':']
    } as any);
    if (translatedMessage !== notification.translationKey) {
      return translatedMessage;
    }
    return notification.message;
  }

  getLocalize() {
    return $localize;
  }

  private static ignoreError(req: HttpRequest<any>): boolean {
    return ExceptionInterceptor.EndpointsToIgnoreErrors.some((url) => req.url.indexOf(url) >= 0);
  }

  private static getErrorMessageForStatusCode(statucCode: number, req: HttpRequest<any>): { key: string; errorMessage: string } | undefined {
    const endpoints = Object.keys(ExceptionInterceptor.StatusCodeWiseErrorsForEndPoints);
    const matchingEndpoint = endpoints.find(endpoint => req.url.indexOf(endpoint) >= 0);
    if (!matchingEndpoint) {
      return undefined;
    }
    return ExceptionInterceptor.StatusCodeWiseErrorsForEndPoints[matchingEndpoint][statucCode];
  }

  private static setErrorWhenMessageForStatusCodeExists(message: { key: string; errorMessage: string }, statucCode: number, request: HttpRequest<any>): void {
    const statusCodeWiseErrorMessage = ExceptionInterceptor.getErrorMessageForStatusCode(504, request);
    if (statusCodeWiseErrorMessage) {
      message.errorMessage = statusCodeWiseErrorMessage.errorMessage;
      message.key = statusCodeWiseErrorMessage.key;
    }
  }
}
