import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { BptSliderComponent } from 'bpt-ui-library/bpt-slider';
import { Subscription } from 'rxjs';
import {
  ClientFacingNote,
  ClientFacingNoteContextType,
  FieldType,
  ExperimentWorkflowState,
  User
} from '../../api/models';
import {
  ClientFacingNoteChangedEventNotification,
  ClientFacingNoteCreatedEventNotification
} from '../../api/data-entry/models';
import { ExperimentComponent } from '../experiment.component';
import { DataRecordService } from '../services/data-record.service';
import { ClientFacingNoteComponent } from './client-facing-note/client-facing-note.component';
import { UserService } from '../../../app/api/services/user.service';
import { UserService as CurrentUserService } from 'services/user.service';
import { User as CurrentUser } from 'model/user.interface';
import {
  ActivityInputClientFacingNoteContext,
  CrossReferenceClientFacingNoteContext,
  FormFieldClientFacingNoteContext,
  LabItemsClientFacingNoteContext,
  LabItemsPreparationClientFacingNoteContext,
  PreparationsClientFacingNoteContext,
  ShowClientFacingNotesEventData,
  TableCellClientFacingNoteContext
} from './client-facing-note/client-facing-note-event.model';
import { BaseComponent } from '../../../../src/app/base/base.component';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { ClientFacingNoteModel } from './client-facing-note/client-facing-note.model';
import { isEqual, omit } from 'lodash-es';
import { DataValueService } from '../services/data-value.service';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { ExperimentService } from '../services/experiment.service';
import { CommentService } from './comment.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('commentsSlider') slider!: BptSliderComponent;
  @ViewChild('commentsSlider', { read: ElementRef }) sliderElement!: ElementRef;
  @ViewChild('newComment') newCommentComponent!: ClientFacingNoteComponent;
  @ViewChildren(ClientFacingNoteComponent) childNotes!: QueryList<ClientFacingNoteComponent>;

  showNote = false;
  visible = false;
  currentUser: CurrentUser;
  private readonly subscriptions: Subscription[] = [];
  canEditExperimentInReviewStateFlag = false;
  experimentWorkFlowEventSubscription!: Subscription;

  /**
   * Context of the initial interest in notes. For example, the context of a show-notes context menu command.
   *
   * Example usages: creating a new note or setting the initial focus to an existing one.
   */
  public clientFacingNotesEventData?: ShowClientFacingNotesEventData;

  public readonly parentExperiment: ExperimentComponent;

  /**
   * Note the one candidate new note, when applicable; otherwise undefined.
   * This property is set upon the slider being opened for a context that doesn't have a note, yet.
   * It is unset upon successfully submitting the new note.
   */
  public newClientFacingNote?: ClientFacingNoteModel;

  public get clientFacingNotes() {
    return this.parentExperiment.experiment?.clientFacingNotes ?? [];
     // Note: there is no practical use of this component while there is no experiment but it is created before the experiment is loaded
  }

  public closeOnEsc = true;

  users: User[] = [];

  private loaded = false;

  public clientFacingNoteConstants: CommentType = {
    header: $localize`:@@clientFacingNoteHeader:Client-facing Notes`,
    newEntryLabel: $localize`:@@newClientFacingNote:Enter a Client-facing Note`
  };

  /**
   * Returns false if there is already a note attached to the particular node.
   * Example application: if there is then do not show the new note entry form.
   */
  public get newCommentVisible(): boolean {
    const nodeType = this.clientFacingNotesEventData?.eventContext?.contextType;
    if (!nodeType) return false;

    switch (nodeType) {
      case ClientFacingNoteContextType.TableCell: {
        const tableContext = this.newClientFacingNote?.getTableCellContext();
        if (!tableContext) return false; //can happen on load

        const currentColumn = tableContext?.columnField;
        const currentRow = tableContext?.rowId;
        const currentTable = tableContext?.tableId;
        if (currentColumn && currentRow && currentTable) {
          return !this.clientFacingNotes.some((c) => {
            const ctx = c.context as TableCellClientFacingNoteContext;
            return (
              ctx?.columnField === currentColumn &&
              ctx?.rowId === currentRow &&
              ctx?.tableId === currentTable
            );
          });
        }
        break;
      }
      case ClientFacingNoteContextType.CrossReference:
        const context = this.newClientFacingNote?.getCrossReferenceCellContext();
        if (!context) return false; //can happen on load

        return !this.clientFacingNotes.some((c) => {
          const ctx = c.context as CrossReferenceClientFacingNoteContext;
          return (
            ctx?.columnField === context.columnField &&
            ctx?.rowId === context.rowId &&
            ctx?.activityId === context.activityId
          );
        });        
      case ClientFacingNoteContextType.LabItems:
        const labItemContext = this.newClientFacingNote?.getLabItemCellContext();
        if (!labItemContext) return false; //can happen on load

        const currentColumnLabItem = labItemContext?.columnField;
        const currentRowLabItem = labItemContext?.rowId;
        const currentTableLabItem = labItemContext?.labItemId;
        const currentTableLabItemType = labItemContext?.labItemType;
        if (currentColumnLabItem && currentRowLabItem && currentTableLabItem) {
          return !this.clientFacingNotes.some((c) => {
            const ctx = c.context as LabItemsClientFacingNoteContext;
            return (
              ctx?.columnField === currentColumnLabItem &&
              ctx?.rowId === currentRowLabItem &&
              ctx?.labItemId === currentTableLabItem &&
              ctx?.labItemType === currentTableLabItemType
            );
          });
        }
        break;
        case ClientFacingNoteContextType.Preparations:
          const prepContext = this.newClientFacingNote?.getPreparationsCellContext();
          if (!prepContext) return false;
  
          const currentColumn = prepContext?.columnField;
          const currentRow = prepContext?.rowId;
          const currentActivityId = prepContext?.nodeId;
          if (currentColumn && currentRow && currentActivityId) {
            return !this.clientFacingNotes.some((c) => {
              const ctx = c.context as PreparationsClientFacingNoteContext;
              return (
                ctx?.columnField === currentColumn &&
                ctx?.rowId === currentRow &&
                ctx?.nodeId === currentActivityId
              );
            });
          }
          break;
      case ClientFacingNoteContextType.ActivityInput:
        const activityInputContext = this.newClientFacingNote?.getActivityInputContext();
        if (!activityInputContext) return false; //can happen on load

        const presentColumn = activityInputContext?.columnField;
        const presentRow = activityInputContext?.rowId;
        const presentActivityInput = activityInputContext?.activityInputId;
        if (presentColumn && presentRow && presentActivityInput) {
          return !this.clientFacingNotes.some((c) => {
            const ctx = c.context as ActivityInputClientFacingNoteContext;
            return (
              ctx?.columnField === presentColumn &&
              ctx?.rowId === presentRow &&
              ctx?.activityInputId === presentActivityInput
            );
          });
        }
        break;
      case ClientFacingNoteContextType.LabItemsPreparation:
        const lPrepContext = this.newClientFacingNote?.getLabItemPreparationsCellContext();
        if (!lPrepContext) return false;
        const labItemCurrentColumn = lPrepContext?.columnField;
        const labItemCurrentRow = lPrepContext?.rowId;
        const activityId = lPrepContext?.nodeId;
        if (labItemCurrentColumn && labItemCurrentRow && activityId) {
          return !this.clientFacingNotes.some((c) => {
            const ctx = c.context as LabItemsPreparationClientFacingNoteContext;
            return (
              ctx?.columnField === labItemCurrentColumn &&
              ctx?.rowId === labItemCurrentRow &&
              ctx?.nodeId === activityId
            );
          });
        }
        break;

      case ClientFacingNoteContextType.FormField:
        const formContext = this.newClientFacingNote?.getFormFieldContext();
        if (!formContext) return false; //can happen on load

        const formId = formContext.formId;
        const fieldIdentifier = formContext.fieldIdentifier;
        if (formId && fieldIdentifier) {
          return !this.clientFacingNotes.some((c) => {
            const ctx = c.context as FormFieldClientFacingNoteContext;
            return ctx?.formId === formId && ctx?.fieldIdentifier === fieldIdentifier;
          });
        }
        break;
    }

    return this.loaded ? !this.visible : true;
  }

  constructor(
    experiment: ExperimentComponent,
    private readonly dataRecordService: DataRecordService,
    private readonly userService: UserService,
    currentUserService: CurrentUserService,
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly dataValueService: DataValueService,
    private readonly commentsService: CommentService,
    private readonly experimentService: ExperimentService
  ) {
    super(clientStateService, activatedRoute);
    this.parentExperiment = experiment;
    this.userService = userService;
    this.currentUser = { ...currentUserService.currentUser };

    this.subscriptions.splice(
      0,
      0,
      this.dataRecordService.clientFacingNoteCreatedDataRecordReceiver.subscribe((data) =>
        this.applyClientFacingNoteCreatedEvent(data)
      ),
      this.dataRecordService.clientFacingNoteChangedDataRecordReceiver.subscribe((data) =>
        this.applyClientFacingNoteChangedEvent(data)
      ),
      this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe((data) =>
        this.setReadOnly(data.state)
      )
    );
  }

  loadUsers() {
    this.userService
      .usersActiveUsersPost$Json({ body: [] })
      .subscribe((result) => {
        this.users = result;
      });
  }

  private _userCache: { [puid: string]: User } = {};
  findUser(puid: string): User | undefined {
    const cachedUser = this._userCache[puid];
    if (cachedUser) return cachedUser;

    const foundUser = this.users.find((u) => u.puid === puid);
    if (foundUser) this._userCache[puid] = foundUser;
    return foundUser;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.parentExperiment.showComments.subscribe((args) => {
        if (args?.eventContext?.mode === 'clientFacingNotes') {
          this.showClientFacingNotes(args as ShowClientFacingNotesEventData);
        }
      })
    );
    this.updateFeatureFlags();
    this.loadUsers();
  }

  private updateFeatureFlags() {
    const featureFlags = this.clientStateService.getFeatureFlags(this.clientState);
    this.canEditExperimentInReviewStateFlag =
      featureFlags.find(
        (data) =>
          JSON.parse(data).CanEditExperimentInReviewState &&
          JSON.parse(data).CanEditExperimentInReviewState === true
      ) !== (null || undefined);
  }

  private setReadOnly(workflowState: ExperimentWorkflowState) {
    this.readOnly =
      workflowState === ExperimentWorkflowState.InReview &&
      !this.canEditExperimentInReviewStateFlag;
  }

  ngAfterViewInit(): void {
    this.loaded = true;
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  /**
   * Repopulates and shows the client-facing notes slider.
   * Focuses on the target note if any, or new note if one can be created.
   *
   * Makes it visible if it's not. Rebinds children to existing notes in the experiment.
   * @param event
   */
  showClientFacingNotes(event: ShowClientFacingNotesEventData) {
    if (!event) throw new Error('Event missing data');
    if (!this.parentExperiment.experiment)
      throw new Error('Logic Error: cannot show for experiment without experiment.');
    this.setReadOnly(this.parentExperiment.experiment?.workflowState);

    this.clientFacingNotesEventData = event;
    const nodeType = this.clientFacingNotesEventData.eventContext.contextType;

    this.newClientFacingNote = new ClientFacingNoteModel(nodeType, undefined, event.targetContext);

    this.changeDetector.detectChanges(); // create the children
    this.visible = this.showNote = true;

    const targetContext = omit(event.targetContext, ['__proto__']); // so can use isEqual
    const targetNote =
      this.childNotes
        .filter((c) => !c.isNewClientFacingNote)
        .find((c) => isEqual(targetContext, omit(c.clientFacingNote?.context, ['__proto__']))) ??
      this.newCommentComponent;
    if (targetNote) targetNote.focus();
  }

  applyClientFacingNoteCreatedEvent(data: ClientFacingNoteCreatedEventNotification): void {
    // reshape with deep cloning because there might be multiple subscribers and mutators among them.
    const value: ClientFacingNote = {
      ...data,
      content: {
        isModified: false,
        value: { ...data.content }
      },
      path: [...data.path]
    };
    const newNote = new ClientFacingNoteModel(data.contextType, value);

    // Update experiment model
    this.parentExperiment.experiment?.clientFacingNotes.unshift(newNote);

    this.publishClientFacingNoteEvent(newNote);
  }

  applyClientFacingNoteChangedEvent(data: ClientFacingNoteChangedEventNotification): void {
    // Update experiment model
    const note = this.parentExperiment.experiment?.clientFacingNotes.find(
      (n) => n.number === data.number
    );
    if (!note)
      throw new Error('ClientFacingNoteChangedEventNotification does not match any existing note');

    // only 3 properties can change
    note.lastEditedOn = data.lastEditedOn;
    note.lastEditedBy = data.lastEditedBy;
    note.content = DataRecordService.getModifiableDataValue(data.content, note.content);
    note.currentComment = this.dataValueService.getPrimitiveValue(FieldType.Textbox, note.content);

    this.publishClientFacingNoteEvent(note);
  }

  refreshCloseButtonEnablement() {
    const disabled =
      this.newClientFacingNote?.isBeingEdited ||
      this.clientFacingNotes.some((c) => c.isBeingEdited);

    const className = 'header-close-button';
    const closeButton = this.sliderElement.nativeElement.getElementsByClassName(
      className
    )[0] as HTMLButtonElement;

    if (closeButton) {
      closeButton.disabled = disabled;
      //NOTE Can CSS pseudo-class :disabled but used instead of procedural code?
      closeButton.style.opacity = disabled ? '40%' : '100%';
    }
  }

  closeSlider(message: string) {
    if (message === 'closePanel') {
      this.visible = false;
    }
  }

  publishClientFacingNoteEvent(note: ClientFacingNoteModel) {
    this.newClientFacingNote = undefined;
    if (
      !this.parentExperiment.experiment?.clientFacingNotes.find((n) => n.number === note.number)
    ) {
      this.parentExperiment.experiment?.clientFacingNotes.unshift(note);
    }

    this.experimentService.clientFacingNoteEvents.next(note);
    this.changeDetector.detectChanges();
  }
}

type CommentType = {
  header: string;
  newEntryLabel: string;
  currentText?: string;
};
