/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateCommand } from '../models/create-command';
import { UserPicklistAppendItemResponse } from '../models/user-picklist-append-item-response';
import { UserPicklistCreateResponse } from '../models/user-picklist-create-response';
import { UserPicklistResponse } from '../models/user-picklist-response';

@Injectable({
  providedIn: 'root',
})
export class UserPicklistsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userPicklistsCreatePicklistPost
   */
  static readonly UserPicklistsCreatePicklistPostPath = '/user-picklists/create-picklist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsCreatePicklistPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsCreatePicklistPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateCommand
  }): Observable<StrictHttpResponse<UserPicklistCreateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPicklistsService.UserPicklistsCreatePicklistPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPicklistCreateResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPicklistsCreatePicklistPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsCreatePicklistPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateCommand
  }): Observable<UserPicklistCreateResponse> {

    return this.userPicklistsCreatePicklistPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserPicklistCreateResponse>) => r.body as UserPicklistCreateResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsCreatePicklistPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsCreatePicklistPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateCommand
  }): Observable<StrictHttpResponse<UserPicklistCreateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPicklistsService.UserPicklistsCreatePicklistPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPicklistCreateResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPicklistsCreatePicklistPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsCreatePicklistPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateCommand
  }): Observable<UserPicklistCreateResponse> {

    return this.userPicklistsCreatePicklistPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPicklistCreateResponse>) => r.body as UserPicklistCreateResponse)
    );
  }

  /**
   * Path part for operation userPicklistsAppendItemIdPost
   */
  static readonly UserPicklistsAppendItemIdPostPath = '/user-picklists/append-item/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsAppendItemIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsAppendItemIdPost$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: any
  }): Observable<StrictHttpResponse<UserPicklistAppendItemResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPicklistsService.UserPicklistsAppendItemIdPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPicklistAppendItemResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPicklistsAppendItemIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsAppendItemIdPost$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: any
  }): Observable<UserPicklistAppendItemResponse> {

    return this.userPicklistsAppendItemIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserPicklistAppendItemResponse>) => r.body as UserPicklistAppendItemResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsAppendItemIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsAppendItemIdPost$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: any
  }): Observable<StrictHttpResponse<UserPicklistAppendItemResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPicklistsService.UserPicklistsAppendItemIdPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPicklistAppendItemResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPicklistsAppendItemIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsAppendItemIdPost$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: any
  }): Observable<UserPicklistAppendItemResponse> {

    return this.userPicklistsAppendItemIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPicklistAppendItemResponse>) => r.body as UserPicklistAppendItemResponse)
    );
  }

  /**
   * Path part for operation userPicklistsIdGet
   */
  static readonly UserPicklistsIdGetPath = '/user-picklists/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsIdGet$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<UserPicklistResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPicklistsService.UserPicklistsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPicklistResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPicklistsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsIdGet$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<UserPicklistResponse> {

    return this.userPicklistsIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserPicklistResponse>) => r.body as UserPicklistResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsIdGet$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<UserPicklistResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserPicklistsService.UserPicklistsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPicklistResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPicklistsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsIdGet$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<UserPicklistResponse> {

    return this.userPicklistsIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPicklistResponse>) => r.body as UserPicklistResponse)
    );
  }

  /**
   * Path part for operation userPicklistsGet
   */
  static readonly UserPicklistsGetPath = '/user-picklists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsGet$Plain$Response(params?: {
    availableInDesigners?: boolean;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<Array<UserPicklistResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, UserPicklistsService.UserPicklistsGetPath, 'get');
    if (params) {
      rb.query('availableInDesigners', params.availableInDesigners, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserPicklistResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPicklistsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsGet$Plain(params?: {
    availableInDesigners?: boolean;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<Array<UserPicklistResponse>> {

    return this.userPicklistsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserPicklistResponse>>) => r.body as Array<UserPicklistResponse>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsGet$Json$Response(params?: {
    availableInDesigners?: boolean;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<Array<UserPicklistResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, UserPicklistsService.UserPicklistsGetPath, 'get');
    if (params) {
      rb.query('availableInDesigners', params.availableInDesigners, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserPicklistResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPicklistsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsGet$Json(params?: {
    availableInDesigners?: boolean;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<Array<UserPicklistResponse>> {

    return this.userPicklistsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserPicklistResponse>>) => r.body as Array<UserPicklistResponse>)
    );
  }

}
