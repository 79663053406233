import { Activity, ActivityInput, Experiment, Form, Module, Table } from "model/experiment.interface";
import { ModifiableDataValue, NumberValue, FieldGroupResponse, FieldDefinitionResponse, FormItemType, CrossReference } from "../../../api/models";
import { 
  ActivityInputClientFacingNoteContext, 
  ClientFacingNoteContext, 
  CrossReferenceClientFacingNoteContext, 
  FormFieldClientFacingNoteContext, 
  LabItemsClientFacingNoteContext, 
  LabItemsPreparationClientFacingNoteContext, 
  PreparationsClientFacingNoteContext, 
  TableCellClientFacingNoteContext 
} from "./client-facing-note-event.model";
import { LabItemsConsumablesTableOptions } from "../../labItems/consumables/lab-items-consumable-table-options";
import { LabItemsMaterialTableOptions } from "../../labItems/materials/lab-items-material/lab-items-material-table-options";
import { LabItemsInstrumentTableOptions } from "../../labItems/instruments/lab-items-instrument/lab-items-instrument-table-options";
import { ClientFacingNoteContextType } from "../../../api/models/client-facing-note-context-type";
import { CrossReferencesColumns } from "../../references/cross-references/cross-references.component";
import { ActivityInputType } from "../../../api/data-entry/models";
import { ExperimentService } from '../../services/experiment.service';
import { LabItemsColumnTableOptions } from "../../labItems/columns/lab-items-column/lab-items-column-table-options";
import { PreparationConstants } from "../../../preparation/preparation-constants";

export class ContextReference {
  experiment: Experiment;
  context: ClientFacingNoteContext;
  activity?: Activity;
  module?: Module;
  table?: Table;
  form?: Form;
  activityInputId?: ActivityInput;
  crossReference?: CrossReference;

  get nodeType(): ClientFacingNoteContextType {
    if ('tableId' in this.context) return ClientFacingNoteContextType.Table;
    if ('formId' in this.context) return ClientFacingNoteContextType.Form;
    if ('activityInputId' in this.context) return ClientFacingNoteContextType.ActivityInput;
    if ('labItemId' in this.context) return ClientFacingNoteContextType.LabItems;
    if ('labItemsPreparationIdentifier' in this.context) return ClientFacingNoteContextType.LabItemsPreparation;
    if ('preparationsTableId' in this.context) return ClientFacingNoteContextType.Preparations;
    if ('activityId' in this.context && 'columnField' in this.context && 'rowId' in this.context) return ClientFacingNoteContextType.CrossReference
    return ClientFacingNoteContextType.Invalid;
  }

  get rowIndex() {
    if (this.nodeType !== ClientFacingNoteContextType.Table) return undefined;

    const tblContext = this.context as TableCellClientFacingNoteContext;
    return (
      (
        this.table?.value?.find((r) => r.id === tblContext?.rowId) as unknown as { // `as unknown` !!! See TableValueRow docs
          rowIndex: ModifiableDataValue;
        }
      ).rowIndex.value as NumberValue
    ).value;
  }

  get rowIndexForCrossReference(): string | undefined {
    if (this.nodeType !== ClientFacingNoteContextType.CrossReference) return undefined;
    
    return (this.crossReference?.rowIndex.value as NumberValue).value;
  }

  get columnLabelForCrossReference(): string | undefined {
    if (this.nodeType !== ClientFacingNoteContextType.CrossReference) return undefined;

    const xrefContext = this.context as CrossReferenceClientFacingNoteContext;
    return CrossReferencesColumns.find(c => c.field === xrefContext.columnField)?.label;
  }

  get rowIndexForActivityInput() {
    if (this.nodeType !== ClientFacingNoteContextType.ActivityInput) return undefined;

    const tblContext = this.context as ActivityInputClientFacingNoteContext;
    return tblContext?.rowId;
  }

  get rowIdForLabItem(): string | undefined {
    if (this.nodeType !== ClientFacingNoteContextType.LabItems) return undefined;

    const tblContext = this.context as LabItemsClientFacingNoteContext;
    return tblContext?.headerText ? tblContext?.headerText : tblContext?.rowId;
  }

  get rowIndexForPreparations(): string | undefined {
    if (this.nodeType !== ClientFacingNoteContextType.Preparations) return undefined;

    const prepContext = this.context as PreparationsClientFacingNoteContext;
    const activity = this.experiment.activities.find(act => act.activityId === prepContext.nodeId);
    const index = activity?.preparations.findIndex(prep => prep.preparationId === prepContext.rowId);
    return index !== undefined ? (index + 1).toString() : prepContext.rowId;
  }
  get rowIndexForLabItemsPreparations(): string | undefined {
    if (this.nodeType !== ClientFacingNoteContextType.LabItemsPreparation) return undefined;

    const labItemPrepContext = this.context as LabItemsPreparationClientFacingNoteContext;
    const activity = this.experiment.activityLabItems.find(act => act.activityLabItemNodeId === labItemPrepContext.nodeId);
    const index = activity?.preparations.findIndex(prep => prep.preparationId === labItemPrepContext.rowId);
    return index !== undefined ? (index + 1).toString() : labItemPrepContext.rowId;
  }

  get columnLabel() {
    if (this.nodeType !== ClientFacingNoteContextType.Table) return undefined;

    const tblContext = this.context as TableCellClientFacingNoteContext;
    return this.table?.columnDefinitions?.find((c) => c.field === tblContext?.columnField)?.label;
  }

  get columnLabelForActivityInput() {
    if (this.nodeType !== ClientFacingNoteContextType.ActivityInput) return undefined;

    const tblContext = this.context as ActivityInputClientFacingNoteContext;
    return tblContext.label;
  }

  get columnLabelForLabItem() {
    if (this.nodeType !== ClientFacingNoteContextType.LabItems) return undefined;
    const tblContext = this.context as LabItemsClientFacingNoteContext;
    if (tblContext.labItemType === ActivityInputType.Material) {
      return LabItemsMaterialTableOptions.ColumnDefinition[tblContext?.columnField]?.displayName;
    } else if (tblContext.labItemType === ActivityInputType.InstrumentDetails) {
      return LabItemsInstrumentTableOptions.ColumnDefinition[tblContext?.columnField]?.displayName;
    }
    else if (tblContext.labItemType === ActivityInputType.InstrumentColumn) {
      return LabItemsColumnTableOptions.ColumnDefinition[tblContext?.columnField]?.displayName;
    } else {
      return LabItemsConsumablesTableOptions.ColumnDefinition[tblContext?.columnField]?.displayName;
    }
  }

  get columnLabelForPreparations() {
    if (this.nodeType !== ClientFacingNoteContextType.Preparations) return undefined;

    const prepContext = this.context as LabItemsClientFacingNoteContext;
    return PreparationConstants.columnDisplayNames[prepContext.columnField];
  }
  get columnLabelForLabItemsPreparations() {
    if (this.nodeType !== ClientFacingNoteContextType.LabItemsPreparation) return undefined;
    const labItemPrepContext = this.context as LabItemsClientFacingNoteContext;
    return PreparationConstants.columnDisplayNames[labItemPrepContext.columnField];
  }
  get moduleLabelForLabItem() {
    if (this.nodeType !== ClientFacingNoteContextType.LabItems) return undefined;
    const tblContext = this.context as LabItemsClientFacingNoteContext;
    switch (tblContext.labItemType) {
      case ActivityInputType.Material:
        return $localize`:@@LabItemsMaterialsTableTitle:Materials`;
      case ActivityInputType.InstrumentDetails:
        return $localize`:@@LabItemsInstrumentsTableTitle:Instruments`;
      case ActivityInputType.Consumable:
        return $localize`:@@LabItemsConsumablesTableTitle:Consumables and Supplies `;
      case ActivityInputType.InstrumentColumn:
        return $localize`:@@LabItemsColumnTitle:Columns `
      default:
        return '';
    }
  }

  private _formLabel?: string;
  get formLabel() {
    if (this._formLabel) return this._formLabel; // Assumes that labels can't be change. Future may bring rules that can change labels.
    if (this.nodeType !== ClientFacingNoteContextType.Form) return undefined;

    const frmContext = this.context as FormFieldClientFacingNoteContext;
    const targetField = this.findFieldDefinition(
      this.form?.fieldDefinitions,
      frmContext.fieldIdentifier
    );

    this._formLabel = targetField?.label;
    return this._formLabel;
  }

  constructor(experiment: Experiment, context: ClientFacingNoteContext, 
    private readonly experimentService: ExperimentService) {
    this.experiment = experiment;
    this.context = context;

    switch (this.nodeType) {
      case ClientFacingNoteContextType.CrossReference:
        const crossReferenceInputContext = context as CrossReferenceClientFacingNoteContext;
        this.activity = this.experiment.activities.find((a) => a.activityId === crossReferenceInputContext.activityId);
        this.crossReference = this.activity?.activityReferences.crossReferences.find(cr => cr.id === crossReferenceInputContext.rowId);
        break;
      case ClientFacingNoteContextType.ActivityInput:
        const activityInputContext = context as ActivityInputClientFacingNoteContext;
        this.activity = this.experiment.activities.find(
          (a) => a.activityId === activityInputContext.activityId
        );
        this.experiment.activityInputs?.forEach((a) => {
          const tblContext = this.context as TableCellClientFacingNoteContext;
          const aliquotId = a.aliquots.find(
            (t) => t.sampleNumber || t.aliquotNumber === tblContext?.rowId
          );
          if (aliquotId) {
            this.activityInputId = aliquotId as any;
          }
        });
        break;
      case ClientFacingNoteContextType.LabItems:
        this.defineLabItems(context);
        break;
      case ClientFacingNoteContextType.Preparations:
        const preparationsContext = context as PreparationsClientFacingNoteContext;
        this.activity = this.experiment.activities.find(
          (a) => a.activityId === preparationsContext.nodeId
        );
        break;
      case ClientFacingNoteContextType.LabItemsPreparation:
        const labItemsPreparationsContext = context as LabItemsPreparationClientFacingNoteContext;
        this.activity = this.experiment.activities.find(
          (a) => a.activityId === labItemsPreparationsContext.nodeId
        );
        break;
      case ClientFacingNoteContextType.Table:
        this.constructForTable();
        break;
      case ClientFacingNoteContextType.Form:
        const frmContext = context as FormFieldClientFacingNoteContext;
        this.experiment.activities.forEach((a) => {
          a.dataModules.forEach((m) => {
            const f = m.items.find((f) => (f as Form).formId === frmContext?.formId) as Form;
            if (f) {
              this.activity = a;
              this.module = m;
              this.form = f;
            }
          });
        });
        break;
    }
  }

  constructForTable() {
    const tblContext = this.context as TableCellClientFacingNoteContext;
    this.experiment.activities.forEach((a) => {
      a.dataModules.forEach((m) => {
        const t = m.items.find((t) => (t as Table).tableId === tblContext?.tableId) as Table;
        if (t) {
          this.activity = a;
          this.module = m;
          this.table = t;
        }
      });
    });
    if (this.table) return;
    this.table = this.experimentService.getTable(tblContext.tableId);
    this.activity = this.experiment.activities.find((a) => 
      a.activityReferences.compendiaReferencesTableId === this.table?.tableId || 
      a.activityReferences.documentReferencesTableId === this.table?.tableId);
  }

  // DANGER: This is recursive! Use with extreme caution.
  private findFieldDefinition(
    formItems: (FieldGroupResponse | FieldDefinitionResponse)[] | undefined,
    targetField: string
  ): FieldDefinitionResponse | undefined {
    if (!formItems) return undefined;

    for (const item of formItems) {
      if (item.itemType === FormItemType.FieldGroup) {
        const fieldGroup = item as FieldGroupResponse;
        const foundSubItem = this.findFieldDefinition(fieldGroup.fieldDefinitions, targetField);
        if (foundSubItem) return foundSubItem;
      } else if (item.field === targetField) {
        return item as FieldDefinitionResponse;
      }
    }
    return undefined;
  }

  private defineLabItems(context: ClientFacingNoteContext): void {
    const tblContext = context as LabItemsClientFacingNoteContext;
    this.activity = this.experiment.activities.find((a) => a.activityId === tblContext.labItemId);
  }
}
