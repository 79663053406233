<div *ngIf="form && !form.isHidden" class="module-item module-form">
  <bpt-progress-spinner
    i18n-message="@@loadingHistory"
    message="History Loading..."
    [useOverlay]="true"
    *ngIf="isLoading"></bpt-progress-spinner>
  <div class="grid">
    <div class="col-10 font-bold eln-form-contextMenu flex flex-row gap-3 align-items-center">
      <p-contextMenu #cmForFormTitleLevel [model]="items" appendTo="body"></p-contextMenu>
      <div class="fetchid bpt-eln-node-inline-editing-input-size minimum-height
      flex align-items-center" (contextmenu)="cmForFormTitleLevel.show($event);">
        <span id="{{form.formId}}-title" *ngIf="!retitleEnabled"
          class="eln-form-title" [innerText]="form.itemTitle"
          (dblclick)="retitleEnabled = true"></span>
        <div class="bpt-eln-inline-control">
          <app-experiment-node-title-changer
            *ngIf="retitleEnabled"
            [nodeId]="form.formId"
            [parentNodeId]="parentNodeId"
            [nodeType]="nodeType.Form"
            [currentTitle]="form.itemTitle"
            (titleChangeHasCancelled)="retitleEnabled = false;"
            (titleChanged)="form.itemTitle = $event.title;retitleEnabled = false">
          </app-experiment-node-title-changer>
        </div>
      </div>
      <app-completion-tracking *ngIf="!isOnOutputsPage" [completionPercent]=completionPercent></app-completion-tracking>
    </div>
    <div class="col-2 text-right m-auto">
      <a
        class="audit-Icon"
        (click)="loadDialog()"
        [tabindex]="greatestTabOrder === 0 ? 1 : greatestTabOrder - 1">
        <span
          i18n-pTooltip="@@viewHistory"
          class="fa fa-history"
          pTooltip="View History"
          tooltipPosition="left"><span class="ml-1 audit" i18n="@@History">History</span></span>
      </a>
    </div>
  </div>
  <div class="module-body">
    <div class="module-form-body" *ngFor="let field of form.fieldDefinitions">
      <ng-container [ngSwitch]="field.itemType">
        <app-data-field-group
          *ngSwitchCase="'fieldGroup'"
          [group]="asFieldGroup(field)"
          [value]="form.value[field.field] ?? {}"
          [experimentId]="form.experimentId"
          [formId]="form.formId"
          [path]="[field.field]"
          [parentNodeId]="parentNodeId"
          [formEvents]="formNotifications"
          (fieldChangedEvent)="applyFieldChangedEvent($event)"
          (fieldReady)="applyFieldReadyEvent($event)"
          [greatestTabOrder]="greatestTabOrder"></app-data-field-group>
        <app-data-field
          *ngSwitchDefault
          [fieldDefinition]="asFieldDefinition(field)"
          [value]="form.value[field.field]"
          [experimentId]="form.experimentId"
          [formId]="form.formId"
          [path]="[field.field]"
          [parentNodeId]="parentNodeId"
          [formEvents]="formNotifications"
          (fieldChangedEvent)="applyFieldChangedEvent($event)"
          (fieldReady)="applyFieldReadyEvent($event)"
          [greatestTabOrder]="greatestTabOrder"></app-data-field>
      </ng-container>
    </div>
  </div>
</div>