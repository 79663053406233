/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RecipeAddColumnPromptCommand } from '../models/recipe-add-column-prompt-command';
import { RecipeAddConsumableSupplyPromptCommand } from '../models/recipe-add-consumable-supply-prompt-command';
import { RecipeAddInstrumentPromptCommand } from '../models/recipe-add-instrument-prompt-command';
import { RecipeAddMaterialPromptCommand } from '../models/recipe-add-material-prompt-command';
import { RecipeAddPreparationPromptCommand } from '../models/recipe-add-preparation-prompt-command';
import { RecipeColumnPromptAddedResponse } from '../models/recipe-column-prompt-added-response';
import { RecipeConsumableSupplyPromptAddedResponse } from '../models/recipe-consumable-supply-prompt-added-response';
import { RecipeEditPromptCommand } from '../models/recipe-edit-prompt-command';
import { RecipeInstrumentPromptAddedResponse } from '../models/recipe-instrument-prompt-added-response';
import { RecipeMaterialPromptAddedResponse } from '../models/recipe-material-prompt-added-response';
import { RecipePreparationPromptAddedResponse } from '../models/recipe-preparation-prompt-added-response';
import { RecipePromptEditResponse } from '../models/recipe-prompt-edit-response';
import { RecipePromptRemovedResponse } from '../models/recipe-prompt-removed-response';
import { RecipePromptRestoredResponse } from '../models/recipe-prompt-restored-response';
import { RecipeRemovePromptCommand } from '../models/recipe-remove-prompt-command';
import { RecipeRestorePromptCommand } from '../models/recipe-restore-prompt-command';

@Injectable({
  providedIn: 'root',
})
export class PromptService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation promptsCreateMaterialPost
   */
  static readonly PromptsCreateMaterialPostPath = '/prompts/create-material';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateMaterialPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateMaterialPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddMaterialPromptCommand
  }): Observable<StrictHttpResponse<RecipeMaterialPromptAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsCreateMaterialPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeMaterialPromptAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsCreateMaterialPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateMaterialPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddMaterialPromptCommand
  }): Observable<RecipeMaterialPromptAddedResponse> {

    return this.promptsCreateMaterialPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeMaterialPromptAddedResponse>) => r.body as RecipeMaterialPromptAddedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateMaterialPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateMaterialPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddMaterialPromptCommand
  }): Observable<StrictHttpResponse<RecipeMaterialPromptAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsCreateMaterialPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeMaterialPromptAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsCreateMaterialPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateMaterialPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddMaterialPromptCommand
  }): Observable<RecipeMaterialPromptAddedResponse> {

    return this.promptsCreateMaterialPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeMaterialPromptAddedResponse>) => r.body as RecipeMaterialPromptAddedResponse)
    );
  }

  /**
   * Path part for operation promptsCreateColumnPost
   */
  static readonly PromptsCreateColumnPostPath = '/prompts/create-column';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateColumnPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateColumnPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddColumnPromptCommand
  }): Observable<StrictHttpResponse<RecipeColumnPromptAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsCreateColumnPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeColumnPromptAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsCreateColumnPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateColumnPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddColumnPromptCommand
  }): Observable<RecipeColumnPromptAddedResponse> {

    return this.promptsCreateColumnPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeColumnPromptAddedResponse>) => r.body as RecipeColumnPromptAddedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateColumnPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateColumnPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddColumnPromptCommand
  }): Observable<StrictHttpResponse<RecipeColumnPromptAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsCreateColumnPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeColumnPromptAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsCreateColumnPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateColumnPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddColumnPromptCommand
  }): Observable<RecipeColumnPromptAddedResponse> {

    return this.promptsCreateColumnPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeColumnPromptAddedResponse>) => r.body as RecipeColumnPromptAddedResponse)
    );
  }

  /**
   * Path part for operation promptsCreatePreparationPost
   */
  static readonly PromptsCreatePreparationPostPath = '/prompts/create-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreatePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreatePreparationPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddPreparationPromptCommand
  }): Observable<StrictHttpResponse<RecipePreparationPromptAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsCreatePreparationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipePreparationPromptAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsCreatePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreatePreparationPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddPreparationPromptCommand
  }): Observable<RecipePreparationPromptAddedResponse> {

    return this.promptsCreatePreparationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipePreparationPromptAddedResponse>) => r.body as RecipePreparationPromptAddedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreatePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreatePreparationPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddPreparationPromptCommand
  }): Observable<StrictHttpResponse<RecipePreparationPromptAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsCreatePreparationPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipePreparationPromptAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsCreatePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreatePreparationPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddPreparationPromptCommand
  }): Observable<RecipePreparationPromptAddedResponse> {

    return this.promptsCreatePreparationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipePreparationPromptAddedResponse>) => r.body as RecipePreparationPromptAddedResponse)
    );
  }

  /**
   * Path part for operation promptsCreateInstrumentPost
   */
  static readonly PromptsCreateInstrumentPostPath = '/prompts/create-instrument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateInstrumentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateInstrumentPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddInstrumentPromptCommand
  }): Observable<StrictHttpResponse<RecipeInstrumentPromptAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsCreateInstrumentPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeInstrumentPromptAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsCreateInstrumentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateInstrumentPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddInstrumentPromptCommand
  }): Observable<RecipeInstrumentPromptAddedResponse> {

    return this.promptsCreateInstrumentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeInstrumentPromptAddedResponse>) => r.body as RecipeInstrumentPromptAddedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateInstrumentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateInstrumentPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddInstrumentPromptCommand
  }): Observable<StrictHttpResponse<RecipeInstrumentPromptAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsCreateInstrumentPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeInstrumentPromptAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsCreateInstrumentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateInstrumentPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddInstrumentPromptCommand
  }): Observable<RecipeInstrumentPromptAddedResponse> {

    return this.promptsCreateInstrumentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeInstrumentPromptAddedResponse>) => r.body as RecipeInstrumentPromptAddedResponse)
    );
  }

  /**
   * Path part for operation promptsCreateConsumableSupplyPost
   */
  static readonly PromptsCreateConsumableSupplyPostPath = '/prompts/create-consumable-supply';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateConsumableSupplyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateConsumableSupplyPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddConsumableSupplyPromptCommand
  }): Observable<StrictHttpResponse<RecipeConsumableSupplyPromptAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsCreateConsumableSupplyPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeConsumableSupplyPromptAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsCreateConsumableSupplyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateConsumableSupplyPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddConsumableSupplyPromptCommand
  }): Observable<RecipeConsumableSupplyPromptAddedResponse> {

    return this.promptsCreateConsumableSupplyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeConsumableSupplyPromptAddedResponse>) => r.body as RecipeConsumableSupplyPromptAddedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateConsumableSupplyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateConsumableSupplyPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddConsumableSupplyPromptCommand
  }): Observable<StrictHttpResponse<RecipeConsumableSupplyPromptAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsCreateConsumableSupplyPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipeConsumableSupplyPromptAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsCreateConsumableSupplyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateConsumableSupplyPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeAddConsumableSupplyPromptCommand
  }): Observable<RecipeConsumableSupplyPromptAddedResponse> {

    return this.promptsCreateConsumableSupplyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipeConsumableSupplyPromptAddedResponse>) => r.body as RecipeConsumableSupplyPromptAddedResponse)
    );
  }

  /**
   * Path part for operation promptsEditRecipePromptPost
   */
  static readonly PromptsEditRecipePromptPostPath = '/prompts/edit-recipe-prompt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsEditRecipePromptPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsEditRecipePromptPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeEditPromptCommand
  }): Observable<StrictHttpResponse<RecipePromptEditResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsEditRecipePromptPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipePromptEditResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsEditRecipePromptPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsEditRecipePromptPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeEditPromptCommand
  }): Observable<RecipePromptEditResponse> {

    return this.promptsEditRecipePromptPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipePromptEditResponse>) => r.body as RecipePromptEditResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsEditRecipePromptPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsEditRecipePromptPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeEditPromptCommand
  }): Observable<StrictHttpResponse<RecipePromptEditResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsEditRecipePromptPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipePromptEditResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsEditRecipePromptPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsEditRecipePromptPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeEditPromptCommand
  }): Observable<RecipePromptEditResponse> {

    return this.promptsEditRecipePromptPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipePromptEditResponse>) => r.body as RecipePromptEditResponse)
    );
  }

  /**
   * Path part for operation promptsRemoveRecipePromptPost
   */
  static readonly PromptsRemoveRecipePromptPostPath = '/prompts/remove-recipe-prompt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsRemoveRecipePromptPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRemoveRecipePromptPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeRemovePromptCommand
  }): Observable<StrictHttpResponse<RecipePromptRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsRemoveRecipePromptPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipePromptRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsRemoveRecipePromptPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRemoveRecipePromptPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeRemovePromptCommand
  }): Observable<RecipePromptRemovedResponse> {

    return this.promptsRemoveRecipePromptPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipePromptRemovedResponse>) => r.body as RecipePromptRemovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsRemoveRecipePromptPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRemoveRecipePromptPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeRemovePromptCommand
  }): Observable<StrictHttpResponse<RecipePromptRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsRemoveRecipePromptPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipePromptRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsRemoveRecipePromptPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRemoveRecipePromptPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeRemovePromptCommand
  }): Observable<RecipePromptRemovedResponse> {

    return this.promptsRemoveRecipePromptPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipePromptRemovedResponse>) => r.body as RecipePromptRemovedResponse)
    );
  }

  /**
   * Path part for operation promptsRestoreRecipePromptPost
   */
  static readonly PromptsRestoreRecipePromptPostPath = '/prompts/restore-recipe-prompt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsRestoreRecipePromptPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRestoreRecipePromptPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeRestorePromptCommand
  }): Observable<StrictHttpResponse<RecipePromptRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsRestoreRecipePromptPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipePromptRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsRestoreRecipePromptPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRestoreRecipePromptPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeRestorePromptCommand
  }): Observable<RecipePromptRestoredResponse> {

    return this.promptsRestoreRecipePromptPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RecipePromptRestoredResponse>) => r.body as RecipePromptRestoredResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsRestoreRecipePromptPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRestoreRecipePromptPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeRestorePromptCommand
  }): Observable<StrictHttpResponse<RecipePromptRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromptService.PromptsRestoreRecipePromptPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecipePromptRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promptsRestoreRecipePromptPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRestoreRecipePromptPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: RecipeRestorePromptCommand
  }): Observable<RecipePromptRestoredResponse> {

    return this.promptsRestoreRecipePromptPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RecipePromptRestoredResponse>) => r.body as RecipePromptRestoredResponse)
    );
  }

}
