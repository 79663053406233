/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangeClientFacingNoteResponse } from '../models/change-client-facing-note-response';
import { ClientFacingNoteContextType } from '../models/client-facing-note-context-type';
import { CreateClientFacingNoteResponse } from '../models/create-client-facing-note-response';
import { StringValue } from '../models/string-value';

@Injectable({
  providedIn: 'root',
})
export class ClientFacingNoteService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost
   */
  static readonly ClientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePostPath = '/client-facing-note-events/{experimentId}/{contextType}/{nodeId}/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain$Response(params: {
    experimentId: string;
    contextType: ClientFacingNoteContextType;
    nodeId: string;
    path?: Array<string>;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: StringValue
  }): Observable<StrictHttpResponse<CreateClientFacingNoteResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClientFacingNoteService.ClientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('contextType', params.contextType, {});
      rb.path('nodeId', params.nodeId, {});
      rb.query('path', params.path, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateClientFacingNoteResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain(params: {
    experimentId: string;
    contextType: ClientFacingNoteContextType;
    nodeId: string;
    path?: Array<string>;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: StringValue
  }): Observable<CreateClientFacingNoteResponse> {

    return this.clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreateClientFacingNoteResponse>) => r.body as CreateClientFacingNoteResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json$Response(params: {
    experimentId: string;
    contextType: ClientFacingNoteContextType;
    nodeId: string;
    path?: Array<string>;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: StringValue
  }): Observable<StrictHttpResponse<CreateClientFacingNoteResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClientFacingNoteService.ClientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('contextType', params.contextType, {});
      rb.path('nodeId', params.nodeId, {});
      rb.query('path', params.path, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateClientFacingNoteResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json(params: {
    experimentId: string;
    contextType: ClientFacingNoteContextType;
    nodeId: string;
    path?: Array<string>;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: StringValue
  }): Observable<CreateClientFacingNoteResponse> {

    return this.clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreateClientFacingNoteResponse>) => r.body as CreateClientFacingNoteResponse)
    );
  }

  /**
   * Path part for operation clientFacingNoteEventsExperimentIdNodeIdNumberChangePost
   */
  static readonly ClientFacingNoteEventsExperimentIdNodeIdNumberChangePostPath = '/client-facing-note-events/{experimentId}/{nodeId}/{number}/change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain$Response(params: {
    experimentId: string;
    nodeId: string;
    number: number;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: StringValue
  }): Observable<StrictHttpResponse<ChangeClientFacingNoteResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClientFacingNoteService.ClientFacingNoteEventsExperimentIdNodeIdNumberChangePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('nodeId', params.nodeId, {});
      rb.path('number', params.number, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeClientFacingNoteResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain(params: {
    experimentId: string;
    nodeId: string;
    number: number;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: StringValue
  }): Observable<ChangeClientFacingNoteResponse> {

    return this.clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeClientFacingNoteResponse>) => r.body as ChangeClientFacingNoteResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json$Response(params: {
    experimentId: string;
    nodeId: string;
    number: number;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: StringValue
  }): Observable<StrictHttpResponse<ChangeClientFacingNoteResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClientFacingNoteService.ClientFacingNoteEventsExperimentIdNodeIdNumberChangePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.path('nodeId', params.nodeId, {});
      rb.path('number', params.number, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeClientFacingNoteResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json(params: {
    experimentId: string;
    nodeId: string;
    number: number;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: StringValue
  }): Observable<ChangeClientFacingNoteResponse> {

    return this.clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeClientFacingNoteResponse>) => r.body as ChangeClientFacingNoteResponse)
    );
  }

}
