/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmpowerDatabaseResponse } from '../models/empower-database-response';
import { SaveResultSetColumnRequest } from '../models/save-result-set-column-request';
import { SaveResultSetColumnResponse } from '../models/save-result-set-column-response';

@Injectable({
  providedIn: 'root',
})
export class ChromatographyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation chromatographyEmpowerDatabasesGet
   */
  static readonly ChromatographyEmpowerDatabasesGetPath = '/chromatography/empower-databases';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chromatographyEmpowerDatabasesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  chromatographyEmpowerDatabasesGet$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<EmpowerDatabaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ChromatographyService.ChromatographyEmpowerDatabasesGetPath, 'get');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmpowerDatabaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chromatographyEmpowerDatabasesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chromatographyEmpowerDatabasesGet$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<EmpowerDatabaseResponse> {

    return this.chromatographyEmpowerDatabasesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmpowerDatabaseResponse>) => r.body as EmpowerDatabaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chromatographyEmpowerDatabasesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  chromatographyEmpowerDatabasesGet$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<EmpowerDatabaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ChromatographyService.ChromatographyEmpowerDatabasesGetPath, 'get');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmpowerDatabaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chromatographyEmpowerDatabasesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chromatographyEmpowerDatabasesGet$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<EmpowerDatabaseResponse> {

    return this.chromatographyEmpowerDatabasesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmpowerDatabaseResponse>) => r.body as EmpowerDatabaseResponse)
    );
  }

  /**
   * Path part for operation chromatographySaveResultsetColumnsPost
   */
  static readonly ChromatographySaveResultsetColumnsPostPath = '/chromatography/save-resultset-columns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chromatographySaveResultsetColumnsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographySaveResultsetColumnsPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveResultSetColumnRequest
  }): Observable<StrictHttpResponse<SaveResultSetColumnResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ChromatographyService.ChromatographySaveResultsetColumnsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SaveResultSetColumnResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chromatographySaveResultsetColumnsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographySaveResultsetColumnsPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveResultSetColumnRequest
  }): Observable<SaveResultSetColumnResponse> {

    return this.chromatographySaveResultsetColumnsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SaveResultSetColumnResponse>) => r.body as SaveResultSetColumnResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chromatographySaveResultsetColumnsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographySaveResultsetColumnsPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveResultSetColumnRequest
  }): Observable<StrictHttpResponse<SaveResultSetColumnResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ChromatographyService.ChromatographySaveResultsetColumnsPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SaveResultSetColumnResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chromatographySaveResultsetColumnsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographySaveResultsetColumnsPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveResultSetColumnRequest
  }): Observable<SaveResultSetColumnResponse> {

    return this.chromatographySaveResultsetColumnsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SaveResultSetColumnResponse>) => r.body as SaveResultSetColumnResponse)
    );
  }

}
