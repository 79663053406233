  <div class="eln-lab-items-data eln-table-theme secondary">  
      <div class="table-title title-absolute">
        <span class="eln-module-tab-header">{{ titleOfTable }}</span>
    </div>
    <div class="preparation-grid-layout" *ngIf="showGrid">
      <bpt-grid  #labItemPreparationsGrid
        class="eln-grid eln-preparation-grid"
        [columnDefinitions]="columnDefinitions"
        [includeRowNumberColumn]="gridOptions.includeRowNumberColumn"
        [showAutoSizeButton]="gridOptions.showAutoSizeButton"
        [showFilterToggleButton]="gridOptions.showFilterToggleButton"
        [showGridOptionsButton]="gridOptions.showGridOptionsButton"
        [allowRowAdd]="false"
        (gridReady)="onGridReady()"
        [dataSource]="primitiveValue"
        [gridMode]="gridOptions.gridMode"
        [rowActionConfigurations]="gridActions"
        [contextMenu]="getContextMenu()">
        [ianaTimeZone]="labSiteTimeZone">
        <ng-container class="bpt-grid-toolbar-right-layout">
          <a
            class="bpt-grid-toolbar-item table-grid-toolbar-audit-icon"
            align="right"
            id="eln-iconAuditHistoryShow-{{ labItemsPreparationId }}"
            (click)="loadAuditHistoryDialog()">
            <span
              i18n-pTooltip="@@viewHistory"
              class="fa fa-history"
              pTooltip="View History"
              tooltipPosition="top"><span class="ml-1 audit" i18n="@@History">History</span></span>
          </a>
          <a 
            *ngIf="containsRemovedRows"
            class="bpt-grid-toolbar-item table-grid-toolbar-view-removed-rows-icon reference-remove-icon ml-3"
             align="right"
             id="eln-iconViewRemovedRowsShow-{{ labItemsPreparationId }}"
             (click)="loadRemovedRowsDialog()">
              <span
                i18n-pTooltip="@@viewRemovedRows"
                class="fa icon-view-row"
                pTooltip="View Removed Rows"
                tooltipPosition="top">
                <span class="ml-1 viewRemovedRows-iconTxt" i18n="@@viewRemovedRows">View Removed Rows</span>
              </span>
          </a>
      </ng-container>
      </bpt-grid>
  </div>
<app-preparation-internal-information
  *ngIf="showInternalInfoSlider"
  [showSlider]="showInternalInfoSlider"
  [preparationData]="preparationDataForInternalInformation"
  [isReadOnlySlider]='true'
  (closeSlider)="closeInternalInfoSlider($event)">
</app-preparation-internal-information>
</div>