import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { BaseComponent } from '../../../../base/base.component';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';
import { BehaviorSubject, Subject } from 'rxjs';
import { ExperimentService } from '../../../services/experiment.service';
import { Activity, Table, TableValueRow } from '../../../../model/experiment.interface';
import { TableDataService } from '../table-data.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ColumnSpecification } from '../../../../api/models';

export type RemovedRowsDialogConfigData = {
  getPrimitiveDataValueRows: (rows: TableValueRow[], columns: ColumnSpecification[]) => any[];
  data: Table,
  /** Handle for row removal from this dialog */
  restoreRow: (rowId: string) => void,
  /** Announces row with rowId restored from outside the dialog (e.g., via collaborative editing [not implemented in recipes today]) */
  rowRestored: Subject<string>,
};

/** 
 * UI for a generic presentation of removed rows and affordance for restoration of rows. Designed for use as a dialog.
 * Listens for external restoration of rows.
 * Requires a column of of type rowId. Does not display any ~rowSelected~ column.
 */
@Component({
  selector: 'app-removed-rows',
  templateUrl: './removed-rows.component.html',
  styleUrls: ['./removed-rows.component.scss']
})
export class RemovedRowsComponent<T extends RemovedRowsDialogConfigData> extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() subHeading = $localize`:@@howToRestoreRows:Please click on the restore icon from the table to restore removed rows`;
  @ViewChild('grid') grid!: BptGridComponent;

  dataSource: { [key: string]: any }[] = [];
  gridMode = BptGridMode.dataView;
  private readonly configData!: T;
  get tableTitle(): string { return $localize`:@@removedRowsModalHeader:Removed Rows for Table: ${this.configData.data.itemTitle}`; }

  get columnDefinitions(): ColumnDefinition[] {
    return ((this.configData.data.columnDefinitions ?? []) as ColumnDefinition[]).filter((c) => c.field !== '~rowSelected~');
  }

  get currentActivity(): Activity | undefined {
    return this.experimentService.currentActivity;
  }

  get gridActions(): BptGridRowActionConfiguration {
    const rowActions = this.rowActionItems;
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);

    return { actions: actionsSubject };
  }

  get rowActionItems(): BptRowActionElement[] {
    return [
      {
        id: 'bpt-table-restore-row',
        enabled: true,
        styleClass: 'icon-s icon-restore-icon',
        click: this.rowRestoreActionClick.bind(this),
        tooltip: $localize`:@@restoreRow:Restore row`
      }
    ];
  }

  constructor(
    public readonly activatedRoute: ActivatedRoute,
    public readonly clientStateService: ClientStateService,
    config: DynamicDialogConfig<T>,
    private readonly experimentService: ExperimentService,
  ) {
    super(clientStateService, activatedRoute);
    if (!config.data) throw new Error('LOGIC ERROR: RemovedRowsComponent expects config data');
    this.configData = config.data;
  }

  ngOnInit(): void {
    this.watchRestoredNotification();
  }

  ngAfterViewInit(): void {
    this.refreshDataSource();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }

  private refreshDataSource(): void {
    const rows = this.configData.data.value ?? [];
    const removedRows = rows.filter(r => TableDataService.rowIsRemoved(r));
    this.dataSource = this.configData.getPrimitiveDataValueRows(removedRows, this.configData.data.columnDefinitions as ColumnSpecification[]);
    // Apparently it's not enough to set the property the grid's dataSource is bound to, we have to call the API for some reason
    this.grid?.gridApi?.setRowData(this.dataSource);
  }

  private watchRestoredNotification() {
    this.activeSubscriptions.push(
      this.configData.rowRestored.subscribe({
        next: () => this.refreshDataSource() // don't care which row; assumes data source is already updated.
      })
    );
  }

  private rowRestoreActionClick(e: BptGridRowActionClickEvent) {
    this.configData.restoreRow(e.params.data['id']);
  }
}
