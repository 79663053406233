export enum MeasurementMethod {
  Direct = 'Direct',
  Difference = 'Difference',
  ResidualDifference = 'Residual Difference'
}

export type MethodType = `${MeasurementMethod}`;

export type FieldKeys = 'directTare' | 'directActual' | 'differencePan' | 'differencePanSample' | 'differenceActual' | 'residualDifferencePanSample' | 'residualDifferencePanResidual' | 'residualDifferenceActual';


