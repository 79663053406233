/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddLabItemsConsumableCommand } from '../models/add-lab-items-consumable-command';
import { ChangeLabItemsCellCommand } from '../models/change-lab-items-cell-command';
import { LabItemPreparationRemovedResponse } from '../models/lab-item-preparation-removed-response';
import { LabItemPreparationRestoredResponse } from '../models/lab-item-preparation-restored-response';
import { LabItemsCellChangedEventNotification } from '../models/lab-items-cell-changed-event-notification';
import { LabItemsConsumableAddedResponse } from '../models/lab-items-consumable-added-response';
import { LabItemsConsumableRemovedResponse } from '../models/lab-items-consumable-removed-response';
import { LabItemsConsumableRestoredResponse } from '../models/lab-items-consumable-restored-response';
import { LabItemsInstrumentColumnRefreshedNotification } from '../models/lab-items-instrument-column-refreshed-notification';
import { LabItemsInstrumentColumnRemovedResponse } from '../models/lab-items-instrument-column-removed-response';
import { LabItemsInstrumentColumnRestoredResponse } from '../models/lab-items-instrument-column-restored-response';
import { LabItemsInstrumentRefreshedNotification } from '../models/lab-items-instrument-refreshed-notification';
import { LabItemsInstrumentRemovedResponse } from '../models/lab-items-instrument-removed-response';
import { LabItemsInstrumentRestoredResponse } from '../models/lab-items-instrument-restored-response';
import { LabItemsMaterialRefreshedNotification } from '../models/lab-items-material-refreshed-notification';
import { LabItemsMaterialRemovedResponse } from '../models/lab-items-material-removed-response';
import { LabItemsMaterialRestoredResponse } from '../models/lab-items-material-restored-response';
import { LabItemsPreparationRefreshedNotification } from '../models/lab-items-preparation-refreshed-notification';
import { RefreshLabItemsInstrumentColumnCommand } from '../models/refresh-lab-items-instrument-column-command';
import { RefreshLabItemsInstrumentCommand } from '../models/refresh-lab-items-instrument-command';
import { RefreshLabItemsMaterialCommand } from '../models/refresh-lab-items-material-command';
import { RefreshLabItemsPreparationCommand } from '../models/refresh-lab-items-preparation-command';
import { RemoveLabItemPreparationCommand } from '../models/remove-lab-item-preparation-command';
import { RemoveLabItemsConsumableCommand } from '../models/remove-lab-items-consumable-command';
import { RemoveLabItemsInstrumentColumnCommand } from '../models/remove-lab-items-instrument-column-command';
import { RemoveLabItemsInstrumentCommand } from '../models/remove-lab-items-instrument-command';
import { RemoveLabItemsMaterialCommand } from '../models/remove-lab-items-material-command';
import { RestoreLabItemPreparationCommand } from '../models/restore-lab-item-preparation-command';
import { RestoreLabItemsConsumableCommand } from '../models/restore-lab-items-consumable-command';
import { RestoreLabItemsInstrumentColumnCommand } from '../models/restore-lab-items-instrument-column-command';
import { RestoreLabItemsInstrumentCommand } from '../models/restore-lab-items-instrument-command';
import { RestoreLabItemsMaterialCommand } from '../models/restore-lab-items-material-command';

@Injectable({
  providedIn: 'root',
})
export class LabItemEventsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation labItemEventsExperimentIdRemoveMaterialPost
   */
  static readonly LabItemEventsExperimentIdRemoveMaterialPostPath = '/lab-item-events/{experimentId}/remove-material';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveMaterialPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveMaterialPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsMaterialCommand
  }): Observable<StrictHttpResponse<LabItemsMaterialRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRemoveMaterialPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsMaterialRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveMaterialPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveMaterialPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsMaterialCommand
  }): Observable<LabItemsMaterialRemovedResponse> {

    return this.labItemEventsExperimentIdRemoveMaterialPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsMaterialRemovedResponse>) => r.body as LabItemsMaterialRemovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveMaterialPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveMaterialPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsMaterialCommand
  }): Observable<StrictHttpResponse<LabItemsMaterialRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRemoveMaterialPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsMaterialRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveMaterialPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveMaterialPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsMaterialCommand
  }): Observable<LabItemsMaterialRemovedResponse> {

    return this.labItemEventsExperimentIdRemoveMaterialPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsMaterialRemovedResponse>) => r.body as LabItemsMaterialRemovedResponse)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRestoreMaterialPost
   */
  static readonly LabItemEventsExperimentIdRestoreMaterialPostPath = '/lab-item-events/{experimentId}/restore-material';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreMaterialPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreMaterialPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsMaterialCommand
  }): Observable<StrictHttpResponse<LabItemsMaterialRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRestoreMaterialPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsMaterialRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreMaterialPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreMaterialPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsMaterialCommand
  }): Observable<LabItemsMaterialRestoredResponse> {

    return this.labItemEventsExperimentIdRestoreMaterialPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsMaterialRestoredResponse>) => r.body as LabItemsMaterialRestoredResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreMaterialPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreMaterialPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsMaterialCommand
  }): Observable<StrictHttpResponse<LabItemsMaterialRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRestoreMaterialPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsMaterialRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreMaterialPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreMaterialPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsMaterialCommand
  }): Observable<LabItemsMaterialRestoredResponse> {

    return this.labItemEventsExperimentIdRestoreMaterialPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsMaterialRestoredResponse>) => r.body as LabItemsMaterialRestoredResponse)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRemoveInstrumentPost
   */
  static readonly LabItemEventsExperimentIdRemoveInstrumentPostPath = '/lab-item-events/{experimentId}/remove-instrument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveInstrumentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsInstrumentCommand
  }): Observable<StrictHttpResponse<LabItemsInstrumentRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRemoveInstrumentPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsInstrumentRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveInstrumentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsInstrumentCommand
  }): Observable<LabItemsInstrumentRemovedResponse> {

    return this.labItemEventsExperimentIdRemoveInstrumentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentRemovedResponse>) => r.body as LabItemsInstrumentRemovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveInstrumentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsInstrumentCommand
  }): Observable<StrictHttpResponse<LabItemsInstrumentRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRemoveInstrumentPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsInstrumentRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveInstrumentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsInstrumentCommand
  }): Observable<LabItemsInstrumentRemovedResponse> {

    return this.labItemEventsExperimentIdRemoveInstrumentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentRemovedResponse>) => r.body as LabItemsInstrumentRemovedResponse)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRestoreInstrumentPost
   */
  static readonly LabItemEventsExperimentIdRestoreInstrumentPostPath = '/lab-item-events/{experimentId}/restore-instrument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreInstrumentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsInstrumentCommand
  }): Observable<StrictHttpResponse<LabItemsInstrumentRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRestoreInstrumentPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsInstrumentRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreInstrumentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsInstrumentCommand
  }): Observable<LabItemsInstrumentRestoredResponse> {

    return this.labItemEventsExperimentIdRestoreInstrumentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentRestoredResponse>) => r.body as LabItemsInstrumentRestoredResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreInstrumentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsInstrumentCommand
  }): Observable<StrictHttpResponse<LabItemsInstrumentRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRestoreInstrumentPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsInstrumentRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreInstrumentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsInstrumentCommand
  }): Observable<LabItemsInstrumentRestoredResponse> {

    return this.labItemEventsExperimentIdRestoreInstrumentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentRestoredResponse>) => r.body as LabItemsInstrumentRestoredResponse)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRefreshInstrumentPost
   */
  static readonly LabItemEventsExperimentIdRefreshInstrumentPostPath = '/lab-item-events/{experimentId}/refresh-instrument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshInstrumentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsInstrumentCommand
  }): Observable<StrictHttpResponse<LabItemsInstrumentRefreshedNotification>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRefreshInstrumentPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsInstrumentRefreshedNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshInstrumentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsInstrumentCommand
  }): Observable<LabItemsInstrumentRefreshedNotification> {

    return this.labItemEventsExperimentIdRefreshInstrumentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentRefreshedNotification>) => r.body as LabItemsInstrumentRefreshedNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshInstrumentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsInstrumentCommand
  }): Observable<StrictHttpResponse<LabItemsInstrumentRefreshedNotification>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRefreshInstrumentPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsInstrumentRefreshedNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshInstrumentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsInstrumentCommand
  }): Observable<LabItemsInstrumentRefreshedNotification> {

    return this.labItemEventsExperimentIdRefreshInstrumentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentRefreshedNotification>) => r.body as LabItemsInstrumentRefreshedNotification)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRefreshInstrumentColumnPost
   */
  static readonly LabItemEventsExperimentIdRefreshInstrumentColumnPostPath = '/lab-item-events/{experimentId}/refresh-instrumentColumn';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshInstrumentColumnPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentColumnPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsInstrumentColumnCommand
  }): Observable<StrictHttpResponse<LabItemsInstrumentColumnRefreshedNotification>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRefreshInstrumentColumnPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsInstrumentColumnRefreshedNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshInstrumentColumnPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentColumnPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsInstrumentColumnCommand
  }): Observable<LabItemsInstrumentColumnRefreshedNotification> {

    return this.labItemEventsExperimentIdRefreshInstrumentColumnPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentColumnRefreshedNotification>) => r.body as LabItemsInstrumentColumnRefreshedNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshInstrumentColumnPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentColumnPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsInstrumentColumnCommand
  }): Observable<StrictHttpResponse<LabItemsInstrumentColumnRefreshedNotification>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRefreshInstrumentColumnPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsInstrumentColumnRefreshedNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshInstrumentColumnPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentColumnPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsInstrumentColumnCommand
  }): Observable<LabItemsInstrumentColumnRefreshedNotification> {

    return this.labItemEventsExperimentIdRefreshInstrumentColumnPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentColumnRefreshedNotification>) => r.body as LabItemsInstrumentColumnRefreshedNotification)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdChangeCellValuePost
   */
  static readonly LabItemEventsExperimentIdChangeCellValuePostPath = '/lab-item-events/{experimentId}/change-cell-value';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdChangeCellValuePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdChangeCellValuePost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeLabItemsCellCommand
  }): Observable<StrictHttpResponse<LabItemsCellChangedEventNotification>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdChangeCellValuePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsCellChangedEventNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdChangeCellValuePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdChangeCellValuePost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeLabItemsCellCommand
  }): Observable<LabItemsCellChangedEventNotification> {

    return this.labItemEventsExperimentIdChangeCellValuePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsCellChangedEventNotification>) => r.body as LabItemsCellChangedEventNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdChangeCellValuePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdChangeCellValuePost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeLabItemsCellCommand
  }): Observable<StrictHttpResponse<LabItemsCellChangedEventNotification>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdChangeCellValuePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsCellChangedEventNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdChangeCellValuePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdChangeCellValuePost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ChangeLabItemsCellCommand
  }): Observable<LabItemsCellChangedEventNotification> {

    return this.labItemEventsExperimentIdChangeCellValuePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsCellChangedEventNotification>) => r.body as LabItemsCellChangedEventNotification)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRefreshMaterialPost
   */
  static readonly LabItemEventsExperimentIdRefreshMaterialPostPath = '/lab-item-events/{experimentId}/refresh-material';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshMaterialPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshMaterialPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsMaterialCommand
  }): Observable<StrictHttpResponse<LabItemsMaterialRefreshedNotification>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRefreshMaterialPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsMaterialRefreshedNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshMaterialPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshMaterialPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsMaterialCommand
  }): Observable<LabItemsMaterialRefreshedNotification> {

    return this.labItemEventsExperimentIdRefreshMaterialPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsMaterialRefreshedNotification>) => r.body as LabItemsMaterialRefreshedNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshMaterialPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshMaterialPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsMaterialCommand
  }): Observable<StrictHttpResponse<LabItemsMaterialRefreshedNotification>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRefreshMaterialPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsMaterialRefreshedNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshMaterialPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshMaterialPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsMaterialCommand
  }): Observable<LabItemsMaterialRefreshedNotification> {

    return this.labItemEventsExperimentIdRefreshMaterialPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsMaterialRefreshedNotification>) => r.body as LabItemsMaterialRefreshedNotification)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdAddConsumablePost
   */
  static readonly LabItemEventsExperimentIdAddConsumablePostPath = '/lab-item-events/{experimentId}/add-consumable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdAddConsumablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdAddConsumablePost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddLabItemsConsumableCommand
  }): Observable<StrictHttpResponse<LabItemsConsumableAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdAddConsumablePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsConsumableAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdAddConsumablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdAddConsumablePost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddLabItemsConsumableCommand
  }): Observable<LabItemsConsumableAddedResponse> {

    return this.labItemEventsExperimentIdAddConsumablePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsConsumableAddedResponse>) => r.body as LabItemsConsumableAddedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdAddConsumablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdAddConsumablePost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddLabItemsConsumableCommand
  }): Observable<StrictHttpResponse<LabItemsConsumableAddedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdAddConsumablePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsConsumableAddedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdAddConsumablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdAddConsumablePost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: AddLabItemsConsumableCommand
  }): Observable<LabItemsConsumableAddedResponse> {

    return this.labItemEventsExperimentIdAddConsumablePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsConsumableAddedResponse>) => r.body as LabItemsConsumableAddedResponse)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRemoveConsumablePost
   */
  static readonly LabItemEventsExperimentIdRemoveConsumablePostPath = '/lab-item-events/{experimentId}/remove-consumable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveConsumablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveConsumablePost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsConsumableCommand
  }): Observable<StrictHttpResponse<LabItemsConsumableRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRemoveConsumablePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsConsumableRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveConsumablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveConsumablePost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsConsumableCommand
  }): Observable<LabItemsConsumableRemovedResponse> {

    return this.labItemEventsExperimentIdRemoveConsumablePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsConsumableRemovedResponse>) => r.body as LabItemsConsumableRemovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveConsumablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveConsumablePost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsConsumableCommand
  }): Observable<StrictHttpResponse<LabItemsConsumableRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRemoveConsumablePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsConsumableRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveConsumablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveConsumablePost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsConsumableCommand
  }): Observable<LabItemsConsumableRemovedResponse> {

    return this.labItemEventsExperimentIdRemoveConsumablePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsConsumableRemovedResponse>) => r.body as LabItemsConsumableRemovedResponse)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRestoreConsumablePost
   */
  static readonly LabItemEventsExperimentIdRestoreConsumablePostPath = '/lab-item-events/{experimentId}/restore-consumable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreConsumablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreConsumablePost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsConsumableCommand
  }): Observable<StrictHttpResponse<LabItemsConsumableRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRestoreConsumablePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsConsumableRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreConsumablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreConsumablePost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsConsumableCommand
  }): Observable<LabItemsConsumableRestoredResponse> {

    return this.labItemEventsExperimentIdRestoreConsumablePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsConsumableRestoredResponse>) => r.body as LabItemsConsumableRestoredResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreConsumablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreConsumablePost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsConsumableCommand
  }): Observable<StrictHttpResponse<LabItemsConsumableRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRestoreConsumablePostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsConsumableRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreConsumablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreConsumablePost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsConsumableCommand
  }): Observable<LabItemsConsumableRestoredResponse> {

    return this.labItemEventsExperimentIdRestoreConsumablePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsConsumableRestoredResponse>) => r.body as LabItemsConsumableRestoredResponse)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRemoveInstrumentColumnPost
   */
  static readonly LabItemEventsExperimentIdRemoveInstrumentColumnPostPath = '/lab-item-events/{experimentId}/remove-instrument-column';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveInstrumentColumnPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentColumnPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsInstrumentColumnCommand
  }): Observable<StrictHttpResponse<LabItemsInstrumentColumnRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRemoveInstrumentColumnPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsInstrumentColumnRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveInstrumentColumnPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentColumnPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsInstrumentColumnCommand
  }): Observable<LabItemsInstrumentColumnRemovedResponse> {

    return this.labItemEventsExperimentIdRemoveInstrumentColumnPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentColumnRemovedResponse>) => r.body as LabItemsInstrumentColumnRemovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveInstrumentColumnPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentColumnPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsInstrumentColumnCommand
  }): Observable<StrictHttpResponse<LabItemsInstrumentColumnRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRemoveInstrumentColumnPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsInstrumentColumnRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveInstrumentColumnPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentColumnPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemsInstrumentColumnCommand
  }): Observable<LabItemsInstrumentColumnRemovedResponse> {

    return this.labItemEventsExperimentIdRemoveInstrumentColumnPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentColumnRemovedResponse>) => r.body as LabItemsInstrumentColumnRemovedResponse)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRestoreInstrumentColumnPost
   */
  static readonly LabItemEventsExperimentIdRestoreInstrumentColumnPostPath = '/lab-item-events/{experimentId}/restore-instrument-column';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreInstrumentColumnPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentColumnPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsInstrumentColumnCommand
  }): Observable<StrictHttpResponse<LabItemsInstrumentColumnRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRestoreInstrumentColumnPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsInstrumentColumnRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreInstrumentColumnPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentColumnPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsInstrumentColumnCommand
  }): Observable<LabItemsInstrumentColumnRestoredResponse> {

    return this.labItemEventsExperimentIdRestoreInstrumentColumnPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentColumnRestoredResponse>) => r.body as LabItemsInstrumentColumnRestoredResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreInstrumentColumnPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentColumnPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsInstrumentColumnCommand
  }): Observable<StrictHttpResponse<LabItemsInstrumentColumnRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRestoreInstrumentColumnPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsInstrumentColumnRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreInstrumentColumnPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentColumnPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemsInstrumentColumnCommand
  }): Observable<LabItemsInstrumentColumnRestoredResponse> {

    return this.labItemEventsExperimentIdRestoreInstrumentColumnPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentColumnRestoredResponse>) => r.body as LabItemsInstrumentColumnRestoredResponse)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRemovePreparationPost
   */
  static readonly LabItemEventsExperimentIdRemovePreparationPostPath = '/lab-item-events/{experimentId}/remove-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemovePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemovePreparationPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemPreparationCommand
  }): Observable<StrictHttpResponse<LabItemPreparationRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRemovePreparationPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemPreparationRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemovePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemovePreparationPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemPreparationCommand
  }): Observable<LabItemPreparationRemovedResponse> {

    return this.labItemEventsExperimentIdRemovePreparationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemPreparationRemovedResponse>) => r.body as LabItemPreparationRemovedResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemovePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemovePreparationPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemPreparationCommand
  }): Observable<StrictHttpResponse<LabItemPreparationRemovedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRemovePreparationPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemPreparationRemovedResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemovePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemovePreparationPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RemoveLabItemPreparationCommand
  }): Observable<LabItemPreparationRemovedResponse> {

    return this.labItemEventsExperimentIdRemovePreparationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemPreparationRemovedResponse>) => r.body as LabItemPreparationRemovedResponse)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRestorePreparationPost
   */
  static readonly LabItemEventsExperimentIdRestorePreparationPostPath = '/lab-item-events/{experimentId}/restore-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestorePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestorePreparationPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemPreparationCommand
  }): Observable<StrictHttpResponse<LabItemPreparationRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRestorePreparationPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemPreparationRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestorePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestorePreparationPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemPreparationCommand
  }): Observable<LabItemPreparationRestoredResponse> {

    return this.labItemEventsExperimentIdRestorePreparationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemPreparationRestoredResponse>) => r.body as LabItemPreparationRestoredResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestorePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestorePreparationPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemPreparationCommand
  }): Observable<StrictHttpResponse<LabItemPreparationRestoredResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRestorePreparationPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemPreparationRestoredResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestorePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestorePreparationPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreLabItemPreparationCommand
  }): Observable<LabItemPreparationRestoredResponse> {

    return this.labItemEventsExperimentIdRestorePreparationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemPreparationRestoredResponse>) => r.body as LabItemPreparationRestoredResponse)
    );
  }

  /**
   * Path part for operation labItemEventsExperimentIdRefreshPreparationPost
   */
  static readonly LabItemEventsExperimentIdRefreshPreparationPostPath = '/lab-item-events/{experimentId}/refresh-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshPreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshPreparationPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsPreparationCommand
  }): Observable<StrictHttpResponse<LabItemsPreparationRefreshedNotification>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRefreshPreparationPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsPreparationRefreshedNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshPreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshPreparationPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsPreparationCommand
  }): Observable<LabItemsPreparationRefreshedNotification> {

    return this.labItemEventsExperimentIdRefreshPreparationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsPreparationRefreshedNotification>) => r.body as LabItemsPreparationRefreshedNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshPreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshPreparationPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsPreparationCommand
  }): Observable<StrictHttpResponse<LabItemsPreparationRefreshedNotification>> {

    const rb = new RequestBuilder(this.rootUrl, LabItemEventsService.LabItemEventsExperimentIdRefreshPreparationPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabItemsPreparationRefreshedNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshPreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshPreparationPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RefreshLabItemsPreparationCommand
  }): Observable<LabItemsPreparationRefreshedNotification> {

    return this.labItemEventsExperimentIdRefreshPreparationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabItemsPreparationRefreshedNotification>) => r.body as LabItemsPreparationRefreshedNotification)
    );
  }

}
