import { Injectable } from "@angular/core";
import {
  ClientFacingNoteContextType,
  ExperimentPreparationStatus, InstantValue, LocalDateValue, ModifiableDataValue,
  PreparationInternalInformation, ValueType
} from "../../../../api/models";
import {
  ActivityPreparationsPresentation,
  PreparationAdditionalInformationPresentation,
  PreparationInternalInformationPresentation,
  PreparationItem,
  PreparationSummaryPresentation
} from "../../../../preparation/models/preparation-presentation.model";
import { ExperimentNewPreparations, PreparationAdditionalInformationResponse, PreparationInternalInformationResponse, PreparationRemovedResponse, PreparationRestoredResponse, PreparationSummaryResponse, RemovePreparationCommand, RestorePreparationCommand, ExperimentPreparationCellChangeCommand, ExperimentPreparationStatusChangedNotification } from "../../../../api/data-entry/models";
import { Subject, Subscription } from "rxjs";
import { PreparationsEventsService } from "../../../../api/data-entry/services";
import { PreparationEventService } from "../../../../preparation/services/preparation-event.service";
import { AuditHistoryDataRecordResponse, ExperimentDataRecordNotification, ExperimentEventType } from "../../../../api/audit/models";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { AuditHistoryService } from "../../../audit-history/audit-history.service";
import { PreparationAuditHistoryInputs } from "../../../../preparation/models/preparation.model";
import { ELNAppConstants } from "../../../../shared/eln-app-constants";
import { ExperimentPreparationsCreatedNotification } from "../../../model/preparations/experiment-preparation-created-notification";
import { PreparationCellChangedResponse } from "../../../../api/data-entry/models/preparation-cell-changed-response";
import { ElnProgressSpinnerService } from "../../../../eln-progress-spinner-module/eln-progress-spinner.service";
import { SpinnerOptions } from "../../../../eln-progress-spinner-module/spinner-options.interface";
import { DataRecordService } from "../../../services/data-record.service";
import { ExperimentService } from "../../../services/experiment.service";
import { PreparationConstants } from "../../../../preparation/preparation-constants";
import { MessageService } from "primeng/api";
import { ClientFacingNoteModel } from "../../../comments/client-facing-note/client-facing-note.model";

@Injectable()
export class ExperimentPreparationService {
  columnDeleteActionId = 'bpt-preparation-delete-row';
  columnRefreshActionId = 'bpt-preparation-refresh-row';
  columnRestoreActionId = 'bpt-preparation-restore-row';
  /** Completion of preparations in the current activity  */
  allPreparationsFieldsFilled = false;
  public isUserAllowedToRestore = false;
  public isLoading = false;
  private readonly preparationRemovalResponseSubject = new Subject<PreparationRemovedResponse>();
  private readonly preparationRestoreResponseSubject = new Subject<PreparationRestoredResponse>();
  private readonly preparationCellChangedResponseSubject = new Subject<PreparationCellChangedResponse>();
  dynamicDialogRef!: DynamicDialogRef;
  private readonly eventsForHistory: ExperimentEventType[] = [
    ExperimentEventType.ExperimentPreparationCreated,
    ExperimentEventType.ExperimentPreparationRestored,
    ExperimentEventType.ExperimentPreparationRemoved,
    ExperimentEventType.ExperimentPreparationInternalInformationChanged,
    ExperimentEventType.ExperimentPreparationDiscardedOrConsumed,
    ExperimentEventType.ExperimentPreparationCellChanged,
    ExperimentEventType.ExperimentPreparationStatusChanged,
    ExperimentEventType.ClientFacingNoteCreated,
    ExperimentEventType.ClientFacingNoteChanged
  ];

  auditHistoryLoadingMessage: SpinnerOptions = {
    message: $localize`:@@loadingHistory:Loading History...`,
    i18nMessage: `@@loadingHistory`
  };

  private _isRestoreButtonEnabled = true;
  public get isRestoreButtonEnabled() {
    return this._isRestoreButtonEnabled;
  }
  public set isRestoreButtonEnabled(value: boolean) {
    this._isRestoreButtonEnabled = value;
  }

  constructor(private readonly preparationsEventsService: PreparationsEventsService,
    public readonly preparationEventService: PreparationEventService,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly experimentService: ExperimentService,
    private readonly dataRecordService: DataRecordService,
    private readonly messageService: MessageService) {
      this.watchCollaborativeEditingSubscriptions();
  }

  removedPreparationsDetails: Array<PreparationItem> = [];

  subscriptions: Subscription[] = [];

  preparationRemovalResponse() {
    return this.preparationRemovalResponseSubject.asObservable();
  }

  preparationRestoreResponse() {
    return this.preparationRestoreResponseSubject.asObservable();
  }
  preparationCellChangedResponse() {
    return this.preparationCellChangedResponseSubject.asObservable();
  }

  buildPresentationModel(activityPreparations: ExperimentNewPreparations): ActivityPreparationsPresentation {
    let expiration = {} as ModifiableDataValue;
    const activityPreparationsPresentation: ActivityPreparationsPresentation = {
      nodeId: "",
      preparations: []
    }
    activityPreparationsPresentation.nodeId = activityPreparations.activityId;
    activityPreparations.preparations.forEach((preparation) => {
      if (preparation.expirationValue) {
        expiration = {
          isModified: false,
          value: preparation.expirationValue.expirationDateValue?.type === ValueType.Instant ?
            preparation.expirationValue.expirationDateValue as InstantValue : preparation.expirationValue.expirationDateValue as LocalDateValue,
        }
      }
      const newlyCreatedPreparation: PreparationItem = {} as PreparationItem;
      newlyCreatedPreparation.additionalInformation = this.buildAdditionalInformationPresentation(preparation.additionalInformation);
      newlyCreatedPreparation.internalInformation = this.buildInternalInformationPresentation(preparation.internalInformation as PreparationInternalInformation);
      newlyCreatedPreparation.summary = this.buildSummaryPresentation(preparation.summary);
      newlyCreatedPreparation.description = {
        isModified: false,
        value: preparation.description
      }
      newlyCreatedPreparation.isRemoved = false;
      newlyCreatedPreparation.name = {
        isModified: false,
        value: preparation.name
      };
      newlyCreatedPreparation.status = ExperimentPreparationStatus.Pending;
      newlyCreatedPreparation.preparationId = preparation.preparationId;
      newlyCreatedPreparation.preparationNumber = preparation.preparationNumber;
      newlyCreatedPreparation.expirationValue = {
        expirationDateValue: expiration
      }
      activityPreparationsPresentation.preparations.push(newlyCreatedPreparation);
    });
    return activityPreparationsPresentation;
  }

  subscribePreparationFieldsFilled() {
    this.subscriptions.push(this.preparationEventService.isAllPreparationsFieldsFilled.subscribe(isComplete => {
      this.allPreparationsFieldsFilled = isComplete;
    }));
  }

  buildAdditionalInformationPresentation(additionalInfo: PreparationAdditionalInformationResponse): PreparationAdditionalInformationPresentation {
    const additionalInformation: PreparationAdditionalInformationPresentation = {
      analysis: additionalInfo.analysis,
      type: additionalInfo.client,
      method: additionalInfo.method,
      compendia: additionalInfo.compendia,
      client: additionalInfo.client,
      project: additionalInfo.project,
      preparedBy: additionalInfo.preparedBy ? additionalInfo.preparedBy : "",
      reviewedBy: additionalInfo.reviewedBy,
      storedBy: additionalInfo.storedBy,
      subBusinessUnit: additionalInfo.subBusinessUnit,
      discardedOrConsumed: additionalInfo.discardedOrConsumed,
      discardedOrConsumedBy: additionalInfo.discardedOrConsumedBy,
      discardedOrConsumedOn: additionalInfo.discardedOrConsumedOn
    };
    return additionalInformation;
  }

  buildInternalInformationPresentation(internalInfo: PreparationInternalInformationResponse): PreparationInternalInformationPresentation {
    const result: PreparationInternalInformationPresentation = {
      disposal: internalInfo?.disposal,
      storageLocation: internalInfo?.storageLocation,
      stability: internalInfo.stability,
      hazards: internalInfo?.hazards,
      originalQuantity: internalInfo?.originalQuantity,
      additionalLabel: internalInfo?.additionalLabel
    };
    return result;
  }

  buildSummaryPresentation(summary: PreparationSummaryResponse): PreparationSummaryPresentation {
    const summaryInfo: PreparationSummaryPresentation = {} as PreparationSummaryPresentation;
    if (summary.formulaComponents) {
      summaryInfo.formulaComponents = {
        value: summary.formulaComponents,
        isModified: false
      }
    }
    if (summary.concentration) {
      summaryInfo.concentration = {
        value: summary.concentration,
        isModified: false
      }
    }
    if (summary.storageCondition) {
      summaryInfo.storageCondition = {
        value: summary.storageCondition,
        isModified: false
      }
    }
    return summaryInfo;
  }

  public removePreparation(preparationToBeRemoved: RemovePreparationCommand) {
    this.subscriptions.push(this.preparationsEventsService
      .preparationsEventsRemovePreparationPost$Json$Response({
        body: preparationToBeRemoved
      }).subscribe({
        next: (result) => {
          this.preparationRemovalResponseSubject.next(result.body);
        }
      }));
  }

  public updatePreparation(experimentPreparationCellChangeCommand: ExperimentPreparationCellChangeCommand) {
    this.subscriptions.push(this.preparationsEventsService
      .preparationsEventsPreparationCellChangePost$Json$Response({
        body: experimentPreparationCellChangeCommand
      }).subscribe({
        next: (result) => {
          this.preparationCellChangedResponseSubject.next(result.body);
        }
      }));
  }
  public restorePreparation(preparationToBeRestored: RestorePreparationCommand) {
    this.subscriptions.push(this.preparationsEventsService
      .preparationsEventsRestorePreparationPost$Json$Response({
        body: preparationToBeRestored
      }).subscribe({
        next: (result) => {
          this.preparationRestoreResponseSubject.next(result.body);
        }
      }));
  }

  /**
 * Gets called to load audit history dialog
 */
  public loadAuditHistoryDialog(input: PreparationAuditHistoryInputs) {
    this.elnProgressSpinnerService.Show(this.auditHistoryLoadingMessage);
    this.auditHistoryService
      .loadPreparationsAuditHistory(input.experimentId as string, input.activityId)
      .subscribe((data: AuditHistoryDataRecordResponse) => {
        if (data) {
          this.elnProgressSpinnerService.Hide();
          data.dataRecords = this.getPreparationDataRecords(data.dataRecords);
          if (!input.row || !input.field) {
            this.allAuditHistory(data, input);
            return;
          }
          this.preparationAuditHistory(input, data);
        }
      });
  }
  /*
   Audit history for the Preparation Events
   */
  private allAuditHistory(historyResponse: AuditHistoryDataRecordResponse, input: PreparationAuditHistoryInputs) {
    this.preparationEventService.isHistoryLoading = false;
    const history = historyResponse.dataRecords
      .filter((x) => {
        return (
          x !== null &&
          x.eventContext !== null &&
          this.eventsForHistory.indexOf(x.eventContext.eventType) > -1
        );
      })
      .filter(dr => (!('number' in dr)) ||
        ((this.lookupNote(dr.number as number)?.contextType === ClientFacingNoteContextType.Preparations) &&
          (dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated || dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged)));

    history.forEach((x: any) => {
      const column = input.columnDefinition.find(c => (c.field?.toLowerCase() === x.propertyName?.toLowerCase()) ||
        c.field?.split('.')[1]?.toLowerCase() === x.propertyName?.toLowerCase()
      );
      x.propertyName = column?.label
    })
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      history,
      $localize`:@@preparations:Preparations`);
  }
  
  public lookupNote(number: number): ClientFacingNoteModel | undefined {
    return this.experimentService.currentExperiment?.clientFacingNotes.find(n => n.number === number);
  }

  private preparationAuditHistory(input: PreparationAuditHistoryInputs, data: AuditHistoryDataRecordResponse) {
    const cellChangedRecords: any[] = data.dataRecords
      .filter(
        (d): d is ExperimentPreparationsCreatedNotification =>
          d?.eventContext.eventType.toString() === ExperimentEventType.ExperimentPreparationCreated ||
          d?.eventContext.eventType.toString() === ExperimentEventType.ExperimentPreparationRestored ||
          d?.eventContext.eventType.toString() === ExperimentEventType.ExperimentPreparationDiscardedOrConsumed ||
          d?.eventContext.eventType.toString() === ExperimentEventType.ExperimentPreparationInternalInformationChanged ||
          d?.eventContext.eventType.toString() === ExperimentEventType.ExperimentPreparationCellChanged ||
          d?.eventContext.eventType.toString() === ExperimentEventType.ExperimentPreparationRemoved ||
          d?.eventContext.eventType.toString() === ExperimentEventType.ExperimentPreparationStatusChanged ||
          d?.eventContext.eventType.toString() === ExperimentEventType.ClientFacingNoteCreated ||
          d?.eventContext.eventType.toString() === ExperimentEventType.ClientFacingNoteChanged)
      .filter(c => c.createdPreparations?.map(x => x.preparationId).includes(input.row as string))
      .filter(dr => (!('number' in dr)) ||
        ((this.lookupNote(dr.number as number)?.contextType === ClientFacingNoteContextType.Preparations) &&
          (dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated || dr.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged)));

    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      cellChangedRecords,
      $localize`:@@Preparations:Preparations`.concat(
        ELNAppConstants.WhiteSpace,
        '→',
        ELNAppConstants.WhiteSpace
      ));
  }


  private getPreparationDataRecords(dataRecords: ExperimentDataRecordNotification[]): ExperimentDataRecordNotification[] {
    return dataRecords.filter((x: any) => x &&
      x.eventContext.eventType === ExperimentEventType.ExperimentPreparationCreated ||
      x.eventContext.eventType === ExperimentEventType.ExperimentPreparationCellChanged ||
      x.eventContext.eventType === ExperimentEventType.ExperimentPreparationDiscardedOrConsumed ||
      x.eventContext.eventType === ExperimentEventType.ExperimentPreparationInternalInformationChanged ||
      x.eventContext.eventType === ExperimentEventType.ExperimentPreparationRemoved ||
      x.eventContext.eventType === ExperimentEventType.ExperimentPreparationRestored ||
      x.eventContext.eventType === ExperimentEventType.ExperimentPreparationStatusChanged ||
      x.eventContext.eventType === ExperimentEventType.ClientFacingNoteCreated ||
      x.eventContext.eventType === ExperimentEventType.ClientFacingNoteChanged
    )
  }

  //collaborative editing
  watchCollaborativeEditingSubscriptions() {
    this.subscriptions.push(
      this.dataRecordService.experimentPreparationStatusChangedNotificationReceiver.subscribe({
        next:(notification: ExperimentPreparationStatusChangedNotification) => {
          this.applyStatusChangedDataRecord(notification);
        }
      })
    );
  }

  applyStatusChangedDataRecord(notification: ExperimentPreparationStatusChangedNotification) {
    const activity = this.experimentService.currentExperiment?.activities.find(
      activity => activity.activityId === notification.activityId
    );
    if(!activity) return;
    const prep = activity.preparations.find(prep => prep.preparationId === notification.preparationId);
    if(prep) {
      prep.status= notification.status;
      prep.additionalInformation!.reviewedBy= notification.reviewedBy;
      this.messageService.add({
        key: 'notification',
        severity: 'success',
        id: 'validationToast',
        detail: `${prep.preparationNumber}`,
        summary: PreparationConstants.cellValueChangedMessage,
        sticky: false
      });
      this.preparationEventService.refreshGrid.next();
    }
  }
}

