import { Injectable } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { forkJoin, map, Observable, Subject } from 'rxjs';
import { DateAndInstantFormat, formatInstant, formatLocalDate } from '../../shared/date-time-helpers';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { UserService } from '../../api/services/user.service';
import { UserService as CurrentUserService } from 'services/user.service';
import { WorkflowEventNotification } from '../../api/data-entry/models/workflow-event-notification';
import {
  ActivityInputType,
  ActivityLabItemsNode,
  ClientFacingNote,
  ColumnType,
  Consumable,
  ExperimentPreparation,
  ExperimentResponse,
  ExperimentWorkflowState,
  FieldDefinitionResponse,
  FieldGroupResponse,  
  Labsite,  
  ModifiableDataValue,
  NotificationDetails,
  NotificationResult,
  NumberValue,
  Unit,
  User,
  ValueState,
} from '../../api/models';
import {
  ActivityInputCellChangedEventNotification,
  ActivityInputRowRefreshedEventNotification,
  ActivityInputRowRemovedEventNotification,
  ActivityInputRowRestoredEventNotification,
  ActivityPreparationRemovedNotification,
  ActivityPreparationRestoredNotification,
  AddRowEventNotification,
  AliquotAddedEventNotification,
  AliquotTest,
  AssignedAnalystsChangedEventNotification,
  AssignedReviewersChangedEventNotification,
  AssignedSupervisorsChangedEventNotification,
  AttachedFileEventNotification,
  AuthorizationDueDateChangedEventNotification,
  CellChangedEventNotification,
  ChromatographyDataImportedEventNotification,
  ChromatographyDataRefreshedEventNotification,
  ChromatographyDataRemovedEventNotification,
  ClientFacingNoteChangedEventNotification,
  ClientFacingNoteContextType,
  ClientFacingNoteCreatedEventNotification,
  CrossReferenceAddedEventNotification,
  CrossReferenceChangedEventNotification,
  CrossReferenceRemovedEventNotification,
  CrossReferenceRestoredEventNotification,
  CrossReferenceType,
  DeletedFilesEventNotification,
  ExperimentAuthorizedEventNotification,
  ExperimentCancelledEventNotification,
  ExperimentCreatedEventNotification,
  ExperimentDataRecordNotification,
  ExperimentDataValue,
  ExperimentEventType,
  ExperimentNodeOrderChangedNotification,
  ExperimentNodeTitleChangedNotification,
  ExperimentPreparationStatusChangedNotification,
  ExperimentSentForCorrectionEventNotification,
  ExperimentSentForReviewEventNotification,
  ExperimentStartedEventNotification,
  ExperimentTemplateAppliedEventNotification,
  FieldChangedEventNotification,
  InstrumentAddedEventNotification,
  InstrumentDateRemovedChangedEventNotification,
  InstrumentDescriptionChangedEventNotification,
  InstrumentReadingValue,
  InstrumentRemovedFromServiceChangedEventNotification,
  LabItemPreparationRemovedEventNotification,
  LabItemPreparationRestoredEventNotification,
  LabItemsCellChangedEventNotification,
  LabItemsConsumableAddedNotification,
  LabItemsConsumableRemovedEventNotification,
  LabItemsConsumableRestoredEventNotification,
  LabItemsInstrumentColumnRefreshedNotification,
  LabItemsInstrumentColumnRemovedEventNotification,
  LabItemsInstrumentColumnRestoredEventNotification,
  LabItemsInstrumentRefreshedNotification,
  LabItemsInstrumentRemovedEventNotification,
  LabItemsInstrumentRestoredEventNotification,
  LabItemsMaterialRefreshedNotification,
  LabItemsMaterialRemovedEventNotification,
  LabItemsMaterialRestoredEventNotification,
  LabItemsPreparationRefreshedNotification,
  MaintenanceEventSelectedEventNotification,
  MaterialAddedEventNotification,
  MaterialAddedNotification,
  NodeType,
  NonRoutineIssueEncounteredDataChangeEventNotification,  
  ObservationSpec,
  PreparationCellChangedNotification,
  PreparationDiscardedOrConsumedNotification,
  PreparationInternalInformationChangedNotification,
  ReferenceTemplateAppliedEventNotification,
  ReturnToServiceDataChangeEventNotification,
  RowRemovedEventNotification,
  RowRestoredEventNotification,
  RowsRenumberedEventNotification,
  SampleTestAddedEventNotification,
  ScheduledReviewStartDateChangedEventNotification,
  ScheduledStartDateChangedEventNotification,
  SingleValueSpec,
  SpecComplianceAssessorType,
  SpecificationValue,
  SpecType,
  StringTypeDictionaryValue,
  StringValue,
  StudyActivitySelectedEventNotification,
  SubBusinessUnitsChangedEventNotification,
  TableRow,
  TagsChangedEventNotification,
  TitleChangedEventNotification,
  TwoValueRangeSpec,
  ValueType,
} from '../../api/data-entry/models';
import { AuditHistory } from './audit-history.interface';
import { ExperimentService } from './experiment.service';
import { LabsiteService } from '../../api/services';
import { ExperimentTemplateEventService } from '../../template-loader/experiment-template-load/services/experiment-template-event.service';
import { ELNAppConstants } from '../../shared/eln-app-constants';
import { NA, Quantity } from 'bpt-ui-library/shared';
import { clone, difference, first, last, reverse, camelCase } from 'lodash-es';
import { UnitLoaderService } from 'services/unit-loader.service';
import { DataValue, DataValueService } from './data-value.service';
import { Activity, Experiment, Form, Module, ModuleItem, Table } from '../../model/experiment.interface';
import { ConditionType, DataType, SearchCriteria, StringMatchType } from '../../api/search/models';
import { BookshelfService } from '../../api/search/services';
import { environment } from '../../../environments/environment';
import { LabItemsConsumablesTableOptions } from '../labItems/consumables/lab-items-consumable-table-options';
import { CrossReferencesColumns } from '../references/cross-references/cross-references.component';
import { LabItemsMaterialTableOptions } from '../labItems/materials/lab-items-material/lab-items-material-table-options';
import { LabItemsInstrumentTableOptions } from '../labItems/instruments/lab-items-instrument/lab-items-instrument-table-options';
import { ClientFacingNoteModel } from '../comments/client-facing-note/client-facing-note.model';
import { SpecificationService } from '../../shared/specification-input/specification.service';
import { LabItemsColumnTableOptions } from '../labItems/columns/lab-items-column/lab-items-column-table-options';
import { OutputEmpowerService } from './output-empower.service';
import { SampleTableGridOptions } from '../inputs/sample-table/sample-table-grid-options';
import { ExperimentPreparationsCreatedNotification } from '../model/preparations/experiment-preparation-created-notification';
import { ExperimentNotificationOnlyResponse } from '../model/experiment-notification-only-response.model';
import { Message, MessageService } from 'primeng/api';
import { Instant } from '@js-joda/core';
import { ExperimentRecordTypesHelper } from './experiment-data-record-types-helper';
import { BlowerState } from '../model/instrument-connection/blower-state';
import { InstrumentType } from '../instrument-connection/shared/instrument-type';
import { PhMeterMode } from '../model/instrument-connection/ph-meter-modes';
import { PreparationConstants } from '../../preparation/preparation-constants';
import { ProjectLogLoaderService } from '../../services/project-log-loader.service';
import { LabItemPreparationAddedEventNotification } from '../../api/data-entry/models/lab-item-preparation-added-event-notification';
import { ReferencesService as ActivityReferencesService } from '../references/references.service';
import { PreparationTableOptions } from '../../preparation/preparation-table-options';

/** TODO this will be removed once the server side event is exposed. */
export type SetVariableEventNotification = ExperimentDataRecordNotification & {
  nodeId: string;
  value: ModifiableDataValue;
  name: string;
};

interface CellUpdateHistory {
  [cellKey: string]: string[];
}

/**
 * Union type for readability
 */
type AssignmentEventNotifications = 
  ExperimentCreatedEventNotification |
  SubBusinessUnitsChangedEventNotification | AssignedSupervisorsChangedEventNotification | 
  AssignedAnalystsChangedEventNotification | AssignedReviewersChangedEventNotification;

/**
 * Defines a type for the subtype of ExperimentDataRecordNotification matching a given value for the nested discriminant eventContext.eventType
 * Ref: https://github.com/microsoft/TypeScript/issues/18758#issuecomment-1172487806
 *      https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
 */
type GetTypeForEventType<
  ExperimentEventType,
  TEvent = ExperimentDataRecordNotification
> = TEvent extends { eventContext: { eventType: ExperimentEventType } } ? TEvent : never;

/**
 * Type predicate that narrows ExperimentDataRecordNotification to the subtype with the given value for the nested discriminant eventContext.eventType
 * @example
 * ```
 * if (isEventOfType(event, ExperimentEventType.FieldChanged)) {
 *   functionThatRequiresAParameterOfType_FieldChangedEventNotification(event);
 * }
 * ```
 */
export const isEventOfType = <
  ExperimentEventType extends ExperimentDataRecordNotification['eventContext']['eventType']
>(
  event: ExperimentDataRecordNotification,
  eventType: ExperimentEventType
): event is GetTypeForEventType<ExperimentEventType> => event.eventContext.eventType === eventType;

@Injectable({
  providedIn: 'root'
})
export class DataRecordService {
  private allModuleItemsInExperiment: Array<ModuleItem> = [];
  private allModulesInExperiment: Array<Module> = [];

  get experiment(): Experiment {
    /* 
      Tests break this rule so skipping until they can be changed. 
      if (!this.experimentService.currentExperiment) throw Error('LOGIC ERROR: Can't use DataRecordService methods without an experiment being loaded.');
    */
    return this.experimentService.currentExperiment as Experiment;
  }

  /**
   * @deprecated ExperimentResponse is not the current state of the Experiment. Experiment is.
   * We can sometimes get away with this. But don't count always reloading the experiment to get this refreshed. 
   */
  get experimentResponse(): ExperimentResponse | undefined {
    /* 
      Tests break this rule so skipping until they can be changed. 
      if (!this.experimentService.currentExperimentResponse) throw Error('LOGIC ERROR: Can't use DataRecordService methods without an experiment being loaded.');
    */
    return this.experimentService.currentExperimentResponse;
  }

  usersList!: User[];
  labsites!: Array<Labsite>;
  /** Dictionary of experimentNumber keyed by experimentId. This data is safe to cache forever. */
  experimentNumberCache: { [key: string]: string } = {};
  cellUpdateHistory: CellUpdateHistory = {};

  get experimentNumber(): string {
    return this.experiment.experimentNumber;
  }

  private readonly activityInputsTitle = $localize`:@@activityInputPageTitle:Inputs`;
  private readonly activityOutputsTitle = $localize`:@@outputs:Outputs`;
  private readonly cover = $localize`:@@Cover:Cover`;
  private readonly crossReferencesTitle = $localize`:@@crossReferences:Cross References`;
  private readonly instrumentPageTitle = $localize`:@@Instrument:Instrument`;
  private readonly labItemsConsumableTitle = $localize`:@@LabItemsConsumableTableTitle:Consumables and Supplies`;
  private readonly labItemsInstrumentColumnTitle = $localize`:@@LabItemsColumnsTableTitle:Columns`;
  private readonly labItemsInstrumentTitle = $localize`:@@LabItemsInstrumentsTableTitle:Instruments`;
  private readonly labItemsMaterialTitle = $localize`:@@LabItemsMaterialsTableTitle:Materials`;
  private readonly labItemsTitle = $localize`:@@activityLabItemsPageTitle:Lab Items`;
  private readonly materialAliquotsTitle = $localize`:@@StudyActivities:Study Activities`;
  private readonly referencesTitle = $localize`:@@references:References`;
  private readonly sampleAliquotsTitle = $localize`:@@SampleAliquots:Samples & Aliquots`;
  private readonly expectedToFindTableMessage = 'LOGIC ERROR: Expected to find table with ID: ';
  private readonly labItemsPreparationTitle = $localize`:@@preparations:Preparations`;
  private readonly experimentPreparationTitle = $localize`:@@preparations:Preparations`;

  private readonly preparationColumns: Record<string, string> = {
    Name: $localize`:@@name:Name`,
    FormulaComponents: $localize`:@@formulationOrComponents:Formulation/Components`,
    ExpirationValue: $localize`:@@expiration:Expiration`,
    StorageCondition: $localize`:@@storageCondition:Storage Condition`,
    Concentration: $localize`:@@concentration:Concentration`,
    Description: $localize`:@@containerDescription:Container Description`,
  };

  public readonly activityInputCellChangedDataRecordReceiver = new Subject<ActivityInputCellChangedEventNotification>();
  public readonly activityInputRowRefreshedDataRecordReceiver = new Subject<ActivityInputRowRefreshedEventNotification>();
  public readonly activityInputRowRemovedDataRecordReceiver = new Subject<ActivityInputRowRemovedEventNotification>();
  public readonly activityInputRowRestoredDataRecordReceiver = new Subject<ActivityInputRowRestoredEventNotification>();
  public readonly activityReferenceTemplateAppliedEventNotificationReceiver = new Subject<ReferenceTemplateAppliedEventNotification>();
  public readonly addRowsDataRecordReceiver = new Subject<AddRowEventNotification>();
  public readonly attachedFileEventNotification = new Subject<AttachedFileEventNotification>();
  public readonly cellChangedDataRecordReceiver = new Subject<CellChangedEventNotification>();
  public readonly chromatographyDataImportedEventNotificationReceiver = new Subject<ChromatographyDataImportedEventNotification>();
  public readonly chromatographyDataRefreshedEventNotificationReceiver = new Subject<ChromatographyDataRefreshedEventNotification>();
  public readonly chromatographyDataRemovedEventNotificationReceiver = new Subject<ChromatographyDataRemovedEventNotification>();
  public readonly crossReferenceAddedEventReceiver = new Subject<CrossReferenceAddedEventNotification>();
  public readonly crossReferenceChangedEventReceiver = new Subject<CrossReferenceChangedEventNotification>();
  public readonly crossReferenceRemovedEventReceiver = new Subject<CrossReferenceRemovedEventNotification>();
  public readonly crossReferenceRestoredEventReceiver = new Subject<CrossReferenceRestoredEventNotification>();
  public readonly experimentNodeTitleChangedNotificationReceiver = new Subject<ExperimentNodeTitleChangedNotification>();
  public readonly experimentPreparationStatusChangedNotificationReceiver = new Subject<ExperimentPreparationStatusChangedNotification>();
  public readonly experimentWorkFlowDataRecordReceiver = new Subject<WorkflowEventNotification>();
  public readonly fieldChangedDataRecordReceiver = new Subject<FieldChangedEventNotification>();
  public readonly instrumentDateRemovedChangedDataRecordReceiver = new Subject<InstrumentDateRemovedChangedEventNotification>();
  public readonly instrumentDescriptionChangedDataRecordReceiver = new Subject<InstrumentDescriptionChangedEventNotification>();
  public readonly instrumentRemovedFromServiceChangedDataRecordReceiver = new Subject<InstrumentRemovedFromServiceChangedEventNotification>();
  public readonly labItemsCellChangedEventNotificationReceiver = new Subject<LabItemsCellChangedEventNotification>();
  public readonly labItemsConsumableAddedEventNotificationReceiver = new Subject<LabItemsConsumableAddedNotification>();
  public readonly labItemsConsumableRemovedEventNotificationReceiver = new Subject<LabItemsConsumableRemovedEventNotification>();
  public readonly labItemsConsumableRestoredEventNotificationReceiver = new Subject<LabItemsConsumableRestoredEventNotification>();
  public readonly labItemsInstrumentRemovedEventNotificationReceiver = new Subject<LabItemsInstrumentRemovedEventNotification>();
  public readonly labItemsInstrumentRestoredEventNotificationReceiver = new Subject<LabItemsInstrumentRestoredEventNotification>();
  public readonly labItemsMaterialRemovedEventNotificationReceiver = new Subject<LabItemsMaterialRemovedEventNotification>()
  public readonly labItemsMaterialRestoredEventNotificationReceiver = new Subject<LabItemsMaterialRestoredEventNotification>();
  public readonly labItemsRefreshedColumnNotification = new Subject<LabItemsInstrumentColumnRefreshedNotification>();
  public readonly labItemsRefreshedInstrumentNotification = new Subject<LabItemsInstrumentRefreshedNotification>();
  public readonly labItemsRefreshedMaterialNotification = new Subject<LabItemsMaterialRefreshedNotification>();
  public readonly labItemsRemovedColumnNotification = new Subject<LabItemsInstrumentColumnRemovedEventNotification>();
  public readonly labItemsRestoredColumnNotification = new Subject<LabItemsInstrumentColumnRestoredEventNotification>();
  public readonly maintenanceEventSelectedDataRecordReceiver = new Subject<MaintenanceEventSelectedEventNotification>();
  public readonly nonRoutineIssueEncounteredDataChangeEventNotificationReceiver = new Subject<NonRoutineIssueEncounteredDataChangeEventNotification>();
  public readonly returnedToServiceDataChangeEventNotificationReceiver = new Subject<ReturnToServiceDataChangeEventNotification>();
  public readonly rowRemovedDataRecordReceiver = new Subject<RowRemovedEventNotification>();
  public readonly rowRestoredDataRecordReceiver = new Subject<RowRestoredEventNotification>();
  public readonly rowsRenumberedDataRecordReceiver = new Subject<RowsRenumberedEventNotification>();
  public readonly sampleTestAddedDataRecordReceiver = new Subject<SampleTestAddedEventNotification>();
  public readonly setVariableDataRecordReceiver = new Subject<SetVariableEventNotification>();
  public readonly studyActivitySelectedDataRecordReceiver = new Subject<StudyActivitySelectedEventNotification>();
  public readonly labItemPreparationRefreshedNotification = new Subject<LabItemsPreparationRefreshedNotification>();
  public readonly labItemsPreparationRemovedEventNotificationReceiver = new Subject<LabItemPreparationRemovedEventNotification>()
  public readonly labItemsPreparationRestoredEventNotificationReceiver = new Subject<LabItemPreparationRestoredEventNotification>()

  /**
   * Subject for subscribing to @see ClientFacingNoteChangedEventNotification
   * Note: There will be multiple subscribers. @see CommentsComponent will be first and observer order is guaranteed for a Subject.
   */
  public readonly clientFacingNoteChangedDataRecordReceiver = new Subject<ClientFacingNoteChangedEventNotification>();
  /**
   * Subject for subscribing to @see ClientFacingNoteCreatedEventNotification
   * Note: There will be multiple subscribers. @see CommentsComponent will be first and observer order is guaranteed for a Subject.
   */
  public readonly clientFacingNoteCreatedDataRecordReceiver = new Subject<ClientFacingNoteCreatedEventNotification>();
  public readonly experimentNodeOrderChangedNotificationReceiver = new Subject<ExperimentNodeOrderChangedNotification>();

  public static readonly labelsByItemType: {
    [itemType: string]: { heading: string };
  } = {
    material: {
      heading: $localize`:@@Material:Material`
    },
    instrumentDetails: {
      heading: $localize`:@@Instrument:Instrument`
    }
  };

  public static readonly Title = $localize`:@@title:Title`;

  private readonly nodeTitleChangeAuditHistory: {
    [nodeType: string]: {
      historyComposer: (notification: ExperimentNodeTitleChangedNotification) => AuditHistory;
    };
  } = {
    activity: {
      historyComposer: this.getActivityTitleChangedAuditContext.bind(this)
    },
    module: {
      historyComposer: this.getModuleTitleChangedAuditContext.bind(this)
    },
    table: {
      historyComposer: this.getTableTitleChangedAuditContext.bind(this)
    },
    form: {
      historyComposer: this.getFormTitleChangedAuditContext.bind(this)
    }
  };

  experimentWorkFlowPreviousStatus!: ExperimentDataRecordNotification;

  public readonly experimentNotificationOnlyReceiver: {
    [key: string]: Subject<ExperimentNotificationOnlyResponse>;
  } = {
      chromatographyDataImported: new Subject<ExperimentNotificationOnlyResponse>(),
      chromatographyDataRefreshed: new Subject<ExperimentNotificationOnlyResponse>()
    };

  public static readonly MessageTypeMapForNotification: { [key: string]: string } = {
    'information': 'info',
    'warning': 'warn',
    'error': 'error',
    'validation': 'warn',
    1: 'info',
    2: 'warn',
    3: 'error',
    4: 'warn'
  };

  constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly dataValueService: DataValueService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly experimentService: ExperimentService,
    private readonly experimentTemplateEventService: ExperimentTemplateEventService,
    private readonly labsiteService: LabsiteService,
    private readonly messageService: MessageService,
    private readonly searchService: BookshelfService,
    private readonly specificationService: SpecificationService,
    private readonly titleCasePipe: TitleCasePipe,
    private readonly unitLoaderService: UnitLoaderService,
    private readonly userService: UserService,
    public readonly projectLogLoaderService: ProjectLogLoaderService,    
    private readonly activityReferencesService: ActivityReferencesService,
  ) {
    searchService.rootUrl = environment.searchServiceUrl;
    this.experimentNotificationService.dataRecordReceiver.subscribe((data) =>
      this.applyDataRecord(data)
    );
    this.experimentNotificationService.experimentNotificationOnlyReceiver.subscribe({
      next: this.notifyExperimentNotificationOnly.bind(this)
    });
  }

  /** 
   * Updates experiment (and percent completion etc) synchronously! THEN broadcasts that the event occurred.
   * 
   * Note: for some events these two steps are not done correctly. Update should be done in service, not UI components (which might not exist)
   * */
  private applyDataRecord(dataRecords: ExperimentDataRecordNotification | ExperimentDataRecordNotification[]) {
    this.experimentService.amICollaborator().subscribe({
      next: amICollaborator => {
        if (amICollaborator) this.experimentService._isCurrentUserCollaboratorSubject$.next(amICollaborator);
      }
    });    
    if (!Array.isArray(dataRecords)) dataRecords = [dataRecords];
    dataRecords.forEach(data => {
      switch (data.eventContext.eventType) {
        case ExperimentEventType.RowRemoved:
          const rowRemoved = data as RowRemovedEventNotification;
          this.experimentService.applyRowRemovedDataRecord(rowRemoved);
          this.rowRemovedDataRecordReceiver.next(rowRemoved);
          break;
        case ExperimentEventType.RowRestored:
          const rowRestored = data as RowRestoredEventNotification;
          this.experimentService.applyRowRestoredDataRecord(rowRestored);
          this.rowRestoredDataRecordReceiver.next(rowRestored);
          break;
        case ExperimentEventType.RowsRenumbered:
          const rowsRenumbered = data as RowsRenumberedEventNotification;
          this.experimentService.applyRowsRenumberedDataRecord(rowsRenumbered);
          this.rowsRenumberedDataRecordReceiver.next(rowsRenumbered);
          break;
        case ExperimentEventType.CellChanged:
          const cellChangedDr = data as CellChangedEventNotification;
          this.experimentService.applyCellChange(
            cellChangedDr.tableIds,
            cellChangedDr.rowIds,
            cellChangedDr.columnValues
          );
          this.cellChangedDataRecordReceiver.next(cellChangedDr);
          break;
        case ExperimentEventType.RowsAdded:
          const tableAddRowDr = data as AddRowEventNotification;
          this.experimentService.applyAddRow(tableAddRowDr.tableId, tableAddRowDr.rows);
          this.addRowsDataRecordReceiver.next(tableAddRowDr);
          break;
        case ExperimentEventType.FieldChanged:
          const fieldChangeDr = data as FieldChangedEventNotification;
          this.fieldChangedDataRecordReceiver.next(fieldChangeDr);
          this.experimentService.applyFieldChange(
            fieldChangeDr.formId,
            fieldChangeDr.path,
            fieldChangeDr.newValue
          );
          break;
        case ExperimentEventType.ClientFacingNoteCreated:
          this.clientFacingNoteCreatedDataRecordReceiver.next(data as ClientFacingNoteCreatedEventNotification);
          break;
        case ExperimentEventType.ClientFacingNoteChanged:
          this.clientFacingNoteChangedDataRecordReceiver.next(data as ClientFacingNoteChangedEventNotification);
          break;
        case ExperimentEventType.ExperimentStarted:
        case ExperimentEventType.ExperimentCancelled:
        case ExperimentEventType.ExperimentRestored:
        case ExperimentEventType.ExperimentSentForReview:
        case ExperimentEventType.ExperimentSentForCorrection:
        case ExperimentEventType.ExperimentAuthorized:
          this.experimentWorkFlowDataRecordReceiver.next(data as WorkflowEventNotification);
          break;
        case ExperimentEventType.ExperimentTemplateApplied:
          this.notifyTemplateAppliedMessage(data as ExperimentTemplateAppliedEventNotification);
          break;
        case ExperimentEventType.SampleTestChanged:
          this.sampleTestAddedDataRecordReceiver.next(data as SampleTestAddedEventNotification);
          break;
        case ExperimentEventType.ActivityInputCellChanged:
          this.activityInputCellChangedDataRecordReceiver.next(data as ActivityInputCellChangedEventNotification);
          break;
        case ExperimentEventType.ActivityInputRowRestored:
          this.activityInputRowRestoredDataRecordReceiver.next(data as ActivityInputRowRestoredEventNotification);
          break;
        case ExperimentEventType.ActivityInputRowRemoved:
          this.activityInputRowRemovedDataRecordReceiver.next(data as ActivityInputRowRemovedEventNotification);
          break;
        case ExperimentEventType.ActivityCrossReferenceAdded:
          this.activityReferencesService.applyActivityCrossReferenceAdded(data as CrossReferenceAddedEventNotification);
          this.crossReferenceAddedEventReceiver.next(data as CrossReferenceAddedEventNotification);
          break;
        case ExperimentEventType.ActivityCrossReferenceChanged:
          this.activityReferencesService.applyActivityCrossReferenceChanged(data as CrossReferenceChangedEventNotification);
          this.crossReferenceChangedEventReceiver.next(data as CrossReferenceChangedEventNotification);
          break;
        case ExperimentEventType.ActivityCrossReferenceRemoved:
          this.activityReferencesService.applyActivityCrossReferenceRemoved(data as CrossReferenceRemovedEventNotification);
          this.crossReferenceRemovedEventReceiver.next(data as CrossReferenceRemovedEventNotification);
          break;
        case ExperimentEventType.ActivityCrossReferenceRestored:
          this.activityReferencesService.applyActivityCrossReferenceRestored(data as CrossReferenceRestoredEventNotification);
          this.crossReferenceRestoredEventReceiver.next(data as CrossReferenceRestoredEventNotification);
          break;
        case ExperimentEventType.ActivityInputRowRefreshed:
          this.activityInputRowRefreshedDataRecordReceiver.next(data as ActivityInputRowRefreshedEventNotification);
          break;
        case ExperimentEventType.VariableCreated:
          this.setVariableDataRecordReceiver.next(data as SetVariableEventNotification);
          break;
        case ExperimentEventType.StudyActivitySelected:
          this.studyActivitySelectedDataRecordReceiver.next(data as StudyActivitySelectedEventNotification);
          break;
        case ExperimentEventType.MaintenanceEventSelected:
          this.maintenanceEventSelectedDataRecordReceiver.next(data as MaintenanceEventSelectedEventNotification);
          break;
        case ExperimentEventType.LabItemsMaterialRemoved:
          this.labItemsMaterialRemovedEventNotificationReceiver.next(data as LabItemsMaterialRemovedEventNotification);
          break;
        case ExperimentEventType.InstrumentDescriptionChanged:
          this.instrumentDescriptionChangedDataRecordReceiver.next(data as InstrumentDescriptionChangedEventNotification);
          break;
        case ExperimentEventType.InstrumentDateRemovedChanged:
          this.instrumentDateRemovedChangedDataRecordReceiver.next(data as InstrumentDateRemovedChangedEventNotification);
          break;
        case ExperimentEventType.InstrumentRemovedFromServiceChanged:
          this.instrumentRemovedFromServiceChangedDataRecordReceiver.next(data as InstrumentRemovedFromServiceChangedEventNotification);
          break;
        case ExperimentEventType.LabItemsMaterialRefreshed:
          this.labItemsRefreshedMaterialNotification.next(data as LabItemsMaterialRefreshedNotification);
          break;
        case ExperimentEventType.LabItemsInstrumentRefreshed:
          this.labItemsRefreshedInstrumentNotification.next(data as LabItemsInstrumentRefreshedNotification);
          break;
        case ExperimentEventType.LabItemsInstrumentColumnRefreshed:
          this.labItemsRefreshedColumnNotification.next(data as LabItemsInstrumentColumnRefreshedNotification);
          break;
        case ExperimentEventType.LabItemsInstrumentColumnRemoved:
          this.labItemsRemovedColumnNotification.next(data as LabItemsInstrumentColumnRemovedEventNotification);
          break;
        case ExperimentEventType.LabItemsInstrumentColumnRestored:
          this.labItemsRestoredColumnNotification.next(data as LabItemsInstrumentColumnRestoredEventNotification);
          break;
        case ExperimentEventType.LabItemsMaterialRestored:
          this.labItemsMaterialRestoredEventNotificationReceiver.next(data as LabItemsMaterialRestoredEventNotification);
          break;
        case ExperimentEventType.LabItemsCellChanged:
          this.labItemsCellChangedEventNotificationReceiver.next(data as LabItemsCellChangedEventNotification);
          break;
        case ExperimentEventType.LabItemsInstrumentRemoved:
          this.labItemsInstrumentRemovedEventNotificationReceiver.next(data as LabItemsInstrumentRemovedEventNotification);
          break;
        case ExperimentEventType.LabItemsInstrumentRestored:
          this.labItemsInstrumentRestoredEventNotificationReceiver.next(data as LabItemsInstrumentRestoredEventNotification);
          break;
        case ExperimentEventType.LabItemsConsumableAdded:
          this.labItemsConsumableAddedEventNotificationReceiver.next(data as LabItemsConsumableAddedNotification);
          break;
        case ExperimentEventType.ActivityReferenceTemplateApplied:
          this.activityReferenceTemplateAppliedEventNotificationReceiver.next(data as ReferenceTemplateAppliedEventNotification);
          break;
        case ExperimentEventType.ExperimentNodeTitleChanged:
          this.experimentNodeTitleChangedNotificationReceiver.next(data as ExperimentNodeTitleChangedNotification);
          break;
        case ExperimentEventType.LabItemsConsumableRemoved:
          this.labItemsConsumableRemovedEventNotificationReceiver.next(data as LabItemsConsumableRemovedEventNotification);
          break;
        case ExperimentEventType.LabItemsConsumableRestored:
          this.labItemsConsumableRestoredEventNotificationReceiver.next(data as LabItemsConsumableRestoredEventNotification);
          break;
        case ExperimentEventType.ExperimentNodeOrderChanged:
          this.experimentNodeOrderChangedNotificationReceiver.next(data as ExperimentNodeOrderChangedNotification);
          break;
        case ExperimentEventType.ChromatographyDataImported:
          this.chromatographyDataImportedEventNotificationReceiver.next(data as ChromatographyDataImportedEventNotification);
          break;
        case ExperimentEventType.ChromatographyDataRefreshed:
          this.chromatographyDataRefreshedEventNotificationReceiver.next(data as ChromatographyDataRefreshedEventNotification);
          break;
        case ExperimentEventType.ChromatographyDataRemoved:
          this.chromatographyDataRemovedEventNotificationReceiver.next(data as ChromatographyDataRemovedEventNotification);
          break;
        case ExperimentEventType.InstrumentEventNonRoutineIssueEncountered:
          this.nonRoutineIssueEncounteredDataChangeEventNotificationReceiver.next(data as NonRoutineIssueEncounteredDataChangeEventNotification);
          break;
        case ExperimentEventType.InstrumentEventReturnedToService:
          this.returnedToServiceDataChangeEventNotificationReceiver.next(data as ReturnToServiceDataChangeEventNotification);
          break;
        case ExperimentEventType.ActivityFilesAdded:
          this.attachedFileEventNotification.next(data as AttachedFileEventNotification);
          break;
        case ExperimentEventType.LabItemPreparationRefreshed:
          this.labItemPreparationRefreshedNotification.next(data as LabItemsPreparationRefreshedNotification);
          break;
        case ExperimentEventType.ExperimentPreparationStatusChanged:
          this.experimentPreparationStatusChangedNotificationReceiver.next(
            data as ExperimentPreparationStatusChangedNotification
          );
          break;
        case ExperimentEventType.LabItemPreparationRemoved:
          this.labItemsPreparationRemovedEventNotificationReceiver.next(data as LabItemPreparationRemovedEventNotification);
          break;
        case ExperimentEventType.LabItemPreparationRestored:
          this.labItemsPreparationRestoredEventNotificationReceiver.next(data as LabItemPreparationRestoredEventNotification);
          break;
      }
      this.experimentService.lastProcessedDataRecordTimeStamp = data.eventContext.eventTime;
    });
  }

  private notifyTemplateAppliedMessage(event: ExperimentTemplateAppliedEventNotification): void {
    this.experimentTemplateEventService.notifyAppliedTemplateEvent(event);
  }

  private notifyExperimentNotificationOnly(notification: ExperimentNotificationOnlyResponse): void {
    if (this.experimentNotificationOnlyReceiver[notification.operationType]) {
      this.experimentNotificationOnlyReceiver[notification.operationType].next(notification);
    } else {
      this.displayUnHandledErrorNotification(
        notification.notifications,
        notification.operationType
      );
    }
  }

  private extractNodesFromCurrentExperiment(): void {
    if (!this.experiment) {
      return;
    }
    this.allModuleItemsInExperiment = [];
    this.allModulesInExperiment = [];
    this.experiment.activities.forEach(activity => {
      this.extractModuleNodeFromCurrentExperiment(activity);
    })
  }

  private extractModuleNodeFromCurrentExperiment(activity: Activity): void {
    if (!activity.dataModules) {
      return;
    }
    activity.dataModules.forEach(module => {
      this.extractTableAndFormFromCurrentExperiment(module);
      this.allModulesInExperiment.push(module);
    })
  }

  private extractTableAndFormFromCurrentExperiment(module: Module): void {
    if (!module.items) {
      return;
    }
    module.items.forEach(moduleItem => {
      this.allModuleItemsInExperiment.push(moduleItem);
    })
  }

  public async getAuditHistoryAsync(records: ExperimentDataRecordNotification[]): Promise<AuditHistory[]> {
    this.experimentWorkFlowPreviousStatus = undefined as any;
    this.extractNodesFromCurrentExperiment();
    if (typeof this.usersList === 'undefined' || typeof this.labsites === 'undefined') {
      await forkJoin({
        r1: this.userService.usersActiveUsersPost$Json({
          body: ['ELN Analyst', 'ELN Reviewer', 'ELN Supervisor', 'ELN Viewer']
        }),
        r2: this.labsiteService.labsitesSubBusinessUnitsGet$Json({
          labsiteCodes: [this.currentUserService.currentUser.labSiteCode as string].join(',')
        })
      })
        .toPromise()
        .then((result: any) => {
          this.usersList = JSON.parse(JSON.stringify(result.r1));
          this.labsites = result.r2.labsites;
        })
        .catch((e) => {
          console.error(e);
        });
    }
    records = this.splitLabItemsCompositeDataRecords(records);
    const experimentIds = records
      .filter((r): r is CrossReferenceAddedEventNotification => r.eventContext.eventType === ExperimentEventType.ActivityCrossReferenceAdded)
      .map((r) => r.linkId);
    if (experimentIds.length) {
      (await this.lookupExperimentNumbers(experimentIds).toPromise())?.forEach(
        ({ experimentId, experimentNumber }) => this.experimentNumberCache[experimentId] = experimentNumber
      );
    }
    const dataSource: AuditHistory[] = [];
    this.getRecords(records, dataSource);
    dataSource.forEach(audit => audit.Time = formatInstant(audit.Time, DateAndInstantFormat.dateTimeToSecond));
    return Promise.resolve(dataSource);
  }

  lookupExperimentNumbers(experimentIds: string[]): Observable<{ experimentId: string, experimentNumber: string }[]> {
    const labSiteCode = this.experiment.organization.labSiteCode;
    const search: SearchCriteria = {
      bypassSecurity: false,
      filterConditions: [{
        conditionType: ConditionType.And,
        filters: [
          { columnName: 'experimentId', matchType: StringMatchType.In, values: experimentIds, isSecurityFlag: false, dataType: DataType.String },
          { columnName: 'labsiteCode', matchType: StringMatchType.Word, text: labSiteCode, isSecurityFlag: true, dataType: DataType.String },
        ],
      }],
      pagination: { pageNumber: 1, pageSize: 5000 },
      sort: []
    };
    return this.searchService.bookshelfSearchExperimentIndexPost$Json({ body: search })
      .pipe(map((experiments) => experiments.records
        .filter((e) => experimentIds.includes(e.experimentId))
        .map((e) => ({ experimentId: e.experimentId, experimentNumber: e.experimentNumber }))
      ));
  }

  private splitLabItemsCompositeDataRecords(records: ExperimentDataRecordNotification[]): ExperimentDataRecordNotification[] {
    records = this.splitLabItemsMaterialRefreshedCompositeDataRecords(records);
    return records;
  }

  private splitLabItemsMaterialRefreshedCompositeDataRecords(records: ExperimentDataRecordNotification[]): ExperimentDataRecordNotification[] {
    const compositeRecords = records.filter(record => record.eventContext.eventType === ExperimentEventType.LabItemsMaterialRefreshed);
    records = records.filter(record => record.eventContext.eventType !== ExperimentEventType.LabItemsMaterialRefreshed);
    compositeRecords.forEach(compositeRecord => {
      const dataValues = (compositeRecord as LabItemsMaterialRefreshedNotification).refreshedDataValues;
      Object.keys(dataValues).forEach((fieldName) => {
        const recordTemplate = clone(compositeRecord) as LabItemsMaterialRefreshedNotification;
        recordTemplate.refreshedDataValues = {};
        recordTemplate.refreshedDataValues[fieldName] = dataValues[fieldName];
        records.push(recordTemplate);
      });
    });
    return records;
  }

  private getLabItemsMaterialRefreshedHistoryContext(notification: ExperimentDataRecordNotification): AuditHistory {
    const record = notification as LabItemsMaterialRefreshedNotification;
    const changedField = Object.keys(record.refreshedDataValues)[0];
    const path = `${this.getActivityInputContextByType(ActivityInputType.Material, this.labItemsTitle)} > ${record.itemReference} > ${changedField}`;
    return this.getHistory(
      notification,
      path,
      $localize`:@@labItemRefreshed:Lab Item Refreshed`,
      $localize`:@@Table:Table`,
      '',
      `${record.refreshedDataValues[changedField].value}`,
      $localize`:@@LabItemsMaterialsTableTitle:Materials`
    );
  }

  private getLabItemsInstrumentRefreshedHistoryContext(notification: ExperimentDataRecordNotification): AuditHistory {
    const record = notification as LabItemsInstrumentRefreshedNotification;
    const changedField = Object.keys(record.refreshedDataValues)[0];
    const path = `${this.getActivityInputContextByType(ActivityInputType.InstrumentDetails, this.labItemsTitle)} > ${record.itemReference} > ${changedField}`;
    return this.getHistory(
      notification,
      path,
      $localize`:@@labItemRefreshed:Lab Item Refreshed`,
      $localize`:@@Table:Table`,
      '',
      `${record.refreshedDataValues[changedField].value}`,
      $localize`:@@Instrument:Instrument`
    );
  }

  /**
   * Gets the history-style formatted context of a activity
   */
  private getActivityContext(nodeId: string): { fullPath: string, title: string } {
    const activityTitle = this.experiment?.activities.find((f) => f.activityId === nodeId)?.itemTitle ?? $localize`:@@NoTitle:No Title`;
    return { fullPath: `${activityTitle}`, title: activityTitle };
  }

  private getActivityInputContextByType(type: ActivityInputType, contextTitle: string): string {
    const pageTitle = this.getActivityInputTypeTitleByType(type);
    return `${this.getActivityContext(this.experimentService.currentActivityId).fullPath} > ${contextTitle} > ${pageTitle}`;
  }

  private getActivityInputTypeTitleByType(type: ActivityInputType): string {
    let pageTitle = '';
    switch (type) {
      case ActivityInputType.Material:
        pageTitle = this.labItemsMaterialTitle;
        break;
      case ActivityInputType.InstrumentDetails:
        pageTitle = this.labItemsInstrumentTitle;
        break;
      case ActivityInputType.Consumable:
        pageTitle = this.labItemsConsumableTitle;
        break;
      case ActivityInputType.InstrumentColumn:
        pageTitle = this.labItemsInstrumentColumnTitle;
        break;
      case ActivityInputType.Preparation:
        pageTitle = this.labItemsPreparationTitle;
        break;
    }
    return pageTitle;
  }

  private getLabItemClientFacingNoteContext(note: ClientFacingNote): string {
    const labItemContext = ClientFacingNoteModel.getContextByTypeAndPath(
      note.nodeId,
      note.contextType,
      note.path
    );
    const labItemsContextTitle = this.getActivityInputTypeTitleByType(labItemContext.labItemType as ActivityInputType);
    let fieldName;
    if (note.path[3] === ActivityInputType.Material) {
      fieldName = LabItemsMaterialTableOptions.ColumnDefinition[note.path[1]]?.displayName;
    } else if (note.path[3] === ActivityInputType.InstrumentDetails) {
      fieldName = LabItemsInstrumentTableOptions.ColumnDefinition[note.path[1]]?.displayName;
    }
    else if (note.path[3] === ActivityInputType.InstrumentColumn) {
      fieldName = LabItemsColumnTableOptions.ColumnDefinition[note.path[1]]?.displayName;
    }
    else if (note.path[3] === ActivityInputType.Consumable) {
      fieldName = LabItemsConsumablesTableOptions.ColumnDefinition[note.path[1]]?.displayName;
    }
    return `${this.getActivityContext(this.experimentService.currentActivityId).fullPath} > ${this.labItemsTitle
      } > ${labItemsContextTitle} > ${labItemContext.rowId} > ${fieldName}`;
  }

  private getActivityInputClientFacingNoteContext(note: ClientFacingNote): string {
    const context = ClientFacingNoteModel.getContextByTypeAndPath(
      note.nodeId,
      note.contextType,
      note.path
    );
    const fieldName = SampleTableGridOptions.prepareColumns().find(c => c.field === context.columnField);
    return `${this.getActivityContext(this.experimentService.currentActivityId).fullPath} > ${this.activityInputsTitle} > ${context.rowId} > ${fieldName?.label}`;
  }

  private getExperimentPreparationClientFacingNoteContext(note: ClientFacingNote): string {
    const context = ClientFacingNoteModel.getContextByTypeAndPath(
      note.nodeId,
      note.contextType,
      note.path
    );
    const activityId = this.experimentService.currentActivity?.activityId;
    const fullPath = activityId ? this.getActivityContext(activityId).fullPath : '';
    const fieldName = PreparationTableOptions.getDisplayValue(note.path[1]) ?? '';    
    const rowId = context.rowId ? this.getExperimentPreparationNumber(note.nodeId, context.rowId) : '';
    return `${fullPath} > ${this.experimentPreparationTitle} > ${rowId} > ${fieldName}`;
  }

  private getLabItemPreparationClientFacingNoteContext(note: ClientFacingNote): string {
    const labItemContext = ClientFacingNoteModel.getContextByTypeAndPath(
      note.nodeId,
      note.contextType,
      note.path
    );
    const activityId = this.experimentService.currentActivity?.activityId;
    const fullPath = activityId ? this.getActivityContext(activityId).fullPath : '';
    const labItemsContextTitle = this.getActivityInputTypeTitleByType(ActivityInputType.Preparation);
    const fieldName = PreparationTableOptions.getDisplayValue(note.path[1]) ?? '';    
    const rowId = labItemContext.rowId ? this.getPreparationNumber(note.nodeId, labItemContext.rowId) : '';
    return `${fullPath} > ${this.labItemsTitle} > ${labItemsContextTitle} > ${rowId} > ${fieldName}`;
  }
  
  private getExperimentPreparationNumber(activityId: string, preparationId: string): string {
    const preparations = this.experimentService.currentExperiment?.activities?.find(
      (activity: Activity) => activity.activityId === activityId)?.preparations;
    if (preparations) {
      return preparations.find((preparation: ExperimentPreparation) => preparation.preparationId === preparationId)?.preparationNumber ?? '';
    }
    return '';
  }

  private getActivityLabItemNode(activityId: string) : ActivityLabItemsNode | undefined {
    return this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === activityId
    );
  }
  
  private getPreparationNumber(activityId: string, preparationId: string): string {
    const preparations = this.getActivityLabItemNode(activityId)?.preparations;
      if (preparations) {
        return preparations.find((preparation: ExperimentPreparation) => preparation.preparationId === preparationId)?.preparationNumber ?? '';
      }
    return '';
  }
  
  isFormHistoryRecord(rec: ExperimentDataRecordNotification): rec is HistoryRecord {
    if (!rec) return false;
    return 'newValue' in rec
  }

  isTableHistoryRecord(rec: ExperimentDataRecordNotification): rec is HistoryRecord {
    if (!rec) return false;
    return 'columnValues' in rec
  }

  private getRecords(records: ExperimentDataRecordNotification[], dataSource: AuditHistory[]) {
    records?.forEach((record) => {
      let newValue: any;
      if (this.isTableHistoryRecord(record)) {
        newValue = record?.columnValues[0].propertyValue.value;
      } else if (this.isFormHistoryRecord(record)) {
        newValue = record?.newValue.value;
      }

      const isMultiselect = Array.isArray(newValue);
      const totalHistory = this.getFullContext(record, records);

      if (!totalHistory || totalHistory.length === 0) return;
      totalHistory.forEach((history: AuditHistory) => {
        if (!history) return;
        const historyRecords = dataSource.filter(
          (c) => c.ActualContext === history.ActualContext
        );

        if (historyRecords.length > 0) {
          history.RecordVersion = historyRecords[historyRecords.length - 1].RecordVersion + 1;
          if (isMultiselect) {
            const previousVersion: ExperimentDataRecordNotification = records[records.indexOf(record) - 1];
            this.processMultiselectHistory(previousVersion, newValue, history);
          }
          dataSource?.push(history);
        } else {
          if (isMultiselect) this.processMultiselectHistory(records[records.indexOf(record) - 1], newValue, history);
          dataSource?.push(history);
        }
      });
    });
  }

  public processMultiselectHistory(previousVersion: ExperimentDataRecordNotification, newValue: any, history: AuditHistory) {
    let oldValue: string[] = [];

    if (previousVersion) {
      if (this.isFormHistoryRecord(previousVersion)) {
        oldValue = previousVersion.newValue.value;
      } else {
        oldValue = this.isTableHistoryRecord(previousVersion) ? previousVersion.columnValues[0].propertyValue.value : [];
      }
    }

    if (Array.isArray(oldValue)) {
      const selected = difference(newValue, oldValue);
      const unselected = difference(oldValue, newValue); 
      history.Description = this.getMultiselectDescription(history.Description, selected, unselected);
    }
  }

  /**
   * TODO: This method needs revisit as it currently needs to check all the event type for every record refer PBI 3084763
   * gets the full context of the record
   * @param record experiment data record
   * @returns
   */
  public getFullContext(record: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]): AuditHistory[] {
    const noContext = $localize`:@@NoContext:No Context`;
    let history: AuditHistory[];
    switch (record.eventContext.eventType.toString()) {
      case ExperimentEventType.AssignedAnalystsChanged:
      case ExperimentEventType.AssignedReviewersChanged:
      case ExperimentEventType.AssignedSupervisorsChanged:
      case ExperimentEventType.AuthorizationDueDateChanged:
      case ExperimentEventType.ExperimentCreated:
      case ExperimentEventType.ExperimentTemplateApplied:
      case ExperimentEventType.ScheduledReviewStartDateChanged:
      case ExperimentEventType.ScheduledStartDateChanged:
      case ExperimentEventType.SubBusinessUnitsChanged:
      case ExperimentEventType.TagsChanged:
      case ExperimentEventType.TitleChanged:
        history = [this.getExperimentRecordContext(record, records)];
        break;
      case ExperimentEventType.ExperimentAuthorized:
      case ExperimentEventType.ExperimentCancelled:
      case ExperimentEventType.ExperimentRestored:
      case ExperimentEventType.ExperimentSentForCorrection:
      case ExperimentEventType.ExperimentSentForReview:
      case ExperimentEventType.ExperimentStarted:
        history = this.getExperimentWorkflowStatesContext(record);
        break;
      case ExperimentEventType.CellChanged:
      case ExperimentEventType.RowRemoved:
      case ExperimentEventType.RowRestored:
      case ExperimentEventType.RowsAdded:
      case ExperimentEventType.RowsRenumbered:
        history = this.getTableRecordContext(record);
        break;
      case ExperimentEventType.FieldChanged:
        history = [this.getFormRecordContext(record)];
        break;
      case ExperimentEventType.ClientFacingNoteChanged:
        history = [this.getClientFacingNoteChangedRecord(record)];
        break;
      case ExperimentEventType.ClientFacingNoteCreated:
        history = [this.getClientFacingNoteCreatedRecord(record)];
        break;
      case ExperimentEventType.ActivityCrossReferenceAdded:
      case ExperimentEventType.ActivityCrossReferenceRemoved:
      case ExperimentEventType.ActivityCrossReferenceRestored:
      case ExperimentEventType.ActivityInputCellChanged:
      case ExperimentEventType.ActivityInputRowRefreshed:
      case ExperimentEventType.ActivityInputRowRemoved:
      case ExperimentEventType.ActivityInputRowRestored:
      case ExperimentEventType.AliquotAdded:
      case ExperimentEventType.InstrumentAdded:
      case ExperimentEventType.InstrumentColumnAdded:
      case ExperimentEventType.InstrumentDateRemovedChanged:
      case ExperimentEventType.InstrumentDescriptionChanged:
      case ExperimentEventType.InstrumentRemovedFromServiceChanged:
      case ExperimentEventType.LabItemsCellChanged:
      case ExperimentEventType.LabItemsConsumableAdded:
      case ExperimentEventType.LabItemsConsumableRemoved:
      case ExperimentEventType.LabItemsConsumableRestored:
      case ExperimentEventType.LabItemsInstrumentAdded:
      case ExperimentEventType.LabItemsInstrumentRemoved:
      case ExperimentEventType.LabItemsInstrumentRestored:
      case ExperimentEventType.LabItemsMaterialAdded:
      case ExperimentEventType.LabItemsMaterialRemoved:
      case ExperimentEventType.LabItemsMaterialRestored:
      case ExperimentEventType.MaintenanceEventSelected:
      case ExperimentEventType.MaterialAdded:
      case ExperimentEventType.SampleTestChanged:
      case ExperimentEventType.StudyActivitySelected:
        history = [this.getActivityRecordContext(record, records)];
        break;
      case ExperimentEventType.LabItemsInstrumentColumnRemoved:
        history = [this.getLabItemsInstrumentColumnRemovedRecord(record as LabItemsInstrumentColumnRemovedEventNotification)];
        break;
      case ExperimentEventType.LabItemsInstrumentColumnRestored:
        history = [this.getLabItemsInstrumentColumnRestoredRecord(record as LabItemsInstrumentColumnRestoredEventNotification)];
        break;
      case ExperimentEventType.LabItemsInstrumentColumnRefreshed:
        history = [this.getLabItemsInstrumentColumnRefreshedRecord(record as LabItemsInstrumentColumnRefreshedNotification)];
        break;
      case ExperimentEventType.LabItemsMaterialRefreshed:
        history = [this.getLabItemsMaterialRefreshedHistoryContext(record)];
        break;
      case ExperimentEventType.LabItemsInstrumentRefreshed:
        history = [this.getLabItemsInstrumentRefreshedHistoryContext(record)];
        break;
      case ExperimentEventType.ExperimentNodeTitleChanged:
        history = [this.getExperimentNodeTItleChangedContext(
          record as ExperimentNodeTitleChangedNotification
        )];
        break;
      case ExperimentEventType.ActivityCrossReferenceChanged:
        history = [this.getActivityCrossReferenceChangedRecordContext(record as CrossReferenceChangedEventNotification)];
        break;
      case ExperimentEventType.ChromatographyDataImported:
        history = [this.getChromatographyDataImportedRecordContext(record as ChromatographyDataImportedEventNotification)];
        break;
      case ExperimentEventType.ChromatographyDataRemoved:
        history = [this.getChromatographyDataRemovedRecordContext(record as ChromatographyDataRemovedEventNotification)];
        break;
      case ExperimentEventType.ChromatographyDataRefreshed:
        history = [...this.getChromatographyDataRefreshedRecordContext(record as ChromatographyDataRefreshedEventNotification)];
        break;
      case ExperimentEventType.InstrumentEventNonRoutineIssueEncountered:
        history = [this.getInstrumentEventNonRoutineIssueEncounteredRecordContext(record as NonRoutineIssueEncounteredDataChangeEventNotification)];
        break;
      case ExperimentEventType.InstrumentEventReturnedToService:
        history = [this.getInstrumentEventReturnedToServiceRecordContext(record as ReturnToServiceDataChangeEventNotification)];
        break;
      case ExperimentEventType.ExperimentPreparationCreated:
        history = this.getPreparationEventRecordContext(record as ExperimentPreparationsCreatedNotification);
        break;
      case ExperimentEventType.ExperimentPreparationRestored:
        history = [this.getPreparationRestoredEventRecordContext(record as ActivityPreparationRestoredNotification)];
        break;
      case ExperimentEventType.ExperimentPreparationRemoved:
        history = [this.getPreparationRemovedEventRecordContext(record as ActivityPreparationRestoredNotification)];
        break;
      case ExperimentEventType.ExperimentPreparationDiscardedOrConsumed:
        history = [this.getPreparationDiscardOrConsumedEventRecordContext(record as PreparationDiscardedOrConsumedNotification)];
        break;
      case ExperimentEventType.ExperimentPreparationInternalInformationChanged:
        history = [this.getPreparationInternalInformationChangedEventRecordContext(record as PreparationInternalInformationChangedNotification)];
        break;
      case ExperimentEventType.ExperimentPreparationCellChanged:
        history = [this.getPreparationCellChangedEventRecordContext(record as PreparationCellChangedNotification)];
        break;
      case ExperimentEventType.ExperimentPreparationStatusChanged:
        history = [this.getPreparationStatusChangedEventRecordContext(record as ExperimentPreparationStatusChangedNotification)];
        break;
      case ExperimentEventType.ActivityReferenceTemplateApplied:
        history = [this.getActivityReferenceTemplateAppliedEventRecordContext(record as ReferenceTemplateAppliedEventNotification)];
        break;
      case ExperimentEventType.ActivityFilesAdded:
        history = [this.getActivityFilesAddedEventRecordContext(record as AttachedFileEventNotification)];
        break;
      case ExperimentEventType.ActivityFilesDeleted:
        history = [this.getActivityFilesDeletedEventRecordContext(record as DeletedFilesEventNotification)];
        break;
      case ExperimentEventType.LabItemsPreparationAdded:
        history = [this.getLabItemsPreparationAddedRecord(record as LabItemPreparationAddedEventNotification)];
        break;
      case ExperimentEventType.LabItemPreparationRemoved:
        history = [this.getLabItemsPreparationRemovedRecord(record as LabItemPreparationRemovedEventNotification)];
        break;
      case ExperimentEventType.LabItemPreparationRestored:
        history = [this.getLabItemsPreparationRestoredRecord(record as LabItemPreparationRestoredEventNotification)];
        break;
      case ExperimentEventType.LabItemPreparationRefreshed:
        history = [this.getLabItemsPreparationRefreshedRecord(record as LabItemsPreparationRefreshedNotification)];
        break;
      default:
        history = [{
          Time: record.eventContext.eventTime,
          Context: noContext,
          RecordType: record.eventContext.eventType.toString(),
          ContextType: noContext,
          Name: noContext,
          Description: noContext,
          RecordVersion: 1,
          ActualContext: noContext
        }];
        break;
    }
    return history;
  }

  getActivityReferenceTemplateAppliedEventRecordContext(event: ReferenceTemplateAppliedEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const refAdded = $localize`:@@referenceAdded:Reference table added`;
    const recordType = $localize`:@@referenceTemplateApplied:Reference, New`;
    const type = this.titleCasePipe.transform(event.type); // Capitalize first letter
    const context = `${this.experiment.title}/${activityName}/${type}`;
    return this.getHistory(event, context, recordType, type, recordType, `${refAdded}: ${activityName}/${type}`);
  }

  getExperimentWorkflowStatesContext(experimentRecord: ExperimentDataRecordNotification): AuditHistory[] {
    const historyList: AuditHistory[] = [];
    (experimentRecord as WorkflowEventNotification).eSignatureContext = {
      signed: true
    };
    let eSignedRecord: AuditHistory | undefined;
    switch (experimentRecord.eventContext.eventType) {
      case ExperimentEventType.ExperimentRestored:
        eSignedRecord = this.getESignedRecordOfExperimentRestoredToSetupTransition(experimentRecord, this.experimentWorkFlowPreviousStatus);
        historyList.push(this.getExperimentSetupReviewRecord(experimentRecord));
        break;
      case ExperimentEventType.ExperimentStarted:
        eSignedRecord = this.getESignedRecordOfExperimentStarted(experimentRecord, this.experimentWorkFlowPreviousStatus);
        historyList.push(this.getExperimentStartedRecord(experimentRecord));
        break;
      case ExperimentEventType.ExperimentSentForReview:
        eSignedRecord = this.getESignedRecordOfExperimentInReview(experimentRecord, this.experimentWorkFlowPreviousStatus);
        historyList.push(this.getExperimentSentForReviewRecord(experimentRecord));
        break;
      case ExperimentEventType.ExperimentSentForCorrection:
        eSignedRecord = this.getESignedRecordOfExperimentInCorrection(experimentRecord, this.experimentWorkFlowPreviousStatus);
        historyList.push(this.getExperimentSentForCorrectionRecord(experimentRecord));
        break;
      case ExperimentEventType.ExperimentAuthorized:
        eSignedRecord = this.getESignedRecordOfExperimentAuthorized(experimentRecord);
        historyList.push(this.getExperimentAuthorizedRecord(experimentRecord));
        break;
      case ExperimentEventType.ExperimentCancelled:
        eSignedRecord = this.getESignedRecordOfExperimentCancelled(experimentRecord);
        historyList.push(this.getExperimentCancelledRecord(experimentRecord));
        break;
    }
    if (eSignedRecord) {
      historyList.push(eSignedRecord);
    }
    this.experimentWorkFlowPreviousStatus = experimentRecord;
    return historyList;
  }

  /**
   * Gets the history-style formatted context of a table
   */
  private getTableContext(tableId: string): string {

    const tableTitle = this.getTableTitleForExperiment(tableId);

    const parentModule = this.experiment.activities.flatMap(a => a.dataModules)
      .find(m => m.items.filter((mi): mi is Table => mi.itemType === NodeType.Table).find((t: Table) => t.tableId === tableId));

    // activity reference tables are not associated with a module, therefore we need to inspect the activity's references.
    const parentActivity = this.experiment.activities.find(a => {
      const module = a.dataModules.find(m => m.moduleId === parentModule?.moduleId);
      return !!module || a.activityReferences.compendiaReferencesTableId === tableId || a.activityReferences.documentReferencesTableId === tableId
    });

    let parentPath = '';
    if (parentModule) {
      // traditional table nested within a module
      parentPath = `${parentActivity?.itemTitle} > ${parentModule.moduleLabel}`;
    }
    else if (parentActivity) {
      // specialized table node not associated with a module, such as document/compendia
      const label = $localize`:@@references:References`;
      parentPath = `${parentActivity.itemTitle} > ${label}`;
    }
    return `${parentPath} > ${tableTitle}`;
  }

  public getTableTitleForExperiment(tableId: string): string {
    return this.experimentService.getTable(tableId)?.itemTitle ?? $localize`:@@NoTitle:No Title`
  }

  public getFormTitleForExperiment(formId: string): string {
    const form = this.allModuleItemsInExperiment.find((f) => f.itemType === NodeType.Form && (f as Form).formId === formId);
    return form?.itemTitle ?? $localize`:@@NoTitle:No Title`
  }

  private getActivityInputContext(type: ActivityInputType): string {

    const activityTitle = this.experimentService.currentActivity?.itemTitle;

    let pageTitle = '';
    switch (type) {
      case ActivityInputType.Aliquot:
        pageTitle = this.sampleAliquotsTitle;
        break;
      case ActivityInputType.Material:
        pageTitle = this.materialAliquotsTitle;
        break;
      case ActivityInputType.Instrument:
        pageTitle = this.instrumentPageTitle;
        break;
    }

    return `${activityTitle} > ${this.activityInputsTitle} > ${pageTitle}`;
  }

  /**
   * Gets the history-style formatted context of a form
   */
  private getFormContext(formId: string): string {
    const formName =
      this.experimentResponse?.forms.find(f => f.formId === formId)?.itemTitle === undefined
        ? $localize`:@@NoTitle:No Title`
        : this.experimentResponse?.forms.find(f => f.formId === formId)?.itemTitle;
    const moduleId = this.experimentResponse?.modules.find((m) =>
      m.childOrder.find((c) => c === formId)
    )?.moduleId;
    const moduleDetails = [
      this.experimentResponse?.modules.find(m => m.childOrder.find(c => c === formId))?.moduleLabel,
      moduleId
    ];
    const activityName = this.experimentResponse?.activities.find(a =>
      a.childOrder.find((c) => c === moduleDetails[1])
    )?.itemTitle;
    const module = `${activityName} > ${moduleDetails[0]}`;
    return `${module} > ${formName}`;
  }

  /**
   * Searches a tree of fields for a leaf field, accumulating the labels on the path
   * @param fieldDefinitions array of field or field-groups to iterate over and search into
   * @param field is the leaf to find
   * @returns label path of field-groups down to the field
   */
  getFieldLabelPathFromField(formId: string, field: string): string[] {
    const form = this.experimentService.getForm(formId);
    if (!form) throw new Error('Logic Error: Form not found in experiment ' + formId); // this can't happen!

    const labels: string[] = [];
    this.getFieldLabelPathFromFieldImpl(form.fieldDefinitions, field, labels);
    return labels;
  }

  /**
   * Implementation for the recursive part of getFieldLabelPathFromField
   * @param fieldDefinitions array of field or field-groups to iterate over and search into
   * @param field is the leaf to find
   * @param labels is the array modified to hold the candidate result
   * @returns true if leave found
   */
  private getFieldLabelPathFromFieldImpl(
    fieldDefinitions: (FieldGroupResponse | FieldDefinitionResponse)[],
    field: string,
    labels: string[]
  ): boolean {
    for (const definition of fieldDefinitions) {
      if ('fieldDefinitions' in definition) {
        labels.push(definition.itemTitle); // itemTitle is an old synonym for label.
        if (definition.field === field) return true;
        const found = this.getFieldLabelPathFromFieldImpl(
          definition.fieldDefinitions,
          field,
          labels
        );
        if (found) return true;
        labels.pop();
      } else if (definition.field === field) {
        labels.push(definition.label);
        return true;
      }
    }
    return false;
  }
  /**
   * gets context of experiment cover records
   * @param experimentRecord
   * @returns
   */
  private getExperimentRecordContext(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]): AuditHistory {
    let history!: AuditHistory;
    switch (experimentRecord.eventContext.eventType.toString()) {
      case ExperimentEventType.AssignedAnalystsChanged:
        history = this.getAssignedAnalystsRecord(experimentRecord, records);
        break;
      case ExperimentEventType.AssignedReviewersChanged:
        history = this.getAssignedReviewersRecord(experimentRecord, records);
        break;
      case ExperimentEventType.AssignedSupervisorsChanged:
        history = this.getAssignedSupervisorsRecord(experimentRecord, records);
        break;
      case ExperimentEventType.AuthorizationDueDateChanged:
        history = this.getAuthorizedDueDateRecord(experimentRecord);
        break;
      case ExperimentEventType.ExperimentCreated:
        history = this.getExperimentCreatedRecord(experimentRecord);
        break;
      case ExperimentEventType.ScheduledReviewStartDateChanged:
        history = this.getScheduledReviewStartDateRecord(experimentRecord);
        break;
      case ExperimentEventType.ScheduledStartDateChanged:
        history = this.getScheduledStartDateRecord(experimentRecord);
        break;
      case ExperimentEventType.SubBusinessUnitsChanged:
        history = this.getSubBusinessUnitsRecord(experimentRecord, records);
        break;
      case ExperimentEventType.TagsChanged:
        history = this.getTagsChangedRecord(experimentRecord);
        break;
      case ExperimentEventType.TitleChanged:
        history = this.getTitleChangedRecord(experimentRecord);
        break;
      case ExperimentEventType.ExperimentTemplateApplied:
        history = this.getTemplateAppliedRecord(experimentRecord);
        break;
    }
    return history;
  }

  private getClientFacingNoteChangedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ClientFacingNoteChangedEventNotification;
    return this.getHistory(
      experimentRecord,
      this.getClientFacingNoteHistoryContext(record.number),
      $localize`:@@ClientFacingNoteChanged:Client Facing Note Changed`,
      $localize`:@@ClientFacingNote:Client Facing Note`,
      'C',
      record.content.value?.toString() ?? ''
    );
  }

  private getClientFacingNoteCreatedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ClientFacingNoteCreatedEventNotification;
    return this.getHistory(
      experimentRecord,
      this.getClientFacingNoteHistoryContext(record.number),
      $localize`:@@ClientFacingNoteCreated:Client Facing Note Created`,
      $localize`:@@ClientFacingNote:Client Facing Note`,
      'C',
      record.content.value?.toString() ?? ''
    );
  }

  private getClientFacingNoteHistoryContext(number: number): string {
    const outputFormFieldIdentifiers = ['Non-Routine Issue Encountered', 'Returned to Service'];
    if (!this.experimentResponse) throw new Error('Logic Error: Experiment not found for client-facing note ' + number); // this can't happen!

    const note = this.experimentResponse.clientFacingNotes.find(n => n.number === number);
    if (!note) throw new Error('Logic Error: Note not found in experiment for client-facing note ' + number); // this can't happen!

    switch (note.contextType) {
      case ClientFacingNoteContextType.FormField:
        return outputFormFieldIdentifiers.includes(note.path[0]) ?
          `${this.getFieldContextForImpactAssessmentForm(note)}` :
          `${this.getFormContext(note.nodeId)} > ${this.getFieldLabelPathFromField(
            note.nodeId,
            note.path[0]
          ).join(' > ')}`;
      case ClientFacingNoteContextType.TableCell:
        return `${this.getTableCellContext(note.nodeId, note.path[0], note.path[1])}`;
      case ClientFacingNoteContextType.CrossReference:
        return `${this.getCrossReferenceCellContext(note.nodeId, note.path[0], note.path[1])}`;

      // Some of these will be implemented in Product Backlog Item 3146899: Client-facing Notes: Use for other contexts
      // https://alm1.eurofins.local/tfs/BPTCollection/Eurofins%20ELN/_workitems/edit/3146899
      case ClientFacingNoteContextType.Activity:
      case ClientFacingNoteContextType.LabItems:
        return `${this.getLabItemClientFacingNoteContext(note)}`;
      case ClientFacingNoteContextType.LabItemsPreparation:
        return `${this.getLabItemPreparationClientFacingNoteContext(note)}`;
      case ClientFacingNoteContextType.ActivityInput:
        return `${this.getActivityInputClientFacingNoteContext(note)}`;
      case ClientFacingNoteContextType.Preparations:
        return `${this.getExperimentPreparationClientFacingNoteContext(note)}`;
      case ClientFacingNoteContextType.ActivityGroup:
      case ClientFacingNoteContextType.Experiment:
      case ClientFacingNoteContextType.Form:
      case ClientFacingNoteContextType.Module:
      case ClientFacingNoteContextType.Table:
      default:
        // this can't happen unless new work is started but not finished.
        throw new Error(
          'Logic Error: Unimplemented ClientFacingNoteContextType' + note.contextType
        );
    }
  }

  private getFieldContextForImpactAssessmentForm(note: ClientFacingNote): string {
    const experimentName = this.experiment.experimentNumber;
    const activityName = this.experiment?.activities.find(activity => activity.activityId === note.nodeId)?.itemTitle;
    return `${experimentName} > ${activityName} > Outputs > Instrument Event Impact Assessment > Impact Assessment > ${note.path[0]}`;
  }

  private getExperimentAuthorizedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentAuthorizedEventNotification;
    const authorized = $localize`:@@experimentTransitionedTo:Transitioned to`;
    const description = authorized + this.getExperimentState(record.state);
    return this.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, $localize`:@@recordType-workflow:Workflow`),
      this.experiment.experimentNumber,
      `${this.cover} > ${description}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getExperimentSentForCorrectionRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentSentForCorrectionEventNotification;
    const sentForCorrection = $localize`:@@experimentTransitionedTo:Transitioned to `;
    const description = sentForCorrection + this.getExperimentState(record.state);
    return this.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, $localize`:@@recordType-workflow:Workflow`),
      this.experiment.experimentNumber,
      `${this.cover} > ${description}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getExperimentCancelledRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentCancelledEventNotification;
    const sentForCancellation = $localize`:@@TransitionedTo:Transitioned to `;
    const description = sentForCancellation + this.getExperimentState(record.state);
    return this.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, $localize`:@@recordType-workflow:Workflow`),
      this.experiment.experimentNumber,
      `${this.cover} > ${description}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getExperimentSentForReviewRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentSentForReviewEventNotification;
    const sentForReview = $localize`:@@experimentTransitionedTo:Transitioned to`;
    const description = sentForReview + this.getExperimentState(record.state);
    return this.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, $localize`:@@recordType-workflow:Workflow`),
      this.experiment.experimentNumber,
      `${this.cover} > ${description}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getTemplateAppliedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentTemplateAppliedEventNotification;
    const expTemplateApplied = $localize`:@@ExperimentTemplateApplied:Experiment Template Applied`;
    const description =
      $localize`:@@TemplatedAddedToExperiment:Template added to an experiment: ` + record.templateTitle;
    return this.getHistory(
      experimentRecord,
      `${this.cover} > ${expTemplateApplied}`,
      expTemplateApplied,
      this.cover,
      `${this.cover} > ${expTemplateApplied}`,
      description
    );
  }

  private getTitleChangedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as TitleChangedEventNotification;
    const titleChanged = $localize`:@@title:Title`;
    const description = $localize`:@@titleChangedColon:Title Changed: ` + record.title;
    return this.getHistory(
      experimentRecord,
      `${this.cover} > ${titleChanged}`,
      $localize`:@@titleChanged:Title Changed`,
      this.cover,
      `${this.cover} > ${titleChanged}`,
      description
    );
  }

  private getTagsChangedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as TagsChangedEventNotification;
    let description = '';
    if (record.addedTags?.length > 0) {
      description =
        $localize`:@@TagsAdded:Added Tags: ` + record.addedTags?.join(`,${ELNAppConstants.WhiteSpace}`);
    }
    if (record.removedTags?.length > 0) {
      description =
        description.length > 0
          ? description.concat(
            ELNAppConstants.WhiteSpace,
            $localize`:@@TagsRemoved:Removed Tags: ` + record.removedTags?.join(`,${ELNAppConstants.WhiteSpace}`)
          )
          : description.concat(
            $localize`:@@TagsRemoved:Removed Tags: ` + record.removedTags?.join(`,${ELNAppConstants.WhiteSpace}`)
          );
    }
    const tagsChanged = $localize`:@@tags:Tags`;
    return this.getHistory(
      experimentRecord,
      `${this.cover} > ${tagsChanged}`,
      $localize`:@@TagsChanged:Tags Changed`,
      this.cover,
      `${this.cover} > ${tagsChanged}`,
      description
    );
  }

  private getScheduledStartDateRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ScheduledStartDateChangedEventNotification;
    const scheduledStartDate =
      record.scheduledStartDate === null || record.scheduledStartDate === undefined
        ? ''
        : formatLocalDate(record.scheduledStartDate);
    const description = $localize`:@@ScheduledStartDateChanged:Scheduled Start Date Changed`;
    const scheduledStart = $localize`:@@ScheduledStartDate:Scheduled Start Date`;
    return this.getHistory(
      experimentRecord,
      `${this.cover} > ${scheduledStart}`,
      $localize`:@@ScheduledStartDateChanged:Scheduled Start Date Changed`,
      this.cover,
      `${this.cover} > ${scheduledStart}`,
      description.concat(': ', scheduledStartDate)
    );
  }

  private getScheduledReviewStartDateRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ScheduledReviewStartDateChangedEventNotification;
    const scheduledReviewDate =
      record.scheduledReviewStartDate === null || record.scheduledReviewStartDate === undefined
        ? ''
        : formatLocalDate(record.scheduledReviewStartDate);
    const description = $localize`:@@ScheduledReviewStartDateChanged:Scheduled Review Start Date Changed`;
    const scheduledReview = $localize`:@@ScheduledReviewStartDate:Scheduled Review Start Date`;
    return this.getHistory(
      experimentRecord,
      `${this.cover} > ${scheduledReview}`,
      $localize`:@@ScheduledReviewStartDateChanged:Scheduled Review Start Date Changed`,
      this.cover,
      `${this.cover} > ${scheduledReview}`,
      description.concat(': ', scheduledReviewDate)
    );
  }

  private getExperimentStartedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentStartedEventNotification;
    const experimentStarted = $localize`:@@experimentTransitionedTo:Transitioned to`;
    const description = experimentStarted + this.getExperimentState(record.state);
    return this.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, $localize`:@@recordType-workflow:Workflow`),
      this.experiment.experimentNumber,
      `${this.cover} > ${description}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getExperimentCreatedRecord(experimentRecord: ExperimentDataRecordNotification) {
    const expCreated = $localize`:@@ExperimentCreated:Experiment Created`;
    return this.getHistory(
      experimentRecord,
      `${this.cover} > ${expCreated}`,
      $localize`:@@ExperimentCreated:Experiment Created`,
      this.cover,
      $localize`:@@ExperimentCreated:Experiment Created`,
      expCreated
    );
  }

  private getAuthorizedDueDateRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as AuthorizationDueDateChangedEventNotification;
    const authorizedDueDate =
      record.authorizationDueDate === null || record.authorizationDueDate === undefined
        ? ''
        : formatLocalDate(record.authorizationDueDate);
    const description = $localize`:@@AssignedDueDateChanged:Assigned Due Date Changed`;
    const assignedDueDate = $localize`:@@AssignedDueDate:Assigned Due Date`;
    return this.getHistory(
      experimentRecord,
      `${this.cover} > ${assignedDueDate}`,
      $localize`:@@AssignedDueDateChanged:Assigned Due Date Changed`,
      this.cover,
      `${this.cover} > ${assignedDueDate}`,
      description.concat(': ', authorizedDueDate)
    );
  }

  private getSubBusinessUnitsRecord(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]) {
    const record = experimentRecord as SubBusinessUnitsChangedEventNotification;
    const currentValue = DataRecordService.replayHistory(experimentRecord, records);
    const description = this.getMultiselectDescription(
      this.getSubBusinessUnits(currentValue).join(', '), 
      this.getSubBusinessUnits(record.addedSubBusinessUnits), 
      this.getSubBusinessUnits(record.removedSubBusinessUnits)
    );
   
    const subBusinessUnitsChanged = $localize`:@@SubBusinessUnits:Sub Business Units`;
    return this.getHistory(
      experimentRecord,
      `${this.cover} > ${subBusinessUnitsChanged}`,
      $localize`:@@SubBusinessUnitsChanged:Sub Business Units Changed`,
      this.cover,
      `${this.cover} > ${subBusinessUnitsChanged}`,
      description
    );
  }

  /** Travel back in time and replay history to that point to see what the current value was at any given point in time */
  static replayHistory(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]): any[] {
    const eventType = experimentRecord.eventContext.eventType;
    const time = Instant.parse(experimentRecord.eventContext.eventTime);

    const recordsOfType = records.filter((r): r is AssignmentEventNotifications => ([eventType, ExperimentEventType.ExperimentCreated].includes(r.eventContext.eventType)));
    const historyRecords = recordsOfType
      .filter(r => r.eventContext.experimentId === experimentRecord.eventContext.experimentId)
      .map(h => ({ ...h, eventTime: Instant.parse(h.eventContext.eventTime) }))
      .sort((a, b) => a.eventTime.compareTo(b.eventTime));

    const createdEventAdded = (createdEvent: ExperimentCreatedEventNotification) => {
      switch (eventType) {
        case ExperimentEventType.AssignedAnalystsChanged: return createdEvent.assignedAnalysts;
        case ExperimentEventType.AssignedSupervisorsChanged: return createdEvent.assignedSupervisors;
        case ExperimentEventType.AssignedReviewersChanged: return createdEvent.assignedReviewers;
        case ExperimentEventType.SubBusinessUnitsChanged: return createdEvent.subBusinessUnits;
        default: return [];
      };
    };

    let currentValue: any[] = [];
    const timeTravel = historyRecords.filter(r => r.eventTime.compareTo(time) < 1);
    timeTravel.forEach(record => {
      let added: any[] = [];
      let removed: any[] = [];
      switch (record.eventContext.eventType) {
        case ExperimentEventType.ExperimentCreated:
          added = createdEventAdded(record as ExperimentCreatedEventNotification);
          break;
        case ExperimentEventType.AssignedSupervisorsChanged:
          const assignedSupervisorsChangedEventNotification = record as AssignedSupervisorsChangedEventNotification;
          added = assignedSupervisorsChangedEventNotification.addedSupervisors;
          removed = assignedSupervisorsChangedEventNotification.removedSupervisors;
          break;
        case ExperimentEventType.AssignedAnalystsChanged:
          const assignedAnalystsChangedEventNotification = record as AssignedAnalystsChangedEventNotification;
          added = assignedAnalystsChangedEventNotification.addedAnalysts;
          removed = assignedAnalystsChangedEventNotification.removedAnalysts;
          break;
        case ExperimentEventType.AssignedReviewersChanged:
          const assignedReviewersChangedEventNotification = record as AssignedReviewersChangedEventNotification;
          added = assignedReviewersChangedEventNotification.addedReviewers;
          removed = assignedReviewersChangedEventNotification.removedReviewers;
          break;
        case ExperimentEventType.SubBusinessUnitsChanged:
          const subBusinessUnitsChangedEventNotification = record as SubBusinessUnitsChangedEventNotification;
          added = subBusinessUnitsChangedEventNotification.addedSubBusinessUnits;
          removed = subBusinessUnitsChangedEventNotification.removedSubBusinessUnits; 
          break;
        default:
          throw new Error('Cannot replay history on unsupported type: ' + experimentRecord.eventContext.eventType);
      }
      currentValue.push(...difference(added, currentValue)); // duplicate data records is known issue, so need to de-dupe them
      currentValue = difference(currentValue, removed);
    });

    return currentValue;
  }

  private getAssignedSupervisorsRecord(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]) {
    const record = experimentRecord as AssignedSupervisorsChangedEventNotification;
    const currentValue = DataRecordService.replayHistory(experimentRecord, records);
    const description = this.getMultiselectDescription(this.getUsers(currentValue).join(', '), this.getUsers(record.addedSupervisors), this.getUsers(record.removedSupervisors));

    const assignedSupervisors = $localize`:@@AssignedSupervisors:Assigned Supervisors`;
    return this.getHistory(
      experimentRecord,
      `${this.cover} > ${assignedSupervisors}`,
      $localize`:@@AssignedSupervisorsChanged:Assigned Supervisors Changed`,
      this.cover,
      `${this.cover} > ${assignedSupervisors}`,
      description
    );
  }

  private getAssignedReviewersRecord(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]) {
    const record = experimentRecord as AssignedReviewersChangedEventNotification;
    const currentValue = DataRecordService.replayHistory(experimentRecord, records);
    const description = this.getMultiselectDescription(this.getUsers(currentValue).join(', '), this.getUsers(record.addedReviewers), this.getUsers(record.removedReviewers));

    const assignedReviewers = $localize`:@@AssignedReviewers:Assigned Reviewers`;
    return this.getHistory(
      experimentRecord,
      `${this.cover} > ${assignedReviewers}`,
      $localize`:@@AssignedReviewersChanged:Assigned Reviewers Changed`,
      this.cover,
      `${this.cover} > ${assignedReviewers}`,
      description
    );
  }

  private getAssignedAnalystsRecord(experimentRecord: ExperimentDataRecordNotification, records: ExperimentDataRecordNotification[]) {
    const currentValue = DataRecordService.replayHistory(experimentRecord, records);
    const record = experimentRecord as AssignedAnalystsChangedEventNotification;
    const description = this.getMultiselectDescription(this.getUsers(currentValue).join(', '), this.getUsers(record.addedAnalysts), this.getUsers(record.removedAnalysts));

    const assignedAnalyst = $localize`:@@assignedAnalystsNoParens:Assigned Analysts`;
    return this.getHistory(
      experimentRecord,
      `${this.cover} > ${assignedAnalyst}`,
      $localize`:@@AssignedAnalystsChanged:Assigned Analysts Changed`,
      this.cover,
      `${this.cover} > ${assignedAnalyst}`,
      description
    );
  }

  /**
   * @param currentValue String representing full value to display to user. Usually, this is a ', ' (comma-space) separated string.
   * i.e. 'Some value, Some other value'
   */
  private getMultiselectDescription(currentValue: string, selected: string[], unselected: string[]) {
    const selectedText = $localize`:@@selected:Selected`;
    const unselectedText = $localize`:@@unselected:Unselected`;
    const currentValueText = $localize`:@@currentValue:Current Value`;
    let description = `${currentValueText}: ${currentValue}\n`;

    selected.forEach((v: string) => {
      description += `${selectedText} ${v}\n`;
    });

    unselected.forEach((v: string) => {
      description += `${unselectedText} ${v}\n`;
    });

    return description.trim();
  }

  private getUsers(addedAnalysts: string[]) {
    return this.usersList.filter((u) => addedAnalysts.includes(u.puid)).map((m) => m.fullName);
  }

  private getSubBusinessUnits(record: string[]) {
    return first(this.labsites)
      ?.subBusinessUnits?.filter((item: { code: string }) => record.includes(item.code))
      .map((m: { displayLabel: any }) => m.displayLabel) as string[];
  }

  private getExperimentState(state: ExperimentWorkflowState) {
    let currentState = '';
    switch (state.toString()) {
      case ExperimentWorkflowState.Setup:
        currentState = $localize`:@@setupState:Setup`;
        break;
      case ExperimentWorkflowState.InProgress:
        currentState = $localize`:@@inProgressState:In Progress`;
        break;
      case ExperimentWorkflowState.InReview:
        currentState = $localize`:@@inReviewState:In Review`;
        break;
      case ExperimentWorkflowState.InCorrection:
        currentState = $localize`:@@inCorrectionState:In Correction`;
        break;
      case ExperimentWorkflowState.Authorized:
        currentState = $localize`:@@authorizedState:Authorized`;
        break;
      case ExperimentWorkflowState.Cancelled:
        currentState = $localize`:@@cancelledState:Cancelled`;
        break;
    }
    return currentState;
  }

  /**
   * gets context of table records
   * @param tableRecord
   * @returns
   */
  private getTableRecordContext(tableRecord: ExperimentDataRecordNotification): AuditHistory[] {
    let history!: AuditHistory[];
    switch (tableRecord.eventContext.eventType.toString()) {
      case ExperimentEventType.CellChanged:
        history = this.getCellChangedRecord(tableRecord);
        break;
      case ExperimentEventType.RowsAdded:
        history = [this.getRowsAddedRecord(tableRecord)];
        break;
      case ExperimentEventType.RowRemoved:
        history = [this.getRowRemovedRecord(tableRecord)];
        break;  
      case ExperimentEventType.RowRestored:
        history = [this.getRowRestoredRecord(tableRecord)];
        break;  
      case ExperimentEventType.RowsRenumbered:
        history = [this.getRowsRenumberedRecord(tableRecord)];
        break;  
    }
    return history;
  }

  private getChromatographyDataImportedRecordContext(event: ChromatographyDataImportedEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const empowerResultSet = $localize`:@@empowerResultSet:Empower Result Set `;
    const resultSetName = `${empowerResultSet}${event.resultSetId}`;
    const imported = $localize`:@@imported:Imported`;
    return {
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${this.activityOutputsTitle} > ${resultSetName}`,
      RecordType: $localize`:@@New:New`,
      ContextType: $localize`:@@Table:Table`,
      Name: resultSetName,
      Description: `${imported}: ${resultSetName}`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        (u) => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.chromatographyDataId + event.eventContext.eventType,
    }
  }

  private getChromatographyDataRemovedRecordContext(event: ChromatographyDataRemovedEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const resultSetId =
      this.experiment.activityOutputChromatographyResultSetsSummary?.find(resultSet => resultSet.chromatographyDataId === event.chromatographyDataId)?.resultSetId
      ?? OutputEmpowerService.removedChromatographyData.get(event.chromatographyDataId);
    const empowerResultSet = $localize`:@@empowerResultSet:Empower Result Set `;
    const resultSetName = `${empowerResultSet}${resultSetId}`;

    const removed = $localize`:@@removed:Removed`;
    return {
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${this.activityOutputsTitle} > ${resultSetName}`,
      RecordType: $localize`:@@removed:Removed`,
      ContextType: $localize`:@@Table:Table`,
      Name: resultSetName,
      Description: `${removed}: ${resultSetName}`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        (u) => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.chromatographyDataId + event.eventContext.eventType,
    }
  }

  private getChromatographyDataRefreshedRecordContext(event: ChromatographyDataRefreshedEventNotification): AuditHistory[] {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const resultSetId = this.experiment.activityOutputChromatographyData?.find(resultSet => resultSet.chromatographyDataId === event.chromatographyDataId)?.resultSetId;
    const empowerResultSet = $localize`:@@empowerResultSet:Empower Result Set `;
    const resultSetName = `${empowerResultSet}${resultSetId}`;
    const refreshHistory: AuditHistory[] = []
    event.resultsAdded?.forEach((idOfResultSet) => {
      refreshHistory.push({
        Time: event.eventContext.eventTime,
        Context: `${activityName} > ${this.activityOutputsTitle} > ${resultSetName}`,
        RecordType: $localize`:@@New:New`,
        ContextType: $localize`:@@Table:Table`,
        Name: $localize`:@@empowerTableTitle:Empower Results - ` + resultSetId,
        Description: $localize`:@@EmpowerResultIdAdded:Empower Result ${idOfResultSet} Added`,
        RecordVersion: 1,
        PerformedBy: this.usersList?.find(
          (u) => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
        )?.fullName,
        ActualContext: event.chromatographyDataId + event.eventContext.eventType,
      })
    })
    event.resultsRemoved?.forEach((result) => {
      refreshHistory.push({
        Time: event.eventContext.eventTime,
        Context: `${activityName} > ${this.activityOutputsTitle} > ${resultSetName}`,
        RecordType: $localize`:@@rowRemoved:Row Removed`,
        ContextType: $localize`:@@Table:Table`,
        Name: $localize`:@@empowerTableTitle:Empower Results - ` + resultSetId,
        Description: $localize`:@@EmpowerResultIdRemoved:Empower Result ${result} Removed`,
        RecordVersion: 1,
        PerformedBy: this.usersList?.find(
          (u) => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
        )?.fullName,
        ActualContext: event.chromatographyDataId + event.eventContext.eventType
      })
    })
    return refreshHistory
  }

  private getInstrumentEventNonRoutineIssueEncounteredRecordContext(event: NonRoutineIssueEncounteredDataChangeEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const impactAssessment = $localize`:@@impactAssessment:Impact Assessment`;

    return {
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${this.activityOutputsTitle} > ${impactAssessment}`,
      RecordType: $localize`:@@nonRoutineIssueEncounteredChanged:Non-Routine Issue Encountered Changed`,
      ContextType: $localize`:@@Form:Form`,
      Name: impactAssessment,
      Description: event.nonRoutineIssueEncountered?.value ?? '',
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.instrumentId + event.eventContext.eventType,
    };
  }

  private getInstrumentEventReturnedToServiceRecordContext(event: ReturnToServiceDataChangeEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const impactAssessment = $localize`:@@impactAssessment:Impact Assessment`;

    return {
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${this.activityOutputsTitle} > ${impactAssessment}`,
      RecordType: $localize`:@@returnedToServiceChanged:Returned To Service Changed`,
      ContextType: $localize`:@@Form:Form`,
      Name: impactAssessment,
      Description: event.returnedToService?.value ?? '',
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.instrumentId + event.eventContext.eventType,
    }
  }

  private getRowsAddedRecord(tableRecord: ExperimentDataRecordNotification): AuditHistory {
    const actualRecordTbl = tableRecord as AddRowEventNotification;
    const table = this.experimentService.getTable(actualRecordTbl.tableId);
    if (!table) throw new Error(this.expectedToFindTableMessage + actualRecordTbl.tableId);

    let rowNumber: any;
    // for multiple rows
    if (actualRecordTbl.rows.length > 1) {
      const startIndex = table.value.find(
        (v: any) => v.id === (first(actualRecordTbl.rows) as TableRow).id
      )?.rowIndex.value.value as string;
      const lastRowAddedIndex: number = (+startIndex as unknown as number) + actualRecordTbl.rows.length;
      rowNumber = `${startIndex} to ${lastRowAddedIndex}`;
    } else {
      rowNumber = table.value.find(
        (v: any) => v.id === (first(actualRecordTbl.rows) as TableRow).id
      )?.rowIndex.value.value;
    }

    return this.getHistory(
      tableRecord,
      `${this.getTableContext(actualRecordTbl.tableId)} > ` + $localize`:@@RowsAdded:Rows Added`,
      $localize`:@@RowsAdded:Rows Added`,
      $localize`:@@Table:Table`,
      `${this.getTableContext(actualRecordTbl.tableId)} > ${(first(actualRecordTbl.rows) as TableRow).id}`,
      $localize`:@@RowIndexAdded:Row Index: ${rowNumber as string} Added`,
      `${this.getTableTitleForExperiment(actualRecordTbl.tableId)}`
    );
  }

  private getRowRemovedRecord(tableRecord: ExperimentDataRecordNotification): AuditHistory {
    const actualRecordTbl = tableRecord as RowRemovedEventNotification;
    const table = this.experimentService.getTable(actualRecordTbl.tableId);
    if (!table) throw new Error(this.expectedToFindTableMessage + actualRecordTbl.tableId);

    const rowNumber = table.value.find(
      (v: any) => v.id === actualRecordTbl.rowId
    )?.rowIndex.value.value;
    
    return this.getHistory(
      tableRecord,
      `${this.getTableContext(actualRecordTbl.tableId)} > ` + $localize`:@@rowRemoved:Row Removed`,
      $localize`:@@rowRemoved:Row Removed`,
      $localize`:@@Table:Table`,
      `${this.getTableContext(actualRecordTbl.tableId)} > ${rowNumber}`,
      $localize`:@@RowIndexRemoved:Row Index: ${rowNumber} Removed`,
      `${this.getTableTitleForExperiment(actualRecordTbl.tableId)}`
    );
  }

  private getRowRestoredRecord(tableRecord: ExperimentDataRecordNotification): AuditHistory {
    const actualRecordTbl = tableRecord as RowRestoredEventNotification;
    const table = this.experimentService.getTable(actualRecordTbl.tableId);
    if (!table) throw new Error(this.expectedToFindTableMessage + actualRecordTbl.tableId);

    const rowNumber = table.value.find(
      (v: any) => v.id === actualRecordTbl.rowId
    )?.rowIndex.value.value;
    
    return this.getHistory(
      tableRecord,
      `${this.getTableContext(actualRecordTbl.tableId)} > ` + $localize`:@@rowRestored:Row Restored`,
      $localize`:@@rowRestored:Row Restored`,
      $localize`:@@Table:Table`,
      `${this.getTableContext(actualRecordTbl.tableId)} > ${rowNumber}`,
      $localize`:@@RowIndexRestored:Row Index: ${rowNumber} Restored`,
      `${this.getTableTitleForExperiment(actualRecordTbl.tableId)}`
    );
  }

  private getRowsRenumberedRecord(tableRecord: ExperimentDataRecordNotification): AuditHistory {
    const actualRecordTbl = tableRecord as RowsRenumberedEventNotification;
    const table = this.experimentService.getTable(actualRecordTbl.tableId);
    if (!table) throw new Error(this.expectedToFindTableMessage + actualRecordTbl.tableId);

    return this.getHistory(
      tableRecord, 
      this.getTableContext(actualRecordTbl.tableId),
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(tableRecord), // BTW nothing like New or Change apply here
      $localize`:@@Table:Table`,
      `${this.getTableContext(actualRecordTbl.tableId)} > StepsReordered`, 
      $localize`:@@stepsReordered:Steps Reordered`, 
      `${this.getTableTitleForExperiment(actualRecordTbl.tableId)}`
    );
  }

  private getCellChangedRecord(tableRecord: ExperimentDataRecordNotification) {
    const actualRecordTbl = tableRecord as CellChangedEventNotification;
    const firstId = first(actualRecordTbl.tableIds) as string;
    const table = this.experimentService.getTable(firstId);
    if (!table) throw new Error(this.expectedToFindTableMessage + firstId);
    const rowNumberText = $localize`:@@RowNumber:Row Number`;

    const historyStack: AuditHistory[] = [];
    actualRecordTbl.columnValues.forEach((colVal) => {
      const columnName = table?.columnDefinitions?.find((f) => f['field'] === colVal.propertyName)?.label;
      const rowNumbers: { rowNumber: string, rowId: string }[] = [];
      actualRecordTbl.rowIds.forEach((rowId: string) => {
        rowNumbers.push({ rowNumber: table.value.find((row: any) => row.id === rowId)?.rowIndex.value.value as string, rowId: rowId });
      });

      let description = this.checkStateAndGetValue(colVal.propertyValue as DataValue).concat(ELNAppConstants.WhiteSpace);
      let recordType = '';
      const instrumentReading = (colVal.propertyValue as NumberValue).instrumentReading;
      const unit = this.unitLoaderService.allUnits.find((u: Unit) => u.id === (colVal.propertyValue as NumberValue).unit)?.abbreviation;

      rowNumbers.forEach(r => {
        const cellKey = `${firstId}-${columnName}-${r.rowId}`;
        const timestamp = tableRecord.eventContext.eventTime;
        if (instrumentReading && 'equipmentId' in instrumentReading) {
          description = this.getInstrumentDescription(instrumentReading, unit);
          recordType = this.getRecordType(cellKey, timestamp);
          this.addUpdateToHistory(cellKey, timestamp);
        } else if (instrumentReading && (instrumentReading as InstrumentReadingValue).instrumentType === InstrumentType.phMeter) {
          description = this.getPhMeterInstrumentDescription(instrumentReading, timestamp);
          recordType = this.getRecordType(cellKey, timestamp);
          this.addUpdateToHistory(cellKey, timestamp);
        } else {
          recordType = ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotificationByItsKey(tableRecord, `${r.rowId}:${colVal.propertyName}`, $localize`:@@Change:Change`);
        }

        const history = this.getHistory(
          tableRecord,
          `${this.getTableContext(first(actualRecordTbl.tableIds) as string)} > ${rowNumberText} ${r.rowNumber} > ${columnName}`,
          recordType,
          $localize`:@@Table:Table`,
          `${this.getTableContext(first(actualRecordTbl.tableIds) as string)} > ${r.rowId} > Row Index ${r.rowNumber} > ${columnName}`,
          description,
          `${this.getTableTitleForExperiment(first(actualRecordTbl.tableIds) as string)}`
        );
        if (!historyStack.find(h => JSON.stringify(h) === JSON.stringify(history))) historyStack.push(history);
      });
    });
    return historyStack;
  }

  private getPhMeterInstrumentDescription(instrumentReading: InstrumentReadingValue, timeStamp: string): string {
    const readingMetaData = instrumentReading.instrumentMetaData;
    const timeStampData = formatInstant(timeStamp, DateAndInstantFormat.dateTimeToSecond);
    const unitToDisplay = readingMetaData?.phMeterMode === PhMeterMode.mV ? PhMeterMode.mV : ''
    return $localize`:@@instrumentDetailsForPhMeter:    ${readingMetaData?.actual} ${unitToDisplay}
    Instrument Name: ${instrumentReading.instrumentName},
    Manufacturer: ${instrumentReading.manufacturer},
    Instrument ID: ${instrumentReading.instrumentName},
    Model: ${instrumentReading.modelNumber},
    Mode: ${readingMetaData?.phMeterMode},
    Serial Number: ${instrumentReading.serialNumber},
    Temperature, Push: ${readingMetaData?.temperatureValue} ${readingMetaData?.temperatureUnit} @ ${timeStampData},
    mV, Push: ${readingMetaData?.mvValue} ${readingMetaData?.mvUnit} @ ${timeStampData},
    pH, Push: ${readingMetaData?.phValue} ${readingMetaData?.phUnit} @ ${timeStampData}`;
  }

  private getInstrumentDescription(instrumentReading: InstrumentReadingValue, unitId?: string): string {
    const blowerState = $localize`:@@blowerStateValue:(${instrumentReading.instrumentMetaData?.BlowerState})`;
    const toleranceBlowerDetails = $localize`:@@toleranceBlowerDetails:${instrumentReading.instrumentMetaData?.BlowerState === BlowerState.No_Blower ? '' : blowerState}`;
    const toleranceSpecValueDetails = $localize`:@@toleranceSpecValueDetails:"${instrumentReading.instrumentMetaData?.ToleranceSpec}"`;
    const toleranceSpecValue = $localize`:@@toleranceSpecValue:${instrumentReading.instrumentMetaData?.ToleranceSpec === NA ? NA : toleranceSpecValueDetails}`;
    const toleranceSpecDetails = $localize`:@@toleranceSpecDetails:,\n      Tolerance Spec ${toleranceBlowerDetails} : ${toleranceSpecValue}`
    const commonInstrumentDetails = 
      $localize`:@@commonInstrumentDetails:Instrument Name: ${instrumentReading.instrumentName},
        Instrument ID: ${instrumentReading.equipmentId},
        Instrument Type: ${instrumentReading.instrumentType},
        Manufacturer: ${instrumentReading.manufacturer},
        Model Number: ${instrumentReading.modelNumber},
        Serial Number: ${instrumentReading.serialNumber},
        Reading Method: ${instrumentReading.instrumentMetaData?.ReadingMethod}${toleranceSpecDetails}
        `;

    const methodsMap = {
      'ResidualDifference': $localize`:@@residualDifferenceHistory: 
      ${instrumentReading.instrumentMetaData?.actual} ${unitId},
      Pan + Sample : ${instrumentReading.instrumentMetaData?.panSample} ${unitId} ${instrumentReading.instrumentMetaData?.panSampleReadMethod} ${
        instrumentReading.instrumentMetaData?.panSampleReadTime},
      Pan + Residual : ${instrumentReading.instrumentMetaData?.panResidual} ${unitId} ${instrumentReading.instrumentMetaData?.panResidualReadMethod} ${
        instrumentReading.instrumentMetaData?.panResidualReadTime},
      Actual : ${instrumentReading.instrumentMetaData?.actual} ${unitId} computed ${instrumentReading.instrumentMetaData?.actualReadTime},
      ${commonInstrumentDetails}
      `,
      'Direct': $localize`:@@directHistory: 
      ${instrumentReading.instrumentMetaData?.actual} ${unitId},
      Tare : ${instrumentReading.instrumentMetaData?.tare} ${unitId} ${instrumentReading.instrumentMetaData?.tareReadMethod} ${instrumentReading.instrumentMetaData?.tareReadTime},
      Actual : ${instrumentReading.instrumentMetaData?.actual} ${unitId} ${instrumentReading.instrumentMetaData?.actualReadMethod} ${
        instrumentReading.instrumentMetaData?.actualReadTime},
      ${commonInstrumentDetails}
      `,
      'Difference': $localize`:@@differenceHistory:
      ${instrumentReading.instrumentMetaData?.actual} ${unitId},
      Pan : ${instrumentReading.instrumentMetaData?.pan} ${unitId} ${instrumentReading.instrumentMetaData?.panReadMethod} ${instrumentReading.instrumentMetaData?.panReadTime},
      Pan + Sample : ${instrumentReading.instrumentMetaData?.panSample} ${unitId} ${instrumentReading.instrumentMetaData?.panSampleReadMethod} ${
        instrumentReading.instrumentMetaData?.panSampleReadTime},
      Actual : ${instrumentReading.instrumentMetaData?.actual} ${unitId} computed ${instrumentReading.instrumentMetaData?.actualReadTime},
      ${commonInstrumentDetails}
      `
    };

    return methodsMap[instrumentReading.instrumentMetaData?.ReadingMethod as keyof typeof methodsMap] || '';
  }

  private getRecordType(cellKey: string, currentTimestamp: string): string {
    if (!this.cellUpdateHistory[cellKey]) {
      this.cellUpdateHistory[cellKey] = [currentTimestamp];
      return $localize`:@@ReadingNew:Reading, New`;
    }

    if (this.cellUpdateHistory[cellKey][0] === currentTimestamp) {
      return $localize`:@@ReadingNew:Reading, New`;
    } else {
      return $localize`:@@ReadingChange:Reading, Change`;
    }
  }
  
  private addUpdateToHistory(cellKey: string, timestamp: string): void {
    if (!this.cellUpdateHistory[cellKey]) {
      this.cellUpdateHistory[cellKey] = [];
    }
    this.cellUpdateHistory[cellKey].push(timestamp);
  }

  private getTableCellContext(tableId: string, rowId: string, columnField: string): string {
    if (!this.experimentResponse) throw new Error('Logic Error: Experiment not found for table ' + tableId); // this can't happen!

    const table = this.experimentService.getTable(tableId);
    if (!table) throw new Error('Logic Error: table not found in experiment ' + tableId); // this can't happen!
    if (!table.columnDefinitions) throw new Error('Logic Error: column definitions not found for table ' + tableId); // this can't happen!

    const row = table.value.find((r) => r.id === rowId);
    if (!row) throw new Error('Logic Error: row not found in table ' + rowId); // this can't happen!

    const rowIndex = row.rowIndex.value.value;
    const column = table.columnDefinitions.find((c) => c.field === columnField);
    if (!column) throw new Error('Logic Error: column not found in table ' + columnField); // this can't happen!

    const columnLabel = column.label;
    const rowNumberText = $localize`:@@RowNumber:Row Number`;

    return `${this.getTableContext(tableId)} > ${rowNumberText} ${rowIndex} > ${columnLabel}`;
  }

  getActivityCrossReferenceChangedRecordContext(event: CrossReferenceChangedEventNotification): AuditHistory {
    const context = this.getCrossReferenceCellContext(event.activityId, event.crossReferenceId, event.property);

    // The right values are sent via the needed parameters. Others might be garbage due to a lack of understanding.
    const experimentRecord = event;
    const fullContextExp = context;
    const recordType = $localize`:@@Change:Change`;
    const contextExp = context;
    const actualContextExp = context;
    const description = event.propertyValue.value ?? '';
    const name = context;
    return this.getHistory(experimentRecord, fullContextExp, recordType, contextExp, actualContextExp, description, name);
  }

  private getCrossReferenceCellContext(activityId: string, rowId: string, columnField: string): string {
    const crossReference = this.experiment.activities
      .find((a) => a.activityId === activityId)?.activityReferences.crossReferences
      .find((r) => r.id === rowId);
    if (!crossReference) throw new Error('Logic Error: row not found in table ' + rowId); // this can't happen!

    const rowIndex = (crossReference.rowIndex.value as StringValue).value;
    const column = CrossReferencesColumns.find((c) => c.field === columnField);
    if (!column) throw new Error('Logic Error: column not found in table ' + columnField); // this can't happen!

    const crossReferencesText = $localize`:@@CrossReferences:Cross References`;
    const rowNumberText = $localize`:@@RowNumber:Row Number`;
    const columnLabel = column.label;

    return `${this.getActivityContext(activityId).fullPath} > ${this.referencesTitle} > ${crossReferencesText} > ${rowNumberText} ${rowIndex} > ${columnLabel}`;
  }

  /** IMPORTANT if modifying this logic, it must also be modified in Domain.ExperimentData.ModifiableDataValue within core services */
  public static getModifiableDataValue(
    newValue: ExperimentDataValue,
    oldValue: ModifiableDataValue | undefined
  ): ModifiableDataValue {
    const wasEmpty = !oldValue || (oldValue?.value?.state === ValueState.Empty ?? true);
    return {
      isModified: oldValue?.isModified || !wasEmpty, // DO NOT change to ??
      value: newValue
    };
  }

  private getActivityRecordContext(
    activityInputRecord: ExperimentDataRecordNotification,
    records: ExperimentDataRecordNotification[]
  ): AuditHistory {
    let history!: AuditHistory;

    switch (activityInputRecord.eventContext.eventType.toString()) {
      case ExperimentEventType.AliquotAdded:
        const actualInputEvent = activityInputRecord as AliquotAddedEventNotification;
        history = this.getActivityInputAddedRecord(actualInputEvent);
        break;
      case ExperimentEventType.ActivityInputRowRestored:
        const actualInputRowRestoredEvent =
          activityInputRecord as ActivityInputRowRestoredEventNotification;
        history = this.getActivityInputRowRestoredRecord(actualInputRowRestoredEvent);
        break;
      case ExperimentEventType.ActivityInputRowRemoved:
        const actualInputRowRemovedEvent =
          activityInputRecord as ActivityInputRowRemovedEventNotification;
        history = this.getActivityInputRowRemovedRecord(actualInputRowRemovedEvent);
        break;
      case ExperimentEventType.ActivityInputRowRefreshed:
        const actualInputRowRefreshedEvent = activityInputRecord as ActivityInputRowRefreshedEventNotification;
        if (actualInputRowRefreshedEvent.aliquotsDetails.find(ad => ad.modifiedFields.length > 0) ||
          actualInputRowRefreshedEvent.materialsDetails.find(md => md.modifiedFields.length > 0)) {
            history = this.getActivityInputRowRefreshedRecord(actualInputRowRefreshedEvent);
        }
        break;
      case ExperimentEventType.MaterialAdded:
        history = this.getMaterialAddedRecord(activityInputRecord as MaterialAddedEventNotification);
        break;
      case ExperimentEventType.LabItemsMaterialAdded:
        history = this.getLabItemsMaterialAddedRecord(activityInputRecord as MaterialAddedNotification);
        break;
      case ExperimentEventType.SampleTestChanged:
        history = this.getSampleTestAddedRecord(activityInputRecord as SampleTestAddedEventNotification, records);
        break;
      case ExperimentEventType.StudyActivitySelected:
        history = this.getStudyActivitySelectedRecord(activityInputRecord as StudyActivitySelectedEventNotification, records);
        break;
      case ExperimentEventType.ActivityInputCellChanged:
        history = this.getActivityInputCellChangedRecord(activityInputRecord as ActivityInputCellChangedEventNotification);
        break;
      case ExperimentEventType.LabItemsCellChanged:
        history = this.getLabItemsCellChangedRecord(activityInputRecord as LabItemsCellChangedEventNotification);
        break;
      case ExperimentEventType.InstrumentAdded:
        history = this.getInstrumentAddedRecord(activityInputRecord);
        break;
      case ExperimentEventType.InstrumentColumnAdded:
        history = this.getInstrumentColumnAddedRecord(activityInputRecord);
        break;
      case ExperimentEventType.MaintenanceEventSelected:
        history = this.getMaintenanceEventSelectedRecord(activityInputRecord as MaintenanceEventSelectedEventNotification);
        break;
      case ExperimentEventType.InstrumentDateRemovedChanged:
        history = this.getInstrumentDateRemovedChangedRecord(activityInputRecord as InstrumentDateRemovedChangedEventNotification);
        break;
      case ExperimentEventType.InstrumentDescriptionChanged:
        history = this.getInstrumentDescriptionChangedRecord(activityInputRecord as InstrumentDescriptionChangedEventNotification);
        break;
      case ExperimentEventType.InstrumentRemovedFromServiceChanged:
        history = this.getInstrumentRemovedFromServiceChangedRecord(activityInputRecord as InstrumentRemovedFromServiceChangedEventNotification);
        break;
      case ExperimentEventType.LabItemsMaterialRemoved:
        const labItemsMaterialRemovedEvent = activityInputRecord as LabItemsMaterialRemovedEventNotification;
        if (labItemsMaterialRemovedEvent.studyLinkIdentified) {
          history = this.getLabItemsMaterialRemovedOnRefreshRecord(labItemsMaterialRemovedEvent);
        }
        else {
          history = this.getLabItemsMaterialRemovedRecord(labItemsMaterialRemovedEvent);
        }
        break;
      case ExperimentEventType.LabItemsMaterialRestored:
        history = this.getLabItemsMaterialRestoredRecord(activityInputRecord as LabItemsMaterialRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentRemoved:
        history = this.getLabItemsInstrumentRemovedRecord(activityInputRecord as LabItemsInstrumentRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentRestored:
        history = this.getLabItemsInstrumentRestoredRecord(activityInputRecord as LabItemsInstrumentRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemsInstrumentAdded:
        history = this.getLabItemsInstrumentAddedRecord(activityInputRecord as InstrumentAddedEventNotification);
        break;
      case ExperimentEventType.LabItemsConsumableAdded:
        history = this.getLabItemsConsumableAddedRecord(activityInputRecord as LabItemsConsumableAddedNotification);
        break;
      case ExperimentEventType.ActivityCrossReferenceAdded:
        history = this.getActivityCrossReferenceAddedRecord(activityInputRecord as CrossReferenceAddedEventNotification);
        break;
      case ExperimentEventType.ActivityCrossReferenceRemoved:
        history = this.getActivityCrossReferenceRemovedRecord(activityInputRecord as CrossReferenceRemovedEventNotification);
        break;
      case ExperimentEventType.ActivityCrossReferenceRestored:
        history = this.getActivityCrossReferenceRestoredRecord(activityInputRecord as CrossReferenceRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemsConsumableRemoved:
        history = this.getLabItemsConsumableRemovedRecord(activityInputRecord as LabItemsConsumableRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemsConsumableRestored:
        history = this.getLabItemsConsumableRestoredRecord(activityInputRecord as LabItemsConsumableRestoredEventNotification);
        break;
      case ExperimentEventType.LabItemsPreparationAdded:
        history = this.getLabItemsPreparationAddedRecord(activityInputRecord as LabItemPreparationAddedEventNotification);
        break;
      case ExperimentEventType.LabItemPreparationRemoved:
        history = this.getLabItemsPreparationRemovedRecord(activityInputRecord as LabItemPreparationRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemPreparationRestored:
        history = this.getLabItemsPreparationRestoredRecord(activityInputRecord as LabItemPreparationRemovedEventNotification);
        break;
      case ExperimentEventType.LabItemPreparationRefreshed:
        history = this.getLabItemsPreparationRefreshedRecord(activityInputRecord as LabItemsPreparationRefreshedNotification);
        break;
    }
    return history;
  }

  private getActivityInputRowRestoredRecord(
    notification: ActivityInputRowRestoredEventNotification
  ): AuditHistory {
    const path = this.getActivityInputContext(notification.activityInputType);
    return this.getHistory(
      notification,
      path,
      $localize`:@@rowRestored:Row Restored`,
      $localize`:@@Table:Table`,
      '',
      `${this.getActivityInputName(notification.activityInputType)}: ${notification.activityInputReference} ` + $localize`:@@restoredState:Restored`
    );
  }

  private getActivityInputRowRemovedRecord(
    notification: ActivityInputRowRemovedEventNotification
  ): AuditHistory {
    const path = this.getActivityInputContext(notification.activityInputType);
    return this.getHistory(
      notification,
      path,
      $localize`:@@rowRemoved:Row Removed`,
      $localize`:@@Table:Table`,
      '',
      `${this.getActivityInputName(notification.activityInputType)}: ${notification.activityInputReference} ` + $localize`:@@removed:Removed`
    );
  }

  private getLabItemsMaterialRemovedRecord(notification: LabItemsMaterialRemovedEventNotification) {
    const path = this.getActivityInputContextByType(ActivityInputType.Material, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsMaterialRemoved:Lab Item Removed`,
      $localize`:@@Table:Table`,
      '',
      `${notification.itemReference}`,
      $localize`:@@LabItemsMaterialsTableTitle:Materials`
    );
  }

  private getLabItemsMaterialRemovedOnRefreshRecord(notification: LabItemsMaterialRemovedEventNotification) {
    const path = this.getActivityInputContextByType(ActivityInputType.Material, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsMaterialRefreshedAndRemoved:Lab Item Refreshed & Removed`,
      $localize`:@@Table:Table`,
      '',
      `${notification.itemReference} removed on refresh due to latest study activities associated to the material`,
      $localize`:@@LabItemsMaterialsTableTitle:Materials`,
    );
  }

  private getLabItemsMaterialRestoredRecord(
    notification: LabItemsMaterialRestoredEventNotification
  ) {
    const path = this.getActivityInputContextByType(ActivityInputType.Material, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsMaterialRestored:Lab Item Restored`,
      $localize`:@@Table:Table`,
      '',
      `${notification.itemReference}`,
      $localize`:@@LabItemsMaterialsTableTitle:Materials`
    );
  }

  private getActivityInputRowRefreshedRecord(
    notification: ActivityInputRowRefreshedEventNotification
  ): AuditHistory {
    const path = this.getActivityInputContext(notification.activityInputType);
    const messageData = this.getActivityInputRefreshMessageData(notification);
    return this.getHistory(
      notification,
      path,
      $localize`:@@AliquotRefreshed:Rows Refreshed`,
      $localize`:@@Table:Table`,
      '',
      `Aliquot(s): ${messageData} Refreshed`
    );
  }

  private getLabItemsInstrumentRemovedRecord(
    notification: LabItemsMaterialRemovedEventNotification
  ) {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentDetails, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsInstrumentRemoved:Lab Item Removed`,
      $localize`:@@Table:Table`,
      '',
      `${notification.itemReference}`,
      $localize`:@@LabItemsInstrumentsTableTitle:Instruments`
    );
  }

  private getLabItemsInstrumentRestoredRecord(
    notification: LabItemsMaterialRemovedEventNotification
  ) {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentDetails, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsInstrumentRestored:Lab Item Restored`,
      $localize`:@@Table:Table`,
      '',
      `${notification.itemReference}`,
      $localize`:@@LabItemsInstrumentsTableTitle:Instruments`
    );
  }

  private getLabItemsInstrumentAddedRecord(notification: InstrumentAddedEventNotification) {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentDetails, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@labItemAdded:Lab Item Added`,
      $localize`:@@Table:Table`,
      '',
      `${notification.activityInputReference}`,
      $localize`:@@LabItemsInstrumentsTableTitle:Instruments`
    );
  }

  private getLabItemsInstrumentColumnRemovedRecord(notification: LabItemsInstrumentColumnRemovedEventNotification) {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentColumn, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsInstrumentColumnRemoved:Lab Item Removed`,
      $localize`:@@Table:Table`,
      '',
      `${notification.itemReference}`,
      $localize`:@@LabItemsColumnsTableTitle:Columns`
    );
  }
  private getLabItemsInstrumentColumnRestoredRecord(notification: LabItemsInstrumentColumnRestoredEventNotification) {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentColumn, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsInstrumentColumnRestored:Lab Item Restored`,
      $localize`:@@Table:Table`,
      '',
      `${notification.itemReference}`,
      $localize`:@@LabItemsColumnsTableTitle:Columns`
    );
  }

  private getLabItemsInstrumentColumnRefreshedRecord(notification: LabItemsInstrumentColumnRefreshedNotification) {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentColumn, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@labItemRefreshed:Lab Item Refreshed`,
      $localize`:@@Table:Table`,
      '',
      `${notification.itemReference}`,
      $localize`:@@LabItemsColumnsTableTitle:Columns`
    );
  }

  private getLabItemsConsumableAddedRecord(notification: LabItemsConsumableAddedNotification) {
    const path = this.getActivityInputContextByType(ActivityInputType.Consumable, this.labItemsTitle);
    const rowIndexField = notification.tableData.find(row => !!row.rowIndex);
    const rowIndex = rowIndexField?.rowIndex.value.value;

    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsConsumableAdded:Lab Item Consumable Added`,
      $localize`:@@Table:Table`,
      '',
      $localize`:@@RowIndexAdded:Row Index: ${rowIndex} Added`,
      $localize`:@@ConsumablesTag:Consumables`
    );
  }

  private getActivityCrossReferenceAddedRecord(notification: CrossReferenceAddedEventNotification): AuditHistory {
    switch (notification.type) {
      case CrossReferenceType.Experiment: {
        const referencedExperimentNumber = this.experimentNumberCache[notification.linkId];
        const context = `${this.getActivityContext(notification.activityId).fullPath} > ${this.referencesTitle} > ${this.crossReferencesTitle}`;
        const recordType = [$localize`:@@Reference:Reference`, $localize`:@@New:New`].join(', '); //TODO This a fake. Apply the eventual system of Record Type #3214982
        const description = [$localize`:@@AddedCrossReferenceTo:Added Cross Reference to`, `${referencedExperimentNumber}`].join(' ');
        const contextExp = 'not used';
        const actualContext = 'not used';
        return this.getHistory(notification, context, recordType, contextExp, actualContext, description);
      }
      case CrossReferenceType.Activity:
      default:
        throw Error('LOGIC ERROR: CrossReferenceType not implemented');
    }
  }

  private getActivityCrossReferenceRemovedRecord(notification: CrossReferenceRemovedEventNotification) {
    const activity = this.experimentService.currentExperiment?.activities.find(a => a.activityId === notification.activityId);
    const ref = activity?.activityReferences.crossReferences.find(r => r.id === notification.crossReferenceId);
    if (!ref) throw new Error('LOGIC ERROR: Expected to find cross reference');

    const referencedExperimentNumber = this.experimentNumberCache[ref.linkId];
    const context = `${this.getActivityContext(notification.activityId).fullPath} > ${this.referencesTitle} > ${this.crossReferencesTitle}`;
    const recordType = [$localize`:@@Reference:Reference`, $localize`:@@removed:Removed`].join(', '); //TODO This a fake. Apply the eventual system of Record Type #3214982
    const description = $localize`:@@removedCrossReferenceDescription:Removed Cross Reference to ${referencedExperimentNumber}`;
    const contextExp = 'not used';
    const actualContext = 'not used';
    return this.getHistory(notification, context, recordType, contextExp, actualContext, description);
  }

  private getActivityCrossReferenceRestoredRecord(notification: CrossReferenceRestoredEventNotification) {
    const activity = this.experimentService.currentExperiment?.activities.find(a => a.activityId === notification.activityId);
    const ref = activity?.activityReferences.crossReferences.find(r => r.id === notification.crossReferenceId);
    if (!ref) throw new Error('LOGIC ERROR: Expected to find cross reference');

    const referencedExperimentNumber = this.experimentNumberCache[ref.linkId];
    const context = `${this.getActivityContext(notification.activityId).fullPath} > ${this.referencesTitle} > ${this.crossReferencesTitle}`;
    const recordType = [$localize`:@@Reference:Reference`, $localize`:@@restoredState:Restored`].join(', '); //TODO This a fake. Apply the eventual system of Record Type #3214982
    const description = $localize`:@@restoredCrossReferenceDescription:Restored Cross Reference to ${referencedExperimentNumber}`;
    const contextExp = 'not used';
    const actualContext = 'not used';
    return this.getHistory(notification, context, recordType, contextExp, actualContext, description);
  }

  private getActivityInputRefreshMessageData(notification: ActivityInputRowRefreshedEventNotification): string {
    if (notification.activityInputType === ActivityInputType.Aliquot) return notification.aliquotsDetails.map(ad => ad.activityInputReference).join(',');
    return notification.materialsDetails.map(md => md.activityInputReference).join(',');
  }

  private getActivityInputAddedRecord(notification: AliquotAddedEventNotification): AuditHistory {
    const description = this.getDescriptionForActivityInputs(notification);
    const path = this.getActivityInputContext(ActivityInputType.Aliquot);
    return this.getHistory(
      notification,
      path,
      $localize`:@@ActivityInputAdded:Input added`,
      $localize`:@@Table:Table`,
      '',
      description
    );
  }

  private getDescriptionForActivityInputs(notification: AliquotAddedEventNotification): string {
    if (notification.eventContext.eventType.toString() === ExperimentEventType.AliquotAdded) {
      return `${notification.activityInputReference} ` + $localize`:@@added:Added`;
    }

    return '';
  }

  private getSampleTestAddedRecord(notification: SampleTestAddedEventNotification, records: ExperimentDataRecordNotification[]): AuditHistory {
    const description = this.getDescriptionForSampleTestSelection(notification, records);
    const path = this.getActivityInputContext(ActivityInputType.Aliquot);
    return this.getHistory(
      notification,
      path,
      $localize`:@@SampleTestChanged:Sample Test Changed`,
      $localize`:@@Table:Table`,
      '',
      description
    );
  }

  private getDescriptionForStudyActivitySelection(notification: StudyActivitySelectedEventNotification, records: ExperimentDataRecordNotification[]): string {
    const studyActivitySelectedRecords = records.filter(r => r.eventContext.eventType === notification.eventContext.eventType) as StudyActivitySelectedEventNotification[];
    const historyRecords = studyActivitySelectedRecords.filter(r => 
      r.eventContext.experimentId === notification.eventContext.experimentId
      && r.activityId === notification.activityId
      && r.activityInputReference === notification.activityInputReference
    ).sort((a, b) => a.eventContext.eventTime.localeCompare(b.eventContext.eventTime));
    const previousVersion = historyRecords[historyRecords.indexOf(notification) - 1];
    const newValue = notification.studyActivities.map(a => a.code);

    let oldValue: string[] = [];

    if (previousVersion) {
        oldValue = previousVersion.studyActivities.map(a => a.code);
    }

    const selected = difference(newValue, oldValue);
    const unselected = difference(oldValue, newValue);
    return this.getMultiselectDescription(newValue.join(', '), selected, unselected);
  }

  private getStudyActivitySelectedRecord(notification: StudyActivitySelectedEventNotification, records: ExperimentDataRecordNotification[]): AuditHistory {
    const description = this.getDescriptionForStudyActivitySelection(notification, records);
    const path = this.getActivityInputContext(ActivityInputType.Material);
    return this.getHistory(
      notification,
      path,
      $localize`:@@StudyActivitySelected:Study Activity Selected`,
      $localize`:@@Table:Table`,
      '',
      description
    );
  }

  private getActivityInputCellChangedRecord(notification: ActivityInputCellChangedEventNotification): AuditHistory {
    const path = this.getActivityInputCellChangedContext(notification);
    return this.getHistory(
      notification,
      path,
      $localize`:@@ActivityInputCellChanged:Activity Input Cell Changed`,
      $localize`:@@Table:Table`,
      path,
      this.checkStateAndGetValue(notification.propertyValue).concat(ELNAppConstants.WhiteSpace)
    );
  }

  private getLabItemsCellChangedRecord(notification: LabItemsCellChangedEventNotification): AuditHistory {
    const path = this.getLabItemsCellChangedContext(notification);
    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsCellChanged:Lab Item Cell Changed`,
      $localize`:@@TableCell:Table Cell`,
      path,
      this.checkStateAndGetValue(notification.propertyValue as DataValue).concat(ELNAppConstants.WhiteSpace),
      this.getPropertyName(notification)
    );
  }

  private getPropertyName(notification: LabItemsCellChangedEventNotification): string {
    switch (notification.itemType) {
      case ActivityInputType.Consumable:
        const columnDefinitions = LabItemsConsumablesTableOptions.GetColumnDefinitions(false);
        const column = columnDefinitions.find((c) => c.field === notification.propertyName);
        return column?.label ?? '';
      case ActivityInputType.Material:
        const instrumentMaterialColumnDefinitions = LabItemsMaterialTableOptions.GetColumnDefinitions(undefined, () => undefined);
        const instrumentMaterialColumn = instrumentMaterialColumnDefinitions.find((c) => c.field === notification.propertyName);
        return instrumentMaterialColumn?.label ?? '';
      case ActivityInputType.InstrumentColumn:
        const instrumentColumnDefinitions = LabItemsColumnTableOptions.GetColumnDefinitions();
        const instrumentColumn = instrumentColumnDefinitions.find((c) => c.field === notification.propertyName);
        return instrumentColumn?.label ?? '';
      case ActivityInputType.Instrument:
      case ActivityInputType.InstrumentDetails:
        const instrumentTableDefinitions = LabItemsInstrumentTableOptions.GetColumnDefinitions();
        const instrumentTableColumn = instrumentTableDefinitions.find((c) => c.field === notification.propertyName);
        return instrumentTableColumn?.label ?? '';
      default:
        return notification.propertyName;
    }
  }

  private getInstrumentAddedRecord(notification: any): AuditHistory {
    const path = this.getActivityInputContext(ActivityInputType.Instrument);
    return this.getHistory(
      notification,
      path,
      $localize`:@@InstrumentAdded:Instrument Added`,
      $localize`:@@Form:Form`,
      '',
      notification.activityInputReference
    );
  }

  private getInstrumentColumnAddedRecord(notification: any): AuditHistory {
    const path = this.getActivityInputContextByType(ActivityInputType.InstrumentColumn, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@labItemAdded:Lab Item Added`,
      $localize`:@@Table:Table`,
      '',
      notification.activityInputReference,
      $localize`:@@LabItemsColumnsTableTitle:Columns`
    );
  }

  private getInstrumentRemovedFromServiceChangedRecord(notification: InstrumentRemovedFromServiceChangedEventNotification): AuditHistory {
    const path = this.getActivityInputContext(ActivityInputType.Instrument);
    return this.getHistory(
      notification,
      path,
      $localize`:@@InstrumentRemoveFromServiceChanged:Instrument Remove From Service Changed`,
      $localize`:@@Form:Form`,
      '',
      notification?.removedFromService?.value ?? ''
    );
  }

  private getInstrumentDescriptionChangedRecord(notification: InstrumentDescriptionChangedEventNotification): AuditHistory {
    const path = this.getActivityInputContext(ActivityInputType.Instrument);
    return this.getHistory(
      notification,
      path,
      $localize`:@@InstrumentDescriptionChanged:Instrument Description Changed`,
      $localize`:@@Form:Form`,
      '',
      notification?.description?.value ?? ''
    );
  }

  private getInstrumentDateRemovedChangedRecord(notification: InstrumentDateRemovedChangedEventNotification): AuditHistory {
    const path = this.getActivityInputContext(ActivityInputType.Instrument);
    return this.getHistory(
      notification,
      path,
      $localize`:@@InstrumentDateRemovedChanged:Instrument Date Removed Changed`,
      $localize`:@@Form:Form`,
      '',
      notification?.dateRemoved?.value ?? ''
    );
  }

  private getMaintenanceEventSelectedRecord(notification: MaintenanceEventSelectedEventNotification): AuditHistory {
    const path = this.getActivityInputContext(ActivityInputType.Instrument);
    return this.getHistory(
      notification,
      path,
      $localize`:@@MaintenanceEventSelected:Maintenance Event Selected`,
      $localize`:@@Form:Form`,
      '',
      notification?.nameDescription?.value ?? ''
    );
  }

  private getDescriptionForSampleTestSelection(notification: SampleTestAddedEventNotification, records: ExperimentDataRecordNotification[]): string {
    const sampleTestChangedRecords = records.filter(r => r.eventContext.eventType === notification.eventContext.eventType) as SampleTestAddedEventNotification[];
    const historyRecords = sampleTestChangedRecords.filter(r => 
      r.eventContext.experimentId === notification.eventContext.experimentId
      && r.activityId === notification.activityId
      && r.activityInputReference === notification.activityInputReference
    ).sort((a, b) => a.eventContext.eventTime.localeCompare(b.eventContext.eventTime));
    const previousVersion = historyRecords[historyRecords.indexOf(notification) - 1];
    const newValue = notification.aliquotTests;

    let oldValue: AliquotTest[] = [];

    if (previousVersion) oldValue = previousVersion.aliquotTests;

    const selected = newValue.filter((v: AliquotTest) => !oldValue.map(o => o.testId).includes(v.testId));
    const unselected = oldValue.filter((v: AliquotTest) => !newValue.map(n => n.testId).includes(v.testId)); 
    return this.getMultiselectDescription(newValue.map(t => t.testReportableName).join(', '), selected.map(t => t.testReportableName), unselected.map(t => t.testReportableName));
  }

  public formatTestProperties(property: string) {
    return property === NA ? NA : `"${property}"`;
  }

  private getMaterialAddedRecord(notification: MaterialAddedEventNotification): AuditHistory {
    const description = this.getDescriptionForMaterialAliquot(notification);
    const path = this.getActivityInputContext(ActivityInputType.Material);
    return this.getHistory(
      notification,
      path,
      $localize`:@@ActivityInputAdded:Input added`,
      $localize`:@@Table:Table`,
      '',
      description
    );
  }

  private getLabItemsMaterialAddedRecord(notification: MaterialAddedNotification): AuditHistory {
    const description = this.getDescriptionForLabItemsMaterial(notification);
    const path = this.getActivityInputContextByType(ActivityInputType.Material, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@labItemAdded:Lab Item Added`,
      $localize`:@@Table:Table`,
      '',
      description,
      $localize`:@@LabItemsMaterialsTableTitle:Materials`
    );
  }

  private getDescriptionForMaterialAliquot(notification: MaterialAddedEventNotification): string {
    if (notification.eventContext.eventType.toString() === ExperimentEventType.MaterialAdded) {
      return `${notification.activityInputReference} ` + $localize`:@@added:Added`;
    }

    return '';
  }

  private getDescriptionForLabItemsMaterial(notification: MaterialAddedNotification): string {
    if (notification.eventContext.eventType.toString() === ExperimentEventType.LabItemsMaterialAdded) {
      return notification.activityInputReference;
    }

    return '';
  }

  private getFormRecordContext(formRecord: ExperimentDataRecordNotification): AuditHistory {
    let history!: AuditHistory;
    /**
     * ! It will be replaced with Switch statements if the cases are greater than three
     */
    if (formRecord.eventContext.eventType.toString() === ExperimentEventType.FieldChanged) {
      const actualRecordForm = formRecord as FieldChangedEventNotification;
      history = this.getFieldChangedRecord(actualRecordForm);
    }
    return history;
  }

  private getFieldChangedRecord(actualRecordForm: FieldChangedEventNotification): AuditHistory {
    let description = this.checkStateAndGetValue(actualRecordForm.newValue as DataValue);
    const instrumentReading = (actualRecordForm.newValue as NumberValue).instrumentReading;
    let recordType = '';
    if (instrumentReading) {
      const fieldKey = `${actualRecordForm.formId}-${actualRecordForm.path[actualRecordForm.path.length-1]}`;
      const timestamp = actualRecordForm.eventContext.eventTime
      const unit = this.unitLoaderService.allUnits.find((u: Unit) => u.id === (actualRecordForm.newValue as NumberValue).unit)?.abbreviation;
      description = this.getInstrumentDescription(instrumentReading, unit);
      recordType = this.getRecordType(fieldKey, timestamp);
      this.addUpdateToHistory(fieldKey, timestamp);
    }
    const path = this.getFieldLabelPathFromField(
      actualRecordForm.formId,
      last(actualRecordForm.path) as string
    ).join(' > ');
    return this.getHistory(
      actualRecordForm,
      `${this.getFormContext(actualRecordForm.formId)} > 
      ${path}`,
      instrumentReading ? recordType : $localize`:@@FieldChanged:Field Changed`,
      $localize`:@@Form:Form`,
      `${this.getFormContext(actualRecordForm.formId)} > ${actualRecordForm.formId} > 
      ${path}`,
      description
    );
  }

  private getHistory(
    experimentRecord: ExperimentDataRecordNotification,
    fullContextExp: string,
    recordType: string,
    contextExp: string,
    actualContextExp: string,
    description: string,
    name?: string
  ): AuditHistory {
    const fullName = this.usersList?.find(
      u => u.puid.toLowerCase() === experimentRecord.eventContext.puid.toLowerCase()
    )?.fullName;
    return {
      Time: experimentRecord.eventContext.eventTime,
      Context: fullContextExp,
      RecordType: recordType,
      ContextType: contextExp,
      Name: name ?? contextExp,
      Description: description,
      PerformedBy: typeof fullName === 'undefined' ? experimentRecord.eventContext.puid : fullName,
      RecordVersion: 1,
      ActualContext: actualContextExp
    };
  }

  private checkStateAndGetValue(propertyValue: DataValue) {
    let description = '';
    switch (propertyValue.state) {
      case ValueState.Empty.toString():
        if (propertyValue.type === ValueType.Number) {
          description = this.getValueType(propertyValue);
        }
        break;
      case ValueState.Invalid.toString():
        description = $localize`:@@InvalidValueError:Internal Error. Please contact support.`;
        break;
      case ValueState.NotApplicable.toString():
        description = NA;
        break;
      case ValueState.Set.toString():
        description = this.getValueType(propertyValue);
        break;
    }
    return description;
  }

  private getValueType(propertyValue: DataValue): string {
    let desc = '';
    switch (propertyValue.type) {
      case ValueType.Invalid:
        desc = $localize`:@@InvalidValueError:Internal Error. Please contact support.`;
        break;
      case ValueType.Instant:
        desc = formatInstant(propertyValue.value as string, DateAndInstantFormat.dateTimeToSecond);
        break;
      case ValueType.LocalDate:
        desc = formatLocalDate(propertyValue.value as string);
        break;
      case ValueType.Number:
        const number = propertyValue as NumberValue;
        const unit = this.unitLoaderService.allUnits.find((u: Unit) => u.id === number.unit);
        const quantity = new Quantity(number.state, number.value, unit, number.sigFigs, number.exact);
        desc = quantity.toString();
        break;
      case ValueType.Boolean:
      case ValueType.String:
        desc = propertyValue.value as string;
        break;
      case ValueType.StringArray:
        desc = (propertyValue.value as string[]).join(`,${ELNAppConstants.WhiteSpace}`);
        break;
      case ValueType.Specification:
        desc = this.getSpecificationContext(propertyValue);
        break;
      case ValueType.StringDictionary:
        desc = this.dataValueService.joinValues(propertyValue as StringTypeDictionaryValue);
        break;
    }
    return desc;
  }

  getActivityInputName(activityInputType: ActivityInputType) {
    switch (activityInputType) {
      case ActivityInputType.Aliquot:
        return $localize`:@@SampleAliquots:Samples & Aliquots`;
      case ActivityInputType.Material:
        return $localize`:@@StudyActivities:Study Activities`;
      case ActivityInputType.Instrument:
        return $localize`:@@instrumentEventPageHeader:Instrument Event`;
    }
    return '';
  }

  getLabItemsName(itemType: ActivityInputType) {
    switch (itemType) {
      case ActivityInputType.Aliquot:
        return $localize`:@@SampleAliquots:Samples & Aliquots`;
      case ActivityInputType.Material:
        return $localize`:@@StudyActivities:Study Activities`;
    }
    return '';
  }

  private getActivityInputCellChangedContext(notification: ActivityInputCellChangedEventNotification): string {
    const activityId = this.experimentService.currentActivityId;

    const activityName = this.experiment.activities.find(
      (a) => a.activityId === activityId
    )?.itemTitle;
    const context = `${activityName} > ${this.activityInputsTitle} > ${this.getActivityInputName(notification.activityInputType)}`;

    return context.concat(` > ${notification.activityInputReference} > ${notification.propertyName}`)
  }

  private getLabItemsCellChangedContext(notification: LabItemsCellChangedEventNotification): string {
    let context = this.getActivityInputContextByType(notification.itemType, this.labItemsTitle);
    if (notification.itemReference) {
      context = context.concat(` > ${this.getItemReference(notification)} > ${this.getPropertyName(notification)}`);
    }
    else {
      context = context.concat(` > ${this.getItemReference(notification)}`);
    }

    return context;
  }

  private getItemReference(notification: LabItemsCellChangedEventNotification) {
    if (notification.itemType === ActivityInputType.Consumable) {
      const datasource = (this.experimentService.currentExperiment?.activityLabItems.find(
        (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
      )?.consumables as Array<Consumable>) || [];
      const consumable = datasource.find(k => k.itemReference === notification.itemReference);
      const fields = consumable?.tableData.filter((r: any) => !!r.rowIndex);
      if (fields) {
        const rowIndexField = fields[0];
        const rowIndex = (rowIndexField?.rowIndex.value as NumberValue).value;
        return 'Row Number ' + rowIndex;
      }
      return '';
    }
    else {
      return notification.itemReference;
    }
  }

  private getExperimentNodeTItleChangedContext(
    notification: ExperimentNodeTitleChangedNotification
  ): AuditHistory {
    return this.nodeTitleChangeAuditHistory[notification.nodeType].historyComposer(notification);
  }

  private getTableTitleChangedAuditContext(notification: ExperimentNodeTitleChangedNotification) {
    const auditDisplayContext = `${this.getTableContext(notification.nodeId)} > ${DataRecordService.Title}`;
    return this.getHistory(
      notification,
      `${auditDisplayContext}`,
      $localize`:@@Change:Change`,
      $localize`:@@Table:Table`,
      `${auditDisplayContext}`,
      notification.title,
      `${this.getTableTitleForExperiment(notification.nodeId)}`
    );
  }

  private getFormTitleChangedAuditContext(notification: ExperimentNodeTitleChangedNotification) {
    const auditDisplayContext = `${this.getFormContext(notification.nodeId)} > ${DataRecordService.Title}`;
    return this.getHistory(
      notification,
      `${auditDisplayContext}`,
      $localize`:@@Change:Change`,
      $localize`:@@Form:Form`,
      `${auditDisplayContext}`,
      notification.title,
      `${this.getFormTitleForExperiment(notification.nodeId)}`
    );
  }

  private getModuleTitleChangedAuditContext(notification: ExperimentNodeTitleChangedNotification) {
    const auditDisplayContext = this.getModuleContext(notification.nodeId);
    return this.getHistory(
      notification,
      `${auditDisplayContext.fullPath} > ${DataRecordService.Title}`,
      $localize`:@@Change:Change`,
      $localize`:@@Module:Module`,
      `${auditDisplayContext.fullPath} > ${DataRecordService.Title}`,
      notification.title,
      `${auditDisplayContext.title}`
    );
  }

  private getActivityTitleChangedAuditContext(notification: ExperimentNodeTitleChangedNotification) {
    const auditDisplayContext = this.getActivityContext(notification.nodeId);
    return this.getHistory(
      notification,
      `${auditDisplayContext.fullPath} > ${DataRecordService.Title}`,
      $localize`:@@Change:Change`,
      $localize`:@@Activity:Activity`,
      `${auditDisplayContext.fullPath} > ${DataRecordService.Title}`,
      notification.title,
      `${auditDisplayContext.title}`
    );
  }

  private getLabItemsConsumableRemovedRecord(
    notification: LabItemsConsumableRemovedEventNotification
  ) {
    const path = this.getActivityInputContextByType(ActivityInputType.Consumable, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsConsumableRemoved:Consumable Removed`,
      $localize`:@@Table:Table`,
      '',
      $localize`:@@RowIndexRemoved:Row Index: ${(notification as any).rowIndex} Removed`,
      $localize`:@@ConsumablesTag:Consumables`
    );
  }

  private getLabItemsConsumableRestoredRecord(
    notification: LabItemsMaterialRemovedEventNotification
  ) {
    const path = this.getActivityInputContextByType(ActivityInputType.Consumable, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsConsumableRestored:Consumable Restored`,
      $localize`:@@Table:Table`,
      '',
      $localize`:@@RowIndexRestored:Row Index: ${(notification as any).rowIndex} Restored`,
      $localize`:@@ConsumablesTag:Consumables`
    );
  }

  private getLabItemsPreparationAddedRecord(
    notification: LabItemPreparationAddedEventNotification
  ) {
    const path = this.getActivityInputContextByType(ActivityInputType.Preparation, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@labItemAdded:Lab Item Added`,
      $localize`:@@Table:Table`,
      '',
      `${notification.preparationNumber}`,
      $localize`:@@preparations:Preparations`
    );
  }

  private getLabItemsPreparationRemovedRecord(
    notification: LabItemPreparationRemovedEventNotification
  ) {
    const path = this.getActivityInputContextByType(ActivityInputType.Preparation, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsRemoved:Lab Item Removed`,
      $localize`:@@Table:Table`,
      '',
      `${notification.itemReference}`,
      $localize`:@@preparations:Preparations`
    );
  }

  private getLabItemsPreparationRestoredRecord(
    notification: LabItemPreparationRestoredEventNotification
  ) {
    const path = this.getActivityInputContextByType(ActivityInputType.Preparation, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@LabItemsRestored:Lab Item Restored`,
      $localize`:@@Table:Table`,
      '',
      `${notification.itemReference}`,
      $localize`:@@preparations:Preparations`
    );
  }

  private getLabItemsPreparationRefreshedRecord(
    notification: LabItemsPreparationRefreshedNotification
  ) {
    const path = this.getActivityInputContextByType(ActivityInputType.Preparation, this.labItemsTitle);
    return this.getHistory(
      notification,
      path,
      $localize`:@@labItemRefreshed:Lab Item Refreshed`,
      $localize`:@@Table:Table`,
      '',
      this.getLabItemPreparationDescription(notification.refreshedDataValues),
      $localize`:@@preparations:Preparations`
    );
  }

  private isExperimentDataValue(fieldValue: ModifiableDataValue) {    
    return fieldValue?.value && fieldValue?.value?.state ? true : false;
  }  

  private getClient(clientId: string) {    
    return this.projectLogLoaderService.getClient(clientId)?.label ?? '';
  }

  private getProject(projectId: string) {
    return this.projectLogLoaderService.getProject(projectId)?.label ?? '';
  }

  private getFieldName(fieldName: string): string {    
    return fieldName.split('.').length > 1 ? fieldName.split('.')[1] : fieldName;
  }

  private getLabItemPreparationDescription(refreshedData: { [key: string]: any }) {
    let refreshedValues = '';
    Object.keys(refreshedData).forEach((key, index) => {
      const fieldName = this.getFieldName(key);
      const fieldValue = refreshedData[key];
      let refreshedValue = '';
      if (this.isExperimentDataValue(fieldValue)) {
        refreshedValue = this.checkStateAndGetValue(fieldValue?.value as ExperimentDataValue);
      } else {
        refreshedValue = this.getRefreshedValue(fieldName, fieldValue);
      }
      if (fieldName === PreparationConstants.expirationDateValueKey) {
        refreshedValue = refreshedValue === NA ? PreparationConstants.suitableForUseText : refreshedValue;
      }      
      refreshedValues +=  PreparationTableOptions.getDisplayValue(camelCase(fieldName)).concat(': ', refreshedValue ?? '');
      if (index < Object.keys(refreshedData).length - 1) {
        refreshedValues += ', ';
      }
    });
    return refreshedValues;
  }

  private getRefreshedValue(fieldName: string, fieldValue: any): string {
    let refreshedValue = '';
    if (fieldName === PreparationConstants.clientKey) {
      refreshedValue = this.getClient(fieldValue);
    } else if (fieldName === PreparationConstants.projectKey) {
      refreshedValue = this.getProject(fieldValue);
    } else if (fieldName === PreparationConstants.discardedOrConsumedOnKey) {
      refreshedValue = fieldValue ? formatInstant(fieldValue, DateAndInstantFormat.date) : '';
    } else if (fieldName === PreparationConstants.stabilityKey || fieldName === PreparationConstants.originalQuantityKey) {
      const numberValue: NumberValue = {
        type: fieldValue?.Type, state: fieldValue?.State,
        value: fieldValue?.Value, unit: fieldValue?.Unit, exact: fieldValue?.Exact
      };
      refreshedValue = this.getValueType(numberValue);
    } else {
      refreshedValue = fieldValue ? fieldValue.toString() : '';
    }
    return refreshedValue;
  }      

  /**
   * Gets the history-style formatted context of a module
   */
  private getModuleContext(nodeId: string): {
    fullPath: string,
    title: string
  } {
    const moduleTitle =
      this.allModulesInExperiment.find((f) => f.moduleId === nodeId)?.moduleLabel ??
      $localize`:@@NoTitle:No Title`;
    const activityName = this.experiment?.activities.find((a) =>
      a.dataModules.some((c) => c.moduleId === nodeId)
    )?.itemTitle;
    return { fullPath: `${activityName} > ${moduleTitle}`, title: moduleTitle };
  }

  /**
   * Gets the history-style formatted context of a specification
   */
  private getSpecificationContext(specification: SpecificationValue): string {
    if (!specification) return '';
    let ctx = this.specificationService.getDisplayString(specification);

    if (specification.state !== ValueState.Set) return ctx;

    const getComplianceContext = (spec: Partial<ObservationSpec> | Partial<SingleValueSpec> | Partial<TwoValueRangeSpec>): string => {
      if (spec.complianceAssessorType === SpecComplianceAssessorType.Invalid
        || spec.complianceAssessorType === SpecComplianceAssessorType.None) {
        return '';
      }
      let compliance = '(';
      compliance += $localize`:@@complianceNeeded:Compliance needed`;
      switch (spec.complianceAssessorType) {
        case SpecComplianceAssessorType.ExactMatch:
          const exactMatch = $localize`:@@exactMatch:Exact match`
          compliance = `${compliance}: ${exactMatch}`;
          break;
        case SpecComplianceAssessorType.Round:
          const round = $localize`:@@round:Round`;
          compliance = `${compliance}: ${round}`;
          break;
        case SpecComplianceAssessorType.Truncate:
          const truncate = $localize`:@@truncate:Truncate`;
          compliance = `${compliance}: ${truncate}`;
          break;
        case SpecComplianceAssessorType.InexactMatch:
          break; // no change to the output needed, but we cannot hit the default.
        default:
          throw new Error('Specification compliance assessor is invalid');
      }
      return compliance + ')';
    }
    if (specification.specType === SpecType.Observation
      || specification.specType === SpecType.SingleValue
      || specification.specType === SpecType.TwoValueRange) {
      const complianceContext = getComplianceContext(specification);
      if (complianceContext !== '') {
        ctx = `${ctx} ${complianceContext}`;
      }
    }
    return ctx;
  }

  private getESignedRecordOfExperimentRestoredToSetupTransition(
    experimentRecord: ExperimentDataRecordNotification,
    experimentWorkFlowPreviousStatus?: ExperimentDataRecordNotification
  ): AuditHistory | undefined {
    const record = experimentRecord as ExperimentStartedEventNotification;
    if (
      !record.eSignatureContext?.signed ||
      !experimentWorkFlowPreviousStatus ||
      (experimentWorkFlowPreviousStatus as WorkflowEventNotification).state !==
      ExperimentWorkflowState.Cancelled
    ) {
      return undefined;
    }
    const eSignedTo = $localize`:@@eSignedForSetup:ESigned For Setup`;
    const eSignedDetails = this.getSignedDetails(experimentRecord as WorkflowEventNotification);
    const description = $localize`:@@cancelledToSetupESignatureDescription:Cancelled Experiment transitioned to Setup by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    return this.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      $localize`:@@eSignature:E-Signature`,
      this.experiment.experimentNumber,
      `${this.cover} > ${eSignedTo}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getESignedRecordOfExperimentStarted(
    experimentRecord: ExperimentDataRecordNotification,
    experimentWorkFlowPreviousStatus?: ExperimentDataRecordNotification
  ): AuditHistory | undefined {
    const record = experimentRecord as ExperimentStartedEventNotification;
    if (
      !record.eSignatureContext?.signed ||
      !experimentWorkFlowPreviousStatus ||
      (experimentWorkFlowPreviousStatus as WorkflowEventNotification).state !==
      ExperimentWorkflowState.Cancelled
    ) {
      return undefined;
    }
    const eSignedTo = $localize`:@@eSignedForStarted:ESigned For Started`;
    const eSignedDetails = this.getSignedDetails(experimentRecord as WorkflowEventNotification);
    const description 
      = $localize`:@@cancelledToInProgressESignatureDescription:Cancelled Experiment transitioned to In progress by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    return this.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      $localize`:@@eSignature:E-Signature`,
      this.experiment.experimentNumber,
      `${this.cover} > ${eSignedTo}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getESignedRecordOfExperimentInReview(
    experimentRecord: ExperimentDataRecordNotification,
    experimentWorkFlowPreviousStatus: ExperimentDataRecordNotification
  ): AuditHistory | undefined {
    const record = experimentRecord as ExperimentSentForCorrectionEventNotification;
    if (!record.eSignatureContext?.signed) {
      return undefined;
    }
    const eSignedTo = $localize`:@@eSignedForInReview:ESigned For InReview`;
    const description = this.getAuditDescriptionOfInReviewFor(
      experimentRecord,
      experimentWorkFlowPreviousStatus
    );
    return this.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      $localize`:@@eSignature:E-Signature`,
      this.experiment.experimentNumber,
      `${this.cover} > ${eSignedTo}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getESignedRecordOfExperimentAuthorized(experimentRecord: ExperimentDataRecordNotification): AuditHistory | undefined {
    const record = experimentRecord as ExperimentSentForCorrectionEventNotification;
    if (!record.eSignatureContext?.signed) return undefined;
    
    const eSignedTo = $localize`:@@eSignedForAuthorization:ESigned For Authorization`;
    const eSignedDetails = this.getSignedDetails(experimentRecord as WorkflowEventNotification);
    const description = $localize`:@@authorizedESignatureDescription:Reviewed and electronically authorized for specified Samples, where applicable by ${
      eSignedDetails.signedBy
    } on ${eSignedDetails.signedOn}`;
    return this.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      $localize`:@@eSignature:E-Signature`,
      this.experiment.experimentNumber,
      `${this.cover} > ${eSignedTo}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getESignedRecordOfExperimentCancelled(experimentRecord: ExperimentDataRecordNotification): AuditHistory | undefined {
    const record = experimentRecord as ExperimentSentForCorrectionEventNotification;
    if (!record.eSignatureContext?.signed) return undefined;
    
    const eSignedTo = $localize`:@@eSignedForCancelled:ESigned For Cancelled`;
    const eSignedDetails = this.getSignedDetails(experimentRecord as WorkflowEventNotification);
    const description = $localize`:@@cancelledESignatureDescription:Experiment cancelled by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    return this.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      $localize`:@@eSignature:E-Signature`,
      this.experiment.experimentNumber,
      `${this.cover} > ${eSignedTo}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getESignedRecordOfExperimentInCorrection(experimentRecord: ExperimentDataRecordNotification, experimentWorkFlowPreviousStatus: ExperimentDataRecordNotification
  ): AuditHistory | undefined {
    const record = experimentRecord as ExperimentSentForCorrectionEventNotification;
    if (!record.eSignatureContext?.signed) {
      return undefined;
    }
    const eSignedTo = $localize`:@@eSignedForCorrection:ESigned For Correction`;
    const description = this.getAuditDescriptionOfInCorrectionFor(
      experimentRecord,
      experimentWorkFlowPreviousStatus
    );
    return this.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      $localize`:@@eSignature:E-Signature`,
      this.experiment.experimentNumber,
      `${this.cover} > ${eSignedTo}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getAuditDescriptionOfInReviewFor(
    experimentRecord: ExperimentDataRecordNotification,
    experimentWorkFlowPreviousStatus: ExperimentDataRecordNotification
  ) {
    const eSignedDetails = this.getSignedDetails(experimentRecord as WorkflowEventNotification);
    if (
      (experimentWorkFlowPreviousStatus as WorkflowEventNotification).state ===
      ExperimentWorkflowState.InProgress
    ) {
      return $localize`:@@inProgressToInReviewESignatureDescription:Experiment complete and ready for review by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    } else {
      return $localize`:@@inCorrectionToInReviewESignatureDescription:Experiment corrected and send for review by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    }
  }

  private getAuditDescriptionOfInCorrectionFor(
    experimentRecord: ExperimentDataRecordNotification,
    experimentWorkFlowPreviousStatus: ExperimentDataRecordNotification
  ) {
    const eSignedDetails = this.getSignedDetails(experimentRecord as WorkflowEventNotification);
    if (
      (experimentWorkFlowPreviousStatus as WorkflowEventNotification).state ===
      ExperimentWorkflowState.InReview
    ) {
      return $localize`:@@inReviewToInCorrectionESignatureDescription:Experiment reviewed and send for correction by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    } else if (
      (experimentWorkFlowPreviousStatus as WorkflowEventNotification).state ===
      ExperimentWorkflowState.Authorized
    ) {
      return $localize`:@@authorizedToInCorrectionESignatureDescription:Authorized Experiment send for correction by ${eSignedDetails.signedBy} on ${eSignedDetails.signedOn}`;
    } else {
      return $localize`:@@cancelledToInCorrectioneSignatureAuditDescriptionForExperimentStarted:Cancelled Experiment sent for correction by ${
        eSignedDetails.signedBy
      } on ${eSignedDetails.signedOn}`;
    }
  }

  private getExperimentSetupReviewRecord(experimentRecord: ExperimentDataRecordNotification) {
    const record = experimentRecord as ExperimentSentForReviewEventNotification;
    const setup = $localize`:@@experimentTransitionedTo:Transitioned to`;
    const description = setup + this.getExperimentState(record.state);
    return this.getHistory(
      experimentRecord,
      this.experiment.experimentNumber,
      ExperimentRecordTypesHelper.getLocalizeRecordTypesFromNotification(experimentRecord, $localize`:@@recordType-workflow:Workflow`),
      this.experiment.experimentNumber,
      `${this.cover} > ${description}`,
      description,
      this.experiment.experimentNumber
    );
  }

  private getSignedDetails(experimentRecord: WorkflowEventNotification): {
    signedBy: string;
    signedOn: string;
  } {
    const signedBy =
      this.usersList?.find(
        u => u.puid.toLowerCase() === experimentRecord.eventContext.puid.toLowerCase()
      )?.fullName ?? '';
    const signedOn = formatInstant(
      experimentRecord.eventContext.eventTime,
      DateAndInstantFormat.dateTimeToSecond
    );
    return { signedBy, signedOn };
  }

  private getPreparationEventRecordContext(event: ExperimentPreparationsCreatedNotification): AuditHistory[] {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const preparations = $localize`:@@preparations:Preparations`;
    const preparationCreated: AuditHistory[] = [];
    reverse(event.createdPreparations).forEach(prep => {
     preparationCreated.push(  
      {
        Time: event.eventContext.eventTime,
        Context: `${activityName} > ${preparations} > ${preparations} > ${prep.preparationNumber}`,
        RecordType: $localize`:@@preparationCreated:Preparation Created`,
        ContextType: $localize`:@@preparationNew:Preparation, New`,
        Name: preparations,
        Description: `${prep.preparationNumber}`,
        RecordVersion: 1,
        PerformedBy: this.usersList?.find(
          u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
        )?.fullName,
        ActualContext: event.activityId + event.eventContext.eventType,
       }   
     ) 
    });
    return preparationCreated; 
  }

  private getPreparationRestoredEventRecordContext(event: ActivityPreparationRestoredNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const prepNumber = this.experiment.activities.filter(act => act.activityId === event.activityId).map(
      activity => activity.preparations.find(prep => prep.preparationId === event.preparationId)?.preparationNumber
    );
    const preparations = $localize`:@@preparations:Preparations`;
    return {
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${preparations} > ${preparations} > ${prepNumber}`,
      RecordType: $localize`:@@preparationRestoredType:Preparation Restored`,
      ContextType: $localize`:@@preparationRestored:Preparation, Restored`,
      Name: preparations,
      Description: $localize`:@@auditPreparationRestored:${prepNumber} Preparation restored`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType,
    }
  }

  private getPreparationRemovedEventRecordContext(event: ActivityPreparationRemovedNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const prepNumber = this.experiment.activities.filter(act => act.activityId === event.activityId).map(
      activity => activity.preparations.find(prep => prep.preparationId === event.preparationId)?.preparationNumber
    );
    const preparations = $localize`:@@preparations:Preparations`;
    return {
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${preparations} > ${preparations} > ${prepNumber}`,
      RecordType: $localize`:@@preparationRemoved:Preparation Removed`,
      ContextType: $localize`:@@preparationCommaRemoved:Preparation, Removed`,
      Name: preparations,
      Description: $localize`:@@auditPreparationRemoved:${prepNumber} Preparation removed`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType,
    }
  }

  private getPreparationDiscardOrConsumedEventRecordContext(event: PreparationDiscardedOrConsumedNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const preparations = $localize`:@@preparations:Preparations`;
    const prepNumber = this.experiment.activities.filter(act => act.activityId === event.activityId).map(
      activity => activity.preparations.find(prep => prep.preparationId === event.preparationId)?.preparationNumber
    );
    return {
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${preparations} > ${preparations} > ${prepNumber}`,
      RecordType: event.discardedOrConsumed ? $localize`:@@preparationDiscardType:Preparation Discarded or Consumed` :
        $localize`:@@preparationNotDiscardedorConsumedType:Preparation Not Discarded or Consumed`,
      ContextType: $localize`:@@preparationDiscardedOrConsumedContext:Preparation, Discarded or consumed`,
      Name: preparations,
      Description: event.discardedOrConsumed ? $localize`:@@auditPreparationDiscarded:${prepNumber} discarded or consumed` :
        $localize`:@@auditPreparationConsumed:${prepNumber} not discarded or consumed`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType,
    }
  }

  private getActivityFilesAddedEventRecordContext(event: AttachedFileEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const outputs = $localize`:@@outputs:Outputs`;
    const attachedFiles = $localize`:@@attachedFiles:Attached Files`;
    return {
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${outputs} > ${attachedFiles}`,
      RecordType: $localize`:@@recordType-attachedFile:Attached File`,
      ContextType: $localize`:@@recordType-attachedFile:Attached File`,
      Name: $localize`:@@attachedFileRecordName:Attached Files`,
      Description: $localize`:@@attachedFileRecordDescription:Attached [${event.title}]`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        (u) => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType,
    }
  }

  private getActivityFilesDeletedEventRecordContext(event: DeletedFilesEventNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const outputs = $localize`:@@outputs:Outputs`;
    const attachedFiles = $localize`:@@attachedFiles:Attached Files`;
    return {
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${outputs} > ${attachedFiles}`,
      RecordType: $localize`:@@recordType-attachedFile:Attached File`,
      ContextType: $localize`:@@recordType-attachedFile:Attached File`,
      Name: $localize`:@@attachedFiles:Attached Files`,
      Description: $localize`:@@deletedFilesRecordDescription:Removed [${event.fileIds[0]}]`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        (u) => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType,
    };
  }

  private getPreparationCellChangedEventRecordContext(event: PreparationCellChangedNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const changedPreparation = this.experiment.activities
      .find(activity => activity.activityId === event.activityId)?.preparations.find(prep => prep.preparationId === event.preparationId)?.preparationNumber;
    const preparations = $localize`:@@preparations:Preparations`;
    return {
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${preparations} > ${preparations} > ${changedPreparation} > ${this.getPreparationColumnForHistory(event.changedField)}`,
      RecordType: $localize`:@@preparationCellChanged:Preparation Cell Changed`,
      ContextType: $localize`:@@preparationCellChange:Preparation, Cell change`,
      Name: preparations,
      Description: this.getDescriptionForPreparationHistory(event),
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType,
    };
  }

  private getPreparationInternalInformationChangedEventRecordContext(event: PreparationInternalInformationChangedNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const preparations = $localize`:@@preparations:Preparations`;
    const changedPreparation = this.experiment.activities
      .find(activity => activity.activityId === event.activityId)?.preparations.find(prep => prep.preparationId === event.preparationId)?.preparationNumber;
    return {
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${preparations} > ${preparations} > ${changedPreparation}`,
      RecordType: $localize`:@@preparationInternalInfoChange:Preparation Internal Information Changed`,
      ContextType: $localize`:@@preparationChange:Preparation, Internal information change`,
      Name: preparations,
      Description: $localize`:@@auditPreparationInternalInfoChanged:${changedPreparation} internal information changed`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType,
    };
  }

  private getPreparationStatusChangedEventRecordContext(event: ExperimentPreparationStatusChangedNotification): AuditHistory {
    const activityName = this.experiment.activities.find(activity => activity.activityId === event.activityId)?.itemTitle;
    const preparations = $localize`:@@preparations:Preparations`;
    const status = $localize`:@@Status:Status`;
    const prepNumber = this.experiment.activities.filter(act => act.activityId === event.activityId).map(
      activity => activity.preparations.find(prep => prep.preparationId === event.preparationId)?.preparationNumber
    );
    return {
      Time: event.eventContext.eventTime,
      Context: `${activityName} > ${preparations} > ${preparations} > ${prepNumber} > ${status}`,
      RecordType: $localize`:@@preparationStatusChange:Preparation Status Changed`,
      ContextType: $localize`:@@preparationStatusChangeContext:Preparation, Status change`,
      Name: preparations,
      Description: `${event.status.charAt(0).toUpperCase()}${event.status.slice(1)}`,
      RecordVersion: 1,
      PerformedBy: this.usersList?.find(
        u => u.puid.toLowerCase() === event.eventContext.puid.toLowerCase()
      )?.fullName,
      ActualContext: event.activityId + event.eventContext.eventType,
    };
  }

  public displayUnHandledErrorNotification(
    notification: NotificationResult,
    operationType: string
  ): void {
    if (notification.notifications.length === 0) return;
    
    const message = this.localizeNotificationMessage(notification.notifications[0]);
    this.displayNotification(message, operationType, DataRecordService.MessageTypeMapForNotification[notification.notifications[0].notificationType]);
  }

  private displayNotification(detail: string, operationType: string, severity: string): void {
    const errorMessage: Message = {
      key: 'notification',
      severity: severity,
      summary: `${operationType}`,
      detail: detail,
      sticky: false
    };
    this.messageService.add(errorMessage);
  }

  private localizeNotificationMessage(notification: NotificationDetails): string {
    const localize = this.getLocalize();
    // "Any" type can not avoided here as TemplateStringArray has many members which are not needed to be supplied from our end
    const translatedMessage = localize({
      '0': `:@@${notification.translationKey}:${notification.translationKey}`,
      raw: [':']
    } as any);
    if (translatedMessage !== notification.translationKey) {
      return translatedMessage;
    }
    return notification.message;
  }

  getLocalize() {
    return $localize;
  }

  getColumnType(type: ValueType): ColumnType {
    switch (type) {
      case ValueType.Number:
        return ColumnType.Quantity;
      case ValueType.String:
        return ColumnType.String;
      case ValueType.LocalDate:
      case ValueType.Instant:
        return ColumnType.Date;
      default: throw new Error('Unexpected value type was passed');
    }
  }

  getDescriptionForPreparationHistory(event: PreparationCellChangedNotification): string {
    if (event.changedField === 'ExpirationValue' && event.changedValue.state === ValueState.NotApplicable) {
      return $localize`:@@whileSuitable:While suitable for use`
    }
    if (event.changedValue.type !== ValueType.StringDictionary) {
      const columnType = this.getColumnType(event.changedValue.type)
      const primitiveValue = this.dataValueService.getPrimitiveValue(columnType, { isModified: false, value: event.changedValue });

      return (columnType === ColumnType.Date) ? this.formatDate(event.changedValue.type, primitiveValue) : primitiveValue;
    } else {
      return this.dataValueService.joinValues(event.changedValue as StringTypeDictionaryValue); // using type assertion since we are already checking the type above
    }
  }

  private formatDate(type: ValueType, value: any): string {
    return (type === ValueType.Instant) ? formatInstant(value, DateAndInstantFormat.dateTimeToMinute) : formatLocalDate(value);
  }

  getPreparationColumnForHistory(changedField: string): string {
    return this.preparationColumns[changedField];
  }
}

type HistoryRecord = ExperimentDataRecordNotification & {
  newValue: {
    value: any
  },
  columnValues: {
    propertyValue: {
      value: any
    }
  }[]
};
