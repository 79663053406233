<div class="eln-activity">
  <bpt-progress-spinner
    *ngIf="isLoading"
    [useOverlay]="true"
    [message]="message"></bpt-progress-spinner>

  <p-tabView
    cdkDropList
    #tabview
    *ngIf="experiment"
    class="experimentPage"
    orientation="left"
    [scrollable]="false"
    (onChange)="moduleSelectionChanged($event)"
    (cdkDropListDropped)="drop($event)"
    cdkDropListOrientation="horizontal"
    [cdkDropListDisabled]="!experimentNodeReOrderService.hasPermission() && !experimentNodeReOrderService.hasPermissionWithRespectToFlow()"
  >
    <div *ngIf="validation.warnings.length > 0">
      <p-messages severity="warn" styleClass="p-mr-2" id="eln-data-component-warning">
        <ng-template pTemplate>
          <span class="p-message-icon pi pi-exclamation-triangle"></span>
          <span class="p-ml-2">{{ validation.warningTitle }}</span>
          <ul>
            <li *ngFor="let warn of validation.warnings">{{ warn }}</li>
          </ul>
        </ng-template>
      </p-messages>
    </div>
    <div *ngIf="validation && !validation.isValid()" class="eln-column-title-validation-message">
      <app-validation-summary [validation]="validation"></app-validation-summary>
    </div>
    
    <p-contextMenu class="eln-module-contextMenu" [model]="items" #cm></p-contextMenu>
    <ng-container *ngFor="let module of fixedModules; let i = index">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span cdkDrag
            *ngIf="!retitleEnabled || !retitleEnabled[module.moduleId]"
            class="eln-module-tab-header"
            (contextmenu)="cm.show($event); onContextMenu(module,cm)"
            pTooltip="{{ module.moduleLabel }}"
            tooltipPosition="top"
            [id]="'eln-tabAuditHistoryShow' + i"
            [innerText]="module.moduleLabel"
            (dblclick)="contextCurrentModuleId = module.moduleId; retitleEnabled[module.moduleId] = true">
          </span>
          <div class="bpt-eln-inline-control">
            <app-experiment-node-title-changer
              *ngIf="retitleEnabled && retitleEnabled[module.moduleId]"
              [nodeId]="module.moduleId"
              [parentNodeId]="[activity.activityId]"
              [nodeType]="nodeType.Module"
              [currentTitle]="module.moduleLabel"
              (titleChangeHasCancelled)="retitleEnabled[module.moduleId] = false; fixupTabView();"
              (titleChanged)="module.moduleLabel = $event.title;retitleEnabled[module.moduleId] = false; fixupTabView();">
            </app-experiment-node-title-changer>
          </div>
          <span *ngIf="module.isModuleComplete;else elseBlock">
            <span class="icon-check icon-m"
              [pTooltip]="options.completeTooltip"
              tooltipPosition="bottom"
              i18n-pTooltip="@@moduleComplete"></span>
          </span>
          <ng-template #elseBlock>
            <span class="icon-exclamation-mark icon-s"
              [pTooltip]="options.incompleteTooltip"
              tooltipPosition="bottom"
              i18n-pTooltip="@@moduleIncomplete"></span>
          </ng-template>
          <span
            *ngIf="i !== fixedModules.length - 1"
            class="eln-module-tab-header-separator"></span>
        </ng-template>
        <ng-template #moduleTemplate>
          <app-data-module [module]="module" [parentNodeId]="[activity.activityId]"></app-data-module>
        </ng-template>
        <ng-container *ngIf="experimentService.currentModuleId === module.moduleId">
          <ng-container *ngTemplateOutlet="moduleTemplate">
          </ng-container>
        </ng-container>
      </p-tabPanel>
    </ng-container>
    <p-contextMenu *ngIf="moreModules.length > 0" class="eln-more-Module-contextMenu" [model]="itemsMoreModules" #cmForMoreModule></p-contextMenu>
    <ng-container *ngIf="moreModules.length > 0" (click)="$event.stopPropagation()">
      <p-tabPanel [headerStyleClass]="'more-modules-header'">
        <ng-template pTemplate="header">
          <span id="eln-moreModulesHeader" (click)="$event.stopPropagation()">
            <bpt-menu-button (contextmenu)=" onMoreModuleContext($event) ;" [model]="moreModuleDropdownOptions"
              [label]="moreModulesHeader">
            </bpt-menu-button>
          </span>
        </ng-template>
        <app-data-module [module]="selectedModule" [parentNodeId]="[activity.activityId]"></app-data-module>
      </p-tabPanel>
    </ng-container>
  </p-tabView>
</div>