import { Injectable } from "@angular/core";
import * as signalR from '@microsoft/signalr';
import { environment } from "../../../../environments/environment";
import { InstrumentDetailsDto, InstrumentSearchDto, InstrumentSearchResultsDto } from "../../../api/instrument-admin/models";
import { UserService } from "../../../services/user.service";
import { InstrumentConnectionHelper } from "./instrument-connection-helper";
import { InstrumentsReadingResponse } from "./instrument-getreading-response";
import { Subject } from "rxjs";
import { ConnectionResult } from "../../model/instrument-connection/connection-result";
import { InstrumentService as InstrumentAdminService } from "../../../api/instrument-admin/services";
import { InstrumentDetailsLimsDto, InstrumentResponse } from "../../../api/models";
import { InstrumentService } from "../../../api/services";
import { InstrumentType } from "./instrument-type";
import { InstrumentConnectionService } from "../instrument-connection.service";

@Injectable({
  providedIn: 'root'
})
export class InstrumentNotificationService {
  hubConnection!: signalR.HubConnection;
  private get serviceUrl(): string {
    return `${environment.instrumentServerUrl}/InstrumentNotifications`;
  }
  instrumentDetails?: InstrumentDetailsDto;
  instrumentDetailsLims?: InstrumentDetailsLimsDto;
  clientName?: string;
  plannedWeightCount = 10000;
  timeoutMessage = 'Response timeout after 30 seconds.';
  signalRDisconnectedMessage = 'Not connected to SignalR server instrument hub.';
  responseReceived = false;
  readonly instrumentGetReadings = new Subject<InstrumentsReadingResponse>();
  BALANCES_INSTRUMENT = 'Balances';

  constructor(
    private readonly instrumentConnectionHelper: InstrumentConnectionHelper,
    private readonly userService: UserService,
    private readonly instrumentAdminService: InstrumentAdminService,
    private readonly instrumentService: InstrumentService,
    private readonly instrumentConnectionService: InstrumentConnectionService
  ) {
    this.clientName = this.userService.currentUser.puid;
  }

  getConnectionBuilder() {
    return new signalR.HubConnectionBuilder();
  }

  async connectToInstrument(instrument: InstrumentDetailsDto) {
    this.instrumentDetails = instrument;
    this.startConnection(InstrumentType.balance);
  }

  async stopPushReadings(equipmentId: string | null | undefined) {
    if (!equipmentId) {
      return;
    }
    if (this.hubConnection) {
      if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
        await this.startHubConnection();
      }
      await this.hubConnection.send('StopPushReadings', this.clientName, equipmentId);
    }
  }

  async disconnectFromInstrument(equipmentId: string, instrumentType: string) {
    if (this.hubConnection != null) {
      if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
        await this.startHubConnection();
      }
      const result = await this.invokeHub(
        'disconnectFromInstrument',
        this.clientName,
        equipmentId,
        instrumentType
      );
      if (result.instrumentType === InstrumentType.phMeter) {
        this.instrumentConnectionHelper.phMeterConnectionSuccessfulForOtherExperiments.next(
          undefined
        );
        this.instrumentConnectionHelper._equipmentId = undefined;
        this.instrumentConnectionHelper.pageLoaded.next(
          true
        );
      } else {
        this.instrumentConnectionHelper.instrumentDisconnectedSuccessfully.next(true);
      }
    } else {
      this.instrumentConnectionHelper.setInstrumentConnectedStatus(false);
      console.error(this.signalRDisconnectedMessage);
    }
  }

  stopHubConnection() {
    // server cleans up anything going on under this connection
    if (this.hubConnection) {
      this.hubConnection.stop().then(() => {
        console.log('Disconnected.');
      });
    }
  }

  async setConnection() {
    console.log("instrument server url while setting connection: " + this.serviceUrl);
    this.hubConnection = this.getConnectionBuilder()
      .withUrl(this.serviceUrl, {
        withCredentials: false
      })
      .build();
    // Listen for the 'ReceiveWeightReading' event
    this.hubConnection.on(
      'ReceiveWeightReading',
      (equipmentId, reading: InstrumentsReadingResponse) => {
        // Log the received reading
        this.instrumentGetReadings.next(reading);
        // Set responseReceived to true to indicate that the response has been received
        this.responseReceived = true;
      }
    );

    this.hubConnection.on('InstrumentConnected', (result: ConnectionResult) => {
      if (result.equipmentId) {
        if (result.isSuccess) {
          this.instrumentConnectionHelper.instrumentType = result.instrumentType;
          this.instrumentConnectionHelper.instrumentConnectionSuccess.next(result.equipmentId);
          this.instrumentConnectionHelper.sequentialReadingIsInProgress.next(result.sequentialReadingIsInProgress ?? false);
          if (result.isPaused) this.instrumentConnectionHelper.instrumentConnectionPausedSuccessfully.next({ instrumentType: InstrumentType.balance, isPaused: true });
          else this.instrumentConnectionHelper.instrumentConnectionUnpausedSuccessfully.next({ instrumentType: InstrumentType.balance, isPaused: false });
          this.instrumentConnectionHelper.instrumentConnectionSuccessForOtherExperiments.next(result.equipmentId);
        } else {
          console.error('Instrument could not be connected successfully');
        }
      }
    });

    this.hubConnection.on('InstrumentDisconnected', (result: any) => {
      if (result.isSuccess) {
        this.instrumentConnectionHelper.instrumentDisconnectedSuccessfully.next(true);
      } else {
        console.error('Instrument could not be disconnected successfully');
      }
    });

    this.hubConnection.on('InstrumentPaused', (result: any) => {
      if (result.isSuccess) {
        this.instrumentConnectionHelper.instrumentConnectionPausedSuccessfully.next({ instrumentType: result.instrumentType ?? InstrumentType.balance, isPaused: true });
      } else {
        console.error('Instrument could not be paused');
      }
    });

    this.hubConnection.on('InstrumentUnPaused', (result: any) => {
      if (result.isSuccess) {
        this.instrumentConnectionHelper.instrumentConnectionUnpausedSuccessfully.next({ instrumentType: result.instrumentType ?? InstrumentType.balance, isPaused: false });
      } else {
        console.error('Instrument could not be unpaused');
      }
    });

    this.hubConnection.on('SequentialReadingIsInProgress', (result: any) => {
      if (result.isSuccess) {
        this.instrumentConnectionHelper.sequentialReadingIsInProgress.next(result.sequentialReadingIsInProgress);
      } else {
        console.error('Instrument could not be unpaused');
      }
    });

    this.hubConnection.on('PhMeterConnected', (result: ConnectionResult) => {
      if (result.isSuccess) {
        this.instrumentConnectionHelper.phMeterConnectionSuccessfulForOtherExperiments.next(result.equipmentId);
      } else {
        console.error('pH meter could not be connected');
      }
    });

    this.hubConnection.on('PhMeterDisconnected', (result: ConnectionResult) => {
      if (result.isSuccess) {
        this.instrumentConnectionHelper.phMeterIsDisconnected.next(true);
        this.instrumentConnectionHelper.pageLoaded.next(true);

      } else {
        console.error('pH meter could not be disconnected');
      }
    });
  }

  private async invokeHub<T = any>(methodName: string, ...args: any[]) {
    let result: ConnectionResult = {};
    if (this.hubConnection?.state === signalR.HubConnectionState.Connected) {
      result = await this.hubConnection.invoke(methodName, ...args);
    }
    return result;
  }

  readonly startHubConnection = async () => {
    try {
      await this.hubConnection.start();
    } catch (error) {
      console.assert(this.hubConnection.state === signalR.HubConnectionState.Disconnected);
    }
  };

  async pauseOrUnpauseInstrumentConnection(instrumentEquipmentId: string, isPaused: boolean, instrumentType: InstrumentType) {
    try {
      const result = await this.invokeHub(
        'PauseOrUnpauseInstrumentConnection',
        this.clientName,
        instrumentEquipmentId,
        isPaused,
        null
      );
      if (result.isSuccess)
        if (result.isPaused) {
          this.instrumentConnectionHelper.instrumentConnectionPausedSuccessfully.next({ instrumentType: instrumentType, isPaused: true });
        } else {
          this.instrumentConnectionHelper.instrumentConnectionUnpausedSuccessfully.next({ instrumentType: instrumentType, isPaused: false });
        }
    } catch (error) {
      console.assert(this.hubConnection.state === signalR.HubConnectionState.Disconnected);
    }
  }

  async updateSequentialReadingProgress(isSequentialReadingProgress: boolean) {
    try {
      const instrumentEquipmentId =
        this.instrumentConnectionHelper.connectedInstrument?.equipmentId;
      const result = await this.invokeHub(
        'UpdateSequentialReadingProgress',
        this.clientName,
        instrumentEquipmentId,
        isSequentialReadingProgress
      );
      if (result.isSuccess) {
        this.instrumentConnectionHelper.sequentialReadingIsInProgress.next(result.sequentialReadingIsInProgress ?? false);
      }
    } catch (error) {
      console.assert(this.hubConnection.state === signalR.HubConnectionState.Disconnected);
    }
  }

  async connectToPhMeter() {
    this.startConnection(InstrumentType.phMeter, this.instrumentConnectionHelper.phName);
  }

  readonly joinInstrumentHub = async (instrumentType: InstrumentType) => {
    try {
      console.log('instrument server url while joining hub: ' + this.serviceUrl);
      await this.setConnection();
      await this.hubConnection.start();
      const result = await this.invokeHub('JoinInstrumentGroup', this.clientName, instrumentType);
      if (result.isSuccess) {
        console.log(`Joined hub successfully with response: ${result} for instrument: ${instrumentType}`);
        this.handleJoinHubConnectionSuccess(result);
        this.instrumentConnectionHelper.instrumentDisconnected = false;
      }
    } catch (error) {
      console.assert(this.hubConnection.state === signalR.HubConnectionState.Disconnected);
      this.instrumentConnectionHelper.instrumentJoinHubStatus.next(false);
      this.instrumentConnectionHelper.instrumentDetailsFetched.next(false);
      this.instrumentConnectionHelper.instrumentLIMSDetailsFetched.next(false);
      console.log(`Joining hub failed for instrument: ${instrumentType}`);
    }
  };

  public handleJoinHubConnectionSuccess(result: ConnectionResult) {
    this.instrumentConnectionHelper.instrumentType = result.instrumentType;
    if (result.instrumentType === InstrumentType.phMeter) {
      this.instrumentConnectionHelper.instrumentConnectionSuccessForOtherExperiments.next(
        undefined
      );
      this.instrumentConnectionHelper.pageLoaded.next(true);
      this.instrumentConnectionHelper.phMeterConnectionSuccessfulForOtherExperiments.next(result.equipmentId);
      this.pauseUnpauseInstrument(result);
    } else {
      if (result.equipmentId) {
        this.loadInstrumentDetails(result.equipmentId, true);
        this.instrumentConnectionHelper.instrumentConnectionSuccess.next(result.equipmentId);
        this.instrumentConnectionHelper.sequentialReadingIsInProgress.next(result.sequentialReadingIsInProgress ?? false);
        this.instrumentConnectionHelper.instrumentConnectionSuccessForOtherExperiments.next(result.equipmentId);

        this.pauseUnpauseInstrument(result);
      } else {
        this.instrumentConnectionHelper.instrumentConnectionSuccessForOtherExperiments.next(undefined);
        this.instrumentConnectionHelper.phMeterConnectionSuccessfulForOtherExperiments.next(undefined);
        this.instrumentConnectionHelper.instrumentDetailsFetched.next(true);
        this.instrumentConnectionHelper.instrumentLIMSDetailsFetched.next(true);
      }
    }
  }

  readonly startConnection = async (instrumentType: string, instrumentEquipmentId?: string) => {
    const equipmentId = instrumentEquipmentId ?? this.instrumentDetails?.equipmentId;
    try {
      console.log(`Hub connection ${this.hubConnection}, Instrument server: ${this.serviceUrl}`);
      if (!this.hubConnection) {
        console.log(`setting/starting hub connection ${this.hubConnection} with instrument server: ${this.serviceUrl}`);
        await this.setConnection();
      }
      else if (this.hubConnection?.state === signalR.HubConnectionState.Disconnected) {
        console.log("Hub is disconnected with server and starting connection now: " + this.serviceUrl);
        await this.startHubConnection();
      }
      const result = await this.invokeHub(
        'ConnectToInstrument',
        this.clientName,
        equipmentId,
        instrumentType,
        null
      );
      console.log(`Response from hub for ConnectToInstrument and client Name: ${this.clientName}, equipment: ${equipmentId}, instrument type: ${instrumentType}, Result: ${result} `);
      if (equipmentId) {
        if (result.isSuccess) {
          console.log('Connection to hub is successful for equipment: ' + equipmentId);
          this.instrumentConnectionHelper.instrumentDisconnected = false;
          this.handleStartConnectionSuccess(result, equipmentId);
        } else {
          console.log('Connection to hub is not successful for equipment: ' + equipmentId);
          this.instrumentConnectionHelper.instrumentConnectionFailed.next(equipmentId);
          this.instrumentConnectionHelper.instrumentDetailsFetched.next(false);
          this.instrumentConnectionHelper.instrumentLIMSDetailsFetched.next(false);
        }
      }
    } catch (error) {
      console.log('Error: ' + error);
      if (equipmentId) {
        this.instrumentConnectionHelper.instrumentConnectionFailed.next(equipmentId);
      }
      this.instrumentConnectionHelper.instrumentDetailsFetched.next(false);
      this.instrumentConnectionHelper.instrumentLIMSDetailsFetched.next(false);
    }
  };

  private pauseUnpauseInstrument(result: ConnectionResult) {
    if (result.isPaused) {
      this.instrumentConnectionHelper.instrumentConnectionPausedSuccessfully.next({ instrumentType: result.instrumentType ?? InstrumentType.balance, isPaused: true });
    } else {
      this.instrumentConnectionHelper.instrumentConnectionUnpausedSuccessfully.next({ instrumentType: result.instrumentType ?? InstrumentType.balance, isPaused: false });
    }
  }

  public handleStartConnectionSuccess(result: ConnectionResult, equipmentId: string) {
    if (result.instrumentType === InstrumentType.phMeter) {
      this.instrumentConnectionHelper.pageLoaded.next(
        true
      );
      this.instrumentConnectionHelper._equipmentId = equipmentId;
      console.log("EquipmentId: " + this.instrumentConnectionHelper._equipmentId);
    } else {
      this.loadInstrumentDetails(equipmentId, false);
      this.instrumentConnectionHelper.instrumentConnectionSuccess.next(equipmentId);
      if (result.isPaused) this.instrumentConnectionHelper.instrumentConnectionPausedSuccessfully.next({ instrumentType: InstrumentType.balance, isPaused: true });
      else this.instrumentConnectionHelper.instrumentConnectionUnpausedSuccessfully.next({ instrumentType: InstrumentType.balance, isPaused: true });
      this.instrumentConnectionHelper.connectedInstrument = this.instrumentDetails;
      this.instrumentConnectionHelper.instrumentDetailsFetchInitiated.next(true);
    }
  }

  loadInstrumentDetails(equipmentId: string, loadInstrumentDetails: boolean) {
    const balanceInstrumentsSearch = this.getSearchCriteria();
    if (loadInstrumentDetails) {
      this.instrumentAdminService
        .instrumentsSearchPost$Json({ body: balanceInstrumentsSearch })
        .subscribe({
          next: (result: InstrumentSearchResultsDto) => {
            this.instrumentDetails = result.instrumentDetails?.find(
              (instrumentDetail: InstrumentDetailsDto) =>
                instrumentDetail.equipmentId === equipmentId
                );
            this.instrumentConnectionHelper.connectedInstrument = this.instrumentDetails;
            this.instrumentConnectionHelper.instrumentDetailsFetched.next(true);
          },
          error: () => {
            this.instrumentDetails = undefined;
            this.instrumentConnectionHelper.instrumentDetailsFetched.next(true);
          }
        });
    }
    this.instrumentService
      .instrumentsInstrumentCodeGet$Json({ instrumentCode: equipmentId })
      .subscribe({
        next: (result: InstrumentResponse) => {
          this.instrumentDetailsLims = result.instrumentDetails;
          this.instrumentConnectionService.setBlowerState(this.instrumentDetailsLims);
          this.instrumentConnectionHelper.instrumentLIMSDetailsFetched.next(true);
        },
        error: () => {
          this.instrumentDetailsLims = undefined;
          this.instrumentConnectionHelper.instrumentLIMSDetailsFetched.next(true);
        }
      });
  }

  private getSearchCriteria(): InstrumentSearchDto {
    return {
      filterDictionary: {
        Labsites: [this.userService.currentUser.labSiteCode],
        Groups: [this.BALANCES_INSTRUMENT]
      },
      sortingDictionary: {}
    };
  }

  requestWeightReadings() {
    if (this.hubConnection != null) {
      this.responseReceived = false; // Reset the flag before sending the request
      this.hubConnection
        .send('pullWeightReading', this.clientName, this.instrumentDetails?.equipmentId)
        .catch((err) => {
          // Handle the send operation error here
          console.error(err);
        });
    } else {
      const message = this.signalRDisconnectedMessage;
      console.error(message);
    }
  }

  requestWeightReadingsWithPush() {
    if (this.hubConnection != null) {
      this.responseReceived = false; // Reset the flag before sending the request
      this.hubConnection
        .send(
          'requestWeightReadings',
          this.clientName,
          this.instrumentDetails?.equipmentId,
          this.plannedWeightCount
        )
        .catch((err) => {
          // Handle the send operation error here
          console.error(err);
        });
    } else {
      const message = this.signalRDisconnectedMessage;
      console.error(message);
    }
  }

  stopReading() {
    if (this.hubConnection != null) {
      this.hubConnection
        .send('StopReadings', this.clientName, this.instrumentDetails?.equipmentId)
        .then((response) => { })
        .catch((err) => { });
    }
  }
}