import { ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { ColumnType } from '../../../api/models';
import { DateBasedWarningIconRendererComponent } from '../../shared/date-based-warning-icon-renderer';
import { LinkOutCellRendererComponent } from '../sample-table/linkout-cell-renderer';
import { ITooltipParams, ValueGetterParams } from 'ag-grid-community';

export class StudyActivityTableGridOptions {
  private static readonly cellStyleClass = 'grid-cell-align';
  public static isReadOnly = true;
  private static readonly maxIsoDateForRefresh = '9999-12-31'

  static prepareColumns(openStudyModelCallback?: (row: any) => void, removedRows = false): ColumnDefinition[] {
    const columns: ColumnDefinition[] = [
      {
        label: $localize`:@@preparationRowId:Row ID`,
        field: 'id',
        columnType: ColumnType.RowId,
        hidden: true,
        editable: false,
        showInColumnChooser: true
      },
      {
        label: $localize`:@@Index:Index`,
        field: 'rowIndex',
        columnType: ColumnType.Index,
        hidden: true,
        showInColumnChooser: false,
        editable: false
      },
      {
        field: 'code',
        label: $localize`:@@aliquotId:Aliquot ID`,
        minWidth: 300,
        width: 'auto',
        editable: false,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: false,
        cellRenderer: LinkOutCellRendererComponent,
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: LinkOutCellRendererComponent
        }
      },
      {
        label: $localize`:@@StudyActivity:Study Activity`,
        columnType: ColumnType.String,
        field: 'studyActivityName',
        maxWidth: 320,
        width: 320,
        allowAutoResize: true,
        editable: false,
        onCellClicked: openStudyModelCallback?.bind(this),
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data ? params.data.studyActivityName : '';
        },
        showInColumnChooser: false
      },
      {
        field: 'studyName',
        label: $localize`:@@StudyName:Study Name`,
        minWidth: 180,
        width: 180,
        editable: false,
        columnType: ColumnType.String,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data ? params.data.studyName : '';
        }
      },
      {
        field: 'studyGroupName',
        label: $localize`:@@StudyGroupName:Study Group Name`,
        columnType: ColumnType.String,
        minWidth: 180,
        width: 180,
        cellClass: this.cellStyleClass,
        editable: false,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data ? params.data.studyGroupName : '';
        },
        showInColumnChooser: true
      },
      {
        field: 'accountCode',
        label: $localize`:@@AccountCode:Account Code`,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        cellClass: this.cellStyleClass,
        editable: false,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: false,
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data ? params.data.accountCode : '';
        }
      },
      {
        field: 'customerAnalyticalProjectCode',
        label: $localize`:@@ProjectCode:Project (CCF) Code`,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        editable: false,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: false,
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data ?
            params.data.customerAnalyticalProjectCode : '';
        }
      },
      {
        field: 'materialStatus',
        label: $localize`:@@MaterialStatus:Material Status`,
        minWidth: 180,
        width: 180,
        editable: false,
        columnType: ColumnType.String,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        }
      },
      {
        field: 'inUseExpirationDate',
        label: $localize`:@@InUseExpiration:In Use Expiration`,
        minWidth: 180,
        width: 180,
        editable: false,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        cellRenderer: DateBasedWarningIconRendererComponent,
        cellRendererParams: {
          toolTipMessage: $localize`:@@MaterialAliquotExpired:Material Aliquot has expired.`
        },
        valueGetter: (params: ValueGetterParams) => {
          if (!params.data.inUseExpirationDate || params.data.inUseExpirationDate == null || params.data.inUseExpirationDate === this.maxIsoDateForRefresh) return '';
          return params.data.inUseExpirationDate;
        }
      },
      {
        field: 'expirationDate',
        label: $localize`:@@ExpiresOn:Expires On`,
        editable: false,
        minWidth: 180,
        width: 180,
        hidden: false,
        cellRenderer: DateBasedWarningIconRendererComponent,
        cellRendererParams: {
          toolTipMessage: $localize`:@@MaterialAliquotExpired:Material Aliquot has expired.`
        },
        valueGetter: (params: ValueGetterParams) => {
          if (!params.data.expirationDate || params.data.expirationDate == null || params.data.expirationDate === this.maxIsoDateForRefresh) return '';
          return params.data.expirationDate;
        }
      },
      {
        field: 'studyActivityCode',
        label: $localize`:@@StudyActivityCode:Study Activity Code`,
        editable: false,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        hidden: true,
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data ? params.data.studyActivityCode : '';
        }
      },
      {
        field: 'studyCode',
        label: $localize`:@@StudyCode:Study Code`,
        editable: false,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        hidden: true,
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data ? params.data.studyCode : '';
        }
      },
      {
        field: 'accountName',
        label: $localize`:@@AccountName:Account Name`,
        editable: false,
        minWidth: 180,
        width: 180,
        hidden: true,
        columnType: ColumnType.String,
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data ? params.data.accountName : '';
        }
      }
    ];
    columns.push(...StudyActivityTableGridOptions.getMoreColumns(removedRows));
    return columns;
  }

  private static readonly getMoreColumns = (removedRows = false): ColumnDefinition[] => {
    return [
      {
        field: 'projectName',
        label: $localize`:@@projectName:Project Name`,
        editable: false,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        hidden: true,
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data ? params.data.projectName : '';
        }
      },
      {
        field: 'status',
        label: $localize`:@@MaterialAliquotStatus:Material Aliquot Status`,
        editable: false,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        hidden: true,
      },
      {
        field: 'elnReference',
        label: $localize`:@@ELNReference:ELN Reference`,
        editable: false,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        hidden: true
      },
      {
        field: 'materialType',
        label: $localize`:@@MaterialType:Material Type`,
        editable: false,
        hidden: true,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String
      },
      {
        field: 'name',
        label: $localize`:@@name:Name`,
        editable: false,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        hidden: true
      },
      {
        field: 'description',
        label: $localize`:@@Description:Description`,
        editable: false,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        hidden: true
      },
      {
        field: 'lotNumber',
        label: $localize`:@@LotNumber:Lot#`,
        editable: false,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        hidden: true
      },
      {
        field: 'concentration',
        label: $localize`:@@Concentration:Concentration`,
        editable: false,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        hidden: true
      },
      {
        field: 'purity',
        label: $localize`:@@purity:Purity`,
        editable: false,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        hidden: true
      },
      {
        field: 'AdditionalInformation',
        label: $localize`:@@additionalInfo:Additional Information`,
        width: 280,
        minWidth: 280,
        allowMultiLine: true,
        editable: () => {
          if (!StudyActivityTableGridOptions.isReadOnly && !removedRows) {
            return true;
          }
          return false;
        },
        columnType: ColumnType.String,
        hidden: true
      }
    ];
  }
}
