import { BptIconDefinition, BptSizes } from 'bpt-ui-library/shared';
import { dropdownBasicList } from 'model/drop-down-basic-list';
import { TemplateType } from '../../api/models';
import { TemplateTypeNameDictionary } from './template-type-names';
import { RecipeType } from '../../api/cookbook/models/recipe-type';

export class TemplateListPresentationHelper {
  public static readonly templateId = 'id';
  public static readonly templateNumber = 'templateNumber';
  public static readonly templateType = 'templateType';
  public static readonly isRecipe = 'isRecipe';

  public static getTemplateTypeDisplayIconDefinition(
    templateType: TemplateType | string | RecipeType
  ): BptIconDefinition | undefined {
    const iconPath = TemplateListPresentationHelper.getTemplateTypeDisplayIconPath(templateType);
    return {
      iconClass: undefined,
      src: iconPath,
      size: { iconSize: BptSizes.Medium },
      styleClass: ' mr-2'
    };
  }

  public static getRecipeTypeDisplayIconDefinition(
    templateType: string | RecipeType
  ): BptIconDefinition | undefined {
    const iconPath = TemplateListPresentationHelper.getRecipeTypeDisplayIconPath(templateType);
    return {
      iconClass: undefined,
      src: iconPath,
      size: { iconSize: BptSizes.Medium },
      styleClass: ' mr-2'
    };
  }

  public static getTemplateTypeDisplayIconPath(
    templateType: TemplateType | string 
  ): string | undefined {
    const nexusIconPath = 'assets/eln-assets/nexus-icons/';
      switch (templateType.toLowerCase()) {
        case TemplateType.Activity:
          return `${nexusIconPath}activity-icon.svg`;
        case TemplateType.Module:
          return `${nexusIconPath}module-icon.svg`;
        case TemplateType.Form:
          return `${nexusIconPath}form-icon.svg`;
        case TemplateType.Table:
          return `${nexusIconPath}table-icon.svg`;
    }
    return undefined;
  }

  public static getRecipeTypeDisplayIconPath(
    templateType: string | RecipeType
  ): string | undefined {
    const nexusIconPath = 'assets/eln-assets/nexus-icons/';
      switch (templateType.toLowerCase()) {
        case RecipeType.Table:
          return `${nexusIconPath}table-recipes-icon.svg`;
        case RecipeType.Form:
          return `${nexusIconPath}form-recipes-icon.svg`;
        case RecipeType.Module:
          return `${nexusIconPath}module-recipes-icon.svg`;
        case RecipeType.Activity:
          return `${nexusIconPath}activity-recipes-icon.svg`;
        case RecipeType.TableForm.toLowerCase():
          return `${nexusIconPath}recipe-form-table-icon.svg`;
        case RecipeType.ActivityGroup.toLowerCase():
          return `${nexusIconPath}recipe-activity-group-icon.svg`;
        case RecipeType.None:
          return undefined;
      }
    
    return undefined;
  }

  public static getTemplateTypeLookup(
    templateTypes: string | undefined
  ): dropdownBasicList<string>[] {
    let templateTypeKeys: string[] = [];
    if (templateTypes) {
      templateTypeKeys = templateTypes.split(',');
    }
    return templateTypeKeys.map((templateTypeKey) => {
      return {
        value: templateTypeKey,
        label: TemplateTypeNameDictionary[templateTypeKey]
      };
    });
  }
}
