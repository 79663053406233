/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ActivityGroupTemplate } from '../models/activity-group-template';
import { ActivityTemplate } from '../models/activity-template';
import { CancelTemplateCommand } from '../models/cancel-template-command';
import { CreateTemplateCommand } from '../models/create-template-command';
import { FormTemplate } from '../models/form-template';
import { MakeTemplateAvailableCommand } from '../models/make-template-available-command';
import { ModuleTemplate } from '../models/module-template';
import { NoContentNotification } from '../models/no-content-notification';
import { PublishMajorVersionTemplateCommand } from '../models/publish-major-version-template-command';
import { RestoreTemplateToDraftCommand } from '../models/restore-template-to-draft-command';
import { RetireTemplateCommand } from '../models/retire-template-command';
import { SaveActivityTemplateCommand } from '../models/save-activity-template-command';
import { SaveAsCopyCommand } from '../models/save-as-copy-command';
import { SaveAsNewDraftCommand } from '../models/save-as-new-draft-command';
import { SaveFormTemplateCommand } from '../models/save-form-template-command';
import { SaveModuleTemplateCommand } from '../models/save-module-template-command';
import { SaveTableTemplateCommand } from '../models/save-table-template-command';
import { TableTemplate } from '../models/table-template';
import { TemplateAddResponse } from '../models/template-add-response';
import { TemplateGetLatestResponse } from '../models/template-get-latest-response';
import { UpdateActivityTemplateCommand } from '../models/update-activity-template-command';
import { UpdateFormTemplateCommand } from '../models/update-form-template-command';
import { UpdateModuleTemplateCommand } from '../models/update-module-template-command';
import { UpdateTableTemplateCommand } from '../models/update-table-template-command';
import { UpdateTemplateIsCoreCommand } from '../models/update-template-is-core-command';
import { UpdateTemplateRulesCommand } from '../models/update-template-rules-command';
import { UpdateTemplateSummaryCommand } from '../models/update-template-summary-command';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation templatesGetLatestGet
   */
  static readonly TemplatesGetLatestGetPath = '/templates/get-latest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesGetLatestGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesGetLatestGet$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<TemplateGetLatestResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesGetLatestGetPath, 'get');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateGetLatestResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesGetLatestGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesGetLatestGet$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<TemplateGetLatestResponse> {

    return this.templatesGetLatestGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateGetLatestResponse>) => r.body as TemplateGetLatestResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesGetLatestGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesGetLatestGet$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<TemplateGetLatestResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesGetLatestGetPath, 'get');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateGetLatestResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesGetLatestGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesGetLatestGet$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<TemplateGetLatestResponse> {

    return this.templatesGetLatestGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateGetLatestResponse>) => r.body as TemplateGetLatestResponse)
    );
  }

  /**
   * Path part for operation templatesIdGet
   */
  static readonly TemplatesIdGetPath = '/templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesIdGet$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesIdGet$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)> {

    return this.templatesIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>) => r.body as (ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate))
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesIdGet$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesIdGet$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)> {

    return this.templatesIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>) => r.body as (ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate))
    );
  }

  /**
   * Path part for operation templatesMajorVersionNumberGet
   */
  static readonly TemplatesMajorVersionNumberGetPath = '/templates/major-version/{number}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesMajorVersionNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesMajorVersionNumberGet$Plain$Response(params: {
    number: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesMajorVersionNumberGetPath, 'get');
    if (params) {
      rb.path('number', params.number, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesMajorVersionNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesMajorVersionNumberGet$Plain(params: {
    number: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {

    return this.templatesMajorVersionNumberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>) => r.body as Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesMajorVersionNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesMajorVersionNumberGet$Json$Response(params: {
    number: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesMajorVersionNumberGetPath, 'get');
    if (params) {
      rb.path('number', params.number, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesMajorVersionNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesMajorVersionNumberGet$Json(params: {
    number: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {

    return this.templatesMajorVersionNumberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>) => r.body as Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>)
    );
  }

  /**
   * Path part for operation templatesNonCancelledTemplatesTemplateNumberGet
   */
  static readonly TemplatesNonCancelledTemplatesTemplateNumberGetPath = '/templates/non-cancelled-templates/{templateNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesNonCancelledTemplatesTemplateNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesNonCancelledTemplatesTemplateNumberGet$Plain$Response(params: {
    templateNumber: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesNonCancelledTemplatesTemplateNumberGetPath, 'get');
    if (params) {
      rb.path('templateNumber', params.templateNumber, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesNonCancelledTemplatesTemplateNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesNonCancelledTemplatesTemplateNumberGet$Plain(params: {
    templateNumber: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {

    return this.templatesNonCancelledTemplatesTemplateNumberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>) => r.body as Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesNonCancelledTemplatesTemplateNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesNonCancelledTemplatesTemplateNumberGet$Json$Response(params: {
    templateNumber: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesNonCancelledTemplatesTemplateNumberGetPath, 'get');
    if (params) {
      rb.path('templateNumber', params.templateNumber, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesNonCancelledTemplatesTemplateNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesNonCancelledTemplatesTemplateNumberGet$Json(params: {
    templateNumber: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
  }): Observable<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {

    return this.templatesNonCancelledTemplatesTemplateNumberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>) => r.body as Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>)
    );
  }

  /**
   * Path part for operation templatesCreateTemplatePost
   */
  static readonly TemplatesCreateTemplatePostPath = '/templates/create-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesCreateTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesCreateTemplatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesCreateTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesCreateTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesCreateTemplatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesCreateTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesCreateTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesCreateTemplatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesCreateTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesCreateTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesCreateTemplatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CreateTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesCreateTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * Path part for operation templatesSaveActivityTemplatePost
   */
  static readonly TemplatesSaveActivityTemplatePostPath = '/templates/save-activity-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveActivityTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveActivityTemplatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveActivityTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesSaveActivityTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesSaveActivityTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveActivityTemplatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveActivityTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesSaveActivityTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveActivityTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveActivityTemplatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveActivityTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesSaveActivityTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesSaveActivityTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveActivityTemplatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveActivityTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesSaveActivityTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * Path part for operation templatesSaveModuleTemplatePost
   */
  static readonly TemplatesSaveModuleTemplatePostPath = '/templates/save-module-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveModuleTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveModuleTemplatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveModuleTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesSaveModuleTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesSaveModuleTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveModuleTemplatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveModuleTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesSaveModuleTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveModuleTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveModuleTemplatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveModuleTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesSaveModuleTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesSaveModuleTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveModuleTemplatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveModuleTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesSaveModuleTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * Path part for operation templatesUpdateModuleTemplatePost
   */
  static readonly TemplatesUpdateModuleTemplatePostPath = '/templates/update-module-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateModuleTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateModuleTemplatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateModuleTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesUpdateModuleTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateModuleTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateModuleTemplatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateModuleTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesUpdateModuleTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateModuleTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateModuleTemplatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateModuleTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesUpdateModuleTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateModuleTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateModuleTemplatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateModuleTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesUpdateModuleTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * Path part for operation templatesSaveFormTemplatePost
   */
  static readonly TemplatesSaveFormTemplatePostPath = '/templates/save-form-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveFormTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveFormTemplatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveFormTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesSaveFormTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesSaveFormTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveFormTemplatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveFormTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesSaveFormTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveFormTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveFormTemplatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveFormTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesSaveFormTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesSaveFormTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveFormTemplatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveFormTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesSaveFormTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * Path part for operation templatesUpdateFormTemplatePost
   */
  static readonly TemplatesUpdateFormTemplatePostPath = '/templates/update-form-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateFormTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateFormTemplatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateFormTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesUpdateFormTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateFormTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateFormTemplatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateFormTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesUpdateFormTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateFormTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateFormTemplatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateFormTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesUpdateFormTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateFormTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateFormTemplatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateFormTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesUpdateFormTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * Path part for operation templatesSaveTableTemplatePost
   */
  static readonly TemplatesSaveTableTemplatePostPath = '/templates/save-table-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveTableTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveTableTemplatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveTableTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesSaveTableTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesSaveTableTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveTableTemplatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveTableTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesSaveTableTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveTableTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveTableTemplatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveTableTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesSaveTableTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesSaveTableTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveTableTemplatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveTableTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesSaveTableTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * Path part for operation templatesUpdateTableTemplatePost
   */
  static readonly TemplatesUpdateTableTemplatePostPath = '/templates/update-table-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateTableTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTableTemplatePost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTableTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesUpdateTableTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateTableTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTableTemplatePost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTableTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesUpdateTableTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateTableTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTableTemplatePost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTableTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesUpdateTableTemplatePostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateTableTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTableTemplatePost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTableTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesUpdateTableTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * Path part for operation templatesUpdateTemplatePost
   */
  static readonly TemplatesUpdateTemplatePostPath = '/templates/update-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTemplatePost$Plain$Response(params?: {
    id?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTemplateSummaryCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesUpdateTemplatePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTemplatePost$Plain(params?: {
    id?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTemplateSummaryCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesUpdateTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTemplatePost$Json$Response(params?: {
    id?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTemplateSummaryCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesUpdateTemplatePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTemplatePost$Json(params?: {
    id?: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTemplateSummaryCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesUpdateTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * Path part for operation templatesIdActivityMinorVersionUpdatePost
   */
  static readonly TemplatesIdActivityMinorVersionUpdatePostPath = '/templates/{id}/activity/minor-version-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdActivityMinorVersionUpdatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdActivityMinorVersionUpdatePost$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateActivityTemplateCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdActivityMinorVersionUpdatePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdActivityMinorVersionUpdatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdActivityMinorVersionUpdatePost$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateActivityTemplateCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdActivityMinorVersionUpdatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdActivityMinorVersionUpdatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdActivityMinorVersionUpdatePost$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateActivityTemplateCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdActivityMinorVersionUpdatePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdActivityMinorVersionUpdatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdActivityMinorVersionUpdatePost$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateActivityTemplateCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdActivityMinorVersionUpdatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * Path part for operation templatesIdMakeAvailablePost
   */
  static readonly TemplatesIdMakeAvailablePostPath = '/templates/{id}/make-available';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdMakeAvailablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdMakeAvailablePost$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: MakeTemplateAvailableCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdMakeAvailablePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdMakeAvailablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdMakeAvailablePost$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: MakeTemplateAvailableCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdMakeAvailablePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdMakeAvailablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdMakeAvailablePost$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: MakeTemplateAvailableCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdMakeAvailablePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdMakeAvailablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdMakeAvailablePost$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: MakeTemplateAvailableCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdMakeAvailablePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * Path part for operation templatesIdPublishMajorVersionPost
   */
  static readonly TemplatesIdPublishMajorVersionPostPath = '/templates/{id}/publish-major-version';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdPublishMajorVersionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdPublishMajorVersionPost$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: PublishMajorVersionTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdPublishMajorVersionPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdPublishMajorVersionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdPublishMajorVersionPost$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: PublishMajorVersionTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesIdPublishMajorVersionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdPublishMajorVersionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdPublishMajorVersionPost$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: PublishMajorVersionTemplateCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdPublishMajorVersionPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdPublishMajorVersionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdPublishMajorVersionPost$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: PublishMajorVersionTemplateCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesIdPublishMajorVersionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * Path part for operation templatesIdSaveAsCopyPost
   */
  static readonly TemplatesIdSaveAsCopyPostPath = '/templates/{id}/save-as-copy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdSaveAsCopyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsCopyPost$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveAsCopyCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdSaveAsCopyPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdSaveAsCopyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsCopyPost$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveAsCopyCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesIdSaveAsCopyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdSaveAsCopyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsCopyPost$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveAsCopyCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdSaveAsCopyPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdSaveAsCopyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsCopyPost$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveAsCopyCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesIdSaveAsCopyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * Path part for operation templatesIdSaveAsNewDraftMinorPost
   */
  static readonly TemplatesIdSaveAsNewDraftMinorPostPath = '/templates/{id}/save-as-new-draft-minor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdSaveAsNewDraftMinorPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsNewDraftMinorPost$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveAsNewDraftCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdSaveAsNewDraftMinorPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdSaveAsNewDraftMinorPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsNewDraftMinorPost$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveAsNewDraftCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesIdSaveAsNewDraftMinorPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdSaveAsNewDraftMinorPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsNewDraftMinorPost$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveAsNewDraftCommand
  }): Observable<StrictHttpResponse<TemplateAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdSaveAsNewDraftMinorPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateAddResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdSaveAsNewDraftMinorPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsNewDraftMinorPost$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: SaveAsNewDraftCommand
  }): Observable<TemplateAddResponse> {

    return this.templatesIdSaveAsNewDraftMinorPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>) => r.body as TemplateAddResponse)
    );
  }

  /**
   * Path part for operation templatesIdCancelTemplatePost
   */
  static readonly TemplatesIdCancelTemplatePostPath = '/templates/{id}/cancel-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdCancelTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdCancelTemplatePost$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CancelTemplateCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdCancelTemplatePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdCancelTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdCancelTemplatePost$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CancelTemplateCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdCancelTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdCancelTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdCancelTemplatePost$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CancelTemplateCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdCancelTemplatePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdCancelTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdCancelTemplatePost$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: CancelTemplateCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdCancelTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * Path part for operation templatesIdRestoreToDraftPost
   */
  static readonly TemplatesIdRestoreToDraftPostPath = '/templates/{id}/restore-to-draft';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdRestoreToDraftPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRestoreToDraftPost$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreTemplateToDraftCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdRestoreToDraftPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdRestoreToDraftPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRestoreToDraftPost$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreTemplateToDraftCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdRestoreToDraftPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdRestoreToDraftPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRestoreToDraftPost$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreTemplateToDraftCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdRestoreToDraftPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdRestoreToDraftPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRestoreToDraftPost$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RestoreTemplateToDraftCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdRestoreToDraftPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * Path part for operation templatesIdRetireTemplatePost
   */
  static readonly TemplatesIdRetireTemplatePostPath = '/templates/{id}/retire-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdRetireTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRetireTemplatePost$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RetireTemplateCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdRetireTemplatePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdRetireTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRetireTemplatePost$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RetireTemplateCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdRetireTemplatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdRetireTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRetireTemplatePost$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RetireTemplateCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdRetireTemplatePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdRetireTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRetireTemplatePost$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: RetireTemplateCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdRetireTemplatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * Path part for operation templatesIdRulesPut
   */
  static readonly TemplatesIdRulesPutPath = '/templates/{id}/rules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdRulesPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRulesPut$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTemplateRulesCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdRulesPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdRulesPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRulesPut$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTemplateRulesCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdRulesPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdRulesPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRulesPut$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTemplateRulesCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdRulesPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdRulesPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRulesPut$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTemplateRulesCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdRulesPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * Path part for operation templatesIdIsCorePut
   */
  static readonly TemplatesIdIsCorePutPath = '/templates/{id}/is-core';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdIsCorePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdIsCorePut$Plain$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTemplateIsCoreCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdIsCorePutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdIsCorePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdIsCorePut$Plain(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTemplateIsCoreCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdIsCorePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdIsCorePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdIsCorePut$Json$Response(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTemplateIsCoreCommand
  }): Observable<StrictHttpResponse<NoContentNotification>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesService.TemplatesIdIsCorePutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoContentNotification>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `templatesIdIsCorePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdIsCorePut$Json(params: {
    id: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: UpdateTemplateIsCoreCommand
  }): Observable<NoContentNotification> {

    return this.templatesIdIsCorePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NoContentNotification>) => r.body as NoContentNotification)
    );
  }

}
