import { Injectable } from '@angular/core';
import { NA } from 'bpt-ui-library/shared';
import { cloneDeep, mapValues } from 'lodash-es';
import { ActivityInputItem } from 'model/activity-input-item';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import {
  ActivityInputType, ActivityLabItemsNode, AddExperimentScannedItemsCommand, ExperimentPreparation, LabItemsInstrument,
  NodeType, ValueState
} from '../api/models';
import { ActivityInputItemState } from '../experiment/barcode-scanner/activity-input-item-state';
import { ActivityInputSamples } from '../experiment/model/activity-input-samples';
import { ActivityInputStudyActivity } from '../experiment/model/activity-input-study-activity';
import { ActivityInputInstrumentEvent } from '../experiment/model/activity-input-instrument-event';
import { MaterialAliquot as LabItemsMaterial } from '../api/models/LabItemsELN/Bookshelf/Api/LabItems/material-aliquot';
import { ExperimentService } from '../experiment/services/experiment.service';
import { MaterialAliquot, ModifiableDataValue } from '../api/data-entry/models';
import { UserService } from './user.service';
import { SampleTests } from '../experiment/model/sample-tests';
import { InstrumentColumn } from '../api/models/LabItemsELN/Bookshelf/Api/LabItems/instrument-column';
import { StudyActivities } from '../model/study-activities';
import { InstrumentType } from '../experiment/instrument-connection/shared/instrument-type';

export type SampleAddedNotification = { activityId: string,  activityInputDto : any };
export type ScannedSampleAddedNotification = { notificationError: string, addExperimentScannedItemsCommand: AddExperimentScannedItemsCommand, scannedItemNotification: SampleAddedNotification[] };

export type LabItemsPreparationAddedNotification = { activityId: string,  materialAliquotNotificationDetails : ExperimentPreparation };
export type LabItemsMaterialAddedNotification = { activityId: string,  materialAliquotNotificationDetails : LabItemsMaterial };
export type LabItemsColumnAddedNotification = { activityId: string,  instrumentColumnAddedNotificationDetails : InstrumentColumn };
export type LabItemsInstrumentAddedNotification = { activityId: string, instrumentAddedNotificationDetails: LabItemsInstrument & {
  cellData: Array<{ [key: string]: ModifiableDataValue }> }
};

export type ScannedLabItemsPreparationAddedNotification = { notificationError: string, addExperimentScannedItemsCommand: AddExperimentScannedItemsCommand, scannedItemNotification: LabItemsPreparationAddedNotification[] };
export type ScannedLabItemsMaterialAddedNotification = { notificationError: string, addExperimentScannedItemsCommand: AddExperimentScannedItemsCommand, scannedItemNotification: LabItemsMaterialAddedNotification[] };
export type ScannedLabItemsColumnAddedNotification = { notificationError: string, addExperimentScannedItemsCommand: AddExperimentScannedItemsCommand, scannedItemNotification: LabItemsColumnAddedNotification[] };
export type ScannedLabItemsInstrumentAddedNotification = {
  notificationError: string, addExperimentScannedItemsCommand: AddExperimentScannedItemsCommand, scannedItemNotification: LabItemsInstrumentAddedNotification[] };  

@Injectable({
  providedIn: 'root'
})
export class BarcodeScannerHelper {
  private readonly labItemMaterialAliquotAddedPublisher: Subject<LabItemsMaterialAddedNotification> =
    new Subject<LabItemsMaterialAddedNotification>();
  public readonly labItemMaterialAliquotAddedNotification: Observable<LabItemsMaterialAddedNotification> =
    this.labItemMaterialAliquotAddedPublisher.asObservable();
  private readonly labItemColumnAddedPublisher: Subject<LabItemsColumnAddedNotification> =
    new Subject<LabItemsColumnAddedNotification>();
  public readonly labItemColumnAddedNotification: Observable<LabItemsColumnAddedNotification> =
    this.labItemColumnAddedPublisher.asObservable();
  private readonly labItemInstrumentAddedPublisher: Subject<LabItemsInstrumentAddedNotification> =
    new Subject<LabItemsInstrumentAddedNotification>();
  public readonly labItemInstrumentAddedNotification: Observable<LabItemsInstrumentAddedNotification> =
    this.labItemInstrumentAddedPublisher.asObservable();
  public readonly activityScannedNotification: Subject<boolean> = new Subject<boolean>();
  public readonly barcodeScanSuccess: Subject<boolean> = new Subject<boolean>();
  public readonly barcodeScanFailed: Subject<{activityType : ActivityInputType, instrumentType: InstrumentType}> = new Subject<{activityType : ActivityInputType, instrumentType: InstrumentType}>();
  public readonly labItemPreparationNotification: Subject<string> = new Subject<string>();
  public readonly labItemPreparationAddedPublisher: Observable<string> =
  this.labItemPreparationNotification.asObservable();

  constructor(private readonly messageService: MessageService,
    experimentService: ExperimentService,
    private readonly userService: UserService) {
    this.experimentService = experimentService;
  }
  experimentService: ExperimentService;
  allScannedItems: ActivityInputItem[] = [];
  pendingScanListItems: ActivityInputItem[] = [];
  itemToAddOrUpdate: any;
  refreshGrid = true;
  currentActivityId: any;
  currentActivityInputId?: string;
  labItemsMaterialCollection: Array<LabItemsMaterial> = [];
  labItemsInstrumentCollection: Array<LabItemsInstrument> = [];
  labItemsColumnCollection: Array<InstrumentColumn> = [];
  instrumentType?: InstrumentType;
  refreshStudyActivityGrid = true;
  additionalInformation = 'AdditionalInformation';
  rowIndex = 'rowIndex';
  emptySpace = ' ';
  aliquotAdditionalInformation = 'AliquotAdditionalInformation';
  scannedMaterialAliquot!: MaterialAliquot;
  readonly excludedMaterialStatus = 'On Order';
  activityInputDataSourcePrepared = new Subject<any>();
  sampleTableDataSourcePrepared = new Subject<ActivityInputSamples>();
  public aliquotUpdateEvent = new Subject<string>();
  public studyActivityUpdateEvent = new Subject<string>();
  studyActivityTableDataSourcePrepared = new Subject<ActivityInputStudyActivity>();
  instrumentEventDataSourcePrepared = new Subject<ActivityInputInstrumentEvent>();
  activityInputSelected = new Subject<any>();
  cancelTestSelection = new Subject<any>();
  readonly PreparationNotFund = "Preparation does not exist or could not be found.";
  readonly PreparationRemoved = "preparation is removed.";
  readonly PreparationInvalid = "Preparation is in invalid status.";
  readonly PreparationNotFoundConst="Preparation does not exist or found."
  public static readonly ServerSideErrorMessages: {
    [key: string]: (params: any) => { nodeType: NodeType; message: string };
  } = {
      MaterialInUseValidationMessageCode: function (_params) {
        return {
          nodeType: NodeType.LabItems,
          message: $localize`:@@MaterialInUseValidationMessageCode:The material aliquot trying to add is in use status.`
        };
      },
      MaterialUnAvailableValidationMessageCode: function (_params) {
        return {
          nodeType: NodeType.LabItems,
          message: $localize`:@@MaterialUnAvailableValidationMessage:The material aliquot trying to add is Unavailable.`
        };
      },
      InstrumentInActiveValidationMessageCode: function (_params) {
        return {
          nodeType: NodeType.LabItems,
          message: $localize`:@@InstrumentInActiveValidationMessageCode:The instrument trying to add is inactive.`
        };
      },
      ColumnInActiveValidationMessageCode: function (_params) {
        return {
          nodeType: NodeType.LabItems,
          message: $localize`:@@ColumnInActiveValidationMessageCode:The column trying to add is inactive.`
        };
      }
    };

  public updateActivityInputsScanStatus(notifications: any): void {
    const activityInputType =
      Object.values(ActivityInputType)[
      notifications.addExperimentScannedItemsCommand.activityInputType
      ];
    notifications.addExperimentScannedItemsCommand.activityIds.forEach((activityId: string) => {
      if (notifications.notificationError) {
        this.updatePendingScannedActivityInput(notifications, activityId);
        this.activityScannedNotification.next(true);
        return;
      }
      const item = notifications.scannedItemNotification.find((k: any) => k.activityId === activityId);
      switch (activityInputType) {
        case ActivityInputType.Aliquot:
          this.handleSampleAliquot(item, notifications);
          break;
        case ActivityInputType.Material:
          this.handleMaterialAliquot(item, notifications);
          break;
        case ActivityInputType.Instrument:
          this.handleInstrumentEvent(item, notifications);
          break;
        case ActivityInputType.InstrumentDetails:
           this.handleLabitemsInstrumentAddedNotification(item, notifications);
           break;
        case ActivityInputType.InstrumentColumn:
          this.handleLabitemsColumnAddedNotification(item, notifications);
          break;
          case ActivityInputType.Preparation:
          this.handleLabItemPreparation(item, notifications);
          break;
        default:
          this.updatePendingScannedActivityInput(notifications, item.activityId);
      }
      this.activityScannedNotification.next(true);
    })
  }

  private createLabItemNodeNodeBy(
    notifications: any,
    scannedItemResults : any
  ): ActivityLabItemsNode {
    const labItemNode = {
      nodeId: scannedItemResults.activityId,
        activityLabItemNodeId: scannedItemResults.activityId,
        consumables: [],
        experimentId: notifications.addExperimentScannedItemsCommand.experimentId,
        id: scannedItemResults.activityId,
        instrumentColumns: [],
        instruments: [],
        materials:[],
        preparations: [],
        itemType: NodeType.LabItems,
        _ts: 0
    };
    this.experimentService.currentExperiment?.activityLabItems?.push(labItemNode);
    return labItemNode;
  }

  public handleLabItemPreparation(scannedItemResults: any, notifications: any): void {
    this.updatePendingScannedActivityInput(notifications, scannedItemResults.activityId);
    if (scannedItemResults.preparationAddedNotificationDetails === null) return;
    const LabItemsNode = this.prepareLabitemsNode(scannedItemResults.preparationAddedNotificationDetails);

    if(this.experimentService.currentExperiment?.activityLabItems === undefined 
      || this.experimentService.currentExperiment?.activityLabItems.length === 0
      ||this.experimentService.currentExperiment?.activityLabItems?.
      find(exp => exp.nodeId === scannedItemResults.activityId) === undefined)
      {
        this.createLabItemNodeNodeBy(notifications, scannedItemResults);
      }
    const activityLabItems = this.experimentService.currentExperiment?.activityLabItems?.
      find(x => x.nodeId === scannedItemResults.activityId);

    if (activityLabItems) {
      activityLabItems.preparations.push(LabItemsNode)
        this.labItemPreparationNotification.next("");
    }
  }
  
  private handleSampleAliquot(scannedItemResults: any, notifications: any): void {
    this.updatePendingScannedActivityInput(notifications, scannedItemResults.activityId);
    if (scannedItemResults.activityInputDto === null) return;
    const scannedAliquotSample = this.prepareSampleDataSource(scannedItemResults);
    if (scannedItemResults.activityId !== this.currentActivityId) 
    {
      const activityInputNode = this.experimentService.currentExperiment?.activityInputs?.find(
        (a: any) => a.activityId === scannedItemResults.activityId
      );
      if (activityInputNode) {
       activityInputNode.aliquots.push(scannedAliquotSample)
      }
      else {
        this.syncActivityInputsInExperiment(scannedItemResults, notifications);
     }
     return;
    }  
    const currentActivityId = this.experimentService.currentActivity?.activityId;
    const activityInputNode = this.experimentService.currentExperiment?.activityInputs?.
      find(i => i.activityId === currentActivityId);
    if (activityInputNode) {
      activityInputNode.aliquots.push(scannedAliquotSample)
    }
    else {
      this.syncActivityInputsInExperiment(scannedItemResults, notifications);
    }
    this.sampleTableDataSourcePrepared.next(scannedAliquotSample);
    this.aliquotUpdateEvent.next(scannedItemResults.activityId);
    this.activityScannedNotification.next(true);
  }

  private syncActivityInputsInExperiment(scannedItemResults: SampleAddedNotification, notifications: ScannedSampleAddedNotification)
  {
    this.experimentService.currentExperiment?.activityInputs?.push({
      activityId: scannedItemResults.activityId,
      activityInputId: scannedItemResults.activityId,
      aliquots: [scannedItemResults.activityInputDto],
      experimentId: notifications.addExperimentScannedItemsCommand.experimentId,
      id: scannedItemResults.activityId,
      materials: [],
      itemType: NodeType.ActivityInput,
      _ts: 0,
    })
  }

  private prepareSampleDataSource(scannedItemResults: any): ActivityInputSamples {
    const modifiableFields = JSON.parse(scannedItemResults.activityInputDto.modifiableFields);
    const sampleDataForScannedAliquot: ActivityInputSamples = scannedItemResults.activityInputDto;
    sampleDataForScannedAliquot.activityInputReference = sampleDataForScannedAliquot.aliquotNumber;
    sampleDataForScannedAliquot.activityInputType = ActivityInputType.Aliquot;
    sampleDataForScannedAliquot.modifiableFields = [];
    sampleDataForScannedAliquot.id = sampleDataForScannedAliquot.aliquotNumber;
    sampleDataForScannedAliquot.aliquotTests = [];
    sampleDataForScannedAliquot.createdBy = { value: this.userService.currentUser.puid };
    this.populateAdditionalFieldsActivityInput(sampleDataForScannedAliquot, modifiableFields);
    return sampleDataForScannedAliquot;
  }

  private populateAdditionalFieldsActivityInput(
    activityInput: ActivityInputSamples | ActivityInputStudyActivity,
    modifiableFields: Array<{ [key: string]: any }>
  ) {
    mapValues(modifiableFields, (modifiableField: any) => {
      mapValues(modifiableField, (dataValue: any, propertyName: string) => {
        if (propertyName === this.additionalInformation)
          activityInput.AdditionalInformation =
            dataValue.Value.State === ValueState.NotApplicable ? NA : dataValue.Value.Value;
        if (propertyName === this.rowIndex)
          (activityInput as ActivityInputSamples).rowIndex = dataValue.Value.Value;
        activityInput.modifiableFields.push({
          [propertyName]: {
            isModified: dataValue.IsModified,
            value: {
              state: dataValue.Value.State,
              type: dataValue.Value.Type,
              value: dataValue.Value.State === ValueState.NotApplicable ? NA : dataValue.Value.Value
            }
          }
        });
      });
    });
  }

  private handleMaterialAliquot(scannedItemResults: any, notifications: any): void {    
    this.updatePendingScannedActivityInput(notifications, scannedItemResults.activityId);
    if (scannedItemResults.materialAliquotNotificationDetails && scannedItemResults.materialAliquotNotificationDetails !== null) {
      this.labItemMaterialAliquotAddedPublisher.next(scannedItemResults);
      this.activityScannedNotification.next(true);
    }
    else {
      if (scannedItemResults.activityInputDto === null) return;
      const scannedMaterial = this.prepareStudyActivityDataSource(scannedItemResults);
      this.updatePendingScannedActivityInput(notifications, scannedItemResults.activityId);
      if (scannedItemResults.activityId !== this.currentActivityId) 
      {
        const activityInputNode = this.experimentService.currentExperiment?.activityInputs?.find(
          (a: any) => a.activityId === scannedItemResults.activityId
        );
        if (activityInputNode) {
          activityInputNode.materials.push(scannedMaterial);  
        }
        else {
          const activityInputNode = this.createAndGetMaterialNode(scannedItemResults, notifications);
          this.experimentService.currentExperiment?.activityInputs?.push(activityInputNode);
        }
        return;
      }
      const activityInputNode = this.experimentService.currentExperiment?.activityInputs?.find(
        (a: any) => a.activityId === this.experimentService.currentActivityId
      );
      if (activityInputNode) {
        activityInputNode.materials.push(scannedMaterial);

      }
      else {
        const activityInputNode = this.createAndGetMaterialNode(scannedItemResults, notifications);
        this.experimentService.currentExperiment?.activityInputs?.push(activityInputNode);
      }
      this.studyActivityTableDataSourcePrepared.next(scannedMaterial);
    }
    this.studyActivityUpdateEvent.next(scannedItemResults.activityId)
    this.activityScannedNotification.next(true);
  }

  private createAndGetMaterialNode(scannedItemResults: SampleAddedNotification, notifications: ScannedSampleAddedNotification)
  {
    return {
      activityId: scannedItemResults.activityId,
      activityInputId: scannedItemResults.activityId,
      experimentId: notifications.addExperimentScannedItemsCommand.experimentId,
      itemType: NodeType.ActivityInput,
      aliquots: [],
      materials: [scannedItemResults.activityInputDto],
      id: scannedItemResults.activityId,
      _ts: 0
    };
  }

 public prepareLabitemsNode(preparation: any):ExperimentPreparation{
    const preparationLabitemsNode:ExperimentPreparation={
      additionalInformation:  preparation.additionalInformation,
      internalInformation: preparation.internalInformation,
      summary: preparation.summary,
      name: {
        value: preparation.name,
        isModified: false
      }  ,
      preparationNumber: preparation.preparationNumber,
      status: preparation.status,
      preparationId: preparation.preparationId,
      expirationValue: preparation.expiration,
      description: {
        value: preparation.description,
        isModified: false
      },
      isRemoved: preparation.isRemoved,
    };
   return preparationLabitemsNode;
 }

  private prepareStudyActivityDataSource(scannedItemResults: any): ActivityInputStudyActivity {
    const modifiableFields = JSON.parse(scannedItemResults.activityInputDto.modifiableFields);
    const scannedMaterialAliquot: ActivityInputStudyActivity = scannedItemResults.activityInputDto;
    scannedMaterialAliquot.activityInputReference = scannedMaterialAliquot.code;
    scannedMaterialAliquot.createdBy = { value: this.userService.currentUser.puid };
    scannedMaterialAliquot.modifiableFields = [];
    scannedMaterialAliquot.id = scannedMaterialAliquot.code;
    scannedMaterialAliquot.activityInputType = ActivityInputType.Material;
    this.populateAdditionalFieldsActivityInput(scannedMaterialAliquot, modifiableFields);
    return scannedMaterialAliquot;
  }

  private isScannedStateSuccessForLabItemsMaterial(scannedItemResults: any, pendingItemToUpdate: any): boolean {
    return (scannedItemResults.materialAliquotNotificationDetails !== null &&
      scannedItemResults.materialAliquotNotificationDetails &&
      scannedItemResults.materialAliquotNotificationDetails.code.toLowerCase() === pendingItemToUpdate.activityInputReference.toLowerCase());
  }

  private isScannedStateSuccessForLabItemsColumn(scannedItemResults: any, pendingItemToUpdate: any): boolean {
    return (scannedItemResults.instrumentColumnAddedNotificationDetails !== null &&
      scannedItemResults.instrumentColumnAddedNotificationDetails &&
      scannedItemResults.instrumentColumnAddedNotificationDetails.code.toLowerCase() === pendingItemToUpdate.activityInputReference.toLowerCase());
  }

  private isScannedStateSuccessForLabItemsInstrument(scannedItemResults: any, pendingItemToUpdate: any): boolean {
    return (scannedItemResults.instrumentAddedNotificationDetails !== null &&
      scannedItemResults.instrumentAddedNotificationDetails &&
      scannedItemResults.instrumentAddedNotificationDetails.code.toLowerCase() === pendingItemToUpdate.activityInputReference.toLowerCase());
  }

  private updatePendingItemStatus(scannedItemResults: any, notifications: any, activityId: string): void {
    const pendingItemsToUpdate = this.pendingScanListItems.filter((s: any) => s.activityId === activityId && s.state === ActivityInputItemState.Pending);
    pendingItemsToUpdate.forEach(pendingItemToUpdate => {
        if (pendingItemToUpdate !== null && pendingItemToUpdate !== undefined) {
          if (this.isScannedStateSuccessForLabItemsMaterial(scannedItemResults, pendingItemToUpdate) || this.isScannedStateSuccessForLabItemsInstrument(scannedItemResults, pendingItemToUpdate) ||
            this.isScannedStateSuccessForLabItemsColumn(scannedItemResults, pendingItemToUpdate)) {
            if (notifications.scannedItemNotification !== null) {
              pendingItemToUpdate.state = ActivityInputItemState.Success;
              if(notifications.addExperimentScannedItemsCommand.activityInputType === 5)
              this.barcodeScanSuccess.next(true);
            }
          } 
        }
        this.activityScannedNotification.next(true);
    });
  }

  private updatePendingScannedActivityInput(notifications: any, activityId: string): void {
    const pendingItemsToUpdate = this.pendingScanListItems.filter((s: any) => s.activityId === activityId && s.state === ActivityInputItemState.Pending);
    const pendingItemToUpdate = pendingItemsToUpdate.find(item => item.activityInputReference === notifications.addExperimentScannedItemsCommand?.activityInputReference)
    const activityInputType =
      Object.values(ActivityInputType)[
      notifications.addExperimentScannedItemsCommand.activityInputType
      ];
    if (pendingItemToUpdate !== null && pendingItemToUpdate !== undefined) {
      if( activityInputType === ActivityInputType.Preparation && notifications.scannedItemNotification.length === 0){
        pendingItemToUpdate.state = ActivityInputItemState.Warning;
        pendingItemToUpdate.tooltipText = this.getTranslationMessage(notifications.notificationError);
        this.messageService.add({
          key: 'notification',
          severity: 'error',
          summary:  $localize`:@@preparationCannotBeScanned:Couldn't scan preparation ${notifications.addExperimentScannedItemsCommand.activityInputReference}`,
          detail: this.getTranslationMessage(notifications.notificationError),
        });
      }
      else{
      if (notifications.scannedItemNotification !== null) {
        pendingItemToUpdate.state = ActivityInputItemState.Success;
      } else {
        this.processFailureStateOfScannedInput(pendingItemToUpdate, notifications);
      }
    }
  }
    this.activityScannedNotification.next(true);
  }

  public processFailureStateOfScannedInput(pendingItemToUpdate: ActivityInputItem, notifications: any): void {  
    var summary = (pendingItemToUpdate.activityInputType === ActivityInputType.InstrumentColumn ||
      pendingItemToUpdate.activityInputType === ActivityInputType.InstrumentDetails) ? $localize`:@@failedScanItemErrorLabItems:Could not scan lab item successfully` + this.emptySpace +
    notifications.addExperimentScannedItemsCommand.activityInputReference : $localize`:@@failedScanItemError:Could not scan activity input successfully` + this.emptySpace +
    notifications.addExperimentScannedItemsCommand.activityInputReference 
    this.barcodeScanFailed.next({
      activityType: pendingItemToUpdate.activityInputType,
      instrumentType: this.instrumentType as InstrumentType
    });
    pendingItemToUpdate.state = ActivityInputItemState.Warning;
    pendingItemToUpdate.tooltipText = notifications.notificationError;
    this.messageService.add({
      key: 'notification',
      severity: 'error',
      summary: summary,
      detail: notifications.notificationError,
    });
  }

  handleInstrumentEvent(scannedItemResults: any, notifications: any) {
    this.updatePendingScannedActivityInput(notifications, scannedItemResults.activityId);
    if (scannedItemResults.activityInputDto === null) return;
    if (scannedItemResults.activityId !== this.currentActivityId) {
      const activityInputNode = this.experimentService.currentExperiment?.activityInputs?.
        find(x => x.activityId === scannedItemResults.activityId);
      const instrumentEventsDataSource = scannedItemResults.activityInputDto as ActivityInputInstrumentEvent;
      instrumentEventsDataSource.activityInputReference = scannedItemResults.activityInputDto.instrumentNumber;
      instrumentEventsDataSource.activityInputType = ActivityInputType.Instrument;
      instrumentEventsDataSource.activityId = scannedItemResults.activityId;

      if (activityInputNode) {
        activityInputNode.instruments = cloneDeep(instrumentEventsDataSource);
      }
      else {
        this.experimentService.currentExperiment?.activityInputs?.push(this.getAndCreateInstrumentNode(instrumentEventsDataSource, scannedItemResults, notifications));
      }
      this.updateInstrumentEventStatus(notifications);
      return;
    }
    const currentActivityId = this.experimentService.currentActivity?.activityId;
    const activityInputNode = this.experimentService.currentExperiment?.activityInputs?.
      find(i => i.activityId === currentActivityId);
    const instrumentEventsDataSource = scannedItemResults.activityInputDto as ActivityInputInstrumentEvent;
    instrumentEventsDataSource.activityInputReference = scannedItemResults.activityInputDto.instrumentNumber;
    instrumentEventsDataSource.activityInputType = ActivityInputType.Instrument;
    instrumentEventsDataSource.activityId = scannedItemResults.activityId;
    this.updateInstrumentEventStatus(notifications);
    if (activityInputNode) {
      activityInputNode.instruments = cloneDeep(instrumentEventsDataSource);
    }
    else {
      this.experimentService.currentExperiment?.activityInputs?.push(this.getAndCreateInstrumentNode(instrumentEventsDataSource, scannedItemResults, notifications));
      this.syncActivityInputsInExperiment(scannedItemResults, notifications);
    }

    this.instrumentEventDataSourcePrepared.next(instrumentEventsDataSource);
    this.activityScannedNotification.next(true);
  }

  private getAndCreateInstrumentNode(instrumentEventsDataSource: ActivityInputInstrumentEvent, 
    scannedItemResults: SampleAddedNotification, notifications: ScannedSampleAddedNotification) {
    return {
      activityId: scannedItemResults.activityId,
      activityInputId: scannedItemResults.activityId,
      aliquots: [],
      experimentId: notifications.addExperimentScannedItemsCommand.experimentId,
      id: scannedItemResults.activityId,
      materials: [],
      instruments: cloneDeep(instrumentEventsDataSource),
      itemType: NodeType.ActivityInput,
      _ts: 0,
    }
  }

  private updateInstrumentEventStatus(_notifications: any): void {
    const pendingItemToUpdate = this.pendingScanListItems[0];
    if (pendingItemToUpdate !== null && pendingItemToUpdate !== undefined) {
      pendingItemToUpdate.state = ActivityInputItemState.Success;
    }
    this.activityScannedNotification.next(true);
  }

  private handleLabitemsInstrumentAddedNotification(scannedItemResults: any, notifications: any): void {
    if (scannedItemResults.activityId !== this.currentActivityId) 
    {
    if (scannedItemResults.instrumentAddedNotificationDetails && scannedItemResults.instrumentAddedNotificationDetails !== null) {
      this.labItemInstrumentAddedPublisher.next(scannedItemResults);
      this.updatePendingItemStatus(scannedItemResults,notifications, scannedItemResults.activityId);
    }
    else {
      this.createLabItemNodeNodeBy(notifications, scannedItemResults);
     }
     return;
    }    
    if (scannedItemResults.instrumentAddedNotificationDetails && scannedItemResults.instrumentAddedNotificationDetails !== null) {
      this.labItemInstrumentAddedPublisher.next(scannedItemResults);
      this.updatePendingItemStatus(scannedItemResults,notifications, scannedItemResults.activityId);
      }
    else {
      this.createLabItemNodeNodeBy(notifications, scannedItemResults);
      this.labItemInstrumentAddedPublisher.next(scannedItemResults);
      this.updatePendingItemStatus(scannedItemResults, notifications, scannedItemResults.activityId);
    }
}

  private handleLabitemsColumnAddedNotification(scannedItemResults: any, notifications: any): void {
    if (scannedItemResults.activityId !== this.currentActivityId) 
    {
    if (scannedItemResults.instrumentColumnAddedNotificationDetails && scannedItemResults.instrumentColumnAddedNotificationDetails !== null) {
      this.labItemColumnAddedPublisher.next(scannedItemResults);
      this.updatePendingItemStatus(scannedItemResults,notifications, scannedItemResults.activityId);
    }
    else {
      this.createLabItemNodeNodeBy(notifications, scannedItemResults);
     }
     return;
    }    
    if (scannedItemResults.instrumentColumnAddedNotificationDetails && scannedItemResults.instrumentColumnAddedNotificationDetails !== null) {
      this.labItemColumnAddedPublisher.next(scannedItemResults);
      this.updatePendingItemStatus(scannedItemResults,notifications, scannedItemResults.activityId);
      }
    else {
      this.createLabItemNodeNodeBy(notifications, scannedItemResults);
      this.labItemColumnAddedPublisher.next(scannedItemResults);
      this.updatePendingItemStatus(scannedItemResults, notifications, scannedItemResults.activityId);
    }
  }

  public isMaterialExistsInLabItems(aliquotNumber: string): boolean {
    return this.labItemsMaterialCollection.some((material) => {
      return material.code.toLowerCase() === aliquotNumber.toLowerCase();
    });
  }

  public isInstrumentExistsInLabItems(instrumentCode: string): boolean {
    return this.labItemsInstrumentCollection.some((instrument) => {
      return instrument.code.toLowerCase() === instrumentCode.toLowerCase();
    });
  }

  public isColumnExistsInLabItems(columnCode: string): boolean {
    return this.labItemsColumnCollection.some((column) => {
      return column.code.toLowerCase() === columnCode.toLowerCase();
    });
  }

  private getTranslationMessage(notificationError: any): string {
    switch(notificationError) {
      case this.PreparationRemoved: return $localize`:@@preparationIsRemoved:Preparation is removed.`;
      case this.PreparationInvalid: return $localize`:@@preparationInvalid:Preparation is in invalid status.`;
      case this.PreparationNotFoundConst: return $localize`:@@preparationNotExist:Preparation does not exist or could not be found.`;
      default: return notificationError;
    }
  }
}


