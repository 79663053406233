<div class="grid m-auto gridAudit">
  <div *ngIf="isValid" class="col-12 m-auto audit-spinner-container">
    <bpt-progress-spinner
      [useOverlay]="false"
      [message]="message"
      class="col-12 m-auto"
      id="eln-progressSpinShow"
    ></bpt-progress-spinner>
  </div>
  <bpt-grid #auditGrid
    *ngIf="dataloaded"
    [columnDefinitions]="columnDefs"
    [allowRowAdd]="auditGridOptions.allowRowAdd"
    [includeRowNumberColumn]="auditGridOptions.includeRowNumberColumn"
    [enableExport]="auditGridOptions.enableExport"
    [dataSource]="dataSource"
    [useJsJodaTypes]="auditGridOptions.useJsJodaTypes"
    class="col-12 m-auto audit-Grid"
    [gridMode]="bptGridMode"
    id="eln-bptGridAuditHistory"
    (gridReady)="resultsGridReady()"
    (exportAllData)="exportDataToExcel()"
    [debounceVerticalScrollbar]="auditGridOptions.debounceVerticalScrollbar"
    [rowBuffer]="5000"
    ></bpt-grid>
</div>
