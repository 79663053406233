/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AggregationResult } from '../models/aggregation-result';
import { ExperimentRecordSearchResult } from '../models/experiment-record-search-result';
import { SearchCriteria } from '../models/search-criteria';

@Injectable({
  providedIn: 'root',
})
export class BookshelfService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation bookshelfSearchExperimentIndexPost
   */
  static readonly BookshelfSearchExperimentIndexPostPath = '/bookshelf/search-experiment-index';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookshelfSearchExperimentIndexPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchExperimentIndexPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<StrictHttpResponse<ExperimentRecordSearchResult>> {

    const rb = new RequestBuilder(this.rootUrl, BookshelfService.BookshelfSearchExperimentIndexPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentRecordSearchResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bookshelfSearchExperimentIndexPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchExperimentIndexPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<ExperimentRecordSearchResult> {

    return this.bookshelfSearchExperimentIndexPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentRecordSearchResult>) => r.body as ExperimentRecordSearchResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookshelfSearchExperimentIndexPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchExperimentIndexPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<StrictHttpResponse<ExperimentRecordSearchResult>> {

    const rb = new RequestBuilder(this.rootUrl, BookshelfService.BookshelfSearchExperimentIndexPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExperimentRecordSearchResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bookshelfSearchExperimentIndexPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchExperimentIndexPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<ExperimentRecordSearchResult> {

    return this.bookshelfSearchExperimentIndexPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExperimentRecordSearchResult>) => r.body as ExperimentRecordSearchResult)
    );
  }

  /**
   * Path part for operation bookshelfSearchAggregateExperimentIndexPost
   */
  static readonly BookshelfSearchAggregateExperimentIndexPostPath = '/bookshelf/search-aggregate-experiment-index';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookshelfSearchAggregateExperimentIndexPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchAggregateExperimentIndexPost$Plain$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<StrictHttpResponse<AggregationResult>> {

    const rb = new RequestBuilder(this.rootUrl, BookshelfService.BookshelfSearchAggregateExperimentIndexPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AggregationResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bookshelfSearchAggregateExperimentIndexPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchAggregateExperimentIndexPost$Plain(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<AggregationResult> {

    return this.bookshelfSearchAggregateExperimentIndexPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AggregationResult>) => r.body as AggregationResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookshelfSearchAggregateExperimentIndexPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchAggregateExperimentIndexPost$Json$Response(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<StrictHttpResponse<AggregationResult>> {

    const rb = new RequestBuilder(this.rootUrl, BookshelfService.BookshelfSearchAggregateExperimentIndexPostPath, 'post');
    if (params) {
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AggregationResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bookshelfSearchAggregateExperimentIndexPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchAggregateExperimentIndexPost$Json(params?: {
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;
    body?: SearchCriteria
  }): Observable<AggregationResult> {

    return this.bookshelfSearchAggregateExperimentIndexPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AggregationResult>) => r.body as AggregationResult)
    );
  }

}
