//{Key} should be replaced by the respective values of the environment.

import { EnvironmentVariables } from "./environment.model";

//These will be replaced during deployment by Azure DevOps.
export const environment: EnvironmentVariables = {
  production: true,
  displayWaitDialogThreshold: 500,
  supportedLanguages: ['en', 'es', 'fr', 'ja'],
  defaultLanguage: 'en',
  coreServicesUrl: '',
  dataEntryServicesUrl: '',
  searchServiceUrl: '',
  auditServiceUrl: '',
  internalCommentServiceUrl: '',
  dataPackageServiceUrl: '',
  cookbookServiceUrl: '',
  instrumentIntegrationServiceUrl: '',
  instrumentAdminServiceUrl: '',
  instrumentServerUrl: '',
  elnInstrumentsServiceUrl: '',
  elnFilesServiceUrl: '',
  elnLabelsServiceUrl: '',
  elnWebUrl: '',
  logging: {
    enableDisplay: false,
    logViewerUrl: ''
  },
  instrumentDisconnectTimeout: 0,
  signalRRetryAttempts: [5000, 5000, 5000, 5000, 5000],
  signalRInitialDelay: 5000,
  fieldFlashDelay: 1000,
  maxIntervalToLoadLocks: 5000,
  // This license is only for Eurofins ELN and should not be used by any other application
  agGridLicense: 'CompanyName=Eurofins (US, Lancaster, PA),'
    + 'LicensedApplication=Eurofins ELN,LicenseType=SingleApplication,'
    + 'LicensedConcurrentDeveloperCount=5,'
    + 'LicensedProductionInstancesCount=0,'
    + 'AssetReference=AG-035230,'
    + 'SupportServicesEnd=4_January_2024_[v2]_MTcwNDMyNjQwMDAwMA==25dd214d01550e171563540f5b4c864b',
  features: {
    systemAlert: {
      message: ''
    }
  },
};
