/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuditHistoryDataRecordResponse } from '../models/audit-history-data-record-response';
import { ExperimentBelongsToContextCommand } from '../models/experiment-belongs-to-context-command';

@Injectable({
  providedIn: 'root',
})
export class ActivityInputAuditHistoryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation activityInputsAuditHistoryExperimentIdPost
   */
  static readonly ActivityInputsAuditHistoryExperimentIdPostPath = '/activity-inputs-audit-history/{experimentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputsAuditHistoryExperimentIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputsAuditHistoryExperimentIdPost$Plain$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentBelongsToContextCommand
  }): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputAuditHistoryService.ActivityInputsAuditHistoryExperimentIdPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuditHistoryDataRecordResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputsAuditHistoryExperimentIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputsAuditHistoryExperimentIdPost$Plain(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentBelongsToContextCommand
  }): Observable<AuditHistoryDataRecordResponse> {

    return this.activityInputsAuditHistoryExperimentIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>) => r.body as AuditHistoryDataRecordResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputsAuditHistoryExperimentIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputsAuditHistoryExperimentIdPost$Json$Response(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentBelongsToContextCommand
  }): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivityInputAuditHistoryService.ActivityInputsAuditHistoryExperimentIdPostPath, 'post');
    if (params) {
      rb.path('experimentId', params.experimentId, {});
      rb.header('X-User-Puid', params['X-User-Puid'], {});
      rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
      rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuditHistoryDataRecordResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activityInputsAuditHistoryExperimentIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputsAuditHistoryExperimentIdPost$Json(params: {
    experimentId: string;
    'X-User-Puid'?: any;
    'X-User-LabsiteCode'?: any;

    /**
     * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
     */
    'X-Experiment-PreviewMode'?: any;
    body?: ExperimentBelongsToContextCommand
  }): Observable<AuditHistoryDataRecordResponse> {

    return this.activityInputsAuditHistoryExperimentIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>) => r.body as AuditHistoryDataRecordResponse)
    );
  }

}
