import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { BaseComponent } from '../../../../base/base.component';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';
import { BehaviorSubject } from 'rxjs';
import { CrossReferencesColumns, CrossReferenceRow } from '../cross-references.component';
import { ReferencesService } from '../../references.service';
import { ExperimentService } from '../../../services/experiment.service';
import { Activity } from '../../../../model/experiment.interface';
import { ExperimentWarningService } from '../../../services/experiment-warning.service';

@Component({
  selector: 'app-references-removed-cross-reference',
  templateUrl: './references-removed-cross-reference.component.html',
  styleUrls: ['./references-removed-cross-reference.component.scss']
})
export class ReferencesRemovedCrossReferenceComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() titleOfTable = $localize`:@@crossReferences:Cross References`;
  @Input()
  subHeading = $localize`:@@RemovedCrossReferenceSubHeading:Please click on the restore icon from the table to restore removed cross references`;
  dataSource: CrossReferenceRow[] = [];
  @ViewChild('grid') grid?: BptGridComponent;

  columnDefinitions: ColumnDefinition[];
  gridMode = BptGridMode.dataView;

  get currentActivity(): Activity | undefined {
    return this.experimentService.currentActivity;
  }
  
  get gridActions(): BptGridRowActionConfiguration {
    const rowActions = this.rowActionItems;
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);
  
    return { actions: actionsSubject };
  }

  get rowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.referencesService.crossReferenceRestoreActionId,
        enabled: true,
        styleClass: 'icon-s icon-restore-icon',
        click: this.rowRestoreActionClick.bind(this),
        tooltip: $localize`:@@RestoreItem:Restore item`
      }
    ];
  }

  constructor(
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    public readonly referencesService: ReferencesService,
    private readonly experimentService: ExperimentService,
    private readonly experimentWarningService : ExperimentWarningService
  ) {
    super(clientStateService, activatedRoute);
    this.columnDefinitions = CrossReferencesColumns;
    this.refreshDataSource();
  }

  ngOnInit(): void {
    this.watchConsumableRestoredNotification();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }

  private refreshDataSource(): void {
    const crossReferences = this.currentActivity?.activityReferences.crossReferences ?? [];
    const inactiveXRefs = crossReferences.filter(c => c.isRemoved);
    this.dataSource = this.referencesService.crossReferencesByActivity[this.experimentService.currentActivityId]
       ?.filter(r => inactiveXRefs.some(x => x.id === r.id));
    // Apparently it's not enough to set the property the grid's dataSource is bound to, we have to call the API for some reason
    this.grid?.gridApi?.setRowData(this.dataSource);
  }

  private watchConsumableRestoredNotification() {
    this.activeSubscriptions.push(
      this.referencesService.crossReferenceRestored.subscribe({
        next: () => this.refreshDataSource()
      })
    );
  }

  private rowRestoreActionClick(e: BptGridRowActionClickEvent) {
    this.referencesService.confirmThenRestoreCrossReference(e.params.data); 
  }
}
